import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) =>
	state.network.dns.domain.transfer.createIncoming;

const { actions, reducer, sagas, selectors } = createAPIModule({
	getStateSlice,
	actionType: 'NETWORK_DNS_DOMAIN_TRANSFER_CREATEINCOMING',
	method: 'POST',
	url: '/network/dns/domain/transfer/createIncoming.json',
});

export { actions, reducer, sagas, selectors };
