import { useState, useEffect } from 'react';

// From: https://dev.to/gabe_ragland/debouncing-with-react-hooks-jci
export default function useDebounce(value, delay) {
	const [debouncedValue, setDebouncedValue] = useState(value);

	useEffect(() => {
		const handlerTimeoutId = setTimeout(() => {
			setDebouncedValue(value);
		}, delay);

		return () => {
			clearTimeout(handlerTimeoutId);
		};
	}, [value, delay]);

	return debouncedValue;
}
