import React from 'react';
import Box from '@material-ui/core/Box';
import LWTypography from 'components/common/LWTypography';
import { currency as formatUScurrency } from 'utility/format';
import PropTypes from 'prop-types';

const pricingText = (priceData) => {
	if (priceData) {
		return priceData.get('month') !== '0.00'
			? ` for ${formatUScurrency(priceData.get('month'))} / month`
			: '';
	}
	return '. Price could not be calculated';
};

const PrimaryClusterable = ({ priceData }) => (
	<>
		<Box pb={3}>
			<LWTypography>
				This server is compatible with a Database Clustering service available
				for this project. Since it is the first, it will be used as the Primary
				DB node.
			</LWTypography>
		</Box>
		<Box pb={2}>
			<LWTypography>
				<LWTypography bold inline>
					After added, the Database Clustering service will be enabled
					{pricingText(priceData)}.
				</LWTypography>{' '}
				The server will then be permanently tied to the service and cannot be
				removed from the project unless the server is destroyed.
			</LWTypography>
		</Box>
	</>
);

PrimaryClusterable.propTypes = {
	priceData: PropTypes.object.isRequired,
};

export default PrimaryClusterable;
