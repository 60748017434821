import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import logo from 'images/lw-logo-full.png';
import logo2x from 'images/lw-logo-full@2x.png';
import logo3x from 'images/lw-logo-full@3x.png';
import mobileLogo from 'images/logo-mobile.png';
import LWTypography from 'components/common/LWTypography';

const styles = () => ({
	root: {
		margin: '1em',
	},
});

const AppConfigError = (props) => {
	const { classes } = props;
	return (
		<div className={classes.root}>
			<Hidden smDown>
				<img
					className={classes.image}
					src={logo}
					srcSet={`${logo2x} 2x, ${logo3x} 3x`}
					alt="Liquid Web Logo"
				/>
			</Hidden>
			<Hidden mdUp>
				<img className={classes.image} src={mobileLogo} alt="Liquid Web Logo" />
			</Hidden>
			<LWTypography variant="h1">Error</LWTypography>
			<LWTypography>There was an error loading the site.</LWTypography>
		</div>
	);
};

AppConfigError.propTypes = {
	classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AppConfigError);
export { AppConfigError };
