import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import flexSaga from 'utility/redux/saga/flexSaga';
import wwwClient from 'utility/redux/saga/wwwClient';

const getStateSlice = (state) => state.www.wpPosts;

// TODO: remove this once www caching is fixed
const time = new Date().getTime();

const { actions, reducer, sagas, selectors } = createAPIModule({
	getStateSlice,
	actionType: 'WP_POSTS',
	method: 'GET',
	// TODO: remove this query string once www caching is fixed
	url: `wp/v2/posts/?_q=${time}`,
	client: flexSaga(wwwClient),
	prefix: 'www-api',
});

export { actions, reducer, sagas, selectors };
