import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import { Iterable } from 'immutable';
import asyncComponent from 'components/AsyncComponent';
import { roles } from 'utility/constants/roles';
import RoleRestrictedRoute from 'utility/routing/RoleRestrictedRoute';

const User = asyncComponent(() =>
	import('containers/pages/account/users/User'),
);
const TwoFactor = asyncComponent(() =>
	import('containers/pages/account/twoFactor/TwoFactor'),
);

const AddUser = asyncComponent(() =>
	import('containers/pages/account/users/AddUser'),
);

const InvoiceDetails = asyncComponent(() =>
	import('containers/pages/billing/InvoiceDetails'),
);

const NotFound = asyncComponent(() => import('containers/structural/NotFound'));

const AccountRouter = (props) => {
	const { navData } = props;
	const subNavs = navData.get('subNav');
	return (
		<Switch>
			<RoleRestrictedRoute
				exact
				path={navData.get('path')}
				component={navData.get('component')}
			/>
			<RoleRestrictedRoute
				exact
				path={`${navData.get('path')}/billing/invoice/:invoiceId`}
				component={InvoiceDetails}
				whitelistedRoles={[
					roles.ACCOUNT_OWNER,
					roles.SECONDARY_OWNER,
					roles.SUSPENDED_OWNER,
					roles.PURCHASER,
				]}
			/>
			<RoleRestrictedRoute
				path={`${navData.get('path')}/users/:username/authentication`}
				component={TwoFactor}
			/>
			<RoleRestrictedRoute
				path={`${navData.get('path')}/users/:username`}
				component={User}
			/>
			<RoleRestrictedRoute
				path={`${navData.get('path')}/add-user/`}
				component={AddUser}
			/>
			{subNavs.valueSeq().map((subNavData) => {
				let whitelistedRoles = [];
				if (Iterable.isIterable(subNavData.get('whitelistedRoles'))) {
					whitelistedRoles = subNavData.get('whitelistedRoles').toJS();
				}
				return (
					<RoleRestrictedRoute
						key={subNavData.get('path')}
						path={subNavData.get('path')}
						component={subNavData.get('component')}
						whitelistedRoles={whitelistedRoles}
						exact
					/>
				);
			})}
			<Route component={NotFound} />
		</Switch>
	);
};

AccountRouter.propTypes = {
	navData: PropTypes.object.isRequired,
};
const ACCOUNT_CREATE_PATH = '/account/create';

export { ACCOUNT_CREATE_PATH };

export default AccountRouter;
