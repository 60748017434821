import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { required } from 'redux-form-validators';
import countriesMappedToOptions from 'utility/countriesMappedToOptions';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import {
	renderCheckbox,
	renderTextField,
	renderAutocomplete,
} from 'utility/form/renderers';

import {
	getRegionLabel,
	getCityLabel,
	getPostalCodeLabel,
} from 'utility/addressLabel';

import {
	countryOption,
	countryWithRegions,
	stateOption,
	getRegions,
} from 'utility/countryRegionConverter';
import variantConfigs from './variantConfigs';

const TEST_ID = 'AddressFormSection';
const AddressFormSection = ({
	changeFieldValue,
	contact,
	reset, // from redux-form
	selectedCountry,
	showPhysicalAddress,
	variant = 'default',
}) => {
	const [usePhysicalAddress, setUsePhysicalAddress] = useState(false);
	const stateOptions = useMemo(() => getRegions(selectedCountry), [
		selectedCountry,
	]);

	const { gridItemSettings, gridContainerSettings } = variantConfigs[variant];

	const togglePhysicalAddress = () => {
		if (!usePhysicalAddress && contact) {
			const country = countryWithRegions(contact.country);

			// copy mailing address into form fields
			changeFieldValue('address', contact.address);
			changeFieldValue('address2', contact.address2);
			changeFieldValue('city', contact.city);
			changeFieldValue('state', stateOption(country, contact.state));
			changeFieldValue('country', countryOption(country));
			changeFieldValue('postalCode', contact.postal_code);
			changeFieldValue('usePhysicalAddress', !usePhysicalAddress);
		} else {
			// clear form fields
			reset();
		}
		setUsePhysicalAddress(!usePhysicalAddress);
	};

	return (
		<div data-testid={TEST_ID}>
			<Grid container {...gridContainerSettings} direction="column">
				<Grid item {...gridItemSettings}>
					{showPhysicalAddress && (
						<Box px={1} pb={3}>
							<Field
								aria-label="Use Physical Address"
								label="Use Physical Address"
								name="usePhysicalAddress"
								component={renderCheckbox}
								input={{
									value: usePhysicalAddress,
									onChange: togglePhysicalAddress,
								}}
							/>
						</Box>
					)}
					<Field
						aria-label="Country"
						label="Country"
						name="country"
						id="addressformsection__country"
						component={renderAutocomplete}
						options={countriesMappedToOptions()}
						onChange={() => {
							changeFieldValue('taxExempt', null);
							changeFieldValue('taxExemptReason', null);
							changeFieldValue('taxExemptId', null);
							changeFieldValue('state', null);
						}}
						placeholder=""
						validate={[required()]}
						disabled={usePhysicalAddress}
					/>
				</Grid>
				<Grid item {...gridItemSettings}>
					<Field
						aria-label="Address1"
						label="Address"
						name="address"
						component={renderTextField}
						validate={[required()]}
						variant="outlined"
						disabled={usePhysicalAddress}
					/>
				</Grid>
				<Grid item {...gridItemSettings}>
					<Field
						aria-label="Address2"
						label="Address Line 2"
						name="address2"
						component={renderTextField}
						variant="outlined"
						disabled={usePhysicalAddress}
					/>
				</Grid>
				<Grid item {...gridItemSettings}>
					<Field
						aria-label={selectedCountry && getCityLabel(selectedCountry.label)}
						label={selectedCountry && getCityLabel(selectedCountry.label)}
						name="city"
						component={renderTextField}
						validate={[required()]}
						variant="outlined"
						disabled={usePhysicalAddress}
					/>
				</Grid>
				<Grid item {...gridItemSettings}>
					<Field
						aria-label={selectedCountry && getRegionLabel(selectedCountry)}
						label={selectedCountry && getRegionLabel(selectedCountry)}
						name="state"
						id="addressformsection__state"
						component={renderAutocomplete}
						options={stateOptions || []}
						validate={[required()]}
						variant="outlined"
						disabled={usePhysicalAddress}
						placeholder=""
					/>
				</Grid>
				<Grid item {...gridItemSettings}>
					<Field
						aria-label="PostalCode"
						label={selectedCountry && getPostalCodeLabel(selectedCountry)}
						name="postalCode"
						component={renderTextField}
						validate={[required()]}
						variant="outlined"
						disabled={usePhysicalAddress}
					/>
				</Grid>
			</Grid>
		</div>
	);
};

AddressFormSection.propTypes = {
	changeFieldValue: PropTypes.func,
	contact: PropTypes.object,
	reset: PropTypes.func,
	selectedCountry: PropTypes.shape({
		label: PropTypes.string,
		value: PropTypes.string,
	}),
	showPhysicalAddress: PropTypes.bool,
};

export { TEST_ID };
export default AddressFormSection;
