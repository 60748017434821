import actions from './actions';

const defaultState = { newIpCount: 1 };

const reducer = (state = defaultState, { newIpCount, type }) => {
	switch (type) {
		case actions.BASKET_SET_NEW_IP_COUNT:
			return { ...state, newIpCount };
		case actions.BASKET_DECREMENT_NEW_IP_COUNT:
			return { ...state, newIpCount: state.newIpCount - 1 };
		default:
			return state;
	}
};

export default reducer;
