import { all, call } from 'redux-saga/effects';
import { sagas as reboot } from './rebootModule';
import { sagas as resize } from './resizeModule';
import { sagas as resizeVolume } from './resizeVolumeModule';
import { sagas as resizeStorage } from './resizeStorageModule';
import { sagas as start } from './startModule';
import { sagas as shutdown } from './shutdownModule';
import { sagas as update } from './updateModule';
import { sagas as available } from './availableModule';
import { sagas as availableFlavors } from './availableFlavorsModule';
import { sagas as availableStorage } from './availableStorageModule';
import { sagas as vncConsole } from './vncConsoleModule';
import { sagas as availableConfigs } from './availableConfigsModule';
import auth from './auth/authSagas';
import whmSagas from './WHM/whmSagas';

export default function* serverSaga() {
	yield all([
		call(auth),
		call(available),
		call(availableStorage),
		call(availableFlavors),
		call(update),
		call(reboot),
		call(resize),
		call(resizeVolume),
		call(resizeStorage),
		call(shutdown),
		call(start),
		call(vncConsole),
		call(whmSagas),
		call(availableConfigs),
	]);
}
