import { createSelector } from 'reselect';
import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { selectors as assetSelectors } from 'modules/api/asset/detailsModule';
import templateSelectors from 'modules/api/basket/item/detailsModule/templateSelectors';
import get from 'lodash/get';

const getStateSlice = (state) => state.account.limits.dynamicChild;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'ACCOUNT_LIMITS_DYNAMICCHILD',
	url: '/account/limits/dynamicChild.json',
});

const selectors = {
	limits: createSelector(
		defaultSelectors.getNativeData,
		assetSelectors.childType,
		(native, childType) =>
			get(native, childType, {
				Cpu: 1,
				Disk: 1,
				Memory: 1,
			}),
	),
	basketLimits: createSelector(
		defaultSelectors.getNativeData,
		templateSelectors.childType,
		(native, childType_) =>
			get(native, childType_, {
				Cpu: 1,
				Disk: 1,
				Memory: 1,
			}),
	),

	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };
