import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import QuantitySelect from 'components/common/QuantitySelect';
import LWTypography from 'components/common/LWTypography';
import ConfirmCancel from 'components/common/Dialogs/ConfirmCancel';
import Box from '@material-ui/core/Box';

const QuantitySelectModalContents = ({
	onClose,
	onConfirm,
	onChange = () => {},
	confirmText = '', // This will make sense when you take the pluralize param into account.
	instructionsTop,
	instructionsBottom,
	helperText,
	label,
	limit,
	pluralize = false,
	isLoading,
}) => {
	const [quantity, setQuantity] = useState(1);
	const [closing, setClosing] = useState(false);
	useEffect(() => {
		if (closing && !isLoading) {
			onClose();
		}
	}, [closing, isLoading, onClose]);
	return (
		<>
			<Box mx={3} mb={4}>
				<LWTypography>{instructionsTop}</LWTypography>
				<QuantitySelect
					{...{ quantity, setQuantity, helperText, label, limit, onChange }}
				/>
				<LWTypography
					BoxProps={{ mt: 1 }}
					variant="body1"
					color="textSecondary"
				>
					{instructionsBottom}
				</LWTypography>
			</Box>
			<ConfirmCancel
				isLoading={isLoading}
				cancel={onClose}
				confirm={() => {
					onConfirm(quantity);
					setTimeout(setClosing(true));
				}}
				confirmText={
					`${confirmText}${
						pluralize && confirmText && quantity > 1 ? 's' : ''
					}` || 'Confirm'
				}
			/>
		</>
	);
};

QuantitySelectModalContents.defaultProps = {
	instructionsTop: '',
	instructionsBottom: '',
};

QuantitySelectModalContents.propTypes = {
	/** A function that closes the modal */
	onClose: PropTypes.func.isRequired,
	/** Funtion trigger by confirm button. Quantity is passed in a first arg. */
	onConfirm: PropTypes.func.isRequired,
	/** confirm button test. Defaults to "Confirm" */
	confirmText: PropTypes.string,
	/** appears above the selector as primary text. */
	instructionsTop: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	/** appears beneath the selector as subtext. */
	instructionsBottom: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	/** The maximum number allowed */
	limit: PropTypes.number,
	/** The text that will appear below the field. For example: 7 slots remaining. */
	helperText: PropTypes.string,
	/** Label for the field (appears at the top) */
	label: PropTypes.string,
	/** Add 's' to confirm if more than one? */
	pluralize: PropTypes.bool,
};

export default QuantitySelectModalContents;
