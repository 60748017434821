const productConfig = {
	SET_PRIVATE_PARENT: 'SET_PRIVATE_PARENT',
	setPrivateParent: ({ deployOnto, noAssert }) => ({
		type: productConfig.SET_PRIVATE_PARENT,
		deployOnto,
		noAssert, // setPrivateParent in itemAssertProperties takes this action. Set to true to make that saga no-op.
	}),
	SET_PRODUCT_CONFIG_DEPLOY_ONTO: 'SET_PRODUCT_CONFIG_DEPLOY_ONTO',
	setDeployOnto: ({ deployOnto, noAssert }) => ({
		type: productConfig.SET_PRODUCT_CONFIG_DEPLOY_ONTO,
		deployOnto,
		noAssert, // setPrivateParent in itemAssertProperties takes this action. Set to true to make that saga no-op.
	}),
	ADD_NEW_CHILD: 'ADD_NEW_CHILD',
	addNewChild: ({ parent }) => ({
		type: productConfig.ADD_NEW_CHILD,
		parent,
	}),
	ON_CHANGE_DEPLOY_ONTO: 'ON_CHANGE_DEPLOY_ONTO',
	onChangeDeployOnto: ({ value, confirm }) => ({
		type: productConfig.ON_CHANGE_DEPLOY_ONTO,
		value,
		confirm,
	}),
};

export default productConfig;
