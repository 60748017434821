/* eslint-disable camelcase */
import { put, select, takeLatest } from 'redux-saga/effects';
import { getPaginationStateProps } from 'utility/redux/paginationProps';
import moment from 'moment';
import timelineActions from 'modules/billing/transactionTimeline/actions';
import {
	selectors as timelineModuleSelectors,
	actions as timelineModuleActions,
} from 'modules/api/account/invoicesAndPaymentsTimelineModule';
import timelineSelectors from 'modules/billing/transactionTimeline/selectors';

const DATE_FORMAT = 'YYYY-MM-DD';

function* handleChangeFilter(action) {
	if (!action.payload.skipRefetch) {
		const timelineData = yield select((state) =>
			timelineSelectors.getFilters(state),
		);
		const paginationData = yield select((state) =>
			getPaginationStateProps(timelineModuleSelectors, state),
		);
		const { title, begin_date, end_date, invoice_id } = timelineData;
		const payload = {};

		if (begin_date) {
			payload.begin_date = moment(begin_date).format(DATE_FORMAT);
		}
		if (end_date) {
			payload.end_date = moment(end_date).format(DATE_FORMAT);
		}
		if (invoice_id) {
			payload.invoice_id = invoice_id;
		}
		if (title) {
			payload.title = title;
		}

		const { pageSize: page_size } = paginationData;
		payload.page_size = page_size;

		yield put(timelineModuleActions.fetch(payload));
	}
}

export default function* rootSaga() {
	yield takeLatest(
		timelineActions.TRANSACTION_TIMELINE_TOGGLE_CHANGE_FILTER,
		handleChangeFilter,
	);
	yield takeLatest(
		timelineActions.TRANSACTION_TIMELINE_TOGGLE_CLEAR_FILTERS,
		handleChangeFilter,
	);
}
