import React from 'react';
import { connect } from 'react-redux';
import compose from 'utility/compose';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';

import { selectors as appConfigSelectors } from 'modules/api/appConfigModule';
import dialogActions from 'modules/dialogs/actions';
import { immutableToValueArray } from 'utility/immutable';

import LWLink from 'components/common/LWLink';
import NavLinkHelper from '../NavLinkHelper';

// puts navbuttons ontop of sticky table
const SPopper = styled(Popper)`
	z-index: 1401;
`;
export const styles = (theme) => ({
	root: {},
	submenu: {
		display: 'flex',
		flexDirection: 'column',
		'& .MuiMenuItem-root': {
			overflow: 'visible',
		},
		'& .MuiBadge-anchorOriginTopRightRectangle': {
			marginRight: -8,
		},
	},
	navLink: {
		width: '100%',
	},
	linkTypography: {
		color: theme.palette.other.link,
		textTransform: 'uppercase',
	},
});

const SubNavMenu = ({
	configObj,
	closeDialog,
	classes,
	anchorEl,
	openDialog,
	subNavData: rawSubNavData,
	parentName,
	openManageRedirectDialog,
}) => {
	const subNavData = immutableToValueArray(rawSubNavData);
	return (
		<SPopper
			anchorEl={anchorEl}
			open={Boolean(anchorEl)}
			placement="bottom"
			data-testid={`SubNavMenu__${parentName}`}
		>
			<Paper elevation={3} className={classes.submenu}>
				{subNavData.map((navLink) => {
					const navLinkHelper = new NavLinkHelper({
						navLink,
						classes,
						configObj,
						closeDialog,
						openDialog,
						openManageRedirectDialog,
					});

					return (
						<div key={navLink.name}>
							<LWLink
								underline="none"
								className={classes.navLink}
								to={navLink.path || '/'}
								onClick={navLinkHelper.onClick}
							>
								{!navLink.navHidden && (
									<MenuItem>{navLinkHelper.linkContents}</MenuItem>
								)}
							</LWLink>
						</div>
					);
				})}
			</Paper>
		</SPopper>
	);
};

const mapStateToProps = (state) => ({
	configObj: appConfigSelectors.getNativeData(state),
});
const mapDispatchToProps = (dispatch) => ({
	openDialog: ({ title, content, DialogProps }) =>
		dispatch(dialogActions.open({ title, content, DialogProps })),
	closeDialog: () => dispatch(dialogActions.close()),
});

SubNavMenu.defaultProps = {
	anchorEl: null,
};

SubNavMenu.propTypes = {
	classes: PropTypes.object.isRequired,
	anchorEl: PropTypes.object,
	subNavData: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
		.isRequired,
};

export default compose(
	withStyles(styles),
	connect(
		mapStateToProps,
		mapDispatchToProps,
	),
)(SubNavMenu);
export { SubNavMenu };
