import { combineReducers } from 'redux';

import loadReducer from './load/loadReducer';
import bandwidthReducer from './bandwidth/bandwidthReducer';
import servicesReducer from './services/servicesReducer';

export default combineReducers({
	load: loadReducer,
	bandwidth: bandwidthReducer,
	services: servicesReducer,
});
