import { createSelector } from 'reselect';

import { getDescriptionAndIcon } from 'utility/redux/selectorHelperFunctions/templateCopyHelpers';
import {
	createTemplateTagOptions,
	createTagType,
} from 'utility/redux/selectorHelperFunctions/template';

import { selectors } from '.';

const templatesFormName = 'productConfigTemplates';

// does not include selected.
const templateOptions = createSelector(
	selectors.template,
	(slice) =>
		slice?.options?.sort(
			(
				{
					value_display_order: orderA,
					price: priceA,
					value_description: descA,
				},
				{
					value_display_order: orderB,
					price: priceB,
					value_description: descB,
				},
			) =>
				orderA - orderB ||
				priceA - priceB ||
				(() => {
					if (descA < descB) return -1;
					if (descA > descB) return 1;
					return 0;
				})(),
		) || [],
);

const rawSelected = createSelector(
	selectors.template,
	(slice) =>
		// This basically moves the root into the options; no need to use up RAM to store the options inside of an option.
		({ ...slice, options: undefined }),
);

// Includes the selected template. Selected should always be first.
const allTemplates = createSelector(
	rawSelected,
	templateOptions,
	(selected, unselected_) => [selected, ...unselected_],
);

const availableTags = createSelector(
	allTemplates,
	(options) =>
		Array.from(
			new Set(options?.map(({ tags: elemTags }) => elemTags).flat()),
		).filter((elem) => elem),
);

const availableSupportLevels = createSelector(
	allTemplates,
	(slice) =>
		Array.from(
			new Set(slice.map((elem) => elem?.extra_data?.support_level)),
		).filter((elem) => elem),
);

const availableOs = createSelector(
	allTemplates,
	(slice) =>
		Array.from(new Set(slice.map((elem) => elem?.extra_data?.os))).filter(
			(elem) => elem,
		),
);

const getTemplateTagOptions = createSelector(
	availableTags,
	availableSupportLevels,
	availableOs,
	createTemplateTagOptions,
);

// value from the form data (name="show")
const showValue = (state) => state?.form[templatesFormName]?.values?.show;

const getTagType = createSelector(
	availableOs,
	availableSupportLevels,
	showValue,
	createTagType,
);

const uniqIdToClone = createSelector(
	// TODO: NEWMAN-1782, get real property from BE. uniq_id_to_clone is something Caleb made up.
	selectors.properties,
	(properties) => properties?.uniq_id_to_clone,
);

const templateEditable = createSelector(
	uniqIdToClone,
	(id) => !id, // No template selection if cloning a vps.
);

const templateVisibleOptions = createSelector(
	templateOptions, // does not include selected
	getTagType,
	showValue,
	rawSelected,
	templateEditable,
	(unselected_, selectedTag, show, selectedOption, editable) => [
		selectedOption, // selected should always be first.
		...(() => {
			if (!editable) return [];
			switch (selectedTag) {
				case 'All':
					return unselected_;
				case 'os':
					return unselected_.filter((elem) => elem.extra_data.os === show);
				case 'supportLevel':
					return unselected_.filter(
						(elem) => elem.extra_data.support_level === show,
					);
				default:
					// tag
					return unselected_.filter((elem) => elem.tags?.includes(show));
			}
		})(),
	],
);

const selectedTemplate = createSelector(
	selectors.template,
	(templatesObj) => templatesObj?.value,
);

// For sending to dynamicChild
const childType = createSelector(
	selectors.template,
	(templatesObj) => {
		if (templatesObj?.extra_data?.os === 'Windows') {
			return 'Windows';
		}
		const supportLevel = templatesObj?.extra_data?.support_level;
		switch (supportLevel) {
			case 'Fully-Managed':
			case 'Core-Managed':
				return 'Managed';
			case 'Self-Managed':
				return 'Unmanaged';
			default:
				return false;
		}
	},
);

const templateTileData = createSelector(
	templateVisibleOptions,
	(options) => {
		return options
			.filter(({ value }) => value)
			.map(
				({
					value,
					price_total: price,
					value_description: title,
					extra_data: { os, support_level: supportLevel } = {},
				}) => ({
					value,
					price,
					title,
					...getDescriptionAndIcon({ os, supportLevel }),
				}),
			);
	},
);

export { templatesFormName };

export default {
	templateOptions, // does not include selected
	selectedTemplate,
	allTemplates,
	getTemplateTagOptions,
	templateTileData,
	childType,
	uniqIdToClone,
	templateEditable,
};
