import { currency } from 'utility/format';
import { createSelector } from 'reselect';
import { selectors } from '.';

// ExtraIp config selectors

// This refers to the number of IPs in addition to the free one. To get the total, add the selectedValue selector.
const extraIpNumUnits = createSelector(
	selectors.extraIpConfig,
	(config) => config?.num_units,
);

const extraIpPrice = createSelector(
	selectors.extraIpConfig,
	(config) => currency(config?.price, { raw: true, dollarSign: true }),
);

const selectedValue = createSelector(
	selectors.extraIpConfig,
	(config) => config?.value,
);

// IP pool selectors
const ipPool = createSelector(
	selectors.extraData,
	(extraData_) => extraData_?.ip_pool,
);

const unusedIps = createSelector(
	ipPool,
	(ipPool_) => ipPool_?.unused_assignments || [],
);

const unusedIpRows = createSelector(
	unusedIps,
	(unusedIps_) =>
		unusedIps_.map((ip) => ({
			description: ip.ip,
			id: ip.ip,
		})),
);

const selectedPooledIps = createSelector(
	selectors.properties,
	(properties) => properties?.ip_pool || [],
);

const newIps = createSelector(
	selectedPooledIps,
	extraIpNumUnits,
	selectedValue,
	(pooled, total, numberIncluded) =>
		Math.max(Number(total) - pooled.length + Number(numberIncluded), 0),
);

// initial values used for 'selected' fields of StormConfig__IPTables
const initialIpPoolValues = createSelector(
	selectedPooledIps,
	(ips) => ips.map((elem) => ({ description: elem, id: elem })),
);

export default {
	extraIpNumUnits,
	extraIpPrice,
	selectedValue,
	ipPool,
	unusedIpRows,
	selectedPooledIps,
	newIps,
	initialIpPoolValues,
};
