import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';

const getStateSlice = (state) => state.project.available;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'PROJECT_AVAILABLE',
	url: '/project/available.json',
});

const selectors = {
	isAvailable: createSelector(
		getStateSlice,
		(slice) => !!slice.getIn(['data', 'available'], null),
	),
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };
