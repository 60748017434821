import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { renderSelectField, renderSwitch } from 'utility/form/renderers';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import MiniCart from 'components/forms/MiniCart';
import MenuItem from '@material-ui/core/MenuItem';
import LWTooltip from 'components/common/LWTooltip';
import LWTypography from 'components/common/LWTypography';
import ConfirmCancel from 'components/common/Dialogs/ConfirmCancel';
import { getOptionPrice } from 'utility/products/options';
import get from 'lodash/get';

const RenewDomainForm = ({
	renewDialogObj: {
		payload: { domain },
	},
	details,
	renewObj,
	renewDomain,
	fetchProduct,
	product,
	changeFieldValue,
	selected,
	onClose,
}) => {
	const { numberOfYears, privacy, autoRenew } = selected;
	const { options } = product;
	const submitPending = details.isLoading || renewObj.isLoading;

	const privacyPriceData = getOptionPrice({
		key: 'DomainPrivacy',
		value: 'On',
		productOptions: options,
	});

	const privacyPrice = privacyPriceData ? privacyPriceData['one-time'] : null;

	React.useEffect(() => {
		if (!product.isLoading && product.code !== 'DREG') {
			fetchProduct({ code: 'DREG' });
		}
	}, [fetchProduct, product.isLoading, product.code]);

	if (details.isLoading) {
		return <CircularProgress size={30} />;
	}

	if (!details || !details.data) return null;

	const miniCartData = [
		{
			show: true,
			data: {
				key: {
					title: domain,
					subtitle: 'Domain Renewal fee',
				},
				value: numberOfYears * details.renewalPrice,
			},
		},
		{
			show: selected.privacy,
			data: {
				key: {
					title: 'WHOIS Privacy Protection',
					subtitle: `for ${domain}`,
				},
				value: parseFloat(privacyPrice),
			},
		},
		{
			show: details.isRGP,
			data: {
				key: {
					title: (
						<div>
							{'Third Party Redemption Fee'}
							<LWTooltip>
								Domain redemption occurs when a domain name has passed the
								expired grace period. During the redemption period the domain
								name owner can renew their domain, but the registrar charges an
								additional redemption fee.
							</LWTooltip>
						</div>
					),
					subtitle: `for ${domain}`,
				},
				value: parseFloat(details.redemptionPrice),
			},
		},
	];

	const miniCartList = miniCartData
		.filter((item) => item.show)
		.map((item) => item.data);
	return (
		<>
			<Grid container alignContent="center" spacing={2}>
				<Box width="100%" p={2}>
					<Grid>
						<Field
							aria-label="number of years"
							name="number_of_years"
							component={renderSelectField}
							label="Number of Years"
							fullWidth
						>
							{/* Defaulting to 1 if no renewalMaxYears in API */}
							{Array.from(Array(details.renewalMaxYears).keys()).map((i) => {
								return (
									<MenuItem value={i + 1} key={i + 1}>
										{i + 1}
									</MenuItem>
								);
							})}
						</Field>
					</Grid>
				</Box>
				<Grid item xs={12}>
					{/* privacy */}
					{renderSwitch({
						label: (
							<div>
								{'Renew WHOIS Privacy Protection'}
								{details.isExpired && (
									<LWTooltip>
										Purchasing WHOIS Privacy Protection is unavailable while the
										domain is expired. It can be purchased once the domain is
										renewed.
									</LWTooltip>
								)}
							</div>
						),
						value: privacy,
						handleChange: (e) =>
							changeFieldValue('privacy', get(e, 'target.checked')),
						checked: privacy,
						disabled: details.isExpired,
					})}
				</Grid>
				<Grid item xs={12}>
					{/* autoRenew */}
					{renderSwitch({
						label: (
							<div>
								{'Autorenew Domain'}
								{details.isExpired && (
									<LWTooltip>
										Enabling auto-renew is unavailable while the domain is
										expired. It can be enabled once the domain is renewed.
									</LWTooltip>
								)}
							</div>
						),
						value: autoRenew,
						handleChange: (e) =>
							changeFieldValue('autoRenew', get(e, 'target.checked')),
						checked: autoRenew,
						disabled: details.isExpired,
					})}
				</Grid>
				<Grid item xs={12}>
					<MiniCart list={miniCartList} />
				</Grid>
				<Grid item xs={12}>
					<LWTypography>
						Renewal costs will be charged immediately. All charges for renewals
						are non-refundable.
					</LWTypography>
				</Grid>
			</Grid>
			<ConfirmCancel
				confirmText="Submit"
				confirm={() =>
					renewDomain({
						domain,
						duration: numberOfYears,
						privacy: Number(privacy),
						autoRenew: Number(autoRenew),
					})
				}
				cancel={onClose}
				isLoading={submitPending}
			/>
		</>
	);
};

RenewDomainForm.propTypes = {
	changeFieldValue: PropTypes.func,
	details: PropTypes.shape({
		domain: PropTypes.string,
		data: PropTypes.object,
		isExpired: PropTypes.bool,
		isLoading: PropTypes.bool,
		isRGP: PropTypes.bool,
		renewalMaxYears: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		renewalPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		redemptionPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	}).isRequired,
	fetchProduct: PropTypes.func,
	product: PropTypes.object,
	renewDomain: PropTypes.any,
	renewObj: PropTypes.any,
	selected: PropTypes.shape({
		numberOfYears: PropTypes.number,
		privacy: PropTypes.bool,
		autoRenew: PropTypes.bool,
	}),
	onClose: PropTypes.func.isRequired,
};

export default RenewDomainForm;
