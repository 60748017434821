import { Map as ImmutableMap } from 'immutable';
import actions from './paymentActions';

const initialState = ImmutableMap({
	stage: null,
	amount: null,
	method: null,
});

const paymentReducer = (state = initialState, action) => {
	switch (action.type) {
		case actions.createType:
			return state
				.set('method', action.method)
				.set('amount', action.amount)
				.set('stage', action.stage)
				.set('cvv', action.cvv);
		case actions.setStageType:
			return state.set('stage', action.stage);
		default:
			return state;
	}
};

export default paymentReducer;
