import { createStore, applyMiddleware } from 'redux';
import { createBrowserHistory } from 'history';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { routerMiddleware } from 'connected-react-router';

import { createMiddleware } from 'redux-beacon';
import GoogleTagManager from '@redux-beacon/google-tag-manager';

import createSagaMiddleware from 'redux-saga';
import createRootReducer from './modules/RootReducer';
import rootSagas from './modules/rootSagas';
import eventsMap from './modules/gtm/eventsMap';

const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();

const gtm = GoogleTagManager();
const gtmMiddleware = createMiddleware(eventsMap, gtm);

const store = createStore(
	createRootReducer(history),
	composeWithDevTools(
		applyMiddleware(routerMiddleware(history), sagaMiddleware, gtmMiddleware),
	),
);

sagaMiddleware.run(rootSagas);

export { store };
export { history };
