import { createSelector } from 'reselect';
import { selectors as dynamicChildSelectors } from 'modules/api/account/limits/dynamicChildModule';
import { selectors as optionsSelectors } from 'modules/server/resize/options';
import { selectors as assetDetailsSelectors } from 'modules/api/asset/detailsModule';
import { middleValue } from 'utility/middleValue';
import { formValueSelector } from 'redux-form';

const formName = 'PrivateParentSliders';
const formValuesSelector = (state) => {
	const selector = formValueSelector(formName);
	return selector(state, 'ram', 'cpu', 'disk');
};

const isLoading = createSelector(
	assetDetailsSelectors.isLoading,
	dynamicChildSelectors.isLoading,
	optionsSelectors.isLoading,
	(asset, dynamic, options) => asset || dynamic || options,
);

const selectionValid = createSelector(
	formValuesSelector,
	dynamicChildSelectors.limits,
	optionsSelectors.selectedPrivateParent,
	(
		{ cpu: selectedVcpu, ram: selectedRam, disk: selectedDisk },
		{ Cpu, Disk, Memory },
		{ resources, vcpu },
	) =>
		selectedVcpu >= Cpu &&
		selectedVcpu <= vcpu &&
		selectedRam >= Memory &&
		selectedRam <= resources?.memory?.free &&
		selectedDisk >= Disk &&
		selectedDisk <= resources?.diskspace?.free,
);

const initialCpu = createSelector(
	optionsSelectors.selectedPrivateParent,
	dynamicChildSelectors.limits,
	assetDetailsSelectors.CPUint,
	(privateParent, limits, current) =>
		middleValue([privateParent?.vcpu, limits.Cpu, current]) || 0,
);

const initialRam = createSelector(
	optionsSelectors.selectedPrivateParent,
	dynamicChildSelectors.limits,
	assetDetailsSelectors.RAM,
	(privateParent, limits, current) =>
		middleValue([
			privateParent?.resources?.memory?.free,
			limits?.Memory,
			current,
		]) || 0,
);

const initialDisk = createSelector(
	optionsSelectors.selectedPrivateParent,
	dynamicChildSelectors.limits,
	assetDetailsSelectors.storageSize,
	(privateParent, limits, current) =>
		middleValue([
			privateParent?.resources?.diskspace?.free,
			limits?.Disk,
			current,
		]) || 0,
);

const maxDisk = createSelector(
	optionsSelectors.selectedPrivateParent,
	assetDetailsSelectors.storageSize,
	assetDetailsSelectors.privateParent,
	(selectedPrivateParent_, storageSize, privateParent) => {
		if (privateParent?.get('uniq_id') === selectedPrivateParent_?.uniq_id) {
			return selectedPrivateParent_?.resources?.diskspace?.free + storageSize;
		}
		return selectedPrivateParent_?.resources?.diskspace?.free;
	},
);

const maxRam = createSelector(
	optionsSelectors.selectedPrivateParent,
	assetDetailsSelectors.RAM,
	assetDetailsSelectors.privateParent,
	(selectedPrivateParent_, RAM, privateParent) => {
		if (privateParent?.get('uniq_id') === selectedPrivateParent_?.uniq_id) {
			return selectedPrivateParent_?.resources?.memory?.free + RAM;
		}
		return selectedPrivateParent_?.resources?.memory?.free;
	},
);

const selectors = {
	selectionValid,
	initialRam,
	initialCpu,
	initialDisk,
	maxDisk,
	maxRam,
	formValuesSelector,
	isLoading,
};

export { selectors, formName };
export default selectors;
