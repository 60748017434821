import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.monitoring.services.status;

const { actions, reducer, sagas, selectors } = createAPIModule({
	getStateSlice,
	actionType: 'MONITORING_SERVICES_STATUS',
	method: 'POST',
	url: '/monitoring/services/status.json',
});

export { actions, reducer, sagas, selectors };
