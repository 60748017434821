import {
	createAPIModule,
	createSelectors,
} from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';
import { List as ImmutableList } from 'immutable';
import get from 'lodash/get';
import displayName from 'utility/assetDisplayNames';
import { selectors as clusterListSelectors } from 'modules/api/storage/block/cluster/listModule';
import { selectors as volumeDetailsSelectors } from 'modules/api/storage/block/volume/detailsModule';

const getStateSlice = (state) => state.asset.list;

const moduleKeys = {
	MOCKED: 'mocked',
	REIMAGE: 'REIMAGE',
	THREAT_STACK: 'THREAT_STACK',
};

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'ASSET_LIST',
	method: 'POST',
	url: '/asset/list.json',
	isPaginated: true,
});

const getType = (asset) => {
	const categories = asset.get('categories', ImmutableList());
	if (categories.includes('StrictDedicated')) {
		return 'dedicated';
	}
	if (categories.includes('NocworxOpenstack')) {
		return 'open stack';
	}
	if (categories.includes('PrivateParent')) {
		return 'private parent';
	}
	if (categories.includes('VirtualDedicated')) {
		return 'cloud hosting';
	}
	if (categories.includes('CloudSitesList')) {
		return 'cloud sites';
	}
	if (categories.includes('ManagedApplication')) {
		return 'managed app';
	}
	return null;
};

const reimageSelectors = createSelectors(
	getStateSlice,
	false,
	moduleKeys.REIMAGE,
);

// TODO: move threatstack stuff into a threatstack file.
const threatStackSelectors = createSelectors(
	getStateSlice,
	false,
	moduleKeys.THREAT_STACK,
);

const threatStackList = createSelector(
	threatStackSelectors.getNativeData,
	(slice) => {
		return (
			slice?.items.map(({ status, uniq_id: uniqId, ancestorServer }) => {
				const {
					domain: server,
					uniq_id: ancestorUniqueId,
				} = ancestorServer || {
					domain: 'unknown',
				};
				return {
					status,
					uniqId,
					link: `/go/${ancestorUniqueId}`,
					server,
				};
			}) || []
		);
	},
);

const withType = createSelector(
	getStateSlice,
	(slice) =>
		slice
			.getIn(['data', 'items'], ImmutableList())
			.map((asset) => asset.set('assetType', getType(asset))),
);

const mockedSelectors = createSelectors(
	getStateSlice,
	false,
	moduleKeys.MOCKED,
);

// fillters assets by matching volume zone
const matchingZone = createSelector(
	defaultSelectors.getNativeData,
	clusterListSelectors.filtered,
	volumeDetailsSelectors.getNativeData,
	(assets, cluster) => {
		if (!assets || !cluster.zoneAvailability) return null;
		// eslint-disable-next-line
		return assets.items.filter((asset) => {
			const assetZone = asset.zone ? asset.zone.id : null;
			const attachable =
				get(asset, 'capabilities.volumeAttach') ||
				get(asset, 'capabilities.volumeCouldAttach');
			if (
				attachable &&
				Object.values(cluster.zoneAvailability).includes(assetZone)
			) {
				return asset;
			}
		});
	},
);
const uniqueDomains = createSelector(
	defaultSelectors.getNativeItems,
	(items) => Array.from(new Set(items?.map((item) => item.domain) || [])),
);

const selectors = {
	mockedSelectors,
	uniqueDomains,
	options: createSelector(
		defaultSelectors.getNativeItems,
		(items) => {
			return items.map((item) => ({ label: item.domain, value: item.uniq_id }));
		},
	),
	mockedItems: createSelector(
		mockedSelectors.getData,
		(data) => data && data.getIn(['items'], ImmutableList()),
	),
	withType,
	withDisplayName: createSelector(
		withType,
		(slice) =>
			slice.map((asset) =>
				asset.set(
					'display',
					displayName(asset.get('assetType'), asset.get('type')),
				),
			),
	),
	getcPanelServers: createSelector(
		getStateSlice,
		(slice) => slice.getIn(['cPanelServers', 'data', 'items']),
	),
	reimageSelectors,
	threatStackSelectors: {
		...threatStackSelectors,
		list: threatStackList,
	},
	iscPanelLoading: createSelector(
		getStateSlice,
		(slice) => slice.getIn(['cPanelServers', 'pending']) !== 0,
	),
	matchingZone,
	// filter out servers that are not currently attached to the volume
	attachable: createSelector(
		matchingZone,
		clusterListSelectors.filtered,
		volumeDetailsSelectors.getNativeData,
		(assets, cluster, volumeDetails) => {
			if (!assets) return null;
			return assets.filter((asset) => {
				let attached = false;
				// eslint-disable-next-line array-callback-return
				volumeDetails.attachedTo.map((attachedTo) => {
					if (attachedTo.resource === asset.uniq_id) {
						attached = true;
					}
				});
				return !attached;
			});
		},
	),
	// filter out servers that are currently attached to the volume
	attached: createSelector(
		matchingZone,
		clusterListSelectors.filtered,
		volumeDetailsSelectors.getNativeData,
		(assets, cluster, volumeDetails) => {
			if (!assets) return null;
			// eslint-disable-next-line
			return assets.filter((asset) => {
				let attached = false;
				// eslint-disable-next-line array-callback-return
				volumeDetails.attachedTo.map((attachedTo) => {
					if (attachedTo.resource === asset.uniq_id) {
						attached = true;
					}
				});
				if (attached) {
					return asset;
				}
			});
		},
	),
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors, moduleKeys, getType };
