const actions = {
	DISALLOWED_USER_SET_CAUSE: 'DISALLOWED_USER_SET_CAUSE',
	setCause: (cause) => ({
		type: actions.DISALLOWED_USER_SET_CAUSE,
		payload: {
			cause,
		},
	}),
};

export default actions;
