const backupsKeys = {
	quota: '52LWYF',
	daily: '7CNH4M',
	acronis: '56Q011',
	none: 'UQZ3HX',
};

const standard = {
	FYZ05N: {
		active: false,
		domain: 'Acronis Backup - FYZ05N',
		ip: '127.0.0.1',
		project_id: 100895,
		project_name: 'fizz bam',
		region_id: 3,
		status: 'New',
		type: 'AcronisBackup',
		uniq_id: 'FYZ05N',
		username: 'host1wreathofbar',
		acronisBackingUp: [
			{
				uniq_id: '805DJ0',
				domain: 'host1.wreathofbarbs.com',
			},
		],
	},
	ZLRMHZ: {
		active: true,
		domain: 'Acronis Backup - ZLRMHZ',
		ip: '127.0.0.1',
		project_id: null,
		project_name: null,
		region_id: 2,
		status: 'Active',
		type: 'AcronisBackup',
		uniq_id: 'ZLRMHZ',
		username: 'host2wreathofbar',
		acronisBackingUp: {},
	},
	CG6416: {
		active: true,
		domain: 'bundleopenstackreadonly.bundleopenstackdb.folksneedopenstack.com',
		ip: '69.160.55.118',
		project_id: 102659,
		project_name: 'New Project 3',
		region_id: 1,
		status: 'Active',
		type: 'Cloud.VM.DB',
		uniq_id: 'CG6416',
		username: 'root',
	},
	UEY7CE: {
		active: true,
		domain: 'Acronis Backup - UEY7CE',
		ip: '127.0.0.1',
		project_id: null,
		project_name: null,
		region_id: 1,
		status: 'Active',
		type: 'AcronisBackup',
		uniq_id: 'UEY7CE',
		username: 'host3wreathofbar',
	},
	UQZ3HX: {
		active: true,
		domain: 'child1.mctesterson.com',
		ip: '67.43.15.30',
		project_id: 100054,
		project_name: 'Cameron Test',
		region_id: 1,
		status: 'Active',
		type: 'SS.VPS',
		uniq_id: 'UQZ3HX',
		username: 'root',
	},
	'52LWYF': {
		active: true,
		domain: 'cpanel.mctestersons.com',
		ip: '67.43.15.16',
		project_id: null,
		project_name: null,
		region_id: 1,
		status: 'Active',
		type: 'SS.VPS',
		uniq_id: '52LWYF',
		username: 'root',
	},
	'7CNH4M': {
		active: true,
		domain:
			'paypergigbackups.testingsaldfjslkdfjdslkjfldskjflskjdfldskjfldskjfldskj.com',
		ip: '67.43.15.27',
		project_id: 100031,
		project_name: 'wiggle',
		region_id: 1,
		status: 'Active',
		type: 'SS.VPS',
		uniq_id: '7CNH4M',
		username: 'root',
	},
	XVUT00: {
		active: true,
		domain: 'vps.resoundgame.com',
		ip: '69.167.170.134',
		project_id: 102692,
		project_name: 'resound',
		region_id: 1,
		status: 'Active',
		type: 'SS.VPS',
		uniq_id: 'XVUT00',
		username: 'root',
	},
	'56Q011': {
		active: true,
		domain: 'acronis.example.com',
		ip: '67.43.15.33',
		project_id: 100031,
		project_name: 'wiggle',
		region_id: 1,
		status: 'Active',
		type: 'SS.VPS',
		uniq_id: '56Q011',
		username: 'root',
	},
	'4KP25A': {
		active: true,
		domain: 'test.bghupdps3kcmyav6q0u11wnt0z02ogwd.com',
		ip: '127.0.0.1',
		project_id: 100054,
		project_name: 'Cameron Test',
		region_id: 1,
		status: 'Active',
		type: 'DS.MinimalForTests',
		uniq_id: '4KP25A',
		username: 'root',
	},
	'424QV0': {
		active: true,
		domain: 'Acronis Backup - 424QV0',
		ip: '127.0.0.1',
		project_id: null,
		project_name: null,
		region_id: 1,
		status: 'Active',
		type: 'AcronisBackup',
		uniq_id: '424QV0',
		username: 'testbghupdps3kcm',
	},
	WFTN6D: {
		active: true,
		domain: 'fs.andthenanotherthinghappened.com',
		ip: '69.160.55.120',
		project_id: 102426,
		project_name: 'New Project 1',
		region_id: 1,
		status: 'Active',
		type: 'Cloud.VM.FS',
		uniq_id: 'WFTN6D',
		username: 'root',
	},
};

const alsowithData = {
	acronisBackingUp: {
		UEY7CE: [
			{
				uniq_id: 'H0HMPM',
				domain: 'host3.wreathofbarbs.com',
			},
		],
		'424QV0': [
			{
				accnt: 3378300,
				active: 1,
				activeStatus: 'Active',
				bucket_uniq_id: null,
				domain: 'test.bghupdps3kcmyav6q0u11wnt0z02ogwd.com',
				id: '11986563',
				ip: '127.0.0.1',
				paccnt: 3378300,
				password: 'password',
				project_name: 'Cameron Test',
				region_id: 1,
				relid: null,
				salesforce_asset: '02i2F000003L1XcQAK',
				server: null,
				status: 'Active',
				subaccnt: '11986563',
				type: 'DS.MinimalForTests',
				uniq_id: '4KP25A',
				username: 'root',
			},
		],
	},
	featureDetails: {
		'4KP25A': {
			Bandwidth: {
				capabilities: {},
				comments: 'DS.MinimalForTests->Bandwidth:11000GB',
				cycle: 'static-month',
				description: '11000GB Monthly Transfer',
				group_display_order: 12,
				id: 37948582,
				num_units: null,
				option_key_display_text: 'Bandwidth',
				parent_id: null,
				price: '0.00000',
				pricing: {
					hour: '0.00000',
					month: '0.00',
				},
				value: '11000GB',
			},
			BandwidthOverage: {
				capabilities: {},
				comments: 'DS.MinimalForTests->BandwidthOverage:1GB',
				cycle: 'static-nontemporal',
				description: '%{units} GB Overage',
				group_display_order: 66,
				id: 37948583,
				num_units: -1,
				option_key_display_text: 'Bandwidth Overage',
				parent_id: null,
				price: '0.25000',
				pricing: {
					nontemporal: '0.00',
				},
				value: '1GB',
			},
			ControlPanel: {
				capabilities: {
					cpanelLogin: 1,
					serviceMonitoringCpanel: 1,
				},
				comments: 'DS.MinimalForTests->ControlPanel:cPanel',
				cycle: 'month',
				description: 'Fully Managed - cPanel',
				group_display_order: 10,
				id: 37948578,
				num_units: null,
				option_key_display_text: 'Management & Control Panel',
				parent_id: null,
				price: '20.00000',
				pricing: {
					hour: '0.02741',
					month: '20.00',
				},
				value: 'cPanel',
			},
			Decoration: {
				capabilities: {},
				comments: 'DS.MinimalForTests->Decoration:Stickers',
				cycle: 'month',
				description: 'Cool stickers',
				group_display_order: 98,
				id: 37948584,
				num_units: 0,
				option_key_display_text: 'Server prettification elements',
				parent_id: null,
				price: '1.00000',
				pricing: {
					hour: '0.00000',
					month: '0.00',
				},
				value: 'Stickers',
			},
			Fantastico: {
				capabilities: {},
				comments: null,
				cycle: 'month',
				description: 'Softaculous',
				group_display_order: 1,
				id: 37948580,
				num_units: null,
				option_key_display_text: 'Fantastico',
				parent_id: 37948578,
				price: '0.00000',
				pricing: {
					hour: '0.00000',
					month: '0.00',
				},
				value: 'Softaculous',
			},
			Processor: {
				capabilities: {},
				comments: 'DS.MinimalForTests->Processor:AMD.4x8.6128.6cores.4sockets',
				cycle: 'month',
				description:
					'AMD Quad x 8 CORES 2.0Ghz Opteron 6128 - Total of 32 CORES!',
				group_display_order: 1,
				id: 37948576,
				num_units: null,
				option_key_display_text: 'Processor',
				parent_id: null,
				price: '0.00000',
				pricing: {
					hour: '0.00000',
					month: '0.00',
				},
				value: 'AMD.4x8.6128.6cores.4sockets',
			},
			RAM: {
				capabilities: {},
				comments: 'DS.MinimalForTests->RAM:8GBDDR3',
				cycle: 'month',
				description: '8GB DDR3 SDRAM',
				group_display_order: 2,
				id: 37948577,
				num_units: null,
				option_key_display_text: 'Memory',
				parent_id: null,
				price: '0.00000',
				pricing: {
					hour: '0.00000',
					month: '0.00',
				},
				value: '8GBDDR3',
			},
			ServerSecure: {
				capabilities: {},
				comments: null,
				cycle: 'month',
				description: 'ServerSecure enabled',
				group_display_order: 2,
				id: 37948581,
				num_units: null,
				option_key_display_text: 'ServerSecure',
				parent_id: 37948578,
				price: '0.00000',
				pricing: {
					hour: '0.00000',
					month: '0.00',
				},
				value: '1',
			},
			cPanelLicenseTier: {
				capabilities: {},
				comments: null,
				cycle: 'month',
				description: 'Premier Fixed 100',
				group_display_order: 0,
				id: 37948579,
				num_units: null,
				option_key_display_text: 'cPanel License Tier',
				parent_id: 37948578,
				price: '35.00000',
				pricing: {
					hour: '0.04797',
					month: '35.00',
				},
				value: 'premier_metal_100',
			},
		},
		CG6416: {
			Bandwidth: {
				capabilities: {},
				comments: 'Cloud.VM.DB->Bandwidth:OBWtenG.10000',
				cycle: 'month',
				description: '10 TB Monthly Transfer',
				group_display_order: 80,
				id: 40611167,
				num_units: null,
				option_key_display_text: 'Bandwidth',
				parent_id: null,
				price: '0.00000',
				pricing: {
					hour: '0.00000',
					month: '0.00',
				},
				value: 'OBWtenG.10000',
			},
			BandwidthOverage: {
				capabilities: {},
				comments: 'Cloud.VM.DB->BandwidthOverage:1GB',
				cycle: 'month',
				description: '%{units} GB Overage',
				group_display_order: 90,
				id: 40611160,
				num_units: -1,
				option_key_display_text: 'Bandwidth Overage',
				parent_id: null,
				price: '0.00000',
				pricing: {
					hour: '0.00000',
					month: '0.00',
				},
				value: '1GB',
			},
			CloudCompute: {
				capabilities: {},
				comments: null,
				cycle: 'month',
				description: '4 Core CPU / 8 GB Memory',
				group_display_order: 10,
				id: 40611162,
				num_units: null,
				option_key_display_text: 'Compute',
				parent_id: 40611161,
				price: '0.00000',
				pricing: {
					hour: '0.00000',
					month: '0.00',
				},
				value: 'computeDB8',
			},
			CloudFlavor: {
				capabilities: {
					nocworxFlavor: 1,
				},
				comments: 'Cloud.VM.DB->CloudFlavor:l7b-mh-1.db8',
				cycle: 'month',
				description: 'OpenStack DB8',
				group_display_order: 20,
				id: 40611161,
				num_units: null,
				option_key_display_text: 'Flavor',
				parent_id: null,
				price: '0.00000',
				pricing: {
					hour: '0.00000',
					month: '0.00',
				},
				value: 'l7b-mh-1.db8',
			},
			CloudFlavorCPU: {
				capabilities: {
					nocworxCPU: 1,
				},
				comments: null,
				cycle: 'month',
				description: '4 Core',
				group_display_order: 10,
				id: 40611164,
				num_units: null,
				option_key_display_text: 'CPU',
				parent_id: 40611162,
				price: '0.00000',
				pricing: {
					hour: '0.00000',
					month: '0.00',
				},
				value: '4',
			},
			CloudFlavorMemory: {
				capabilities: {
					nocworxMemory: 1,
				},
				comments: null,
				cycle: 'month',
				description: '8 GB',
				group_display_order: 20,
				id: 40611163,
				num_units: null,
				option_key_display_text: 'Memory',
				parent_id: 40611162,
				price: '0.00000',
				pricing: {
					hour: '0.00000',
					month: '0.00',
				},
				value: '8',
			},
			CloudFlavorStorageType: {
				capabilities: {
					nocworxVolumeType: 1,
					nocworxVolumeTypeLocal: 1,
				},
				comments: null,
				cycle: 'month',
				description: 'Local Storage',
				group_display_order: 30,
				id: 40611165,
				num_units: null,
				option_key_display_text: 'Storage Type',
				parent_id: 40611161,
				price: '0.00000',
				pricing: {
					hour: '0.00000',
					month: '0.00',
				},
				value: 'localDB8',
			},
			CloudImage: {
				capabilities: {
					nocworxImage: 1,
				},
				comments: null,
				cycle: 'month',
				description: 'CentOS 7',
				group_display_order: 10,
				id: 40611156,
				num_units: null,
				option_key_display_text: 'Image',
				parent_id: 40611155,
				price: '0.00000',
				pricing: {
					hour: '0.00000',
					month: '0.00',
				},
				value: 'centos-7-x86_64-latest',
			},
			CloudManagement: {
				capabilities: {},
				comments: 'Cloud.VM.DB->CloudManagement:fullDB',
				cycle: 'month',
				description: 'Fully Managed',
				group_display_order: 30,
				id: 40611155,
				num_units: null,
				option_key_display_text: 'Management',
				parent_id: null,
				price: '0.00000',
				pricing: {
					hour: '0.00000',
					month: '0.00',
				},
				value: 'fullDB',
			},
			CloudRole: {
				capabilities: {
					nocworxRole: 1,
				},
				comments: 'Cloud.VM.DB->CloudRole:db',
				cycle: 'month',
				description: 'Database',
				group_display_order: 10,
				id: 40611159,
				num_units: null,
				option_key_display_text: 'Role',
				parent_id: null,
				price: '0.00000',
				pricing: {
					hour: '0.00000',
					month: '0.00',
				},
				value: 'db',
			},
			CloudStorageSize: {
				capabilities: {
					nocworxVolumeSize: 1,
					nocworxVolumeSizeLocal: 1,
				},
				comments: null,
				cycle: 'month',
				description: '80 GB',
				group_display_order: 10,
				id: 40611166,
				num_units: null,
				option_key_display_text: 'Storage Size',
				parent_id: 40611165,
				price: '10.00000',
				pricing: {
					hour: '0.01371',
					month: '10.00',
				},
				value: '80',
			},
			ControlPanel: {
				capabilities: {
					cpanelLogin: 1,
					serviceMonitoringCpanel: 1,
					whmLogin: 1,
				},
				comments: null,
				cycle: 'month',
				description: 'cPanel/WHM',
				group_display_order: 20,
				id: 40611157,
				num_units: null,
				option_key_display_text: 'Management & Control Panel',
				parent_id: 40611155,
				price: '0.00000',
				pricing: {
					hour: '0.00000',
					month: '0.00',
				},
				value: 'cPanelVM',
			},
			ExtraIp: {
				capabilities: {
					nocworxPublicIP: 1,
				},
				comments: 'Cloud.VM.DB->ExtraIp:1',
				cycle: 'month',
				description: '%{units} Additional Public IPs',
				group_display_order: 70,
				id: 40611169,
				num_units: 0,
				option_key_display_text: 'Extra IP Addresses',
				parent_id: null,
				price: '0.00000',
				pricing: {
					hour: '0.00000',
					month: '0.00',
				},
				value: '1',
			},
			PortSpeed: {
				capabilities: {},
				comments: null,
				cycle: 'month',
				description: '10 Gigabit Public and Private Connections',
				group_display_order: 10,
				id: 40611168,
				num_units: null,
				option_key_display_text: 'Port Speed',
				parent_id: 40611167,
				price: '0.00000',
				pricing: {
					hour: '0.00000',
					month: '0.00',
				},
				value: '10gOS',
			},
			cPanelLicenseTier: {
				capabilities: {},
				comments: null,
				cycle: 'month',
				description: 'Admin',
				group_display_order: 10,
				id: 40611158,
				num_units: null,
				option_key_display_text: 'cPanel License Tier',
				parent_id: 40611157,
				price: '0.00000',
				pricing: {
					hour: '0.00000',
					month: '0.00',
				},
				value: 'admin_cloud_5',
			},
		},
		'424QV0': {
			AcronisStorageDestination: {
				capabilities: {
					acronisStorageDestinationLiquidWeb: 1,
				},
				comments:
					'AcronisBackup->AcronisStorageDestination:AcronisLiquidWebStorage',
				cycle: 'month',
				description: 'Liquid Web',
				group_display_order: 10,
				id: 39398415,
				num_units: null,
				option_key_display_text: 'Backup Storage Destination',
				parent_id: null,
				price: '0.00000',
				pricing: {
					hour: '0.00000',
					month: '0.00',
				},
				value: 'AcronisLiquidWebStorage',
			},
			AcronisStorageQuotaLiquidWeb: {
				capabilities: {},
				comments: null,
				cycle: 'month',
				description: '750 GB',
				group_display_order: 1,
				id: 39398416,
				num_units: null,
				option_key_display_text: 'Storage Quota',
				parent_id: 39398415,
				price: '50.00000',
				pricing: {
					hour: '0.06853',
					month: '50.00',
				},
				value: '750',
			},
		},
		UEY7CE: {
			AcronisStorageDestination: {
				capabilities: { acronisStorageDestinationAcronis: 1 },
				comments:
					'AcronisBackup->AcronisStorageDestination:AcronisCloudStorage',
				cycle: 'month',
				description: 'Acronis Cloud',
				group_display_order: 10,
				id: 37908810,
				num_units: null,
				option_key_display_text: 'Backup Storage Destination',
				parent_id: null,
				price: '0.00000',
				pricing: { hour: '0.00000', month: '0.00' },
				value: 'AcronisCloudStorage',
			},
			AcronisStorageQuotaCloud: {
				capabilities: {},
				comments: null,
				cycle: 'month',
				description: '100 GB',
				group_display_order: 1,
				id: 39819688,
				num_units: null,
				option_key_display_text: 'Storage Quota',
				parent_id: 37908810,
				price: '15.00000',
				pricing: { hour: '0.02056', month: '15.00' },
				value: '100',
			},
		},
		UQZ3HX: {
			Bandwidth: {
				capabilities: {},
				comments: 'SS.VPS->Bandwidth:SS.20000',
				cycle: 'static-month',
				description: '20 TB Monthly Transfer',
				group_display_order: 3,
				id: 37777548,
				num_units: null,
				option_key_display_text: 'Bandwidth',
				parent_id: null,
				price: '100.00000',
				pricing: {
					hour: '0.13706',
					month: '100.00',
				},
				value: 'SS.20000',
			},
			BandwidthOverage: {
				capabilities: {},
				comments: 'SS.VPS->BandwidthOverage:1GB',
				cycle: 'nontemporal',
				description: '%{units} GB Overage',
				group_display_order: 4,
				id: 37762309,
				num_units: -1,
				option_key_display_text: 'Bandwidth Overage',
				parent_id: null,
				price: '0.25000',
				pricing: {
					nontemporal: '0.00',
				},
				value: '1GB',
			},
			ConfigId: {
				active: 1,
				capabilities: {},
				comments: 'SS.VPS->ConfigId:0',
				cycle: 'month',
				description: 'Dynamically defined config',
				disk: 15,
				group_display_order: 1,
				id: 37762307,
				memory: 512,
				num_units: null,
				option_key_display_text: 'Server Type',
				parent_id: null,
				price: '0.00000',
				pricing: {
					hour: '0.00000',
					month: '0.00',
				},
				value: '0',
				vcpu: 1,
				zone_availability: {
					'40460': 1,
				},
			},
			ExtraIp: {
				capabilities: {},
				comments: 'SS.VPS->ExtraIp:1',
				cycle: 'month',
				description: '%{units} Additional Public IPs',
				group_display_order: 6,
				id: 40968128,
				num_units: 9,
				option_key_display_text: 'Extra IP Addresses',
				parent_id: null,
				price: '1.00000',
				pricing: {
					hour: '0.01234',
					month: '9.00',
				},
				value: '1',
			},
			LiquidWebBackupPlan: {
				capabilities: {},
				comments: 'SS.VPS->LiquidWebBackupPlan:None',
				cycle: 'month',
				description: 'No Backups',
				group_display_order: 5,
				id: 38720706,
				num_units: null,
				option_key_display_text: 'Backup Plan',
				parent_id: null,
				price: '0.00000',
				pricing: {
					hour: '0.00000',
					month: '0.00',
				},
				value: 'None',
			},
			Template: {
				active: 1,
				capabilities: {
					ATSLogin: 0,
					migratableDestination: 0,
					serviceMonitoringSelfManaged: 1,
				},
				comments: 'SS.VPS->Template:CENTOS_7_UNMANAGED',
				cycle: 'month',
				deprecated: 0,
				description: 'CentOS 7 64-bit Self-managed',
				group_display_order: 2,
				id: 37762303,
				num_units: null,
				option_key_display_text: 'Server Type',
				os: 'LinuxCentOS',
				os_license: null,
				parent_id: null,
				price: '0.00000',
				pricing: {
					hour: '0.00000',
					month: '0.00',
				},
				support_level: 'Self-Managed',
				value: 'CENTOS_7_UNMANAGED',
				zone_availability: {
					'40460': 1,
					'45412': 1,
				},
			},
		},
		'52LWYF': {
			BackupQuota: {
				capabilities: {},
				comments: null,
				cycle: 'month',
				description: '50 GB',
				group_display_order: 1,
				id: 39091575,
				num_units: null,
				option_key_display_text: 'Quota Pricing',
				parent_id: 39091574,
				price: '0.00000',
				pricing: {
					hour: '0.00000',
					month: '0.00',
				},
				value: '50',
			},
			Bandwidth: {
				capabilities: {},
				comments: 'SS.VPS->Bandwidth:SS.10000',
				cycle: 'static-month',
				description: '10 TB Monthly Transfer',
				group_display_order: 3,
				id: 40742810,
				num_units: null,
				option_key_display_text: 'Bandwidth',
				parent_id: null,
				price: '0.00000',
				pricing: {
					hour: '0.00000',
					month: '0.00',
				},
				value: 'SS.10000',
			},
			BandwidthOverage: {
				capabilities: {},
				comments: 'SS.VPS->BandwidthOverage:1GB',
				cycle: 'nontemporal',
				description: '%{units} GB Overage',
				group_display_order: 4,
				id: 39091577,
				num_units: -1,
				option_key_display_text: 'Bandwidth Overage',
				parent_id: null,
				price: '0.35000',
				pricing: {
					nontemporal: '0.00',
				},
				value: '1GB',
			},
			ConfigId: {
				active: 1,
				capabilities: {},
				comments: 'SS.VPS->ConfigId:0',
				cycle: 'month',
				description: 'Dynamically defined config',
				disk: 100,
				group_display_order: 1,
				id: 39091579,
				memory: 4096,
				num_units: null,
				option_key_display_text: 'Server Type',
				parent_id: null,
				price: '0.00000',
				pricing: {
					hour: '0.00000',
					month: '0.00',
				},
				value: '0',
				vcpu: 4,
				zone_availability: {
					'40460': 1,
				},
			},
			ExtraIp: {
				capabilities: {},
				comments: 'SS.VPS->ExtraIp:1',
				cycle: 'month',
				description: '%{units} Additional Public IPs',
				group_display_order: 6,
				id: 40616394,
				num_units: 9,
				option_key_display_text: 'Extra IP Addresses',
				parent_id: null,
				price: '1.00000',
				pricing: {
					hour: '0.01234',
					month: '9.00',
				},
				value: '1',
			},
			LiquidWebBackupPlan: {
				capabilities: {},
				comments: 'SS.VPS->LiquidWebBackupPlan:Quota',
				cycle: 'month',
				description: 'Quota-based Backups',
				group_display_order: 5,
				id: 39091574,
				num_units: null,
				option_key_display_text: 'Backup Plan',
				parent_id: null,
				price: '0.00000',
				pricing: {
					hour: '0.00000',
					month: '0.00',
				},
				value: 'Quota',
			},
			Template: {
				active: 1,
				capabilities: {
					cpanelLogin: 1,
					serviceMonitoringCpanel: 1,
					whmLogin: 1,
				},
				comments: 'SS.VPS->Template:CENTOS_7_CPANEL',
				cycle: 'month',
				deprecated: 0,
				description: 'CentOS 7 64-bit with cPanel Fully-managed',
				extra_software: 'Cpanel',
				group_display_order: 2,
				id: 39091576,
				num_units: null,
				option_key_display_text: 'Server Type',
				os: 'LinuxCentOS',
				os_license: null,
				parent_id: null,
				price: '15.00000',
				pricing: {
					hour: '0.02056',
					month: '15.00',
				},
				support_level: 'Fully-Managed',
				value: 'CENTOS_7_CPANEL',
				zone_availability: {
					'40460': 1,
					'45412': 1,
					'86172': 1,
				},
			},
			cPanelLicenseTier: {
				capabilities: {},
				comments: 'SS.VPS->cPanelLicenseTier:plus_cloud_50',
				cycle: 'month',
				description: 'Plus',
				group_display_order: 0,
				id: 41802742,
				num_units: null,
				option_key_display_text: 'cPanel License Tier',
				parent_id: null,
				price: '10.00000',
				pricing: {
					hour: '0.01371',
					month: '10.00',
				},
				value: 'plus_cloud_50',
			},
		},
		'7CNH4M': {
			BackupDay: {
				capabilities: {},
				comments: null,
				cycle: 'month',
				description: '%{units} Retention Days',
				group_display_order: 2,
				id: 37641212,
				num_units: 7,
				option_key_display_text: 'Backup Days',
				parent_id: 37641211,
				price: '0.00000',
				pricing: {
					hour: '0.00000',
					month: '0.00',
				},
				value: '1',
			},
			BackupStorage: {
				capabilities: {},
				comments: null,
				cycle: 'hour',
				description: '%{units} GB Hours',
				group_display_order: 1,
				id: 37641213,
				num_units: -1,
				option_key_display_text: 'Backup Storage',
				parent_id: 37641211,
				price: '0.00016',
				pricing: {
					hour: '0.00000',
					month: '0.00',
				},
				value: '1GBHR',
			},
			Bandwidth: {
				capabilities: {},
				comments: 'SS.VPS->Bandwidth:SS.20000',
				cycle: 'static-month',
				description: '20 TB Monthly Transfer',
				group_display_order: 3,
				id: 37852200,
				num_units: null,
				option_key_display_text: 'Bandwidth',
				parent_id: null,
				price: '100.00000',
				pricing: {
					hour: '0.13706',
					month: '100.00',
				},
				value: 'SS.20000',
			},
			BandwidthOverage: {
				capabilities: {},
				comments: 'SS.VPS->BandwidthOverage:1GB',
				cycle: 'nontemporal',
				description: '%{units} GB Overage',
				group_display_order: 4,
				id: 37641210,
				num_units: -1,
				option_key_display_text: 'Bandwidth Overage',
				parent_id: null,
				price: '0.25000',
				pricing: {
					nontemporal: '0.00',
				},
				value: '1GB',
			},
			ConfigId: {
				active: 1,
				available: 27,
				capabilities: {},
				category: 'ssd',
				comments: 'SS.VPS->ConfigId:4',
				cpu_cores: 1,
				cycle: 'month',
				description: 'VPS 2000 Plus',
				disk: 25,
				group_display_order: 1,
				id: 37641208,
				memory: 440,
				num_units: null,
				option_key_display_text: 'Server Type',
				parent_id: null,
				price: '1.00000',
				pricing: {
					hour: '0.00137',
					month: '1.00',
				},
				value: '4',
				zone_availability: {
					'40460': 27,
				},
			},
			ExtraIp: {
				capabilities: {},
				comments: 'SS.VPS->ExtraIp:1',
				cycle: 'month',
				description: '%{units} Additional Public IPs',
				group_display_order: 6,
				id: 37953969,
				num_units: 7,
				option_key_display_text: 'Extra IP Addresses',
				parent_id: null,
				price: '1.00000',
				pricing: {
					hour: '0.00959',
					month: '7.00',
				},
				value: '1',
			},
			LiquidWebBackupPlan: {
				capabilities: {},
				comments: 'SS.VPS->LiquidWebBackupPlan:Daily',
				cycle: 'month',
				description: 'As-you-go Backups',
				group_display_order: 5,
				id: 37641211,
				num_units: null,
				option_key_display_text: 'Backup Plan',
				parent_id: null,
				price: '0.00000',
				pricing: {
					hour: '0.00000',
					month: '0.00',
				},
				value: 'Daily',
			},
			Template: {
				active: 1,
				capabilities: {
					cpanelLogin: 1,
					serviceMonitoringCpanel: 1,
				},
				comments: 'SS.VPS->Template:CENTOS_7_CPANEL_CLOUDLINUX',
				cycle: 'month',
				deprecated: 0,
				description: 'CentOS 7 64-bit cPanel w/CloudLinux',
				extra_software: 'Cpanel',
				group_display_order: 2,
				id: 38878414,
				num_units: null,
				option_key_display_text: 'Server Type',
				os: 'LinuxCloudLinux',
				os_license: null,
				parent_id: null,
				price: '15.00000',
				pricing: {
					hour: '0.02056',
					month: '15.00',
				},
				support_level: 'Fully-Managed',
				value: 'CENTOS_7_CPANEL_CLOUDLINUX',
				zone_availability: {
					'40460': 1,
					'45412': 1,
					'86172': 1,
				},
			},
			cPanelLicenseTier: {
				capabilities: {},
				comments: 'SS.VPS->cPanelLicenseTier:admin_cloud_5',
				cycle: 'month',
				description: 'Admin',
				group_display_order: 0,
				id: 37641215,
				num_units: null,
				option_key_display_text: 'cPanel License Tier',
				parent_id: null,
				price: '0.00000',
				pricing: {
					hour: '0.00000',
					month: '0.00',
				},
				value: 'admin_cloud_5',
			},
		},
		XVUT00: {
			BackupQuota: {
				capabilities: {},
				comments: null,
				cycle: 'month',
				description: '100 GB',
				group_display_order: 1,
				id: 5745895,
				num_units: null,
				option_key_display_text: 'Quota Pricing',
				parent_id: 5745894,
				price: '0.00000',
				pricing: {
					hour: '0.00000',
					month: '0.00',
				},
				value: '100',
			},
			Bandwidth: {
				capabilities: {},
				comments: 'SS.VPS->Bandwidth:SS.10000',
				cycle: 'static-month',
				description: '10 TB Monthly Transfer',
				group_display_order: 3,
				id: 5745900,
				num_units: null,
				option_key_display_text: 'Bandwidth',
				parent_id: null,
				price: '0.00000',
				pricing: {
					hour: '0.00000',
					month: '0.00',
				},
				value: 'SS.10000',
			},
			BandwidthOverage: {
				capabilities: {},
				comments: 'SS.VPS->BandwidthOverage:1GB',
				cycle: 'nontemporal',
				description: '%{units} GB Overage',
				group_display_order: 4,
				id: 5745898,
				num_units: -1,
				option_key_display_text: 'Bandwidth Overage',
				parent_id: null,
				price: '0.15000',
				pricing: {
					nontemporal: '0.00',
				},
				value: '1GB',
			},
			ConfigId: {
				active: 1,
				available: 790,
				capabilities: {},
				category: 'ssd',
				comments: 'SS.VPS->ConfigId:1435',
				cpu_cores: 4,
				cycle: 'month',
				description: '4GB - SSD',
				disk: 100,
				group_display_order: 1,
				id: 5745896,
				memory: 4000,
				num_units: null,
				option_key_display_text: 'Server Type',
				parent_id: null,
				price: '89.00000',
				pricing: {
					hour: '0.12198',
					month: '89.00',
				},
				value: '1435',
				zone_availability: {
					'12': 110,
					'15': 253,
					'26': 266,
					'27': 113,
					'28': 48,
					'99': 0,
				},
			},
			ExtraIp: {
				capabilities: {},
				comments: 'SS.VPS->ExtraIp:1',
				cycle: 'month',
				description: '%{units} Additional Public IPs',
				group_display_order: 6,
				id: 5745897,
				num_units: 0,
				option_key_display_text: 'Public IP Addresses',
				parent_id: null,
				price: '2.00000',
				pricing: {
					hour: '0.00000',
					month: '0.00',
				},
				value: '1',
			},
			LiquidWebBackupPlan: {
				capabilities: {},
				comments: 'SS.VPS->LiquidWebBackupPlan:Quota',
				cycle: 'month',
				description: 'Quota-based Backups',
				group_display_order: 5,
				id: 5745894,
				num_units: null,
				option_key_display_text: 'Backup Plan',
				parent_id: null,
				price: '0.00000',
				pricing: {
					hour: '0.00000',
					month: '0.00',
				},
				value: 'Quota',
			},
			Template: {
				active: 1,
				capabilities: {
					interworxLogin: 1,
					serviceMonitoringDns: 0,
					serviceMonitoringInternalDns: 0,
					serviceMonitoringInternalInterworx: 1,
					serviceMonitoringInterworx: 1,
				},
				comments: 'SS.VPS->Template:CENTOS_7_INTERWORX',
				cycle: 'month',
				deprecated: 0,
				description: 'CentOS 7 64-bit with Interworx Fully-managed',
				extra_software: 'Interworx',
				group_display_order: 2,
				id: 5745899,
				num_units: null,
				option_key_display_text: 'Server Type',
				os: 'LinuxCentOS',
				os_license: null,
				parent_id: null,
				price: '10.00000',
				pricing: {
					hour: '0.01371',
					month: '10.00',
				},
				support_level: 'Fully-Managed',
				value: 'CENTOS_7_INTERWORX',
				zone_availability: {
					'12': 1,
					'15': 1,
					'26': 1,
					'27': 1,
					'28': 1,
				},
			},
		},
		'56Q011': {
			Bandwidth: {
				capabilities: {},
				comments: 'SS.VPS->Bandwidth:SS.20000',
				cycle: 'static-month',
				description: '20 TB Monthly Transfer',
				group_display_order: 3,
				id: 37777548,
				num_units: null,
				option_key_display_text: 'Bandwidth',
				parent_id: null,
				price: '100.00000',
				pricing: {
					hour: '0.13706',
					month: '100.00',
				},
				value: 'SS.20000',
			},
			BandwidthOverage: {
				capabilities: {},
				comments: 'SS.VPS->BandwidthOverage:1GB',
				cycle: 'nontemporal',
				description: '%{units} GB Overage',
				group_display_order: 4,
				id: 37762309,
				num_units: -1,
				option_key_display_text: 'Bandwidth Overage',
				parent_id: null,
				price: '0.25000',
				pricing: {
					nontemporal: '0.00',
				},
				value: '1GB',
			},
			ConfigId: {
				active: 1,
				capabilities: {},
				comments: 'SS.VPS->ConfigId:0',
				cycle: 'month',
				description: 'Dynamically defined config',
				disk: 15,
				group_display_order: 1,
				id: 37762307,
				memory: 512,
				num_units: null,
				option_key_display_text: 'Server Type',
				parent_id: null,
				price: '0.00000',
				pricing: {
					hour: '0.00000',
					month: '0.00',
				},
				value: '0',
				vcpu: 1,
				zone_availability: {
					'40460': 1,
				},
			},
			ExtraIp: {
				capabilities: {},
				comments: 'SS.VPS->ExtraIp:1',
				cycle: 'month',
				description: '%{units} Additional Public IPs',
				group_display_order: 6,
				id: 40968128,
				num_units: 9,
				option_key_display_text: 'Extra IP Addresses',
				parent_id: null,
				price: '1.00000',
				pricing: {
					hour: '0.01234',
					month: '9.00',
				},
				value: '1',
			},
			LiquidWebBackupPlan: {
				capabilities: {},
				comments: 'SS.VPS->LiquidWebBackupPlan:None',
				cycle: 'month',
				description: 'No Backups',
				group_display_order: 5,
				id: 38720706,
				num_units: null,
				option_key_display_text: 'Backup Plan',
				parent_id: null,
				price: '0.00000',
				pricing: {
					hour: '0.00000',
					month: '0.00',
				},
				value: 'None',
			},
			Template: {
				active: 1,
				capabilities: {
					ATSLogin: 0,
					migratableDestination: 0,
					serviceMonitoringSelfManaged: 1,
				},
				comments: 'SS.VPS->Template:CENTOS_7_UNMANAGED',
				cycle: 'month',
				deprecated: 0,
				description: 'CentOS 7 64-bit Self-managed',
				group_display_order: 2,
				id: 37762303,
				num_units: null,
				option_key_display_text: 'Server Type',
				os: 'LinuxCentOS',
				os_license: null,
				parent_id: null,
				price: '0.00000',
				pricing: {
					hour: '0.00000',
					month: '0.00',
				},
				support_level: 'Self-Managed',
				value: 'CENTOS_7_UNMANAGED',
				zone_availability: {
					'40460': 1,
					'45412': 1,
				},
			},
		},
		WFTN6D: {
			Bandwidth: {
				capabilities: {},
				comments: 'Cloud.VM.FS->Bandwidth:OBWtenG.10000',
				cycle: 'month',
				description: '10 TB Monthly Transfer',
				group_display_order: 80,
				id: 40475127,
				num_units: null,
				option_key_display_text: 'Bandwidth',
				parent_id: null,
				price: '0.00000',
				pricing: {
					hour: '0.00000',
					month: '0.00',
				},
				value: 'OBWtenG.10000',
			},
			BandwidthOverage: {
				capabilities: {},
				comments: 'Cloud.VM.FS->BandwidthOverage:1GB',
				cycle: 'month',
				description: '%{units} GB Overage',
				group_display_order: 90,
				id: 40475139,
				num_units: -1,
				option_key_display_text: 'Bandwidth Overage',
				parent_id: null,
				price: '0.00000',
				pricing: {
					hour: '0.00000',
					month: '0.00',
				},
				value: '1GB',
			},
			CloudCompute: {
				capabilities: {},
				comments: null,
				cycle: 'month',
				description: '8 Core CPU / 16 GB Memory',
				group_display_order: 10,
				id: 43202565,
				num_units: null,
				option_key_display_text: 'Compute',
				parent_id: 43202562,
				price: '0.00000',
				pricing: {
					hour: '0.00000',
					month: '0.00',
				},
				value: 'computeFS16',
			},
			CloudFlavor: {
				capabilities: {
					nocworxFlavor: 1,
				},
				comments: 'Cloud.VM.FS->CloudFlavor:l7b-mh-1.fs16',
				cycle: 'month',
				description: 'OpenStack FS16',
				group_display_order: 20,
				id: 43202562,
				num_units: null,
				option_key_display_text: 'Flavor',
				parent_id: null,
				price: '47.00000',
				pricing: {
					hour: '0.06442',
					month: '47.00',
				},
				value: 'l7b-mh-1.fs16',
			},
			CloudFlavorCPU: {
				capabilities: {
					nocworxCPU: 1,
				},
				comments: null,
				cycle: 'month',
				description: '8 Core',
				group_display_order: 10,
				id: 43202566,
				num_units: null,
				option_key_display_text: 'CPU',
				parent_id: 43202565,
				price: '0.00000',
				pricing: {
					hour: '0.00000',
					month: '0.00',
				},
				value: '8',
			},
			CloudFlavorMemory: {
				capabilities: {
					nocworxMemory: 1,
				},
				comments: null,
				cycle: 'month',
				description: '16 GB',
				group_display_order: 20,
				id: 43202567,
				num_units: null,
				option_key_display_text: 'Memory',
				parent_id: 43202565,
				price: '0.00000',
				pricing: {
					hour: '0.00000',
					month: '0.00',
				},
				value: '16',
			},
			CloudFlavorStorageType: {
				capabilities: {
					nocworxVolumeType: 1,
					nocworxVolumeTypeLocal: 1,
				},
				comments: null,
				cycle: 'month',
				description: 'Local Storage',
				group_display_order: 30,
				id: 43202563,
				num_units: null,
				option_key_display_text: 'Storage Type',
				parent_id: 43202562,
				price: '0.00000',
				pricing: {
					hour: '0.00000',
					month: '0.00',
				},
				value: 'localFS16',
			},
			CloudImage: {
				capabilities: {
					nocworxImage: 1,
				},
				comments: null,
				cycle: 'month',
				description: 'CentOS 7',
				group_display_order: 10,
				id: 40475131,
				num_units: null,
				option_key_display_text: 'Image',
				parent_id: 40475130,
				price: '0.00000',
				pricing: {
					hour: '0.00000',
					month: '0.00',
				},
				value: 'centos-7-x86_64-latest',
			},
			CloudManagement: {
				capabilities: {},
				comments: 'Cloud.VM.FS->CloudManagement:coreFS',
				cycle: 'month',
				description: 'Core Managed',
				group_display_order: 30,
				id: 40475130,
				num_units: null,
				option_key_display_text: 'Management',
				parent_id: null,
				price: '0.00000',
				pricing: {
					hour: '0.00000',
					month: '0.00',
				},
				value: 'coreFS',
			},
			CloudRole: {
				capabilities: {
					nocworxRole: 1,
				},
				comments: 'Cloud.VM.FS->CloudRole:fs',
				cycle: 'month',
				description: 'File Server',
				group_display_order: 10,
				id: 40475132,
				num_units: null,
				option_key_display_text: 'Role',
				parent_id: null,
				price: '0.00000',
				pricing: {
					hour: '0.00000',
					month: '0.00',
				},
				value: 'fs',
			},
			CloudStorageSize: {
				capabilities: {
					nocworxVolumeSize: 1,
					nocworxVolumeSizeLocal: 1,
				},
				comments: null,
				cycle: 'month',
				description: '960 GB',
				group_display_order: 10,
				id: 43202564,
				num_units: null,
				option_key_display_text: 'Storage Size',
				parent_id: 43202563,
				price: '87.50000',
				pricing: {
					hour: '0.11993',
					month: '87.50',
				},
				value: '960',
			},
			ExtraIp: {
				capabilities: {
					nocworxPublicIP: 1,
				},
				comments: 'Cloud.VM.FS->ExtraIp:1',
				cycle: 'month',
				description: '%{units} Additional Public IPs',
				group_display_order: 70,
				id: 40675009,
				num_units: 1,
				option_key_display_text: 'Extra IP Addresses',
				parent_id: null,
				price: '0.00000',
				pricing: {
					hour: '0.00000',
					month: '0.00',
				},
				value: '1',
			},
			PortSpeed: {
				capabilities: {},
				comments: null,
				cycle: 'month',
				description: '10 Gigabit Public and Private Connections',
				group_display_order: 10,
				id: 40475128,
				num_units: null,
				option_key_display_text: 'Port Speed',
				parent_id: 40475127,
				price: '0.00000',
				pricing: {
					hour: '0.00000',
					month: '0.00',
				},
				value: '10gOS',
			},
		},
	},
	instance: {
		UQZ3HX: {
			accnt: 3378300,
			backup_enabled: 0,
			backup_plan: 'none',
			backup_quota: 0,
			backup_size: '0.00',
			backup_status: 'successful',
			config_description: '1 VCPUs, 512MB RAM, 15GB Disk',
			config_id: 0,
			crash_count: 0,
			create_date: '2020-01-28 15:29:46',
			diskspace: 15,
			hv_type: 'kvm',
			ip: '67.43.15.30',
			ip6_count: null,
			ip6_prefix: null,
			ip_count: 10,
			last_update: '2020-11-27 14:53:11.184865',
			memory: 512,
			parent_ip: '10.30.76.78',
			private_parent: 'Z8064R',
			status: 'running',
			subaccnt: 12424857,
			template: 'CENTOS_7_UNMANAGED',
			template_description: 'CentOS 7 64-bit Self-managed',
			uniq_id: 'UQZ3HX',
			valid_parent_key: 1,
			valid_source_hvs: ['xen', 'kvm'],
			vcpu: 1,
			vlan: 713,
			zone: 40460,
		},
		'52LWYF': {
			accnt: 3378300,
			backup_enabled: 1,
			backup_plan: 'quota',
			backup_quota: 50,
			backup_size: '46.14',
			backup_status: 'successful',
			config_description: '4 VCPUs, 4096MB RAM, 100GB Disk',
			config_id: 0,
			crash_count: 0,
			create_date: '2020-05-06 12:01:12',
			diskspace: 100,
			hv_type: 'kvm',
			ip: '67.43.15.16',
			ip6_count: null,
			ip6_prefix: null,
			ip_count: 10,
			last_update: '2021-03-26 00:28:25.030246',
			memory: 4096,
			parent_ip: '10.30.76.78',
			private_parent: 'Z8064R',
			status: 'running',
			subaccnt: 12984267,
			template: 'CENTOS_7_CPANEL',
			template_description: 'CentOS 7 64-bit with cPanel Fully-managed',
			uniq_id: '52LWYF',
			valid_parent_key: 1,
			valid_source_hvs: ['xen', 'kvm'],
			vcpu: 4,
			vlan: 713,
			zone: 40460,
		},
		'7CNH4M': {
			accnt: 3378300,
			backup_enabled: 1,
			backup_plan: 'daily',
			backup_quota: 7,
			backup_size: '90.36',
			backup_status: 'successful',
			config_description: 'Storm 512MB',
			config_id: 4,
			crash_count: 0,
			create_date: '2020-01-16 10:06:14',
			diskspace: 25,
			hv_type: 'kvm',
			ip: '67.43.15.27',
			ip6_count: null,
			ip6_prefix: null,
			ip_count: 8,
			last_update: '2021-03-26 00:26:30.34139',
			memory: 440,
			parent_ip: '10.30.76.12',
			status: 'running',
			subaccnt: 12373985,
			template: 'CENTOS_7_CPANEL_CLOUDLINUX',
			template_description: 'CentOS 7 64-bit cPanel w/CloudLinux',
			uniq_id: '7CNH4M',
			valid_parent_key: 1,
			valid_source_hvs: ['xen', 'kvm'],
			vcpu: 1,
			vlan: 713,
			zone: 40460,
		},
		'56Q011': {
			accnt: 3378300,
			backup_enabled: 0,
			backup_plan: 'none',
			backup_quota: 50,
			config_description: '4 VCPUs, 4096MB RAM, 100GB Disk',
			config_id: 0,
			crash_count: 0,
			create_date: '2020-05-06 12:01:13',
			diskspace: 100,
			hv_type: 'kvm',
			ip: '67.43.15.33',
			ip6_count: null,
			ip6_prefix: null,
			ip_count: 10,
			last_update: '2021-03-26 00:28:25.030246',
			memory: 4096,
			parent_ip: '10.30.76.78',
			private_parent: 'Z8064R',
			status: 'running',
			subaccnt: 12984267,
			template: 'CENTOS_7_CPANEL',
			template_description: 'CentOS 7 64-bit with cPanel Fully-managed',
			uniq_id: '56Q011',
			valid_parent_key: 1,
			valid_source_hvs: ['xen', 'kvm'],
			vcpu: 4,
			vlan: 713,
			zone: 40460,
		},
	},
	categories: {
		'52LWYF': [
			'CloudVPS',
			'AuthDedicated',
			'VirtualDedicated',
			'Dedicated',
			'Provisioned',
		],
		XVUT00: [
			'Provisioned',
			'Dedicated',
			'CloudVPS',
			'AuthDedicated',
			'VirtualDedicated',
		],
		UQZ3HX: [
			'Provisioned',
			'Dedicated',
			'AuthDedicated',
			'CloudVPS',
			'VirtualDedicated',
		],
		// This sever got deleted from the mctesterson account, so these values were copied from 52LWYF
		'7CNH4M': [
			'CloudVPS',
			'AuthDedicated',
			'VirtualDedicated',
			'Dedicated',
			'Provisioned',
		],
		// This sever got deleted from the mctesterson account, so these values were copied from 52LWYF
		'56Q011': [
			'CloudVPS',
			'AuthDedicated',
			'VirtualDedicated',
			'Dedicated',
			'Provisioned',
		],
		WFTN6D: ['NocworxOpenstack', 'Dedicated', 'VirtualDedicated'],
	},
	capabilities: {
		XVUT00: {
			ATSLogin: 1,
			AutoActivate: 1,
			autoAddIp: 1,
			autoPrivateNetworking: 1,
			autoReboot: 1,
			autoRemoveIp: 1,
			autoRename: 1,
			autoShutdown: 1,
			bandwidthStats: 1,
			canHotplugRamVcpu: 1,
			canThreatStack: 1,
			canUseSpiceConsole: 1,
			changeBWOptions: 1,
			clone: 1,
			create: 1,
			credentials: 1,
			destroy: 1,
			emailable: 1,
			extraAuth: 1,
			image: 1,
			interworxLogin: 1,
			manAddIp: 1,
			manReboot: 1,
			manRemoveIp: 1,
			managed: 1,
			migratableDestination: 1,
			migratableSource: 1,
			multiplePublicIPs: 1,
			networking: 1,
			presentable: 1,
			provBackup: 1,
			provFirewall: 1,
			reimage: 1,
			resize: 1,
			restoreFromTemplate: 1,
			reverseDNSEdit: 1,
			rootLogin: 1,
			serviceMonitoring: 1,
			serviceMonitoringBasic: 1,
			serviceMonitoringDns: 0,
			serviceMonitoringInternal: 1,
			serviceMonitoringInternalBasic: 1,
			serviceMonitoringInternalDns: 0,
			serviceMonitoringInternalInterworx: 1,
			serviceMonitoringInternalLinux: 1,
			serviceMonitoringInterworx: 1,
			serviceMonitoringLinux: 1,
			serviceMonitoringMachineBlackbox: 1,
			serviceMonitoringMachineMysqld: 1,
			serviceMonitoringMachineNode: 1,
			sonar: 1,
			volumeAttach: 1,
			volumeDetach: 1,
			webConsole: 1,
		},
	},
	networkSummary: {
		XVUT00: {
			primary_ip: '69.167.170.134',
			private_ip: null,
			total_ips: 1,
		},
	},
	privateParent: {
		'52LWYF': {
			diskDetails: {
				allocated: 220,
				snapshots: 93,
			},
			domain: 'privateparent',
			ip: '10.30.76.78',
			project_name: null,
			region_id: 1,
			status: 'Active',
			type: 'SS.PP',
			uniq_id: 'Z8064R',
			username: 'root',
		},
	},
	backupsAndStorage: {
		'56Q011': { acronis: { status: 'Active', uniq_id: '424QV0' } },
		'4KP25A': {
			acronis: {
				status: 'Active',
				uniq_id: '424QV0',
			},
		},
		'52LWYF': {
			image: {
				count: 13,
				date: '2020-04-25 11:18:20',
				name: 'asdf',
			},
		},
		WFTN6D: {
			acronis: {
				status: 'New',
				uniq_id: 'RPP7CW',
			},
		},
	},
	ancestorServer: {
		FYZ05N: {
			domain: 'child1.mctesterson.com',
			uniq_id: 'UQZ3HX',
		},
		UEY7CE: {
			domain: 'child1.mctesterson.com',
			uniq_id: 'UQZ3HX',
		},
	},
	product: {
		'56Q011': {
			active: 1,
			alias: null,
			credit_eligible: 1,
			cycle: 'month',
			default_price: '0.00000',
			discountable: 1,
			display_in_catalog: 1,
			entereddate: '2021-07-20 15:46:47.262957-04',
			id: 1367374,
			parent_product_id: 1126492,
			price_time_unit_id: 1,
			product_code: 'Acronis.VPS',
			product_description: 'Acronis Cyber Backups',
			related_product_id: null,
			sub_title: '',
			tax_code: null,
			title: '',
		},
		'424QV0': {
			active: 1,
			alias: null,
			credit_eligible: 1,
			cycle: 'month',
			default_price: '0.00000',
			discountable: 1,
			display_in_catalog: 1,
			entereddate: '2019-12-12 13:38:13-05',
			id: 1126492,
			parent_product_id: null,
			price_time_unit_id: 1,
			product_code: 'AcronisBackup',
			product_description: 'Acronis Cyber Backups',
			related_product_id: null,
			sub_title: '',
			tax_code: null,
			title: '',
		},
	},
};

export { standard, alsowithData, backupsKeys };
