import { spawn } from 'redux-saga/effects';

import { sagas as bulkUpdateTTLSagas } from './bulkUpdateTTLModule';
import { sagas as delegationSagas } from './delegationModule';
import { sagas as detailsSagas } from './detailsModule';
import { sagas as formatAsBindSagas } from './formatAsBindModule';
import { sagas as summarySagas } from './summaryModule';
import { sagas as createSaga } from './createModule';
import { sagas as importZoneFileSaga } from './importZoneFileModule';
import { sagas as parkableIpsSaga } from './parkableIpsModule';
import historySagas from './history/historySagas';

export default function* zoneSagas() {
	yield spawn(createSaga);
	yield spawn(bulkUpdateTTLSagas);
	yield spawn(delegationSagas);
	yield spawn(detailsSagas);
	yield spawn(formatAsBindSagas);
	yield spawn(summarySagas);
	yield spawn(historySagas);
	yield spawn(importZoneFileSaga);
	yield spawn(parkableIpsSaga);
}
