/* this file was adapted from the following example
	https://github.com/maxmantz/redux-oidc-example/blob/master/src/utils/userManager.js
	*/
import { createUserManager } from 'redux-oidc';
import { WebStorageStateStore } from 'oidc-client';

let CustomResponseValidator;

if (process.env.NODE_ENV !== 'test') {
	// See file for why this is fine.
	// eslint-disable-next-line global-require
	CustomResponseValidator = require('utility/openid/CustomResponseValidator')
		.default;
}

const userManagerConfig = (openIdConfig) => {
	const userManagerConfigObj = {
		authority: openIdConfig.get('authority'),
		client_id: openIdConfig.get('client_id'),
		redirect_uri: `${window.location.origin}/external/openid/return`,
		scope: 'openid profile grants',
		response_type: 'token id_token',
		automaticSilentRenew: true,
		silent_redirect_uri: `${window.location.origin}/external/openid/silent-return`,
		accessTokenExpiringNotificationTime: openIdConfig.get(
			'accessTokenExpiringNotificationTime',
		),
		userStore: new WebStorageStateStore({ store: window.localStorage }),
	};

	if (process.env.NODE_ENV !== 'test') {
		userManagerConfigObj.ResponseValidatorCtor = CustomResponseValidator;
	}

	return userManagerConfigObj;
};

/* 
		 session storage is the default userStore for oidc-client-js
     using WebStorageStateStore sets it to local storage
     so that it persists across tabs/windows
		 https://github.com/IdentityModel/oidc-client-js/wiki
	*/

const userManager = (openIdConfig) =>
	createUserManager(userManagerConfig(openIdConfig));

export default userManager;
