import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.tags.product.primary;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'TAGS_PRODUCT_PRIMARY',
	method: 'POST',
	url: '/tags/product/primary.json',
});

const selectors = {
	getStateSlice,
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };
