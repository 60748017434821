import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.account.user.available;

const { actions, reducer, sagas, selectors } = createAPIModule({
	isPaginated: true,
	getStateSlice,
	actionType: 'ACCOUNT_USER_AVAILABLE',
	url: '/account/user/available.json',
});

export { actions, reducer, sagas, selectors };
