import asset from 'modules/api/asset/mockData';
import account from 'modules/api/account/mockdata';
import product from 'modules/api/product/mockData';
import stormBackups from 'modules/api/storm/backup/mockData';
import stormPrivateParent from 'modules/api/storm/private/parent/mockData';
import server from 'modules/api/server/mockData';
import usertask from 'modules/api/usertask/mockData';
import basket from 'modules/api/basket/mockData';
import project from 'modules/api/project/mockData';
import backup from 'modules/api/acronis/backup/mockData';
import network from 'modules/api/network/mockData';
import images from 'modules/api/storm/image/mockData';
import notifications from 'modules/api/notifications/mockData';
import billing from 'modules/api/billing/mockData';

const apiMap = {
	...asset,
	...billing,
	...product,
	...stormBackups,
	...stormPrivateParent,
	...server,
	...usertask,
	...basket,
	...project,
	...backup,
	...network,
	...images,
	...account,
	...notifications,
	'/acronis/backup/usage.json': {
		usage: 22,
		quota: 75,
	},
	'/acronis/backup/sso.json': {
		url: 'http://catsanddogs.com',
	},
	'/appConfig.json': {
		ATTENTION_HUMANS:
			'This file is intended to be exposed, and does not contain sensitive information',
		baseURL: 'https://api-public.newman.dev.liquidweb.com:20910/',
		session: {
			length: 900,
			warningTime: 150,
		},
		hosts: {
			www: 'www.liquidweb.com',
			authorize_net: 'test.authorize.net',
			cart: 'cart.newman.dev.liquidweb.com:20250',
			manage: 'manage.newman.dev.liquidweb.com:21210',
			salesforce: 'test.salesforce.com',
			customer_community: 'scdev-liquidweb.cs91.force.com/customer/s',
			managed_apps: 'app.dev.liquidweb.com',
		},
		gaTrackerId: 'UA-106560627-2',
		gtmContainerId: 'GTM-KJQGDTD',
		guestAuth: {
			username: 'remote-signup',
			password: 'r3M0t3s|gnup',
		},
		chatConfig: {
			orgId: '00D2F0000009BrP',
			deploymentScript:
				'https://c.la1-c2cs-phx.salesforceliveagent.com/content/g/js/42.0/deployment.js',
			apiEndpoint: 'https://d.la4-c2cs-phx.salesforceliveagent.com/chat',
			deploymentId: '5720c0000004aCZ',
			buttonId: '5730c0000004aJB',
		},
		openId: {
			client_id: 'wallaby_manage',
			authority: 'https://login.dev.liquidweb.com',
			accessTokenExpiringNotificationTime: 1200,
		},
	},
	'/domain/services/summary.json': {
		business_email: {
			exchange: {
				count: 2,
			},
			standard: {
				count: 1,
			},
			uniq_id: 'abc123',
		},
		// cloudflare: {
		// 	plan: 'SuperPlan',
		// 	security_level: 'poor at best',
		// 	uniq_id: '123abc',
		// },
	},
	'/network/dns/zone/summary.json': {
		a_records: ['66.77.88.99'],
		ns_records: ['ns.liquidweb.com', 'ns1.liquidweb.com'],
		mx_records: [],
		txt_records: [],
		uniq_id: '123456',
	},
	'/network/domain/related.json': {
		tlds: ['domainexample.com', 'domainexample.org'],
		sub_domains: [
			'host.domainexample.whatever',
			'media.domainexample.whatever',
		],
		parent_domain: '',
	},
	'/network/dns/zone/details': {
		accnt: 3378300,
		active: 1,
		created: '2019-01-03 10:50:10.673071',
		delegation: ['ns1.thegnomedev.com', 'ns2.thegnomedev.com'],
		delegation_checked: '2019-01-03 10:55:53',
		delegation_status: 'CORRECT',
		end_date: null,
		id: 1319335,
		last_updated: '2019-01-03 10:55:53.673447',
		master: '10.30.152.4',
		name: 'perltastic.com',
		notified_serial: null,
		region_support: '0',
		type: 'NATIVE',
		uniq_id: '7T5K8R',
	},
	'/network/dns/domain/nameserver/listAssigned': {
		results: ['ns1.example.com', 'ns2.example.com'],
	},
	'/network/dns/domain/registrationDetails.json': {
		available: 0,
		domain: 'thebest.com',
		enabled: 1,
		lax: 1,
		lockable: 1,
		max_period: '10',
		min_period: '1',
		price: '15.00',
		real_time: 1,
		registerable: 0,
		suggested: [
			{
				available: 1,
				domain: 'thebest.org',
				enabled: 1,
				lax: 1,
				lockable: 1,
				max_period: '10',
				min_period: '1',
				price: '15.00',
				real_time: 1,
				registerable: 1,
			},
			{
				available: 1,
				domain: 'thebest.info',
				enabled: 1,
				lax: 1,
				lockable: 1,
				max_period: '10',
				min_period: '1',
				price: '15.00',
				real_time: 1,
				registerable: 1,
			},
			{
				available: 1,
				domain: 'thebest.io',
				enabled: 1,
				lax: 1,
				lockable: 0,
				max_period: '10',
				min_period: '1',
				price: '50.00',
				real_time: 1,
				registerable: 1,
			},
			{
				available: 1,
				domain: 'thebest.ninja',
				enabled: 1,
				lax: 1,
				lockable: 1,
				max_period: '10',
				min_period: '1',
				price: '25.00',
				real_time: 1,
				registerable: 1,
			},
			{
				available: 1,
				domain: 'thebest.rocks',
				enabled: 1,
				lax: 1,
				lockable: 1,
				max_period: '10',
				min_period: '1',
				price: '15.00',
				real_time: 1,
				registerable: 1,
			},
			{
				available: 1,
				domain: 'thebest.tv',
				enabled: 1,
				lax: 1,
				lockable: 1,
				max_period: '10',
				min_period: '1',
				price: '50.00',
				real_time: 1,
				registerable: 1,
			},
			{
				available: 1,
				domain: 'thebest.social',
				enabled: 1,
				lax: 1,
				lockable: 1,
				max_period: '10',
				min_period: '1',
				price: '35.00',
				real_time: 1,
				registerable: 1,
			},
			{
				available: 1,
				domain: 'thebest.zone',
				enabled: 1,
				lax: 1,
				lockable: 1,
				max_period: '10',
				min_period: '1',
				price: '35.00',
				real_time: 1,
				registerable: 1,
			},
			{
				available: 1,
				domain: 'thebest.mobi',
				enabled: 1,
				lax: 1,
				lockable: 1,
				max_period: '10',
				min_period: '1',
				price: '25.00',
				real_time: 1,
				registerable: 1,
			},
		],
	},
	'/product/marketplace/productsForTags.json': {
		paged_data: {
			item_count: 1,
			item_total: 1,
			items: [
				{
					active: 1,
					created_date: '2020-11-05 13:01:30.454657-05',
					description: 'Example description',
					display_order: 7,
					product: {
						marketplacePrices: [
							{
								is_prepay: 0,
								price: {
									month: 359,
								},
								region: 'phx',
								region_id: '2',
								region_name: 'US West',
							},
							{
								is_prepay: 1,
								price: {
									month: 99,
									'one-time': 1000,
								},
								region: 'lan',
								region_id: 1,
								region_name: 'US Central',
							},
						],
						product_description:
							'Dedicated Server - Single Intel Xeon CPU - US Central Zone',
						sub_title: 'Intel Xeon E3-1230 v5, 4-core',
						title: 'Single Processor',
					},
					product_code: 'DS.1230v5',
				},
			],
			page_num: 1,
			page_size: 8,
			page_total: 1,
		},
		unique_tags: ['povtest1', 'primarycat1', 'prodcattest1'],
	},
	'/server/availableStorage.json': {
		disks: [
			{
				size: 200,
				price: '10.00',
			},
			{
				size: 300,
				price: '20.00',
			},
			{
				size: 500,
				price: '40.00',
			},
			{
				size: 400,
				price: '30.00',
			},
			{
				size: 600,
				price: '50.00',
			},
			{
				size: 700,
				price: '60.00',
			},
		],
	},
	'/server/details/services.json': {
		ddos_protection_level: '2 Gbps',
		security_product_enabled: true,
	},
	'/ssl/certificate/details': {
		accnt: 7599420,
		autorenew_value: 'Off',
		certificate: null,
		created: '2018-12-20 09:16:16.789058',
		cpanel_linked: 0,
		csr: '-----BEGIN CERTIFICATE REQUEST-----',
		domain: 'examplecertificate.com',
		duration: 12,
		expiration: '2019-01-19 12:00:00',
		id: 228267,
		notified: 0,
		order_id: 'CEDD181220046745',
		renewable: 0,
		uniq_id: 'K1C6XH',
		verification_data: {
			names: ['examplecertificate.com'],
			rdata:
				'_globalsign-domain-verification=4WNIh50fzAPzF89PtKY7QjuN21ipaJkbypcEk5yccZ',
		},
		verification_method: 'dns',
		verification_status: 'Verified',
		wildcard: 0,
	},
	'/tags/product/primary.json': { tags: ['Servers', 'Bundles'] },
	'/contact/list.json': {
		item_count: 1,
		item_total: 1,
		items: [
			{
				address: "C/O Bob's Test",
				address2: '123 W Test Blvd',
				city: 'Lansing',
				contact_role: 'individual',
				country: 'US',
				emails: [
					{
						address: 'sabcatlibra@gmail.com',
						contact_id: 3399307,
						id: 4631460,
						type: 'main',
					},
				],
				fname: 'Sabrina',
				id: 3399307,
				lname: 'McTesterson',
				mname: 'Willomena',
				organization: "McTesterson's Widgets",
				phones: [
					{
						contact_id: 3399307,
						id: 7592107,
						number: '5172854692',
						type: 'office',
					},
					{
						contact_id: 3399307,
						id: 14854668,
						number: '1234567890',
						type: 'fax',
					},
				],
				postal_code: '48933',
				social: [],
				state: 'MI',
				tax_exempt: 1,
				tax_exempt_id: '18-5789034',
				tax_exempt_reason: 'Z',
				tax_id: '18-5789034',
				time_zone: null,
				title: null,
				type: 'invoice',
				updated_date: '2020-10-21 12:14:47.12439-04',
				validated: 0,
			},
		],
		page_num: 1,
		page_size: 25,
		page_total: 1,
	},
	'/contact/taxExemptReasons.json': {
		reasons: {
			A: 'Federal Government',
			B: 'State Government',
			C: 'Tribe / Status Indian / Indian Band',
			D: 'Foreign Diplomat',
			E: 'Charitable or Benevolent Organization',
			F: 'Religious Organization',
			G: 'Resale',
			H: 'Commercial Agricultural Production',
			I: 'Industrial Production / Manufacturer',
			J: 'Direct Pay Permit',
			K: 'Direct Mail',
			L: 'Other',
			M: 'Educational Organization',
			N: 'Local Government',
			O: 'Commercial Aquaculture',
			Q: 'Commercial Fishery',
			R: 'Non-Resident',
			Z: 'There is no tax',
		},
	},
	logout: { loggedOut: true },
};

export { apiMap };

export default function api(apiData) {
	const resData = apiMap[apiData.url];
	switch (typeof resData) {
		case 'function':
			return resData(apiData.data.params);
		case 'object':
			return resData;
		default:
			return {};
	}
}
