import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';

const getStateSlice = (state) => state.basketApi.addCoupon;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'BASKET_ADDCOUPON',
	method: 'POST',
	url: '/basket/addCoupon.json',
});

const selectors = {
	getSuccess: createSelector(
		defaultSelectors.getData,
		(data) => data && data.get('success'),
	),
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };
