import { select, put, all, takeLatest, take } from 'redux-saga/effects';
import sshActions from 'modules/sshkeys/actions';
import { getAPIParams } from 'modules/sshkeys/selectors';
import { actions as sshAddModuleActions } from 'modules/api/account/ssh/key/addModule';
import { actions as sshEditModuleActions } from 'modules/api/account/ssh/key/updateModule';
import { actions as sshRemoveModuleActions } from 'modules/api/account/ssh/key/removeModule';
import {
	actions as sshListModuleActions,
	moduleKeys as listModuleKeys,
} from 'modules/api/account/ssh/key/listModule';
import get from 'lodash/get';

const formatErrors = (errors) => {
	return errors.map(({ type, field, errorText }) => {
		let text = errorText;
		if (type === 'WORD_') {
			text = 'Invalid Name';
		} else if (type === 'SSH_KEY_PUBLIC') {
			text = 'Invalid Key';
		} else if (!type) {
			text = 'Error';
			// eslint-disable-next-line no-param-reassign
			field = 'generic';
		}

		return {
			field,
			text,
		};
	});
};

function* handleAddSaga() {
	const state = yield select(getAPIParams);
	const { publicKeyName, publicKeyValue } = state;

	const [{ type, ...response }] = yield all([
		take([sshAddModuleActions.setType, sshAddModuleActions.errorType]),
		put(
			sshAddModuleActions.fetch({
				public_key_value: publicKeyValue,
				public_key_name: publicKeyName,
			}),
		),
	]);

	// get errors
	const errors = get(response, 'payload.data.errors', []);

	// if successful, fetch ssh keys
	if (type === sshAddModuleActions.setType) {
		yield put(sshListModuleActions.fetch());
	}

	const error = get(response, 'payload.data.error_class');

	// if error is due to duplicate, add to errors
	if (error === 'LW::Exception::DuplicateRecord') {
		errors.push({
			type: 'DUPLICATE',
			errorText: 'Duplicate Key',
			field: 'duplicate',
		});
	}
	// if errors
	if (type === sshAddModuleActions.errorType) {
		return yield put(sshActions.error(formatErrors(errors)));
	}

	return yield put(sshActions.closeDialog());
}

function* handleEditSaga() {
	const state = yield select(getAPIParams);
	const { publicKeyName, publicKeyValue, id } = state;

	const [{ type, ...response }] = yield all([
		take([sshEditModuleActions.setType, sshEditModuleActions.errorType]),
		put(
			sshEditModuleActions.fetch({
				public_key_value: publicKeyValue,
				public_key_name: publicKeyName,
				id,
			}),
		),
	]);

	// get errors
	const errors = get(response, 'payload.data.errors', []);

	// if successful, fetch ssh keys
	if (type === sshEditModuleActions.setType) {
		yield put(sshListModuleActions.fetch());
	}

	const error = get(response, 'payload.data.error_class');

	// if error is due to duplicate, add to errors
	if (error === 'LW::Exception::DuplicateRecord') {
		errors.push({
			type: 'DUPLICATE',
			errorText: 'Duplicate Key',
			field: 'duplicate',
		});
	}
	// if errors
	if (type === sshEditModuleActions.errorType) {
		return yield put(sshActions.error(formatErrors(errors)));
	}

	return yield put(sshActions.closeDialog());
}

function* handleRemoveSaga() {
	const state = yield select(getAPIParams);
	const { id } = state;

	const [{ type }] = yield all([
		take([sshRemoveModuleActions.setType, sshRemoveModuleActions.errorType]),
		put(sshRemoveModuleActions.fetch({ id })),
	]);

	if (type === sshRemoveModuleActions.setType) {
		yield put(sshListModuleActions.fetch());
	}
	if (type === sshRemoveModuleActions.errorType) {
		return yield put(sshActions.error('Error Removing SSH Key'));
	}
	return yield put(sshActions.closeDialog());
}

// call this to have a ssh key list you can count on without fetching it all over again.
function* initAll() {
	yield put(sshListModuleActions.init({ page_size: 999 }, listModuleKeys.ALL));
}

export default function* sshkeysSagasroot() {
	yield takeLatest(sshActions.SSH_KEYS_ADD_CONFIRM, handleAddSaga);
	yield takeLatest(sshActions.SSH_KEYS_EDIT_CONFIRM, handleEditSaga);
	yield takeLatest(sshActions.SSH_KEYS_REMOVE_CONFIRM, handleRemoveSaga);
	yield takeLatest(sshActions.SSH_KEY_INIT_ALL, initAll);
}
