import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';

const getStateSlice = (state) => state.salesforce.account.interworx.details;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'SALESFORCE_ACCOUNT_INTERWORX_DETAILS',
	method: 'POST',
	url: '/salesforce/account/interworx/details.json',
});

const selectedControlPanel = createSelector(
	getStateSlice,
	(slice) => {
		const controlPanelResult = slice.getIn(
			['data', 'interworx_migration'],
			null,
		);
		if (!controlPanelResult || controlPanelResult === 'No Response')
			return null;
		return controlPanelResult === 'cPanel Pricing Increase Accepted'
			? 'cpanel'
			: 'interworx';
	},
);

const selectors = {
	selectedControlPanel,
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };
