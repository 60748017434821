import React, { useMemo } from 'react';
import LWTypography from 'components/common/LWTypography';
import PropTypes from 'prop-types';
import randomNumberBetween from 'utility/randomNumberBetween';

// TODO: put all of these styles in a config file.

const variants = {
	default: { label: 'subtitle2' },
	indented: { label: 'subtitle1', text: 'body1' },
	secondary: { label: 'body2', text: 'body2' },
	inline: { label: 'body1', text: 'body1' },
};

const colors = {
	secondary: { text: 'textSecondary' },
};

const bolded = {
	default: true,
	inline: true,
};

const labelBoxProps = {
	default: { mt: 2, mb: 0.5 },
	indented: { mt: 3, mb: 1 },
};

const textBoxProps = {
	indented: { ml: 2, pt: 1 },
};

const createTextArray = ({ text, isLoading }) => {
	if (isLoading) return ['loading']; // This will trigger a single skeleton loader.
	if (!text) return []; // will cause null render.
	return (Array.isArray(text) ? text : [text]).filter(
		(elem) =>
			!!((typeof elem === 'object' ? elem.value : elem) || '').replace(
				/\s/g,
				'',
			),
	);
}; // Shows skeleton loaders if loading.

const LabeledText = ({
	label,
	text,
	inline = false,
	isLoading = false,
	variant = 'default',
	noMarginTop = false,
	hideLabelWhileLoading = false,
	dataTestId,
}) => {
	const labelProps = useMemo(
		() => ({
			variant: variants[variant]?.label,
			bold: bolded[variant],
			BoxProps: {
				...labelBoxProps[variant],
				...(noMarginTop ? { mt: 0 } : {}),
				...(inline ? { display: 'inline-block' } : {}),
			},
			color: colors[variant]?.label,
		}),
		[inline, noMarginTop, variant],
	);

	const loaderWidth = useMemo(() => `${randomNumberBetween(40, 70)}%`, []);
	const textArray = useMemo(() => createTextArray({ text, isLoading }), [
		isLoading,
		text,
	]);

	const content = textArray.map((elem) => (
		<LWTypography
			BoxProps={textBoxProps[variant]}
			isLoading={isLoading}
			SkeletonProps={{ width: loaderWidth }}
			key={elem.key || elem}
			variant={variants[variant]?.text}
			color={colors[variant]?.text}
			inline={inline}
		>
			{typeof elem === 'object' ? elem.value : elem}
		</LWTypography>
	));

	// no content? no render.
	if (!content.length) return null;

	return (
		<>
			{(!hideLabelWhileLoading || !isLoading) && (
				<LWTypography data-testid={dataTestId} {...labelProps}>
					{label}
				</LWTypography>
			)}
			{inline && ': '}
			{content}
		</>
	);
};

LabeledText.propTypes = {
	/** The label for the text. If you don't need this, you're using the wrong component. */
	label: PropTypes.string.isRequired,
	/** A string, or an array of strings. If this is empty, the whole component will not render. */
	text: PropTypes.oneOfType([
		PropTypes.arrayOf(
			PropTypes.oneOfType([
				PropTypes.shape({ key: PropTypes.string, value: PropTypes.string }),
				PropTypes.string,
			]),
		),
		PropTypes.string,
	]),
	/** This is passed into the LWTypography components for making skeletons. */
	isLoading: PropTypes.bool,
	/** adjusts the version of labeled text. */
	variant: PropTypes.oneOf(['default', 'indented', 'secondary', 'inline']),
	/** Set this to true to get rid of the top margin (i.e. this is the first of a list.). Default is false. */
	noMarginTop: PropTypes.bool,
	/** Set this to true to remove the label if isLoading is also true. */
	hideLabelWhileLoading: PropTypes.bool,
};

export default LabeledText;
