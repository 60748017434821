// Using requires for importing jest functions to avoid a dependancy cycle catastrophe. TODO: figure out why that's happening.
/* eslint-disable global-require */
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
	adminToken,
	userToken,
	ownerToken,
	unauthedToken,
	incompleteAddressToken,
	incompletePaymentToken,
} from 'modules/auth/testData';

const tokens = {
	admin: adminToken,
	user: userToken,
	owner: ownerToken,
	unauthed: unauthedToken,
	incompleteAddress: incompleteAddressToken,
	incompletePayment: incompletePaymentToken,
};

// gathers the textContent of the given tagName, and takes a snapshot.
const tagNameTextTest = ({
	container,
	tagName: tagNameArg, // can be an array too,
	includeInnerHTML, // if true, will use the innerHTML property if no textContent is found.
}) => {
	const tagNameArray = Array.isArray(tagNameArg) ? tagNameArg : [tagNameArg];
	expect(
		tagNameArray
			.map((tagName) =>
				Array.from(container.querySelectorAll(tagName))
					.map(
						(elem) => elem.textContent || (includeInnerHTML && elem.innerHTML),
					)
					.filter((elem) => elem),
			)
			.flat(),
	).toMatchSnapshot();
};

// Used for mocking list apis with pagination capabilities.
// Data should be an object in key value pairs. i.e. [uuid, data]
const getList = ({
	pageSize = 25,
	pageNum = 1,
	data = {},
	alsowith = [],
	alsowithData = {},
	uniqId,
}) => {
	// Let's not divide by 0.
	if (Number(pageSize) <= 0)
		throw new Error('page_size must be a positive integer.');
	const allItems = Object.values(data)
		.map((value) => value)
		.filter((elem) => (uniqId ? elem.uniq_id === uniqId : true));
	const itemTotal = allItems.length;
	const pageTotal = Math.ceil(itemTotal / pageSize);
	let items = allItems.slice((pageNum - 1) * pageSize, pageNum * pageSize);

	if (alsowith.length !== 0) {
		items = items.map((elem) => {
			let fElem = { ...elem };
			alsowith.forEach((key) => {
				fElem = {
					...fElem,
					...{ [key]: alsowithData[key][fElem.uniq_id] },
				};
			});
			return fElem;
		});
	}
	return {
		item_count: Math.min(pageSize, itemTotal),
		item_total: itemTotal,
		items,
		page_num: pageNum,
		page_size: pageSize,
		page_total: pageTotal,
	};
};

const selectFirstInAutocomplete = (autoCompleteField) => {
	// Using require to avoid a dependancy cycle catastrophe. Not sure why that's happening.
	// eslint-disable-next-line global-require
	const { fireEvent } = require('testUtils/testing-library/react-wrapper');
	// interact with the field to have dom build the options.
	fireEvent.keyDown(autoCompleteField, {
		key: 'ArrowDown',
	});
	// select the first option.
	fireEvent.keyDown(autoCompleteField, {
		key: 'ArrowDown',
	});
	fireEvent.keyDown(autoCompleteField, {
		key: 'Enter',
	});
};

const selectOptionsTest = ({ selectMenu }) => {
	const {
		fireEvent,
		screen,
	} = require('testUtils/testing-library/react-wrapper');
	fireEvent.keyDown(selectMenu.querySelector('[role="button"]'), {
		key: 'Enter',
	});
	expect(
		Array.from(screen.queryByRole('listbox').querySelectorAll('li')).map(
			(elem) => ({
				label: elem.textContent,
				value: elem.getAttribute('data-value'),
			}),
		),
	).toMatchSnapshot();
};

// use this to arbitrarily dispatch actions on mount to init the redux store on mount during tests.
const DispatchWrapper = ({ children, actions = [], authLevel }) => {
	const dispatch = useDispatch();
	const { store } = require('store');
	if (authLevel) {
		window.localStorage.setItem('testUser', authLevel); // Allows us to modify mock responses based on the user.
		store.dispatch({
			type: 'redux-oidc/USER_FOUND',
			payload: {
				id_token: tokens[authLevel],
				access_token: tokens[authLevel],
				token_type: 'Bearer',
				scope: 'openid profile grants',
			},
		});
	} else {
		store.dispatch({
			type: 'redux-oidc/USER_EXPIRED',
		});
	}
	useEffect(() => {
		const actionsArray = Array.isArray(actions) ? actions : [actions];
		actionsArray.forEach((action) => dispatch(action));
		return () => {};
	}, [actions, authLevel, dispatch, store]);
	return children;
};

const setBasketUuid = ({ uuid = 'youYouEyeDee' } = {}) => {
	global.localStorage.setItem('basketUuid', uuid);
};

// Tests that the button closed the dialog.
const closeDialogTest = ({ button }) => {
	const { store } = require('store');
	const { default: dialogActions } = require('modules/dialogs/actions');
	const { fireEvent } = require('testUtils/testing-library/react-wrapper');
	if (!store.getState().dialogs.open) store.dispatch(dialogActions.open({}));

	// this should always pass if the test is working properly.
	expect(store.getState().dialogs.open).toBe(true);

	fireEvent.click(button);
	// Clicking the button should have closed the modal.
	expect(store.getState().dialogs.open).toBe(false);

	// reset in case of failure so that other tests are initialized properly.
	store.dispatch(dialogActions.close());
};

export {
	tagNameTextTest,
	getList,
	selectFirstInAutocomplete,
	selectOptionsTest,
	DispatchWrapper,
	setBasketUuid,
	closeDialogTest,
};
