import { spawn } from 'redux-saga/effects';

import { sagas as details } from './detailsModule';
import { sagas as getBasicOptions } from './getBasicOptionsModule';
import { sagas as rules } from './rulesModule';
import ruleset from './ruleset/rulesetSagas';
import { sagas as update } from './updateModule';

export default function* sagas() {
	yield spawn(details);
	yield spawn(getBasicOptions);
	yield spawn(rules);
	yield spawn(ruleset);
	yield spawn(update);
}
