import { put } from 'redux-saga/effects';
import snackbarActions from 'modules/snackbar/snackbarActions';

function* snackbarSaga({
	error,
	errorMessage,
	successMessage,
	autoHideDuration = 6000,
}) {
	if (error) {
		yield put(
			snackbarActions.pushMessage(
				errorMessage || 'Something went wrong',
				'error',
				undefined,
				autoHideDuration,
			),
		);
	} else if (successMessage) {
		yield put(
			snackbarActions.pushMessage(
				successMessage,
				undefined,
				undefined,
				autoHideDuration,
			),
		);
	}
}

export default snackbarSaga;
