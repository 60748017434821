// data = {key: {value: responseObject}}

const data = {
	ConfigId: {
		'explodable drive': {
			alerts: [
				{
					details: {
						current: {
							key: 'WindowsLicense',
							value: 'Windows',
						},
						valid: {
							key: 'WindowsLicense',
							value: '4CoreWin',
						},
					},
					level: 'warn',
					message: 'Value for WindowsLicense should be 4CoreWin',
					type: 'license',
				},
				{
					details: {
						current: {
							key: 'NotAthing',
							value: 'Windows',
						},
						valid: {
							key: 'NotAthing',
							value: '4CoreWin',
						},
					},
					level: 'warn',
					message: 'Value for WindowsLicense should be 4CoreWin',
					type: 'notathing',
				},
			],
			discount: '0.00000',
			extra_data: {
				category: 'ssd',
				config_id: 1,
				cores: 2,
				is_baremetal: 0,
				memory: 1700,
				price: 1,
				product_code: 'SS.VPS',
				storage_size: 100,
				tags: [],
			},
			is_extra: 1,
			key: 'ConfigId',
			key_description: 'Server Type',
			key_display_order: 1,
			key_group: 'Server Options',
			key_group_display_order: 1,
			num_units: null,
			parent_key: null,
			parent_value: null,
			price: '1.00000',
			price_total: '1.00000',
			tax: null,
			valid: 1,
			value: '1',
			value_comments: null,
			value_description: '2GB',
			value_display_order: 6,
			value_price_time_unit: 'month',
		},
	},
	Template: {
		CENTOS_7_UNMANAGED: {
			alerts: [],
			discount: '0.00000',
			extra_data: {
				active: 1,
				deprecated: 0,
				os: 'LinuxCentOS',
				os_license: null,
				support_level: 'Self-Managed',
				tags: ['CentOS', 'Self-Managed'],
				zone_availability: {
					'40460': 1,
					'45412': 1,
				},
			},
			is_extra: 0,
			key: 'Template',
			key_description: 'Server Type',
			key_display_order: 2,
			key_group: 'Server Options',
			key_group_display_order: 1,
			nonce: '58230128-f483-11eb-ab40-20cf307037a1',
			num_units: null,
			parent_key: null,
			parent_value: null,
			price: '0.00000',
			price_total: '0.00000',
			tax: null,
			valid: 1,
			value: 'CENTOS_7_UNMANAGED',
			value_comments: null,
			value_description: 'CentOS 7 64-bit Self-managed',
			value_display_order: 0,
			value_price_time_unit: 'month',
		},
	},
};

export default data;
