import React from 'react';
import { Switch } from 'react-router-dom';
import asyncComponent from 'components/AsyncComponent';
import RoleRestrictedRoute from 'utility/routing/RoleRestrictedRoute';
import { roles } from 'utility/constants/roles';

const Cart = asyncComponent(() => import('containers/pages/shop/Cart'));

const CartMerge = asyncComponent(() =>
	import('containers/pages/cart/CartMerge'),
);

const CartRouter = () => {
	return (
		<Switch>
			<RoleRestrictedRoute
				exact
				allowUnauth
				whitelistedRoles={[roles.PURCHASER, roles.SETUP_USER]}
				path="/cart/saved/:uuid?"
				component={CartMerge}
			/>
			<RoleRestrictedRoute
				exact
				allowUnauth
				whitelistedRoles={[roles.PURCHASER, roles.SETUP_USER]}
				path="/cart/:basketUuid?"
				component={Cart}
			/>
		</Switch>
	);
};

export default CartRouter;
