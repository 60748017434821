import { call, put, take, takeLatest, select } from 'redux-saga/effects';
import { replace } from 'connected-react-router';
import {
	selectors as detailsSelectors,
	actions as detailsActions,
} from 'modules/api/basket/detailsModule';
import {
	actions as acquireActions,
	selectors as acquireSelectors,
} from 'modules/api/basket/acquireModule';
import basketActions from 'modules/basket/actions';
import dialogActions from 'modules/dialogs/actions';
import snackbarSaga from 'modules/snackbar/sagas';

import detailsSaga from '../details';
import storeUuid from '../storeUuid';

function* handleMergeSaved({ payload: { uuid } }) {
	if (yield select(detailsSelectors.isLoading)) {
		yield take(detailsActions.setType);
	}

	const isLocked = yield select(detailsSelectors.isLocked);

	if (isLocked) {
		// show the abandon cart modal if the cart is locked
		yield put(replace('/cart'));
		yield put(
			dialogActions.open({
				contentKey: 'AbandonCartModalContents',
				contentProps: {
					savedBasketUuid: uuid,
				},
				color: 'danger',
				dismissible: false,
			}),
		);
	} else {
		yield call(detailsSaga, { init: true });
		const currentBasket = yield select(detailsSelectors.getBasketUuid);

		yield put(
			acquireActions.fetch({
				uuid,
				destination_uuid: currentBasket,
			}),
		);
		const acquireResult = yield take([
			acquireActions.setType,
			acquireActions.errorType,
		]);
		if (acquireResult.type === acquireActions.setType) {
			const newUuid = yield select(acquireSelectors.uuid);
			yield call(storeUuid, { uuid: newUuid });
			yield call(detailsSaga);
			const targetItems = yield select(detailsSelectors.targetItems);
			// TODO: eventually will want this to be a more universal method that doesn't only happen for saved carts
			if (targetItems) {
				yield put(replace(`/shop/config/${targetItems?.[0]}`));
			} else {
				yield put(replace('/cart'));
			}
		}
		const error = acquireResult.type === acquireActions.errorType;
		yield call(snackbarSaga, {
			error,
			errorMessage: 'Failed to merge saved cart.',
		});
	}
}

export { handleMergeSaved };

export default function* root() {
	yield takeLatest(basketActions.BASKET_MERGE_SAVED, handleMergeSaved);
}
