import { call, put, takeLatest, select, take } from 'redux-saga/effects';
import {
	actions as purchaseActions,
	selectors as purchaseSelectors,
} from 'modules/api/basket/purchaseModule';
import {
	actions as claimActions,
	selectors as claimSelectors,
} from 'modules/api/basket/claimModule';
import {
	actions as detailsActions,
	selectors as detailsSelectors,
} from 'modules/api/basket/detailsModule';
import basketActions from 'modules/basket/actions';
import snackbarSaga from 'modules/snackbar/sagas';
import { sagas as attributionSagas } from 'modules/attribution';
import { push } from 'connected-react-router';
import { claimBasket } from '../claim';
import createSaga from '../create';

function* handlePurchaseSaga() {
	yield call(claimBasket); // We need this here because the basket may not yet have been approved when claim was called on init.
	while (yield select(claimSelectors.isLoading))
		yield take(claimActions.setType);
	yield call(attributionSagas.update);
	const uuid = yield select(detailsSelectors.getBasketUuid);
	yield put(purchaseActions.fetch({ uuid }));
	const res = yield take([purchaseActions.setType, purchaseActions.errorType]);
	yield call(snackbarSaga, {
		error: res.type === purchaseActions.errorType,
		errorMessage: yield select(purchaseSelectors.getErrorString),
	});
}

function* handlePostPurchaseSaga() {
	// TODO
	// Clearing basket details because it still shows existing items, possible bug with API
	// As a result of this bug, a user that purchases domains => adds another domain to their cart, will see old domains in their cart

	const basketDetails = yield select(detailsSelectors.getNativeData);

	// As a temporary fix, we save the purchase details in the basket reducer, in order to display it in the CartSuccess component but not CartSummary
	yield put(basketActions.savePurchase(basketDetails));
	yield put(detailsActions.clear());

	yield call(createSaga);

	// When above is fixed, details might need to be added back
	// yield call(detailsSaga);

	yield put(push('/shop/complete'));
}

export { handlePostPurchaseSaga, handlePurchaseSaga };
export default function*() {
	yield takeLatest(basketActions.BASKET_PURCHASE, handlePurchaseSaga);
	yield takeLatest(purchaseActions.setType, handlePostPurchaseSaga);
}
