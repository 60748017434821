import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const { actions, reducer, sagas, selectors } = createAPIModule({
	getStateSlice: (state) => state.server.resizeVolume,
	actionType: 'SERVER_RESIZE_VOLUME',
	method: 'POST',
	url: '/server/resizeVolume.json',
});

export { actions, reducer, sagas, selectors };
