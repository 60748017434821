import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import LWButton from 'components/common/LWButton';
import AddressFormSection from './AddressFormSection';
import { TaxFormSection } from './TaxFormSection';

const TEST_ID = 'AddressForm';
const AddressForm = ({
	changeFieldValue,
	handleSubmit,
	formValues,
	invalid,
	pristine,
	selectedCountry,
	showButton,
	showTaxInformation,
	submitPending,
	taxExemptReasons,
	...rest
}) => (
	<form data-testid={TEST_ID} onSubmit={handleSubmit}>
		<AddressFormSection
			changeFieldValue={changeFieldValue}
			formValues={formValues}
			selectedCountry={selectedCountry}
			{...rest}
		/>
		{showTaxInformation && selectedCountry && (
			<TaxFormSection
				changeFieldValue={changeFieldValue}
				formValues={formValues}
				selectedCountry={selectedCountry}
				taxExemptReasons={taxExemptReasons}
			/>
		)}
		{showButton && (
			<Box justifyContent="flex-end" pt={2}>
				<LWButton
					disabled={invalid || pristine}
					color="secondary"
					variant="contained"
					type="submit"
					isLoading={submitPending}
				>
					Save
				</LWButton>
			</Box>
		)}
	</form>
);

AddressForm.propTypes = {
	/** Function called when form is submitted */
	handleSubmit: PropTypes.func,
	/** Values of the form - stored in redux form */
	formValues: PropTypes.object,
	/** tells whether validation passed - injected by redux form */
	invalid: PropTypes.bool,
	/** tells whether the form was changed - injected by redux form */
	pristine: PropTypes.bool,
	/** Value of the country that has been selected in AddressFormSection - stored in redux form */
	selectedCountry: PropTypes.object,
	/** Tells the component to show the submit button */
	showButton: PropTypes.bool,
	/** Tells component to show the tax form section */
	showTaxInformation: PropTypes.bool,
	/** Data may be initializing */
	isLoading: PropTypes.bool,
};

AddressForm.defaultProps = {
	showButton: false,
};

export { TEST_ID };
export default AddressForm;
