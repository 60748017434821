import actions from './actions';

const defaultState = { deployOnto: 'vps' };

const productConfigReducer = (state = defaultState, action) => {
	switch (action.type) {
		case actions.SET_PRODUCT_CONFIG_DEPLOY_ONTO:
			return { ...state, deployOnto: action.deployOnto };
		default:
			return state;
	}
};

export default productConfigReducer;
