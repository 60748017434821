import { combineReducers } from 'redux';

import { reducer as deleteReducer } from './deleteModule';
import { reducer as listRegistered } from './listRegisteredModule';
import { reducer as listAssigned } from './listAssignedModule';
import { reducer as assign } from './assignModule';
import { reducer as register } from './registerModule';
import { reducer as updateRegisteredIP } from './updateRegisteredIPModule';

export default combineReducers({
	delete: deleteReducer, // delete is a keyword
	listRegistered,
	listAssigned,
	assign,
	register,
	updateRegisteredIP,
});
