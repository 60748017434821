import { createSelector } from 'reselect';

const getStateSlice = (state) => state.gtm;

const initialized = createSelector(
	getStateSlice,
	(slice) => slice.initialized,
);

// eslint-disable-next-line import/prefer-default-export
export { initialized };
