import React from 'react';
import PropTypes from 'prop-types';
import LWDialog from 'components/common/LWDialog';

import AbandonCartModalContents from 'components/cart/AbandonCartModalContents';
import AcronisCancelConfirmationDialogContents from 'components/Acronis/Cancel/dialogs/AcronisCancelConfirmationDialogContents';
import AcronisCancelDialogContents from 'components/Acronis/Cancel/dialogs/AcronisCancelDialogContents';
import ConfigSelectModalContents from 'containers/pages/shop/ProductConfig/dropdownDialogs/ConfigSelectModalContents';
import MasqueradeModalContents from 'components/cart/MasqueradeModalContents';
import MissingHostnameModalContents from 'components/cart/MissingHostnameModalContents';
import OpportunityModalContents from 'components/common/CartBadge/OpportunityModalContents';
import CloneServerModalContents from 'containers/pages/servers/details/CloneServerModalContents';
import AddAcronisModalContents from 'containers/pages/servers/BackupsAndImages/cloud/Settings/Acronis/AddAcronis/AddAcronisModalContents';
import ImageConfirmationDialogContents from 'containers/pages/servers/BackupsAndImages/dialogs/ImageConfirmationDialogContents';
import ImageServerSelectDialogContents from 'containers/pages/servers/BackupsAndImages/dialogs/ImageServerSelectDialogContents';
import BackupConfirmationDialogContents from 'containers/pages/servers/BackupsAndImages/dialogs/BackupConfirmationDialogContents';
import TemplateConfirmationDialogContents from 'containers/pages/servers/BackupsAndImages/dialogs/TemplateConfirmationDialogContents';
import CreateNewCartModalContents from 'containers/pages/shop/dialogs/CreateNewCartModalContents';

import BackupRestoreDialogContents from 'containers/pages/servers/BackupsAndImages/dialogs/BackupRestoreDialogContents';
import { contentKeyToTitleMap, contentKeyToAddPadding } from './maps';

const ContentFromKey = ({ contentKey, contentProps }) => {
	const Component = (() => {
		switch (contentKey) {
			case 'AbandonCartModalContents':
				return AbandonCartModalContents;
			case 'AcronisCancelConfirmationDialogContents':
				return AcronisCancelConfirmationDialogContents;
			case 'AcronisCancelDialogContents':
				return AcronisCancelDialogContents;
			case 'AddAcronisModalContents':
				return AddAcronisModalContents;
			case 'BackupConfirmationDialogContents':
				return BackupConfirmationDialogContents;
			case 'BackupRestoreDialogContents':
				return BackupRestoreDialogContents;
			case 'ConfigSelectModalContents':
				return ConfigSelectModalContents;
			case 'CreateNewCartModalContents':
				return CreateNewCartModalContents;
			case 'CloneServerModalContents':
				return CloneServerModalContents;
			case 'ImageConfirmationDialogContents':
				return ImageConfirmationDialogContents;
			case 'ImageServerSelectDialogContents':
				return ImageServerSelectDialogContents;
			case 'MasqueradeModalContents':
				return MasqueradeModalContents;
			case 'MissingHostnameModalContents':
				return MissingHostnameModalContents;
			case 'OpportunityModalContents':
				return OpportunityModalContents;
			case 'TemplateConfirmationDialogContents':
				return TemplateConfirmationDialogContents;
			default:
				return React.Fragment; // !TODO figure out why an empty dialog flashes onClose, causing this Fragment to be needed
		}
	})();
	return <Component {...contentProps} />;
};

const Dialogs = ({
	onClose, // from connected
	open,
	color,
	dialogProps,
	content,
	contentProps,
	contentKey,
	title = contentKeyToTitleMap[contentKey],
	dismissible,
	maxWidth = 'sm',
}) => {
	return (
		<LWDialog
			title={title}
			color={color}
			onClose={onClose}
			open={open}
			maxWidth={maxWidth}
			fullWidth
			{...dialogProps}
			dismissible={dismissible}
			contentPadding={contentKeyToAddPadding[contentKey]}
		>
			{content || (
				<ContentFromKey contentKey={contentKey} contentProps={contentProps} />
			)}
		</LWDialog>
	);
};

Dialogs.propTypes = {
	/**  */
	title: PropTypes.string,
	/**  function executed to close dialog */
	onClose: PropTypes.func,
	/** from dialogs/reducer */
	open: PropTypes.bool,
	color: PropTypes.string,
	dialogProps: PropTypes.object,
	content: PropTypes.node,
	contentProps: PropTypes.object,
	contentKey: PropTypes.string,
	/**  */
	dismissible: PropTypes.bool,
	/** MUI Prop passed to Dialog */
	maxWidth: PropTypes.string,
};

export default Dialogs;
