import React from 'react';

// MUI components
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

// custom components
import LWTypography from 'components/common/LWTypography';
import HostnameNeeded from 'components/molecules/HostnameNeeded';

const MissingHostnameModalContents = () => (
	<Box px={1} py={2}>
		<Grid container spacing={4}>
			<Grid item>
				<LWTypography>
					<strong>You’re almost done!</strong> To get your servers ready for
					you, we will need you to choose hostnames. You can do this with “Edit
					Hostname/Project”
				</LWTypography>
			</Grid>
			<Grid item>
				<LWTypography bold>
					Look for this badge next to servers that still need a hostname:
				</LWTypography>
			</Grid>
			<Grid container item>
				<Box width="fit-content" margin="auto">
					<HostnameNeeded />
				</Box>
			</Grid>
		</Grid>
	</Box>
);

export default MissingHostnameModalContents;
