import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { currency } from 'utility/format';
import LWTypography from 'components/common/LWTypography';
import LWButton from 'components/common/LWButton';
import LWLink from 'components/common/LWLink';
import Grid from '@material-ui/core/Grid';
import {
	selectors as balanceSelectors,
	actions as balanceActions,
} from 'modules/api/billing/balanceModule';
import {
	hasAuthToken,
	hasGroup,
	isBasketAdmin as isBasketAdminSelector,
	isSetupUser as isSetupUserSelector,
	mainRole as mainRoleSelector,
} from 'modules/auth/oidcSelectors';
import styled from 'styled-components';
import { roles } from 'utility/constants/roles';

const SGrid = styled((props) => (
	<Grid container direction="row" alignItems="center" {...props} />
))`
	div:first-of-type {
		max-width: ${(p) => p.theme.maxWidth}px;
		margin: auto;
		width: 100%;
		display: flex;
	}
	border-bottom: 1px solid ${(p) => p.theme.palette.common.grey1};
	padding: ${(p) => p.theme.spacing(1)}px;
	background: ${(p) => p.theme.palette.common.grey1};
	.MuiTypography-root,
	.MuiButtonBase-root {
		margin-right: ${(p) => p.theme.spacing(1)}px;
	}
	a {
		&:hover {
			text-decoration: none;
		}
	}
	.balanceText {
		font-size: 1.3em;
		margin-left: ${(p) => p.theme.spacing(1)}px;
		margin-right: ${(p) => p.theme.spacing(1)}px;
		color: ${(p) => p.theme.palette.common.grey4};
	}
	.due {
		font-size: 1.3em;
		margin-right: ${(p) => p.theme.spacing(2)}px;
		font-weight: 700;
		color: ${(p) => p.theme.palette.common.grey4};
	}
	.MuiButtonBase-root {
		width: 11em;
	}
`;

const noPaymentDisplayData = {
	dueText: 'Nothing Due',
	payButtonText: 'Make a Payment',
};

const needPaymentDisplayData = {
	dueText: 'Due Now',
	payButtonText: 'Pay Now',
};

const AccountBalanceBar = ({
	isDisplayed,
	fetchBalance,
	isPaymentDue,
	paymentDue,
}) => {
	useEffect(() => {
		if (isDisplayed) {
			fetchBalance();
		}
	}, [fetchBalance, isDisplayed]);

	if (!isDisplayed) return null;

	const paymentDisplayData = isPaymentDue
		? needPaymentDisplayData
		: noPaymentDisplayData;

	if (paymentDue <= 0) return null;

	return (
		<SGrid>
			<div>
				<LWTypography className="balanceText">Account Balance:</LWTypography>
				<LWTypography className="due">
					{currency(paymentDue)} {paymentDisplayData.dueText}
				</LWTypography>

				<LWLink to="/account/billing/pay">
					<LWButton color="secondary" variant="contained" fullWidth>
						{paymentDisplayData.payButtonText}
					</LWButton>
				</LWLink>
				<LWLink to="/account/billing">
					<LWButton variant="outlined" color="secondary" fullWidth>
						More Details
					</LWButton>
				</LWLink>
			</div>
		</SGrid>
	);
};

AccountBalanceBar.propTypes = {
	isDisplayed: PropTypes.bool,
	isPaymentDue: PropTypes.bool.isRequired,
	paymentDue: PropTypes.string.isRequired,
	fetchBalance: PropTypes.func.isRequired,
};

AccountBalanceBar.defaultProps = {
	isDisplayed: false,
};

const mapStateToProps = (state) => ({
	isDisplayed:
		hasAuthToken(state) &&
		hasGroup(state) &&
		!isBasketAdminSelector(state) &&
		!isSetupUserSelector(state) &&
		mainRoleSelector(state) !== roles.TECHNICIAN.key &&
		!balanceSelectors.getError(state),
	isLoading: balanceSelectors.isLoading(state),
	isPaymentDue: balanceSelectors.isPaymentDue(state),
	paymentDue: balanceSelectors.displayBalance(state),
});

const mapDispatchToProps = (dispatch) => ({
	fetchBalance: () => dispatch(balanceActions.fetch()),
});

export { AccountBalanceBar };

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(AccountBalanceBar);
