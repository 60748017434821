import { combineReducers } from 'redux';

import dns from './dns/dnsReducer';
import firewall from './firewall/firewallReducer';
import ip from './ip/ipReducer';
import loadbalancer from './loadbalancer/reducer';
import privateReducer from './private/privateReducer';
import util from './util/utilReducer';
import zone from './zone/zoneReducer';

export default combineReducers({
	dns,
	firewall,
	ip,
	loadbalancer,
	private: privateReducer,
	util,
	zone,
});
