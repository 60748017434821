import { combineReducers } from 'redux';
import { reducer as attach } from './attachModule';
import { reducer as create } from './createModule';
import { reducer as deleteReducer } from './deleteModule';
import { reducer as detach } from './detachModule';
import { reducer as details } from './detailsModule';
import { reducer as list } from './listModule';
import { reducer as resize } from './resizeModule';
import { reducer as update } from './updateModule';

export default combineReducers({
	attach,
	create,
	delete: deleteReducer,
	detach,
	details,
	list,
	resize,
	update,
});
