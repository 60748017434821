import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';

const getStateSlice = (state) => state.domain.services.summary;

const {
	actions,
	reducer,
	sagas,
	selectors: summarySelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'DOMAIN_SERVICES_SUMMARY',
	url: '/domain/services/summary.json',
});

const selectors = {
	getBusinessEmail: createSelector(
		summarySelectors.getData,
		(data) => data && data.get('business_email'),
	),
	getCloudFlare: createSelector(
		summarySelectors.getData,
		(data) => data && data.get('cloudflare'),
	),
	...summarySelectors,
};

export { actions, reducer, sagas, selectors };
