import debian from 'images/osIcons/LinuxDebian.png';
import centos from 'images/osIcons/LinuxCentOS.png';
import fedora from 'images/osIcons/LinuxFedora.png';
import ubuntu from 'images/osIcons/LinuxUbuntu.png';
import lw from 'images/osIcons/Managed.png';
import windows from 'images/osIcons/Windows.png';
import alpine from 'images/osIcons/LinuxAlpine.png';
import alma from 'images/osIcons/LinuxAlma.png';
import rocky from 'images/osIcons/LinuxRocky.png';
import arch from 'images/osIcons/LinuxArch.png';

export default {
	debian,
	centos,
	fedora,
	ubuntu,
	lw,
	windows,
	alpine,
	alma,
	rocky,
	arch,
};
