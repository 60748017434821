import {
	all,
	call,
	delay,
	put,
	select,
	take,
	takeEvery,
} from 'redux-saga/effects';

import { actions as blockVolumeAttachActions } from 'modules/api/storage/block/volume/attachModule';
import { actions as bulkAttachDetachActions } from 'modules/api/network/private/bulkAttachDetachModule';
import {
	actions as currentNotificationActions,
	selectors as currentNotificationSelectors,
} from 'modules/api/notifications/currentModule';
import { actions as firewallRulesetUpdateActions } from 'modules/api/network/firewall/ruleset/updateModule';
import { actions as backupRestoreActions } from 'modules/api/storm/backup/restoreModule';
import { actions as imageCreateActions } from 'modules/api/storm/image/createModule';
import { actions as imageRestoreActions } from 'modules/api/storm/image/restoreModule';
import { actions as templateRestoreActions } from 'modules/api/storm/template/restoreModule';
import { actions as ipAddActions } from 'modules/api/network/ip/addModule';
import { actions as ipRemoveActions } from 'modules/api/network/ip/removeModule';
import { actions as projectAddActions } from 'modules/api/project/addModule';
import { actions as resizeActions } from 'modules/api/server/resizeModule';
import { actions as resizeStorageActions } from 'modules/api/server/resizeStorageModule';
import { actions as resizeVolumeActions } from 'modules/api/server/resizeVolumeModule';
import { actions as serverRebootActions } from 'modules/api/server/rebootModule';
import { actions as serverShutdownActions } from 'modules/api/server/shutdownModule';
import { actions as serverStartActions } from 'modules/api/server/startModule';
import { actions as assetRemoveActions } from 'modules/api/asset/removeModule';
import { isLoggedInAndNotBasketAdmin as isLoggedInAndNotBasketAdminSelector } from 'modules/auth/oidcSelectors';
import actions from './actions';

const POLL_SECONDS = 30;
const FETCH_DELAY_SECONDS = 0.5;
const UNIQ_POLL_SECONDS = 5;

function* fetchCurrentNotifications(fetchDelay = 0) {
	// Allow a delay as notifications will sometimes have a slight delay before being created after an API call completes.
	yield delay(fetchDelay * 1000);
	const isLoggedInAndNotBasketAdmin = yield select(
		isLoggedInAndNotBasketAdminSelector,
	);
	if (isLoggedInAndNotBasketAdmin) {
		yield put(currentNotificationActions.fetch());
	}
}

function* pollCurrentNotifications() {
	yield delay(POLL_SECONDS * 1000); // do an initial delay as there is an existing call that already happens on app load anyway
	while (true) {
		yield fetchCurrentNotifications();
		yield delay(POLL_SECONDS * 1000);
	}
}

function* waitForUniqId(action) {
	yield fetchCurrentNotifications();
	yield take(currentNotificationActions.setType);
	let notifications = yield select(currentNotificationSelectors.newestPerAsset);

	let notification = notifications.find(
		(x) =>
			x.get('uniq_id') === action.uniqId &&
			x.get('category') === action.category,
	);
	while (notification) {
		yield fetchCurrentNotifications();
		yield delay(UNIQ_POLL_SECONDS * 1000);
		yield take(currentNotificationActions.setType);
		notifications = yield select(currentNotificationSelectors.newestPerAsset);
		notification = notifications.find(
			(x) =>
				x.get('uniq_id') === action.uniqId &&
				x.get('category') === action.category,
		);
	}
	if (action.toFetchAfter) {
		yield call(action.toFetchAfter);
	}
}

function* pollForUniqId() {
	yield takeEvery(actions.POLL_NOTIFICATIONS, waitForUniqId);
}

function* watchActionsThatNeedFreshNotifications() {
	yield takeEvery(
		[
			assetRemoveActions.setType,
			backupRestoreActions.setType,
			blockVolumeAttachActions.setType,
			bulkAttachDetachActions.setType,
			firewallRulesetUpdateActions.setType,
			imageCreateActions.setType,
			imageRestoreActions.setType,
			ipAddActions.setType,
			ipRemoveActions.setType,
			projectAddActions.setType,
			resizeActions.setType,
			resizeStorageActions.setType,
			resizeVolumeActions.setType,
			serverRebootActions.setType,
			serverShutdownActions.setType,
			serverStartActions.setType,
			templateRestoreActions.setType,
		],
		fetchCurrentNotifications,
		FETCH_DELAY_SECONDS,
	);
}

export default function* saga() {
	yield all([
		pollCurrentNotifications(),
		watchActionsThatNeedFreshNotifications(),
		pollForUniqId(),
	]);
}
