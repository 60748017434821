import React from 'react';
import LWTypography from 'components/common/LWTypography';

const ErrorContent = () => (
	<LWTypography color="error">
		Something unexpected happened. Please try again or contact a helpful human.
	</LWTypography>
);

export default ErrorContent;
