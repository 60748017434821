import Immutable from 'immutable';
import React from 'react';

import Home from '@material-ui/icons/HomeRounded';
import Dns from '@material-ui/icons/DnsRounded';
import Language from '@material-ui/icons/LanguageRounded';
import SettingsSystemDaydream from '@material-ui/icons/SettingsSystemDaydreamRounded';
import AddShoppingCart from '@material-ui/icons/AddShoppingCartRounded';
import MonetizationOn from '@material-ui/icons/MonetizationOn';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Layers from '@material-ui/icons/LayersRounded';
import RoleRestrictedRoute from 'utility/routing/RoleRestrictedRoute';
import { roles } from 'utility/constants/roles';
import assetTypes from 'utility/constants/assetTypes';
import asyncComponent from 'components/AsyncComponent';
import ServersRouter from 'components/routers/ServersRouter';
import HomeRouter from 'components/routers/HomeRouter';
import ServicesRouter from 'components/routers/ServicesRouter';
import ShopRouter from 'components/routers/ShopRouter';
import DomainsRouter from 'components/routers/DomainsRouter';
import BillingRouter from 'components/routers/BillingRouter';
import AccountRouter from 'components/routers/AccountRouter';
import ProjectsRouter from 'components/routers/ProjectsRouter';
import SecureNotesBadge from 'containers/badges/SecureNotesBadge';

const DomainSearch = asyncComponent(() =>
	import('containers/pages/domains/Register/Search'),
);

const HomePage = asyncComponent(() => import('containers/pages/Home'));
const Servers = asyncComponent(() =>
	import('containers/pages/servers/Servers'),
);
const SSHKeys = asyncComponent(() =>
	import('containers/pages/servers/SSHKeys/Page'),
);
const ScheduledTasks = asyncComponent(() =>
	import('containers/pages/servers/ScheduledTasks'),
);
const Domains = asyncComponent(() =>
	import('containers/pages/domains/Domains'),
);
const LoadBalancerList = asyncComponent(() =>
	import('containers/pages/services/LoadBalancersContainer'),
);

const AccountMain = asyncComponent(() =>
	import('containers/pages/account/AccountMain'),
);
const ControlPanelPreference = asyncComponent(() =>
	import('containers/pages/account/ControlPanelPreference'),
);
const SecureNotes = asyncComponent(() =>
	import('containers/pages/account/secureNotes/SecureNotes'),
);
const Users = asyncComponent(() =>
	import('containers/pages/account/users/Users'),
);
const BillingPage = asyncComponent(() =>
	import('containers/pages/billing/BillingPage'),
);
const BillingPay = asyncComponent(() =>
	import('containers/pages/billing/BillingPay'),
);
const Transactions = asyncComponent(() =>
	import(
		'containers/pages/billing/details/TransactionTimeline/TransactionTimelinePage'
	),
);
const PaymentMethodPage = asyncComponent(() =>
	import('containers/pages/billing/details/PaymentMethodPage'),
);
const ProjectCreate = asyncComponent(() =>
	import('containers/pages/projects/ProjectCreate'),
);
const ProjectList = asyncComponent(() =>
	import('containers/pages/projects/ProjectList'),
);
// const AddCloudBlockStorage = asyncComponent(() =>
// 	import('containers/pages/services/CloudBlockStorage/AddCloudBlockStorage'),
// );
const CloudBlockStorageList = asyncComponent(() =>
	import('containers/pages/services/CloudBlockStorage/CloudBlockStorageList'),
);

const ThreatStack = asyncComponent(() =>
	import('containers/pages/services/ThreatStack'),
);

// const ServerSecurePlus = asyncComponent(() =>
// 	import('containers/pages/services/ServerSecurePlus'),
// );

const AcronisList = asyncComponent(() =>
	import('containers/pages/services/Acronis'),
);
const CloudPrivateNetworks = asyncComponent(() =>
	import('containers/pages/services/CloudPrivateNetworks'),
);
const AdvancedServices = asyncComponent(() =>
	import('containers/pages/services/AdvancedServices'),
);
const FirewallPresets = asyncComponent(() =>
	import('containers/pages/services/FirewallPresets'),
);
const ServicesPage = asyncComponent(() =>
	import('containers/pages/services/Services'),
);

const Marketplace = asyncComponent(() =>
	import('containers/pages/shop/Marketplace'),
);

const navMap = Immutable.fromJS({
	home: {
		name: 'Home',
		icon: (props) => <Home {...props} />,
		isExact: true,
		path: '/',
		router: () => <HomeRouter navData={navMap.get('home')} />,
		component: () => <HomePage navData={navMap.get('home')} />,
		subNav: {},
	},
	projects: {
		name: 'Projects',
		mobileName: 'Projects List',
		icon: (props) => <Layers {...props} />,
		path: '/projects',
		component: () => <ProjectList navData={navMap.get('projects')} />,
		router: () => <ProjectsRouter navData={navMap.get('projects')} />,
		subNav: {
			create: {
				path: '/projects/create',
				name: 'Create Project',
				component: () => (
					<ProjectCreate
						navData={navMap.getIn(['projects', 'subNav', 'create'])}
					/>
				),
			},
		},
	},
	servers: {
		name: 'Servers',
		mobileName: 'Servers List',
		icon: (props) => <Dns {...props} />,
		path: '/servers',
		router: () => <ServersRouter navData={navMap.get('servers')} />,
		component: () => <Servers navData={navMap.get('servers')} />,
		subNav: {
			sshKeys: {
				path: '/servers/ssh-keys',
				name: 'Public SSH Keys',

				component: () => (
					<SSHKeys navData={navMap.getIn(['servers', 'subNav', 'sshKeys'])} />
				),
			},
			migrationCenter: {
				configPath: 'manage/migration',
				configKey: 'manage',
				name: 'Migration Center',
				path: '/',
				notYetImplemented: true,
			},
			scheduledTasks: {
				path: '/servers/scheduled-tasks',
				name: 'Scheduled Tasks',
				component: () => <ScheduledTasks />,
			},
		},
	},

	domain: {
		name: 'Domains',
		mobileName: 'Domains List',
		icon: (props) => <Language {...props} />,
		path: '/domain',
		component: () => (
			<Domains navData={navMap.get('domain')} assetType={assetTypes.ALL} />
		),
		router: () => <DomainsRouter navData={navMap.get('domain')} />,
		subNav: {
			registration: {
				path: '/shop/domain',
				name: 'Register a New Domain',
				component: () => <DomainSearch />,
			},
			transfer: {
				path: '/domain/create-transfer',
				name: 'Transfer A Domain',
				component: () => (
					<Domains
						parent={navMap.get('domain')}
						navData={navMap.getIn(['domain', 'subNav', 'transfer'])}
						assetType={assetTypes.DOMAIN}
					/>
				),
			},
			dns: {
				path: '/domain/dns',
				name: 'DNS',
				component: () => (
					<Domains
						parent={navMap.get('domain')}
						navData={navMap.getIn(['domain', 'subNav', 'dns'])}
						assetType="DNS"
					/>
				),
			},
			ssl: {
				path: '/domain/ssl',
				name: 'SSL',
				component: () => (
					<Domains
						parent={navMap.get('domain')}
						navData={navMap.getIn(['domain', 'subNav', 'ssl'])}
						assetType={assetTypes.SSL}
					/>
				),
			},
		},
	},
	services: {
		name: 'Services',
		mobileName: 'Services List',
		icon: (props) => <SettingsSystemDaydream {...props} />,
		path: '/services',
		component: () => <ServicesPage navData={navMap.get('services')} />,
		router: () => <ServicesRouter navData={navMap.get('services')} />,
		subNav: {
			loadBalancers: {
				navHidden: true,
				path: '/services/load-balancers',
				name: 'Load Balancers',
				component: () => (
					<LoadBalancerList
						navData={navMap.getIn(['services', 'subNav', 'loadBalancers'])}
					/>
				),
			},
			cloudPrivateNetworks: {
				navHidden: true,
				path: '/services/cloud-private-networks',
				name: 'Cloud Private Networks',
				component: () => (
					<CloudPrivateNetworks
						navData={navMap.getIn([
							'services',
							'subNav',
							'cloudPrivateNetworks',
						])}
					/>
				),
			},
			advancedServices: {
				navHidden: true,
				path: '/services/advanced-services',
				name: 'Advanced Services',
				component: () => (
					<AdvancedServices
						navData={navMap.getIn(['services', 'subNav', 'advancedServices'])}
					/>
				),
			},
			cloudBlockStorage: {
				navHidden: true,
				path: '/services/cloud-block-storage',
				name: 'Cloud Block Storage',
				component: () => (
					<CloudBlockStorageList
						navData={navMap.getIn(['services', 'subNav', 'cloudBlockStorage'])}
					/>
				),
			},
			firewallPresets: {
				navHidden: true,
				path: '/services/firewall-presets',
				name: 'Firewall Presets',
				component: () => (
					<FirewallPresets
						navData={navMap.getIn(['services', 'subNav', 'firewallPresets'])}
					/>
				),
			},
			acronis: {
				navHidden: true,
				path: '/services/acronis',
				name: 'Acronis',
				component: () => (
					<AcronisList
						navData={navMap.getIn(['services', 'subNav', 'acronis'])}
					/>
				),
			},
			threatStack: {
				navHidden: true,
				path: '/services/threatstack',
				name: 'Threat Stack',
				component: () => (
					<ThreatStack
						navData={navMap.getIn(['services', 'subNav', 'threatStack'])}
					/>
				),
			},
			// The product is still being developed
			// serverSecurePlus: {
			// 	navHidden: true,
			// 	path: '/services/server-secure-plus',
			// 	name: 'Server Secure Plus',
			// 	component: () => (
			// 		<ServerSecurePlus
			// 			navData={navMap.getIn(['services', 'subNav', 'serverSecurePlus'])}
			// 		/>
			// 	),
			// },
		},
	},
	shop: {
		name: 'Shop',
		mobileName: 'Shop',
		icon: (props) => <AddShoppingCart {...props} />,
		component: () => <Marketplace />,
		path: '/shop',
		allowUnauth: true,
		router: () => <ShopRouter navData={navMap.get('shop')} />,
		subNav: {
			domain: {
				navHidden: true,
				path: '/shop/domain',
				name: 'Register a Domain',
				component: () => <DomainSearch />,
			},
			marketplace: {
				name: 'Marketplace',
				path: '/shop/marketplace',
				component: () => <Marketplace />,
			},
			cart: {
				name: 'Cart',
				path: '/cart',
				// configKey: 'cart',
			},
			checkout: {
				name: 'Checkout',
				path: '/shop/checkout',
				// configPath: 'checkout',
				// configKey: 'cart',
			},
			/* TODO: add this back once this page is live
			cloudBlockStorage: {
				path: '/add/cloud-block-storage/',
				name: 'Add Cloud Block Storage',
				component: () => <AddCloudBlockStorage />,
			},
			*/
		},
	},
	billing: {
		navHidden: true,
		name: 'Billing',
		icon: (props) => <MonetizationOn {...props} />,
		path: '/billing',
		component: () => <BillingPage navData={navMap.get('billing')} />,
		router: () => <BillingRouter navData={navMap.get('billing')} />,
		subNav: {
			pay: {
				path: '/billing/pay',
				name: 'Make a Payment',
				component: () => (
					<BillingPay
						parent={navMap.get('billing')}
						navData={navMap.getIn(['billing', 'subNav', 'pay'])}
					/>
				),
			},
			transactions: {
				path: '/billing/transactions',
				name: 'Transactions',
				component: () => (
					<Transactions
						parent={navMap.get('billing')}
						navData={navMap.getIn(['billing', 'subNav', 'transactions'])}
					/>
				),
			},
			paymentMethod: {
				path: '/billing/payment-method',
				name: 'Update Payment Method',
				component: () => (
					<PaymentMethodPage
						parent={navMap.get('billing')}
						navData={navMap.getIn(['billing', 'subNav', 'paymentMethod'])}
					/>
				),
			},
		},
	},
	account: {
		name: 'Account',
		mobileName: 'Account Overview',
		icon: (props) => <AccountCircle {...props} />,
		path: '/account',
		component: () => <AccountMain navData={navMap.get('account')} />,
		router: () => <AccountRouter navData={navMap.get('account')} />,
		subNav: {
			users: {
				path: '/account/users',
				name: 'Users',
				component: () => (
					<RoleRestrictedRoute
						path="/account/users"
						component={Users}
						parent={navMap.get('account')}
						navData={navMap.getIn(['account', 'subNav', 'users'])}
					/>
				),
			},
			secureNotes: {
				path: '/account/secure-notes',
				name: 'Secure Notes',
				Badge: SecureNotesBadge,
				whitelistedRoles: [roles.PURCHASER, roles.TECHNICIAN],
				component: () => (
					<SecureNotes
						parent={navMap.get('account')}
						navData={navMap.getIn(['account', 'subNav', 'secureNotes'])}
					/>
				),
			},
			billing: {
				name: 'Billing Center',
				icon: (props) => <MonetizationOn {...props} />,
				path: '/account/billing',
				whitelistedRoles: [
					roles.ACCOUNT_OWNER,
					roles.SECONDARY_OWNER,
					roles.PURCHASER,
					roles.SUSPENDED_OWNER,
				],
				component: () => (
					<BillingPage
						navData={navMap.getIn(['account', 'subNav', 'billing'])}
					/>
				),
				router: () => (
					<BillingRouter
						navData={navMap.getIn(['account', 'subNav', 'billing'])}
					/>
				),
				subNav: {
					pay: {
						path: '/account/billing/pay',
						name: 'Make a Payment',
						navHidden: true,
						whitelistedRoles: [
							roles.ACCOUNT_OWNER,
							roles.SECONDARY_OWNER,
							roles.PURCHASER,
							roles.SUSPENDED_OWNER,
						],
						component: () => (
							<BillingPay
								parent={navMap.getIn(['account'])}
								navData={navMap.getIn([
									'account',
									'subNav',
									'billing',
									'subNav',
									'pay',
								])}
							/>
						),
					},
					transactions: {
						path: '/account/billing/transactions',
						name: 'Transactions',
						navHidden: true,
						component: () => (
							<Transactions
								parent={navMap.getIn(['account'])}
								navData={navMap.getIn([
									'account',
									'subNav',
									'billing',
									'subNav',
									'transactions',
								])}
							/>
						),
					},
					paymentMethod: {
						path: '/account/billing/payment-method',
						name: 'Update Payment Method',
						navHidden: true,
						whitelistedRoles: [
							roles.ACCOUNT_OWNER,
							roles.SECONDARY_OWNER,
							roles.PURCHASER,
							roles.SUSPENDED_OWNER,
						],
						component: () => (
							<PaymentMethodPage
								parent={navMap.getIn(['account'])}
								navData={navMap.getIn([
									'account',
									'subNav',
									'billing',
									'subNav',
									'paymentMethod',
								])}
							/>
						),
					},
				},
			},
			pay: {
				path: '/account/billing/pay',
				name: 'Make a Payment',
				navHidden: true,
				breadcrumbHidden: true,
				whitelistedRoles: [
					roles.ACCOUNT_OWNER,
					roles.SECONDARY_OWNER,
					roles.PURCHASER,
					roles.SUSPENDED_OWNER,
				],
				component: () => (
					<BillingPay
						parent={navMap.getIn(['account'])}
						navData={navMap.getIn([
							'account',
							'subNav',
							'billing',
							'subNav',
							'pay',
						])}
					/>
				),
			},
			transactions: {
				path: '/account/billing/transactions',
				name: 'Transactions',
				navHidden: true,
				breadcrumbHidden: true,
				component: () => (
					<Transactions
						parent={navMap.getIn(['account'])}
						navData={navMap.getIn([
							'account',
							'subNav',
							'billing',
							'subNav',
							'transactions',
						])}
					/>
				),
			},
			paymentMethod: {
				path: '/account/billing/payment-method',
				name: 'Update Payment Method',
				navHidden: true,
				breadcrumbHidden: true,
				whitelistedRoles: [
					roles.ACCOUNT_OWNER,
					roles.SECONDARY_OWNER,
					roles.PURCHASER,
					roles.SUSPENDED_OWNER,
				],
				component: () => (
					<PaymentMethodPage
						parent={navMap.getIn(['account'])}
						navData={navMap.getIn([
							'account',
							'subNav',
							'billing',
							'subNav',
							'paymentMethod',
						])}
					/>
				),
			},
			controlPanelPrefs: {
				path: '/account/cpanel-price-increase',
				name: 'Control Panel Preference',
				navHidden: true,
				whitelistedRoles: [
					roles.ACCOUNT_OWNER,
					roles.SECONDARY_OWNER,
					roles.PURCHASER,
				],
				component: () => (
					<ControlPanelPreference
						parent={navMap.get('account')}
						navData={navMap.getIn(['account', 'subNav', 'controlPanelPrefs'])}
					/>
				),
			},
		},
	},
});
const navLinks = navMap.toList();

export { navMap, navLinks };
