import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectors as availableAssetsSelectors } from 'modules/api/project/availableAssetsModule';
import { actions as availableAssetsActions } from 'modules/availableAssets';
import PropTypes from 'prop-types';

// MUI components
import Box from '@material-ui/core/Box';

// custom components
import LWTypography from 'components/common/LWTypography';
import LWTooltip from 'components/common/LWTooltip';

const LabeledServer = ({ uniqId, label, toolTipText }) => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(availableAssetsActions.initServers());
	}, [dispatch]);
	const { domain } =
		useSelector(availableAssetsSelectors.serverUniqIdMap)[uniqId] || {};
	if (!domain) return null;
	return (
		<Box
			display="flex"
			alignItems="center"
			data-testid="LabeledServer__Container"
		>
			{label && (
				<LWTypography
					BoxProps={{ mr: 1 }}
					color="textSecondary"
					variant="subtitle2"
				>
					{label}
				</LWTypography>
			)}
			<LWTypography variant="subtitle2" bold color="palette.primary.dark">
				{domain}
			</LWTypography>
			{toolTipText && <LWTooltip>{toolTipText}</LWTooltip>}
		</Box>
	);
};

LabeledServer.propTypes = {
	label: PropTypes.string.isRequired,
	uniqId: PropTypes.string.isRequired,
	toolTipText: PropTypes.string,
};

export default LabeledServer;
