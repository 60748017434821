import { connect } from 'react-redux';
import { selectors as serverRebootAPISelectors } from 'modules/api/server/rebootModule';
import serverRebootActions from 'modules/server/reboot/actions';
import * as rebootSelectors from 'modules/server/reboot/selectors';
import { withRouter } from 'react-router';
import RebootDialog from './RebootDialog';

const mapStateToProps = (state) => ({
	canAutoReboot: rebootSelectors.getCanAutoReboot(state),
	domain: rebootSelectors.getDomain(state),
	uniqId: rebootSelectors.getUniqId(state),
	isOpen: rebootSelectors.getIsDialogOpen(state),
	serverRebootResult: {
		isLoading: serverRebootAPISelectors.isLoading(state),
		hasData: serverRebootAPISelectors.hasData(state),
		nativeData: serverRebootAPISelectors.getNativeData(state),
		error: serverRebootAPISelectors.getError(state),
	},
});

const mapDispatchToProps = (dispatch) => ({
	closeDialog: () => dispatch(serverRebootActions.closeDialog()),

	invokeReboot: ({ uniqId, forceReboot = false, deferSeconds = null }) => {
		dispatch(
			serverRebootActions.invokeReboot({
				uniqId,
				forceReboot,
				deferSeconds,
			}),
		);
	},
});

export { RebootDialog };

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withRouter(RebootDialog));
