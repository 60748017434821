import { defaultData, defaultParams } from 'modules/gtm/defaults';

const brand = 'Liquid Web';

const baskets = {
	BASKET_INITIALIZED: () => {
		return {
			...defaultParams,
			event: 'lw.structured',
			data: {
				...defaultData,
				category: 'Ecommerce',
				action: 'basket_created',
			},
		};
	},
	BASKET_ITEM_ADDED: ({ payload }) => {
		const { base_price: price, product_code: productCode } = payload;
		return {
			...defaultParams,
			event: 'lw.structured',
			data: {
				...defaultData,
				category: 'Ecommerce',
				action: 'basketItem_added',
			},
			ecommerce: {
				add: {
					products: [
						{
							name: productCode,
							price,
							quantity: 1,
							brand,
						},
					],
				},
			},
		};
	},
	BASKET_ITEM_REMOVED: ({ payload }) => {
		const { productCode } = payload;
		return {
			...defaultParams,
			event: 'lw.structured',
			data: {
				...defaultData,
				category: 'Ecommerce',
				action: 'basketItem_removed',
			},
			ecommerce: {
				remove: {
					products: [
						{
							name: productCode,
							quantity: 1,
							brand,
						},
					],
				},
			},
		};
	},
};

export default baskets;
