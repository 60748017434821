import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';
import moment from 'moment';
import { dateTime } from 'utility/datetime';
import orderBy from 'lodash/orderBy';
import { getId, getUniqId } from 'utility/redux/selectorHelperFunctions/common';

const getStateSlice = (state) => state.storm.backup.list;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'STORM_BACKUP_LIST',
	method: 'POST',
	url: '/storm/backup/list.json',
});

const sortedByDate = createSelector(
	defaultSelectors.getItems,
	(slice) =>
		slice
			? slice.sort(
					(a, b) =>
						new Date(a.get('time_taken')) - new Date(b.get('time_taken')),
			  )
			: null,
);

const selectedGenerator = (id) => {
	return createSelector(
		defaultSelectors.getNativeItems,
		(slice) => slice.find((backup) => backup.id === id) || {},
	);
};

const formattedList = createSelector(
	defaultSelectors.getNativeItems,
	getUniqId,
	(items, uniqId) => {
		return items
			.filter((item) => {
				if (uniqId) return item.uniq_id === uniqId;
				return true;
			})
			.map((backupObj) => {
				const { template, time_taken: timeTaken, name, size, id } = backupObj;
				const dateObj = moment(timeTaken);
				return {
					title: name,
					size,
					id,
					subtitle: template,
					date: dateTime(dateObj),
					dateFrom: dateObj.fromNow(),
				};
			});
	},
);

const selectors = {
	selectedGenerator,
	totalSize: createSelector(
		defaultSelectors.getItems,
		(slice) =>
			slice
				?.reduce((total, item) => total + Number(item.get('size')), 0)
				.toFixed(2),
	),
	itemTotal: createSelector(
		defaultSelectors.getNativeState,
		({ data }) => data?.item_total,
	),
	oldest: createSelector(
		sortedByDate,
		(slice) => (slice ? slice.first()?.toJS() : null),
	),
	newest: createSelector(
		sortedByDate,
		(slice) => (slice ? slice.last()?.toJS() : null),
	),
	selected: createSelector(
		defaultSelectors.getNativeItems,
		getId,
		(slice, id) => slice.find((backup) => backup.id === id),
	),
	formattedList,
	sortedFormattedList: createSelector(
		formattedList,
		(formattedList_) => orderBy(formattedList_, 'date', 'desc'),
	),
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };
