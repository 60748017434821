import React from 'react';
import { Switch } from 'react-router-dom';
import RoleRestrictedRoute from 'utility/routing/RoleRestrictedRoute';
import { roles } from 'utility/constants/roles';
import { withRouter } from 'react-router';
import dialogActions from 'modules/dialogs/actions';
import { connect } from 'react-redux';
import LWDialog from 'components/common/LWDialog';
import snackbarActions from 'modules/snackbar/snackbarActions';
import ConfirmCancel from 'components/common/Dialogs/ConfirmCancel';

import {
	actions as authActions,
	selectors as authSelectors,
} from 'modules/api/support/request/authenticateModule';
import LWTypography from 'components/common/LWTypography';
import { username as usernameSelector } from 'modules/auth/oidcSelectors';

const SupportRouter = ({
	hasFetched,
	hasError,
	snackbar,
	isLoading,
	authenticate,
	loggedInUser,
	history,
}) => {
	const url = new URL(window.location.href);
	const id = url.searchParams.get('case_id') || url.searchParams.get('id');
	const close = () => history.push('/');
	React.useEffect(() => {
		if (!id) {
			close();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [history]);
	React.useEffect(() => {
		if (hasFetched) {
			close();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hasFetched]);
	React.useEffect(() => {
		if (hasError) {
			snackbar('Error authenticating');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hasError]);

	return (
		<Switch>
			<RoleRestrictedRoute
				exact
				whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
				path="/support/request/authenticate"
				component={() => (
					<LWDialog open title="Confirmation Needed" onClose={close}>
						<LWTypography>
							You are signed in as {loggedInUser}. Please confirm you would like
							to authenticate this support request with this account.
						</LWTypography>
						<ConfirmCancel
							isLoading={isLoading}
							cancel={close}
							confirm={() => authenticate(id, loggedInUser)}
						/>
					</LWDialog>
				)}
			/>
		</Switch>
	);
};

const mapStateToProps = (state) => ({
	loggedInUser: usernameSelector(state),
	isLoading: authSelectors.isLoading(state),
	hasFetched: authSelectors.hasData(state),
	hasError: authSelectors.getError(state),
});

const mapDispatchToProps = (dispatch) => ({
	snackbar: (message) => dispatch(snackbarActions.pushMessage(message)),
	openDialog: ({ title, content, dialogProps }) =>
		dispatch(dialogActions.open({ title, content, dialogProps })),
	authenticate: (id, username) => dispatch(authActions.fetch({ id, username })),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withRouter(SupportRouter));
