import { createSelector } from 'reselect';

const getStateSlice = (state) => state.referAFriendDialog;

const selectors = {
	isOpen: createSelector(
		getStateSlice,
		(slice) => slice.isOpen,
	),
};
export default selectors;
