// TODO: make this file testable.
import { call, put, takeLatest, select, all, take } from 'redux-saga/effects';
import { actions as addActions } from 'modules/api/basket/item/addModule';
import { actions as modifyActions } from 'modules/api/basket/item/modifyModule';
import { actions as removeActions } from 'modules/api/basket/item/removeModule';

import { actions as bulkModifyActions } from 'modules/api/basket/item/bulkModifyModule';
import {
	actions as detailsActions,
	selectors as detailsSelectors,
} from 'modules/api/basket/detailsModule';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import map from 'lodash/map';
import findIndex from 'lodash/findIndex';
import find from 'lodash/find';
import {
	actions as LWContactActions,
	selectors as LWContactSelectors,
} from 'modules/api/contact/listModule';
import snackbarSaga from 'modules/snackbar/sagas';
import basketActions from '../actions';
import basketDomainsActions from '../actions/domains';
import detailsSaga from './details';

function* handleEditNameServers({ nameServers: { value } }) {
	const domainsInCart = yield select(
		detailsSelectors.getDomainRegistrationDomains,
	);

	const properties = {
		nameServers: value,
	};

	yield put(basketDomainsActions.toggleLoading({ key: 'DNS', value: true }));

	// Loop 'domains' in carts, call basket/item/modify, edit the name server
	yield all(
		domainsInCart.map(function* edit(
			{ uuid, properties: domainProperties },
			index,
		) {
			const domainWithNewProperties = {
				uuid,
				properties: {
					...domainProperties,
					...properties,
				},
			};

			yield put(modifyActions.fetch(domainWithNewProperties));

			// If last domain in cart, wait for modify api, then call basket/details
			// Why? -Caleb
			if (index === domainsInCart.length - 1) {
				yield take([modifyActions.setType, modifyActions.errorType]);
				yield call(detailsSaga);
				// yield put(basketDomainsActions.getDetails());
			}
		}),
	);

	yield put(basketDomainsActions.toggleLoading({ key: 'DNS', value: false }));
}

const mapKeys = (contactObj) => {
	// Use first phone number
	if (contactObj.phones) {
		/* eslint-disable-next-line no-param-reassign */
		contactObj.phone = contactObj.phones[0].number;
	}

	// Use first email
	if (contactObj.emails) {
		/* eslint-disable-next-line no-param-reassign */
		contactObj.email = contactObj.emails[0].address;
	}

	const keysObj = {
		fname: 'FirstName',
		lname: 'LastName',
		address: 'Address1',
		address2: 'Address2',
		email: 'EmailAddress',
		phone: 'Phone',
		city: 'City',
		state: 'StateProvince',
		country: 'Country',
		postal_code: 'PostalCode',
		organization: 'OrganizationName',
		fax: 'Fax',
		contact_role: 'JobTitle',
	};

	const keys = Object.keys(keysObj);

	const contact = {};

	keys.forEach((key) => {
		contact[keysObj[key]] = contactObj[key];
	});

	return contact;
};

function* handleEditWhoIs({ whoIs }) {
	const { lwtech: lwtechBool, ...contactObj } = whoIs;

	const contact = mapKeys(contactObj);

	const properties = {
		...contact,
		lwtech: Number(lwtechBool),
	};

	const domainsInCart = yield select(
		detailsSelectors.getDomainRegistrationDomains,
	);

	yield put(basketDomainsActions.toggleLoading({ key: 'WHO_IS', value: true }));
	// Loop 'domains' in carts, call basket/item/modify, edit the who is
	yield all(
		domainsInCart.map(function* edit(
			{ uuid, properties: domainProperties },
			index,
		) {
			const domainWithNewProperties = {
				uuid,
				properties: {
					...domainProperties,
					...properties,
				},
			};
			yield put(modifyActions.fetch(domainWithNewProperties));
			// If last domain in cart, wait for modify api, then call basket/details
			if (index === domainsInCart.length - 1) {
				yield take([modifyActions.setType, modifyActions.errorType]);
				yield put(basketDomainsActions.getDetails());
			}
		}),
	);

	yield put(
		basketDomainsActions.toggleLoading({ key: 'WHO_IS', value: false }),
	);
}

const boolToValue = (bool) =>
	bool === 'false' ? 'Off' : bool === 'true' && 'On';

function* handleEditDomainBasketItem(action) {
	const {
		duration,
		autoRenewPrivacy,
		autoRenewRegistration,
		uuid,
		configs: configsArr,
	} = action;

	const modifiedItem = { configs: configsArr, uuid };

	const configsObj = keyBy(configsArr, 'key');

	if (duration) {
		configsObj.DomainRegistration = { num_units: duration };
	}

	if (autoRenewPrivacy !== undefined && String(autoRenewPrivacy)) {
		configsObj.DomainPrivacy = {
			value: boolToValue(String(autoRenewPrivacy)),
		};

		// If auto renew privacy, apply auto renew duration also
		if (autoRenewPrivacy) {
			configsObj.DomainRegistrationAutoRenew = { value: 'On' };
		}
	}

	if (autoRenewRegistration !== undefined && String(autoRenewRegistration)) {
		configsObj.DomainRegistrationAutoRenew = {
			value: boolToValue(String(autoRenewRegistration)),
		};

		// If auto renew duration turned off, turn off auto renew privacy
		if (!autoRenewRegistration) {
			configsObj.DomainPrivacy = { value: 'Off' };
		}
	}

	modifiedItem.configs = map(configsObj, (value, key) => ({ key, ...value }));

	// Override

	const toOverride = {};

	const basketDetails = yield select(detailsSelectors.getNativeData);

	const { items: itemsInCart } = basketDetails;

	toOverride.itemIndex = findIndex(itemsInCart, { uuid });

	toOverride.domain = find(itemsInCart, { uuid });

	toOverride.configs = modifiedItem.configs.map(
		// eslint-disable-next-line camelcase
		({ key, value, num_units }) => ({
			key,
			value,
			num_units,
		}),
	);

	const newBasketDetails = basketDetails;

	const overrideConfigsObj = keyBy(toOverride.configs, 'key');
	const currentConfigs = keyBy(
		newBasketDetails.items[toOverride.itemIndex].configs,
		'key',
	);

	Object.keys(overrideConfigsObj).forEach((overrideConfigKey) => {
		if (overrideConfigsObj[overrideConfigKey].value) {
			currentConfigs[overrideConfigKey].value =
				overrideConfigsObj[overrideConfigKey].value;
		}
		if (overrideConfigsObj[overrideConfigKey].num_units) {
			currentConfigs[overrideConfigKey].num_units =
				overrideConfigsObj[overrideConfigKey].num_units;
		}
	});

	newBasketDetails.configs = map(currentConfigs, (value, key) => ({
		key,
		...value,
	}));

	yield put(detailsActions.override(newBasketDetails));

	// End Override

	yield put(modifyActions.fetch(modifiedItem));
	const modifyResult = yield take([
		modifyActions.setType,
		modifyActions.errorType,
	]);
	if (modifyResult.type === modifyActions.errorType) {
		yield put(basketActions.setError('edit Item'));
	}
	yield put(basketDomainsActions.getDetails());
}

function* handleBulkEdit(action) {
	const { duration, autoRenewPrivacy, autoRenewRegistration } = action;

	const domainsInCart = yield select(
		detailsSelectors.getDomainRegistrationDomains,
	);
	let modifiedDomains;

	// If duration was changed
	if (duration) {
		modifiedDomains = domainsInCart.map(({ uuid, properties }) => {
			let durationToSet = duration;

			/* eslint-disable camelcase */
			const min_period = get(properties, 'min_period', 1);
			const max_period = get(properties, 'max_period');

			if (durationToSet < min_period) durationToSet = min_period;
			if (durationToSet > max_period) durationToSet = max_period;
			/* eslint-enable camelcase */

			const configsObj = {};

			configsObj.DomainRegistration = { num_units: durationToSet };

			return {
				uuid,
				configs: map(configsObj, (value, key) => ({ key, ...value })),
			};
		});
	}

	// If auto renew privacy was changed
	if (autoRenewPrivacy !== undefined && String(autoRenewPrivacy)) {
		modifiedDomains = domainsInCart.map(({ uuid }) => {
			const configsObj = {};

			configsObj.DomainPrivacy = {
				value: boolToValue(String(autoRenewPrivacy)),
			};

			// If auto renew privacy, apply auto renew duration also
			if (autoRenewPrivacy) {
				configsObj.DomainRegistrationAutoRenew = { value: 'On' };
			}

			return {
				uuid,
				configs: map(configsObj, (value, key) => ({ key, ...value })),
			};
		});
	}

	// If auto renew duration was changed
	if (autoRenewRegistration !== undefined && String(autoRenewRegistration)) {
		modifiedDomains = domainsInCart.map(({ uuid }) => {
			const configsObj = {};

			configsObj.DomainRegistrationAutoRenew = {
				value: boolToValue(String(autoRenewRegistration)),
			};

			// If auto renew duration turned off, turn off auto renew privacy
			if (!autoRenewRegistration) {
				configsObj.DomainPrivacy = { value: 'Off' };
			}

			return {
				uuid,
				configs: map(configsObj, (value, key) => ({ key, ...value })),
			};
		});
	}

	const bulkModifyResult = yield put(
		bulkModifyActions.fetch({ items: modifiedDomains }),
	);
	if (bulkModifyResult.type === bulkModifyActions.errorType) {
		yield put(basketActions.setError('edit Item'));
	}
}

/* eslint-disable camelcase */
function* handleRemoveDomain({ uuid }) {
	yield put(removeActions.fetch({ uuid }));
	const removeResult = yield take([
		removeActions.setType,
		removeActions.errorType,
	]);
	const error = removeResult?.type === removeActions.errorType;
	yield call(snackbarSaga, {
		error,
		errorMessage: 'Removing the domain was not successful.',
		successMessage: 'The domain has been removed.',
	});
	if (error) {
		yield put(basketActions.setError('remove Domain'));
	} else {
		yield put(basketActions.itemRemoved({ uuid }));
	}
}

function* handleAddDomain({ uuid, domain, max_period, min_period }) {
	const basketDomainsReducer = yield select((state) => state.basketDomains);

	// User has saved nameServers, using it for new domains in cart
	const nameServers = get(basketDomainsReducer, 'nameServers.value', [
		'ns.liquidweb.com',
		'ns1.liquidweb.com',
	]);

	const configs = [
		{
			key: 'DomainRegistration',
			value: 'Registration.Enom',
			num_units: min_period,
		},
		{
			key: 'DomainRegistrationAutoRenew',
			value: 'On',
		},
		{ key: 'DomainPrivacy', value: 'On' },
	];

	const domainObj = {
		basket: uuid,
		product_code: 'DREG',
		configs,
		properties: {
			domain,
			max_period,
			min_period,
			nameServers,
		},
		region: 1,
	};

	const savedWhoIs = get(basketDomainsReducer, 'whoIs');

	let contactToSet;

	// User has saved whoIs, using it for new domains in cart
	if (savedWhoIs) {
		contactToSet = savedWhoIs;
	}
	const contactsList = yield select((state) =>
		LWContactSelectors.getItems(state),
	);

	// No contact list yet fetched, fetch it, then use the first contact
	if (!contactsList) {
		yield put(LWContactActions.fetch());
		const contactsAction = yield take([
			LWContactActions.setType,
			LWContactActions.errorType,
		]);

		contactToSet = get(contactsAction, 'payload.items[0]');

		domainObj.properties = {
			...domainObj.properties,
			...mapKeys(contactToSet),
		};
	} else {
		// Contact list in state, use first contact
		contactToSet = contactsList.toJS();
		const firstContact = contactToSet[0];
		domainObj.properties = {
			...domainObj.properties,
			...mapKeys(firstContact),
		};
	}
	yield put(addActions.fetch(domainObj));

	// Override

	const basketDetails = yield select(detailsSelectors.getNativeData);
	const newBasketDetails = basketDetails || {};
	const newItems = get(newBasketDetails, 'items', []);

	newItems.push(domainObj);
	newBasketDetails.items = newItems;

	yield put(detailsActions.override(newBasketDetails));
	// End Override

	const addResult = yield take([addActions.setType, addActions.errorType]);
	if (addResult.type === addActions.errorType) {
		yield put(basketActions.setError('add Domain'));
	} else {
		yield put(basketActions.itemAdded(domainObj));
	}

	// yield take([detailsActions.setType, detailsActions.errorType]);
}

export default function* brute() {
	yield takeLatest(basketDomainsActions.GET_DETAILS, detailsSaga);

	// Bulk edit (auto renew duration / dns)
	yield takeLatest(basketDomainsActions.BULK_EDIT, handleBulkEdit);

	// Edit basket item from domains/configure (auto renew / duration)
	yield takeLatest(
		basketDomainsActions.EDIT_DOMAIN_BASKET_ITEM,
		handleEditDomainBasketItem,
	);

	yield takeLatest(
		basketDomainsActions.EDIT_NAME_SERVERS,
		handleEditNameServers,
	);

	yield takeLatest(basketDomainsActions.SUBMIT_WHO_IS, handleEditWhoIs);

	yield takeLatest(basketDomainsActions.ADD_DOMAIN, handleAddDomain);
	yield takeLatest(basketDomainsActions.REMOVE_DOMAIN, handleRemoveDomain);
}
