export default {
	base_price: '199.00000',
	basket_uuid: '56198bde-099d-40c8-af5c-03733ee2e201',
	created: '2021-05-03 08:45:14.497432-04',
	discount: '119.40000',
	product_code: 'DS.1230v5.Base',
	properties: {},
	region: 1,
	tax: null,
	uuid: 'fcbfd1a1-f096-4b8b-a60a-fcb2cec83d9a',
	valid: 1,
};
