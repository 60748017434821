import produce from 'immer';
import { actions as detailsActions } from 'modules/api/billing/payment/profile/detailsModule';
import { actions as hostedFormTokenActions } from 'modules/api/billing/payment/profile/hostedFormTokenModule';
import paymentFormActions from 'modules/paymentForm/actions';

export const IS_ADDING_PAYMENT = 'addPayment';
export const IS_EDITING_PAYMENT = 'editPayment';

const initialState = {
	isOpen: false, // show or not
	isLoading: false, // used when re fetching details
	isOneTimePayment: false,
	form_token: null,
	formData: {
		type: null,
		paymentMethod: null,
		paymentProfileId: null,
	},
	iframe: {
		isLoading: false,
	},
};
/* eslint-disable default-case, no-param-reassign */
const reducer = (state = initialState, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case detailsActions.setType:
				draft.isLoading = false;
				break;
			case hostedFormTokenActions.setType:
				draft.isOpen = true;
				draft.form_token = action.payload.form_token;
				break;
			case paymentFormActions.PAYMENT_FORM_TOGGLE_OPEN_MODAL:
				if (action.payload.isOpen) {
					draft.iframe.isLoading = true;
				}
				draft.isLoading = false;
				draft.iframe.isLoading = false;
				draft.isOpen = action.payload.isOpen;

				break;

			case paymentFormActions.PAYMENT_FORM_TOGGLE_LOADING:
				draft.isLoading = action.payload.isLoading;
				break;

			case paymentFormActions.PAYMENT_FORM_TOGGLE_IFRAME_LOADING:
				draft.iframe.isLoading = action.payload.isLoading;
				break;

			case paymentFormActions.PAYMENT_FORM_INITIALIZE_HOSTED_FORM: {
				const { payload } = action;
				const { type, paymentMethod, paymentProfileId } = payload;
				draft.isOpen = true;
				draft.isLoading = true;
				draft.iframe.isLoading = true;

				draft.formData.type = type;
				draft.formData.paymentMethod = paymentMethod;
				draft.formData.paymentProfileId = paymentProfileId;
				break;
			}
		}
	});

export default reducer;
