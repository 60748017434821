const contentKeyToTitleMap = {
	AbandonCartModalContents: 'Abandon Cart?',
	AddAcronisModalContents: 'Switching to Acronis Backups',
	AcronisCancelConfirmationDialogContents: 'Cancel Acronis - Confirmation',
	AcronisCancelDialogContents: 'Cancel Acronis',
	BackupConfirmationDialogContents: 'Restore Server?',
	BackupRestoreDialogContents: 'Restore Server to a Previous State',
	CreateNewCartModalContents: 'Create New Cart?',
	ImageConfirmationDialogContents: 'Reimage Server',
	ImageServerSelectDialogContents: 'Reimage Server',
	TemplateConfirmationDialogContents: 'Reimage Server?',
};

const contentKeyToAddPadding = {
	BackupConfirmationDialogContents: true,
	BackupRestoreDialogContents: true,
	CloneServerModalContents: true,
	ImageConfirmationDialogContents: true,
	ImageServerSelectDialogContents: true,
	TemplateConfirmationDialogContents: true,
};

const contentKeyToColor = {};

export { contentKeyToTitleMap, contentKeyToColor, contentKeyToAddPadding };
