import { spawn } from 'redux-saga/effects';

import { sagas as detailsSagas } from './detailsModule';
import marketplace from './marketplace/marketplaceSagas';
import { sagas as price } from './priceModule';

export default function* sagas() {
	yield spawn(detailsSagas);
	yield spawn(marketplace);
	yield spawn(price);
}
