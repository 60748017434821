const actions = {
	DELETE_PROJECT_DIALOG_TOGGLE: 'DELETE_PROJECT_DIALOG_TOGGLE',
	toggleDialog: (isOpen) => ({
		type: actions.DELETE_PROJECT_DIALOG_TOGGLE,
		isOpen,
	}),
	DELETE_PROJECT_DIALOG_SET_PROPS: 'DELETE_PROJECT_DIALOG_SET_PROPS',
	setProps: (dialogProps) => ({
		type: actions.DELETE_PROJECT_DIALOG_SET_PROPS,
		dialogProps,
	}),
};

export default actions;
