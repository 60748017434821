import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import flexSaga from 'utility/redux/saga/flexSaga';
import idpClient from 'utility/redux/saga/idpClient';

const getStateSlice = (state) => state.openid.logout;

const { actions, reducer, sagas, selectors } = createAPIModule({
	getStateSlice,
	actionType: 'OPENID_LOGOUT',
	method: 'GET',
	url: 'logout',
	client: flexSaga(idpClient),
	prefix: 'openid',
});

export { actions, reducer, sagas, selectors };
