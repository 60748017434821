import pricingUnits from 'utility/constants/pricingUnits';

const desiredPricing = (prices) => {
	// First find units we'd prefer to show to users (month or year)
	if (prices.month)
		return { price: prices.month, priceUnit: pricingUnits.month };
	if (prices.year) return { price: prices.year, priceUnit: pricingUnits.year };

	// Otherwise just return whatever the first price we have is
	const rawPriceUnit = Object.keys(prices || {})[0];
	return {
		price: Object.values(prices || {})[0],
		priceUnit: pricingUnits[rawPriceUnit],
	};
};

export default ({ marketplacePrices, region }) => {
	const priceForRegion = marketplacePrices?.find(
		(pricing) => Number(pricing.region_id) === region,
	);

	// There can be multiple price values returned if this is a prepay product
	const { price: prices, is_prepay: isPrepay } = priceForRegion || {};

	if (isPrepay) {
		return {
			price: prices['one-time'],
			priceUnit: pricingUnits['one-time'],
		};
	}
	return desiredPricing(prices);
};
