import { createSelector } from 'reselect';

const getStateSlice = (state) => state.accountRedux.address;

const getAddress = createSelector(
	getStateSlice,
	(slice) => slice.address,
);

const getSubmit = createSelector(
	getStateSlice,
	(slice) => slice.submit,
);

const getIsValidateDialogOpen = createSelector(
	getStateSlice,
	(slice) => slice.isValidateDialogOpen,
);

export { getStateSlice, getAddress, getSubmit, getIsValidateDialogOpen };
