import emitter from './emitter';
import { getAllConfig, getConfig } from './config';
import { sendEvent as sendGoogleAnalyticsEvent } from './google-analytics';

const DEFAULT_EVENT_CATEGORY = 'chat';

const DATALAYER_EVENT_NAME = 'lw.structured';
const DATALAYER_NAME = 'dataLayer';

const analyticsEvents = {
	onAgentJoinedConference: {
		action: 'agent_joined',
	},
	onAgentLeftConference: {
		action: 'agent_left',
	},
	onAgentMessage: {
		action: 'message_sent',
		label: 'agent',
	},
	onChasitorMessage: {
		action: 'message_sent',
		label: 'chasitor',
	},
	onChatConferenceEnded: {
		action: 'conference_ended',
	},
	onChatConferenceInitiated: {
		action: 'conference_initiated',
	},
	onChatEndedByAgent: {
		action: 'chat_ended',
		label: 'agent',
	},
	onChatEndedByChasitor: {
		action: 'chat_ended',
		label: 'chasitor',
	},
	onChatReconnectSuccessful: {
		action: 'chat_reconnected',
	},
	onChatRequestSuccess: {
		action: 'chat_succeeded',
	},
	onChatTransferSuccessful: {
		action: 'chat_transferred',
	},
	onConnectionError: {
		action: 'error_occurred',
		label: 'connection',
	},
	onIdleTimeoutOccurred: {
		action: 'timeout_occurred',
		label: 'idle',
	},
	onQueueUpdate: {
		action: 'queue_updated',
	},
	onClickSubmitButton: {
		action: 'submitButton_clicked',
	},
	onHelpButtonClick: {
		action: 'helpButton_clicked',
	},
	onInviteAccepted: {
		action: 'invite_accepted',
	},
	onInviteRejected: {
		action: 'invite_rejected',
	},
	LWButtonClickTimeout: {
		action: 'timeout_occurred',
		label: 'click',
	},
	LWESWScriptLoadFailure: {
		action: 'error_occurred',
		label: 'scriptLoadFailure',
	},
	LWPrechatSurveySubmitted: {
		action: 'prechatSurvey_submitted',
		getLabel: (data = []) => data.find(i => i.label === 'Department')?.value,
	},
};

const sendDataLayerEvent = ({ category, action, label }) => (
	window[DATALAYER_NAME].push({
		event: DATALAYER_EVENT_NAME,
		data: {
			category,
			action,
			label,
		},
	})
);

const sendEvent = ({ category = DEFAULT_EVENT_CATEGORY, action, label }) => {
	const { analyticsMode, analyticsActionContext } = getAllConfig();

	const actionWithContext = `${analyticsActionContext}:${action}`;

	switch (analyticsMode) {
		case 'googleAnalytics':
			sendGoogleAnalyticsEvent({
				category,
				action: actionWithContext,
				label,
			});

			break;

		case 'dataLayer':
			sendDataLayerEvent({
				category,
				action: actionWithContext,
				label,
			});

			break;

		default:
	}
};

const handleEvent = (eventName, data) => {
	const { action, label, getLabel } = analyticsEvents[eventName];

	if (!action) return;

	if (typeof getLabel === 'function') {
		sendEvent({
			action,
			label: getLabel(data),
		});

		return;
	}

	sendEvent({ action, label });
};

const watchEvents = () => {
	Object.keys(analyticsEvents)
		.forEach((eventName) => {
			emitter.on(eventName, handleEvent.bind(null, eventName));
		});
};

const maybeStubDatalayer = () => {
	const analyticsMode = getConfig('analyticsMode');

	if (analyticsMode === 'dataLayer') {
		window[DATALAYER_NAME] = window[DATALAYER_NAME] || [];
	}
};

const init = () => {
	maybeStubDatalayer();
	watchEvents();
};

export {
	sendEvent,
	init,
};
