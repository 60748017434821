const list = {
	item_count: 1,
	item_total: 1,
	items: [
		{
			accnt: 3378300,
			bucket_uniq_id: null,
			config_description:
				"'Intel(R) Xeon(R) CPU           E5620  @ 2.40GHz', 2400MHz, 4 Core, Sockets: 2",
			config_id: 7,
			create_date: '2020-01-28 12:01:35',
			diskDetails: {
				allocated: 280,
				snapshots: 93,
			},
			domain: 'privateparent',
			id: '12424845',
			license_state: 'mixed',
			project_name: null,
			region_id: 1,
			resources: {
				diskspace: {
					free: 1476,
					total: 1849,
					used: 373,
				},
				memory: {
					free: 22133,
					total: 32229,
					used: 10096,
				},
			},
			salesforce_asset: '02i2F000003L6NwQAK',
			status: 'Active',
			subaccnt: '12424845',
			type: 'SS.PP',
			uniq_id: 'Z8064R',
			vcpu: 16,
			zone: {
				availability_zone: 'C',
				description: 'Zone C',
				hv_type: 'kvm',
				id: 40460,
				legacy: 0,
				name: 'b3s7z1',
				region: {
					id: 1,
					name: 'US Central',
				},
				status: 'open',
				valid_source_hvs: ['xen', 'kvm'],
			},
		},
	],
	page_num: 1,
	page_size: 25,
	page_total: 1,
};

export default list;
