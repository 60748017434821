import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Header from './Header';
import Details from './Details';
import Admin from './Admin';

const CartItem = ({
	item = { productInfo: {}, totals: {} },
	title,
	hideDomain,
	hideEditHostname,
	hideConfigure,
	hideClone,
	secondaryInfo,
	details,
	isLoading,
	readOnly,
	hideDetails,
	isBasketAdmin,
	salesRepEmail = '',
	setRemoved = () => {},
	removed,
	subtitle,
	perMonthLabel,
	hostnameRequired = true,
}) => {
	const {
		maxDiscount,
		properties,
		productInfo: { title: productInfoTitle } = {},
		totals: {
			item_price_total: itemPriceTotal,
			item_discount_total: itemDiscountTotal,
		} = {},
		uuid,
	} = item;
	const propsForContent = {
		hideDomain,
		hideEditHostname,
		hideConfigure,
		hideClone,
		setRemoved,
		removed,
		secondaryInfo,
		readOnly,
		cloneFrom: properties?.uniq_id_to_clone, // TODO: NEWMAN-1782, get real property from BE. (I made up uniq_id_to_clone)
	};

	const headerTitle = title || productInfoTitle; // Some products may provide a custom title

	const hostnameOrSubtitle = (() => {
		if (subtitle) return subtitle;
		if (properties?.domain) {
			return properties.domain;
		}
		return '';
	})();

	const projectName = properties?.project?.project_name;

	const { customerNotes, internalNotes } = properties || {};

	return (
		<Box width="100%" data-testid="CartItem__Container">
			<Header
				title={headerTitle}
				hostnameOrSubtitle={!hostnameRequired || hostnameOrSubtitle}
				projectName={projectName}
				itemUuid={uuid}
				itemPriceTotal={Number(itemPriceTotal)}
				itemDiscountTotal={Number(itemDiscountTotal)}
				isLoading={isLoading}
				propsForContent={propsForContent}
				itemDetails={item}
				perMonthLabel={perMonthLabel}
			/>
			{!hideDetails && (details || customerNotes) && (
				<Details
					details={details}
					customerNotes={customerNotes}
					// TODO, get salesRepEmail from backend and move into a connected version of detials
					salesRepEmail={salesRepEmail}
				/>
			)}
			{!readOnly && isBasketAdmin && (
				<Admin
					itemUuid={uuid}
					itemProperties={properties}
					isLoading={isLoading}
					customerNotes={customerNotes}
					internalNotes={internalNotes}
					maxDiscount={maxDiscount}
				/>
			)}
		</Box>
	);
};

CartItem.propTypes = {
	/** Item specs from the back end. See ./testData for more info. */
	item: PropTypes.object,
	/** Title that appears at the top of the display */
	title: PropTypes.string,
	/** Secondary text that appears just right of title. Overrides domain if present. */
	subtitle: PropTypes.string,
	/** Data that displays below editing options */
	secondaryInfo: PropTypes.arrayOf(
		PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.shape({
				value: PropTypes.string,
				subitems: PropTypes.arrayOf(PropTypes.string),
			}),
		]),
	),
	/** data that appears in the "Details" expansion panel. */
	details: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			subtitle: PropTypes.string.isRequired,
			children: PropTypes.arrayOf(
				PropTypes.shape({
					title: PropTypes.string.isRequired,
					subtitle: PropTypes.string.isRequired,
				}),
			),
		}),
	),
	/** Can this item be edited? */
	readOnly: PropTypes.bool,
	/** Is this item loading? */
	isLoading: PropTypes.bool,
	/** Should the domain be visible? */
	hideDomain: PropTypes.bool,
	/** Should we be allow to edit the hostname? */
	hideEditHostname: PropTypes.bool,
	/** Is there a config screen to link to? */
	hideConfigure: PropTypes.bool,
	/** Is this item cloanable? */
	hideClone: PropTypes.bool,
	/** Should the admin section display? */
	isBasketAdmin: PropTypes.bool,
	/** Passed down from parent to Details. */
	salesRepEmail: PropTypes.string,
	/** instructions from parent for hiding item during removal. */
	setRemoved: PropTypes.func,
	/** Has item removal been attempted? */
	removed: PropTypes.bool,
	/** Should the "/ mo" label appear after the price? */
	perMonthLabel: PropTypes.bool,
	/** Show the HostnameNeeded component if no hostname is passed in */
	hostnameRequired: PropTypes.bool,
};

CartItem.defaultProps = {
	title: undefined,
	isLoading: false,
	readOnly: false,
	hideDomain: false,
	isBasketAdmin: false,
	removed: false,
};

export default CartItem;
