import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import LWLink from 'components/common/LWLink';
import SubNavMenu from 'components/structural/topbar/SubNavMenu';
import Badge from '@material-ui/core/Badge';
import LWTypography from 'components/common/LWTypography';

export const styles = (theme) => ({
	root: {
		display: 'flex',
		'&:hover': {
			textDecoration: 'none',
		},
		textDecoration: 'none',
		alignItems: 'center',
		height: '100%',
		[theme.breakpoints.up('sm')]: {
			paddingLeft: '1em',
			paddingRight: '1em',
		},
		[theme.breakpoints.down('sm')]: {
			flex: '0 0 3.5em',
		},
		color: theme.palette.common.accent[0],
	},
	iconWrapper: {
		color: theme.palette.common.accent[0],
		lineHeight: 0.8,
		textAlign: 'center',
		fontSize: '36px',
		'& .MuiBadge-badge': {
			top: '6px',
		},
	},
	activeLink: {
		color: theme.palette.primary.contrastText,
		textDecoration: 'none',
		backgroundColor: theme.palette.primary.main,
		'& $iconWrapper': {
			color: '#fff',
		},
	},
	submenu: {
		borderTopWidth: '5px',
		borderTopStyle: 'solid',
		borderTopColor: theme.palette.other.purpleHeader,
	},
});
class NavButton extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			anchorEl: null,
		};
	}

	handleHover = (event) => {
		this.setState({ anchorEl: event.currentTarget });
	};

	handleHoverClose = () => {
		this.setState({ anchorEl: null });
	};

	generateContent = () => {
		const { classes, name, icon, badge } = this.props;
		return (
			<Box>
				<div className={classes.iconWrapper}>
					<Badge
						invisible={!badge}
						badgeContent={badge && badge.content}
						variant={badge && badge.isDot ? 'dot' : 'standard'}
						color="error"
					>
						{icon({
							fontSize: 'inherit',
							color: 'inherit',
						})}
					</Badge>
				</div>
				<Hidden smDown>
					<LWTypography variant="subtitle2" color="inherit">
						{name}
					</LWTypography>
				</Hidden>
			</Box>
		);
	};

	generateLink = () => {
		const {
			badge,
			classes,
			path,
			name,
			onClick,
			subNavData,
			isExternal,
			isLink,
			icon,
			...otherProps
		} = this.props;
		const { anchorEl } = this.state;

		return (
			<NavLink
				exact={path === '/'}
				to={path}
				activeClassName={classNames(classes.root, classes.activeLink)}
				className={classes.root}
				onMouseEnter={this.handleHover}
				onMouseLeave={this.handleHoverClose}
				onClick={onClick}
				data-testid={`NavButton__${name}`}
				name={name}
				{...otherProps}
			>
				{this.generateContent()}

				{subNavData && anchorEl && (
					<Hidden smDown>
						<SubNavMenu
							anchorEl={anchorEl}
							subNavData={subNavData}
							parentName={name}
						/>
					</Hidden>
				)}
			</NavLink>
		);
	};

	generateExternalLink = () => {
		const {
			badge,
			classes,
			path,
			name,
			onClick,
			subNavData,
			isLink,
			isExternal,
			icon,
			...otherProps
		} = this.props;
		const { anchorEl } = this.state;

		return (
			<LWLink
				to={path}
				className={classes.root}
				onMouseEnter={this.handleHover}
				onMouseLeave={this.handleHoverClose}
				onClick={onClick}
				name={name}
				{...otherProps}
			>
				{this.generateContent()}

				{subNavData && (
					<Hidden smDown>
						<SubNavMenu
							anchorEl={anchorEl}
							subNavData={subNavData}
							parentName={name}
						/>
					</Hidden>
				)}
			</LWLink>
		);
	};

	generateButton = () => {
		const { classes, onClick } = this.props;
		return (
			<NavLink className={classes.root} to="#" onClick={onClick}>
				{this.generateContent()}
			</NavLink>
		);
	};

	render() {
		const { isLink, isExternal } = this.props;
		if (isExternal) return this.generateExternalLink();
		if (isLink) return this.generateLink();
		return this.generateButton();
	}
}

NavButton.defaultProps = {
	path: '#',
	subNavData: null,
	isLink: true,
	onClick: null,
	badge: null,
};

NavButton.propTypes = {
	classes: PropTypes.object.isRequired,
	icon: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
	isExternal: PropTypes.bool,
	isLink: PropTypes.bool,
	onClick: PropTypes.func,
	path: PropTypes.string,
	subNavData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	badge: PropTypes.oneOfType([
		PropTypes.shape({
			content: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
			isDot: PropTypes.bool,
		}),
		PropTypes.bool,
	]),
};

export default withStyles(styles)(NavButton);
export { NavButton };
