import React from 'react';
import PropTypes from 'prop-types';
import LWButton from 'components/common/LWButton';
import DialogActions from '@material-ui/core/DialogActions';

const ConfirmCancel = ({
	cancel,
	cancelText = 'Cancel',
	confirm = () => {},
	confirmText = 'Confirm',
	confirmType = 'button',
	disabled = false,
	isLoading,
}) => (
	<DialogActions>
		<LWButton color="tertiary" onClick={cancel}>
			{cancelText}
		</LWButton>
		<LWButton
			type={confirmType}
			onClick={confirm}
			variant="contained"
			color="secondary"
			isLoading={isLoading}
			disabled={disabled}
		>
			{confirmText}
		</LWButton>
	</DialogActions>
);

ConfirmCancel.propTypes = {
	cancelText: PropTypes.string,
	cancel: PropTypes.func.isRequired,
	confirmText: PropTypes.string,
	confirmType: PropTypes.oneOf(['button', 'submit']),
	confirm: PropTypes.func,
	isLoading: PropTypes.bool,
};

export default ConfirmCancel;
