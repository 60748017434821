import Immutable from 'immutable';

const handleImmutability = (type, contact, asImmutable, mapFunction) => {
	if (!contact) return contact;

	let contactInstance = contact;
	if (!Immutable.Map.isMap(contactInstance))
		contactInstance = Immutable.fromJS(contactInstance);
	contactInstance = mapFunction(contactInstance);
	if (asImmutable) return contactInstance;
	return contactInstance.toJS();
};

const stripPrefix = (type, contact, asImmutable = true) =>
	handleImmutability(type, contact, asImmutable, (contactInstance) =>
		contactInstance.mapKeys((key) => key.replace(type.prefix, '')),
	);

const rehydratePrefix = (type, contact, asImmutable = true) =>
	handleImmutability(type, contact, asImmutable, (contactInstance) =>
		contactInstance.mapKeys((key) => type.prefix + key),
	);

const types = {
	REGISTRANT: {
		displayName: 'Registrant',
		keyCheck: 'RegistrantAddress1',
		prefix: 'Registrant',
	},
	ADMIN: {
		displayName: 'Admin',
		keyCheck: 'AdminAddress1',
		prefix: 'Admin',
	},
	TECH: {
		displayName: 'Tech',
		keyCheck: 'TechAddress1',
		prefix: 'Tech',
	},
};

export { stripPrefix, rehydratePrefix, types };
