import React from 'react';
import PropTypes from 'prop-types';

// redux
import { useDispatch } from 'react-redux';
import dialogActions from 'modules/dialogs/actions';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

// custom components
import LWTypography from 'components/common/LWTypography';
import ConfirmCancel from 'components/common/Dialogs/ConfirmCancel';

const AcronisCancelDialogContents = ({ serverId, uniqId }) => {
	const dispatch = useDispatch();
	const closeDialog = () => dispatch(dialogActions.close());
	const goToNextStep = () =>
		dispatch(
			dialogActions.open({
				contentKey: 'AcronisCancelConfirmationDialogContents',
				contentProps: { serverId, uniqId },
				color: 'danger',
			}),
		);

	return (
		<>
			<Grid container direction="column">
				<Box mb={2}>
					<LWTypography gutterBottom>
						When canceling your Acronis backups plan, the cancelation will
						happen immediately.{' '}
						<b>This will delete all backup storage for the server.</b>
					</LWTypography>
				</Box>
			</Grid>
			<ConfirmCancel
				confirmText="Continue"
				cancel={closeDialog}
				confirm={goToNextStep}
			/>
		</>
	);
};

AcronisCancelDialogContents.propTypes = {
	serverId: PropTypes.string,
	uniqId: PropTypes.string.isRequired,
};

export default AcronisCancelDialogContents;
