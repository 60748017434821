import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';
import { List as ImmutableList } from 'immutable';

const getStateSlice = (state) => state.licensing.varietyProductOptions;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'LICENSING_VARIETYPRODUCTOPTIONS',
	method: 'POST',
	url: '/licensing/varietyProductOptions.json',
});

const varietyProductOptions = createSelector(
	getStateSlice,
	(slice) => slice.getIn(['data', 'varietyProductOptions'], ImmutableList()),
);

const cPanel = createSelector(
	varietyProductOptions,
	(slice) => slice.get('cpanel', ImmutableList()).toJS(),
);

const selectors = {
	varietyProductOptions,
	cPanel,
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };
