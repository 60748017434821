import produce from 'immer';
import actions from './actions';

const initialState = {
	isOpen: false,
	hiddenNotifications: [],
	height: 0,
};

/* eslint-disable default-case, no-param-reassign */
const reducer = (state = initialState, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case actions.OPEN:
				draft.isOpen = true;
				break;
			case actions.CLOSE:
				draft.isOpen = false;
				break;
			case actions.HIDE_NOTIFICATIONS:
				draft.hiddenNotifications = [
					...state.hiddenNotifications,
					...action.ids,
				];
				break;
			case actions.SET_HEIGHT:
				draft.height = action.size;
				break;
		}
	});
export default reducer;
