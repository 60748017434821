const actions = {
	BACKUPS_AND_IMAGES_RESTORE_OPEN_IMAGE_DIALOG:
		'BACKUPS_AND_IMAGES_RESTORE_OPEN_IMAGE_DIALOG',
	openImageDialog: ({ id, uniqId }) => ({
		type: actions.BACKUPS_AND_IMAGES_RESTORE_OPEN_IMAGE_DIALOG,
		id,
		uniqId,
	}),
	BACKUPS_AND_IMAGES_RESTORE_OPEN_IMAGE_CONFIRM_DIALOG:
		'BACKUPS_AND_IMAGES_RESTORE_OPEN_IMAGE_CONFIRM_DIALOG',
	openImageConfirmDialog: ({ id, uniqId }) => ({
		type: actions.BACKUPS_AND_IMAGES_RESTORE_OPEN_IMAGE_CONFIRM_DIALOG,
		id,
		uniqId,
	}),
	BACKUPS_AND_IMAGES_RESTORE_INITIALIZE_SERVER:
		'BACKUPS_AND_IMAGES_RESTORE_INITIALIZE_SERVER',
	initializeServer: ({ uniqId }) => ({
		type: actions.BACKUPS_AND_IMAGES_RESTORE_INITIALIZE_SERVER,
		uniqId,
	}),
	BACKUPS_AND_IMAGES_RESTORE_IMAGE: 'BACKUPS_AND_IMAGES_RESTORE_IMAGE',
	restoreImage: ({ id, uniqId, force }) => ({
		type: actions.BACKUPS_AND_IMAGES_RESTORE_IMAGE,
		id,
		uniqId,
		force,
	}),
	BACKUPS_AND_IMAGES_RESTORE_BACKUP: 'BACKUPS_AND_IMAGES_RESTORE_BACKUP',
	restoreBackup: ({ id, uniqId, force }) => ({
		type: actions.BACKUPS_AND_IMAGES_RESTORE_BACKUP,
		id,
		uniqId,
		force,
	}),
	BACKUPS_AND_IMAGES_RESTORE_TEMPLATE: 'BACKUPS_AND_IMAGES_RESTORE_TEMPLATE',
	restoreTemplate: ({ id, uniqId, force }) => ({
		type: actions.BACKUPS_AND_IMAGES_RESTORE_TEMPLATE,
		id,
		uniqId,
		force,
	}),
};

export default actions;
