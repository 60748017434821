import React from 'react';
import Box from '@material-ui/core/Box';
import LWLink from 'components/common/LWLink';

const PaddedLink = ({ ...LWLinkProps }) => (
	<Box pr={2}>
		<LWLink {...LWLinkProps} />
	</Box>
);
export default PaddedLink;
