import { connect } from 'react-redux';
import basketActions from 'modules/basket/actions';
import { selectors as itemModifySelectors } from 'modules/api/basket/item/modifyModule';
import { selectors as addDiscountSelectors } from 'modules/api/basket/addDiscountModule';
import { selectors as basketDetailsSelectors } from 'modules/api/basket/detailsModule';
import Admin from './Admin';

const mapStateToProps = (state) => ({
	isLoading:
		addDiscountSelectors.isLoading(state) ||
		itemModifySelectors.isLoading(state) ||
		// basketDetailsSelectors.hasData is causing snapshots to fail when importing this component's connected version.
		// TODO: Use snapshots only for atoms and molecules.
		!basketDetailsSelectors.hasData(state),
	error:
		addDiscountSelectors.getErrorString(state) ||
		itemModifySelectors.getErrorString(state),
	discountPercByItemUuid: basketDetailsSelectors.discountPercByItemUuid(state),
});

const mapDispatchToProps = (dispatch) => ({
	adminItemModify: ({ properties, discountPercent, discountUuid, itemUuid }) =>
		dispatch(
			basketActions.adminItemModify({
				properties,
				discountPercent,
				itemUuid,
				discountUuid,
			}),
		),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Admin);
