import { call, put, takeLatest, select, spawn } from 'redux-saga/effects';
import { USER_FOUND, USER_EXPIRED } from 'redux-oidc';
import isUUID from 'validator/es/lib/isUUID';
import { push } from 'connected-react-router';
import get from 'lodash/get';
import {
	hasAuthToken as hasAuthTokenSelector,
	isKeroOnly as isKeroOnlySelector,
	isBasketAdmin as isBasketAdminSelector,
} from 'modules/auth/oidcSelectors';
import {
	getFullPathName,
	getPathName,
	getQueryParams,
} from 'modules/routeSelectors';
import snackbarActions from 'modules/snackbar/snackbarActions';
import { BASKET_UUID } from 'utility/constants/baskets';
import {
	REDIRECT_PATH,
	OPP_ID,
	CLONE_BASKET,
	UUID_IS_FROM_URL,
} from 'utility/constants/auth';

import { selectors as appConfigSelectors } from 'modules/api/appConfigModule';
import { actions as addActions } from 'modules/api/basket/item/addModule';
import { actions as assertMetadataActions } from 'modules/api/basket/assertMetadataModule';
import { actions as bulkModifyActions } from 'modules/api/basket/item/bulkModifyModule';
import { actions as itemModifyActions } from 'modules/api/basket/item/modifyModule';
import { actions as itemAssertPropertiesActions } from 'modules/api/basket/item/assertPropertiesModule';
import { actions as itemSwapActions } from 'modules/api/basket/item/swapModule';

import { actions as removeActions } from 'modules/api/basket/item/removeModule';

import { actions as addCouponActions } from 'modules/api/basket/addCouponModule';
import { actions as addDiscountActions } from 'modules/api/basket/addDiscountModule';
import { actions as claimActions } from 'modules/api/basket/claimModule';
import { actions as updateDiscountActions } from 'modules/api/basket/updateDiscountModule';
import { actions as cloneItemActions } from 'modules/api/basket/item/cloneModule';
import { actions as detailsActions } from 'modules/api/basket/detailsModule';
import { actions as removeCouponActions } from 'modules/api/basket/removeCouponModule';
import { actions as removeDiscountActions } from 'modules/api/basket/removeDiscountModule';
import { actions as resetActions } from 'modules/api/basket/item/resetModule';

import abandonSaga from './abandon';
import acronisSaga from './acronis';
import addItemSaga from './addItem';
import basketActions from '../actions';
import claimSaga, { claimBasket } from './claim';
import cloneSaga from './clone';
import createSaga from './create';
import detailsSaga from './details';
import domainSaga from './domains';
import extraOptionsSaga from './extraOptions';
import removeItemSaga from './removeItem';
import savedSaga from './saved';
import setItemConfigSaga from './setItemConfig';
import storeUuid from './storeUuid';
import setExpireSaga from './setExpire';
import adminItemModify from './adminItemModify';
import assertMetadata from './assertMetadata';
import setCartNameSaga from './setCartName';
import resetItemConfigSaga from './resetItemConfig';
import cloneItemSaga from './cloneItem';
import itemAssertPropertiesSaga from './itemAssertProperties';
import itemSwapSaga from './itemSwap';
import ipSaga from '../ip/sagas';
import fetchBasketItemDetails from './fetchBasketItemDetails';
import purchaseSaga from './purchase';
import productConfigSaga from '../productConfig/sagas';
import generateSaved from './generateSaved';

function* addBasketItemSnackbar(action) {
	if (get(action, 'payload.product_code') === 'DREG') {
		yield put(
			snackbarActions.pushMessage('Domain added to your cart', 'success'),
		);
	}
}

function* resetBasket() {
	yield call([localStorage, 'removeItem'], BASKET_UUID);
	yield call(createSaga);
}

function* handleClaimError(action) {
	if (action.payload.status === 404) {
		// when basket is not found, clear the stored basket info and setup new basket
		yield call(resetBasket);
	}
}

/* gets uuid from url and sets in local storage or retrieves from storage */
function* getUuid({ pathName: pathNameArg } = {}) {
	let uuid;

	// If loading a cart URL directly, need to store those details into local state
	const pathName = pathNameArg || (yield select(getPathName));
	const [, root, uuidPath = ''] = pathName?.split('/') || [];
	const isDirectBasketUrl = root === 'cart' && isUUID(uuidPath, 4);
	if (isDirectBasketUrl) {
		uuid = uuidPath;
		yield call(storeUuid, { uuid });
		yield call([sessionStorage, 'setItem'], REDIRECT_PATH, '/cart');
		yield call([sessionStorage, 'setItem'], UUID_IS_FROM_URL, true);
	} else {
		uuid = yield call([localStorage, 'getItem'], BASKET_UUID);
	}
	return uuid;
}

function* initialize({
	hasAuthToken: hasAuthTokenArg,
	isBasketAdmin: isBasketAdminArg,
	isKeroOnly: isKeroOnlyArg,
	queryParams: queryParamsArg,
	uuid: uuidArg,
} = {}) {
	// authenticate
	const hasAuthToken = hasAuthTokenArg || (yield select(hasAuthTokenSelector));
	const uuid = uuidArg || (yield call(getUuid));

	const isKeroOnly = isKeroOnlyArg || (yield select(isKeroOnlySelector));
	if (isKeroOnly) return;

	const isBasketAdmin =
		isBasketAdminArg || (yield select(isBasketAdminSelector));

	const queryParams = queryParamsArg || (yield select(getQueryParams));
	const { opp_id: paramOppId, clone: paramClone } = queryParams || {};
	const oppId = paramOppId || (yield call([sessionStorage, 'getItem'], OPP_ID));
	const clone =
		paramClone || (yield call([sessionStorage, 'getItem'], CLONE_BASKET));

	if (!clone) {
		if (uuid && !oppId) {
			const detailsResult = yield call(detailsSaga);
			if (detailsResult?.type === detailsActions.errorType) {
				yield call(resetBasket);
			}
		} else {
			// no uuid
			yield call(createSaga);
		}
	}
	if (hasAuthToken) {
		if (isBasketAdmin) {
			if (oppId && clone) {
				yield call(cloneSaga, { oppId, uuid });
				yield call([sessionStorage, 'removeItem'], OPP_ID);
				yield call([sessionStorage, 'removeItem'], CLONE_BASKET);
			} else if (oppId) {
				// call assert metadata
				yield put(basketActions.assertMetadata({ opp_id: oppId }));
				yield put(push('/shop/marketplace'));
				yield call([sessionStorage, 'removeItem'], OPP_ID);
			}
			// TODO: handle no oppId for admins
		} else {
			yield call(claimBasket, { isInit: true }); // isManual will be false since this call is not via the action.
		}
	} else if (oppId) {
		// logged out
		const userManager = yield select(appConfigSelectors.userManager);
		const redirectPath = yield select(getFullPathName);
		yield call([sessionStorage, 'setItem'], REDIRECT_PATH, redirectPath);
		if (oppId) yield call([sessionStorage, 'setItem'], OPP_ID, oppId);
		if (clone) yield call([sessionStorage, 'setItem'], CLONE_BASKET, clone);
		if (userManager) yield call([userManager, 'signinRedirect']);
	}
}

const detailsTakeArray = [
	addActions.setType,
	addCouponActions.setType,
	addDiscountActions.setType,
	assertMetadataActions.setType,
	bulkModifyActions.setType,
	claimActions.setType,
	cloneItemActions.setType,
	itemModifyActions.setType,
	itemAssertPropertiesActions.setType,
	itemSwapActions.setType,
	removeActions.setType,
	removeCouponActions.setType,
	removeDiscountActions.setType,
	resetActions.setType,
	updateDiscountActions.setType,
];

export {
	addBasketItemSnackbar,
	resetBasket,
	handleClaimError,
	getUuid,
	initialize,
};
// TODO: Move all "takeLatest" calls to their respective roots, and spawn their exports.
export default function* basketRoot() {
	/* Fetch cart details when
		- basket/item is added, deleted, or edited
		- basket is created
		- coupon is added or removed
		- discount is added or removed
*/

	yield spawn(abandonSaga);
	yield spawn(acronisSaga);
	yield spawn(domainSaga);
	yield spawn(extraOptionsSaga);
	yield spawn(setExpireSaga);
	yield spawn(adminItemModify);
	yield spawn(assertMetadata);
	yield spawn(savedSaga);
	yield spawn(setCartNameSaga);
	yield spawn(resetItemConfigSaga);
	yield spawn(cloneItemSaga);
	yield spawn(itemAssertPropertiesSaga);
	yield spawn(itemSwapSaga);
	yield spawn(fetchBasketItemDetails);
	yield spawn(ipSaga);
	yield spawn(purchaseSaga);
	yield spawn(claimSaga);
	yield spawn(productConfigSaga);
	yield spawn(generateSaved);

	// TODO???: replace takeLatest with "takeFirst"
	// https://github.com/redux-saga/redux-saga/issues/1028
	// yield fork(function*() {
	// 	while (true) {
	// 		yield take(detailsTakeArray);
	// 		yield call(detailsSaga);
	// 	}
	// });

	// TODO???: delete this once above "takeFirst" is implemented.
	yield takeLatest(detailsTakeArray, detailsSaga);

	yield takeLatest(basketActions.BASKET_ADD_ITEM, addItemSaga);
	yield takeLatest(basketActions.BASKET_REMOVE_ITEM, removeItemSaga);
	yield takeLatest(addActions.setType, addBasketItemSnackbar);
	yield takeLatest(basketActions.BASKET_SET_ITEM_CONFIG, setItemConfigSaga);

	yield takeLatest(basketActions.BASKET_RESET, resetBasket);

	yield takeLatest(
		[basketActions.BASKET_INITIALIZE, USER_FOUND, USER_EXPIRED],
		initialize,
	);
}
