import { connect } from 'react-redux';
import {
	actions as assetListActions,
	selectors as assetListSelectors,
} from 'modules/api/asset/listModule';
import {
	actions as projectListActions,
	selectors as projectListSelectors,
} from 'modules/api/project/listModule';
import {
	actions as serverAvailableActions,
	selectors as serverAvailableSelectors,
} from 'modules/api/server/availableModule';
import { selectors as basketDetailsSelectors } from 'modules/api/basket/detailsModule';
import dialogActions from 'modules/dialogs/actions';
import basketActions from 'modules/basket/actions';
import HostnameProjectDialog from './HostnameProjectDialog';

const mapStateToProps = (state) => ({
	assetList: {
		isLoading: assetListSelectors.isLoading(state),
	},
	domains: assetListSelectors.uniqueDomains(state),
	basketDetails: {
		items: basketDetailsSelectors.getBasketItems(state),
		domains: basketDetailsSelectors.getDomains(state),
		projects: basketDetailsSelectors.getProjects(state),
	},
	projectList: {
		isLoading: projectListSelectors.isLoading(state),
		items: projectListSelectors.getNativeData(state)?.items,
	},
	serverAvailable: {
		domain: serverAvailableSelectors.getNativeData(state)?.domain,
		isLoading: serverAvailableSelectors.isLoading(state),
		hasError: serverAvailableSelectors.hasError(state),
	},
});

const mapDispatchToProps = (dispatch) => ({
	fetchAssetList: () => dispatch(assetListActions.init()),
	fetchProjectList: () => dispatch(projectListActions.init()),
	fetchAssertProperties: ({ properties, uuid }) =>
		dispatch(basketActions.itemChangeProperties({ properties, uuid })),
	fetchServerAvailable: ({ domain }) =>
		dispatch(serverAvailableActions.fetch({ domain })),
	closeDialog: () => dispatch(dialogActions.close()),
	setDialogTitle: (title) => dispatch(dialogActions.setTitle(title)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(HostnameProjectDialog);
