// https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
function formatBytes(inBytes, decimals) {
	const bytes = typeof inBytes === 'string' ? parseFloat(inBytes) : inBytes;
	if (bytes === 0)
		return {
			raw: 0,
			withUnit: '0 Bytes',
		};
	const k = 1024;
	const dm = decimals <= 0 ? 0 : decimals || 2;
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
	const i = Math.floor(Math.log(bytes) / Math.log(k));
	const raw = parseFloat((bytes / k ** i).toFixed(dm));
	return {
		raw,
		withUnit: `${raw} ${sizes[i]}`,
		rounded: Math.round(raw),
		unit: sizes[i],
	};
}

export default formatBytes;
