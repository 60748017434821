import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';

import { selectors as basketDetailsSelectors } from 'modules/api/basket/detailsModule';

const getStateSlice = (state) => state.account.limits.servers;

const { actions, reducer, sagas, selectors: moduleSelectors } = createAPIModule(
	{
		getStateSlice,
		actionType: 'ACCOUNT_LIMITS_SERVERS',
		url: '/account/limits/servers.json',
	},
);

const limit = createSelector(
	moduleSelectors.getNativeData,
	(slice) => Number(slice?.limit || 0),
);
const used = createSelector(
	moduleSelectors.getNativeData,
	(slice) => Number(slice?.used || 0),
);
const usedIncludingCart = createSelector(
	used,
	basketDetailsSelectors.getStormItemCount,
	(usedCount, stormItemCount) => usedCount + stormItemCount,
);
const overQuota = createSelector(
	limit,
	usedIncludingCart,
	(limitCount, usedCount) => usedCount > limitCount,
);
const showVPSServerQuotaNotice = createSelector(
	limit,
	usedIncludingCart,
	basketDetailsSelectors.hasStormItems,
	(limitCount, usedCount, hasStormItems) =>
		hasStormItems && usedCount > limitCount / 2,
);

const selectors = {
	limit,
	used,
	usedIncludingCart,
	overQuota,
	showVPSServerQuotaNotice,
	...moduleSelectors,
};

export { actions, reducer, sagas, selectors };
