import { spawn } from 'redux-saga/effects';
import settingsSagas from './settings/sagas';
import listSagas from './list/sagas';
import restoreSagas from './restore/sagas';

export default function* sagas() {
	yield spawn(settingsSagas);
	yield spawn(listSagas);
	yield spawn(restoreSagas);
}
