import {
	createAPIModule,
	createSelectors,
} from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';
import { List } from 'immutable';

const getStateSlice = (state) => state.support.request.list;

const { actions, reducer, sagas, selectors } = createAPIModule({
	getStateSlice,
	actionType: 'SUPPORT_REQUEST_LIST',
	method: 'POST',
	url: '/support/request/list.json',
});

const getSupportTicketsForModule = (moduleKey) =>
	createSelector(
		getStateSlice,
		(supportSummary) =>
			supportSummary.getIn([moduleKey, 'data', 'items'], List()),
	);

const customSelectors = {
	...selectors,
	waitingOnCustomer: {
		...createSelectors(getStateSlice, false, 'waitingOnCustomer', {}),
		getSupportTickets: getSupportTicketsForModule('waitingOnCustomer'),
	},
	open: {
		...createSelectors(getStateSlice, false, 'open', {}),
		getSupportTickets: getSupportTicketsForModule('open'),
	},
	statusNew: {
		...createSelectors(getStateSlice, false, 'statusNew', {}),
		getSupportTickets: getSupportTicketsForModule('statusNew'),
	},
};

export { actions, reducer, sagas, customSelectors as selectors };
