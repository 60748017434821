import { createSelector } from 'reselect';

const getStateSlice = (state) => state.chat;

const getIsStarting = createSelector(
	getStateSlice,
	(chat) => chat.isStarting,
);

export { getStateSlice, getIsStarting };
