import { spawn } from 'redux-saga/effects';

import { sagas as autorenewSagas } from './autoRenewModule';
import { sagas as detailsSagas } from './detailsModule';
import { sagas as enablePrivacySagas } from './enablePrivacyModule';
import { sagas as disablePrivacySagas } from './disablePrivacyModule';
import { sagas as renewSagas } from './renewModule';
import { sagas as registrationDetailsSagas } from './registrationDetailsModule';
import { sagas as tldDetailsSagas } from './tldDetailsModule';
import transferSagas from './transfer/transferSagas';
import contactSagas from './contact/contactSagas';
import nameserverSagas from './nameserver/nameserverSagas';

export default function* domainSagas() {
	yield spawn(autorenewSagas);
	yield spawn(detailsSagas);
	yield spawn(transferSagas);
	yield spawn(contactSagas);
	yield spawn(nameserverSagas);
	yield spawn(enablePrivacySagas);
	yield spawn(disablePrivacySagas);
	yield spawn(renewSagas);
	yield spawn(registrationDetailsSagas);
	yield spawn(tldDetailsSagas);
}
