import React from 'react';
import styled from 'styled-components';
import Card from '@material-ui/core/Card';

const SCard = styled(Card)`
	display: flex;
	flex-direction: column;
	max-width: 800px;
	> div {
		flex: 1;
	}
	background-color: ${({ theme }) => theme.palette.common.grey[0]};
	border: 1px solid ${({ theme }) => theme.palette.common.grey[2]};
	padding: 1rem;
	margin: 1rem 0;
`;

const DataCard = ({ children }) => <SCard elevation={0}>{children}</SCard>;

export default DataCard;
