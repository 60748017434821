import { connect } from 'react-redux';

import { getIsStarting } from 'modules/chat/selectors';
import { getRootPathName } from 'modules/routeSelectors';

import ChatButton from './ChatButton';

const mapStateToProps = (state) => ({
	isLoading: getIsStarting(state),
	rootPath: getRootPathName(state),
});

export default connect(mapStateToProps)(ChatButton);
