import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import moment from 'moment';
import { createSelector } from 'reselect';

const getStateSlice = (state) => state.sslCertificate.details;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'SSL_CERTIFICATE_DETAILS',
	method: 'POST',
	url: '/ssl/certificate/details.json',
});

const selectors = {
	autorenew: createSelector(
		getStateSlice,
		(slice) => slice.getIn(['data', 'autorenew_value'], ''),
	),
	cPanelLinked: createSelector(
		getStateSlice,
		(slice) => slice.getIn(['data', 'cpanel_linked'], ''),
	),
	expiration: createSelector(
		getStateSlice,
		(slice) => {
			const date = slice.getIn(['data', 'expiration'], '');
			return date ? moment(date).fromNow() : null;
		},
	),
	expired: createSelector(
		getStateSlice,
		(slice) => {
			const date = slice.getIn(['data', 'expiration'], null);
			return date ? moment(date).isSameOrBefore(moment(), 'day') : ''; // is before now
		},
	),
	status: createSelector(
		getStateSlice,
		(slice) => slice.getIn(['data', 'verification_status'], ''),
	),
	uniqId: createSelector(
		getStateSlice,
		(slice) => slice.getIn(['data', 'uniq_id'], ''),
	),
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };
