import produce from 'immer';
import actions from './actions';

const initialState = {
	tasksBeingCancelled: new Set(),
};

/* eslint-disable default-case, no-param-reassign */
const reducer = (state = initialState, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case actions.USERTASK_CANCEL_TASK:
				draft.tasksBeingCancelled = state.tasksBeingCancelled.add(
					action.payload.code,
				);
				break;
			case actions.USERTASK_SCHEDULEDTASK_CANCEL_TASK:
				draft.tasksBeingCancelled = state.tasksBeingCancelled.add(
					action.payload.code,
				);
				break;
			case actions.USERTASK_CANCEL_REQUEST_FINISHED:
				draft.tasksBeingCancelled = state.tasksBeingCancelled.delete(
					action.payload.code,
				);
				break;
		}
	});

export default reducer;
