import { createSelector } from 'reselect';

// api
import { selectors as availableConfigsSelectors } from 'modules/api/server/availableConfigsModule';
import { selectors as assetDetailsSelectors } from 'modules/api/asset/detailsModule';

// user input
import { deployOnto as deployOntoSelector } from 'modules/server/resize/options/selectors';

import { toInt, bytes, toGibibyte } from 'utility/format';

import {
	formName,
	formValuesSelector,
} from 'modules/server/resize/cloudConfig/selectors';

const getStateSlice = (state) => state.serverStore.resize;

const filterValues = createSelector(
	assetDetailsSelectors.CPUint,
	assetDetailsSelectors.RAM,
	assetDetailsSelectors.storageSize,
	assetDetailsSelectors.diskType,
	availableConfigsSelectors.cores,
	availableConfigsSelectors.ram,
	availableConfigsSelectors.storage,
	availableConfigsSelectors.diskOptions,
	deployOntoSelector,
	(
		currentCpu,
		currentRam,
		currentStorage,
		currentDiskType,
		coreOptions,
		ramOptions,
		storageOptions,
		diskOptions,
		deployOnto,
	) => ({
		cores: {
			current: currentCpu,
			options: coreOptions,
			virtual: deployOnto === 'vps',
		},
		ram: {
			current: toGibibyte(currentRam),
			options: ramOptions,
		},
		disk: {
			current: `${bytes(
				toInt(currentStorage),
			)}${` ${currentDiskType?.toUpperCase() || ''}`}`,
			options: storageOptions,
			diskType: {
				options: diskOptions,
			},
		},
	}),
);

const totalOptions = createSelector(
	availableConfigsSelectors.optionsSelector,
	(options) => options.length || 0,
);

const selectedOptionValue = (store) =>
	store.form[formName]?.values?.selectedOption?.value || '';

const filteredOptions = createSelector(
	getStateSlice,
	availableConfigsSelectors.optionsSelector,
	formValuesSelector,
	selectedOptionValue,
	(slice, options, formValues, selectedValue) => {
		const {
			cores: coresFilter = [],
			disk: storageFilter = [],
			ram: ramFilter = [],
			diskType = [],
			filtersDisabled,
		} = formValues;
		return options.filter(({ cores, disk, ram, storage, value }) => {
			if (filtersDisabled) return true;
			if (diskType?.length && diskType.indexOf(disk) === -1) {
				return false;
			}
			if (value === selectedValue) return true; // NEWMAN-1718
			return (
				cores >= coresFilter[0] &&
				cores <= coresFilter[1] &&
				storage >= storageFilter[0] &&
				storage <= storageFilter[1] &&
				ram >= ramFilter[0] &&
				ram <= ramFilter[1]
			);
		});
	},
);

const selectors = {
	getStateSlice,
	filterValues,
	totalOptions,
	filteredOptions,
	selectedOptionValue,
};

export default selectors;
