/* eslint-disable camelcase */
const actions = {
	TRANSACTION_TIMELINE_APPLY_FILTERS: 'TRANSACTION_TIMELINE_APPLY_FILTERS',
	applyFilters: (payload) => ({
		type: actions.TRANSACTION_TIMELINE_APPLY_FILTERS,
		payload,
	}),
	TRANSACTION_TIMELINE_TOGGLE_CLEAR_FILTERS:
		'TRANSACTION_TIMELINE_TOGGLE_CLEAR_FILTERS',
	clearFilters: ({ skipRefetch }) => ({
		type: actions.TRANSACTION_TIMELINE_TOGGLE_CLEAR_FILTERS,
		payload: { skipRefetch },
	}),
	TRANSACTION_TIMELINE_TOGGLE_CHANGE_FILTER:
		'TRANSACTION_TIMELINE_TOGGLE_CHANGE_FILTER',
	changeFilter: ({ label, value }) => ({
		type: actions.TRANSACTION_TIMELINE_TOGGLE_CHANGE_FILTER,
		payload: {
			label,
			value,
		},
	}),
};

export default actions;
