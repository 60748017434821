import details from './details';

const path = '/billing/payment/profile/';

export default {
	[`${path}details.json`]: () => {
		// The 'testUser' localStorage property is assigned in src/testUtils/helperFunctions.js
		switch (window.localStorage.getItem('testUser')) {
			case 'incompletePayment':
				return null;
			case 'owner':
				return details.paypal;
			default:
				return details.creditCard;
		}
	},
};
