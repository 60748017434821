import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Info from '@material-ui/icons/Info';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const STooltip = styled((props) => (
	<Tooltip
		classes={{ popper: props.className, tooltip: 'tooltip' }}
		{...props}
	/>
))`
	color: ${(p) => p.theme.palette.common.grey[4]};
	& .tooltip {
		font-size: 0.75rem;
		background-color: ${(p) => p.theme.palette.background.default};
		color: ${(p) => p.theme.palette.text.secondary};
		box-shadow: ${(p) => p.theme.shadows[4]};
		border: 1px solid ${(p) => p.theme.palette.common.grey[4]};
	}
`;

const LWTooltip = ({ children, ...rest }) => {
	const [isOpen, setIsOpen] = useState(false);

	const handleTooltipClose = () => {
		setIsOpen(false);
	};

	const handleTooltipOpen = () => {
		setIsOpen(true);
	};

	return (
		<ClickAwayListener onClickAway={handleTooltipClose}>
			<span>
				<STooltip
					disableFocusListener
					disableHoverListener
					disableTouchListener
					onClose={handleTooltipClose}
					open={isOpen}
					title={children}
					{...rest}
				>
					<IconButton onClick={handleTooltipOpen}>
						<Info />
					</IconButton>
				</STooltip>
			</span>
		</ClickAwayListener>
	);
};

LWTooltip.propTypes = {
	children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
};

export { STooltip };
export default LWTooltip;
