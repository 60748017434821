import { combineReducers } from 'redux';

import { reducer as addCoupon } from './addCouponModule';
import { reducer as addDiscount } from './addDiscountModule';
import { reducer as acquire } from './acquireModule';
import { reducer as assertMetadata } from './assertMetadataModule';
import { reducer as claim } from './claimModule';
import { reducer as clone } from './cloneModule';
import { reducer as create } from './createModule';
import { reducer as details } from './detailsModule';
import { reducer as purchase } from './purchaseModule';
import { reducer as removeCoupon } from './removeCouponModule';
import { reducer as removeDiscount } from './removeDiscountModule';
import { reducer as setExpire } from './setExpireModule';
import { reducer as setName } from './setNameModule';
import { reducer as updateDiscount } from './updateDiscountModule';
import { reducer as generateSaved } from './generateSavedModule';
import item from './item/itemReducer';

export default combineReducers({
	addCoupon,
	addDiscount,
	acquire,
	assertMetadata,
	claim,
	clone,
	create,
	details,
	item,
	purchase,
	removeCoupon,
	removeDiscount,
	setExpire,
	setName,
	updateDiscount,
	generateSaved,
});
