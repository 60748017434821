import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import LWLink from 'components/common/LWLink';
import Hidden from '@material-ui/core/Hidden';
import logo from 'images/lw-logo-full.png';
import logo2x from 'images/lw-logo-full@2x.png';
import logo3x from 'images/lw-logo-full@3x.png';
import mobileLogo from 'images/logo-mobile.png';

export const styles = (theme) => ({
	root: {
		[theme.breakpoints.up('md')]: {
			paddingLeft: '1em',
			paddingRight: '.7em',
			marginTop: '.3em',
			width: '13em',
		},
		[theme.breakpoints.down('sm')]: {
			paddingLeft: '.5em',
			paddingRight: '.5em',
			marginTop: '.3em',
			flexGrow: 2,
			maxWidth: '6em',
		},
	},
	image: {
		[theme.breakpoints.up('md')]: {
			width: '13em',
		},
		[theme.breakpoints.down('sm')]: {
			width: '100%',
		},
	},
});

const Logo = (props) => {
	const { classes, to = '/' } = props;
	return (
		<div className={classes.root} data-test-id="NavBar__Logo">
			<LWLink to={to}>
				<Hidden smDown>
					<img
						className={classes.image}
						src={logo}
						srcSet={`${logo2x} 2x, ${logo3x} 3x`}
						alt="Liquid Web Logo"
					/>
				</Hidden>
				<Hidden mdUp>
					<img
						className={classes.image}
						src={mobileLogo}
						alt="Liquid Web Logo"
					/>
				</Hidden>
			</LWLink>
		</div>
	);
};

Logo.propTypes = {
	classes: PropTypes.object.isRequired,
	to: PropTypes.string,
};

export default withStyles(styles)(Logo);
