import { takeLatest, put, take, call, select } from 'redux-saga/effects';
import {
	actions as listForKeyActions,
	selectors as listForKeySelectors,
	moduleKeys,
} from 'modules/api/account/user/stateData/listForKeyModule';
import {
	selectors as userStateDataReplaceSelectors,
	actions as userStateDataReplaceActions,
} from 'modules/api/account/user/stateData/replaceModule';
import { actions as userStateDataUpdateActions } from 'modules/api/account/user/stateData/addModule';
import actions from './actions';

function* updateMaxWidth({ maxWidth }) {
	yield put(
		userStateDataReplaceActions.fetch({
			data: JSON.stringify([{ value: maxWidth === 'true' }]),
			key: moduleKeys.MAX_WIDTH,
		}),
	);
	yield take(userStateDataReplaceActions.setType);
	const payload = yield select(userStateDataReplaceSelectors.getData);
	yield put(listForKeyActions.set(payload, moduleKeys.MAX_WIDTH));
}

function* initialize() {
	yield put(
		listForKeyActions.fetch(
			{
				key: moduleKeys.MAX_WIDTH,
			},
			moduleKeys.MAX_WIDTH,
		),
	);
	while (
		(yield take(listForKeyActions.setType)).moduleKey !== moduleKeys.MAX_WIDTH
	);
	const maxWidth = yield select(listForKeySelectors.maxWidth);
	if (maxWidth === undefined) {
		// false is fine. undefined means this has not ever been set. updateMaxWidth won't work unless a record exists.
		yield put(
			userStateDataUpdateActions.fetch(
				{
					data: JSON.stringify({ value: false }),
					key: moduleKeys.MAX_WIDTH,
				},
				moduleKeys.MAX_WIDTH,
			),
		);
	}
}

export default function* saga() {
	yield takeLatest(actions.MAX_WIDTH_UPDATE, updateMaxWidth);
	// This applies to the whole app and will always need to be called.
	yield call(initialize);
}
