import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.basketApi.purchase;

const { actions, reducer, sagas, selectors } = createAPIModule({
	getStateSlice,
	actionType: 'BASKET_PURCHASE',
	method: 'POST',
	url: '/basket/purchase.json',
});

export { actions, reducer, sagas, selectors };
