import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';
import { List as ImmutableList } from 'immutable';

const getStateSlice = (state) => state.onetimesecret.list;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'ONETIMESECRET_LIST',
	method: 'POST',
	url: '/onetimesecret/list.json',
});

const selectors = {
	items: createSelector(
		getStateSlice,
		(slice) =>
			slice
				.getIn(['data', 'items'], ImmutableList())
				.sortBy((item) => item.get('expiration')),
	),
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };
