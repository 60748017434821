import React from 'react';
import ManageRedirectDialog from 'containers/structural/ManageRedirectDialog';
import Snackbar from 'components/common/Snackbar';
import UnblockIPDialog from 'containers/structural/UnblockIp';
import ReferAFriendDialog from 'containers/structural/ReferAFriendDialog';
import DisallowedUserDialog from 'containers/structural/DisallowedUserDialog';
import RenewDialog from 'components/structural/RenewDialog';
import AddToProjectDialog from 'containers/pages/projects/AddToProjectDialog';
import ProjectAddDialog from 'containers/pages/projects/ProjectAddDialog';
import DeleteProjectDialog from 'containers/pages/projects/DeleteDialog';
import ValidateAddressDialog from 'components/structural/ValidateAddressDialog';
import EditNameDialog from 'containers/pages/projects/EditNameDialog';
import TaskSchedulerDialog from 'containers/structural/TaskSchedulerDialog';
import Chat from 'components/connectedMolecules/ChatButton';
import StatusSheet from 'components/common/StatusSheet';

import ResetScrollUponNav from 'components/structural/ResetScrollUponNav';
import Dialogs from 'components/common/Dialogs';
// TODO fix me
// import StyledButtons from 'components/styles/StyledButtons';
import GlobalStyles from 'components/styles/GlobalStyles';

const GlobalComponents = () => (
	<>
		<GlobalStyles />
		<Dialogs />
		{/* <StyledButtons /> */}
		<RenewDialog />
		<ResetScrollUponNav />
		<ManageRedirectDialog />
		<UnblockIPDialog />
		<AddToProjectDialog />
		<ProjectAddDialog />
		<DeleteProjectDialog />
		<EditNameDialog />
		<ReferAFriendDialog />
		<TaskSchedulerDialog />
		<Snackbar />
		<DisallowedUserDialog />
		<Chat />
		<StatusSheet />
		<ValidateAddressDialog />
	</>
);

export default GlobalComponents;
