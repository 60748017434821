import { createGlobalStyle, css } from 'styled-components';

// Stops page from shifting sizes between content shifts
const overflowFix = css`
	html {
		overflow: hidden;
	}
`;

// Drawer open causes padding on body
const noPaddingDrawer = css`
	body {
		padding: 0 !important;
	}
`;

// Fix box sizing so calc isn't required everywhere with padding
const boxSizingFix = css`
	html {
		box-sizing: border-box;
	}
	*,
	*:before,
	*:after {
		box-sizing: inherit;
	}
`;

const GlobalStyles = createGlobalStyle`
	${boxSizingFix}
	${overflowFix}
	${noPaddingDrawer}
`;

const GlobalStylesStorybook = createGlobalStyle`
${boxSizingFix}
${noPaddingDrawer}
`;

export { GlobalStylesStorybook };
export default GlobalStyles;
