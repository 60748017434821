import {
	createAPIModule,
	createSelectors,
} from 'utility/redux/apiModuleHelpers';

import displayName from 'utility/assetDisplayNames';
import { toInt } from 'utility/format';
import { acronisProductCodes } from 'utility/acronisProductMap';

import { createSelector } from 'reselect';
import Immutable, {
	Map as ImmutableMap,
	List as ImmutableList,
} from 'immutable';

import isStormHelper from 'utility/isStorm';

// ACRONIS
export const ACRONIS_DESTINATION_CAPABILITY =
	'acronisStorageDestinationAcronis';
export const ACRONISQUOTA = 'AcronisStorageQuotaCloud';

export const LW_DESTINATION_CAPABILITY = 'acronisStorageDestinationLiquidWeb';
export const LWQUOTA = 'AcronisStorageQuotaLiquidWeb';

export const DESTINATION = 'AcronisStorageDestination';

export const acronisCapabilityMapping = {
	[LW_DESTINATION_CAPABILITY]: LWQUOTA,
	[ACRONIS_DESTINATION_CAPABILITY]: ACRONISQUOTA,
};

// END ACRONIS

const getStateSlice = (state) => state?.asset?.details;

const moduleKeys = {
	ACRONIS: 'ACRONIS',
	MES: 'MES',
	NOCWORX_DETAILS: 'nocWorxDetails',
};

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'ASSET_DETAILS',
	method: 'POST',
	url: '/asset/details.json',
});

const getAcronisDestination = createSelector(
	getStateSlice,
	(slice) =>
		slice
			.getIn(['data', 'featureDetails'], ImmutableMap())
			.filter((value) => {
				return (
					value.getIn(['capabilities', ACRONIS_DESTINATION_CAPABILITY]) === 1 ||
					value.getIn(['capabilities', LW_DESTINATION_CAPABILITY]) === 1
				);
			})
			.toJS(),
);

// TODO: replace all invokations of this selector with the one from acronisSelectors.
const getAcronis = createSelector(
	getStateSlice,
	(slice) => {
		if (acronisProductCodes.includes(slice.getIn(['data', 'type']))) {
			const featureDetails = slice.getIn(['data', 'featureDetails'], {}).toJS();
			const toReturn = {};
			toReturn.destination = featureDetails[DESTINATION];

			if (featureDetails[ACRONISQUOTA]) {
				toReturn.quota = featureDetails[ACRONISQUOTA];
			}
			if (featureDetails[LWQUOTA]) {
				toReturn.quota = featureDetails[LWQUOTA];
			}
			return toReturn;
		}
		return {};
	},
);

const acronisSelectors = createSelectors(
	getStateSlice,
	false,
	moduleKeys.ACRONIS,
);

const nocWorxSelectors = createSelectors(
	getStateSlice,
	false,
	moduleKeys.NOCWORX_DETAILS,
);

const mesSelectors = createSelectors(getStateSlice, false, moduleKeys.MES);

const nocWorxServerDetails = createSelector(
	nocWorxSelectors.getData,
	(data) => data && data.getIn(['nocworxRemoteDetails', 'server_details']),
);

const status = createSelector(
	getStateSlice,
	(slice) => slice.getIn(['data', 'status'], ''),
);

const featureDetails = createSelector(
	getStateSlice,
	(slice) => slice.getIn(['data', 'featureDetails'], ImmutableMap()),
);

const featureDetailsNative = createSelector(
	defaultSelectors.getNativeState,
	(state) => state?.data?.featureDetails,
);

const backupQuotaValue = createSelector(
	featureDetailsNative,
	(fdn) => fdn?.BackupQuota?.value,
);

const nocworxFlavor = createSelector(
	getStateSlice,
	(slice) =>
		slice
			.getIn(['data', 'featureDetails'], ImmutableMap())
			.filter((value) => {
				return value.getIn(['capabilities', 'nocworxFlavor']) === 1;
			})
			.toJS(),
);
const nocworxMemory = createSelector(
	getStateSlice,
	(slice) =>
		slice
			.getIn(['data', 'featureDetails'], ImmutableMap())
			.filter((value) => {
				return value.getIn(['capabilities', 'nocworxMemory']) === 1;
			})
			.toJS(),
);
const nocworxCPU = createSelector(
	getStateSlice,
	(slice) =>
		slice
			.getIn(['data', 'featureDetails'], ImmutableMap())
			.filter((value) => {
				return value.getIn(['capabilities', 'nocworxCPU']) === 1;
			})
			.toJS(),
);
const nocworxVolumeSize = createSelector(
	getStateSlice,
	(slice) =>
		slice
			.getIn(['data', 'featureDetails'], ImmutableMap())
			.filter((value) => {
				return value.getIn(['capabilities', 'nocworxVolumeSize']) === 1;
			})
			.toJS(),
);
const nocworxVolumeType = createSelector(
	getStateSlice,
	(slice) =>
		slice
			.getIn(['data', 'featureDetails'], ImmutableMap())
			.filter((value) => {
				return value.getIn(['capabilities', 'nocworxVolumeType']) === 1;
			})
			.toJS(),
);

const templateValue = createSelector(
	featureDetails,
	(slice) => (slice ? slice.getIn(['Template', 'value']) : null),
);

const templateDescription = createSelector(
	featureDetails,
	(slice) => (slice ? slice.getIn(['Template', 'description']) : null),
);

const cPanelLicenseTier = createSelector(
	featureDetails,
	(slice) => (slice ? slice.get('cPanelLicenseTier') : null),
);

const getBandwidth = createSelector(
	featureDetails,
	(slice) => (slice ? slice.get('Bandwidth') : null),
);

const bandwidthOverage = createSelector(
	featureDetails,
	(slice) => (slice ? slice.get('BandwidthOverage') : null),
);

const cPanelLicenseTierValue = createSelector(
	cPanelLicenseTier,
	(slice) => (slice ? slice.get('value') : null),
);

const children = createSelector(
	getStateSlice,
	(slice) => slice.getIn(['data', 'children'], ImmutableList()),
);

const domain = createSelector(
	getStateSlice,
	(slice) => slice.getIn(['data', 'domain'], ''),
);

const privateParent = createSelector(
	getStateSlice,
	(slice) => slice.getIn(['data', 'privateParent'], null),
);

const ip = createSelector(
	getStateSlice,
	(slice) => slice.getIn(['data', 'ip'], ''),
);

const regionId = createSelector(
	getStateSlice,
	(slice) => slice?.getIn(['data', 'region_id']),
);

const zone = createSelector(
	getStateSlice,
	(slice) => slice.getIn(['data', 'zone'], null),
);

const regionName = createSelector(
	zone,
	(zoneSlice) => zoneSlice && zoneSlice.getIn(['region', 'name'], null),
);

const uniqId = createSelector(
	getStateSlice,
	(slice) => slice.getIn(['data', 'uniq_id'], ''),
);

const price = createSelector(
	getStateSlice,
	(slice) => slice.getIn(['data', 'price'], null),
);

const categories = createSelector(
	getStateSlice,
	(slice) => slice.getIn(['data', 'categories'], ImmutableList()),
);

const isPrivateParent = createSelector(
	categories,
	(slice) => slice.includes('PrivateParent'),
);

const capabilities = createSelector(
	getStateSlice,
	(slice) => slice.getIn(['data', 'capabilities'], ImmutableList()),
);
const resourceState = createSelector(
	getStateSlice,
	(slice) => slice.getIn(['data', 'resourceState'], ImmutableList()),
);
const diskDetails = createSelector(
	getStateSlice,
	(slice) => slice.getIn(['data', 'diskDetails'], ImmutableList()),
);

const mesType = createSelector(
	getStateSlice,
	(slice) => slice.getIn(['data', 'mesDetails', 'mes_type'], ''),
);

const mesRole = createSelector(
	getStateSlice,
	(slice) => slice.getIn(['data', 'mesDetails', 'mes_role'], ''),
);

const isOpenStack = createSelector(
	categories,
	(category) => category.includes('NocworxOpenstack'),
);

const isBeyondHosting = createSelector(
	categories,
	(category) => category.includes('BeyondHostingVPS'),
);

const isCloudDedicated = createSelector(
	categories,
	(category) => category.includes('CloudBareMetal'),
);

const isCloudVPS = createSelector(
	categories,
	(category) => category.includes('CloudVPS'),
);

const canAcronisBackup = createSelector(
	capabilities,
	(slice) => (slice ? slice.get('canAcronisBackup', '') : null),
);

const powerStatus = createSelector(
	getStateSlice,
	nocWorxSelectors.getData,
	isBeyondHosting,
	(slice, nocworxData, beyondHosting) =>
		beyondHosting
			? nocworxData &&
			  nocworxData.getIn(['nocworxRemoteDetails', 'power_status'])
			: slice.getIn(['data', 'powerStatus'], ''),
);

const categoryMap = {
	StrictDedicated: 'dedicated',
	CloudVPS: 'cloud',
	// In the future, we could seperate these out, leaving as `cloud` for now as there are a number of places
	// in the code that use these categories.  Would need to make sure they handle a change properly.
	CloudBareMetal: 'cloud',
	PrivateParent: 'cloud',
	BeyondHostingVPS: 'cloud',
	DNS: 'dns_zone',
	SSL: 'ssl',
	NocworxOpenstack: 'open stack',
	WordPress: 'wordpress',
};

const assetType = createSelector(
	categories,
	(slice) => {
		const type = slice.reduce(
			(reduction, category) => categoryMap[category] || reduction,
			'',
		);
		return type || 'asset';
	},
);

const productType = createSelector(
	getStateSlice,
	(slice) => slice.getIn(['data', 'type']),
);

const projectName = createSelector(
	getStateSlice,
	(slice) => slice.getIn(['data', 'project_name']),
);

const projectId = createSelector(
	getStateSlice,
	(slice) => slice.getIn(['data', 'project_id']),
);

const isMWPv1 = createSelector(
	productType,
	(productCode) => productCode === 'WP.VM',
);

const displayType = createSelector(
	defaultSelectors.getData,
	assetType,
	productType,
	(asset, type, productCode) => displayName(type, productCode),
);

const managedType = createSelector(
	featureDetails,
	assetType,
	(slice, type) => {
		switch (type) {
			case 'dedicated':
				return slice.getIn(['ControlPanel', 'value'], '');
			case 'cloud':
				return slice.getIn(['Template', 'extra_software'], '');
			case 'open stack':
				return slice.getIn(['CloudManagement', 'value'], '');
			default:
				return '';
		}
	},
);
const managedLevel = createSelector(
	featureDetails,
	assetType,
	(slice, type) => {
		switch (type) {
			case 'dedicated':
				return slice.getIn(['ControlPanel', 'description'], '');
			case 'cloud':
				return slice.getIn(['Template', 'support_level'], '');
			case 'open stack':
				return slice.getIn(['CloudManagement', 'description'], '');
			default:
				return '';
		}
	},
);
const OS = createSelector(
	featureDetails,
	nocWorxSelectors.getData,
	assetType,
	isBeyondHosting,
	(slice, nocWorxDetails, type, assetIsBeyondHosting) => {
		const serverType = assetIsBeyondHosting ? 'beyondhosting' : type;
		switch (serverType) {
			case 'dedicated':
				return (
					slice.getIn(['centos', 'description']) ||
					slice.getIn(['OS', 'description'], '')
				);
			case 'cloud':
				return slice.getIn(['Template', 'description'], '');
			case 'open stack': {
				const OsOption = slice.filter((option) =>
					option.hasIn(['capabilities', 'nocworxImage']),
				);
				if (OsOption.isEmpty()) return null;
				return OsOption.map((option) => option.get('description'))
					.valueSeq()
					.first();
			}
			case 'beyondhosting': {
				if (!nocWorxDetails) return null;
				return nocWorxDetails.getIn(
					['nocworxRemoteDetails', 'os', 'identity'],
					null,
				);
			}
			default:
				return '';
		}
	},
);

const childType = createSelector(
	OS,
	managedLevel,
	(myOS, myManagedLevel) => {
		if (myOS?.toLowerCase() === 'windows') {
			return 'Windows';
		}
		if (
			myManagedLevel === 'Fully-Managed' ||
			myManagedLevel === 'Core-Managed'
		) {
			return 'Managed';
		}
		return 'Unmanaged';
	},
);
const RAM = createSelector(
	featureDetails,
	nocWorxServerDetails,
	assetType,
	isPrivateParent,
	isBeyondHosting,
	privateParent,
	(
		slice,
		nocWorxDetails,
		type,
		assetIsPrivateParent,
		assetIsBeyondHosting,
		isChild,
	) => {
		let serverType = assetIsPrivateParent ? 'privateparent' : type;
		serverType = isChild ? 'child' : serverType;
		serverType = assetIsBeyondHosting ? 'beyondhosting' : serverType;
		switch (serverType) {
			case 'dedicated':
				return slice.getIn(['RAM', 'description']);
			case 'open stack': {
				const memoryOption = slice.filter((option) =>
					option.hasIn(['capabilities', 'nocworxMemory']),
				);
				if (memoryOption.isEmpty()) return null;
				return memoryOption
					.map((option) => option.get('description'))
					.valueSeq()
					.first();
			}
			case 'privateparent':
				return slice.getIn(['ConfigId', 'memory']);
			case 'cloud':
			case 'child':
				return slice.getIn(['ConfigId', 'memory']);
			case 'beyondhosting': {
				if (!nocWorxDetails) return null;
				return `${nocWorxDetails.get('ram')}MB`;
			}
			default:
				return undefined;
		}
	},
);

const RAMdisplay = createSelector(
	RAM,
	(ram) => `${ram} MB`,
);

const CPU = createSelector(
	featureDetails,
	nocWorxServerDetails,
	assetType,
	isPrivateParent,
	isBeyondHosting,
	privateParent,
	(
		slice,
		nocWorxDetails,
		type,
		assetIsPrivateParent,
		assetIsBeyondHosting,
		isChild,
	) => {
		let serverType = assetIsPrivateParent ? 'privateparent' : type;
		serverType = isChild ? 'child' : serverType;
		serverType = assetIsBeyondHosting ? 'beyondhosting' : serverType;
		switch (serverType) {
			case 'dedicated':
				return slice.getIn(['Processor', 'description'], '');
			case 'cloud': {
				let cores = slice.getIn(['ConfigId', 'cpu_cores']);
				if (!cores) cores = slice.getIn(['ConfigId', 'vcpu']);

				return cores + (parseInt(cores, 10) > 1 ? ' cores' : ' core');
			}
			case 'open stack': {
				const cpuOption = slice.filter((option) =>
					option.hasIn(['capabilities', 'nocworxCPU']),
				);
				if (cpuOption.isEmpty()) return null;
				return cpuOption
					.map((option) => option.get('description'))
					.valueSeq()
					.first();
			}
			case 'privateparent': {
				const cores = slice.getIn(['ConfigId', 'cpu_cores_per_socket'], '');
				const sockets = slice.getIn(['ConfigId', 'cpu_sockets'], '');
				const displayedCores = cores * sockets;
				return (
					displayedCores +
					(parseInt(displayedCores, 10) > 1 ? ' cores' : ' core')
				);
			}
			case 'child': {
				const cores = slice.getIn(['ConfigId', 'vcpu'], '');
				return cores + (parseInt(cores, 10) > 1 ? ' cores' : ' core');
			}
			case 'beyondhosting': {
				if (!nocWorxDetails) return null;
				const cores = nocWorxDetails.get('cpu');
				return cores + (parseInt(cores, 10) > 1 ? ' cores' : ' core');
			}
			default:
				return '';
		}
	},
);

const CPUint = createSelector(
	CPU,
	(cpu) => toInt(cpu),
);

const configId = createSelector(
	featureDetails,
	(slice) => slice.getIn(['ConfigId']) || Immutable.fromJS({}),
);

const storageSize = createSelector(
	configId,
	(slice) => toInt(slice.getIn(['disk'])),
);

const storageSizeDisplay = createSelector(
	storageSize,
	(storageSize_) => `${storageSize_} GB`,
);

const diskType = createSelector(
	configId,
	(slice) => slice.getIn(['disk_type']),
);

const configIdValue = createSelector(
	configId,
	(slice) => Number(slice.getIn(['value'])),
);

const deployedOnto = createSelector(
	configId,
	privateParent,
	(slice, child) => {
		const category = slice.getIn(['category']);
		switch (category) {
			case 'bare-metal':
				return 'cloudDedicated';
			case undefined:
				return child?.getIn(['uniq_id']);
			default:
				return 'vps';
		}
	},
);

const hardDriveData = createSelector(
	featureDetails,
	(slice) =>
		slice.filter(
			(value, key) =>
				key.search(/HD\d/) === 0 &&
				value.get('description') !== 'No Tertiary Drive',
		) || ImmutableMap(),
);

const hardDrives = createSelector(
	hardDriveData,
	assetType,
	featureDetails,
	nocWorxServerDetails,
	isBeyondHosting,
	(slice, type, features, nocWorxDetails, assetIsBeyondHosting) => {
		const serverType = assetIsBeyondHosting ? 'beyondhosting' : type;
		switch (serverType) {
			case 'open stack': {
				const diskOption = features.filter((option) =>
					option.hasIn(['capabilities', 'nocworxVolumeSize']),
				);
				if (diskOption.isEmpty()) return [];
				const diskValue = diskOption
					.map((option) => option.get('num_units'))
					.valueSeq()
					.first();
				const diskDescription = diskOption
					.map((option) => option.get('description'))
					.valueSeq()
					.first();

				return [
					{
						title: 'Disk',
						value: diskValue ? `${diskValue} GB` : diskDescription,
					},
				];
			}
			case 'beyondhosting': {
				if (!nocWorxDetails) return [];
				return [
					{
						title: 'Disk',
						value: `${nocWorxDetails.get('disk_space')}GB`,
					},
				];
			}
			default: {
				const drives = slice.keySeq().toArray();
				return drives.map((key) => ({
					title: key,
					value: slice.getIn([key, 'description']),
				}));
			}
		}
	},
);

const role = createSelector(
	featureDetails,
	assetType,
	(slice, type) => {
		switch (type) {
			case 'open stack':
				return slice.getIn(['CloudRole', 'description'], '');
			default:
				return '';
		}
	},
);

const flavor = createSelector(
	featureDetails,
	assetType,
	(slice, type) => {
		switch (type) {
			case 'open stack':
				return slice.getIn(['CloudFlavor', 'description'], '');
			default:
				return '';
		}
	},
);

const beyondHostingSize = createSelector(
	featureDetails,
	(slice) => slice.getIn(['NocworxSKU', 'value']),
);

const networkSummary = createSelector(
	getStateSlice,
	(slice) => slice.getIn(['data', 'networkSummary'], ImmutableMap()),
);

const primaryIp = createSelector(
	networkSummary,
	(slice) => (slice ? slice.get('primary_ip', '') : null),
);

const privateIp = createSelector(
	networkSummary,
	(slice) => (slice ? slice.get('private_ip', '') : null),
);

const totalIps = createSelector(
	networkSummary,
	(slice) => (slice ? slice.get('total_ips', '') : null),
);

const firewallType = createSelector(
	networkSummary,
	(slice) => (slice ? slice.getIn(['firewall', 'type'], null) : null),
);

const firewallConfig = createSelector(
	networkSummary,
	(slice) => (slice ? slice.getIn(['firewall', 'config'], null) : null),
);

const backupsAndStorage = createSelector(
	getStateSlice,
	(slice) => slice.getIn(['data', 'backupsAndStorage'], ImmutableMap()),
);

const blockStorage = createSelector(
	backupsAndStorage,
	(slice) => (slice ? slice.get('block_storage', ImmutableList()) : null),
);

const imageCount = createSelector(
	backupsAndStorage,
	(slice) => (slice ? slice.getIn(['image', 'count']) : slice),
);

const imageName = createSelector(
	backupsAndStorage,
	(slice) => (slice ? slice.getIn(['image', 'name']) : null),
);

const imageDate = createSelector(
	backupsAndStorage,
	(slice) => (slice ? slice.getIn(['image', 'date']) : null),
);

const getAcronisId = createSelector(
	backupsAndStorage,
	(slice) => (slice ? slice.getIn(['acronis', 'uniq_id']) : null),
);

const hasAcronis = createSelector(
	backupsAndStorage,
	(slice) => slice?.has('acronis'),
);

const getAcronisStatus = createSelector(
	backupsAndStorage,
	(slice) => (slice ? slice.getIn(['acronis', 'status']) : null),
);

const parentDiskResources = createSelector(
	resourceState,
	diskDetails,
	(slice, disk) => {
		if (!slice) return null;

		const data = slice.find((value) => value.get('type') === 'disk.lvm.volume');
		return data
			? {
					// for display purposes, we need to remove reserved snapshot space from the amounts
					free: data.get('free') - disk.get('snapshots'),
					used: data.get('used') - disk.get('snapshots'),
					total: data.get('total') - disk.get('snapshots'),
			  }
			: null;
	},
);

const parentMemoryResources = createSelector(
	resourceState,
	(slice) => {
		if (!slice) return null;

		return slice.find((value) => value.get('type') === 'memory') || null;
	},
);

const backupPlanMonthlyPrice = createSelector(
	featureDetails,
	(slice) => slice.getIn(['LiquidWebBackupPlan', 'pricing', 'month']),
);

// Such a normal way to doing things...
const backupPlanRaw = createSelector(
	featureDetails,
	(slice) => slice.getIn(['LiquidWebBackupPlan'])?.toJS(),
);

const backupPlan = createSelector(
	backupPlanRaw,
	hasAcronis,
	(slice, acronis) => {
		if (acronis) return 'Acronis';
		switch (slice?.value) {
			case 'BackupDay':
			case 'Daily':
				return 'Daily';
			case 'BackupQuota':
			case 'Quota':
				return 'Quota';
			default:
				return 'None';
		}
	},
);

const backupPlanIsLegacy = createSelector(
	backupPlanRaw,
	hasAcronis,
	(slice, acronis) => {
		if (acronis) return false;
		switch (slice?.value) {
			case 'BackupDay':
			case 'Daily':
			case 'BackupQuota':
			case 'Quota':
				return true;
			default:
				return false;
		}
	},
);

const daysToRetain = createSelector(
	defaultSelectors.getNativeState,
	({ data } = {}) => data?.featureDetails?.BackupDay?.num_units || 7,
);

const instance = createSelector(
	defaultSelectors.getNativeState,
	({ data } = {}) => data?.instance,
);

const stormBackupSize = createSelector(
	instance,
	(data) => {
		return data?.backup_size ? parseFloat(data.backup_size) : undefined;
	},
);

const stormBackupQuota = createSelector(
	instance,
	(data) => (data?.backup_quota ? data.backup_quota : undefined),
);

const volumeCostPerMonth = createSelector(
	featureDetails,
	(slice) => slice.getIn(['VolumeSize', 'price']),
);

const isStorm = createSelector(
	productType,
	(slice) => isStormHelper(slice),
);

const selectors = {
	nocWorxSelectors,
	nocworxFlavor,
	nocworxMemory,
	nocworxCPU,
	nocworxVolumeSize,
	nocworxVolumeType,
	powerStatus,
	nocWorxServerDetails,
	status,
	featureDetails,
	featureDetailsNative,
	managedType,
	managedLevel,
	childType,
	domain,
	ip,
	instance,
	stormBackupSize,
	stormBackupQuota,
	price,
	categories,
	capabilities,
	assetType,
	displayType,
	OS,
	children,
	RAM,
	RAMdisplay,
	CPU,
	CPUint,
	hardDrives,
	role,
	flavor,
	uniqId,
	volumeCostPerMonth,
	privateParent,
	configId,
	storageSize,
	storageSizeDisplay,
	diskType,
	configIdValue,
	deployedOnto,
	hardDriveData,
	primaryIp,
	privateIp,
	totalIps,
	firewallType,
	firewallConfig,
	backupsAndStorage,
	bandwidthOverage,
	blockStorage,
	imageName,
	imageCount,
	imageDate,
	isCloudDedicated,
	isCloudVPS,
	isPrivateParent,
	isOpenStack,
	isBeyondHosting,
	isMWPv1,
	acronisSelectors,
	isStorm,
	mesSelectors,
	beyondHostingSize,
	parentDiskResources,
	parentMemoryResources,
	productType,
	hasAcronis,
	getAcronis,
	getAcronisId,
	getAcronisDestination,
	getBandwidth,
	getAcronisStatus,
	regionId,
	regionName,
	cPanelLicenseTier,
	cPanelLicenseTierValue,
	backupPlanMonthlyPrice,
	backupPlanRaw,
	backupPlan,
	backupPlanIsLegacy,
	backupQuotaValue,
	daysToRetain,
	templateValue,
	templateDescription,
	projectName,
	projectId,
	mesType,
	mesRole,
	canAcronisBackup,
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors, moduleKeys };
