const acronis = {
	GET_USAGE: 'ACRONIS_GET_USAGE',
	getUsage: ({ uniqId }) => ({
		type: acronis.GET_USAGE,
		uniqId,
	}),
	GET_SSO: 'ACRONIS_GET_SSO',
	getSSO: ({ uniqId }) => ({
		type: acronis.GET_SSO,
		uniqId,
	}),
	SUBMIT_CANCEL: 'ACRONIS_SUBMIT_CANCEL',
	submitCancel: ({ serverId, uniqId }) => ({
		type: acronis.SUBMIT_CANCEL,
		uniqId,
		serverId,
	}),
	SUBMIT_UPDATE: 'ACRONIS_SUBMIT_UPDATE',
	submitUpdate: ({ features }) => ({
		type: acronis.SUBMIT_UPDATE,
		features,
	}),
	TOGGLE_DIALOG: 'ACRONIS_TOGGLE_DIALOG',
	toggleDialog: (isDialogOpen) => ({
		type: acronis.TOGGLE_DIALOG,
		isDialogOpen,
	}),
	TOGGLE_LOADING: 'ACRONIS_TOGGLE_LOADING',
	toggleLoading: (isLoading) => ({
		type: acronis.TOGGLE_LOADING,
		isLoading,
	}),
	SET_ERROR: 'ACRONIS_SET_ERROR',
	setError: (error) => ({
		type: acronis.SET_ERROR,
		error,
	}),
};

export default acronis;
