import { combineReducers } from 'redux';
import auth from './auth/authReducer';
import { reducer as resize } from './resizeModule';
import { reducer as resizeVolume } from './resizeVolumeModule';
import WHM from './WHM/whmReducer';
import { reducer as reboot } from './rebootModule';
import { reducer as resizeStorage } from './resizeStorageModule';
import { reducer as start } from './startModule';
import { reducer as available } from './availableModule';
import { reducer as availableFlavors } from './availableFlavorsModule';
import { reducer as availableStorage } from './availableStorageModule';
import { reducer as shutdown } from './shutdownModule';
import { reducer as update } from './updateModule';
import { reducer as vncConsole } from './vncConsoleModule';
import { reducer as availableConfigs } from './availableConfigsModule';

export default combineReducers({
	auth,
	available,
	resizeStorage,
	reboot,
	resize,
	resizeVolume,
	availableFlavors,
	availableStorage,
	update,
	shutdown,
	start,
	vncConsole,
	WHM,
	availableConfigs,
});
