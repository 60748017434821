import { createSelector } from 'reselect';
import { selectors as limitSelectors } from 'modules/api/account/limits/ipModule';

const formName = 'StormConfig__IPTables';
const formSelector = (state) => state.form[formName];

const getStateSlice = (state) => state.basket.ip;

const newIpCount = createSelector(
	getStateSlice,
	(slice) => slice.newIpCount,
);

const currentValues = createSelector(
	formSelector,
	(form) => form?.values?.selected || [],
);

const extraIpCount = createSelector(
	newIpCount,
	currentValues,
	(newIpCount_, currentValues_) =>
		Math.max(currentValues_.length + newIpCount_ - 1, 0),
);

const currentValuesIds = createSelector(
	currentValues,
	(values) => values?.map((item) => item.id),
);

const remaining = createSelector(
	limitSelectors.limit,
	extraIpCount,
	(limit, extraIpCount_) => limit - extraIpCount_,
);

const selectors = {
	getStateSlice,
	extraIpCount,
	newIpCount,
	currentValues,
	currentValuesIds,
	remaining,
	formSelector,
};

export { formName };
export default selectors;
