import { spawn } from 'redux-saga/effects';

import { sagas as enableSagas } from './enableModule';
import { sagas as disableSagas } from './disableModule';
import { sagas as activationKeySagas } from './activationKeyModule';

export default function* sagas() {
	yield spawn(enableSagas);
	yield spawn(disableSagas);
	yield spawn(activationKeySagas);
}
