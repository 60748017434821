import Immutable from 'immutable';

// Allows input to either be an Immmutable Map or List, or just a regular JS array or object
// Return value is always an array, if an object is passed in we wrap it in an array
const immutableToArray = (input) => {
	if (Immutable.List.isList(input)) return input.toJS();
	if (Immutable.Map.isMap(input)) return [input.toJS()];
	if (Array.isArray(input)) return input;
	return [input];
};

// Allows input to either be an Immmutable Map or List, or just a regular JS array or object
// Return value is always an array, if an object is passed in we return just the values
const immutableToValueArray = (subNavData) => {
	if (Immutable.Map.isMap(subNavData)) return Object.values(subNavData.toJS());
	if (Immutable.List.isList(subNavData)) return subNavData.toJS();
	if (Array.isArray(subNavData)) return subNavData;
	if (typeof subNavData === 'object') return Object.values(subNavData);
	return subNavData;
};

// Allows input to either be an Immmutable Map or List, or just a regular JS array or object
// Return value is always an array, if an object is passed in we return Object.entries
const immutableToEntryArray = (subNavData) => {
	if (Immutable.Map.isMap(subNavData)) return Object.entries(subNavData.toJS());
	if (Immutable.List.isList(subNavData)) return subNavData.toJS();
	if (Array.isArray(subNavData)) return subNavData;
	if (typeof subNavData === 'object') return Object.entries(subNavData);
	return subNavData;
};

export { immutableToArray, immutableToValueArray, immutableToEntryArray };

export default {
	immutableToArray,
	immutableToValueArray,
	immutableToEntryArray,
};
