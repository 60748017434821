import React from 'react';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import CircularProgress from '@material-ui/core/CircularProgress';
import LWTypography from 'components/common/LWTypography';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import capitalize from 'lodash/capitalize';
import PropTypes from 'prop-types';

const DefaultContent = ({
	categoriesData,
	projectName,
	category,
	changeCategory,
	availableAssetsData,
	asset,
	assetCurrentProject,
	changeAsset,
}) => (
	<>
		<Box pb={3}>
			<LWTypography>
				Select the asset you would like to add to {projectName}.
			</LWTypography>
		</Box>
		<Box pb={3}>
			<FormControl variant="outlined">
				<InputLabel id="category">Category</InputLabel>
				<Select
					label="Category"
					labelId="category"
					disabled={categoriesData.isLoading}
					value={category}
					onChange={changeCategory}
					inputProps={{
						name: 'category',
						id: 'category',
					}}
				>
					{categoriesData.categories &&
						categoriesData.categories.map((categoryName) => (
							<MenuItem key={categoryName} value={categoryName}>
								{capitalize(categoryName)}
							</MenuItem>
						))}
				</Select>
			</FormControl>
		</Box>
		<Box pb={1}>
			<FormControl variant="outlined">
				<InputLabel id="assets">
					{availableAssetsData.hasData && category
						? `My ${capitalize(category)}`
						: ''}
				</InputLabel>
				{availableAssetsData.isLoading && <CircularProgress />}
				{!availableAssetsData.isLoading && (
					<Select
						label={
							availableAssetsData.hasData && category ? `My ${category}` : ''
						}
						labelId="assets"
						disabled={!availableAssetsData.hasData}
						value={asset.uniq_id}
						onChange={changeAsset}
						inputProps={{
							name: 'assets',
							id: 'assets',
						}}
					>
						{availableAssetsData.assets &&
							availableAssetsData.assets.map((assetData) => (
								<MenuItem
									key={assetData.get('uniq_id')}
									value={assetData.get('uniq_id')}
								>
									{assetData.get('domain')}
								</MenuItem>
							))}
					</Select>
				)}
				<FormHelperText>
					{assetCurrentProject.uniqId
						? `${asset.domain} already belongs to ${assetCurrentProject.name}.`
						: ''}
				</FormHelperText>
			</FormControl>
		</Box>
	</>
);

DefaultContent.propTypes = {
	categoriesData: PropTypes.object.isRequired,
	availableAssetsData: PropTypes.object.isRequired,
	projectName: PropTypes.string,
	category: PropTypes.string.isRequired,
	changeCategory: PropTypes.func.isRequired,
	asset: PropTypes.object.isRequired,
	assetCurrentProject: PropTypes.object.isRequired,
	changeAsset: PropTypes.func.isRequired,
};

DefaultContent.defaultProps = {
	projectName: 'Project Name',
};

export default DefaultContent;
