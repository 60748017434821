import React from 'react';
import styled, { css } from 'styled-components';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Loading from 'components/common/Loading';

const SButton = styled(
	({ BoxProps, isLoading, color, disabled, textColorMatch, ...props }) => (
		<Box display="inline-flex" position="relative" {...BoxProps}>
			<Button
				disabled={isLoading || disabled}
				color={color === 'tertiary' ? undefined : color}
				{...props}
			/>
			{isLoading && (
				<Loading
					CircularProgressProps={{ size: 18 }}
					css={`
						top: 0;
						bottom: 0;
					`}
				/>
			)}
		</Box>
	),
)`
	color: ${({ theme, textColorMatch }) => {
		return textColorMatch ? '' : theme.palette.text.primary;
	}};
	border-width: 2px;
	border-radius: 100px;
	&:hover {
		border-width: 2px;
	}
	min-width: 128px;
	${(p) =>
		p.variant !== 'contained' &&
		css`
			background-color: ${p.theme.palette.common.white};
		`}
`;

export default SButton;

SButton.defaultProps = {
	color: 'secondary',
	variant: 'outlined',
};
