import { takeLatest, put } from 'redux-saga/effects';
import {
	actions as availableAssetsActions,
	moduleKeys as assetsModuleKeys,
} from 'modules/api/project/availableAssetsModule';
import actions from './actions';

function* initServers() {
	yield put(
		availableAssetsActions.init(
			{ category: 'servers' },
			assetsModuleKeys.SERVERS,
		),
	);
}

export default function* rootSaga() {
	yield takeLatest(actions.INIT_AVAILABLEASSETS_SERVERS, initServers);
}
