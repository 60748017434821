import { call, put, select, take, takeLatest } from 'redux-saga/effects';

import {
	actions as claimActions,
	selectors as claimSelectors,
} from 'modules/api/basket/claimModule';
import { selectors as detailsSelectors } from 'modules/api/basket/detailsModule';
import { sagas as attributionSagas } from 'modules/attribution';
import { BASKET_UUID } from 'utility/constants/baskets';
import { UUID_IS_FROM_URL } from 'utility/constants/auth';
import dialogActions from 'modules/dialogs/actions';
import { isMasquerade } from 'modules/auth/oidcSelectors';

import { minDetailsRefresh } from '../details';
import basketActions from '../../actions';

function* handleClaimError({ error }) {
	if (error) yield put(basketActions.setError('claim cart'));
}

function* handleClaimBasket({ uuid, isManual }) {
	const uuidFromUrl = yield call([sessionStorage, 'getItem'], UUID_IS_FROM_URL);
	if ((yield select(isMasquerade)) && !isManual && uuidFromUrl) {
		yield put(
			dialogActions.open({
				title: 'Proceed with Claim Cart?',
				contentKey: 'MasqueradeModalContents',
				dismissible: false,
			}),
		);
		yield call([sessionStorage, 'removeItem'], UUID_IS_FROM_URL);
		return;
	}

	yield put(claimActions.fetch({ uuid, merge: 0 }));
	const claimResult = yield take([
		claimActions.setType,
		claimActions.errorType,
	]);
	yield call(handleClaimError, {
		error: claimResult?.type === claimActions.errorType,
	});
}

function* claimBasket({
	isManual, // If called from the claim action, this is true by default. See BASKET_CLAIM action creator.
	isInit,
} = {}) {
	const uuid = yield call([localStorage, 'getItem'], BASKET_UUID);

	yield call(attributionSagas.update);
	if (!isInit) yield call(minDetailsRefresh);

	const claimable = (yield select(detailsSelectors.getNativeData))?.claimable;
	const prevClaimedUuid = yield select(claimSelectors.uuid);
	if (
		claimable &&
		prevClaimedUuid !== uuid // else Basket already claimed during this session.
	) {
		yield call(handleClaimBasket, { uuid, isManual });
	}
}

export { handleClaimBasket, handleClaimError, claimBasket };
export default function*() {
	yield takeLatest(claimActions.errorType, handleClaimError);
	yield takeLatest(basketActions.BASKET_CLAIM, claimBasket);
}
