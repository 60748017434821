import Immutable from 'immutable';

const initialState = Immutable.fromJS({
	preset: null,
});

const firewallPresets = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_PRESET':
			return state.set('preset', action.payload);
		case 'CLEAR_PRESET':
			return state.set('preset', '');
		default:
			return state;
	}
};

export default firewallPresets;
