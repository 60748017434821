import { all, select, put, takeEvery } from 'redux-saga/effects';

import { actions as currentNotificationActions } from 'modules/api/notifications/currentModule';
import { actions as resolveNotificationActions } from 'modules/api/notifications/resolveModule';
import { pendingNotifications as pendingNotificationsSelector } from 'modules/statusSheet/selectors';

import statusSheetActions from './actions';
import { isOpen as isOpenSelector } from './selectors';

function* handleNotificationUpdate() {
	const isOpen = yield select(isOpenSelector);
	const pendingNotifications = yield select(pendingNotificationsSelector);

	if (pendingNotifications.size && !isOpen) {
		yield put(statusSheetActions.open());
	}
	if (!pendingNotifications.size && isOpen) {
		yield put(statusSheetActions.close());
	}
}

function* handleStatusSheetClose() {
	const pendingNotifications = yield select(pendingNotificationsSelector);

	const currentIds =
		pendingNotifications &&
		pendingNotifications.map((notification) => notification.get('id')).toJS();

	yield put(statusSheetActions.hideNotifications(currentIds));
}

function* handleDismissNotification(action) {
	const { id } = action;
	// optimistic update since the user will simply get the notification on refresh if this fails
	yield put(statusSheetActions.hideNotifications([id]));
	yield put(resolveNotificationActions.fetch({ id }));
}

function* watchForNewNotifications() {
	yield takeEvery(
		[currentNotificationActions.setType],
		handleNotificationUpdate,
	);
}

function* watchStatusSheetClose() {
	yield takeEvery([statusSheetActions.CLOSE], handleStatusSheetClose);
}

function* watchDismissNotification() {
	yield takeEvery(
		[statusSheetActions.DISMISS_NOTIFICATION],
		handleDismissNotification,
	);
}

export default function* saga() {
	yield all([
		watchForNewNotifications(),
		watchStatusSheetClose(),
		watchDismissNotification(),
	]);
}
