import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import moment from 'moment';
import { createSelector } from 'reselect';
import { List as ImmutableList } from 'immutable';

const getStateSlice = (state) => state.network.dns.domain.details;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'NETWORK_DNS_DOMAIN_DETAILS',
	method: 'POST',
	url: '/network/dns/domain/details.json',
});

const expired = createSelector(
	getStateSlice,
	(slice) => !!slice.getIn(['data', 'is_expired'], 0),
);

const selectors = {
	autorenew: createSelector(
		getStateSlice,
		(slice) => !!slice.getIn(['data', 'autorenew'], false),
	),
	autorenew_value: createSelector(
		getStateSlice,
		(slice) => slice.getIn(['data', 'autorenew_value'], 'Off'),
	),
	registeredNameServers: createSelector(
		getStateSlice,
		(slice) => slice.getIn(['data', 'nameservers'], ImmutableList()),
	),
	expirationText: createSelector(
		getStateSlice,
		expired,
		(slice, isExpired) => {
			const verb = `Expire${isExpired ? 'd' : 's'}`;
			const date = slice.getIn(['data', 'expiration']);
			return verb && date ? `${verb} ${moment(date).format('l')}` : '';
		},
	),
	expired,
	domain: createSelector(
		getStateSlice,
		(slice) => slice.getIn(['data', 'domain'], null),
	),
	isRGP: createSelector(
		getStateSlice,
		(slice) => slice.getIn(['data', 'is_rgp']),
	),
	isRenewable: createSelector(
		getStateSlice,
		(slice) => !!slice.getIn(['data', 'is_renewable']),
	),
	isSubdomain: createSelector(
		defaultSelectors.getError,
		(error) =>
			error
				? error
						.getIn(['data', 'error'])
						.includes('Subdomains cannot be registered')
				: false,
	),
	isTransfer: createSelector(
		getStateSlice,
		(slice) => slice.getIn(['data', 'is_transfer'], 0),
	),
	renewalPrice: createSelector(
		getStateSlice,
		(slice) => slice.getIn(['data', 'renewal_price']),
	),
	redemptionPrice: createSelector(
		getStateSlice,
		(slice) => slice.getIn(['data', 'redemption_price']),
	),
	renewalMaxYears: createSelector(
		getStateSlice,
		(slice) => parseInt(slice.getIn(['data', 'renewal_max_years'], 1), 10),
	),
	uniqId: createSelector(
		getStateSlice,
		(slice) => slice.getIn(['data', 'uniq_id'], null),
	),
	purchasedPrivacy: createSelector(
		getStateSlice,
		(slice) => !!slice.getIn(['data', 'has_privacy'], 0),
	),
	whoisPrivacy: createSelector(
		getStateSlice,
		(slice) => slice.getIn(['data', 'privacy'], 'Off'),
	),
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };
