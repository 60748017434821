const actions = {
	RENAME_PROJECT_DIALOG_TOGGLE: 'RENAME_PROJECT_DIALOG_TOGGLE',
	toggleDialog: (isOpen) => ({
		type: actions.RENAME_PROJECT_DIALOG_TOGGLE,
		isOpen,
	}),
	RENAME_PROJECT_DIALOG_SET_PROPS: 'RENAME_PROJECT_DIALOG_SET_PROPS',
	setProps: (dialogProps) => ({
		type: actions.RENAME_PROJECT_DIALOG_SET_PROPS,
		dialogProps,
	}),
};

export default actions;
