import React from 'react';
import Box from '@material-ui/core/Box';
import LWTypography from 'components/common/LWTypography';

const ReadOnlyClusterable = () => (
	<>
		<Box pb={3}>
			<LWTypography>
				This server is compatible with the Database Clustering service enabled
				on this project. Because a Primary DB node is already active, this
				server will be used as a Read-Only DB node.
			</LWTypography>
		</Box>
		<Box pb={3}>
			<LWTypography>
				If added as a Read-Only Database, this server will be permanently tied
				to the Database Clustering service and cannot be removed from the
				project unless the server is destroyed.
			</LWTypography>
		</Box>
		<Box pb={2}>
			<LWTypography color="error">
				All current data on the server will be deleted before setting as a
				Read-Only Database, to then cluster with the Primary Database.
			</LWTypography>
		</Box>
	</>
);

export default ReadOnlyClusterable;
