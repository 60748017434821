import { spawn } from 'redux-saga/effects';

import { sagas as deleteSagas } from './deleteModule';
import { sagas as listRegisteredSagas } from './listRegisteredModule';
import { sagas as listAssignedSagas } from './listAssignedModule';
import { sagas as assignSagas } from './assignModule';
import { sagas as registerSagas } from './registerModule';
import { sagas as updateRegisteredIPSagas } from './updateRegisteredIPModule';

export default function* domainSagas() {
	yield spawn(deleteSagas);
	yield spawn(listRegisteredSagas);
	yield spawn(listAssignedSagas);
	yield spawn(assignSagas);
	yield spawn(registerSagas);
	yield spawn(updateRegisteredIPSagas);
}
