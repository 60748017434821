import { spawn } from 'redux-saga/effects';

import accountSagas from './account/accountSagas';
import articleSagas from './article/articleSagas';
import chatSagas from './chat/chatSagas';

export default function* sagas() {
	yield spawn(accountSagas);
	yield spawn(articleSagas);
	yield spawn(chatSagas);
}
