/**
 * Replaces React Testing Library's render method with a custom
 * renderer, which wraps the element with our Provider stack. For
 * efficiency, use only when the component under test needs state,
 * theme, or some other provider(s).
 *
 * HT https://testing-library.com/docs/react-testing-library/setup#custom-render
 */
import { TestProviders } from 'utility/ProvidersWrapper';
import { render } from '@testing-library/react';

const selectNodeContents = jest.fn((node) => node.textContent);

// prevents "TypeError: document.createRange is not a function"
// This is no longer required since Jest v26.0.0. Remove the following assignment to global.document.createRange once jest is upgraded:
global.document.createRange = () => ({
	setStart: () => {},
	setEnd: () => {},
	commonAncestorContainer: {
		nodeName: 'BODY',
		ownerDocument: document,
	},
	selectNodeContents,
});

global.document.execCommand = jest.fn((command) => {
	switch (command) {
		case 'copy':
			return global.document
				.createRange()
				.selectNodeContents.mock.results.slice(-1)?.[0].value;
		default:
			return command;
	}
});

global.getSelection = () => ({
	addRange: jest.fn(),
	removeAllRanges: jest.fn(),
});

const customRender = (ui, options) =>
	render(ui, {
		wrapper: TestProviders,
		...options,
	});

export * from '@testing-library/react';
export { customRender as render };
