import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import LWDialog from 'components/common/LWDialog';
import { selectors as appConfigSelectors } from 'modules/api/appConfigModule';
import manageRedirectActions from 'modules/manageRedirect/manageRedirectActions';
import DetourDialogContent from 'components/structural/DetourDialogContent';

import { selectors as accountDetailsSelectors } from 'modules/api/account/detailsModule';

const styles = ({ palette, zIndex }) => ({
	root: {
		zIndex: zIndex.appBar,
	},
	summary: {
		marginBottom: '1rem',
	},
	card: {
		marginBottom: '2rem',
	},
	button: {
		color: palette.secondary.contrastText,
	},
});

const ManageRedirectDialog = ({
	classes,
	onClose,
	isOpen,
	url,
	manageHostname,
	managementPortal,
	featureAvailableInManage,
}) => {
	const getUrl = () => {
		if (!url) {
			return manageHostname;
		}
		if (url[0] === '/') {
			return `${manageHostname}${url}`;
		}
		return url;
	};

	return (
		<LWDialog
			title={
				managementPortal === 'any' && featureAvailableInManage
					? 'Temporary Detour'
					: 'Feature Not Implemented'
			}
			onClose={onClose}
			className={classes.root}
			maxWidth="xl"
			open={isOpen}
		>
			<DetourDialogContent
				route={getUrl()}
				closeDialog={onClose}
				buttonsVisible={managementPortal === 'any' && featureAvailableInManage}
			/>
		</LWDialog>
	);
};

ManageRedirectDialog.defaultProps = {
	url: null,
	managementPortal: null,
	featureAvailableInManage: true,
};

ManageRedirectDialog.propTypes = {
	classes: PropTypes.object.isRequired,
	onClose: PropTypes.func.isRequired,
	manageHostname: PropTypes.string.isRequired,
	managementPortal: PropTypes.string,
	url: PropTypes.string,
	isOpen: PropTypes.bool.isRequired,
	featureAvailableInManage: PropTypes.bool,
};

const mapStateToProps = (state) => ({
	manageHostname: appConfigSelectors.getManageHostname(state),
	isOpen: state.manageRedirect.get('isOpen'),
	url: state.manageRedirect.get('url'),
	featureAvailableInManage: state.manageRedirect.get(
		'featureAvailableInManage',
	),
	managementPortal: accountDetailsSelectors.managementPortal(state),
});

const mapDispatchToProps = (dispatch) => ({
	onClose: () => dispatch(manageRedirectActions.setOpen(false)),
});

export { ManageRedirectDialog };

export default compose(
	withStyles(styles),
	connect(
		mapStateToProps,
		mapDispatchToProps,
	),
)(ManageRedirectDialog);
