const categories = {
	USER_INTERACTION: 'User Interaction',
	NAV_INTERACTION: 'Nav Interaction',
	AD_INTERACTION: 'Ad Interaction',
	HELP_INTERACTION: 'Help Interaction',
	BACKGROUND_EVENT: 'Background Event',
	ERROR: 'Error',
};

export default categories;
