const actionType = 'SNACKBAR';
const actions = {
	pushType: `${actionType}_PUSH_MESSAGE`,
	// TODO: make this an object and update anything calling this
	pushMessage: (
		message,
		color = 'success',
		// TODO: remove this prop everywhere it is used (doesn't seem to do anything in the snackbar component)
		showIcon = false,
		autoHideDuration = 6000,
	) => ({
		type: `${actionType}_PUSH_MESSAGE`,
		message,
		showIcon,
		color,
		autoHideDuration,
	}),
	shiftType: `${actionType}_SHIFT_MESSAGE`,
	shiftMessage: () => ({
		type: `${actionType}_SHIFT_MESSAGE`,
	}),
};

export default actions;
