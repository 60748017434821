import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
	actions as renameActions,
	selectors as renameSelectors,
} from 'modules/api/project/renameModule';
import {
	actions as availableActions,
	selectors as availableSelectors,
} from 'modules/api/project/availableModule';
import { selectors } from 'modules/renameProjectDialog/selectors';
import snackbarActions from 'modules/snackbar/snackbarActions';
import actions from 'modules/renameProjectDialog/actions';
import EditNameDialog from './EditNameDialog';

const mapStateToProps = (state) => ({
	availableObj: {
		data: availableSelectors.getNativeData(state),
		isLoading: availableSelectors.isLoading(state),
		error: availableSelectors.getError(state),
	},
	renameObj: {
		isLoading: renameSelectors.isLoading(state),
		renamed: renameSelectors.hasData(state),
	},
	dialogObj: {
		isOpen: selectors.isOpen(state),
		dialogProps: selectors.dialogProps(state),
	},
});

const mapDispatchToProps = (dispatch) => ({
	clearRename: () => dispatch(renameActions.clear()),
	clearAvailable: () => dispatch(availableActions.clear()),
	renameProject: ({ name, projectId }) =>
		dispatch(renameActions.fetch({ id: projectId, new_project_name: name })),
	fetchAvailable: (name) =>
		dispatch(availableActions.fetch({ project_name: name })),
	displaySnackbar: (message) => dispatch(snackbarActions.pushMessage(message)),
	toggleDialog: (value) => dispatch(actions.toggleDialog(value)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withRouter(EditNameDialog));
