import { all, fork, call, race, take, put, cancel } from 'redux-saga/effects';
import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import localAjaxClientSaga from 'utility/redux/saga/localAjaxClientSaga';
import { createSelector } from 'reselect';
import userManager from 'utility/openid/userManager';

const getStateSlice = (state) => state.appConfig;

const {
	actions,
	reducer,
	sagas: appConfigModuleSaga,
	selectors: moduleSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'APPCONFIG',
	method: 'GET',
	url: '/appConfig.json',
	client: localAjaxClientSaga,
});

function* watchAppConfigResult() {
	return yield race({
		success: take(actions.setType),
		error: take(actions.errorType),
	});
}

function* sagas() {
	const [appConfigModuleTask, appConfigResult] = yield all([
		fork(appConfigModuleSaga),
		call(watchAppConfigResult),
		put(actions.fetch()),
	]);

	yield cancel(appConfigModuleTask);

	return appConfigResult;
}
const getOpenIdConfig = createSelector(
	moduleSelectors.getData,
	(appConfig) => appConfig.get('openId'),
);

const getKeroSignupLink = createSelector(
	getOpenIdConfig,
	(openIdConfig) =>
		`${openIdConfig.get('authority')}/signup?client_id=${openIdConfig.get(
			'client_id',
		)}&amp;response_type=token%20id_token&amp;scope=openid%20profile%20grants`,
);

const selectors = {
	getAppConfig: createSelector(
		getStateSlice,
		(appConfig) => appConfig.get('data'),
	),
	getKeroSignupLink,
	getWwwHostname: createSelector(
		getStateSlice,
		(appConfig) => `https://${appConfig.getIn(['data', 'hosts', 'www'])}`,
	),
	getCartHostname: createSelector(
		getStateSlice,
		(appConfig) => `https://${appConfig.getIn(['data', 'hosts', 'cart'])}`,
	),
	getSalesforceHostname: createSelector(
		getStateSlice,
		(appConfig) =>
			`https://${appConfig.getIn(['data', 'hosts', 'salesforce'])}`,
	),
	getCustomerCommunityHostname: createSelector(
		getStateSlice,
		(appConfig) =>
			`https://${appConfig.getIn(['data', 'hosts', 'customer_community'])}`,
	),
	getAuthorizeUrl: createSelector(
		getStateSlice,
		(appConfig) =>
			`https://${appConfig.getIn(['data', 'hosts', 'authorize_net'])}`,
	),
	getManageHostname: createSelector(
		getStateSlice,
		(appConfig) => `https://${appConfig.getIn(['data', 'hosts', 'manage'])}`,
	),
	getManagedAppsHostname: createSelector(
		getStateSlice,
		(appConfig) =>
			`https://${appConfig.getIn(['data', 'hosts', 'managed_apps'])}`,
	),
	getAppConfigHosts: createSelector(
		getStateSlice,
		(appConfig) => appConfig.getIn(['data', 'hosts']),
	),
	sessionLength: createSelector(
		getStateSlice,
		(appConfig) => appConfig.getIn(['data', 'session', 'length']),
	),
	warningTime: createSelector(
		getStateSlice,
		(appConfig) => appConfig.getIn(['data', 'session', 'warningTime']),
	),
	getGaTrackerId: createSelector(
		moduleSelectors.getData,
		(appConfig) => appConfig.get('gaTrackerId'),
	),
	getGoogleTagManagerId: createSelector(
		moduleSelectors.getData,
		(appConfig) => appConfig.get('gtmContainerId'),
	),
	getGuestCredentials: createSelector(
		moduleSelectors.getData,
		(appConfig) => appConfig.get('guestAuth'),
	),
	getChatConfig: createSelector(
		moduleSelectors.getData,
		(appConfig) => appConfig.get('chatConfig'),
	),
	getOpenIdConfig,
	userManager: createSelector(
		getOpenIdConfig,
		userManager,
	),
	...moduleSelectors,
};

export { actions, reducer, sagas, selectors };
