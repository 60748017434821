import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.basketApi.removeCoupon;

const { actions, reducer, sagas, selectors } = createAPIModule({
	getStateSlice,
	actionType: 'BASKET_REMOVECOUPON',
	method: 'POST',
	url: '/basket/removeCoupon.json',
});

export { actions, reducer, sagas, selectors };
