const getInitialExtraDetails = () => (
	[
		{
			label: 'ChatAuthToken',
			value: '',
			displayToAgent: false,
			transcriptFields: ['Chat_Auth_Token__c'],
		},
	]
);

export default getInitialExtraDetails;
