import produce from 'immer';
import basketDomainsActions from 'modules/basket/actions/domains';
import { actions as basketDetailsActions } from 'modules/api/basket/detailsModule';
import get from 'lodash/get';

const initialState = {
	whoIs: null,
	nameServers: null,
	isLoading: {
		DNS: false,
		WHO_IS: false,
	},
};

/* eslint-disable default-case, no-param-reassign */
const reducer = (state = initialState, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case basketDetailsActions.setType: {
				// Fill nameserver / whoIs form on basket details
				const { payload } = action;
				const properties = get(payload, 'items[0].properties');
				if (properties) {
					const { nameServers, ...whoIs } = properties;

					const mapKeys = () => {
						const keysObj = {
							FirstName: 'fname',
							LastName: 'lname',
							Address1: 'address',
							Address2: 'address2',
							EmailAddress: 'email',
							Phone: 'phone',
							City: 'city',
							StateProvince: 'state',
							Country: 'country',
							PostalCode: 'postal_code',
							OrganizationName: 'organization',
							Fax: 'fax',
							JobTitle: 'contact_role',
							lwtech: 'lwtech',
						};

						const keys = Object.keys(keysObj);

						const contact = {};

						keys.forEach((key) => {
							contact[keysObj[key]] = whoIs[key];
						});

						return contact;
					};

					draft.whoIs = mapKeys();
					draft.nameServers = nameServers;
				}

				break;
			}

			case basketDomainsActions.TOGGLE_LOADING:
				draft.isLoading[action.key] = action.value;
				break;

			case basketDomainsActions.SUBMIT_WHO_IS:
				draft.whoIs = action.whoIs;
				break;

			case basketDomainsActions.EDIT_NAME_SERVERS:
				draft.nameServers = action.nameServers;
				break;
		}
	});

export default reducer;
