import accountDetails from './details';
import sshkey from '../ssh/key/mockData';
import limits from '../limits/mockData';
import user from '../user/mockData';
import billingAddress from '../billingAddress/mockData';

const path = '/account/';

export default {
	[`${path}details.json`]: accountDetails,
	...sshkey,
	...limits,
	...user,
	...billingAddress,
};
