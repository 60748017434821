import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ErrorIcon from '@material-ui/icons/Error';
import Info from '@material-ui/icons/Info';
import Close from '@material-ui/icons/Close';
import Warning from '@material-ui/icons/Warning';
import LWLink from 'components/common/LWLink';
import {
	NOTIFICATION_SEVERITY,
	ERROR_SEVERITY,
	WARNING_SEVERITY,
	CRITICAL_SEVERITY,
} from 'utility/constants/notificationConstants';

const icon = {
	margin: '0 auto',
	width: '70%',
	height: '70%',
	position: 'relative',
	top: '50%',
	transform: 'translateY(-50%)',
};

const styles = (theme) => ({
	root: {
		marginTop: 0,
		margin: '2em',
		backgroundColor: theme.palette.common.white1,
		padding: '0',
		width: 'inherit',
		alignItems: 'stretch',
		overflow: 'hidden',
	},
	listIcon: {
		width: '2.75em',
		textAlign: 'center',
	},
	listIconError: {
		backgroundColor: theme.palette.error.main,
	},
	listIconInfo: {
		backgroundColor: theme.palette.secondary.light,
	},
	listIconWarning: {
		backgroundColor: theme.palette.common.yellow3,
	},
	errorIcon: {
		...icon,
		...theme.mixins.error,
	},
	infoIcon: {
		...icon,
		...theme.mixins.info,
	},
	warningIcon: {
		...icon,
		...theme.mixins.warning,
	},
	listItemTextTypography: {
		marginLeft: theme.spacing(0.5),
		width: '100%',
		color: 'black',
		wordWrap: 'break-word',
		justifyContent: 'center',
		position: 'relative',
		top: '50%',
		transform: 'translateY(-50%)',
	},
	dismissButton: {},
	closeIconButton: {
		'&:hover': {
			backgroundColor: '#fff',
		},
	},
	domain: {
		fontWeight: 500,
	},
});

const severityIconMap = {
	[CRITICAL_SEVERITY]: (classes) => <ErrorIcon className={classes.errorIcon} />,
	[ERROR_SEVERITY]: (classes) => <ErrorIcon className={classes.errorIcon} />,
	[WARNING_SEVERITY]: (classes) => <Warning className={classes.warningIcon} />,
	[NOTIFICATION_SEVERITY]: (classes) => <Info className={classes.infoIcon} />,
};

const NotificationLine = ({
	classes,
	notification,
	handleDismissNotification,
	...otherProps
}) => {
	const notificationDisplay = notification.get('last_alert')
		? notification.get('last_alert')
		: notification.get('description');
	return (
		<ListItem
			key={notification.get('id')}
			className={classes.root}
			{...otherProps}
		>
			<ListItemIcon
				className={classNames({
					[classes.listIcon]: true,
					[classes.listIconError]:
						notification.get('severity') === ERROR_SEVERITY ||
						notification.get('severity') === CRITICAL_SEVERITY,
					[classes.listIconInfo]:
						notification.get('severity') === NOTIFICATION_SEVERITY,
					[classes.listIconWarning]:
						notification.get('severity') === WARNING_SEVERITY,
				})}
			>
				{severityIconMap[notification.get('severity')](classes)}
			</ListItemIcon>
			<ListItemText
				primaryTypographyProps={{
					component: 'div',
					className: classes.listItemTextTypography,
				}}
			>
				{notification.get('domain') && notification.get('uniq_id') && (
					<LWLink to={`/go/${notification.get('uniq_id')}`}>
						{`${notification.get('domain')}: `}
					</LWLink>
				)}
				{notification.get('domain') && !notification.get('uniq_id') && (
					<span className={classes.domain}>{`${notification.get(
						'domain',
					)}: `}</span>
				)}
				<span
					// eslint-disable-next-line react/no-danger
					dangerouslySetInnerHTML={{ __html: notificationDisplay }}
				/>
			</ListItemText>
			<ListItemIcon className={classes.dismissButton}>
				<IconButton
					onClick={() => handleDismissNotification(notification.get('id'))}
					className={classes.closeIconButton}
				>
					<Close />
				</IconButton>
			</ListItemIcon>
		</ListItem>
	);
};

NotificationLine.propTypes = {
	classes: PropTypes.object.isRequired,
	notification: ImmutablePropTypes.map.isRequired,

	handleDismissNotification: PropTypes.func.isRequired,
};

export { NotificationLine };
export default withStyles(styles)(NotificationLine);
