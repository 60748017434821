import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Switch, useLocation, useHistory } from 'react-router-dom';
import SubNavRoutes from 'components/routers/SubNavRoutes';
import RoleRestrictedRoute from 'utility/routing/RoleRestrictedRoute';
import asyncComponent from 'components/AsyncComponent';
import { initialized } from 'modules/gtm/selectors';
import { roles } from 'utility/constants/roles';

const Marketplace = asyncComponent(() =>
	import('containers/pages/shop/Marketplace'),
);

const AddProduct = asyncComponent(() =>
	import('containers/pages/shop/AddProduct'),
);

const ProductConfig = asyncComponent(() =>
	import('containers/pages/shop/ProductConfig'),
);

const DomainSearch = asyncComponent(() =>
	import('containers/pages/domains/Register/Search'),
);

const DomainConfigure = asyncComponent(() =>
	import('containers/pages/domains/Register/Configure'),
);

const PaymentMethod = asyncComponent(() =>
	import('containers/pages/shop/Cart/PaymentMethod'),
);

const Checkout = asyncComponent(() =>
	import('containers/pages/shop/Cart/Checkout'),
);

const OrderConfirmation = asyncComponent(() =>
	import('containers/pages/shop/OrderConfirmation/OrderConfirmation'),
);

const ShopRouter = (props) => {
	const location = useLocation();
	const history = useHistory();
	const gtmInitialized = useSelector(initialized);
	useEffect(() => {
		// can't do navigation events until GTM has been initialized
		if (gtmInitialized && location.pathname === '/shop') {
			history.replace('/shop/marketplace');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location, gtmInitialized]);

	const { navData } = props;
	return (
		<Switch>
			<RoleRestrictedRoute
				exact
				allowUnauth
				path={navData.get('path')}
				component={navData.get('component')}
				whitelistedRoles={[roles.PURCHASER, roles.SETUP_USER]}
			/>
			<RoleRestrictedRoute
				exact
				allowUnauth
				path={`${navData.get('path')}/marketplace/:tab`}
				component={Marketplace}
				whitelistedRoles={[roles.PURCHASER, roles.SETUP_USER]}
			/>
			<RoleRestrictedRoute
				exact
				allowUnauth
				path={`${navData.get('path')}/marketplace`}
				component={Marketplace}
				whitelistedRoles={[roles.PURCHASER, roles.SETUP_USER]}
			/>
			<RoleRestrictedRoute
				exact
				allowUnauth
				path={`${navData.get('path')}/domain`}
				component={DomainSearch}
				whitelistedRoles={[roles.PURCHASER, roles.SETUP_USER]}
			/>
			<RoleRestrictedRoute
				exact
				allowUnauth
				path={`${navData.get('path')}/domain/configure`}
				component={DomainConfigure}
				whitelistedRoles={[roles.PURCHASER, roles.SETUP_USER]}
			/>
			<RoleRestrictedRoute
				exact
				allowUnauth
				path={`${navData.get('path')}/add/:productCode`}
				component={AddProduct}
				whitelistedRoles={[roles.PURCHASER, roles.SETUP_USER]}
			/>
			<RoleRestrictedRoute
				exact
				allowUnauth
				path={`${navData.get('path')}/config/:itemUuid`}
				component={ProductConfig}
				whitelistedRoles={[roles.PURCHASER, roles.SETUP_USER]}
			/>
			<RoleRestrictedRoute
				exact
				path={`${navData.get('path')}/payment-method`}
				component={PaymentMethod}
				whitelistedRoles={[roles.PURCHASER, roles.SETUP_USER]}
			/>
			<RoleRestrictedRoute
				exact
				path={`${navData.get('path')}/checkout`}
				component={Checkout}
				whitelistedRoles={[roles.PURCHASER, roles.SETUP_USER]}
			/>
			<RoleRestrictedRoute
				name="Order Confirmation"
				path="/shop/complete"
				component={OrderConfirmation}
				whitelistedRoles={[roles.PURCHASER, roles.SETUP_USER]}
			/>
			<SubNavRoutes
				subNavs={navData.get('subNav')}
				whitelistedRoles={[roles.PURCHASER, roles.SETUP_USER]}
			/>
		</Switch>
	);
};

ShopRouter.propTypes = {
	navData: PropTypes.object.isRequired,
};

export default ShopRouter;
