import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import LWTypography from 'components/common/LWTypography';
import { perMonth, currency } from 'utility/format';

const Price = ({
	itemPriceTotal,
	itemDiscountTotal,
	isLoading,
	perMonthLabel = true,
}) => {
	const skeletonWidths = {
		main: '80px',
	};

	return (
		<Box
			display="flex"
			flexDirection="column"
			alignItems="flex-end"
			flexGrow={1}
		>
			<LWTypography
				variant="subtitle1"
				semiBold
				isLoading={isLoading}
				BoxProps={{
					marginY: 0.5,
					textAlign: 'right', // makes sure that if the price wraps, it still aligns right.
				}}
				SkeletonProps={{ width: skeletonWidths.main }}
			>
				{perMonthLabel ? perMonth(itemPriceTotal) : currency(itemPriceTotal)}
			</LWTypography>
			{!!itemDiscountTotal && (
				<Box display="flex" alignItems="center">
					<LWTypography variant="body1" color="palette.text.disabled">
						Discount
					</LWTypography>
					<LWTypography
						color="palette.success.main"
						variant="subtitle1"
						semiBold
						isLoading={isLoading}
						BoxProps={{
							minWidth: '4em',
							marginY: 0.5,
							marginLeft: 1,
						}}
					>
						{perMonth(itemDiscountTotal)}
					</LWTypography>
				</Box>
			)}
		</Box>
	);
};

Price.propTypes = {
	itemPriceTotal: PropTypes.number,
	itemDiscountTotal: PropTypes.number,
	isLoading: PropTypes.bool,
	perMonthLabel: PropTypes.bool,
};

Price.defaultProps = {
	itemPriceTotal: 0,
	itemDiscountTotal: 0,
	isLoading: false,
};

export default Price;
