import { spawn } from 'redux-saga/effects';

import { sagas as addSagas } from './addModule';
import { sagas as availableSagas } from './availableModule';
import { sagas as availableAssetsSagas } from './availableAssetsModule';
import { sagas as categoriesSagas } from './categoriesModule';
import { sagas as createSagas } from './createModule';
import { sagas as deleteSagas } from './deleteModule';
import { sagas as detailsSagas } from './detailsModule';
import { sagas as listSagas } from './listModule';
import { sagas as renameSagas } from './renameModule';
import { sagas as removeSagas } from './removeModule';

export default function* sagas() {
	yield spawn(addSagas);
	yield spawn(availableSagas);
	yield spawn(availableAssetsSagas);
	yield spawn(categoriesSagas);
	yield spawn(createSagas);
	yield spawn(deleteSagas);
	yield spawn(detailsSagas);
	yield spawn(listSagas);
	yield spawn(renameSagas);
	yield spawn(removeSagas);
}
