const actions = {
	OPEN_DIALOG: 'TASK_SCHEDULER_OPEN_DIALOG',
	openDialog: (payload) => ({
		type: actions.OPEN_DIALOG,
		payload,
	}),
	CLOSE_DIALOG: 'TASK_SCHEDULER_CLOSE_DIALOG',
	closeDialog: () => ({
		type: actions.CLOSE_DIALOG,
	}),
	SCHEDULE: 'TASK_SCHEDULER_SCHEDULE',
	schedule: (payload) => ({
		type: actions.SCHEDULE,
		payload,
	}),
	GET_CURRENT_TASKS: 'TASK_SCHEDULER_GET_CURRENT_TASKS',
	getCurrentTasks: (uniqId) => ({
		type: actions.GET_CURRENT_TASKS,
		uniqId,
	}),
};

export default actions;
