const actions = {
	SET_MARKETPLACE_TAGS: 'SET_MARKETPLACE_TAGS',
	setTags: (tags) => ({
		type: actions.SET_MARKETPLACE_TAGS,
		tags,
	}),
	SET_PRIMARY_MARKETPLACE_TAG: 'SET_PRIMARY_MARKETPLACE_TAG',
	setPrimaryTag: (primaryTag) => ({
		type: actions.SET_PRIMARY_MARKETPLACE_TAG,
		activePrimaryTag: primaryTag,
	}),
	SET_MARKETPLACE_PAGE: 'SET_MARKETPLACE_PAGE',
	setPage: (newPage) => ({
		type: actions.SET_MARKETPLACE_PAGE,
		page: newPage,
	}),
	SET_MARKETPLACE_ITEMS_PER_PAGE: 'SET_MARKETPLACE_ITEMS_PER_PAGE',
	setItemsPerPage: (newItemsPerPage) => ({
		type: actions.SET_MARKETPLACE_ITEMS_PER_PAGE,
		itemsPerPage: newItemsPerPage,
	}),
	OPEN_SIDESHEET: 'OPEN_SIDESHEET',
	openSidesheet: (payload) => ({
		type: actions.OPEN_SIDESHEET,
		payload,
	}),
	OPEN_SIDESHEET_FOR_PRODUCT: 'OPEN_SIDESHEET_FOR_PRODUCT',
	openSidesheetForProduct: (payload) => ({
		type: actions.OPEN_SIDESHEET_FOR_PRODUCT,
		payload,
	}),
	SET_MARKETPLACE_SELECTED_TAB: 'SET_MARKETPLACE_SELECTED_TAB',
	setSelectedTab: (selectedTab) => ({
		type: actions.SET_MARKETPLACE_SELECTED_TAB,
		selectedTab,
	}),
};

export default actions;
