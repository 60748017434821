import { all, put, select, call, take, takeLatest } from 'redux-saga/effects';

import { push } from 'connected-react-router';

import {
	selectors as itemDetailsSelectors,
	actions as itemDetailsActions,
} from 'modules/api/basket/item/detailsModule';
import stormConfigSelectors from 'modules/api/basket/item/detailsModule/stormConfigSelectors';
import { actions as swapActions } from 'modules/api/basket/item/swapModule';
import dialogActions from 'modules/dialogs/actions';

import { itemAssertProperties } from 'modules/basket/sagas/itemAssertProperties';

import configActions from './actions';
import basketActions from '../actions';
import { generateGetNeedsSwap } from './selectors';

function* resetProperties() {
	yield call(itemAssertProperties, {
		properties: { parent: null, diskspace: null, memory: null, vcpu: null },
	});
}

function* setDeployOnto({ deployOnto, noAssert }) {
	yield put(dialogActions.close()); // If swap needs to be called, there is a modal to close
	const uuid = yield select(itemDetailsSelectors.uuid);

	const { parent } = (yield select(itemDetailsSelectors.properties)) || {};
	const isChild = !!parent;
	const isVps = yield select(itemDetailsSelectors.isVps);
	const isCloudDedicated = yield select(itemDetailsSelectors.isCloudDedicated);

	if (
		// .VM to anything other than .VM needs a swap
		(isCloudDedicated && deployOnto !== 'cloudDedicated') ||
		// .VPS or children should swap only if going to a .VM
		(isVps && deployOnto === 'cloudDedicated')
	) {
		if (isChild) yield call(resetProperties);
		yield put(
			basketActions.itemChangeStormType({
				uuid,
			}),
		);
		yield all([
			take([swapActions.setType, swapActions.errorType]),
			take([itemDetailsActions.setType, itemDetailsActions.errorType]),
		]);
	}

	// Set private parent whenever deploying to a private parent
	if (deployOnto !== 'cloudDedicated' && deployOnto !== 'vps') {
		yield put(configActions.setPrivateParent({ deployOnto, noAssert }));
	}

	// Set initial config when moving from a private parent to VPS
	if (isChild && deployOnto === 'vps') {
		yield call(resetProperties);
		const firstOptionValue = (yield select(
			stormConfigSelectors.rawOptions,
		))?.[0]?.value; // defaults back to this if dropdown is changed back to VPS.
		yield put(
			basketActions.setItemConfig({ key: 'ConfigId', value: firstOptionValue }),
		);
	}
}

function* addNewChild({ parent }) {
	yield put(push(`/shop/add/SS.VPS?properties=${JSON.stringify({ parent })}`));
}

function* onChangeDeployOnto({ value, confirm }) {
	const needsSwap = yield select(generateGetNeedsSwap(value));
	if (needsSwap) {
		yield put(
			dialogActions.open({
				title: 'Change Product Type?',
				color: 'danger',
				contentKey: 'ConfigSelectModalContents',
				contentProps: {
					confirm,
					variant: 'type',
				},
			}),
		);
	} else {
		confirm();
	}
}

export default function*() {
	yield takeLatest(
		[configActions.SET_PRODUCT_CONFIG_DEPLOY_ONTO],
		setDeployOnto,
	);
	yield takeLatest([configActions.ADD_NEW_CHILD], addNewChild);
	yield takeLatest([configActions.ON_CHANGE_DEPLOY_ONTO], onChangeDeployOnto);
}
