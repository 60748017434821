import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { selectors as contactListSelectors } from 'modules/api/contact/listModule';
import AddressForm from './AddressForm';

const mapStateToProps = (state) => ({
	contact: contactListSelectors.getDefaultContact(state),
});

export default connect(mapStateToProps)(withRouter(AddressForm));
