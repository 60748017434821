import { spawn } from 'redux-saga/effects';
import authSagas from './auth/authSagas';
import billingAddressSagas from './billingAddress/billingAddressSagas';
import chatPasswordSagas from './chatpassword/chatPasswordSagas';
import limitsSagas from './limits/limitsSagas';
import settingsSagas from './settings/settingsSagas';
import sshSagas from './ssh/sshSagas';
import userSagas from './user/userSagas';

import { sagas as adCampaignsSagas } from './adCampaignsModule';
import { sagas as detailsSagas } from './detailsModule';
import { sagas as invoicesAndPaymentsTimelineSaga } from './invoicesAndPaymentsTimelineModule';
import { sagas as signupSagas } from './signupModule';
import { sagas as supportSummarySagas } from './supportSummaryModule';

export default function* sagas() {
	yield spawn(adCampaignsSagas);
	yield spawn(authSagas);
	yield spawn(billingAddressSagas);
	yield spawn(chatPasswordSagas);
	yield spawn(detailsSagas);
	yield spawn(invoicesAndPaymentsTimelineSaga);
	yield spawn(limitsSagas);
	yield spawn(settingsSagas);
	yield spawn(signupSagas);
	yield spawn(sshSagas);
	yield spawn(supportSummarySagas);
	yield spawn(userSagas);
}
