const stormCloudDedicatedProductCodes = ['SS.VM', 'SS.VM.WIN', 'SS.VM.R'];

const stormVpsProductCodes = ['SS.VPS', 'SS.VPS.WIN', 'SS.VPS.WT'];

const stormProductCodes = [
	'SS.PP',
	...stormCloudDedicatedProductCodes,
	...stormVpsProductCodes,
];

const isCloudDedicated = (productCode) =>
	stormCloudDedicatedProductCodes.includes(productCode);
const isVps = (productCode) => stormVpsProductCodes.includes(productCode);
const isStorm = (productCode) => stormProductCodes.includes(productCode);

export { isCloudDedicated, isVps };

export default isStorm;
