import {
	createAPIModule,
	createSelectors,
} from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';

const getStateSlice = (state) => state.account.user.stateData.listForKey;
const moduleKeys = {
	NAVTRACKING: 'navTracking',
	FAVORITES: 'favorites',
	MAX_WIDTH: 'maxWidth',
};

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'ACCOUNT_USER_STATEDATA_LISTFORKEY',
	url: '/account/user/statedata/listForKey.json',
});

const navTrackingSelectors = createSelectors(
	getStateSlice,
	false,
	moduleKeys.NAVTRACKING,
);

const favoritesSelectors = createSelectors(
	getStateSlice,
	false,
	moduleKeys.FAVORITES,
);

const maxWidthSelectors = createSelectors(
	getStateSlice,
	false,
	moduleKeys.MAX_WIDTH,
);

const maxWidth = createSelector(
	maxWidthSelectors.getNativeData,
	(slice) => slice?.data?.[0]?.value,
);

const selectors = {
	navTrackingSelectors,
	favoritesSelectors,
	maxWidthSelectors,
	maxWidth,
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors, moduleKeys };
