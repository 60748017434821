import { spawn } from 'redux-saga/effects';

import { sagas as create } from './createModule';
import { sagas as deleteSagas } from './deleteModule';
import { sagas as details } from './detailsModule';

import { sagas as list } from './listModule';
import { sagas as update } from './updateModule';
import { sagas as restore } from './restoreModule';

export default function* sagas() {
	yield spawn(create);
	yield spawn(restore);
	yield spawn(deleteSagas);
	yield spawn(details);
	yield spawn(list);
	yield spawn(update);
}
