import threatStackActions from './actions';

const reducer = (state = { pageNum: 1, pageSize: 10 }, action) => {
	switch (action.type) {
		case threatStackActions.SET_THREATSTACK_PAGE:
			return { ...state, pageNum: action.pageNum };
		case threatStackActions.SET_THREATSTACK_ITEMS_PER_PAGE:
			return { ...state, pageSize: action.pageSize };
		default:
			return state;
	}
};

export default reducer;
