import { all, call } from 'redux-saga/effects';

import accountSagas from 'modules/api/account/accountSagas';
import acronisSagas from 'modules/api/acronis/acronisSagas';
import assetSagas from 'modules/api/asset/assetSagas';
import basketSagas from 'modules/api/basket/basketSagas';
import billingSagas from 'modules/api/billing/billingSagas';
import contactSagas from 'modules/api/contact/contactSagas';
import domainSagas from 'modules/api/domain/domainSagas';
import licensingSagas from 'modules/api/licensing/licensingSagas';
import monitoringSagas from 'modules/api/monitoring/monitoringSagas';
import networkSagas from 'modules/api/network/networkSagas';
import notificationsSagas from 'modules/api/notifications/notificationsSagas';
import onetimesecretSagas from 'modules/api/onetimesecret/onetimesecretSagas';
import productSagas from 'modules/api/product/productSagas';
import projectSagas from 'modules/api/project/projectSagas';
import salesforceSagas from 'modules/api/salesforce/salesforceSagas';
import serverSagas from 'modules/api/server/serverSagas';
import sslCertificateSagas from 'modules/api/ssl/certificate/sslCertificateSagas';
import storageSagas from 'modules/api/storage/storageSagas';
import stormSagas from 'modules/api/storm/stormSagas';
import supportSagas from 'modules/api/support/supportSagas';
import tagsSagas from 'modules/api/tags/tagsSagas';
import usertaskSagas from 'modules/api/usertask/usertaskSagas';
import utilitiesSagas from 'modules/api/utilities/utilitiesSagas';
import vpnSagas from 'modules/api/vpn/vpnSagas';

export default function*() {
	yield all([
		call(accountSagas),
		call(acronisSagas),
		call(assetSagas),
		call(basketSagas),
		call(billingSagas),
		call(contactSagas),
		call(domainSagas),
		call(licensingSagas),
		call(monitoringSagas),
		call(networkSagas),
		call(notificationsSagas),
		call(onetimesecretSagas),
		call(productSagas),
		call(projectSagas),
		call(salesforceSagas),
		call(serverSagas),
		call(sslCertificateSagas),
		call(storageSagas),
		call(stormSagas),
		call(supportSagas),
		call(tagsSagas),
		call(usertaskSagas),
		call(utilitiesSagas),
		call(vpnSagas),
	]);
}
