const details = {
	address: '123 Nonaya Buisiness Ave.',
	address2: 'Infinity Complex Suite 0',
	city: 'Lake Armstrong',
	country: 'Peoples Republic of the Moon',
	postal_code: '66666',
	state: 'Insipid',
	full_name: 'Feeble Monomethylhydrazine',
};

export default details;
