import React from 'react';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import InputLabel from './InputLabel';

const ProjectInput = ({
	showLabels,
	project,
	setProject,
	inputProject,
	setInputProject,
	projects,
	isLoading,
}) => (
	<Grid item container alignItems="center">
		{showLabels && (
			<Grid item>
				<InputLabel>B.</InputLabel>
			</Grid>
		)}
		<Grid xs item>
			<Autocomplete
				freeSolo
				fullWidth
				value={project}
				onChange={(event, newValue) => {
					setProject(newValue);
				}}
				inputValue={inputProject}
				onInputChange={(event, newInputValue) => {
					// This is triggered on mount with a null event and empty newInputValue
					// that overwrites the default set in the state initialization
					if (event) setInputProject(newInputValue);
				}}
				options={projects}
				renderInput={(params) => (
					<TextField
						{...params}
						inputProps={{ ...params.inputProps, 'data-lpignore': true }}
						label="Project (optional)"
						variant="outlined"
						helperText={isLoading ? 'loading projects...' : undefined}
					/>
				)}
			/>
		</Grid>
	</Grid>
);

ProjectInput.propTypes = {
	showLabels: PropTypes.bool,
	project: PropTypes.string,
	setProject: PropTypes.func,
	inputProject: PropTypes.string,
	setInputProject: PropTypes.func,
	projects: PropTypes.array,
};

export default ProjectInput;
