import { combineReducers } from 'redux';

import { reducer as create } from './createModule';
import { reducer as list } from './listModule';
import { reducer as read } from './readModule';

export default combineReducers({
	create,
	list,
	read,
});
