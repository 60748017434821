import connectAndWrap from 'utility/form/connectAndWrap';
import { selectors as taxExemptReasonsSelectors } from 'modules/api/contact/taxExemptReasonsModule';
import { selectors as listSelectors } from 'modules/api/contact/listModule';
import submit from './submit';
import TaxFormSection from './TaxFormSection';

const formName = 'taxInfo';

const mapStateToPropsForm = (state) => ({
	formValues: { ...state.form[formName]?.values },
	taxExemptReasons: taxExemptReasonsSelectors.reasons(state),
	selectedCountry: listSelectors.countryOption(state),
	initialValues: {
		taxExempt: listSelectors.taxExempt(state),
		taxExemptReason: listSelectors.taxExemptReason(state),
		taxId: listSelectors.taxId(state),
		taxExemptId: listSelectors.taxExemptId(state),
	},
});

const ConnectedTaxFormSection = connectAndWrap({
	mapStateToProps: mapStateToPropsForm,
	form: formName,
	onSubmit: submit,
})(TaxFormSection);

export { formName };
export default ConnectedTaxFormSection;
