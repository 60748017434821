// We need to always pass all these keys to the dataLayer, even if they are undef, to override the previously sent data
const defaultData = {
	category: undefined,
	action: undefined,
	label: undefined,
	value: undefined,
	isNonInteraction: false,
	variable: undefined,
};

const defaultParams = {
	ecommerce: undefined,
};

export { defaultData, defaultParams };
