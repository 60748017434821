import { spawn } from 'redux-saga/effects';

import { sagas as ipSagas } from './ipModule';
import { sagas as dynamicChild } from './dynamicChildModule';
import { sagas as servers } from './serversModule';

export default function* limitsSagas() {
	yield spawn(ipSagas);
	yield spawn(dynamicChild);
	yield spawn(servers);
}
