const dynamicChild = {
	Managed: { Cpu: 1, Disk: 15, Memory: 880 },
	Unmanaged: { Cpu: 1, Disk: 15, Memory: 512 },
	Windows: { Cpu: 1, Disk: 35, Memory: 1750 },
};
const ip = { limit: '12', limit_ipv6: '1', nocworx_openstack_limit: '5' };
const servers = { limit: 12, used: 4 };

const path = '/account/limits/';

export default {
	[`${path}dynamicChild.json`]: dynamicChild,
	[`${path}ip.json`]: ip,
	[`${path}servers.json`]: servers,
};

export { dynamicChild, ip, servers };
