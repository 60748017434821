import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { connect } from 'react-redux';
import compose from 'utility/compose';
import classNames from 'classnames';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import { selectors as appConfigSelectors } from 'modules/api/appConfigModule';
import { ReactComponent as LWLogo } from 'images/logo-no-text.svg';
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreRounded';
import chatActions from 'modules/chat/actions';
import Help from '@material-ui/icons/HelpRounded';
import Person from '@material-ui/icons/PersonRounded';
import { NavLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import LWLink from 'components/common/LWLink';
import MenuIcon from '@material-ui/icons/MenuRounded';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Badge from '@material-ui/core/Badge';
import { navLinks } from 'config/routingConfig';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import dialogActions from 'modules/dialogs/actions';

import manageRedirectActions from 'modules/manageRedirect/manageRedirectActions';
import LWTypography from 'components/common/LWTypography';
import NavLinkHelper from '../NavLinkHelper';

const SLWLogo = styled(LWLogo)`
	width: 36px;
	height: 36px;
`;

const styles = (theme) => ({
	root: {
		paddingLeft: '1.5em',
		paddingRight: '1.5em',

		textDecoration: 'none',
		marginRight: '.5em',
		display: 'flex',
		borderRight: '2px solid #e9e9e9',
	},
	panel: {
		boxShadow: 'none',
		'&::before': {
			background: 'none',
		},
	},
	heading: {
		display: 'flex',
		alignItems: 'center',
	},
	text: {
		lineHeight: 1,
		textAlign: 'center',
		position: 'relative',
		top: '50%',
		transform: 'translateY(-50%)',
	},
	typography: {
		width: '100%',
		color: '#787878',
	},
	menuIcon: {
		fontSize: '3em',
	},
	list: { width: '100%' },
	listWrapper: {
		paddingTop: '5.6em',
	},
	link: {
		textDecoration: 'none',
		color: theme.palette.primary.alt,
	},
	listItem: {
		width: '100%',
		paddingTop: theme.spacing(0.5),
		paddingBottom: theme.spacing(0.5),
	},
	listIconContainer: {
		color: theme.palette.common.accent[0],
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		fontSize: '36px',
	},
	listIcon: {
		fontSize: 'inherit',
		color: 'inherit',
	},
	activeLink: {
		'& $listItem': {
			backgroundColor: theme.palette.common.grey[0],
			color: theme.palette.common.accent[0],
		},
	},
	upperCase: {
		textTransform: 'uppercase',
	},
	indentInner: {
		paddingTop: 0,
		paddingBottom: 0,
		paddingLeft: theme.spacing(6),
	},
	expansionSummary: {
		height: 48,
		'&.Mui-expanded': {
			minHeight: 48,
		},
	},
	menuButtonBadge: {
		position: 'static',
	},
});

class MenuButton extends React.Component {
	state = { drawerOpen: false };

	toggleDrawer = () => {
		const { drawerOpen } = this.state;
		this.setState({ drawerOpen: !drawerOpen });
	};

	menuKeyDown = (event) => {
		if (event.keyCode === 32) {
			this.toggleDrawer();
		}
	};

	generateNavLink = (navData, classes, isKeroOnly, isLoggedIn, isSetupUser) => {
		if (
			(!isLoggedIn && !navData.get('allowUnauth')) ||
			isKeroOnly ||
			(isSetupUser && !navData.get('allowUnauth'))
		)
			return null;
		if (navData.get('path') === '/') {
			return (
				<NavLink
					exact={navData.get('path') === '/'}
					to={navData.get('path')}
					activeClassName={classNames(classes.link, classes.activeLink)}
					className={classes.link}
					key={navData.get('name')}
					onClick={(e) => {
						this.toggleDrawer(false);
						const { openManageRedirectDialog } = this.props;
						if (
							navData.get('name') !== 'Home' &&
							navData.get('notYetImplemented')
						) {
							openManageRedirectDialog(e);
						}
					}}
				>
					<ListItem className={classes.listItem} button>
						<ListItemIcon className={classes.listIconContainer}>
							{navData.get('icon')({
								fontSize: 'inherit',
								color: 'inherit',
							})}
						</ListItemIcon>
						<LWTypography className={classes.heading}>
							{navData.get('name')}
						</LWTypography>
					</ListItem>
				</NavLink>
			);
		}
		const navSubData = navData.get('subNav');
		const {
			configObj,
			closeDialog,
			openDialog,
			openManageRedirectDialog,
			secureNotesCount,
		} = this.props;
		const secureNotesBadge =
			navData.get('name') === 'Account' && Number(secureNotesCount);
		return (
			<span key={navData.get('path')}>
				<ExpansionPanel className={classes.panel}>
					<ExpansionPanelSummary
						className={classes.expansionSummary}
						expandIcon={<ExpandMoreIcon />}
					>
						<ListItemIcon className={classes.listIconContainer}>
							{navData.get('icon')({
								fontSize: 'inherit',
								color: 'inherit',
							})}
						</ListItemIcon>
						<LWTypography className={classes.heading}>
							{secureNotesBadge ? (
								<Badge badgeContent={secureNotesCount} color="error">
									{navData.get('name')}
								</Badge>
							) : (
								navData.get('name')
							)}
						</LWTypography>
					</ExpansionPanelSummary>
					<ExpansionPanelDetails className={classes.indentInner}>
						<List className={classes.list}>
							<LWLink
								to={navData.get('path')}
								activeClassName={classNames(classes.link, classes.activeLink)}
								className={classes.link}
								key={navData.get('name')}
							>
								<ListItem
									className={classes.listItem}
									button
									onClick={(e) => {
										this.toggleDrawer(false);
										if (
											navData.get('name') !== 'Home' &&
											navData.get('notYetImplemented')
										) {
											openManageRedirectDialog(e);
										}
									}}
								>
									<ListItemText
										className={classes.upperCase}
										primary={navData.get('mobileName') || navData.get('name')}
									/>
								</ListItem>
							</LWLink>
							{navSubData &&
								navSubData.entrySeq().map(([key, navLink]) => {
									if (navLink.get('breadcrumbHidden')) return null;
									if (navLink.get('navHidden')) return null;
									const navLinkHelper = new NavLinkHelper({
										navLink,
										classes,
										configObj,
										openDialog,
										closeDialog,
										openManageRedirectDialog,
									});

									return (
										<LWLink
											onClick={navLinkHelper.onClick}
											to={navLink.get('path')}
											activeClassName={classNames(
												classes.link,
												classes.activeLink,
											)}
											className={classes.link}
											key={key}
										>
											<ListItem
												className={classes.listItem}
												button
												onClick={() => {
													this.toggleDrawer(false);
												}}
											>
												<ListItemText
													className={classes.upperCase}
													color="primary"
												>
													{navLinkHelper.linkContents}
												</ListItemText>
											</ListItem>
										</LWLink>
									);
								})}
						</List>
					</ExpansionPanelDetails>
				</ExpansionPanel>
			</span>
		);
	};

	renderWww() {
		const {
			classes,
			isLoggedIn,
			isLoadingAccountDetails,
			wwwHostname,
			isSetupUser,
		} = this.props;
		if (isLoadingAccountDetails || (isLoggedIn && !isSetupUser)) return null;

		return (
			<ListItem
				className={classes.listItem}
				button
				onClick={() => window.open(wwwHostname, '_blank', 'noopener')}
			>
				<ListItemIcon className={classes.listIconContainer}>
					<SLWLogo />
				</ListItemIcon>
				<LWTypography className={classes.heading}>www</LWTypography>
			</ListItem>
		);
	}

	renderCreateAccount() {
		const {
			classes,
			isKeroOnly,
			isSetupUser,
			isLoggedIn,
			idpHostname,
			idpClientId,
		} = this.props;
		if (isKeroOnly || isSetupUser || isLoggedIn) return null;

		return (
			<ListItem
				className={classes.listItem}
				button
				onClick={() =>
					window.location.assign(
						`${idpHostname}/signup?client_id=${idpClientId}&amp;response_type=token%20id_token&amp;scope=openid%20profile%20grants`,
					)
				}
			>
				<ListItemIcon className={classes.listIconContainer}>
					<Person className={classes.listIcon} />
				</ListItemIcon>
				<LWTypography className={classes.heading}>Create Account</LWTypography>
			</ListItem>
		);
	}

	renderSupport() {
		const {
			startChat,
			customerCommunity,
			isSetupUser,
			isLoggedIn,
		} = this.props;
		if (!isLoggedIn || isSetupUser) return null;
		const helpSubNavData = {
			helpCenter: {
				name: 'Help Center',
				onClick: () => window.open(customerCommunity, '_blank', 'noopener'),
			},
			myTickets: {
				onClick: () =>
					window.open(
						`${customerCommunity}/open-tickets`,
						'_blank',
						'noopener',
					),
				name: 'My Tickets',
			},
			liquidWebStatus: {
				onClick: () =>
					window.open('https://status.liquidweb.com/', '_blank', 'noopener'),
				name: 'Liquid Web Status',
			},
			openATicket: {
				onClick: () =>
					window.open(
						`${customerCommunity}/contactsupport`,
						'_blank',
						'noopener',
					),
				name: 'Open a Ticket',
			},
			chatWithAHuman: {
				onClick: () => startChat(),
				name: 'Chat with a Human',
			},
		};
		const { classes } = this.props;
		return (
			<ExpansionPanel className={classes.panel}>
				<ExpansionPanelSummary
					className={classes.expansionSummary}
					expandIcon={<ExpandMoreIcon />}
				>
					<ListItemIcon className={classes.listIconContainer}>
						<Help className={classes.listIcon} />
					</ListItemIcon>
					<LWTypography className={classes.heading}>Support</LWTypography>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails className={classes.indentInner}>
					<List className={classes.list}>
						{helpSubNavData &&
							Object.keys(helpSubNavData).map((key) => {
								const subLink = helpSubNavData[key];
								return (
									<LWLink
										onClick={subLink.onClick}
										to={subLink.path || '/'}
										activeClassName={classNames(
											classes.link,
											classes.activeLink,
										)}
										className={classes.link}
										key={subLink.name}
									>
										<ListItem
											className={classes.listItem}
											button
											onClick={() => {
												this.toggleDrawer(false);
											}}
										>
											<ListItemText
												className={classes.upperCase}
												primary={subLink.name}
											/>
										</ListItem>
									</LWLink>
								);
							})}
					</List>
				</ExpansionPanelDetails>
			</ExpansionPanel>
		);
	}

	render() {
		const {
			classes,
			secureNotesCount,
			isLoggedIn,
			isSetupUser,
			isKeroOnly,
		} = this.props;
		const { drawerOpen } = this.state;
		const badge = { content: secureNotesCount };
		return (
			<div className={classes.root} data-test-id="NavBar__MenuButton">
				<LWTypography variant="button" className={classes.typography}>
					<div
						className={classes.text}
						onClick={() => this.toggleDrawer(true)}
						role="button"
						tabIndex={0}
						onKeyDown={this.menuKeyDown}
					>
						<Badge
							className={classes.menuButtonBadge}
							invisible={!badge || !Number(badge.content)}
							badgeContent={badge && badge.content}
							variant={badge && badge.isDot ? 'dot' : 'standard'}
							color="error"
						/>
						<MenuIcon className={classes.menuIcon} />
					</div>
				</LWTypography>
				<Drawer
					className={classes.drawer}
					anchor="top"
					open={drawerOpen}
					onClose={() => this.toggleDrawer()}
				>
					<div className={classes.listWrapper}>
						<List className={classes.list}>
							{this.renderWww()}
							{navLinks
								.filter((navData) => !navData.get('navHidden'))
								.map((navData) =>
									this.generateNavLink(
										navData,
										classes,
										isKeroOnly,
										isLoggedIn,
										isSetupUser,
									),
								)}
							{this.renderCreateAccount()}
							{this.renderSupport()}
						</List>
					</div>
				</Drawer>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	configObj: appConfigSelectors.getNativeData(state),
});
const mapDispatchToProps = (dispatch) => ({
	startChat: () => dispatch(chatActions.startChat()),

	openDialog: ({ title, content, DialogProps }) =>
		dispatch(dialogActions.open({ title, content, DialogProps })),
	closeDialog: () => dispatch(dialogActions.close()),
	openManageRedirectDialog: (e, url) => {
		e.preventDefault();
		dispatch(manageRedirectActions.setOpen(true, url));
	},
});

MenuButton.propTypes = {
	classes: PropTypes.object.isRequired,
	openManageRedirectDialog: PropTypes.func.isRequired,
	isLoggedIn: PropTypes.bool.isRequired,
	isSetupUser: PropTypes.bool.isRequired,
};

export { MenuButton };

export default compose(
	withStyles(styles),
	connect(
		mapStateToProps,
		mapDispatchToProps,
	),
)(MenuButton);
