import moment from 'moment';
/**
 * Convert seconds to time format
 *
 * @param Number s
 *
 * @return String in HH:mm:ss
 */
function time(s) {
	return moment.unix(s).format('HH:mm:ss A');
}
function dateTime(s) {
	return moment(s).format('MM-DD-YY hh:MM a');
}
function dateTimeLong(s) {
	return moment(s).format('MMMM Do, YYYY - hh:mm A');
}
// eslint-disable-next-line import/prefer-default-export
export { dateTime, dateTimeLong, time };
