import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import dialogActions from 'modules/dialogs/actions';

// TODO: use @material-ui/icons/ProductionQuantityLimitsRounded once that's in the icons repo
import { ReactComponent as CartWarningIcon } from 'images/production_quantity_limits_rounded.svg';
import StyledBox from 'components/atoms/StyledBox';
import LabeledIcon from 'components/atoms/LabeledIcon';
import HostnameProjectDialog from 'containers/pages/shop/dialogs/HostnameProjectDialog';

const SStyledBox = styled(StyledBox)`
	cursor: ${({ $isClickable }) => ($isClickable ? 'pointer' : 'default')};
`;

const HostnameNeeded = ({
	itemDetails = undefined,
	hostnameDisplay = 'Hostname',
	...rest
}) => {
	// TODO: move this to components/connectedMolecules
	const dispatch = useDispatch();

	const isClickable = !!itemDetails;

	return (
		<SStyledBox
			$isClickable={isClickable}
			variant="tag"
			onClick={
				isClickable
					? () =>
							dispatch(
								dialogActions.open({
									content: <HostnameProjectDialog itemDetails={itemDetails} />,
								}),
							)
					: () => {}
			}
			{...rest}
		>
			<LabeledIcon
				icon={<CartWarningIcon />}
				textProps={{ variant: 'body2' }}
				text={`${hostnameDisplay} required before checkout`}
			/>
		</SStyledBox>
	);
};

export default HostnameNeeded;
