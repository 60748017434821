import { combineReducers } from 'redux';
import { reducer as apply } from './applyModule';
import { reducer as hostedFormToken } from './hostedFormTokenModule';
import { reducer as details } from './detailsModule';

export default combineReducers({
	apply,
	hostedFormToken,
	details,
});
