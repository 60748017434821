import React from 'react';
import { useLocation } from 'react-router-dom';

function ResetScrollUponNav() {
	const { pathname } = useLocation();
	// Scroll to top when pathname changes
	// https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/docs/guides/scroll-restoration.md
	React.useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return null;
}

export default ResetScrollUponNav;
