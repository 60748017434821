import React from 'react';
import PropTypes from 'prop-types';
import ReportProblem from '@material-ui/icons/ReportProblem';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Info from '@material-ui/icons/Info';
import MUISnackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import styled, { css } from 'styled-components';

const types = ['info', 'success', 'error', 'warning'];

const FSnackbar = ({ statusSheet, ...rest }) => <MUISnackbar {...rest} />;
const SSnackbar = styled(FSnackbar)`
${(p) =>
	p.statusSheet.isOpen &&
	css`
		@media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
			bottom: ${p.statusSheet.height + 36}px;
		}
		@media (max-width: ${({ theme }) => theme.breakpoints.values.sm}px) {
			bottom: ${p.statusSheet.height + 20}px;
		}
	`}
.MuiSnackbarContent-message {
	display:flex;
	align-items:center;
	justify-content:center;
	svg{
		margin-right:${(p) => p.theme.spacing(1)}px;
	}
}
${(p) =>
	p.color === 'info' &&
	css`
		.MuiSnackbarContent-root {
			background: ${p.theme.palette.background.paper};
			color: ${p.theme.palette.text.primary};
			border: 2px solid ${p.theme.palette.info.main};
			svg {
				fill: ${p.theme.palette.info.main};
			}
			border-radius: 5px;
		}
	`}
${(p) =>
	p.color === 'success' &&
	css`
		.MuiSnackbarContent-root {
			background: ${p.theme.palette.background.paper};
			color: ${p.theme.palette.text.primary};
			border: 2px solid ${p.theme.palette.success.main};
			svg {
				fill: ${p.theme.palette.success.main};
			}
			border-radius: 5px;
		}
	`}
${(p) =>
	p.color === 'error' &&
	css`
		.MuiSnackbarContent-root {
			background: ${p.theme.palette.background.paper};
			color: ${p.theme.palette.text.primary};
			border: 2px solid ${p.theme.palette.error.main};
			svg {
				fill: ${p.theme.palette.error.main};
			}
			border-radius: 5px;
		}
	`}
${(p) =>
	p.color === 'warning' &&
	css`
		.MuiSnackbarContent-root {
			background: ${p.theme.palette.background.paper};
			color: ${p.theme.palette.text.primary};
			border: 2px solid ${p.theme.palette.warning.main};
			svg {
				fill: ${p.theme.palette.warning.main};
			}
			border-radius: 5px;
		}
	`}
`;

const Snackbar = ({
	closeSnackbar,
	isOpen,
	message,
	color,
	showIcon = false,
	statusSheet,
	autoHideDuration = 6000,
}) => {
	let icon = null;
	if (showIcon || types.includes(color)) {
		switch (color) {
			case 'success':
				icon = <CheckCircle />;
				break;
			case 'error':
			case 'warning':
				icon = <ReportProblem />;
				break;
			case 'info':
				icon = <Info />;
				break;
			default:
				break;
		}
	}

	return (
		<SSnackbar
			color={color}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center',
			}}
			open={isOpen}
			onClose={closeSnackbar}
			autoHideDuration={autoHideDuration}
			statusSheet={statusSheet}
		>
			<SnackbarContent
				message={
					<>
						{icon}
						{message}
					</>
				}
				color="secondary"
			/>
		</SSnackbar>
	);
};

Snackbar.defaultProps = {
	message: null,
};

Snackbar.propTypes = {
	closeSnackbar: PropTypes.func.isRequired,
	isOpen: PropTypes.bool.isRequired,
	message: PropTypes.string,
	color: PropTypes.string,
	statusSheet: PropTypes.shape({
		isOpen: PropTypes.bool.isRequired,
		height: PropTypes.number.isRequired,
	}),
	autoHideDuration: PropTypes.number.isRequired,
};

export default Snackbar;
