const acronis = {
	ADD_ACRONIS: 'ADD_ACRONIS',
	addAcronis: (payload) => ({
		type: acronis.ADD_ACRONIS,
		payload,
	}),
	REMOVE_ACRONIS: 'REMOVE_ACRONIS',
	removeAcronis: ({ uuid }) => ({
		type: acronis.REMOVE_ACRONIS,
		uuid,
	}),
};

export default acronis;
