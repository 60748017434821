import { put, take, call } from 'redux-saga/effects';
import basketActions from 'modules/basket/actions';

import { actions as removeActions } from 'modules/api/basket/item/removeModule';

import snackbarSaga from 'modules/snackbar/sagas';

function* handleResponse({ productCode, error }) {
	yield call(snackbarSaga, {
		errorMessage: 'Failed to remove item from the cart',
		error,
	});
	if (!error) {
		yield put(basketActions.itemRemoved({ productCode }));
	}
}

function* handleRemoveItem({ payload: { uuid, productCode } }) {
	yield put(removeActions.fetch({ uuid }));
	const removeResult = yield take([
		removeActions.setType,
		removeActions.errorType,
	]);
	const error = removeResult?.type === removeActions.errorType;
	yield call(handleResponse, { productCode, error });
}

export { handleResponse };
export default handleRemoveItem;
