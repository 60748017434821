// TODO: redo these styles to be more generic (does not work in storybook.)

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Box from '@material-ui/core/Box';
import LWTypography from 'components/common/LWTypography';
import ExpansionArrow from 'components/atoms/ExpansionArrow';

const SAccordion = styled(Accordion)`
	&:first-child,
	&:last-child {
		border-top-left-radius: inherit;
		border-top-right-radius: inherit;
		border-bottom-left-radius: inherit;
		border-bottom-right-radius: inherit;
	}
	.MuiAccordionSummary-root {
		padding: 0;
		justify-content: flex-start;
		&.Mui-expanded.MuiButtonBase-root {
			min-height: 0rem;
			max-height: 2.875rem;
			height: 2.875rem;
		}
	}
	.MuiAccordionSummary-content {
		flex-grow: inherit;
		align-items: center;
		transition: all 0s;
		border-bottom: 1px solid ${(p) => p.theme.palette.common.grey[2]};
	}
	.MuiAccordionDetails-root {
		padding-left: 0;
	}
	.MuiSvgIcon-root {
		transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
		transform: rotate(0deg);
	}
	.Mui-expanded {
		& > .MuiSvgIcon-root {
			transform: rotate(180deg);
		}
	}
`;

const CartItemExpansion = ({
	title,
	children,
	isExpanded = false,
	setIsExpanded,
}) => {
	const [localIsExpanded, setLocalIsExpanded] = useState(false);
	const handleClick = () => {
		if (setIsExpanded) setIsExpanded(!isExpanded);
		else setLocalIsExpanded(!localIsExpanded);
	};
	return (
		<Box width="100%">
			<SAccordion
				expanded={setIsExpanded ? isExpanded : localIsExpanded}
				elevation={0}
			>
				<AccordionSummary onClick={handleClick}>
					<LWTypography variant="subtitle2" bold>
						{title}
					</LWTypography>
					<ExpansionArrow />
				</AccordionSummary>
				<AccordionDetails>{children}</AccordionDetails>
			</SAccordion>
		</Box>
	);
};

CartItemExpansion.propTypes = {
	title: PropTypes.string.isRequired,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
	isExpanded: PropTypes.bool,
	setIsExpanded: PropTypes.func,
};

export default CartItemExpansion;
