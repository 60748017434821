import { combineReducers } from 'redux';

import { reducer as enable } from './enableModule';
import { reducer as disable } from './disableModule';
import { reducer as activationKey } from './activationKeyModule';

export default combineReducers({
	enable,
	disable,
	activationKey,
});
