import { getList } from 'testUtils/helperFunctions';
import details from './details';

const path = '/storm/image/';

export default {
	[`${path}list.json`]: ({
		page_size: pageSize,
		page_num: pageNum,
		uniq_id: uniqId,
	}) => getList({ pageSize, pageNum, data: details, uniqId }),
	[`${path}details.json`]: ({ id }) => details[id.toString()],
};
