import React, { useState, useEffect } from 'react';

// MUI components
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

// local components
import SLWLink from '../PaddedLink';
import CloneModalContents from './CloneModalContents';

const CloneLink = ({
	openDialog, // from connected
	dialogOpen, // from connected
	isCloning: isCloningApiProp, // from connected
	uuid,
	title,
}) => {
	const [isActive, setIsActive] = useState(false);
	const isCloning = isActive && isCloningApiProp;
	useEffect(() => {
		if (!isCloning && !dialogOpen) setIsActive(false);
	}, [dialogOpen, isCloning]);
	return (
		<SLWLink
			onClick={() => {
				setIsActive(true);
				openDialog({
					title: 'Create Clones',
					content: <CloneModalContents {...{ uuid, title }} />,
				});
			}}
		>
			{isCloning ? (
				<Grid container alignItems="center" spacing={1}>
					<>
						<Grid item>Cloning...</Grid>
						<Grid item>
							<CircularProgress size={16} />
						</Grid>
					</>
				</Grid>
			) : (
				<>Clone</>
			)}
		</SLWLink>
	);
};

export default CloneLink;
