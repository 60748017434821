import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';
import { selectors as listSelectors } from 'modules/api/contact/listModule';

const getStateSlice = (state) => state.contact.taxExemptReasons;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'CONTACT_TAXEXEMPTREASONS',
	method: 'POST',
	url: '/contact/taxExemptReasons.json',
});

const reasons = createSelector(
	defaultSelectors.getNativeData,
	(slice) => {
		if (slice?.reasons) {
			return Object.entries(slice.reasons).map(([value, label]) => ({
				value,
				label,
			}));
		}
		return [];
	},
);

const selectedReason = createSelector(
	reasons,
	listSelectors.taxExemptReason,
	(allReasons, selectedReasonKey) =>
		allReasons.find((elem) => elem.value === selectedReasonKey)?.label,
);

const selectors = {
	reasons,
	selectedReason,
	...defaultSelectors,
};
export { actions, reducer, sagas, selectors };
