import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.product.price;

const { actions, reducer, sagas, selectors } = createAPIModule({
	getStateSlice,
	actionType: 'PRODUCT_PRICE',
	url: '/product/price.json',
});

export { actions, reducer, sagas, selectors };
