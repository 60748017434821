import { combineReducers } from 'redux';
import { reducer as resize } from './resize';
// import { reducer as reboot } from './reboot';
// import { reducer as backupsAndImages } from './backupsAndImages';

// TODO: import reboot, and backupsAndImages through here as well. Restructure files to allow for named exports from an index.
export default combineReducers({
	resize,
	// reboot,
	// backupsAndImages,
});
