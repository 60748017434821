import { combineReducers } from 'redux';

import productConfig from '../productConfig/reducer';
import ip from '../ip/reducer';
import generic from './generic';

export default combineReducers({
	productConfig,
	generic,
	ip,
});
