import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import AppConfigError from 'containers/pages/error/AppConfigError';

export const styles = () => ({
	root: {
		height: '100vh',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
});

const AppConfigGate = ({ appConfig, children, classes }) => {
	if (appConfig.get('error')) return <AppConfigError />;

	if (appConfig.get('data')) return children;

	return (
		<div className={classes.root}>
			<CircularProgress size={80} color="primary" />
		</div>
	);
};

AppConfigGate.propTypes = {
	classes: PropTypes.object.isRequired,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
	appConfig: ImmutablePropTypes.map.isRequired,
};

const mapStateToProps = (state) => {
	const { appConfig } = state;
	return { appConfig };
};

export { AppConfigGate };
export default compose(
	withStyles(styles),
	connect(mapStateToProps),
)(AppConfigGate);
