import produce from 'immer';
import acronisActions from './actions';

const initialState = {
	isDialogOpen: false,
	error: null,
};

/* eslint-disable default-case, no-param-reassign */
const reducer = (state = initialState, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case acronisActions.SET_ERROR:
				draft.error = action.error;
				break;
			case acronisActions.TOGGLE_DIALOG:
				draft.isDialogOpen = action.isDialogOpen;
				break;
		}
	});

export default reducer;
