import Radio from '@material-ui/core/Radio';
import styled, { css } from 'styled-components';

const LWRadio = styled(Radio)`
	${({ $isChecked }) =>
		!$isChecked &&
		css`
			/* This is a work around for the checked status showing when it shouldn't be */
			svg:nth-of-type(2) {
				display: none;
			}
		`}
`;

export default LWRadio;
