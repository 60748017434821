import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, Redirect } from 'react-router-dom';
import SubNavRoutes from 'components/routers/SubNavRoutes';
import asyncComponent from 'components/AsyncComponent';
import RoleRestrictedRoute from 'utility/routing/RoleRestrictedRoute';
import { roles } from 'utility/constants/roles';

const DedicatedLoadBalancerConfig = asyncComponent(() =>
	import('containers/pages/services/DedicatedLoadBalancerConfig'),
);
const CloudLoadBalancerConfig = asyncComponent(() =>
	import('containers/pages/services/CloudLoadBalancerConfig'),
);
const LoadBalancerList = asyncComponent(() =>
	import('containers/pages/services/LoadBalancersContainer'),
);
const CloudBlockStorageDetails = asyncComponent(() =>
	import(
		'containers/pages/services/CloudBlockStorage/CloudBlockStorageDetails'
	),
);
const CloudPrivateNetworks = asyncComponent(() =>
	import('containers/pages/services/CloudPrivateNetworks'),
);

const AcronisRedirect = asyncComponent(() =>
	import('containers/pages/servers/AcronisRedirect'),
);

const ServicesRouter = (props) => {
	const { navData } = props;
	return (
		<Switch>
			<Route
				exact
				path={navData.get('path')}
				component={navData.get('component')}
			/>
			<Route
				exact
				path={`${navData.get('path')}/load-balancers/dedicated`}
				component={LoadBalancerList}
				whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
				parent={navData}
			/>
			<Route
				exact
				path={`${navData.get('path')}/load-balancers/cloud`}
				component={LoadBalancerList}
				whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
				parent={navData}
			/>
			<Route
				exact
				path={`${navData.get('path')}/load-balancers/dedicated/:uniqId`}
				component={DedicatedLoadBalancerConfig}
				whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
				parent={navData}
			/>
			<Route
				exact
				path={`${navData.get('path')}/load-balancers/cloud/:uniqId`}
				component={CloudLoadBalancerConfig}
				whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
				parent={navData}
			/>
			{/* Backwards compatibility for older routes that didn't include server type */}
			<Route
				exact
				path={`${navData.get('path')}/load-balancers/:uniqId`}
				component={({ match }) => (
					<Redirect
						to={`${navData.get('path')}/load-balancers/cloud/${
							match.params.uniqId
						}`}
					/>
				)}
				whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
				parent={navData}
			/>
			<Route
				exact
				path={`${navData.get('path')}/cloud-block-storage/:uniqId`}
				component={CloudBlockStorageDetails}
				whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
				parent={navData}
			/>
			<Route
				exact
				path={`${navData.get('path')}/cloud-private-networks/:tab`}
				component={CloudPrivateNetworks}
				whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
				parent={navData}
			/>
			<Route
				exact
				path={`${navData.get('path')}/managed-engineering`}
				component={() => (
					<Redirect to={`${navData.get('path')}/advanced-services`} />
				)}
				whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
				parent={navData}
			/>
			<RoleRestrictedRoute
				path={`${navData.get('path')}/acronis/:acronisId`}
				component={AcronisRedirect}
				whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
			/>
			<SubNavRoutes subNavs={navData.get('subNav')} />
		</Switch>
	);
};

ServicesRouter.propTypes = {
	navData: PropTypes.object.isRequired,
};

export default ServicesRouter;
