import React, { useState, useEffect, useMemo } from 'react';
import isFQDN from 'validator/es/lib/isFQDN';
import Grid from '@material-ui/core/Grid';
import LWTypography from 'components/common/LWTypography';
import ConfirmCancel from 'components/common/Dialogs/ConfirmCancel';
import useDebounce from 'utility/effects/useDebounce';
import Instructions from './Instructions';
import DomainInput from './inputs/DomainInput';
import ParentNameInput from './inputs/ParentNameInput';
import ProjectInput from './inputs/ProjectInput';
import SubDomainInput from './inputs/SubDomainInput';
import GeneratedHostnameDisplay from './GeneratedHostnameDisplay';

const DEBOUNCE_TIMER = 1000;

const getConfirmText = ({ variant }) => {
	switch (variant) {
		case 'projectOnly':
			return 'Save Project';
		case 'privateParent':
			return 'Save name & Project';
		default:
			return 'Save Hostname & Project';
	}
};

const HostnameAndProjectContent = ({
	assetList,
	basketDetails,
	domains,
	fetchAssetList,
	fetchAssertProperties,
	fetchProjectList,
	fetchServerAvailable,
	itemUuid,
	itemProperties,
	productName,
	projectList,
	onClose,
	serverAvailable,
	variant,
}) => {
	const [domain, setDomain] = useState('');
	const [parentName, setParentName] = useState(itemProperties?.domain || '');
	const [inputDomain, setInputDomain] = useState(
		itemProperties?.mylw?.hostname?.domain || '',
	);
	const [project, setProject] = useState('');
	const [inputProject, setInputProject] = useState(
		itemProperties?.project?.project_name || '',
	);
	const [inputSubdomain, setInputSubdomain] = useState(
		itemProperties?.mylw?.hostname?.subdomain || '',
	);

	const [generatedHostname, setGeneratedHostname] = useState('');
	// server/available API just returns the same domain submitted if it's a valid match
	const hostnameMatch = generatedHostname === serverAvailable.domain;
	const validHostname =
		!serverAvailable.hasError || serverAvailable.isLoading || hostnameMatch;

	const debouncedHostname = useDebounce(generatedHostname, DEBOUNCE_TIMER);

	const mergedDomain = useMemo(
		() => (inputDomain || domain)?.replace(/ /g, '-'),
		[inputDomain, domain],
	);
	const mergedProject = useMemo(() => inputProject || project, [
		inputProject,
		project,
	]);

	// initial load for domains and projects
	useEffect(() => {
		fetchAssetList();
		fetchProjectList();
	}, [fetchAssetList, fetchProjectList]);

	// determine the hostname based on input data
	useEffect(() => {
		if (mergedDomain) {
			let subdomain = 'host';
			if (inputSubdomain) {
				subdomain = encodeURI(inputSubdomain?.replace(/ /g, '-'));
			} else if (mergedProject) {
				// If this is a project being selected, need to append a unique value to the subdomain
				let hostNumber = 1;
				const projectSubdomain = encodeURI(mergedProject?.replace(/ /g, '-'));
				const allDomains = [...basketDetails.domains, ...domains];
				while (
					allDomains.includes(
						`${projectSubdomain}-${hostNumber}.${mergedDomain}`,
					)
				) {
					hostNumber += 1;
				}
				subdomain = `${projectSubdomain}-${hostNumber}`;
			}
			setGeneratedHostname(`${subdomain}.${mergedDomain}`);
		} else {
			setGeneratedHostname('');
		}
	}, [
		mergedDomain,
		mergedProject,
		inputSubdomain,
		domains,
		basketDetails.domains,
	]);

	// determine if hostname is valid
	useEffect(() => {
		if (debouncedHostname && isFQDN(debouncedHostname)) {
			fetchServerAvailable({ domain: debouncedHostname });
		}
	}, [debouncedHostname, fetchServerAvailable]);

	const projectOnly = variant === 'projectOnly';
	const privateParent = variant === 'privateParent';
	const hostname = variant === 'hostname';

	const getProperties = () => {
		const properties = {
			project: mergedProject ? { project_name: mergedProject } : '',
		};
		if (privateParent || hostname) {
			properties.domain = privateParent ? parentName : generatedHostname;
		}
		if (hostname) {
			properties.mylw = {
				// Need to store these values seperately so this form can pull out the different parts again
				hostname: {
					domain: mergedDomain || '',
					subdomain: inputSubdomain || '',
				},
			};
		}
		return properties;
	};

	const existingProjects =
		projectList.items?.map((item) => item.project_name) || [];

	const projects = Array.from(
		new Set([...basketDetails.projects, ...existingProjects]),
	);

	const showLabels = variant === 'hostname';
	const showInstructions = variant !== 'privateParent';
	const showDomainInputs = !privateParent && !projectOnly; // for both domain and subdomains.

	return (
		<Grid container spacing={3} direction="column">
			{showInstructions && (
				<Grid item>
					<Instructions projectOnly={projectOnly} productName={productName} />
				</Grid>
			)}
			{showDomainInputs && (
				<DomainInput
					{...{
						showLabels,
						domain,
						setDomain,
						setInputDomain,
						inputDomain,
						domains,
						isLoading: assetList.isLoading,
					}}
				/>
			)}
			{privateParent && <ParentNameInput {...{ parentName, setParentName }} />}
			{/* Always allow product to have a project added. */}
			<ProjectInput
				{...{
					showLabels,
					project,
					setProject,
					inputProject,
					setInputProject,
					projects,
					isLoading: projectList.isLoading,
				}}
			/>
			{showDomainInputs && (
				<SubDomainInput
					{...{ showLabels, inputSubdomain, setInputSubdomain }}
				/>
			)}
			{!!generatedHostname && (
				<Grid item>
					<LWTypography
						color="textSecondary"
						variant="body2"
					>{`${productName} hostname:`}</LWTypography>
					<GeneratedHostnameDisplay hostname={generatedHostname} />
					{!validHostname && (
						<LWTypography color="error">
							This hostname already exists.
						</LWTypography>
					)}
				</Grid>
			)}
			<Grid item>
				<ConfirmCancel
					cancel={onClose}
					confirmText={getConfirmText({ variant })}
					confirm={() =>
						fetchAssertProperties({
							properties: getProperties(),
							uuid: itemUuid,
						})
					}
				/>
			</Grid>
		</Grid>
	);
};

export default HostnameAndProjectContent;
