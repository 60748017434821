// TODO remove immutable from this file

import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';
import { List as ImmutableList } from 'immutable';
import { selectors as volumeDetailsSelectors } from 'modules/api/storage/block/volume/detailsModule';

const getStateSlice = (state) => state.notifications.current;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'NOTIFICATIONS_CURRENT',
	isPaginated: true,
	url: '/notifications/current.json',
});

const notificationOrder = {
	Critical: 1,
	Error: 2,
	Warning: 3,
	Notification: 4,
};
const notificationSort = (notificationA, notificationB) => {
	const notificationPriorityA =
		notificationOrder[notificationA.get('severity')] || 99;
	const notificationPriorityB =
		notificationOrder[notificationB.get('severity')] || 99;
	return notificationPriorityA - notificationPriorityB;
};

// TODO make a key-map selector of the uniq_id's pointing at complete and sorted arrays of the notifications for each asset
const getNewestPerAsset = createSelector(
	getStateSlice,
	(slice) => {
		const items = slice.getIn(['data', 'items'], ImmutableList());
		const sorted = items.sort(
			(a, b) => new Date(b.get('startdate')) - new Date(a.get('startdate')),
		);
		// Find the newest item with an given uniq_id
		const uniques = sorted.filter(
			(x, index) =>
				index ===
				sorted.findIndex((a) => a.get('uniq_id') === x.get('uniq_id')),
		);
		return uniques;
	},
);

const getNewest = (newestPerAsset, uniqId) =>
	newestPerAsset?.find((x) => x.get('uniq_id') === uniqId)?.toJS();

const generateGetNewest = (uniqId) => {
	return createSelector(
		getNewestPerAsset,
		(newestPerAsset) => getNewest(newestPerAsset, uniqId),
	);
};

const getBackupStatus = (newest) => {
	if (newest?.severity === 'Error') {
		return {
			level: 'high',
			message: 'Restore/Reimage available once errors are resolved',
		};
	}
	if (newest?.severity === 'Notification') {
		return {
			level: 'pending',
			message: 'Restore/Reimage available once tasks are complete',
		};
	}
	return {
		level: 'normal',
		message: 'Server tasks complete - Restore/Reimage available',
	};
};

const generateGetBackupStatus = (uniqId) => {
	return createSelector(
		generateGetNewest(uniqId),
		getBackupStatus,
	);
};

const selectors = {
	filteredAndSorted: createSelector(
		getStateSlice,
		(slice) => {
			const items = slice.getIn(['data', 'items'], ImmutableList());
			let filteredAndSorted = items.sort(notificationSort);
			filteredAndSorted = filteredAndSorted.filter(
				(x) => (x.get('uniq_id') && x.get('domain')) || !x.get('uniq_id'),
			);
			return filteredAndSorted;
		},
	),
	// filters out only the newest notification for each uniq id, so there is only 1 per uniq_id
	newestPerAsset: getNewestPerAsset,
	generateGetNewest,
	volume: createSelector(
		defaultSelectors.getNativeData,
		volumeDetailsSelectors.getNativeData,
		(notifications, volume) => {
			if (!notifications || !notifications.items || !volume) return null;
			return notifications.items.filter(
				(notification) => notification.uniq_id === volume.uniq_id,
			);
		},
	),
	generateGetBackupStatus,
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };
