import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.basketApi.item.clone;

const { actions, reducer, sagas, selectors } = createAPIModule({
	getStateSlice,
	actionType: 'BASKET_ITEM_CLONE',
	method: 'POST',
	url: '/basket/item/clone.json',
});

export { actions, reducer, sagas, selectors };
