import { call, put, select, take } from 'redux-saga/effects';

import { push } from 'connected-react-router';

import { actions as assertMetadataActions } from 'modules/api/basket/assertMetadataModule';
import { actions as cloneActions } from 'modules/api/basket/cloneModule';

import {
	moduleKeys,
	selectors as detailsSelectors,
} from 'modules/api/basket/detailsModule';
import snackbarSaga from 'modules/snackbar/sagas';

import detailsSaga from '../details';
import storeUuid from '../storeUuid';

function* clone({ oppId }) {
	// fetch details of the existing basket using the uuid from the url
	yield call(detailsSaga, { moduleKey: moduleKeys.toClone });

	// eslint-disable-next-line camelcase
	const { share_code } =
		(yield select(detailsSelectors.toCloneSelectors.getNativeData)) || {};
	// clone basket by passing the share_code of the existing basket
	yield put(cloneActions.fetch({ share_code }));
	const action = yield take([cloneActions.setType, cloneActions.errorType]);

	const error = action?.type === cloneActions.errorType;
	yield call(snackbarSaga, { error, errorMessage: 'Failed to clone basket' });
	if (error) return;

	// store the new UUID, add the opp_id to the new basket, and fetch the details for the new basket
	yield call(storeUuid, { uuid: action?.payload.uuid });
	yield put(
		assertMetadataActions.fetch({
			metadata: { opp_id: oppId },
			uuid: action?.payload.uuid,
		}),
	);
	// triggers asset/details fetch
	yield take([assertMetadataActions.setType, assertMetadataActions.errorType]);
	yield put(push('/shop/marketplace'));
}

export default clone;
