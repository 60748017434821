const findOption = (optionKey, productOptions) => {
	if (!productOptions) return null;
	return productOptions.find((option) => {
		if (option.key === optionKey) {
			return option;
		}
		if (option.options) {
			const subOption = findOption(optionKey, option.options);
			if (subOption) {
				return subOption;
			}
		}
		return null;
	});
};

const findOptionDeep = (optionKey, productOptions) => {
	let subOption;
	const { length } = productOptions;
	// There probably has to be a better way to do this, but I couldn't figure out a way to have it return just the option
	// in a different type of loop
	for (let i = 0; i < length; i += 1) {
		if (productOptions[i].key === optionKey) {
			return productOptions[i];
		}
		if (productOptions[i].options) {
			subOption = findOptionDeep(optionKey, productOptions[i].options);
		}
		// eslint-disable-next-line no-loop-func
		productOptions[i].values.forEach((value) => {
			if (value.options && !subOption) {
				subOption = findOptionDeep(optionKey, value.options);
			}
		});
		if (subOption) {
			return subOption;
		}
	}
	return null;
};

const optionValues = (optionKey, productOptions) => {
	const option = findOption(optionKey, productOptions);
	return option ? option.values : null;
};

const optionValuesDeep = (optionKey, productOptions) => {
	const option = findOptionDeep(optionKey, productOptions);
	return option ? option.values : null;
};

const findOptionValue = (optionValue, values) => {
	if (!values) return null;
	return values.find((value) => {
		if (value.value === optionValue) {
			return value;
		}
		if (value.options) {
			const { options } = value;
			options.find((option) => {
				const subValue = findOptionValue(optionValue, option.values);
				if (subValue) {
					return subValue;
				}
				return null;
			});
		}
		return null;
	});
};

const getPrice = (value, region = 1) => {
	if (!value || !value.prices) return null;
	return value.prices[region];
};

// Combines functions to grab a simple price in situations where you just need to translate
// a price from a pair of product keys and values.
const getOptionPrice = ({ key, value, region, productOptions }) => {
	const values = optionValues(key, productOptions);
	const optionValue = findOptionValue(value, values);
	return getPrice(optionValue, region);
};

export {
	findOption,
	findOptionDeep,
	findOptionValue,
	getOptionPrice,
	getPrice,
	optionValues,
	optionValuesDeep,
};
