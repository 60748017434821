const actions = {
	START_CHAT: 'chat/START',
	startChat: () => ({
		type: actions.START_CHAT,
	}),

	SET_IS_STARTING: 'chat/SET_IS_STARTING',
	setIsStarting: (payload) => ({
		type: actions.SET_IS_STARTING,
		payload,
	}),
};

export default actions;
