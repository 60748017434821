import { Map as ImmutableMap } from 'immutable';

const initialState = ImmutableMap({
	url: null,
	isOpen: false,
	featureAvailableInManage: true,
});

const manageRedirect = (state = initialState, action) => {
	switch (action.type) {
		case 'SET_MANAGE_REDIRECT_IS_OPEN':
			return state
				.set('isOpen', action.isOpen)
				.set('url', action.url)
				.set('featureAvailableInManage', action.featureAvailableInManage);
		case 'SET_MANAGE_REDIRECT_URL':
			return state.set('url', action.url);
		case 'SET_MANAGE_REDIRECT_IS_FEATURE_AVAILABLE_IN_MANAGE':
			return state.set(
				'featureAvailableInManage',
				action.featureAvailableInManage,
			);
		default:
			return state;
	}
};

export default manageRedirect;
