import { call, put, select, takeLatest, take } from 'redux-saga/effects';
import { push, replace } from 'connected-react-router';
import errorActions from 'modules/error/errorActions';

import { actions as signupActions } from 'modules/api/account/signupModule';
import { actions as validateAddressActions } from 'modules/api/contact/validateAddressModule';
import { selectors as appConfigSelectors } from 'modules/api/appConfigModule';
import {
	selectors as billingAddressSelectors,
	actions as billingAddressActions,
} from 'modules/api/account/billingAddress/detailsModule';
import {
	selectors as paymentDetailsSelectors,
	actions as paymentDetailsActions,
} from 'modules/api/billing/payment/profile/detailsModule';
import basketActions from 'modules/basket/actions';
import createAccountActions from './actions';

function* handleSignupSuccess() {
	yield put(validateAddressActions.clear());
	const userManager = yield select(appConfigSelectors.userManager);
	yield call([userManager, userManager.signinSilent]);
	yield put(basketActions.initialize());
	yield put(push('/shop/payment-method'));
}

function* handleSignupError() {
	yield put(validateAddressActions.clear());
	yield put(errorActions.pushError({ verb: 'create account' }));
	yield put(replace('/shop/marketplace'));
}

function* initBillingAndAddress() {
	if (!(yield select(paymentDetailsSelectors.hasData))) {
		yield put(paymentDetailsActions.fetch());
		yield take([
			paymentDetailsActions.setType,
			paymentDetailsActions.errorType,
		]);
	}
	if (!(yield select(billingAddressSelectors.hasData))) {
		yield put(billingAddressActions.fetch());
		yield take([
			billingAddressActions.setType,
			billingAddressActions.errorType,
		]);
	}
}

// used for if the user wants to shop before finishing creating their account and then tries to check out.
function* takeToNextStep() {
	yield call(initBillingAndAddress);
	const hasPaymentMethod = !!(yield select(
		paymentDetailsSelectors.paymentMethod,
	));
	const paymentMethodIsPaypal = yield select(
		paymentDetailsSelectors.getPaymentMethodIsPaypal,
	);
	const hasBillingAddress = !!(yield select(
		billingAddressSelectors.billingAddress,
	))?.address;
	if (hasPaymentMethod && (hasBillingAddress || paymentMethodIsPaypal))
		yield put(push('/shop/checkout'));
	else yield put(push('/shop/payment-method'));
}

export default function* saga() {
	yield takeLatest(signupActions.setType, handleSignupSuccess);
	yield takeLatest(signupActions.errorType, handleSignupError);
	yield takeLatest(
		createAccountActions.ACCOUNT_CREATE_TAKE_TO_NEXT_STEP,
		takeToNextStep,
	);
}
