import { combineReducers } from 'redux';

import domain from './domain/domainReducer';
import record from './record/recordReducer';
import zone from './zone/zoneReducer';

export default combineReducers({
	domain,
	record,
	zone,
});
