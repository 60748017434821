import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import RoleRestrictedRoute from 'utility/routing/RoleRestrictedRoute';
import asyncComponent from 'components/AsyncComponent';

const NotFound = asyncComponent(() => import('containers/structural/NotFound'));

const SubNavRoutes = (props) => {
	const { subNavs, whitelistedRoles } = props;
	return (
		<Switch>
			{subNavs.valueSeq().map((subNavData) => (
				<RoleRestrictedRoute
					key={subNavData.get('path')}
					path={subNavData.get('path')}
					component={subNavData.get('component')}
					whitelistedRoles={whitelistedRoles}
					exact
				/>
			))}
			<Route component={NotFound} />
		</Switch>
	);
};

SubNavRoutes.propTypes = {
	subNavs: PropTypes.object.isRequired,
	whitelistedRoles: PropTypes.array,
};

export default SubNavRoutes;
