import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';

const getStateSlice = (state) => state.billing.balance;

const {
	actions,
	reducer,
	sagas,
	selectors: balanceSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'BILLING_BALANCE',
	url: '/billing/balance.json',
});

const isPaymentDue = createSelector(
	balanceSelectors.getData,
	(balanceData) => !!(balanceData && balanceData.get('due') > 0),
);

const pastDue = createSelector(
	balanceSelectors.getData,
	(balanceData) => {
		if (!balanceData) return 0;
		const pastdue = balanceData.get('overdue') - balanceData.get('balance');
		return pastdue < 0 ? 0 : pastdue.toFixed(2);
	},
);

const overDue = createSelector(
	balanceSelectors.getData,
	(balanceData) => {
		if (!balanceData) return 0;
		return balanceData.get('overdue');
	},
);

const isOverDue = createSelector(
	balanceSelectors.getData,
	(balanceData) => !!(balanceData && balanceData.get('overdue') > 0),
);

const displayBalance = createSelector(
	balanceSelectors.getData,
	(balanceData) => {
		const zero = 0;
		if (!balanceData) return zero.toString();
		const due = balanceData.get('due') - balanceData.get('balance');
		return due < 0 ? zero.toString() : due.toFixed(2);
	},
);

const pendingCharges = createSelector(
	balanceSelectors.getData,
	(balanceData) => {
		if (!balanceData) return 0;
		const pending = balanceData.get('due') - balanceData.get('overdue');
		return pending < 0 ? 0 : pending.toFixed(2);
	},
);

const unusedPayments = createSelector(
	balanceSelectors.getData,
	(balanceData) => {
		if (!balanceData) return 0;
		const pending = balanceData.get('due') - balanceData.get('overdue');
		const pendingChargesAmount = pending < 0 ? 0 : pending;
		return pendingChargesAmount === 0
			? balanceData.get('balance')
			: balanceData.get('balance') * -1;
	},
);

const accountCredits = createSelector(
	balanceSelectors.getData,
	(balanceData) => {
		if (!balanceData) return 0;
		return balanceData.get('credit');
	},
);

const hasAccountCredits = createSelector(
	balanceSelectors.getData,
	(balanceData) => !!(balanceData && balanceData.get('credit') > 0),
);

const nextBillDate = createSelector(
	balanceSelectors.getData,
	(balanceData) => {
		if (!balanceData) return null;
		return balanceData.get('next_bill_date');
	},
);

const lastPaymentDate = createSelector(
	balanceSelectors.getData,
	(balanceData) => {
		if (!balanceData) return null;
		return balanceData.get('last_payment_date');
	},
);

const cycle = createSelector(
	balanceSelectors.getData,
	(balanceData) => {
		if (!balanceData) return null;
		return balanceData.get('cycle');
	},
);

const paymentMethod = createSelector(
	getStateSlice,
	(slice) => slice.getIn(['data', 'payment_method'], null),
);

const selectors = {
	isPaymentDue,
	pastDue,
	overDue,
	isOverDue,
	displayBalance,
	pendingCharges,
	unusedPayments,
	accountCredits,
	hasAccountCredits,
	nextBillDate,
	lastPaymentDate,
	cycle,
	paymentMethod,
	...balanceSelectors,
};

export { actions, reducer, sagas, selectors };
