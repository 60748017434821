import { all, call } from 'redux-saga/effects';
import { sagas as attach } from './attachModule';
import { sagas as create } from './createModule';
import { sagas as deleteSagas } from './deleteModule';
import { sagas as detach } from './detachModule';
import { sagas as details } from './detailsModule';
import { sagas as list } from './listModule';
import { sagas as resize } from './resizeModule';
import { sagas as update } from './updateModule';

export default function* clusterSaga() {
	yield all([
		call(attach),
		call(create),
		call(deleteSagas),
		call(detach),
		call(details),
		call(list),
		call(resize),
		call(update),
	]);
}
