import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';

const getStateSlice = (state) => state.basketApi.item.add;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'BASKET_ITEM_ADD',
	method: 'POST',
	url: '/basket/item/add.json',
});

const uniqIdToClone = createSelector(
	defaultSelectors.getNativeData,
	// TODO: NEWMAN-1782, get real property from BE.
	(data) => data?.properties?.uniq_id_to_clone,
);

const lwBackupOption = createSelector(
	defaultSelectors.getNativeData,
	(data) =>
		data?.configs?.find((option) => option.key === 'LiquidWebBackupPlan'),
);

const selectors = {
	lwBackupOption,
	uniqIdToClone,
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };
