import { connect } from 'react-redux';
import dialogActions from 'modules/dialogs/actions';
import basketActions from 'modules/basket/actions';
import { actions as itemModifyActions } from 'modules/api/basket/item/modifyModule';
import { selectors as itemRemoveSelectors } from 'modules/api/basket/item/removeModule';
import Content from './Content';

const mapStateToProps = (state) => ({
	itemRemoveError: itemRemoveSelectors.getErrorString(state),
});

const mapDispatchToProps = (dispatch) => ({
	openDialog: ({ title, content, dialogProps, color }) =>
		dispatch(dialogActions.open({ title, content, dialogProps, color })),
	fetchItemModify: ({ properties, uuid }) =>
		dispatch(itemModifyActions.fetch({ properties, uuid })),
	fetchItemRemove: ({ uuid, productCode }) =>
		dispatch(basketActions.removeItem({ uuid, productCode })),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Content);
