import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Content from './Content';
import Price from './Price';

const Header = ({
	title = '',
	hostnameOrSubtitle,
	projectName = '',
	itemUuid,
	itemDetails,
	itemPriceTotal = 0,
	itemDiscountTotal = 0,
	isLoading = false,
	perMonthLabel,
	propsForContent,
}) => (
	<Box display="flex" justifyContent="space-between">
		<Content
			title={title}
			hostnameOrSubtitle={hostnameOrSubtitle}
			projectName={projectName}
			itemUuid={itemUuid}
			isLoading={isLoading}
			itemDetails={itemDetails}
			{...propsForContent}
		/>
		<Price
			itemPriceTotal={itemPriceTotal}
			itemDiscountTotal={itemDiscountTotal}
			isLoading={isLoading}
			perMonthLabel={perMonthLabel}
		/>
	</Box>
);

Header.propTypes = {
	title: PropTypes.string,
	hostnameOrSubtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	projectName: PropTypes.string,
	itemUuid: PropTypes.string,
	itemPriceTotal: PropTypes.number,
	itemDiscountTotal: PropTypes.number,
	isLoading: PropTypes.bool,
	propsForContent: PropTypes.shape({
		hideDomain: PropTypes.bool,
		setRemoved: PropTypes.func,
		removed: PropTypes.bool,
		secondaryInfo: PropTypes.arrayOf(
			PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.shape({
					value: PropTypes.string,
					subitems: PropTypes.arrayOf(PropTypes.string),
				}),
			]),
		),
		readOnly: PropTypes.bool,
	}).isRequired,
	perMonthLabel: PropTypes.bool,
};

export default Header;
