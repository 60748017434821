import produce from 'immer';
import actions from './actions';

const initialState = {
	// set of read notification ids
	data: null,
	pending: 0,
	error: null,
};

/* eslint-disable default-case, no-param-reassign */
const reducer = (state = initialState, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case actions.NOTIFICATIONS_READ_SET:
				draft.data = action.payload;
				break;
		}
	});

export default reducer;
