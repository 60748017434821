import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.server.availableFlavors;

const { actions, reducer, sagas, selectors } = createAPIModule({
	getStateSlice,
	method: 'POST',
	actionType: 'SERVER_AVAILABLEFLAVORS',
	url: '/server/availableFlavors.json',
});

export { actions, reducer, sagas, selectors };
