import { combineReducers } from 'redux';
import { reducer as allReducer } from './allModule';
import { reducer as currentReducer } from './currentModule';
import { reducer as detailsReducer } from './detailsModule';
import { reducer as resolveReducer } from './resolveModule';

export default combineReducers({
	all: allReducer,
	current: currentReducer,
	details: detailsReducer,
	resolve: resolveReducer,
});
