import { all, delay, put, select, take, takeEvery } from 'redux-saga/effects';

import { actions as pingActions } from 'modules/api/utilities/info/pingModule';

import maintenanceModeActions from './actions';
import { enabled as maintenanceModeEnabledSelector } from './selectors';

const POLL_SECONDS = 5;

function* pingApi() {
	yield put(pingActions.fetch());
}

function* listenForApiSuccess() {
	yield take(pingActions.setType);
	yield put(maintenanceModeActions.off());
}

function* pollApi() {
	while (yield select(maintenanceModeEnabledSelector)) {
		yield pingApi();
		yield delay(POLL_SECONDS * 1000);
	}
}

function* handleMaintenanceModeEnabled() {
	yield all([listenForApiSuccess(), pollApi()]);
}

function* listenForMaintenanceMode() {
	yield takeEvery([maintenanceModeActions.ON], handleMaintenanceModeEnabled);
}

export default function* saga() {
	yield all([listenForMaintenanceMode()]);
}
