const standard = {
	'SS.VPS': {
		alias: 'storm-vps',
		ancestorCode: 'SS.VPS',
		capabilities: {
			ATSLogin: 1,
			AutoActivate: 1,
			autoAddIp: 1,
			autoPrivateNetworking: 1,
			autoReboot: 1,
			autoRemoveIp: 1,
			autoRename: 1,
			autoShutdown: 1,
			bandwidthStats: 1,
			canAcronisBackup: 1,
			canHotplugRamVcpu: 1,
			canThreatStack: 1,
			canUseSpiceConsole: 1,
			changeBWOptions: 1,
			clone: 1,
			create: 1,
			credentials: 1,
			destroy: 1,
			emailable: 1,
			extraAuth: 1,
			freeStormBackups: 1,
			guardian: 1,
			image: 1,
			manAddIp: 1,
			manReboot: 1,
			manRemoveIp: 1,
			managed: 1,
			migratableDestination: 1,
			migratableSource: 1,
			multiplePublicIPs: 1,
			networking: 1,
			presentable: 1,
			provBackup: 1,
			provFirewall: 1,
			reimage: 1,
			rescueBoot: 1,
			resize: 1,
			restoreFromTemplate: 1,
			reverseDNSEdit: 1,
			rootLogin: 1,
			serviceMonitoring: 1,
			serviceMonitoringBasic: 1,
			serviceMonitoringDns: 1,
			serviceMonitoringLinux: 1,
			serviceMonitoringMachineMysqld: 1,
			serviceMonitoringSystem: 1,
			sonar: 1,
			volumeAttach: 1,
			volumeDetach: 1,
			webConsole: 1,
		},
		categories: [
			'AuthDedicated',
			'CloudVPS',
			'Provisioned',
			'VirtualDedicated',
		],
		code: 'SS.VPS',
		credit_eligible: 1,
		cycle: 'month',
		default_price: '0.00000',
		description: 'LiquidWeb Storm VPS',
		discountable: 1,
		features: [],
		option_value_features: {
			'1713663': [
				'4 Cores @ 2.1 GHz Turbo',
				'6 GB RAM (DDR3)',
				'1 TB 7200 RPM SATA RAID 1 (Primary)',
				'1 TB 7200 RPM SATA (Backup)',
				'120 GB Kingston SSD Bonus Drive',
				'5 TB Outbound & FREE Incoming Bandwidth',
			],
			'1715983': [
				'4 Cores @ 2.8 GHz',
				'2 GB RAM (DDR3)',
				'1 TB 7200 RPM SATA RAID 1 (Primary)',
				'1 TB 7200 RPM SATA (Backup)',
				'120 GB Kingston SSD Bonus Drive',
				'5 TB Outbound & FREE Incoming Bandwidth',
			],
			'1715984': [
				'8 Cores @ 2.0 GHz',
				'16 GB RAM (DDR3)',
				'1 TB 7200 RPM SATA RAID 1 (Primary)',
				'1 TB 7200 RPM SATA (Backup)',
				'120 GB Kingston SSD Bonus Drive',
				'5 TB Outbound & FREE Incoming Bandwidth',
			],
		},
		options: [
			{
				automated: 0,
				display_order: 0,
				display_text: 'cPanel License Tier',
				key: 'cPanelLicenseTier',
				option_key_id: '2605518',
				public: 1,
				required: 0,
				values: [
					{
						capabilities: {},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						description: 'Admin',
						discountable: 1,
						display_order: 15,
						featured: 0,
						links: [],
						prices: {
							'1': {
								hour: '0.00000',
								month: '0.00',
							},
							'2': {
								hour: '0.00000',
								month: '0.00',
							},
							'3': {
								hour: '0.00000',
								month: '0.00',
							},
							'493': {
								hour: '0.00000',
								month: '0.00',
							},
						},
						sub_title: '',
						title: '',
						value: 'admin_cloud_5',
					},
					{
						capabilities: {},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						description: 'Pro',
						discountable: 1,
						display_order: 20,
						featured: 0,
						links: [],
						prices: {
							'1': {
								hour: '0.00685',
								month: '5.00',
							},
							'2': {
								hour: '0.00685',
								month: '5.00',
							},
							'3': {
								hour: '0.00685',
								month: '5.00',
							},
							'493': {
								hour: '0.00685',
								month: '5.00',
							},
						},
						sub_title: '',
						title: '',
						value: 'pro_cloud_30',
					},
					{
						capabilities: {},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						description: 'Plus',
						discountable: 1,
						display_order: 25,
						featured: 0,
						links: [],
						prices: {
							'1': {
								hour: '0.01371',
								month: '10.00',
							},
							'2': {
								hour: '0.01371',
								month: '10.00',
							},
							'3': {
								hour: '0.01371',
								month: '10.00',
							},
							'493': {
								hour: '0.01371',
								month: '10.00',
							},
						},
						sub_title: '',
						title: '',
						value: 'plus_cloud_50',
					},
					{
						capabilities: {},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						description: 'Premier Fixed 100',
						discountable: 1,
						display_order: 35,
						featured: 0,
						links: [],
						prices: {
							'1': {
								hour: '0.02741',
								month: '20.00',
							},
							'2': {
								hour: '0.02741',
								month: '20.00',
							},
							'3': {
								hour: '0.02741',
								month: '20.00',
							},
							'493': {
								hour: '0.02741',
								month: '20.00',
							},
						},
						sub_title: '',
						title: '',
						value: 'premier_cloud_100',
					},
					{
						capabilities: {},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						description: 'Premier Fixed 150',
						discountable: 1,
						display_order: 40,
						featured: 0,
						links: [],
						prices: {
							'1': {
								hour: '0.03427',
								month: '25.00',
							},
							'2': {
								hour: '0.03427',
								month: '25.00',
							},
							'3': {
								hour: '0.03427',
								month: '25.00',
							},
							'493': {
								hour: '0.03427',
								month: '25.00',
							},
						},
						sub_title: '',
						title: '',
						value: 'premier_cloud_150',
					},
					{
						capabilities: {},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						description: 'Premier Fixed 200',
						discountable: 1,
						display_order: 45,
						featured: 0,
						links: [],
						prices: {
							'1': {
								hour: '0.04112',
								month: '30.00',
							},
							'2': {
								hour: '0.04112',
								month: '30.00',
							},
							'3': {
								hour: '0.04112',
								month: '30.00',
							},
							'493': {
								hour: '0.04112',
								month: '30.00',
							},
						},
						sub_title: '',
						title: '',
						value: 'premier_cloud_200',
					},
					{
						capabilities: {},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						description: 'Premier Fixed 250',
						discountable: 1,
						display_order: 50,
						featured: 0,
						links: [],
						prices: {
							'1': {
								hour: '0.04797',
								month: '35.00',
							},
							'2': {
								hour: '0.04797',
								month: '35.00',
							},
							'3': {
								hour: '0.04797',
								month: '35.00',
							},
							'493': {
								hour: '0.04797',
								month: '35.00',
							},
						},
						sub_title: '',
						title: '',
						value: 'premier_cloud_250',
					},
					{
						capabilities: {},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						description: 'Premier Fixed 300',
						discountable: 1,
						display_order: 55,
						featured: 0,
						links: [],
						prices: {
							'1': {
								hour: '0.05482',
								month: '40.00',
							},
							'2': {
								hour: '0.05482',
								month: '40.00',
							},
							'3': {
								hour: '0.05482',
								month: '40.00',
							},
							'493': {
								hour: '0.05482',
								month: '40.00',
							},
						},
						sub_title: '',
						title: '',
						value: 'premier_cloud_300',
					},
					{
						capabilities: {},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						description: 'Premier Fixed 350',
						discountable: 1,
						display_order: 60,
						featured: 0,
						links: [],
						prices: {
							'1': {
								hour: '0.06168',
								month: '45.00',
							},
							'2': {
								hour: '0.06168',
								month: '45.00',
							},
							'3': {
								hour: '0.06168',
								month: '45.00',
							},
							'493': {
								hour: '0.06168',
								month: '45.00',
							},
						},
						sub_title: '',
						title: '',
						value: 'premier_cloud_350',
					},
					{
						capabilities: {},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						description: 'Premier Fixed 400',
						discountable: 1,
						display_order: 70,
						featured: 0,
						links: [],
						prices: {
							'1': {
								hour: '0.07538',
								month: '55.00',
							},
							'2': {
								hour: '0.07538',
								month: '55.00',
							},
							'3': {
								hour: '0.07538',
								month: '55.00',
							},
							'493': {
								hour: '0.07538',
								month: '55.00',
							},
						},
						sub_title: '',
						title: '',
						value: 'premier_cloud_400',
					},
					{
						capabilities: {},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						description: 'Premier Fixed 450',
						discountable: 1,
						display_order: 80,
						featured: 0,
						links: [],
						prices: {
							'1': {
								hour: '0.08909',
								month: '65.00',
							},
							'2': {
								hour: '0.08909',
								month: '65.00',
							},
							'3': {
								hour: '0.08909',
								month: '65.00',
							},
							'493': {
								hour: '0.08909',
								month: '65.00',
							},
						},
						sub_title: '',
						title: '',
						value: 'premier_cloud_450',
					},
					{
						capabilities: {},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						description: 'Premier Fixed 500',
						discountable: 1,
						display_order: 90,
						featured: 0,
						links: [],
						prices: {
							'1': {
								hour: '0.10280',
								month: '75.00',
							},
							'2': {
								hour: '0.10280',
								month: '75.00',
							},
							'3': {
								hour: '0.10280',
								month: '75.00',
							},
							'493': {
								hour: '0.10280',
								month: '75.00',
							},
						},
						sub_title: '',
						title: '',
						value: 'premier_cloud_500',
					},
					{
						capabilities: {},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						description: 'Premier Fixed 600',
						discountable: 1,
						display_order: 100,
						featured: 0,
						links: [],
						prices: {
							'1': {
								hour: '0.11650',
								month: '85.00',
							},
							'2': {
								hour: '0.11650',
								month: '85.00',
							},
							'3': {
								hour: '0.11650',
								month: '85.00',
							},
							'493': {
								hour: '0.11650',
								month: '85.00',
							},
						},
						sub_title: '',
						title: '',
						value: 'premier_cloud_600',
					},
					{
						capabilities: {},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						description: 'Premier Fixed 700',
						discountable: 1,
						display_order: 110,
						featured: 0,
						links: [],
						prices: {
							'1': {
								hour: '0.13021',
								month: '95.00',
							},
							'2': {
								hour: '0.13021',
								month: '95.00',
							},
							'3': {
								hour: '0.13021',
								month: '95.00',
							},
							'493': {
								hour: '0.13021',
								month: '95.00',
							},
						},
						sub_title: '',
						title: '',
						value: 'premier_cloud_700',
					},
					{
						capabilities: {},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						description: 'Premier Fixed 800',
						discountable: 1,
						display_order: 120,
						featured: 0,
						links: [],
						prices: {
							'1': {
								hour: '0.14391',
								month: '105.00',
							},
							'2': {
								hour: '0.14391',
								month: '105.00',
							},
							'3': {
								hour: '0.14391',
								month: '105.00',
							},
							'493': {
								hour: '0.14391',
								month: '105.00',
							},
						},
						sub_title: '',
						title: '',
						value: 'premier_cloud_800',
					},
					{
						capabilities: {},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						description: 'Premier Fixed 900',
						discountable: 1,
						display_order: 130,
						featured: 0,
						links: [],
						prices: {
							'1': {
								hour: '0.15762',
								month: '115.00',
							},
							'2': {
								hour: '0.15762',
								month: '115.00',
							},
							'3': {
								hour: '0.15762',
								month: '115.00',
							},
							'493': {
								hour: '0.15762',
								month: '115.00',
							},
						},
						sub_title: '',
						title: '',
						value: 'premier_cloud_900',
					},
					{
						capabilities: {},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						description: 'Premier Fixed 1000',
						discountable: 1,
						display_order: 140,
						featured: 0,
						links: [],
						prices: {
							'1': {
								hour: '0.17133',
								month: '125.00',
							},
							'2': {
								hour: '0.17133',
								month: '125.00',
							},
							'3': {
								hour: '0.17133',
								month: '125.00',
							},
							'493': {
								hour: '0.17133',
								month: '125.00',
							},
						},
						sub_title: '',
						title: '',
						value: 'premier_cloud_1000',
					},
					{
						capabilities: {},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						description: 'Premier Fixed 1500',
						discountable: 1,
						display_order: 200,
						featured: 0,
						links: [],
						prices: {
							'1': {
								hour: '0.25356',
								month: '185.00',
							},
							'2': {
								hour: '0.25356',
								month: '185.00',
							},
							'3': {
								hour: '0.25356',
								month: '185.00',
							},
							'493': {
								hour: '0.25356',
								month: '185.00',
							},
						},
						sub_title: '',
						title: '',
						value: 'premier_cloud_1500',
					},
				],
			},
			{
				automated: 0,
				display_order: 1,
				display_text: 'Server Type',
				key: 'ConfigId',
				option_key_id: '4280',
				public: 1,
				required: 1,
				values: [
					{
						available: 0,
						capabilities: {},
						category: 'ssd',
						comments: null,
						cpu_cores: 1,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						description: 'test create config: 0.4 GB RAM, 75 GB Disk',
						discountable: 1,
						disk: '75',
						display_order: 1,
						featured: 0,
						links: [],
						memory: 400,
						prices: {
							'1': {
								hour: '0.00000',
								month: '0.00',
							},
							'2': {
								hour: '0.00000',
								month: '0.00',
							},
							'3': {
								hour: '0.00000',
								month: '0.00',
							},
							'493': {
								hour: '0.00000',
								month: '0.00',
							},
						},
						sub_title: '',
						title: '',
						value: '167',
						zone_availability: {
							'40460': 0,
						},
					},
					{
						available: 1,
						capabilities: {},
						category: 'ssd',
						comments: null,
						cpu_cores: 2,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						description: '2GB: 1.7 GB RAM, 100 GB Disk',
						discountable: 1,
						disk: '100',
						display_order: 6,
						featured: 0,
						links: [],
						memory: 1700,
						prices: {
							'1': {
								hour: '0.00137',
								month: '1.00',
							},
							'2': {
								hour: '0.00137',
								month: '1.00',
							},
							'3': {
								hour: '0.00137',
								month: '1.00',
							},
							'493': {
								hour: '0.00137',
								month: '1.00',
							},
						},
						sub_title: 'TWO GIGA BYTE CONFIG',
						title: '2gb title',
						value: '1',
						zone_availability: {
							'40460': 6,
						},
					},
					{
						available: 1,
						capabilities: {},
						category: 'ssd',
						comments: null,
						cpu_cores: 1,
						cycle: 'month',
						default: 0,
						default_price: '30.00000',
						description: 'VPS 2000 Plus: 0.4 GB RAM, 25 GB Disk',
						discountable: 1,
						disk: '25',
						display_order: 7,
						featured: 0,
						links: [],
						memory: 440,
						prices: {
							'1': {
								hour: '0.00137',
								month: '1.00',
							},
							'2': {
								hour: '0.00137',
								month: '1.00',
							},
							'3': {
								hour: '0.00137',
								month: '1.00',
							},
							'493': {
								hour: '0.00137',
								month: '1.00',
							},
						},
						sub_title: '',
						title: '',
						value: '4',
						zone_availability: {
							'40460': 27,
						},
					},
					{
						available: 0,
						capabilities: {},
						category: 'storm',
						comments: null,
						cpu_cores: 4,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						description: 'Storm 7GIG: 6.8 GB RAM, 150 GB Disk',
						discountable: 1,
						disk: '150',
						display_order: 11,
						featured: 0,
						links: [],
						memory: 7000,
						prices: {
							'1': {
								hour: '0.00137',
								month: '1.00',
							},
							'2': {
								hour: '0.00137',
								month: '1.00',
							},
							'3': {
								hour: '0.00137',
								month: '1.00',
							},
							'493': {
								hour: '0.00137',
								month: '1.00',
							},
						},
						sub_title: '',
						title: '',
						value: '170',
						zone_availability: {
							'40460': 0,
						},
					},
					{
						available: 0,
						capabilities: {},
						category: 'storm',
						comments: null,
						cpu_cores: 1,
						cycle: 'month',
						default: 1,
						default_price: '40.00000',
						description: '1GB: 0.9 GB RAM, 60 GB Disk',
						discountable: 1,
						disk: '60',
						display_order: 14,
						featured: 0,
						links: [],
						memory: 880,
						prices: {
							'1': {
								hour: '0.00137',
								month: '1.00',
							},
							'2': {
								hour: '0.00137',
								month: '1.00',
							},
							'3': {
								hour: '0.00137',
								month: '1.00',
							},
							'493': {
								hour: '0.00137',
								month: '1.00',
							},
						},
						sub_title: '',
						title: '',
						value: '2',
						zone_availability: {
							'40460': 0,
						},
					},
					{
						available: 1,
						capabilities: {},
						category: 'ssd',
						comments: null,
						cpu_cores: 2,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						description: '1GB: 1.0 GB RAM, 30 GB Disk',
						discountable: 1,
						disk: '30',
						display_order: 19,
						featured: 0,
						links: [],
						memory: 1024,
						prices: {
							'1': {
								hour: '0.01645',
								month: '12.00',
							},
							'2': {
								hour: '0.01782',
								month: '13.00',
							},
							'3': {
								hour: '0.01371',
								month: '10.00',
							},
							'493': {
								hour: '0.01508',
								month: '11.00',
							},
						},
						sub_title: '',
						title: '',
						value: '164',
						zone_availability: {
							'40460': 12,
						},
					},
					{
						available: 0,
						capabilities: {},
						category: 'storm',
						comments: null,
						cpu_cores: 2,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						description: 'Storm 2GB (tp42): 1.8 GB RAM, 100 GB Disk',
						discountable: 1,
						disk: '100',
						display_order: 20,
						featured: 0,
						links: [],
						memory: 1800,
						prices: {
							'1': {
								hour: '0.02056',
								month: '15.00',
							},
							'2': {
								hour: '0.02056',
								month: '15.00',
							},
							'3': {
								hour: '0.02056',
								month: '15.00',
							},
							'493': {
								hour: '0.02056',
								month: '15.00',
							},
						},
						sub_title: '',
						title: '',
						value: '88',
						zone_availability: {
							'40460': 0,
						},
					},
				],
			},
			{
				automated: 1,
				display_order: 2,
				display_text: 'Server Type',
				key: 'Template',
				option_key_id: '4282',
				public: 1,
				required: 1,
				values: [
					{
						capabilities: {
							ATSLogin: 0,
							migratableDestination: 0,
							sbsPersistentDevNames: 1,
							serviceMonitoringSelfManaged: 1,
						},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						deprecated: 1,
						description: 'CentOS 6 64-bit Self-managed',
						discountable: 1,
						display_order: 0,
						featured: 0,
						links: [],
						os: 'LinuxCentOS',
						os_license: null,
						prices: {
							'1': {
								hour: '0.00137',
								month: '1.00',
							},
							'2': {
								hour: '0.00137',
								month: '1.00',
							},
							'3': {
								hour: '0.00137',
								month: '1.00',
							},
							'493': {
								hour: '0.00137',
								month: '1.00',
							},
						},
						sub_title: '',
						support_level: 'Self-Managed',
						title: '',
						value: 'CENTOS_62_UNMANAGED',
						zone_availability: {
							'40460': 1,
						},
					},
					{
						capabilities: {
							migratableDestination: 0,
							sbsPersistentDevNames: 1,
						},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						deprecated: 1,
						description: 'CentOS 6 64-bit Core-managed',
						discountable: 1,
						display_order: 0,
						featured: 0,
						links: [],
						os: 'LinuxCentOS',
						os_license: null,
						prices: {
							'1': {
								hour: '0.00274',
								month: '2.00',
							},
							'2': {
								hour: '0.00274',
								month: '2.00',
							},
							'3': {
								hour: '0.00274',
								month: '2.00',
							},
							'493': {
								hour: '0.00274',
								month: '2.00',
							},
						},
						sub_title: '',
						support_level: 'Core-Managed',
						title: '',
						value: 'CENTOS_62_COREMANAGED',
						zone_availability: {
							'40460': 1,
						},
					},
					{
						capabilities: {
							ATSLogin: 0,
							migratableDestination: 0,
							serviceMonitoringSelfManaged: 1,
						},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						deprecated: 1,
						description: 'Arch Linux 20110819',
						discountable: 1,
						display_order: 0,
						featured: 0,
						links: [],
						os: 'LinuxArch',
						os_license: null,
						prices: {
							'1': {
								hour: '0.00274',
								month: '2.00',
							},
							'2': {
								hour: '0.00274',
								month: '2.00',
							},
							'3': {
								hour: '0.00274',
								month: '2.00',
							},
							'493': {
								hour: '0.00274',
								month: '2.00',
							},
						},
						sub_title: '',
						support_level: 'Self-Managed',
						title: '',
						value: 'ARCHLINUX_20110819_UNMANAGED',
						zone_availability: {
							'40460': 1,
						},
					},
					{
						capabilities: {
							cpanelLogin: 1,
							serviceMonitoringCpanel: 1,
						},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						deprecated: 1,
						description: 'CentOS 6 64-bit cPanel',
						discountable: 1,
						display_order: 0,
						extra_software: 'Cpanel',
						featured: 0,
						links: [],
						os: 'LinuxCentOS',
						os_license: null,
						prices: {
							'1': {
								hour: '0.02056',
								month: '15.00',
							},
							'2': {
								hour: '0.02741',
								month: '20.00',
							},
							'3': {
								hour: '0.02741',
								month: '20.00',
							},
							'493': {
								hour: '0.02741',
								month: '20.00',
							},
						},
						sub_title: '',
						support_level: 'Fully-Managed',
						title: '',
						value: 'CENTOS_62_CPANEL',
						zone_availability: {
							'40460': 1,
						},
					},
					{
						capabilities: {
							ATSLogin: 0,
							migratableDestination: 0,
							serviceMonitoringSelfManaged: 1,
						},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						deprecated: 1,
						description: 'Ubuntu 14.04 LTS 64-bit Self-Managed',
						discountable: 1,
						display_order: 0,
						featured: 0,
						links: [],
						os: 'LinuxUbuntu',
						os_license: null,
						prices: {
							'1': {
								hour: '0.00137',
								month: '1.00',
							},
							'2': {
								hour: '0.00137',
								month: '1.00',
							},
							'3': {
								hour: '0.00137',
								month: '1.00',
							},
							'493': {
								hour: '0.00137',
								month: '1.00',
							},
						},
						sub_title: '',
						support_level: 'Self-Managed',
						title: '',
						value: 'UBUNTU_1404_UNMANAGED',
						zone_availability: {
							'1': 1,
							'40460': 1,
							'45412': 1,
							'86172': 1,
						},
					},
					{
						capabilities: {
							migratableDestination: 0,
						},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						deprecated: 1,
						description: 'Ubuntu 14.04 64-bit Core-managed',
						discountable: 1,
						display_order: 0,
						featured: 0,
						links: [],
						os: 'LinuxUbuntu',
						os_license: null,
						prices: {
							'1': {
								hour: '0.01371',
								month: '10.00',
							},
							'2': {
								hour: '0.01371',
								month: '10.00',
							},
							'3': {
								hour: '0.01371',
								month: '10.00',
							},
							'493': {
								hour: '0.01371',
								month: '10.00',
							},
						},
						sub_title: '',
						support_level: 'Core-Managed',
						title: '',
						value: 'UBUNTU_1404_COREMANAGED',
						zone_availability: {
							'40460': 1,
						},
					},
					{
						capabilities: {
							ATSLogin: 0,
							migratableDestination: 0,
							serviceMonitoringSelfManaged: 1,
						},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						deprecated: 0,
						description: 'CentOS 7 64-bit Self-managed',
						discountable: 1,
						display_order: 0,
						featured: 0,
						links: [],
						os: 'LinuxCentOS',
						os_license: null,
						prices: {
							'1': {
								hour: '0.00000',
								month: '0.00',
							},
							'2': {
								hour: '0.00000',
								month: '0.00',
							},
							'3': {
								hour: '0.00000',
								month: '0.00',
							},
							'493': {
								hour: '0.00000',
								month: '0.00',
							},
						},
						sub_title: '',
						support_level: 'Self-Managed',
						title: '',
						value: 'CENTOS_7_UNMANAGED',
						zone_availability: {
							'40460': 1,
							'45412': 1,
						},
					},
					{
						capabilities: {
							migratableDestination: 0,
						},
						comments: 'THIS IS A TEST',
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						deprecated: 0,
						description: 'CentOS 7.0 64-bit CoreManaged',
						discountable: 1,
						display_order: 0,
						featured: 0,
						links: [],
						os: 'LinuxCentOS',
						os_license: null,
						prices: {
							'1': {
								hour: '0.00000',
								month: '0.00',
							},
							'2': {
								hour: '0.00000',
								month: '0.00',
							},
							'3': {
								hour: '0.00000',
								month: '0.00',
							},
						},
						sub_title: '',
						support_level: 'Core-Managed',
						title: '',
						value: 'CENTOS_7_COREMANAGED',
						zone_availability: {
							'40460': 1,
						},
					},
					{
						capabilities: {
							ATSLogin: 0,
							migratableDestination: 0,
							serviceMonitoringSelfManaged: 1,
						},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						deprecated: 1,
						description: 'Ubuntu 14.10 64-bit Self-managed',
						discountable: 1,
						display_order: 0,
						featured: 0,
						links: [],
						os: 'LinuxUbuntu',
						os_license: null,
						prices: {
							'1': {
								hour: '0.00411',
								month: '3.00',
							},
							'2': {
								hour: '0.00548',
								month: '4.00',
							},
							'3': {
								hour: '0.00548',
								month: '4.00',
							},
							'493': {
								hour: '0.00822',
								month: '6.00',
							},
						},
						sub_title: '',
						support_level: 'Self-Managed',
						title: '',
						value: 'UBUNTU_1410_UNMANAGED',
						zone_availability: {
							'40460': 1,
						},
					},
					{
						capabilities: {
							ATSLogin: 0,
							migratableDestination: 0,
							serviceMonitoringSelfManaged: 1,
						},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						deprecated: 0,
						description: 'Debian 8 64-bit Self-managed',
						discountable: 1,
						display_order: 0,
						featured: 0,
						links: [],
						os: 'LinuxDebian',
						os_license: null,
						prices: {
							'1': {
								hour: '0.00137',
								month: '1.00',
							},
							'2': {
								hour: '0.00137',
								month: '1.00',
							},
							'3': {
								hour: '0.00137',
								month: '1.00',
							},
							'493': {
								hour: '0.00137',
								month: '1.00',
							},
						},
						sub_title: '',
						support_level: 'Self-Managed',
						title: '',
						value: 'DEBIAN_8_UNMANAGED',
						zone_availability: {
							'1': 1,
							'40460': 1,
							'45412': 1,
							'86172': 1,
						},
					},
					{
						capabilities: {
							ATSLogin: 0,
							migratableDestination: 0,
							serviceMonitoringSelfManaged: 1,
						},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						deprecated: 1,
						description: 'Ubuntu 15.04 64-bit Self-Managed',
						discountable: 1,
						display_order: 0,
						featured: 0,
						links: [],
						os: 'LinuxUbuntu',
						os_license: null,
						prices: {
							'1': {
								hour: '0.00137',
								month: '1.00',
							},
							'2': {
								hour: '0.00137',
								month: '1.00',
							},
							'3': {
								hour: '0.00137',
								month: '1.00',
							},
							'493': {
								hour: '0.00137',
								month: '1.00',
							},
						},
						sub_title: '',
						support_level: 'Self-Managed',
						title: '',
						value: 'UBUNTU_1504_UNMANAGED',
						zone_availability: {
							'1': 1,
							'40460': 1,
							'45412': 1,
							'86172': 1,
						},
					},
					{
						capabilities: {
							cpanelLogin: 1,
							serviceMonitoringCpanel: 1,
							whmLogin: 1,
						},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						deprecated: 0,
						description: 'CentOS 7 64-bit with cPanel Fully-managed',
						discountable: 1,
						display_order: 0,
						extra_software: 'Cpanel',
						featured: 0,
						links: [],
						os: 'LinuxCentOS',
						os_license: null,
						prices: {
							'1': {
								hour: '0.02056',
								month: '15.00',
							},
							'2': {
								hour: '0.00137',
								month: '1.00',
							},
							'3': {
								hour: '0.00137',
								month: '1.00',
							},
							'493': {
								hour: '0.00137',
								month: '1.00',
							},
						},
						sub_title: '',
						support_level: 'Fully-Managed',
						title: '',
						value: 'CENTOS_7_CPANEL',
						zone_availability: {
							'40460': 1,
							'45412': 1,
							'86172': 1,
						},
					},
					{
						capabilities: {
							cpanelLogin: 1,
							serviceMonitoringCpanel: 1,
						},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						deprecated: 1,
						description: 'CentOS 7 64-bit cPanel Wordpress Optimized',
						discountable: 1,
						display_order: 0,
						extra_software: 'cPanel',
						featured: 0,
						links: [],
						os: 'LinuxCentOS',
						os_license: null,
						prices: {
							'1': {
								hour: '0.00274',
								month: '2.00',
							},
							'2': {
								hour: '0.00274',
								month: '2.00',
							},
							'3': {
								hour: '0.00274',
								month: '2.00',
							},
							'493': {
								hour: '0.00274',
								month: '2.00',
							},
						},
						sub_title: '',
						support_level: 'Fully-Managed',
						title: '',
						value: 'CENTOS_7_CPANEL_WPOPT',
						zone_availability: {
							'40460': 1,
						},
					},
					{
						capabilities: {},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						deprecated: 1,
						description: 'Ubuntu 14.04 LTS 64-bit MWP',
						discountable: 1,
						display_order: 0,
						featured: 0,
						links: [],
						os: 'LinuxUbuntu',
						os_license: null,
						prices: {
							'1': {
								hour: '0.00274',
								month: '2.00',
							},
							'2': {
								hour: '0.00000',
								month: '0.00',
							},
							'3': {
								hour: '0.00000',
								month: '0.00',
							},
							'493': {
								hour: '0.00000',
								month: '0.00',
							},
						},
						sub_title: '',
						support_level: 'Core-Managed',
						title: '',
						value: 'UBUNTU_1404_MWP',
						zone_availability: {
							'1': 1,
							'40460': 1,
							'45412': 1,
							'86172': 1,
						},
					},
					{
						capabilities: {
							migratableDestination: 0,
						},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						deprecated: 0,
						description: 'Ubuntu 16.04 64-bit Core-managed',
						discountable: 1,
						display_order: 0,
						featured: 0,
						links: [],
						os: 'LinuxUbuntu',
						os_license: null,
						prices: {
							'1': {
								hour: '0.01371',
								month: '10.00',
							},
							'2': {
								hour: '0.01371',
								month: '10.00',
							},
							'3': {
								hour: '0.01371',
								month: '10.00',
							},
							'493': {
								hour: '0.01371',
								month: '10.00',
							},
						},
						sub_title: '',
						support_level: 'Core-Managed',
						title: '',
						value: 'UBUNTU_1604_COREMANAGED',
						zone_availability: {
							'40460': 1,
						},
					},
					{
						capabilities: {
							ATSLogin: 0,
							migratableDestination: 0,
							serviceMonitoringSelfManaged: 1,
						},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						deprecated: 0,
						description: 'Ubuntu 16.04 LTS 64-bit Self-Managed',
						discountable: 1,
						display_order: 0,
						featured: 0,
						links: [],
						os: 'LinuxUbuntu',
						os_license: null,
						prices: {
							'1': {
								hour: '0.00000',
								month: '0.00',
							},
							'2': {
								hour: '0.00000',
								month: '0.00',
							},
							'3': {
								hour: '0.00000',
								month: '0.00',
							},
							'493': {
								hour: '0.00000',
								month: '0.00',
							},
						},
						sub_title: '',
						support_level: 'Self-Managed',
						title: '',
						value: 'UBUNTU_1604_UNMANAGED',
						zone_availability: {
							'1': 1,
							'40460': 1,
							'45412': 1,
							'86172': 1,
						},
					},
					{
						capabilities: {
							pleskLogin: 1,
							serviceMonitoringPlesk: 1,
						},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						deprecated: 0,
						description: 'CentOS 7 64-bit Plesk',
						discountable: 1,
						display_order: 0,
						extra_software: 'Plesk',
						featured: 0,
						links: [],
						os: 'LinuxCentOS',
						os_license: null,
						prices: {
							'1': {
								hour: '0.00137',
								month: '1.00',
							},
							'2': {
								hour: '0.00137',
								month: '1.00',
							},
							'3': {
								hour: '0.00137',
								month: '1.00',
							},
							'493': {
								hour: '0.00137',
								month: '1.00',
							},
						},
						sub_title: '',
						support_level: 'Fully-Managed',
						title: '',
						value: 'CENTOS_7_PLESK',
						zone_availability: {
							'40460': 1,
							'45412': 1,
							'86172': 1,
						},
					},
					{
						capabilities: {
							ATSLogin: 0,
							migratableDestination: 0,
							serviceMonitoringSelfManaged: 1,
						},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						deprecated: 1,
						description: 'Ubuntu 15.10 64-bit Self-Managed',
						discountable: 1,
						display_order: 0,
						featured: 0,
						links: [],
						os: 'LinuxUbuntu',
						os_license: null,
						prices: {
							'1': {
								hour: '0.00137',
								month: '1.00',
							},
							'2': {
								hour: '0.00137',
								month: '1.00',
							},
							'3': {
								hour: '0.00137',
								month: '1.00',
							},
							'493': {
								hour: '0.00137',
								month: '1.00',
							},
						},
						sub_title: '',
						support_level: 'Self-Managed',
						title: '',
						value: 'UBUNTU_1510_UNMANAGED',
						zone_availability: {
							'40460': 1,
						},
					},
					{
						capabilities: {
							ATSLogin: 0,
							migratableDestination: 0,
							serviceMonitoringSelfManaged: 1,
						},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						deprecated: 1,
						description: 'CentOS 6 Self-managed',
						discountable: 1,
						display_order: 0,
						featured: 0,
						links: [],
						os: 'LinuxCentOS',
						os_license: null,
						prices: {
							'1': {
								hour: '0.00137',
								month: '1.00',
							},
							'2': {
								hour: '0.00137',
								month: '1.00',
							},
							'3': {
								hour: '0.00137',
								month: '1.00',
							},
							'493': {
								hour: '0.00137',
								month: '1.00',
							},
						},
						sub_title: '',
						support_level: 'Self-Managed',
						title: '',
						value: 'CENTOS_6_UNMANAGED',
						zone_availability: {
							'40460': 1,
							'45412': 1,
							'86172': 1,
						},
					},
					{
						capabilities: {
							cpanelLogin: 1,
							serviceMonitoringCpanel: 1,
						},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						deprecated: 1,
						description: 'CentOS 6 64-bit cPanel w/CloudLinux',
						discountable: 1,
						display_order: 0,
						extra_software: 'Cpanel',
						featured: 0,
						links: [],
						os: 'LinuxCloudLinux',
						os_license: null,
						prices: {
							'1': {
								hour: '0.02056',
								month: '15.00',
							},
							'2': {
								hour: '0.02741',
								month: '20.00',
							},
							'3': {
								hour: '0.02741',
								month: '20.00',
							},
							'493': {
								hour: '0.02741',
								month: '20.00',
							},
						},
						sub_title: '',
						support_level: 'Fully-Managed',
						title: '',
						value: 'CENTOS_6_CPANEL_CLOUDLINUX',
						zone_availability: {
							'40460': 1,
							'45412': 1,
							'86172': 1,
						},
					},
					{
						capabilities: {
							ATSLogin: 0,
							migratableDestination: 0,
							serviceMonitoringSelfManaged: 1,
						},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						deprecated: 1,
						description: 'Fedora 25 Server 64-bit Self-managed',
						discountable: 1,
						display_order: 0,
						featured: 0,
						links: [],
						os: 'LinuxFedora',
						os_license: null,
						prices: {
							'1': {
								hour: '0.00137',
								month: '1.00',
							},
							'2': {
								hour: '0.00137',
								month: '1.00',
							},
							'3': {
								hour: '0.00137',
								month: '1.00',
							},
							'493': {
								hour: '0.00137',
								month: '1.00',
							},
						},
						sub_title: '',
						support_level: 'Self-Managed',
						title: '',
						value: 'FEDORA_25_SERVER_UNMANAGED',
						zone_availability: {
							'40460': 1,
							'45412': 1,
							'86172': 1,
						},
					},
					{
						capabilities: {
							ATSLogin: 0,
							migratableDestination: 0,
							serviceMonitoringSelfManaged: 1,
						},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						deprecated: 1,
						description: 'Ubuntu 16.10 64-bit Self-Managed',
						discountable: 1,
						display_order: 0,
						featured: 0,
						links: [],
						os: 'LinuxUbuntu',
						os_license: null,
						prices: {
							'1': {
								hour: '0.00137',
								month: '1.00',
							},
							'2': {
								hour: '0.00137',
								month: '1.00',
							},
							'3': {
								hour: '0.00137',
								month: '1.00',
							},
							'493': {
								hour: '0.00137',
								month: '1.00',
							},
						},
						sub_title: '',
						support_level: 'Self-Managed',
						title: '',
						value: 'UBUNTU_1610_UNMANAGED',
						zone_availability: {
							'40460': 1,
							'45412': 1,
							'86172': 1,
						},
					},
					{
						capabilities: {
							cpanelLogin: 1,
							serviceMonitoringCpanel: 1,
						},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						deprecated: 0,
						description: 'CentOS 7 64-bit cPanel w/CloudLinux',
						discountable: 1,
						display_order: 0,
						extra_software: 'Cpanel',
						featured: 0,
						links: [],
						os: 'LinuxCloudLinux',
						os_license: null,
						prices: {
							'1': {
								hour: '0.02056',
								month: '15.00',
							},
							'2': {
								hour: '0.02741',
								month: '20.00',
							},
							'3': {
								hour: '0.02741',
								month: '20.00',
							},
							'493': {
								hour: '0.02741',
								month: '20.00',
							},
						},
						sub_title: '',
						support_level: 'Fully-Managed',
						title: '',
						value: 'CENTOS_7_CPANEL_CLOUDLINUX',
						zone_availability: {
							'40460': 1,
							'45412': 1,
							'86172': 1,
						},
					},
					{
						capabilities: {
							migratableDestination: 0,
							serviceMonitoringSelfManaged: 1,
						},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						deprecated: 1,
						description: 'Fedora 27 Server 64-bit Self-managed',
						discountable: 1,
						display_order: 0,
						featured: 0,
						links: [],
						os: 'LinuxFedora',
						os_license: null,
						prices: {
							'1': {
								hour: '0.00137',
								month: '1.00',
							},
							'2': {
								hour: '0.00137',
								month: '1.00',
							},
							'3': {
								hour: '0.00137',
								month: '1.00',
							},
							'493': {
								hour: '0.00137',
								month: '1.00',
							},
						},
						sub_title: '',
						support_level: 'Self-Managed',
						title: '',
						value: 'FEDORA_27_SERVER_UNMANAGED',
						zone_availability: {
							'40460': 1,
							'45412': 1,
							'86172': 1,
						},
					},
					{
						capabilities: {
							migratableDestination: 0,
							serviceMonitoringSelfManaged: 1,
						},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						deprecated: 0,
						description: 'Ubuntu 18.04 LTS 64-bit Self-Managed',
						discountable: 1,
						display_order: 0,
						featured: 0,
						links: [],
						os: 'LinuxUbuntu',
						os_license: null,
						prices: {
							'1': {
								hour: '0.00000',
								month: '0.00',
							},
							'2': {
								hour: '0.00000',
								month: '0.00',
							},
							'3': {
								hour: '0.00000',
								month: '0.00',
							},
							'493': {
								hour: '0.00000',
								month: '0.00',
							},
						},
						sub_title: '',
						support_level: 'Self-Managed',
						title: '',
						value: 'UBUNTU_1804_UNMANAGED',
						zone_availability: {
							'1': 1,
							'40460': 1,
							'45412': 1,
							'86172': 1,
						},
					},
					{
						capabilities: {
							serviceMonitoringSelfManaged: 1,
						},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						deprecated: 1,
						description: 'Ubuntu 18.04 Redis LTS 64-bit MWP',
						discountable: 1,
						display_order: 0,
						featured: 0,
						links: [],
						os: 'LinuxUbuntu',
						os_license: null,
						prices: {
							'1': {
								hour: '0.00000',
								month: '0.00',
							},
							'2': {
								hour: '0.00000',
								month: '0.00',
							},
							'3': {
								hour: '0.00000',
								month: '0.00',
							},
							'493': {
								hour: '0.00000',
								month: '0.00',
							},
						},
						sub_title: '',
						support_level: 'Self-Managed',
						title: '',
						value: 'UBUNTU_1804_REDIS_MWP',
						zone_availability: {
							'40460': 1,
							'45412': 1,
							'86172': 1,
						},
					},
					{
						capabilities: {
							migratableDestination: 0,
							serviceMonitoringSelfManaged: 1,
						},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						deprecated: 0,
						description: 'Debian 9 64-bit Self-managed',
						discountable: 1,
						display_order: 0,
						featured: 0,
						links: [],
						os: 'LinuxDebian',
						os_license: null,
						prices: {
							'1': {
								hour: '0.00000',
								month: '0.00',
							},
							'2': {
								hour: '0.00000',
								month: '0.00',
							},
							'3': {
								hour: '0.00000',
								month: '0.00',
							},
							'493': {
								hour: '0.00000',
								month: '0.00',
							},
						},
						sub_title: '',
						support_level: 'Self-Managed',
						title: '',
						value: 'DEBIAN_9_UNMANAGED',
						zone_availability: {
							'40460': 1,
							'45412': 1,
							'86172': 1,
						},
					},
					{
						capabilities: {
							migratableDestination: 0,
							serviceMonitoringSelfManaged: 1,
						},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						deprecated: 0,
						description: 'CentOS 8 64-bit Self-managed',
						discountable: 1,
						display_order: 0,
						featured: 0,
						links: [],
						os: 'LinuxCentOS',
						os_license: null,
						prices: {
							'1': {
								hour: '0.00137',
								month: '1.00',
							},
							'2': {
								hour: '0.00137',
								month: '1.00',
							},
							'3': {
								hour: '0.00137',
								month: '1.00',
							},
							'493': {
								hour: '0.00137',
								month: '1.00',
							},
						},
						sub_title: '',
						support_level: 'Self-Managed',
						title: '',
						value: 'CENTOS_8_UNMANAGED',
						zone_availability: {
							'40460': 1,
							'45412': 1,
							'86172': 1,
						},
					},
					{
						capabilities: {
							serviceMonitoringSelfManaged: 1,
						},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						deprecated: 1,
						description: 'Ubuntu 18.04 LTS 64-bit Sites MWP',
						discountable: 1,
						display_order: 0,
						featured: 0,
						links: [],
						os: 'LinuxUbuntu',
						os_license: null,
						prices: {
							'1': {
								hour: '0.00000',
								month: '0.00',
							},
							'2': {
								hour: '0.00000',
								month: '0.00',
							},
							'3': {
								hour: '0.00000',
								month: '0.00',
							},
							'493': {
								hour: '0.00000',
								month: '0.00',
							},
						},
						sub_title: '',
						support_level: 'Self-Managed',
						title: '',
						value: 'UBUNTU_1804_SITES_MWP',
						zone_availability: {
							'40460': 1,
							'45412': 1,
							'86172': 1,
						},
					},
					{
						capabilities: {
							cwpLogin: 1,
							serviceMonitoringCWP: 1,
						},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						deprecated: 0,
						description: 'CentOS 7 w/ CentOS Web Panel',
						discountable: 1,
						display_order: 0,
						extra_software: 'cwp',
						featured: 0,
						links: [],
						os: 'LinuxCentOS',
						os_license: null,
						prices: {
							'1': {
								hour: '0.00000',
								month: '0.00',
							},
							'2': {
								hour: '0.00000',
								month: '0.00',
							},
							'3': {
								hour: '0.00000',
								month: '0.00',
							},
							'493': {
								hour: '0.00000',
								month: '0.00',
							},
						},
						sub_title: '',
						support_level: null,
						title: '',
						value: 'CENTOS_7_CWP',
						zone_availability: {
							'40460': 1,
							'45412': 1,
							'86172': 1,
						},
					},
					{
						capabilities: {},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						deprecated: 0,
						description: 'Ubuntu 18.04 64-bit Core-managed',
						discountable: 1,
						display_order: 0,
						featured: 0,
						links: [],
						os: 'LinuxUbuntu',
						os_license: null,
						prices: {
							'1': {
								hour: '0.00137',
								month: '1.00',
							},
							'2': {
								hour: '0.00137',
								month: '1.00',
							},
							'3': {
								hour: '0.00137',
								month: '1.00',
							},
							'493': {
								hour: '0.00137',
								month: '1.00',
							},
						},
						sub_title: '',
						support_level: 'Core-Managed',
						title: '',
						value: 'UBUNTU_1804_COREMANAGED',
						zone_availability: {
							'40460': 1,
							'45412': 1,
							'86172': 1,
						},
					},
					{
						capabilities: {
							serviceMonitoringSelfManaged: 1,
						},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						deprecated: 0,
						description: 'Fedora 30 Server 64-bit Self-managed',
						discountable: 1,
						display_order: 0,
						featured: 0,
						links: [],
						os: 'LinuxFedora',
						os_license: null,
						prices: {
							'1': {
								hour: '0.00000',
								month: '0.00',
							},
							'2': {
								hour: '0.00000',
								month: '0.00',
							},
							'3': {
								hour: '0.00000',
								month: '0.00',
							},
							'493': {
								hour: '0.00000',
								month: '0.00',
							},
						},
						sub_title: '',
						support_level: 'Self-Managed',
						title: '',
						value: 'FEDORA_30_SERVER_UNMANAGED',
						zone_availability: {
							'40460': 1,
							'45412': 1,
							'86172': 1,
						},
					},
					{
						capabilities: {
							serviceMonitoringSelfManaged: 1,
						},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						deprecated: 0,
						description: 'Debian 10 64-bit Self-managed',
						discountable: 1,
						display_order: 0,
						featured: 0,
						links: [],
						os: 'LinuxDebian',
						os_license: null,
						prices: {
							'1': {
								hour: '0.00000',
								month: '0.00',
							},
							'2': {
								hour: '0.00000',
								month: '0.00',
							},
							'3': {
								hour: '0.00000',
								month: '0.00',
							},
							'493': {
								hour: '0.00000',
								month: '0.00',
							},
						},
						sub_title: '',
						support_level: 'Self-Managed',
						title: '',
						value: 'DEBIAN_10_UNMANAGED',
						zone_availability: {
							'40460': 1,
							'45412': 1,
							'86172': 1,
						},
					},
					{
						capabilities: {
							cwpLogin: 1,
							interworxLogin: 1,
							serviceMonitoringCWP: 1,
							serviceMonitoringInterworx: 1,
						},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						deprecated: 0,
						description: 'CentOS 7 w/ Interworx',
						discountable: 1,
						display_order: 0,
						extra_software: 'Interworx',
						featured: 0,
						links: [],
						os: 'LinuxCentOS',
						os_license: null,
						prices: {
							'1': {
								hour: '0.00685',
								month: '5.00',
							},
							'2': {
								hour: '0.00685',
								month: '5.00',
							},
							'3': {
								hour: '0.00685',
								month: '5.00',
							},
							'493': {
								hour: '0.00685',
								month: '5.00',
							},
						},
						sub_title: '',
						support_level: 'Fully-Managed',
						title: '',
						value: 'CENTOS_7_INTERWORX',
						zone_availability: {
							'40460': 1,
							'45412': 1,
							'86172': 1,
						},
					},
					{
						capabilities: {
							serviceMonitoringSelfManaged: 1,
						},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						deprecated: 0,
						description: 'Alpine Linux 64-bit Self-managed',
						discountable: 1,
						display_order: 0,
						featured: 0,
						links: [],
						os: 'LinuxAlpine',
						os_license: null,
						prices: {
							'1': {
								hour: '0.00000',
								month: '0.00',
							},
							'2': {
								hour: '0.00000',
								month: '0.00',
							},
							'3': {
								hour: '0.00000',
								month: '0.00',
							},
							'493': {
								hour: '0.00000',
								month: '0.00',
							},
						},
						sub_title: '',
						support_level: 'Self-Managed',
						title: '',
						value: 'ALPINE_LINUX',
						zone_availability: {
							'40460': 1,
							'86172': 1,
						},
					},
					{
						capabilities: {
							serviceMonitoringSelfManaged: 1,
						},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						deprecated: 0,
						description: 'Fedora 31 Server 64-bit Self-managed',
						discountable: 1,
						display_order: 0,
						featured: 0,
						links: [],
						os: 'LinuxFedora',
						os_license: null,
						prices: {
							'1': {
								hour: '0.00000',
								month: '0.00',
							},
							'2': {
								hour: '0.00000',
								month: '0.00',
							},
							'3': {
								hour: '0.00000',
								month: '0.00',
							},
							'493': {
								hour: '0.00000',
								month: '0.00',
							},
						},
						sub_title: '',
						support_level: 'Self-Managed',
						title: '',
						value: 'FEDORA_31_SERVER_UNMANAGED',
						zone_availability: {
							'40460': 1,
							'45412': 1,
							'86172': 1,
						},
					},
					{
						capabilities: {
							serviceMonitoringSelfManaged: 1,
						},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						deprecated: 0,
						description: 'CentOS Stream 64-bit Self-managed',
						discountable: 1,
						display_order: 0,
						featured: 0,
						links: [],
						os: 'LinuxCentOS',
						os_license: null,
						prices: {
							'1': {
								hour: '0.00000',
								month: '0.00',
							},
							'2': {
								hour: '0.00000',
								month: '0.00',
							},
							'3': {
								hour: '0.00000',
								month: '0.00',
							},
							'493': {
								hour: '0.00000',
								month: '0.00',
							},
						},
						sub_title: '',
						support_level: 'Self-Managed',
						title: '',
						value: 'CENTOS_STREAM_UNMANAGED',
						zone_availability: {
							'40460': 1,
							'45412': 1,
							'86172': 1,
						},
					},
					{
						capabilities: {},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						deprecated: 0,
						description: 'CentOS 8 64-bit CoreManaged',
						discountable: 1,
						display_order: 0,
						featured: 0,
						links: [],
						os: 'LinuxCentOS',
						os_license: null,
						prices: {
							'1': {
								hour: '0.00000',
								month: '0.00',
							},
							'2': {
								hour: '0.00000',
								month: '0.00',
							},
							'3': {
								hour: '0.00000',
								month: '0.00',
							},
							'493': {
								hour: '0.00000',
								month: '0.00',
							},
						},
						sub_title: '',
						support_level: 'Core-Managed',
						title: '',
						value: 'CENTOS_8_COREMANAGED',
						zone_availability: {
							'40460': 1,
							'45412': 1,
							'86172': 1,
						},
					},
					{
						capabilities: {
							interworxLogin: 1,
							serviceMonitoringInterworx: 1,
						},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						deprecated: 0,
						description: 'CloudLinux 7 w/ Interworx',
						discountable: 1,
						display_order: 0,
						extra_software: 'Interworx',
						featured: 0,
						links: [],
						os: 'LinuxCloudLinux',
						os_license: null,
						prices: {
							'1': {
								hour: '0.00000',
								month: '0.00',
							},
							'2': {
								hour: '0.00000',
								month: '0.00',
							},
							'3': {
								hour: '0.00000',
								month: '0.00',
							},
							'493': {
								hour: '0.00000',
								month: '0.00',
							},
						},
						sub_title: '',
						support_level: 'Fully-Managed',
						title: '',
						value: 'CLOUDLINUX_7_INTERWORX',
						zone_availability: {
							'40460': 1,
						},
					},
					{
						capabilities: {
							serviceMonitoringSelfManaged: 1,
						},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						deprecated: 0,
						description: 'Ubuntu 20.04 LTS 64-bit Self-Managed',
						discountable: 1,
						display_order: 0,
						featured: 0,
						links: [],
						os: 'LinuxUbuntu',
						os_license: null,
						prices: {
							'1': {
								hour: '0.00000',
								month: '0.00',
							},
							'2': {
								hour: '0.00000',
								month: '0.00',
							},
							'3': {
								hour: '0.00000',
								month: '0.00',
							},
							'493': {
								hour: '0.00000',
								month: '0.00',
							},
						},
						sub_title: '',
						support_level: 'Self-Managed',
						title: '',
						value: 'UBUNTU_2004_UNMANAGED',
						zone_availability: {
							'40460': 1,
							'45412': 1,
							'86172': 1,
						},
					},
					{
						capabilities: {
							serviceMonitoringSelfManaged: 1,
						},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						deprecated: 0,
						description: 'Ubuntu 20.04 LTS 64-bit Django',
						discountable: 1,
						display_order: 0,
						featured: 0,
						links: [],
						os: 'LinuxUbuntu',
						os_license: null,
						prices: {
							'1': {
								hour: '0.00000',
								month: '0.00',
							},
							'2': {
								hour: '0.00000',
								month: '0.00',
							},
							'3': {
								hour: '0.00000',
								month: '0.00',
							},
							'493': {
								hour: '0.00000',
								month: '0.00',
							},
						},
						sub_title: '',
						support_level: 'Self-Managed',
						title: '',
						value: 'UBUNTU_2004_DJANGO',
						zone_availability: {
							'40460': 1,
							'45412': 1,
							'86172': 1,
						},
					},
					{
						capabilities: {
							serviceMonitoringSelfManaged: 1,
						},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						deprecated: 0,
						description: 'Fedora 32 Server 64-bit Self-managed',
						discountable: 1,
						display_order: 0,
						featured: 0,
						links: [],
						os: 'LinuxFedora',
						os_license: null,
						prices: {
							'1': {
								hour: '0.00000',
								month: '0.00',
							},
							'2': {
								hour: '0.00000',
								month: '0.00',
							},
							'3': {
								hour: '0.00000',
								month: '0.00',
							},
							'493': {
								hour: '0.00000',
								month: '0.00',
							},
						},
						sub_title: '',
						support_level: 'Self-Managed',
						title: '',
						value: 'FEDORA_32_SERVER_UNMANAGED',
						zone_availability: {
							'40460': 1,
							'45412': 1,
							'86172': 1,
						},
					},
					{
						capabilities: {
							cpanelLogin: 1,
							serviceMonitoringCpanel: 1,
						},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						deprecated: 0,
						description: 'CentOS 8 64-bit with cPanel Fully-managed',
						discountable: 1,
						display_order: 0,
						extra_software: 'Cpanel',
						featured: 0,
						links: [],
						os: 'LinuxCentOS',
						os_license: null,
						prices: {
							'1': {
								hour: '0.02056',
								month: '15.00',
							},
							'2': {
								hour: '0.02056',
								month: '15.00',
							},
							'3': {
								hour: '0.02056',
								month: '15.00',
							},
							'493': {
								hour: '0.02056',
								month: '15.00',
							},
						},
						sub_title: '',
						support_level: 'Fully-Managed',
						title: '',
						value: 'CENTOS_8_CPANEL',
						zone_availability: {
							'1': 1,
							'40460': 1,
							'45412': 1,
							'86172': 1,
						},
					},
					{
						capabilities: {
							serviceMonitoringSelfManaged: 1,
						},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						deprecated: 0,
						description: 'Fedora 33 Server 64-bit Self-managed',
						discountable: 1,
						display_order: 0,
						featured: 0,
						links: [],
						os: 'LinuxFedora',
						os_license: null,
						prices: {
							'1': {
								hour: '0.00000',
								month: '0.00',
							},
							'2': {
								hour: '0.00000',
								month: '0.00',
							},
							'3': {
								hour: '0.00000',
								month: '0.00',
							},
							'493': {
								hour: '0.00000',
								month: '0.00',
							},
						},
						sub_title: '',
						support_level: 'Self-Managed',
						title: '',
						value: 'FEDORA_33_SERVER_UNMANAGED',
						zone_availability: {
							'40460': 1,
							'45412': 1,
							'86172': 1,
						},
					},
					{
						capabilities: {
							serviceMonitoringSelfManaged: 1,
						},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						deprecated: 0,
						description: 'AlmaLinux 8 64-bit Self-managed',
						discountable: 1,
						display_order: 0,
						featured: 0,
						links: [],
						os: 'LinuxCentOS',
						os_license: null,
						prices: {
							'1': {
								hour: '0.00000',
								month: '0.00',
							},
							'2': {
								hour: '0.00000',
								month: '0.00',
							},
							'3': {
								hour: '0.00000',
								month: '0.00',
							},
							'493': {
								hour: '0.00000',
								month: '0.00',
							},
						},
						sub_title: '',
						support_level: 'Self-Managed',
						title: '',
						value: 'ALMALINUX_8_UNMANAGED',
						zone_availability: {
							'1': 1,
							'40460': 1,
							'45412': 1,
							'86172': 1,
						},
					},
					{
						capabilities: {
							ATSLogin: 0,
							canHotplugRamVcpu: 0,
							couldHotplugRamVcpu: 0,
							migratableDestination: 0,
							serviceMonitoringSelfManaged: 1,
						},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						deprecated: 1,
						description: 'Debian 7.4 64-bit Self-managed',
						discountable: 1,
						display_order: 1,
						featured: 0,
						links: [],
						os: 'LinuxDebian',
						os_license: null,
						prices: {
							'1': {
								hour: '0.00137',
								month: '1.00',
							},
							'2': {
								hour: '0.00137',
								month: '1.00',
							},
							'3': {
								hour: '0.00137',
								month: '1.00',
							},
							'493': {
								hour: '0.00137',
								month: '1.00',
							},
						},
						sub_title: '',
						support_level: 'Self-Managed',
						title: '',
						value: 'DEBIAN_7_UNMANAGED',
						zone_availability: {
							'1': 1,
							'40460': 1,
							'45412': 1,
							'86172': 1,
						},
					},
				],
			},
			{
				automated: 1,
				display_order: 3,
				display_text: 'Bandwidth',
				key: 'Bandwidth',
				option_key_id: '2050',
				public: 1,
				required: 1,
				values: [
					{
						capabilities: {},
						comments: null,
						cycle: 'month',
						default: 1,
						default_price: '250.00000',
						description: '10 TB Monthly Transfer',
						discountable: 1,
						display_order: 3,
						featured: 0,
						links: [],
						prices: {
							'1': {
								hour: '0.00000',
								month: '0.00',
							},
							'2': {
								hour: '0.00000',
								month: '0.00',
							},
							'3': {
								hour: '0.00000',
								month: '0.00',
							},
						},
						sub_title: '',
						title: '',
						value: 'SS.10000',
					},
					{
						capabilities: {},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '450.00000',
						description: '15 TB Monthly Transfer',
						discountable: 1,
						display_order: 4,
						featured: 0,
						links: [],
						prices: {
							'1': {
								hour: '0.06853',
								month: '50.00',
							},
							'2': {
								hour: '0.06853',
								month: '50.00',
							},
							'3': {
								hour: '0.06853',
								month: '50.00',
							},
						},
						sub_title: '',
						title: '',
						value: 'SS.15000',
					},
					{
						capabilities: {},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '750.00000',
						description: '20 TB Monthly Transfer',
						discountable: 1,
						display_order: 5,
						featured: 0,
						links: [],
						prices: {
							'1': {
								hour: '0.13706',
								month: '100.00',
							},
							'2': {
								hour: '0.13706',
								month: '100.00',
							},
							'3': {
								hour: '0.13706',
								month: '100.00',
							},
						},
						sub_title: '',
						title: '',
						value: 'SS.20000',
					},
				],
			},
			{
				automated: 1,
				display_order: 4,
				display_text: 'Bandwidth Overage',
				key: 'BandwidthOverage',
				option_key_id: '29998',
				public: 1,
				required: 1,
				values: [
					{
						capabilities: {},
						comments: null,
						cycle: 'nontemporal',
						default: 1,
						default_price: '0.25000',
						description: '%{units} GB Overage',
						discountable: 1,
						display_order: 1,
						featured: 0,
						links: [],
						prices: {
							'1': {
								nontemporal: '0.25',
							},
							'2': {
								nontemporal: '0.25',
							},
							'3': {
								nontemporal: '0.25',
							},
						},
						sub_title: '',
						title: '',
						usage_based: 1,
						value: '1GB',
					},
				],
			},
			{
				automated: 1,
				display_order: 5,
				display_text: 'Backup Plan',
				key: 'LiquidWebBackupPlan',
				option_key_id: '73979',
				public: 1,
				required: 1,
				values: [
					{
						capabilities: {},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						description: 'No Backups',
						discountable: 1,
						display_order: 1,
						featured: 0,
						links: [],
						prices: {
							'1': {
								hour: '0.00000',
								month: '0.00',
							},
							'2': {
								hour: '0.00000',
								month: '0.00',
							},
							'3': {
								hour: '0.00000',
								month: '0.00',
							},
						},
						sub_title: '',
						title: '',
						value: 'None',
					},
					{
						capabilities: {},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						description: 'As-you-go Backups',
						discountable: 1,
						display_order: 2,
						featured: 0,
						links: [],
						options: [
							{
								automated: 1,
								display_order: 1,
								display_text: 'Backup Storage',
								key: 'BackupStorage',
								option_key_id: '4276',
								public: 1,
								required: 1,
								values: [
									{
										capabilities: {},
										comments: null,
										cycle: 'hour',
										default: 1,
										default_price: '0.00016',
										description: '%{units} GB Hours',
										discountable: 1,
										display_order: 1,
										featured: 0,
										links: [],
										prices: {
											'1': {
												hour: '0.00016',
												month: '0.12',
											},
											'2': {
												hour: '0.00016',
												month: '0.12',
											},
											'3': {
												hour: '0.00016',
												month: '0.12',
											},
										},
										sub_title: '',
										title: '',
										usage_based: 1,
										value: '1GBHR',
									},
								],
							},
							{
								automated: 1,
								display_order: 2,
								display_text: 'Backup Days',
								key: 'BackupDay',
								option_key_id: '75634',
								public: 1,
								required: 1,
								values: [
									{
										capabilities: {},
										comments: null,
										cycle: 'month',
										default: 1,
										default_price: '0.00000',
										description: '%{units} Retention Days',
										discountable: 1,
										display_order: 1,
										featured: 0,
										links: [],
										needs_count: 1,
										prices: {
											'1': {
												hour: '0.00000',
												month: '0.00',
											},
											'2': {
												hour: '0.00000',
												month: '0.00',
											},
											'3': {
												hour: '0.00000',
												month: '0.00',
											},
										},
										sub_title: '',
										title: '',
										value: '1',
									},
								],
							},
						],
						prices: {
							'1': {
								hour: '0.00000',
								month: '0.00',
							},
							'2': {
								hour: '0.00000',
								month: '0.00',
							},
							'3': {
								hour: '0.00000',
								month: '0.00',
							},
						},
						sub_title: '',
						title: '',
						value: 'Daily',
					},
					{
						capabilities: {},
						comments: null,
						cycle: 'month',
						default: 1,
						default_price: '0.00000',
						description: 'Quota-based Backups',
						discountable: 1,
						display_order: 3,
						featured: 0,
						links: [],
						options: [
							{
								automated: 1,
								display_order: 1,
								display_text: 'Quota Pricing',
								key: 'BackupQuota',
								option_key_id: '4275',
								public: 1,
								required: 1,
								values: [
									{
										capabilities: {},
										comments: null,
										cycle: 'month',
										default: 0,
										default_price: '0.00000',
										description: '50 GB',
										discountable: 1,
										display_order: 1,
										featured: 0,
										links: [],
										prices: {
											'1': {
												hour: '0.00000',
												month: '0.00',
											},
											'2': {
												hour: '0.00000',
												month: '0.00',
											},
											'3': {
												hour: '0.00000',
												month: '0.00',
											},
											'493': {
												hour: '0.00000',
												month: '0.00',
											},
										},
										sub_title: '50 GB',
										title: '50 GB',
										value: '50',
									},
									{
										capabilities: {},
										comments: null,
										cycle: 'month',
										default: 1,
										default_price: '10.00000',
										description: '100 GB',
										discountable: 1,
										display_order: 5,
										featured: 0,
										links: [],
										prices: {
											'1': {
												hour: '0.01371',
												month: '10.00',
											},
											'2': {
												hour: '0.01371',
												month: '10.00',
											},
											'3': {
												hour: '0.01371',
												month: '10.00',
											},
										},
										sub_title: '',
										title: '',
										value: '100',
									},
									{
										capabilities: {},
										comments: null,
										cycle: 'month',
										default: 0,
										default_price: '23.00000',
										description: '250 GB',
										discountable: 1,
										display_order: 10,
										featured: 0,
										links: [],
										prices: {
											'1': {
												hour: '0.03152',
												month: '23.00',
											},
											'2': {
												hour: '0.03152',
												month: '23.00',
											},
											'3': {
												hour: '0.03152',
												month: '23.00',
											},
										},
										sub_title: '',
										title: '',
										value: '250',
									},
									{
										capabilities: {},
										comments: null,
										cycle: 'month',
										default: 0,
										default_price: '45.00000',
										description: '500 GB',
										discountable: 1,
										display_order: 15,
										featured: 0,
										links: [],
										prices: {
											'1': {
												hour: '0.06168',
												month: '45.00',
											},
											'2': {
												hour: '0.06168',
												month: '45.00',
											},
											'3': {
												hour: '0.06168',
												month: '45.00',
											},
										},
										sub_title: '',
										title: '',
										value: '500',
									},
									{
										capabilities: {},
										comments: null,
										cycle: 'month',
										default: 0,
										default_price: '70.00000',
										description: '1000 GB',
										discountable: 1,
										display_order: 20,
										featured: 0,
										links: [],
										prices: {
											'1': {
												hour: '0.09594',
												month: '70.00',
											},
											'2': {
												hour: '0.09594',
												month: '70.00',
											},
											'3': {
												hour: '0.09594',
												month: '70.00',
											},
										},
										sub_title: '',
										title: '',
										value: '1000',
									},
									{
										capabilities: {},
										comments: null,
										cycle: 'month',
										default: 0,
										default_price: '130.00000',
										description: '2000 GB',
										discountable: 1,
										display_order: 25,
										featured: 0,
										links: [],
										prices: {
											'1': {
												hour: '0.17818',
												month: '130.00',
											},
											'2': {
												hour: '0.17818',
												month: '130.00',
											},
											'3': {
												hour: '0.17818',
												month: '130.00',
											},
										},
										sub_title: '',
										title: '',
										value: '2000',
									},
								],
							},
						],
						prices: {
							'1': {
								hour: '0.00000',
								month: '0.00',
							},
							'2': {
								hour: '0.00000',
								month: '0.00',
							},
							'3': {
								hour: '0.00000',
								month: '0.00',
							},
						},
						sub_title: 'Quota-based Backups',
						title: 'Quota-based Backups',
						value: 'Quota',
					},
				],
			},
			{
				automated: 1,
				display_order: 6,
				display_text: 'Extra IP Addresses',
				key: 'ExtraIp',
				option_key_id: '9133',
				public: 1,
				required: 1,
				values: [
					{
						capabilities: {},
						comments: null,
						cycle: 'month',
						default: 1,
						default_price: '1.00000',
						description: '%{units} Additional Public IPs',
						discountable: 1,
						display_order: 1,
						featured: 0,
						links: [],
						needs_count: 1,
						prices: {
							'1': {
								hour: '0.00273',
								month: '1.99',
							},
							'2': {
								hour: '0.00273',
								month: '1.99',
							},
							'3': {
								hour: '0.00273',
								month: '1.99',
							},
						},
						sub_title: '',
						title: '',
						value: '1',
					},
				],
			},
			{
				automated: 0,
				display_order: 7,
				display_text: 'Network Storage',
				key: 'NetworkStorage',
				option_key_id: '2230619',
				public: 1,
				required: 0,
				values: [
					{
						capabilities: {},
						comments: null,
						cycle: 'month',
						default: 1,
						default_price: '0.00000',
						description: 'None',
						discountable: 0,
						display_order: 0,
						featured: 0,
						links: [],
						prices: {
							'1': {
								hour: '0.00000',
								month: '0.00',
							},
							'2': {
								hour: '0.00000',
								month: '0.00',
							},
							'3': {
								hour: '0.00000',
								month: '0.00',
							},
							'493': {
								hour: '0.00000',
								month: '0.00',
							},
						},
						sub_title: '',
						title: '',
						value: 'no_storage',
					},
					{
						capabilities: {},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						description: '10GB',
						discountable: 0,
						display_order: 5,
						featured: 0,
						links: [],
						prices: {
							'1': {
								hour: '0.00137',
								month: '1.00',
							},
							'2': {
								hour: '0.00137',
								month: '1.00',
							},
							'3': {
								hour: '0.00137',
								month: '1.00',
							},
							'493': {
								hour: '0.00137',
								month: '1.00',
							},
						},
						sub_title: '',
						title: '',
						value: '10gb',
					},
					{
						capabilities: {},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						description: '20GB',
						discountable: 0,
						display_order: 10,
						featured: 0,
						links: [],
						prices: {
							'1': {
								hour: '0.00274',
								month: '2.00',
							},
							'2': {
								hour: '0.00274',
								month: '2.00',
							},
							'3': {
								hour: '0.00274',
								month: '2.00',
							},
							'493': {
								hour: '0.00274',
								month: '2.00',
							},
						},
						sub_title: '',
						title: '',
						value: '20gb',
					},
				],
			},
		],
		postConfigureInterstitialUri: '',
		prices: {
			'1': {
				hour: '0.00000',
				month: '0.00',
			},
			'2': {
				hour: '0.00000',
				month: '0.00',
			},
			'3': {
				hour: '0.00000',
				month: '0.00',
			},
		},
		purchaseQuantityLimit: '3',
		related_product: {
			active: 1,
			alias: null,
			categories: [
				'AuthDedicated',
				'CloudBareMetal',
				'Provisioned',
				'VirtualDedicated',
			],
			code: 'SS.VM',
			credit_eligible: 1,
			cycle: 'month',
			default_price: '0.00000',
			description: 'LiquidWeb SmartServer',
			discountable: 1,
			display_in_catalog: 1,
			entereddate: '2011-01-03 13:39:16.349034-05',
			features: [],
			id: 33611,
			option_value_features: {
				'1713663': [
					'4 Cores @ 2.1 GHz Turbo',
					'6 GB RAM (DDR3)',
					'1 TB 7200 RPM SATA RAID 1 (Primary)',
					'1 TB 7200 RPM SATA (Backup)',
					'120 GB Kingston SSD Bonus Drive',
					'5 TB Outbound & FREE Incoming Bandwidth',
				],
				'1715983': [
					'4 Cores @ 2.8 GHz',
					'2 GB RAM (DDR3)',
					'1 TB 7200 RPM SATA RAID 1 (Primary)',
					'1 TB 7200 RPM SATA (Backup)',
					'120 GB Kingston SSD Bonus Drive',
					'5 TB Outbound & FREE Incoming Bandwidth',
				],
				'1715984': [
					'8 Cores @ 2.0 GHz',
					'16 GB RAM (DDR3)',
					'1 TB 7200 RPM SATA RAID 1 (Primary)',
					'1 TB 7200 RPM SATA (Backup)',
					'120 GB Kingston SSD Bonus Drive',
					'5 TB Outbound & FREE Incoming Bandwidth',
				],
			},
			options: [
				{
					automated: 0,
					display_order: 0,
					display_text: 'Management & Control Panel',
					key: 'ControlPanel',
					option_key_id: '2692',
					public: 0,
					required: 0,
					values: [],
				},
				{
					automated: 0,
					display_order: 0,
					display_text: 'cPanel License Tier',
					key: 'cPanelLicenseTier',
					option_key_id: '2605518',
					public: 1,
					required: 0,
					values: [
						{
							active: 1,
							capabilities: {},
							comments: null,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							description: 'Legacy',
							discountable: 1,
							display_order: 0,
							featured: 0,
							id: 6569582,
							links: [],
							num_units: null,
							prices: {
								'1': {
									hour: '0.00000',
									month: '0.00',
								},
								'2': {
									hour: '0.00000',
									month: '0.00',
								},
								'3': {
									hour: '0.00000',
									month: '0.00',
								},
								'493': {
									hour: '0.00000',
									month: '0.00',
								},
							},
							public: 0,
							sub_title: '',
							title: '',
							value: 'legacy',
						},
						{
							active: 1,
							capabilities: {},
							comments: null,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							description: 'Custom',
							discountable: 1,
							display_order: 0,
							featured: 0,
							id: 6569588,
							links: [],
							num_units: null,
							prices: {
								'1': {
									hour: '0.00000',
									month: '0.00',
								},
								'2': {
									hour: '0.00000',
									month: '0.00',
								},
								'3': {
									hour: '0.00000',
									month: '0.00',
								},
								'493': {
									hour: '0.00000',
									month: '0.00',
								},
							},
							public: 0,
							sub_title: '',
							title: '',
							value: 'custom',
						},
						{
							active: 1,
							capabilities: {},
							comments: null,
							cycle: 'month',
							default: 1,
							default_price: '0.00000',
							description: 'None',
							discountable: 1,
							display_order: 0,
							featured: 0,
							id: 6569594,
							links: [],
							num_units: null,
							prices: {
								'1': {
									hour: '0.00000',
									month: '0.00',
								},
								'2': {
									hour: '0.00000',
									month: '0.00',
								},
								'3': {
									hour: '0.00000',
									month: '0.00',
								},
								'493': {
									hour: '0.00000',
									month: '0.00',
								},
							},
							public: 0,
							sub_title: '',
							title: '',
							value: 'none',
						},
						{
							active: 1,
							capabilities: {},
							comments: null,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							description: 'Pro',
							discountable: 1,
							display_order: 20,
							featured: 0,
							id: 6569559,
							links: [],
							num_units: null,
							prices: {
								'1': {
									hour: '0.00000',
									month: '0.00',
								},
								'2': {
									hour: '0.00000',
									month: '0.00',
								},
								'3': {
									hour: '0.00000',
									month: '0.00',
								},
								'493': {
									hour: '0.00000',
									month: '0.00',
								},
							},
							public: 1,
							sub_title: '',
							title: '',
							value: 'pro_cloud_30',
						},
						{
							active: 1,
							capabilities: {},
							comments: null,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							description: 'Plus',
							discountable: 1,
							display_order: 25,
							featured: 0,
							id: 6569543,
							links: [],
							num_units: null,
							prices: {
								'1': {
									hour: '0.00000',
									month: '0.00',
								},
								'2': {
									hour: '0.00000',
									month: '0.00',
								},
								'3': {
									hour: '0.00000',
									month: '0.00',
								},
								'493': {
									hour: '0.00000',
									month: '0.00',
								},
							},
							public: 1,
							sub_title: '',
							title: '',
							value: 'plus_cloud_50',
						},
						{
							active: 1,
							capabilities: {},
							comments: null,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							description: 'Premier Fixed 100',
							discountable: 1,
							display_order: 35,
							featured: 0,
							id: 6569544,
							links: [],
							num_units: null,
							prices: {
								'1': {
									hour: '0.01371',
									month: '10.00',
								},
								'2': {
									hour: '0.01371',
									month: '10.00',
								},
								'3': {
									hour: '0.01371',
									month: '10.00',
								},
								'493': {
									hour: '0.01371',
									month: '10.00',
								},
							},
							public: 1,
							sub_title: '',
							title: '',
							value: 'premier_cloud_100',
						},
						{
							active: 1,
							capabilities: {},
							comments: null,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							description: 'Premier Fixed 150',
							discountable: 1,
							display_order: 40,
							featured: 0,
							id: 6569546,
							links: [],
							num_units: null,
							prices: {
								'1': {
									hour: '0.02056',
									month: '15.00',
								},
								'2': {
									hour: '0.02056',
									month: '15.00',
								},
								'3': {
									hour: '0.02056',
									month: '15.00',
								},
								'493': {
									hour: '0.02056',
									month: '15.00',
								},
							},
							public: 1,
							sub_title: '',
							title: '',
							value: 'premier_cloud_150',
						},
						{
							active: 1,
							capabilities: {},
							comments: null,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							description: 'Premier Fixed 200',
							discountable: 1,
							display_order: 45,
							featured: 0,
							id: 6569548,
							links: [],
							num_units: null,
							prices: {
								'1': {
									hour: '0.02741',
									month: '20.00',
								},
								'2': {
									hour: '0.02741',
									month: '20.00',
								},
								'3': {
									hour: '0.02741',
									month: '20.00',
								},
								'493': {
									hour: '0.02741',
									month: '20.00',
								},
							},
							public: 1,
							sub_title: '',
							title: '',
							value: 'premier_cloud_200',
						},
						{
							active: 1,
							capabilities: {},
							comments: null,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							description: 'Premier Fixed 250',
							discountable: 1,
							display_order: 50,
							featured: 0,
							id: 6569549,
							links: [],
							num_units: null,
							prices: {
								'1': {
									hour: '0.03427',
									month: '25.00',
								},
								'2': {
									hour: '0.03427',
									month: '25.00',
								},
								'3': {
									hour: '0.03427',
									month: '25.00',
								},
								'493': {
									hour: '0.03427',
									month: '25.00',
								},
							},
							public: 1,
							sub_title: '',
							title: '',
							value: 'premier_cloud_250',
						},
						{
							active: 1,
							capabilities: {},
							comments: null,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							description: 'Premier Fixed 300',
							discountable: 1,
							display_order: 55,
							featured: 0,
							id: 6569550,
							links: [],
							num_units: null,
							prices: {
								'1': {
									hour: '0.04112',
									month: '30.00',
								},
								'2': {
									hour: '0.04112',
									month: '30.00',
								},
								'3': {
									hour: '0.04112',
									month: '30.00',
								},
								'493': {
									hour: '0.04112',
									month: '30.00',
								},
							},
							public: 1,
							sub_title: '',
							title: '',
							value: 'premier_cloud_300',
						},
						{
							active: 1,
							capabilities: {},
							comments: null,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							description: 'Premier Fixed 350',
							discountable: 1,
							display_order: 60,
							featured: 0,
							id: 6569551,
							links: [],
							num_units: null,
							prices: {
								'1': {
									hour: '0.04797',
									month: '35.00',
								},
								'2': {
									hour: '0.04797',
									month: '35.00',
								},
								'3': {
									hour: '0.04797',
									month: '35.00',
								},
								'493': {
									hour: '0.04797',
									month: '35.00',
								},
							},
							public: 1,
							sub_title: '',
							title: '',
							value: 'premier_cloud_350',
						},
						{
							active: 1,
							capabilities: {},
							comments: null,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							description: 'Premier Fixed 400',
							discountable: 1,
							display_order: 70,
							featured: 0,
							id: 6569552,
							links: [],
							num_units: null,
							prices: {
								'1': {
									hour: '0.06168',
									month: '45.00',
								},
								'2': {
									hour: '0.06168',
									month: '45.00',
								},
								'3': {
									hour: '0.06168',
									month: '45.00',
								},
								'493': {
									hour: '0.06168',
									month: '45.00',
								},
							},
							public: 1,
							sub_title: '',
							title: '',
							value: 'premier_cloud_400',
						},
						{
							active: 1,
							capabilities: {},
							comments: null,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							description: 'Premier Fixed 450',
							discountable: 1,
							display_order: 80,
							featured: 0,
							id: 6569553,
							links: [],
							num_units: null,
							prices: {
								'1': {
									hour: '0.07538',
									month: '55.00',
								},
								'2': {
									hour: '0.07538',
									month: '55.00',
								},
								'3': {
									hour: '0.07538',
									month: '55.00',
								},
								'493': {
									hour: '0.07538',
									month: '55.00',
								},
							},
							public: 1,
							sub_title: '',
							title: '',
							value: 'premier_cloud_450',
						},
						{
							active: 1,
							capabilities: {},
							comments: null,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							description: 'Premier Fixed 500',
							discountable: 1,
							display_order: 90,
							featured: 0,
							id: 6569554,
							links: [],
							num_units: null,
							prices: {
								'1': {
									hour: '0.08909',
									month: '65.00',
								},
								'2': {
									hour: '0.08909',
									month: '65.00',
								},
								'3': {
									hour: '0.08909',
									month: '65.00',
								},
								'493': {
									hour: '0.08909',
									month: '65.00',
								},
							},
							public: 1,
							sub_title: '',
							title: '',
							value: 'premier_cloud_500',
						},
						{
							active: 1,
							capabilities: {},
							comments: null,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							description: 'Premier Fixed 600',
							discountable: 1,
							display_order: 100,
							featured: 0,
							id: 6569555,
							links: [],
							num_units: null,
							prices: {
								'1': {
									hour: '0.10280',
									month: '75.00',
								},
								'2': {
									hour: '0.10280',
									month: '75.00',
								},
								'3': {
									hour: '0.10280',
									month: '75.00',
								},
								'493': {
									hour: '0.10280',
									month: '75.00',
								},
							},
							public: 1,
							sub_title: '',
							title: '',
							value: 'premier_cloud_600',
						},
						{
							active: 1,
							capabilities: {},
							comments: null,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							description: 'Premier Fixed 700',
							discountable: 1,
							display_order: 110,
							featured: 0,
							id: 6569556,
							links: [],
							num_units: null,
							prices: {
								'1': {
									hour: '0.11650',
									month: '85.00',
								},
								'2': {
									hour: '0.11650',
									month: '85.00',
								},
								'3': {
									hour: '0.11650',
									month: '85.00',
								},
								'493': {
									hour: '0.11650',
									month: '85.00',
								},
							},
							public: 1,
							sub_title: '',
							title: '',
							value: 'premier_cloud_700',
						},
						{
							active: 1,
							capabilities: {},
							comments: null,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							description: 'Premier Fixed 800',
							discountable: 1,
							display_order: 120,
							featured: 0,
							id: 6569557,
							links: [],
							num_units: null,
							prices: {
								'1': {
									hour: '0.13021',
									month: '95.00',
								},
								'2': {
									hour: '0.13021',
									month: '95.00',
								},
								'3': {
									hour: '0.13021',
									month: '95.00',
								},
								'493': {
									hour: '0.13021',
									month: '95.00',
								},
							},
							public: 1,
							sub_title: '',
							title: '',
							value: 'premier_cloud_800',
						},
						{
							active: 1,
							capabilities: {},
							comments: null,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							description: 'Premier Fixed 900',
							discountable: 1,
							display_order: 130,
							featured: 0,
							id: 6569558,
							links: [],
							num_units: null,
							prices: {
								'1': {
									hour: '0.14391',
									month: '105.00',
								},
								'2': {
									hour: '0.14391',
									month: '105.00',
								},
								'3': {
									hour: '0.14391',
									month: '105.00',
								},
								'493': {
									hour: '0.14391',
									month: '105.00',
								},
							},
							public: 1,
							sub_title: '',
							title: '',
							value: 'premier_cloud_900',
						},
						{
							active: 1,
							capabilities: {},
							comments: null,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							description: 'Premier Fixed 1000',
							discountable: 1,
							display_order: 140,
							featured: 0,
							id: 6569545,
							links: [],
							num_units: null,
							prices: {
								'1': {
									hour: '0.15762',
									month: '115.00',
								},
								'2': {
									hour: '0.15762',
									month: '115.00',
								},
								'3': {
									hour: '0.15762',
									month: '115.00',
								},
								'493': {
									hour: '0.15762',
									month: '115.00',
								},
							},
							public: 1,
							sub_title: '',
							title: '',
							value: 'premier_cloud_1000',
						},
						{
							active: 1,
							capabilities: {},
							comments: null,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							description: 'Premier Fixed 1500',
							discountable: 1,
							display_order: 200,
							featured: 0,
							id: 6569547,
							links: [],
							num_units: null,
							prices: {
								'1': {
									hour: '0.23986',
									month: '175.00',
								},
								'2': {
									hour: '0.23986',
									month: '175.00',
								},
								'3': {
									hour: '0.23986',
									month: '175.00',
								},
								'493': {
									hour: '0.23986',
									month: '175.00',
								},
							},
							public: 1,
							sub_title: '',
							title: '',
							value: 'premier_cloud_1500',
						},
					],
				},
				{
					automated: 0,
					display_order: 1,
					display_text: 'Server Type',
					key: 'ConfigId',
					option_key_id: '4280',
					public: 1,
					required: 1,
					values: [
						{
							active: 1,
							available: 0,
							capabilities: {},
							category: 'bare-metal',
							comments: null,
							cpu_cores: 16,
							cpu_cores_per_socket: 4,
							cpu_description:
								'Intel(R) Xeon(R) CPU           E5620  @ 2.40GHz',
							cpu_hyperthreading: 2,
							cpu_sockets: 2,
							cpu_speed: 2400,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							description:
								"'Intel(R) Xeon(R) CPU           E5620  @ 2.40GHz', 2400MHz, 4 Core, Sockets: 2, 31.5 GB RAM, 1849 GB SATA Disk",
							discountable: 1,
							disk: 1665,
							disk_count: 4,
							disk_total: '1849',
							disk_type: 'SATA',
							display_order: 13,
							featured: 0,
							id: 4020284,
							links: [],
							memory: 32229,
							memory_available: 30750,
							num_units: null,
							prices: {
								'1': {
									hour: '0.00959',
									month: '7.00',
								},
								'2': {
									hour: '0.00959',
									month: '7.00',
								},
								'3': {
									hour: '0.00959',
									month: '7.00',
								},
								'493': {
									hour: '0.00959',
									month: '7.00',
								},
							},
							public: 1,
							raid_level: '10',
							sub_title: '',
							title: '',
							value: '7',
							zone_availability: {
								'40460': 0,
							},
						},
						{
							active: 1,
							available: 0,
							capabilities: {},
							category: 'bare-metal',
							comments: null,
							cpu_cores: 8,
							cpu_cores_per_socket: 4,
							cpu_description:
								'Intel(R) Xeon(R) CPU           E5606  @ 2.13GHz',
							cpu_hyperthreading: 0,
							cpu_sockets: 2,
							cpu_speed: 2133,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							description:
								"'Intel(R) Xeon(R) CPU           E5606  @ 2.13GHz', 2133MHz, 4 Core, Sockets: 2, 31.5 GB RAM, 1849 GB SATA Disk",
							discountable: 1,
							disk: 1665,
							disk_count: 4,
							disk_total: '1849',
							disk_type: 'SATA',
							display_order: 18,
							featured: 0,
							id: 6754086,
							links: [],
							memory: 32229,
							memory_available: 30494,
							num_units: null,
							prices: {
								'1': {
									hour: '0.01371',
									month: '10.00',
								},
								'2': {
									hour: '0.01371',
									month: '10.00',
								},
								'3': {
									hour: '0.01371',
									month: '10.00',
								},
								'493': {
									hour: '0.01371',
									month: '10.00',
								},
							},
							public: 1,
							raid_level: '10',
							sub_title: '',
							title: '',
							value: '6',
							zone_availability: {
								'40460': 0,
							},
						},
						{
							active: 1,
							available: 0,
							capabilities: {},
							category: 'storm',
							comments: null,
							cpu_cores: 4,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							description: 'Storm 7GIG: 6.8 GB RAM, 150 GB Disk',
							discountable: 1,
							disk: '150',
							display_order: 30,
							featured: 0,
							id: 1982840,
							links: [],
							memory: 7000,
							num_units: null,
							prices: {
								'1': {
									hour: '0.01371',
									month: '10.00',
								},
								'3': {
									hour: '0.01371',
									month: '10.00',
								},
							},
							public: 1,
							sub_title: '',
							title: '',
							value: '170',
							zone_availability: {
								'40460': 0,
							},
						},
						{
							active: 1,
							available: 0,
							capabilities: {},
							category: 'ssd',
							comments: null,
							cpu_cores: 1,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							description: 'test create config: 0.4 GB RAM, 75 GB Disk',
							discountable: 1,
							disk: '75',
							display_order: 37,
							featured: 0,
							id: 1767015,
							links: [],
							memory: 400,
							num_units: null,
							prices: {
								'1': {
									hour: '0.06853',
									month: '50.00',
								},
								'2': {
									hour: '0.06853',
									month: '50.00',
								},
								'3': {
									hour: '0.06853',
									month: '50.00',
								},
							},
							public: 1,
							sub_title: '',
							title: '',
							value: '167',
							zone_availability: {
								'40460': 0,
							},
						},
					],
				},
				{
					automated: 1,
					display_order: 2,
					display_text: 'Server Type',
					key: 'Template',
					option_key_id: '4282',
					public: 1,
					required: 1,
					values: [
						{
							active: 1,
							capabilities: {
								ATSLogin: 0,
								migratableDestination: 0,
								sbsPersistentDevNames: 1,
								serviceMonitoringSelfManaged: 1,
							},
							comments: null,
							cycle: 'month',
							default: 0,
							default_price: '10.00000',
							deprecated: 1,
							description: 'Arch Linux 20110819',
							discountable: 1,
							display_order: 0,
							featured: 0,
							id: 227370,
							links: [],
							num_units: null,
							os: 'LinuxArch',
							os_license: null,
							prices: {
								'1': {
									hour: '0.02741',
									month: '20.00',
								},
								'2': {
									hour: '0.02741',
									month: '20.00',
								},
								'3': {
									hour: '0.02741',
									month: '20.00',
								},
								'493': {
									hour: '0.02741',
									month: '20.00',
								},
							},
							public: 1,
							sub_title: '',
							support_level: 'Self-Managed',
							title: '',
							value: 'ARCHLINUX_20110819_UNMANAGED',
							zone_availability: {
								'40460': 1,
							},
						},
						{
							active: 1,
							capabilities: {
								ATSLogin: 0,
								migratableDestination: 0,
								sbsPersistentDevNames: 1,
								serviceMonitoringSelfManaged: 1,
							},
							comments: null,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							deprecated: 1,
							description: 'CentOS 6 64-bit Self-managed',
							discountable: 1,
							display_order: 0,
							featured: 0,
							id: 273098,
							links: [],
							num_units: null,
							os: 'LinuxCentOS',
							os_license: null,
							prices: {
								'1': {
									hour: '0.00137',
									month: '1.00',
								},
								'2': {
									hour: '0.00137',
									month: '1.00',
								},
								'3': {
									hour: '0.00137',
									month: '1.00',
								},
								'493': {
									hour: '0.00137',
									month: '1.00',
								},
							},
							public: 1,
							sub_title: '',
							support_level: 'Self-Managed',
							title: '',
							value: 'CENTOS_62_UNMANAGED',
							zone_availability: {
								'40460': 1,
							},
						},
						{
							active: 1,
							capabilities: {
								migratableDestination: 0,
								sbsPersistentDevNames: 1,
							},
							comments: null,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							deprecated: 1,
							description: 'CentOS 6 64-bit Core-managed',
							discountable: 1,
							display_order: 0,
							featured: 0,
							id: 273100,
							links: [],
							num_units: null,
							os: 'LinuxCentOS',
							os_license: null,
							prices: {
								'1': {
									hour: '0.00274',
									month: '2.00',
								},
								'2': {
									hour: '0.00274',
									month: '2.00',
								},
								'3': {
									hour: '0.00274',
									month: '2.00',
								},
								'493': {
									hour: '0.00274',
									month: '2.00',
								},
							},
							public: 1,
							sub_title: '',
							support_level: 'Core-Managed',
							title: '',
							value: 'CENTOS_62_COREMANAGED',
							zone_availability: {
								'40460': 1,
							},
						},
						{
							active: 1,
							capabilities: {
								cpanelLogin: 1,
								sbsPersistentDevNames: 1,
								serviceMonitoringCpanel: 1,
								whmLogin: 1,
							},
							comments: null,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							deprecated: 1,
							description: 'CentOS 6 64-bit cPanel',
							discountable: 1,
							display_order: 0,
							extra_software: 'Cpanel',
							featured: 0,
							id: 273952,
							links: [],
							num_units: null,
							os: 'LinuxCentOS',
							os_license: null,
							prices: {
								'1': {
									hour: '0.02741',
									month: '20.00',
								},
								'2': {
									hour: '0.02741',
									month: '20.00',
								},
								'3': {
									hour: '0.02741',
									month: '20.00',
								},
								'493': {
									hour: '0.02741',
									month: '20.00',
								},
							},
							public: 1,
							sub_title: '',
							support_level: 'Fully-Managed',
							title: '',
							value: 'CENTOS_62_CPANEL',
							zone_availability: {
								'40460': 1,
							},
						},
						{
							active: 1,
							capabilities: {
								ATSLogin: 0,
								canHotplugRamVcpu: 0,
								couldHotplugRamVcpu: 0,
								migratableDestination: 0,
								serviceMonitoringSelfManaged: 1,
							},
							comments: null,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							deprecated: 1,
							description: 'Debian 7.4 64-bit Self-managed',
							discountable: 1,
							display_order: 0,
							featured: 0,
							id: 1205367,
							links: [],
							num_units: null,
							os: 'LinuxDebian',
							os_license: null,
							prices: {
								'1': {
									hour: '0.00137',
									month: '1.00',
								},
								'2': {
									hour: '0.00137',
									month: '1.00',
								},
								'3': {
									hour: '0.00137',
									month: '1.00',
								},
								'493': {
									hour: '0.00137',
									month: '1.00',
								},
							},
							public: 1,
							sub_title: '',
							support_level: 'Self-Managed',
							title: '',
							value: 'DEBIAN_7_UNMANAGED',
							zone_availability: {
								'1': 1,
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						{
							active: 1,
							capabilities: {
								ATSLogin: 0,
								migratableDestination: 0,
								serviceMonitoringSelfManaged: 1,
							},
							comments: null,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							deprecated: 1,
							description: 'Ubuntu 14.04 LTS 64-bit Self-Managed',
							discountable: 1,
							display_order: 0,
							featured: 0,
							id: 1575839,
							links: [],
							num_units: null,
							os: 'LinuxUbuntu',
							os_license: null,
							prices: {
								'1': {
									hour: '0.00137',
									month: '1.00',
								},
								'2': {
									hour: '0.00137',
									month: '1.00',
								},
								'3': {
									hour: '0.00137',
									month: '1.00',
								},
								'493': {
									hour: '0.00137',
									month: '1.00',
								},
							},
							public: 1,
							sub_title: '',
							support_level: 'Self-Managed',
							title: '',
							value: 'UBUNTU_1404_UNMANAGED',
							zone_availability: {
								'1': 1,
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						{
							active: 1,
							capabilities: {
								migratableDestination: 0,
							},
							comments: null,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							deprecated: 1,
							description: 'Ubuntu 14.04 64-bit Core-managed',
							discountable: 1,
							display_order: 0,
							featured: 0,
							id: 1576873,
							links: [],
							num_units: null,
							os: 'LinuxUbuntu',
							os_license: null,
							prices: {
								'1': {
									hour: '0.01371',
									month: '10.00',
								},
								'2': {
									hour: '0.01371',
									month: '10.00',
								},
								'3': {
									hour: '0.01371',
									month: '10.00',
								},
								'493': {
									hour: '0.01371',
									month: '10.00',
								},
							},
							public: 1,
							sub_title: '',
							support_level: 'Core-Managed',
							title: '',
							value: 'UBUNTU_1404_COREMANAGED',
							zone_availability: {
								'40460': 1,
							},
						},
						{
							active: 1,
							capabilities: {
								ATSLogin: 0,
								migratableDestination: 0,
								serviceMonitoringSelfManaged: 1,
							},
							comments: null,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							deprecated: 0,
							description: 'CentOS 7 64-bit Self-managed',
							discountable: 1,
							display_order: 0,
							featured: 0,
							id: 1624095,
							links: [],
							num_units: null,
							os: 'LinuxCentOS',
							os_license: null,
							prices: {
								'1': {
									hour: '0.00000',
									month: '0.00',
								},
								'2': {
									hour: '0.00000',
									month: '0.00',
								},
								'3': {
									hour: '0.00000',
									month: '0.00',
								},
								'493': {
									hour: '0.00000',
									month: '0.00',
								},
							},
							public: 1,
							sub_title: '',
							support_level: 'Self-Managed',
							title: '',
							value: 'CENTOS_7_UNMANAGED',
							zone_availability: {
								'40460': 1,
								'45412': 1,
							},
						},
						{
							active: 1,
							capabilities: {
								migratableDestination: 0,
							},
							comments: 'THIS IS A TEST',
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							deprecated: 0,
							description: 'CentOS 7.0 64-bit CoreManaged',
							discountable: 1,
							display_order: 0,
							featured: 0,
							id: 1703550,
							links: [],
							num_units: null,
							os: 'LinuxCentOS',
							os_license: null,
							prices: {
								'1': {
									hour: '0.00000',
									month: '0.00',
								},
								'2': {
									hour: '0.00000',
									month: '0.00',
								},
								'3': {
									hour: '0.00000',
									month: '0.00',
								},
							},
							public: 1,
							sub_title: '',
							support_level: 'Core-Managed',
							title: '',
							value: 'CENTOS_7_COREMANAGED',
							zone_availability: {
								'40460': 1,
							},
						},
						{
							active: 1,
							capabilities: {
								ATSLogin: 0,
								migratableDestination: 0,
								serviceMonitoringSelfManaged: 1,
							},
							comments: null,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							deprecated: 1,
							description: 'Ubuntu 14.10 64-bit Self-managed',
							discountable: 1,
							display_order: 0,
							featured: 0,
							id: 1742391,
							links: [],
							num_units: null,
							os: 'LinuxUbuntu',
							os_license: null,
							prices: {
								'1': {
									hour: '0.00137',
									month: '1.00',
								},
								'2': {
									hour: '0.00137',
									month: '1.00',
								},
								'3': {
									hour: '0.00137',
									month: '1.00',
								},
								'493': {
									hour: '0.01508',
									month: '11.00',
								},
							},
							public: 1,
							sub_title: '',
							support_level: 'Self-Managed',
							title: '',
							value: 'UBUNTU_1410_UNMANAGED',
							zone_availability: {
								'40460': 1,
							},
						},
						{
							active: 1,
							capabilities: {},
							comments: null,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							deprecated: 0,
							description: 'Windows Server 2012 R2 Fully Managed with Plesk 12',
							discountable: 1,
							display_order: 0,
							extra_software: 'Plesk',
							featured: 0,
							id: 1963484,
							links: [],
							num_units: null,
							os: 'Windows',
							os_license: null,
							prices: {
								'1': {
									hour: '0.00137',
									month: '1.00',
								},
								'2': {
									hour: '0.00137',
									month: '1.00',
								},
								'3': {
									hour: '0.00137',
									month: '1.00',
								},
								'493': {
									hour: '0.00137',
									month: '1.00',
								},
							},
							public: 1,
							sub_title: '',
							support_level: 'Fully-Managed',
							title: '',
							value: 'WINDOWS_2012R2_PLESK',
							zone_availability: {
								'1': 1,
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						{
							active: 1,
							capabilities: {
								migratableDestination: 0,
							},
							comments: null,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							deprecated: 0,
							description:
								'Windows Server 2012 R2 64-bit Standard Edition Core-Managed',
							discountable: 1,
							display_order: 0,
							featured: 0,
							id: 1996880,
							links: [],
							num_units: null,
							os: 'Windows',
							os_license: null,
							prices: {
								'1': {
									hour: '0.00137',
									month: '1.00',
								},
								'2': {
									hour: '0.00137',
									month: '1.00',
								},
								'3': {
									hour: '0.00137',
									month: '1.00',
								},
								'493': {
									hour: '0.00137',
									month: '1.00',
								},
							},
							public: 1,
							sub_title: '',
							support_level: 'Core-Managed',
							title: '',
							value: 'WINDOWS_2012R2_COREMANAGED',
							zone_availability: {
								'1': 1,
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						{
							active: 1,
							capabilities: {
								ATSLogin: 0,
								migratableDestination: 0,
								serviceMonitoringSelfManaged: 1,
							},
							comments: null,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							deprecated: 0,
							description: 'Debian 8 64-bit Self-managed',
							discountable: 1,
							display_order: 0,
							featured: 0,
							id: 1997910,
							links: [],
							num_units: null,
							os: 'LinuxDebian',
							os_license: null,
							prices: {
								'1': {
									hour: '0.00137',
									month: '1.00',
								},
								'2': {
									hour: '0.00137',
									month: '1.00',
								},
								'3': {
									hour: '0.00137',
									month: '1.00',
								},
								'493': {
									hour: '0.00137',
									month: '1.00',
								},
							},
							public: 1,
							sub_title: '',
							support_level: 'Self-Managed',
							title: '',
							value: 'DEBIAN_8_UNMANAGED',
							zone_availability: {
								'1': 1,
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						{
							active: 1,
							capabilities: {
								migratableDestination: 0,
							},
							comments: null,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							deprecated: 0,
							description:
								'Windows Server 2012 R2 64-bit Standard Edition Self-Managed',
							discountable: 1,
							display_order: 0,
							featured: 0,
							id: 1998929,
							links: [],
							num_units: null,
							os: 'Windows',
							os_license: null,
							prices: {
								'1': {
									hour: '0.01234',
									month: '9.00',
								},
								'2': {
									hour: '0.01234',
									month: '9.00',
								},
								'3': {
									hour: '0.01234',
									month: '9.00',
								},
								'493': {
									hour: '0.01234',
									month: '9.00',
								},
							},
							public: 1,
							sub_title: '',
							support_level: 'Self-Managed',
							title: '',
							value: 'WINDOWS_2012R2_UNMANAGED',
							zone_availability: {
								'1': 1,
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						{
							active: 1,
							capabilities: {
								ATSLogin: 0,
								migratableDestination: 0,
								serviceMonitoringSelfManaged: 1,
							},
							comments: null,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							deprecated: 1,
							description: 'Ubuntu 15.04 64-bit Self-Managed',
							discountable: 1,
							display_order: 0,
							featured: 0,
							id: 2020284,
							links: [],
							num_units: null,
							os: 'LinuxUbuntu',
							os_license: null,
							prices: {
								'1': {
									hour: '0.00137',
									month: '1.00',
								},
								'2': {
									hour: '0.00137',
									month: '1.00',
								},
								'3': {
									hour: '0.00137',
									month: '1.00',
								},
								'493': {
									hour: '0.00137',
									month: '1.00',
								},
							},
							public: 1,
							sub_title: '',
							support_level: 'Self-Managed',
							title: '',
							value: 'UBUNTU_1504_UNMANAGED',
							zone_availability: {
								'1': 1,
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						{
							active: 1,
							capabilities: {
								cpanelLogin: 1,
								serviceMonitoringCpanel: 1,
							},
							comments: null,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							deprecated: 0,
							description: 'CentOS 7 64-bit with cPanel Fully-managed',
							discountable: 1,
							display_order: 0,
							extra_software: 'Cpanel',
							featured: 0,
							id: 2050388,
							links: [],
							num_units: null,
							os: 'LinuxCentOS',
							os_license: null,
							prices: {
								'1': {
									hour: '0.00137',
									month: '1.00',
								},
								'2': {
									hour: '0.00137',
									month: '1.00',
								},
								'3': {
									hour: '0.00137',
									month: '1.00',
								},
								'493': {
									hour: '0.00137',
									month: '1.00',
								},
							},
							public: 1,
							sub_title: '',
							support_level: 'Fully-Managed',
							title: '',
							value: 'CENTOS_7_CPANEL',
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						{
							active: 1,
							capabilities: {
								cpanelLogin: 1,
								serviceMonitoringCpanel: 1,
							},
							comments: null,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							deprecated: 1,
							description: 'CentOS 7 64-bit cPanel Wordpress Optimized',
							discountable: 1,
							display_order: 0,
							extra_software: 'cPanel',
							featured: 0,
							id: 2238423,
							links: [],
							num_units: null,
							os: 'LinuxCentOS',
							os_license: null,
							prices: {
								'1': {
									hour: '0.00274',
									month: '2.00',
								},
								'2': {
									hour: '0.00274',
									month: '2.00',
								},
								'3': {
									hour: '0.00274',
									month: '2.00',
								},
								'493': {
									hour: '0.00274',
									month: '2.00',
								},
							},
							public: 1,
							sub_title: '',
							support_level: 'Fully-Managed',
							title: '',
							value: 'CENTOS_7_CPANEL_WPOPT',
							zone_availability: {
								'40460': 1,
							},
						},
						{
							active: 1,
							capabilities: {},
							comments: null,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							deprecated: 1,
							description: 'Ubuntu 14.04 LTS 64-bit MWP',
							discountable: 1,
							display_order: 0,
							featured: 0,
							id: 2511602,
							links: [],
							num_units: null,
							os: 'LinuxUbuntu',
							os_license: null,
							prices: {
								'1': {
									hour: '0.00000',
									month: '0.00',
								},
								'2': {
									hour: '0.00000',
									month: '0.00',
								},
								'3': {
									hour: '0.00000',
									month: '0.00',
								},
								'493': {
									hour: '0.00000',
									month: '0.00',
								},
							},
							public: 1,
							sub_title: '',
							support_level: 'Core-Managed',
							title: '',
							value: 'UBUNTU_1404_MWP',
							zone_availability: {
								'1': 1,
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						{
							active: 1,
							capabilities: {
								migratableDestination: 0,
							},
							comments: null,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							deprecated: 0,
							description: 'Ubuntu 16.04 64-bit Core-managed',
							discountable: 1,
							display_order: 0,
							featured: 0,
							id: 2529920,
							links: [],
							num_units: null,
							os: 'LinuxUbuntu',
							os_license: null,
							prices: {
								'1': {
									hour: '0.01371',
									month: '10.00',
								},
								'2': {
									hour: '0.01371',
									month: '10.00',
								},
								'3': {
									hour: '0.01371',
									month: '10.00',
								},
								'493': {
									hour: '0.01371',
									month: '10.00',
								},
							},
							public: 1,
							sub_title: '',
							support_level: 'Core-Managed',
							title: '',
							value: 'UBUNTU_1604_COREMANAGED',
							zone_availability: {
								'40460': 1,
							},
						},
						{
							active: 1,
							capabilities: {
								ATSLogin: 0,
								migratableDestination: 0,
								serviceMonitoringSelfManaged: 1,
							},
							comments: null,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							deprecated: 0,
							description: 'Ubuntu 16.04 LTS 64-bit Self-Managed',
							discountable: 1,
							display_order: 0,
							featured: 0,
							id: 2607599,
							links: [],
							num_units: null,
							os: 'LinuxUbuntu',
							os_license: null,
							prices: {
								'1': {
									hour: '0.00000',
									month: '0.00',
								},
								'2': {
									hour: '0.00000',
									month: '0.00',
								},
								'3': {
									hour: '0.00000',
									month: '0.00',
								},
								'493': {
									hour: '0.00000',
									month: '0.00',
								},
							},
							public: 1,
							sub_title: '',
							support_level: 'Self-Managed',
							title: '',
							value: 'UBUNTU_1604_UNMANAGED',
							zone_availability: {
								'1': 1,
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						{
							active: 1,
							capabilities: {
								pleskLogin: 1,
								serviceMonitoringPlesk: 1,
							},
							comments: null,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							deprecated: 0,
							description: 'CentOS 7 64-bit Plesk',
							discountable: 1,
							display_order: 0,
							extra_software: 'Plesk',
							featured: 0,
							id: 2706046,
							links: [],
							num_units: null,
							os: 'LinuxCentOS',
							os_license: null,
							prices: {
								'1': {
									hour: '0.00137',
									month: '1.00',
								},
								'2': {
									hour: '0.00137',
									month: '1.00',
								},
								'3': {
									hour: '0.00137',
									month: '1.00',
								},
								'493': {
									hour: '0.00137',
									month: '1.00',
								},
							},
							public: 1,
							sub_title: '',
							support_level: 'Fully-Managed',
							title: '',
							value: 'CENTOS_7_PLESK',
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						{
							active: 1,
							capabilities: {
								ATSLogin: 0,
								migratableDestination: 0,
								serviceMonitoringSelfManaged: 1,
							},
							comments: null,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							deprecated: 1,
							description: 'Ubuntu 15.10 64-bit Self-Managed',
							discountable: 1,
							display_order: 0,
							featured: 0,
							id: 2758484,
							links: [],
							num_units: null,
							os: 'LinuxUbuntu',
							os_license: null,
							prices: {
								'1': {
									hour: '0.00137',
									month: '1.00',
								},
								'2': {
									hour: '0.00137',
									month: '1.00',
								},
								'3': {
									hour: '0.00137',
									month: '1.00',
								},
								'493': {
									hour: '0.00137',
									month: '1.00',
								},
							},
							public: 1,
							sub_title: '',
							support_level: 'Self-Managed',
							title: '',
							value: 'UBUNTU_1510_UNMANAGED',
							zone_availability: {
								'40460': 1,
							},
						},
						{
							active: 1,
							capabilities: {
								ATSLogin: 0,
								migratableDestination: 0,
								serviceMonitoringSelfManaged: 1,
							},
							comments: null,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							deprecated: 1,
							description: 'CentOS 6 Self-managed',
							discountable: 1,
							display_order: 0,
							featured: 0,
							id: 2950807,
							links: [],
							num_units: null,
							os: 'LinuxCentOS',
							os_license: null,
							prices: {
								'1': {
									hour: '0.00137',
									month: '1.00',
								},
								'2': {
									hour: '0.00137',
									month: '1.00',
								},
								'3': {
									hour: '0.00137',
									month: '1.00',
								},
								'493': {
									hour: '0.00137',
									month: '1.00',
								},
							},
							public: 1,
							sub_title: '',
							support_level: 'Self-Managed',
							title: '',
							value: 'CENTOS_6_UNMANAGED',
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						{
							active: 1,
							capabilities: {
								cpanelLogin: 1,
								serviceMonitoringCpanel: 1,
							},
							comments: null,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							deprecated: 1,
							description: 'CentOS 6 64-bit cPanel w/CloudLinux',
							discountable: 1,
							display_order: 0,
							extra_software: 'Cpanel',
							featured: 0,
							id: 2971572,
							links: [],
							num_units: null,
							os: 'LinuxCloudLinux',
							os_license: null,
							prices: {
								'1': {
									hour: '0.02741',
									month: '20.00',
								},
								'2': {
									hour: '0.02741',
									month: '20.00',
								},
								'3': {
									hour: '0.02741',
									month: '20.00',
								},
								'493': {
									hour: '0.02741',
									month: '20.00',
								},
							},
							public: 1,
							sub_title: '',
							support_level: 'Fully-Managed',
							title: '',
							value: 'CENTOS_6_CPANEL_CLOUDLINUX',
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						{
							active: 1,
							capabilities: {
								ATSLogin: 0,
								migratableDestination: 0,
								serviceMonitoringSelfManaged: 1,
							},
							comments: null,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							deprecated: 1,
							description: 'Fedora 25 Server 64-bit Self-managed',
							discountable: 1,
							display_order: 0,
							featured: 0,
							id: 4081464,
							links: [],
							num_units: null,
							os: 'LinuxFedora',
							os_license: null,
							prices: {
								'1': {
									hour: '0.00137',
									month: '1.00',
								},
								'2': {
									hour: '0.00137',
									month: '1.00',
								},
								'3': {
									hour: '0.00137',
									month: '1.00',
								},
								'493': {
									hour: '0.00137',
									month: '1.00',
								},
							},
							public: 1,
							sub_title: '',
							support_level: 'Self-Managed',
							title: '',
							value: 'FEDORA_25_SERVER_UNMANAGED',
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						{
							active: 1,
							capabilities: {
								ATSLogin: 0,
								migratableDestination: 0,
								serviceMonitoringSelfManaged: 1,
							},
							comments: null,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							deprecated: 1,
							description: 'Ubuntu 16.10 64-bit Self-Managed',
							discountable: 1,
							display_order: 0,
							featured: 0,
							id: 4081468,
							links: [],
							num_units: null,
							os: 'LinuxUbuntu',
							os_license: null,
							prices: {
								'1': {
									hour: '0.00137',
									month: '1.00',
								},
								'2': {
									hour: '0.00137',
									month: '1.00',
								},
								'3': {
									hour: '0.00137',
									month: '1.00',
								},
								'493': {
									hour: '0.00137',
									month: '1.00',
								},
							},
							public: 1,
							sub_title: '',
							support_level: 'Self-Managed',
							title: '',
							value: 'UBUNTU_1610_UNMANAGED',
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						{
							active: 1,
							capabilities: {
								cpanelLogin: 1,
								serviceMonitoringCpanel: 1,
							},
							comments: null,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							deprecated: 0,
							description: 'CentOS 7 64-bit cPanel w/CloudLinux',
							discountable: 1,
							display_order: 0,
							extra_software: 'Cpanel',
							featured: 0,
							id: 4153436,
							links: [],
							num_units: null,
							os: 'LinuxCloudLinux',
							os_license: null,
							prices: {
								'1': {
									hour: '0.02741',
									month: '20.00',
								},
								'2': {
									hour: '0.02741',
									month: '20.00',
								},
								'3': {
									hour: '0.02741',
									month: '20.00',
								},
								'493': {
									hour: '0.02741',
									month: '20.00',
								},
							},
							public: 1,
							sub_title: '',
							support_level: 'Fully-Managed',
							title: '',
							value: 'CENTOS_7_CPANEL_CLOUDLINUX',
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						{
							active: 1,
							capabilities: {
								migratableDestination: 0,
								serviceMonitoringSelfManaged: 1,
							},
							comments: null,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							deprecated: 1,
							description: 'Fedora 27 Server 64-bit Self-managed',
							discountable: 1,
							display_order: 0,
							featured: 0,
							id: 4921409,
							links: [],
							num_units: null,
							os: 'LinuxFedora',
							os_license: null,
							prices: {
								'1': {
									hour: '0.00137',
									month: '1.00',
								},
								'2': {
									hour: '0.00137',
									month: '1.00',
								},
								'3': {
									hour: '0.00137',
									month: '1.00',
								},
								'493': {
									hour: '0.00137',
									month: '1.00',
								},
							},
							public: 1,
							sub_title: '',
							support_level: 'Self-Managed',
							title: '',
							value: 'FEDORA_27_SERVER_UNMANAGED',
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						{
							active: 1,
							capabilities: {
								migratableDestination: 0,
								serviceMonitoringSelfManaged: 1,
							},
							comments: null,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							deprecated: 0,
							description: 'Ubuntu 18.04 LTS 64-bit Self-Managed',
							discountable: 1,
							display_order: 0,
							featured: 0,
							id: 5008691,
							links: [],
							num_units: null,
							os: 'LinuxUbuntu',
							os_license: null,
							prices: {
								'1': {
									hour: '0.00000',
									month: '0.00',
								},
								'2': {
									hour: '0.00000',
									month: '0.00',
								},
								'3': {
									hour: '0.00000',
									month: '0.00',
								},
								'493': {
									hour: '0.00000',
									month: '0.00',
								},
							},
							public: 1,
							sub_title: '',
							support_level: 'Self-Managed',
							title: '',
							value: 'UBUNTU_1804_UNMANAGED',
							zone_availability: {
								'1': 1,
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						{
							active: 1,
							capabilities: {
								serviceMonitoringSelfManaged: 1,
							},
							comments: null,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							deprecated: 1,
							description: 'Ubuntu 18.04 Redis LTS 64-bit MWP',
							discountable: 1,
							display_order: 0,
							featured: 0,
							id: 5405580,
							links: [],
							num_units: null,
							os: 'LinuxUbuntu',
							os_license: null,
							prices: {
								'1': {
									hour: '0.00000',
									month: '0.00',
								},
								'2': {
									hour: '0.00000',
									month: '0.00',
								},
								'3': {
									hour: '0.00000',
									month: '0.00',
								},
								'493': {
									hour: '0.00000',
									month: '0.00',
								},
							},
							public: 1,
							sub_title: '',
							support_level: 'Self-Managed',
							title: '',
							value: 'UBUNTU_1804_REDIS_MWP',
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						{
							active: 1,
							capabilities: {
								migratableDestination: 0,
								serviceMonitoringSelfManaged: 1,
							},
							comments: null,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							deprecated: 0,
							description: 'Debian 9 64-bit Self-managed',
							discountable: 1,
							display_order: 0,
							featured: 0,
							id: 5626244,
							links: [],
							num_units: null,
							os: 'LinuxDebian',
							os_license: null,
							prices: {
								'1': {
									hour: '0.00000',
									month: '0.00',
								},
								'2': {
									hour: '0.00000',
									month: '0.00',
								},
								'3': {
									hour: '0.00000',
									month: '0.00',
								},
								'493': {
									hour: '0.00000',
									month: '0.00',
								},
							},
							public: 1,
							sub_title: '',
							support_level: 'Self-Managed',
							title: '',
							value: 'DEBIAN_9_UNMANAGED',
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						{
							active: 1,
							capabilities: {
								migratableDestination: 0,
								serviceMonitoringSelfManaged: 1,
							},
							comments: null,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							deprecated: 0,
							description: 'CentOS 8 64-bit Self-managed',
							discountable: 1,
							display_order: 0,
							featured: 0,
							id: 5788089,
							links: [],
							num_units: null,
							os: 'LinuxCentOS',
							os_license: null,
							prices: {
								'1': {
									hour: '0.00137',
									month: '1.00',
								},
								'2': {
									hour: '0.00137',
									month: '1.00',
								},
								'3': {
									hour: '0.00137',
									month: '1.00',
								},
								'493': {
									hour: '0.00137',
									month: '1.00',
								},
							},
							public: 1,
							sub_title: '',
							support_level: 'Self-Managed',
							title: '',
							value: 'CENTOS_8_UNMANAGED',
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						{
							active: 1,
							capabilities: {
								serviceMonitoringSelfManaged: 1,
							},
							comments: null,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							deprecated: 1,
							description: 'Ubuntu 18.04 LTS 64-bit Sites MWP',
							discountable: 1,
							display_order: 0,
							featured: 0,
							id: 6084610,
							links: [],
							num_units: null,
							os: 'LinuxUbuntu',
							os_license: null,
							prices: {
								'1': {
									hour: '0.00000',
									month: '0.00',
								},
								'2': {
									hour: '0.00000',
									month: '0.00',
								},
								'3': {
									hour: '0.00000',
									month: '0.00',
								},
								'493': {
									hour: '0.00000',
									month: '0.00',
								},
							},
							public: 1,
							sub_title: '',
							support_level: 'Self-Managed',
							title: '',
							value: 'UBUNTU_1804_SITES_MWP',
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						{
							active: 1,
							capabilities: {
								cwpLogin: 1,
								serviceMonitoringCWP: 1,
							},
							comments: null,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							deprecated: 0,
							description: 'CentOS 7 w/ CentOS Web Panel',
							discountable: 1,
							display_order: 0,
							extra_software: 'cwp',
							featured: 0,
							id: 6153179,
							links: [],
							num_units: null,
							os: 'LinuxCentOS',
							os_license: null,
							prices: {
								'1': {
									hour: '0.00000',
									month: '0.00',
								},
								'2': {
									hour: '0.00000',
									month: '0.00',
								},
								'3': {
									hour: '0.00000',
									month: '0.00',
								},
								'493': {
									hour: '0.00000',
									month: '0.00',
								},
							},
							public: 1,
							sub_title: '',
							support_level: null,
							title: '',
							value: 'CENTOS_7_CWP',
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						{
							active: 1,
							capabilities: {},
							comments: null,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							deprecated: 0,
							description: 'Ubuntu 18.04 64-bit Core-managed',
							discountable: 1,
							display_order: 0,
							featured: 0,
							id: 6156438,
							links: [],
							num_units: null,
							os: 'LinuxUbuntu',
							os_license: null,
							prices: {
								'1': {
									hour: '0.00137',
									month: '1.00',
								},
								'2': {
									hour: '0.00137',
									month: '1.00',
								},
								'3': {
									hour: '0.00137',
									month: '1.00',
								},
								'493': {
									hour: '0.00137',
									month: '1.00',
								},
							},
							public: 1,
							sub_title: '',
							support_level: 'Core-Managed',
							title: '',
							value: 'UBUNTU_1804_COREMANAGED',
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						{
							active: 1,
							capabilities: {
								serviceMonitoringSelfManaged: 1,
							},
							comments: null,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							deprecated: 0,
							description: 'Fedora 30 Server 64-bit Self-managed',
							discountable: 1,
							display_order: 0,
							featured: 0,
							id: 6254042,
							links: [],
							num_units: null,
							os: 'LinuxFedora',
							os_license: null,
							prices: {
								'1': {
									hour: '0.00000',
									month: '0.00',
								},
								'2': {
									hour: '0.00000',
									month: '0.00',
								},
								'3': {
									hour: '0.00000',
									month: '0.00',
								},
								'493': {
									hour: '0.00000',
									month: '0.00',
								},
							},
							public: 1,
							sub_title: '',
							support_level: 'Self-Managed',
							title: '',
							value: 'FEDORA_30_SERVER_UNMANAGED',
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						{
							active: 1,
							capabilities: {
								serviceMonitoringSelfManaged: 1,
							},
							comments: null,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							deprecated: 0,
							description: 'Debian 10 64-bit Self-managed',
							discountable: 1,
							display_order: 0,
							featured: 0,
							id: 6363975,
							links: [],
							num_units: null,
							os: 'LinuxDebian',
							os_license: null,
							prices: {
								'1': {
									hour: '0.00000',
									month: '0.00',
								},
								'2': {
									hour: '0.00000',
									month: '0.00',
								},
								'3': {
									hour: '0.00000',
									month: '0.00',
								},
								'493': {
									hour: '0.00000',
									month: '0.00',
								},
							},
							public: 1,
							sub_title: '',
							support_level: 'Self-Managed',
							title: '',
							value: 'DEBIAN_10_UNMANAGED',
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						{
							active: 1,
							capabilities: {
								cwpLogin: 1,
								interworxLogin: 1,
								serviceMonitoringCWP: 1,
								serviceMonitoringInterworx: 1,
							},
							comments: null,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							deprecated: 0,
							description: 'CentOS 7 w/ Interworx',
							discountable: 1,
							display_order: 0,
							extra_software: 'Interworx',
							featured: 0,
							id: 6363978,
							links: [],
							num_units: null,
							os: 'LinuxCentOS',
							os_license: null,
							prices: {
								'1': {
									hour: '0.00685',
									month: '5.00',
								},
								'2': {
									hour: '0.00685',
									month: '5.00',
								},
								'3': {
									hour: '0.00685',
									month: '5.00',
								},
								'493': {
									hour: '0.00685',
									month: '5.00',
								},
							},
							public: 1,
							sub_title: '',
							support_level: 'Fully-Managed',
							title: '',
							value: 'CENTOS_7_INTERWORX',
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						{
							active: 1,
							capabilities: {
								serviceMonitoringSelfManaged: 1,
							},
							comments: null,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							deprecated: 0,
							description: 'Alpine Linux 64-bit Self-managed',
							discountable: 1,
							display_order: 0,
							featured: 0,
							id: 6492107,
							links: [],
							num_units: null,
							os: 'LinuxAlpine',
							os_license: null,
							prices: {
								'1': {
									hour: '0.00000',
									month: '0.00',
								},
								'2': {
									hour: '0.00000',
									month: '0.00',
								},
								'3': {
									hour: '0.00000',
									month: '0.00',
								},
								'493': {
									hour: '0.00000',
									month: '0.00',
								},
							},
							public: 1,
							sub_title: '',
							support_level: 'Self-Managed',
							title: '',
							value: 'ALPINE_LINUX',
							zone_availability: {
								'40460': 1,
								'86172': 1,
							},
						},
						{
							active: 1,
							capabilities: {
								serviceMonitoringSelfManaged: 1,
							},
							comments: null,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							deprecated: 0,
							description: 'Fedora 31 Server 64-bit Self-managed',
							discountable: 1,
							display_order: 0,
							featured: 0,
							id: 6636243,
							links: [],
							num_units: null,
							os: 'LinuxFedora',
							os_license: null,
							prices: {
								'1': {
									hour: '0.00000',
									month: '0.00',
								},
								'2': {
									hour: '0.00000',
									month: '0.00',
								},
								'3': {
									hour: '0.00000',
									month: '0.00',
								},
								'493': {
									hour: '0.00000',
									month: '0.00',
								},
							},
							public: 1,
							sub_title: '',
							support_level: 'Self-Managed',
							title: '',
							value: 'FEDORA_31_SERVER_UNMANAGED',
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						{
							active: 1,
							capabilities: {
								serviceMonitoringSelfManaged: 1,
							},
							comments: null,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							deprecated: 0,
							description: 'CentOS Stream 64-bit Self-managed',
							discountable: 1,
							display_order: 0,
							featured: 0,
							id: 6637370,
							links: [],
							num_units: null,
							os: 'LinuxCentOS',
							os_license: null,
							prices: {
								'1': {
									hour: '0.00000',
									month: '0.00',
								},
								'2': {
									hour: '0.00000',
									month: '0.00',
								},
								'3': {
									hour: '0.00000',
									month: '0.00',
								},
								'493': {
									hour: '0.00000',
									month: '0.00',
								},
							},
							public: 1,
							sub_title: '',
							support_level: 'Self-Managed',
							title: '',
							value: 'CENTOS_STREAM_UNMANAGED',
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						{
							active: 1,
							capabilities: {},
							comments: null,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							deprecated: 0,
							description: 'CentOS 8 64-bit CoreManaged',
							discountable: 1,
							display_order: 0,
							featured: 0,
							id: 6695463,
							links: [],
							num_units: null,
							os: 'LinuxCentOS',
							os_license: null,
							prices: {
								'1': {
									hour: '0.00000',
									month: '0.00',
								},
								'2': {
									hour: '0.00000',
									month: '0.00',
								},
								'3': {
									hour: '0.00000',
									month: '0.00',
								},
								'493': {
									hour: '0.00000',
									month: '0.00',
								},
							},
							public: 1,
							sub_title: '',
							support_level: 'Core-Managed',
							title: '',
							value: 'CENTOS_8_COREMANAGED',
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						{
							active: 1,
							capabilities: {
								serviceMonitoringSelfManaged: 1,
							},
							comments: null,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							deprecated: 0,
							description: 'Ubuntu 20.04 LTS 64-bit Self-Managed',
							discountable: 1,
							display_order: 0,
							featured: 0,
							id: 6883305,
							links: [],
							num_units: null,
							os: 'LinuxUbuntu',
							os_license: null,
							prices: {
								'1': {
									hour: '0.00000',
									month: '0.00',
								},
								'2': {
									hour: '0.00000',
									month: '0.00',
								},
								'3': {
									hour: '0.00000',
									month: '0.00',
								},
								'493': {
									hour: '0.00000',
									month: '0.00',
								},
							},
							public: 1,
							sub_title: '',
							support_level: 'Self-Managed',
							title: '',
							value: 'UBUNTU_2004_UNMANAGED',
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						{
							active: 1,
							capabilities: {
								serviceMonitoringSelfManaged: 1,
							},
							comments: null,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							deprecated: 0,
							description: 'Ubuntu 20.04 LTS 64-bit Django',
							discountable: 1,
							display_order: 0,
							featured: 0,
							id: 6883309,
							links: [],
							num_units: null,
							os: 'LinuxUbuntu',
							os_license: null,
							prices: {
								'1': {
									hour: '0.00000',
									month: '0.00',
								},
								'2': {
									hour: '0.00000',
									month: '0.00',
								},
								'3': {
									hour: '0.00000',
									month: '0.00',
								},
								'493': {
									hour: '0.00000',
									month: '0.00',
								},
							},
							public: 1,
							sub_title: '',
							support_level: 'Self-Managed',
							title: '',
							value: 'UBUNTU_2004_DJANGO',
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						{
							active: 1,
							capabilities: {
								serviceMonitoringSelfManaged: 1,
							},
							comments: null,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							deprecated: 0,
							description: 'Fedora 32 Server 64-bit Self-managed',
							discountable: 1,
							display_order: 0,
							featured: 0,
							id: 7285947,
							links: [],
							num_units: null,
							os: 'LinuxFedora',
							os_license: null,
							prices: {
								'1': {
									hour: '0.00000',
									month: '0.00',
								},
								'2': {
									hour: '0.00000',
									month: '0.00',
								},
								'3': {
									hour: '0.00000',
									month: '0.00',
								},
								'493': {
									hour: '0.00000',
									month: '0.00',
								},
							},
							public: 1,
							sub_title: '',
							support_level: 'Self-Managed',
							title: '',
							value: 'FEDORA_32_SERVER_UNMANAGED',
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						{
							active: 1,
							capabilities: {
								serviceMonitoringSelfManaged: 1,
							},
							comments: null,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							deprecated: 0,
							description: 'Fedora 33 Server 64-bit Self-managed',
							discountable: 1,
							display_order: 0,
							featured: 0,
							id: 7629701,
							links: [],
							num_units: null,
							os: 'LinuxFedora',
							os_license: null,
							prices: {
								'1': {
									hour: '0.00000',
									month: '0.00',
								},
								'2': {
									hour: '0.00000',
									month: '0.00',
								},
								'3': {
									hour: '0.00000',
									month: '0.00',
								},
								'493': {
									hour: '0.00000',
									month: '0.00',
								},
							},
							public: 1,
							sub_title: '',
							support_level: 'Self-Managed',
							title: '',
							value: 'FEDORA_33_SERVER_UNMANAGED',
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						{
							active: 1,
							capabilities: {
								serviceMonitoringSelfManaged: 1,
							},
							comments: null,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							deprecated: 0,
							description: 'AlmaLinux 8 64-bit Self-managed',
							discountable: 1,
							display_order: 0,
							featured: 0,
							id: 7633517,
							links: [],
							num_units: null,
							os: 'LinuxCentOS',
							os_license: null,
							prices: {
								'1': {
									hour: '0.00000',
									month: '0.00',
								},
								'2': {
									hour: '0.00000',
									month: '0.00',
								},
								'3': {
									hour: '0.00000',
									month: '0.00',
								},
								'493': {
									hour: '0.00000',
									month: '0.00',
								},
							},
							public: 1,
							sub_title: '',
							support_level: 'Self-Managed',
							title: '',
							value: 'ALMALINUX_8_UNMANAGED',
							zone_availability: {
								'1': 1,
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
					],
				},
				{
					automated: 1,
					display_order: 3,
					display_text: 'Bandwidth',
					key: 'Bandwidth',
					option_key_id: '2050',
					public: 1,
					required: 1,
					values: [
						{
							active: 1,
							capabilities: {},
							comments: null,
							cycle: 'static-month',
							default: 1,
							default_price: '200.00000',
							description: '10000 GB (5000 in / 5000 out)',
							discountable: 1,
							display_order: 0,
							featured: 0,
							id: 129770,
							links: [],
							num_units: null,
							prices: {
								'1': {
									hour: '0.00000',
									month: '0.00',
								},
								'2': {
									hour: '0.00000',
									month: '0.00',
								},
								'3': {
									hour: '0.00000',
									month: '0.00',
								},
							},
							public: 1,
							sub_title: '',
							title: '',
							value: 'SS.5000',
						},
						{
							active: 1,
							capabilities: {},
							comments: null,
							cycle: 'static-month',
							default: 0,
							default_price: '50.00000',
							description: '6000 GB Monthly Transfer',
							discountable: 1,
							display_order: 1,
							featured: 0,
							id: 848044,
							links: [],
							num_units: null,
							prices: {
								'1': {
									hour: '0.06853',
									month: '50.00',
								},
								'2': {
									hour: '0.06853',
									month: '50.00',
								},
								'3': {
									hour: '0.06853',
									month: '50.00',
								},
							},
							public: 1,
							sub_title: '',
							title: '',
							value: 'SS.6000',
						},
						{
							active: 1,
							capabilities: {},
							comments: null,
							cycle: 'static-month',
							default: 0,
							default_price: '150.00000',
							description: '8000 GB Monthly Transfer',
							discountable: 1,
							display_order: 2,
							featured: 0,
							id: 848045,
							links: [],
							num_units: null,
							prices: {
								'1': {
									hour: '0.20559',
									month: '150.00',
								},
								'2': {
									hour: '0.20559',
									month: '150.00',
								},
								'3': {
									hour: '0.20559',
									month: '150.00',
								},
							},
							public: 1,
							sub_title: '',
							title: '',
							value: 'SS.8000',
						},
						{
							active: 1,
							capabilities: {},
							comments: null,
							cycle: 'static-month',
							default: 0,
							default_price: '250.00000',
							description: '10 TB Monthly Transfer',
							discountable: 1,
							display_order: 3,
							featured: 0,
							id: 848046,
							links: [],
							num_units: null,
							prices: {
								'1': {
									hour: '0.34265',
									month: '250.00',
								},
								'2': {
									hour: '0.34265',
									month: '250.00',
								},
								'3': {
									hour: '0.34265',
									month: '250.00',
								},
							},
							public: 1,
							sub_title: '',
							title: '',
							value: 'SS.10000',
						},
						{
							active: 1,
							capabilities: {},
							comments: null,
							cycle: 'static-month',
							default: 0,
							default_price: '450.00000',
							description: '15 TB Monthly Transfer',
							discountable: 1,
							display_order: 4,
							featured: 0,
							id: 848047,
							links: [],
							num_units: null,
							prices: {
								'1': {
									hour: '0.61678',
									month: '450.00',
								},
								'2': {
									hour: '0.61678',
									month: '450.00',
								},
								'3': {
									hour: '0.61678',
									month: '450.00',
								},
							},
							public: 1,
							sub_title: '',
							title: '',
							value: 'SS.15000',
						},
						{
							active: 1,
							capabilities: {},
							comments: null,
							cycle: 'static-month',
							default: 0,
							default_price: '750.00000',
							description: '20 TB Monthly Transfer',
							discountable: 1,
							display_order: 5,
							featured: 0,
							id: 848048,
							links: [],
							num_units: null,
							prices: {
								'1': {
									hour: '1.02796',
									month: '750.00',
								},
								'2': {
									hour: '1.02796',
									month: '750.00',
								},
								'3': {
									hour: '1.02796',
									month: '750.00',
								},
							},
							public: 1,
							sub_title: '',
							title: '',
							value: 'SS.20000',
						},
					],
				},
				{
					automated: 1,
					display_order: 4,
					display_text: 'Bandwidth Overage',
					key: 'BandwidthOverage',
					option_key_id: '29998',
					public: 1,
					required: 1,
					values: [
						{
							active: 1,
							capabilities: {},
							comments: null,
							cycle: 'nontemporal',
							default: 1,
							default_price: '0.25000',
							description: '%{units} GB Overage',
							discountable: 1,
							display_order: 1,
							featured: 0,
							id: 129771,
							links: [],
							num_units: -1,
							prices: {
								'1': {
									nontemporal: '0.25',
								},
								'2': {
									nontemporal: '0.25',
								},
								'3': {
									nontemporal: '0.25',
								},
								'493': {
									nontemporal: '0.25',
								},
							},
							public: 1,
							sub_title: '',
							title: '',
							value: '1GB',
						},
					],
				},
				{
					automated: 1,
					display_order: 5,
					display_text: 'Backup Plan',
					key: 'LiquidWebBackupPlan',
					option_key_id: '73979',
					public: 1,
					required: 1,
					values: [
						{
							active: 1,
							capabilities: {},
							comments: null,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							description: 'No Backups',
							discountable: 1,
							display_order: 1,
							featured: 0,
							id: 129773,
							links: [],
							num_units: null,
							prices: {
								'1': {
									hour: '0.00000',
									month: '0.00',
								},
								'2': {
									hour: '0.00000',
									month: '0.00',
								},
								'3': {
									hour: '0.00000',
									month: '0.00',
								},
								'493': {
									hour: '0.00000',
									month: '0.00',
								},
							},
							public: 1,
							sub_title: '',
							title: '',
							value: 'None',
						},
						{
							active: 1,
							capabilities: {},
							comments: null,
							cycle: 'month',
							default: 1,
							default_price: '0.00000',
							description: 'As-you-go Backups',
							discountable: 1,
							display_order: 2,
							featured: 0,
							id: 129774,
							links: [],
							num_units: null,
							options: [
								{
									automated: 1,
									display_order: 1,
									display_text: 'Backup Storage',
									key: 'BackupStorage',
									option_key_id: '4276',
									public: 1,
									required: 1,
									values: [
										{
											active: 1,
											capabilities: {},
											comments: null,
											cycle: 'hour',
											default: 1,
											default_price: '0.00016',
											description: '%{units} GB Hours',
											discountable: 1,
											display_order: 1,
											featured: 0,
											id: 129757,
											links: [],
											num_units: -1,
											prices: {
												'1': {
													hour: '0.00016',
													month: '0.12',
												},
												'2': {
													hour: '0.00016',
													month: '0.12',
												},
												'3': {
													hour: '0.00016',
													month: '0.12',
												},
											},
											public: 1,
											sub_title: '',
											title: '',
											value: '1GBHR',
										},
									],
								},
								{
									automated: 1,
									display_order: 2,
									display_text: 'Backup Days',
									key: 'BackupDay',
									option_key_id: '75634',
									public: 1,
									required: 1,
									values: [
										{
											active: 1,
											capabilities: {},
											comments: null,
											cycle: 'month',
											default: 1,
											default_price: '0.00000',
											description: '%{units} Retention Days',
											discountable: 1,
											display_order: 1,
											featured: 0,
											id: 133995,
											links: [],
											num_units: 0,
											prices: {
												'1': {
													hour: '0.00000',
													month: '0.00',
												},
												'2': {
													hour: '0.00000',
													month: '0.00',
												},
												'3': {
													hour: '0.00000',
													month: '0.00',
												},
											},
											public: 1,
											sub_title: '',
											title: '',
											value: '1',
										},
									],
								},
							],
							prices: {
								'1': {
									hour: '0.00000',
									month: '0.00',
								},
								'2': {
									hour: '0.00000',
									month: '0.00',
								},
								'3': {
									hour: '0.00000',
									month: '0.00',
								},
								'493': {
									hour: '0.00000',
									month: '0.00',
								},
							},
							public: 1,
							sub_title: '',
							title: '',
							value: 'Daily',
						},
						{
							active: 1,
							capabilities: {},
							comments: null,
							cycle: 'month',
							default: 0,
							default_price: '0.00000',
							description: 'Quota-based Backups',
							discountable: 1,
							display_order: 3,
							featured: 0,
							id: 129775,
							links: [],
							num_units: null,
							options: [
								{
									automated: 1,
									display_order: 1,
									display_text: 'Quota Pricing',
									key: 'BackupQuota',
									option_key_id: '4275',
									public: 1,
									required: 1,
									values: [
										{
											active: 1,
											capabilities: {},
											comments: null,
											cycle: 'month',
											default: 0,
											default_price: '0.00000',
											description: '50 GB',
											discountable: 1,
											display_order: 1,
											featured: 0,
											id: 5272589,
											links: [],
											num_units: null,
											prices: {
												'1': {
													hour: '0.00000',
													month: '0.00',
												},
												'2': {
													hour: '0.00000',
													month: '0.00',
												},
												'3': {
													hour: '0.00000',
													month: '0.00',
												},
												'493': {
													hour: '0.00000',
													month: '0.00',
												},
											},
											public: 1,
											sub_title: '50 GB',
											title: '50 GB',
											value: '50',
										},
										{
											active: 1,
											capabilities: {},
											comments: null,
											cycle: 'month',
											default: 1,
											default_price: '10.00000',
											description: '100 GB',
											discountable: 1,
											display_order: 5,
											featured: 0,
											id: 129752,
											links: [],
											num_units: null,
											prices: {
												'1': {
													hour: '0.01371',
													month: '10.00',
												},
												'2': {
													hour: '0.01371',
													month: '10.00',
												},
												'3': {
													hour: '0.01371',
													month: '10.00',
												},
											},
											public: 1,
											sub_title: '',
											title: '',
											value: '100',
										},
										{
											active: 1,
											capabilities: {},
											comments: null,
											cycle: 'month',
											default: 0,
											default_price: '23.00000',
											description: '250 GB',
											discountable: 1,
											display_order: 10,
											featured: 0,
											id: 129753,
											links: [],
											num_units: null,
											prices: {
												'1': {
													hour: '0.03152',
													month: '23.00',
												},
												'2': {
													hour: '0.03152',
													month: '23.00',
												},
												'3': {
													hour: '0.03152',
													month: '23.00',
												},
											},
											public: 1,
											sub_title: '',
											title: '',
											value: '250',
										},
										{
											active: 1,
											capabilities: {},
											comments: null,
											cycle: 'month',
											default: 0,
											default_price: '45.00000',
											description: '500 GB',
											discountable: 1,
											display_order: 15,
											featured: 0,
											id: 129754,
											links: [],
											num_units: null,
											prices: {
												'1': {
													hour: '0.06168',
													month: '45.00',
												},
												'2': {
													hour: '0.06168',
													month: '45.00',
												},
												'3': {
													hour: '0.06168',
													month: '45.00',
												},
											},
											public: 1,
											sub_title: '',
											title: '',
											value: '500',
										},
										{
											active: 1,
											capabilities: {},
											comments: null,
											cycle: 'month',
											default: 0,
											default_price: '70.00000',
											description: '1000 GB',
											discountable: 1,
											display_order: 20,
											featured: 0,
											id: 129755,
											links: [],
											num_units: null,
											prices: {
												'1': {
													hour: '0.09594',
													month: '70.00',
												},
												'2': {
													hour: '0.09594',
													month: '70.00',
												},
												'3': {
													hour: '0.09594',
													month: '70.00',
												},
											},
											public: 1,
											sub_title: '',
											title: '',
											value: '1000',
										},
										{
											active: 1,
											capabilities: {},
											comments: null,
											cycle: 'month',
											default: 0,
											default_price: '130.00000',
											description: '2000 GB',
											discountable: 1,
											display_order: 25,
											featured: 0,
											id: 129756,
											links: [],
											num_units: null,
											prices: {
												'1': {
													hour: '0.17818',
													month: '130.00',
												},
												'2': {
													hour: '0.17818',
													month: '130.00',
												},
												'3': {
													hour: '0.17818',
													month: '130.00',
												},
											},
											public: 1,
											sub_title: '',
											title: '',
											value: '2000',
										},
										{
											active: 1,
											capabilities: {},
											comments: null,
											cycle: 'month',
											default: 0,
											default_price: '0.00000',
											description: '20000 GB',
											discountable: 1,
											display_order: 30,
											featured: 0,
											id: 4512716,
											links: [],
											num_units: null,
											prices: {
												'1': {
													hour: '1.37061',
													month: '1000.00',
												},
												'2': {
													hour: '1.37061',
													month: '1000.00',
												},
												'3': {
													hour: '1.37061',
													month: '1000.00',
												},
												'493': {
													hour: '1.37061',
													month: '1000.00',
												},
											},
											public: 0,
											sub_title: '20000 GB',
											title: '20000 GB',
											value: '20000',
										},
									],
								},
							],
							prices: {
								'1': {
									hour: '0.00000',
									month: '0.00',
								},
								'2': {
									hour: '0.00000',
									month: '0.00',
								},
								'3': {
									hour: '0.00000',
									month: '0.00',
								},
								'493': {
									hour: '0.00000',
									month: '0.00',
								},
							},
							public: 1,
							sub_title: 'Quota-based Backups',
							title: 'Quota-based Backups',
							value: 'Quota',
						},
					],
				},
				{
					automated: 1,
					display_order: 6,
					display_text: 'Extra IP Addresses',
					key: 'ExtraIp',
					option_key_id: '9133',
					public: 1,
					required: 1,
					values: [
						{
							active: 1,
							capabilities: {},
							comments: null,
							cycle: 'month',
							default: 1,
							default_price: '2.00000',
							description: '%{units} Additional Public IPs',
							discountable: 1,
							display_order: 1,
							featured: 0,
							id: 129772,
							links: [],
							num_units: 0,
							prices: {
								'1': {
									hour: '0.00137',
									month: '1.00',
								},
								'2': {
									hour: '0.00137',
									month: '1.00',
								},
								'3': {
									hour: '0.00137',
									month: '1.00',
								},
								'493': {
									hour: '0.00137',
									month: '1.00',
								},
							},
							public: 1,
							sub_title: '',
							title: '',
							value: '1',
						},
					],
				},
				{
					automated: 0,
					display_order: 7,
					display_text: 'WiredTree Extra Ips',
					key: 'WiredTreeIp',
					option_key_id: '1327824',
					public: 0,
					required: 0,
					values: [
						{
							active: 1,
							capabilities: {},
							comments: null,
							cycle: 'month',
							default: 1,
							default_price: '0.00000',
							description: 'Included WiredTree Ips',
							discountable: 1,
							display_order: 0,
							featured: 0,
							id: 3999274,
							links: [],
							num_units: null,
							prices: {
								'1': {
									hour: '0.00000',
									month: '0.00',
								},
								'2': {
									hour: '0.00000',
									month: '0.00',
								},
								'3': {
									hour: '0.00000',
									month: '0.00',
								},
								'493': {
									hour: '0.00000',
									month: '0.00',
								},
							},
							public: 1,
							sub_title: '',
							title: '',
							value: '0',
						},
					],
				},
				{
					automated: 0,
					display_order: 8,
					display_text: 'Legacy WiredTree Discount',
					key: 'LegacyWiredTreeDiscount',
					option_key_id: '1354659',
					public: 0,
					required: 0,
					values: [
						{
							active: 1,
							capabilities: {},
							comments: null,
							cycle: 'month',
							default: 1,
							default_price: '0.00000',
							description: 'No legacy discount given at WiredTree',
							discountable: 1,
							display_order: 0,
							featured: 0,
							id: 3999275,
							links: [],
							num_units: null,
							prices: {
								'1': {
									hour: '0.00000',
									month: '0.00',
								},
								'2': {
									hour: '0.00000',
									month: '0.00',
								},
								'3': {
									hour: '0.00000',
									month: '0.00',
								},
								'493': {
									hour: '0.00000',
									month: '0.00',
								},
							},
							public: 1,
							sub_title: '',
							title: '',
							value: '0',
						},
					],
				},
			],
			parent_product_id: null,
			prices: {
				'1': {
					hour: '0.00000',
					month: '0.00',
				},
				'2': {
					hour: '0.00000',
					month: '0.00',
				},
				'3': {
					hour: '0.00000',
					month: '0.00',
				},
			},
			series: [null],
			sub_title: '',
			tax_code: 'SW050400',
			title: '',
		},
		series: [null],
		sub_title: '',
		tax_code: 'SW050400',
		title: '',
	},
	AcronisBackup: {
		alias: null,
		ancestorCode: 'AcronisBackup',
		capabilities: {
			OrderRoutingServiceDelivery: 1,
			locationable: 1,
			noIP: 1,
			noServer: 1,
			noUsername: 1,
			serviceMonitoring: 1,
			serviceMonitoringAcronis: 1,
		},
		categories: ['RemoteBackup'],
		code: 'AcronisBackup',
		credit_eligible: 1,
		cycle: 'month',
		default_price: '0.00000',
		description: 'Acronis Cyber Backups',
		discountable: 1,
		features: [],
		option_value_features: {},
		options: [
			{
				automated: 0,
				display_order: 10,
				display_text: 'Backup Storage Destination',
				key: 'AcronisStorageDestination',
				option_key_id: '2659044',
				public: 1,
				required: 1,
				values: [
					{
						capabilities: {
							acronisStorageDestinationLiquidWeb: 1,
						},
						comments: null,
						cycle: 'month',
						default: 1,
						default_price: '0.00000',
						description: 'Liquid Web',
						discountable: 1,
						display_order: 10,
						featured: 0,
						links: [],
						options: [
							{
								automated: 0,
								display_order: 1,
								display_text: 'Storage Quota',
								key: 'AcronisStorageQuotaLiquidWeb',
								option_key_id: '2659038',
								public: 1,
								required: 1,
								values: [
									{
										capabilities: {},
										comments: null,
										cycle: 'month',
										default: 1,
										default_price: '0.00000',
										description: '100 GB',
										discountable: 1,
										display_order: 10,
										featured: 0,
										links: [],
										prices: {
											'1': {
												hour: '0.01371',
												month: '10.00',
											},
											'2': {
												hour: '0.01508',
												month: '11.00',
											},
											'3': {
												hour: '0.01645',
												month: '12.00',
											},
										},
										sub_title: '',
										title: '',
										value: '100',
									},
									{
										capabilities: {},
										comments: null,
										cycle: 'month',
										default: 0,
										default_price: '0.00000',
										description: '250 GB',
										discountable: 1,
										display_order: 20,
										featured: 0,
										links: [],
										prices: {
											'1': {
												hour: '0.02741',
												month: '20.00',
											},
											'2': {
												hour: '0.02878',
												month: '21.00',
											},
											'3': {
												hour: '0.03015',
												month: '22.00',
											},
										},
										sub_title: '',
										title: '',
										value: '250',
									},
									{
										capabilities: {},
										comments: null,
										cycle: 'month',
										default: 0,
										default_price: '0.00000',
										description: '500 GB',
										discountable: 1,
										display_order: 30,
										featured: 0,
										links: [],
										prices: {
											'1': {
												hour: '0.04112',
												month: '30.00',
											},
											'2': {
												hour: '0.04249',
												month: '31.00',
											},
											'3': {
												hour: '0.04386',
												month: '32.00',
											},
										},
										sub_title: '',
										title: '',
										value: '500',
									},
									{
										capabilities: {},
										comments: null,
										cycle: 'month',
										default: 0,
										default_price: '0.00000',
										description: '750 GB',
										discountable: 1,
										display_order: 40,
										featured: 0,
										links: [],
										prices: {
											'1': {
												hour: '0.05482',
												month: '40.00',
											},
											'2': {
												hour: '0.05620',
												month: '41.00',
											},
											'3': {
												hour: '0.05757',
												month: '42.00',
											},
										},
										sub_title: '',
										title: '',
										value: '750',
									},
									{
										capabilities: {},
										comments: null,
										cycle: 'month',
										default: 0,
										default_price: '0.00000',
										description: '1 TB',
										discountable: 1,
										display_order: 50,
										featured: 0,
										links: [],
										prices: {
											'1': {
												hour: '0.06853',
												month: '50.00',
											},
											'2': {
												hour: '0.06990',
												month: '51.00',
											},
											'3': {
												hour: '0.07127',
												month: '52.00',
											},
										},
										sub_title: '',
										title: '',
										value: '1000',
									},
								],
							},
						],
						prices: {
							'1': {
								hour: '0.00000',
								month: '0.00',
							},
							'2': {
								hour: '0.00000',
								month: '0.00',
							},
							'3': {
								hour: '0.00000',
								month: '0.00',
							},
							'493': {
								hour: '0.00000',
								month: '0.00',
							},
						},
						sub_title: '',
						title: '',
						value: 'AcronisLiquidWebStorage',
					},
					{
						capabilities: {
							acronisStorageDestinationAcronis: 1,
						},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						description: 'Acronis Cloud',
						discountable: 1,
						display_order: 20,
						featured: 0,
						links: [],
						options: [
							{
								automated: 0,
								display_order: 1,
								display_text: 'Storage Quota',
								key: 'AcronisStorageQuotaCloud',
								option_key_id: '2659039',
								public: 1,
								required: 1,
								values: [
									{
										capabilities: {},
										comments: null,
										cycle: 'month',
										default: 1,
										default_price: '0.00000',
										description: '100 GB',
										discountable: 1,
										display_order: 10,
										featured: 0,
										links: [],
										prices: {
											'1': {
												hour: '0.02056',
												month: '15.00',
											},
											'2': {
												hour: '0.02193',
												month: '16.00',
											},
											'3': {
												hour: '0.02330',
												month: '17.00',
											},
										},
										sub_title: '',
										title: '',
										value: '100',
									},
									{
										capabilities: {},
										comments: null,
										cycle: 'month',
										default: 0,
										default_price: '0.00000',
										description: '250 GB',
										discountable: 1,
										display_order: 20,
										featured: 0,
										links: [],
										prices: {
											'1': {
												hour: '0.03427',
												month: '25.00',
											},
											'2': {
												hour: '0.03564',
												month: '26.00',
											},
											'3': {
												hour: '0.03701',
												month: '27.00',
											},
										},
										sub_title: '',
										title: '',
										value: '250',
									},
									{
										capabilities: {},
										comments: null,
										cycle: 'month',
										default: 0,
										default_price: '0.00000',
										description: '500 GB',
										discountable: 1,
										display_order: 30,
										featured: 0,
										links: [],
										prices: {
											'1': {
												hour: '0.04797',
												month: '35.00',
											},
											'2': {
												hour: '0.04934',
												month: '36.00',
											},
											'3': {
												hour: '0.05071',
												month: '37.00',
											},
										},
										sub_title: '',
										title: '',
										value: '500',
									},
									{
										capabilities: {},
										comments: null,
										cycle: 'month',
										default: 0,
										default_price: '0.00000',
										description: '750 GB',
										discountable: 1,
										display_order: 40,
										featured: 0,
										links: [],
										prices: {
											'1': {
												hour: '0.06168',
												month: '45.00',
											},
											'2': {
												hour: '0.06305',
												month: '46.00',
											},
											'3': {
												hour: '0.06442',
												month: '47.00',
											},
										},
										sub_title: '',
										title: '',
										value: '750',
									},
									{
										capabilities: {},
										comments: null,
										cycle: 'month',
										default: 0,
										default_price: '0.00000',
										description: '1 TB',
										discountable: 1,
										display_order: 50,
										featured: 0,
										links: [],
										prices: {
											'1': {
												hour: '0.07538',
												month: '55.00',
											},
											'2': {
												hour: '0.07675',
												month: '56.00',
											},
											'3': {
												hour: '0.07813',
												month: '57.00',
											},
										},
										sub_title: '',
										title: '',
										value: '1000',
									},
									{
										capabilities: {},
										comments: null,
										cycle: 'month',
										default: 0,
										default_price: '0.00000',
										description: '1.5 TB',
										discountable: 1,
										display_order: 60,
										featured: 0,
										links: [],
										prices: {
											'1': {
												hour: '0.08224',
												month: '60.00',
											},
											'2': {
												hour: '0.08361',
												month: '61.00',
											},
											'3': {
												hour: '0.08498',
												month: '62.00',
											},
										},
										sub_title: '1.5 TB',
										title: '1.5 TB',
										value: '1500',
									},
									{
										capabilities: {},
										comments: null,
										cycle: 'month',
										default: 0,
										default_price: '0.00000',
										description: '2 TB',
										discountable: 1,
										display_order: 70,
										featured: 0,
										links: [],
										prices: {
											'1': {
												hour: '0.08909',
												month: '65.00',
											},
											'2': {
												hour: '0.09046',
												month: '66.00',
											},
											'3': {
												hour: '0.09183',
												month: '67.00',
											},
										},
										sub_title: '2 TB',
										title: '2 TB',
										value: '2000',
									},
									{
										capabilities: {},
										comments: null,
										cycle: 'month',
										default: 0,
										default_price: '0.00000',
										description: '2.5 TB',
										discountable: 1,
										display_order: 80,
										featured: 0,
										links: [],
										prices: {
											'1': {
												hour: '0.09594',
												month: '70.00',
											},
											'2': {
												hour: '0.09731',
												month: '71.00',
											},
											'3': {
												hour: '0.09868',
												month: '72.00',
											},
										},
										sub_title: '2.5 TB',
										title: '2.5 TB',
										value: '2500',
									},
								],
							},
						],
						prices: {
							'1': {
								hour: '0.00000',
								month: '0.00',
							},
							'2': {
								hour: '0.00000',
								month: '0.00',
							},
							'3': {
								hour: '0.00000',
								month: '0.00',
							},
							'493': {
								hour: '0.00000',
								month: '0.00',
							},
						},
						sub_title: '',
						title: '',
						value: 'AcronisCloudStorage',
					},
				],
			},
		],
		postConfigureInterstitialUri: null,
		prices: {
			'1': {
				hour: '0.01371',
				month: '10.00',
			},
			'2': {
				hour: '0.01508',
				month: '11.00',
			},
			'3': {
				hour: '0.01645',
				month: '12.00',
			},
			'493': {
				hour: '0.01782',
				month: '13.00',
			},
		},
		purchaseQuantityLimit: null,
		series: [null],
		sub_title: '',
		tax_code: null,
		title: '',
	},
	LiquidWebImageStorage: {
		alias: null,
		ancestorCode: 'LiquidWebImageStorage',
		capabilities: {},
		categories: ['AccountLevel'],
		code: 'LiquidWebImageStorage',
		credit_eligible: 1,
		cycle: 'month',
		default_price: '0.00000',
		description: 'Server Image Storage',
		discountable: 1,
		features: [],
		option_value_features: {},
		options: [
			{
				automated: 1,
				display_order: 1,
				display_text: 'Server Image Storage',
				key: 'ImageStorage',
				option_key_id: '30801',
				public: 1,
				required: 1,
				values: [
					{
						capabilities: {},
						comments: null,
						cycle: 'month',
						default: 1,
						default_price: '0.25000',
						description: '%{units} GB Months',
						discountable: 1,
						display_order: 1,
						featured: 0,
						links: [],
						prices: {
							'1': {
								hour: '0.00034',
								month: '0.25',
							},
							'3': {
								hour: '0.00034',
								month: '0.25',
							},
						},
						sub_title: '',
						title: '',
						usage_based: 1,
						value: '1GBMO',
					},
				],
			},
		],
		postConfigureInterstitialUri: null,
		prices: {
			'1': {
				hour: '0.00000',
				month: '0.00',
			},
			'3': {
				hour: '0.00000',
				month: '0.00',
			},
		},
		purchaseQuantityLimit: null,
		series: [null],
		sub_title: '',
		tax_code: null,
		title: '',
	},
	'Acronis.VPS': {
		alias: null,
		ancestorCode: 'AcronisBackup',
		capabilities: {
			OrderRoutingServiceDelivery: 1,
			locationable: 1,
			noIP: 1,
			noServer: 1,
			noUsername: 1,
			serviceMonitoring: 1,
			serviceMonitoringAcronis: 1,
		},
		categories: ['RemoteBackup'],
		code: 'Acronis.VPS',
		credit_eligible: 1,
		cycle: 'month',
		default_price: '0.00000',
		description: 'Acronis Cyber Backups',
		discountable: 1,
		features: [],
		option_value_features: {},
		options: [
			{
				automated: 0,
				display_order: 10,
				display_text: 'Backup Storage Destination',
				key: 'AcronisStorageDestination',
				option_key_id: '2659044',
				public: 1,
				required: 1,
				values: [
					{
						capabilities: {},
						comments: null,
						cycle: 'month',
						default: 1,
						default_price: '0.00000',
						description: 'Liquid Web',
						discountable: 1,
						display_order: 0,
						featured: 0,
						links: [],
						options: [
							{
								automated: 0,
								display_order: 1,
								display_text: 'Storage Quota',
								key: 'AcronisStorageQuotaLiquidWeb',
								option_key_id: '2659038',
								public: 1,
								required: 1,
								values: [
									{
										capabilities: {},
										comments: null,
										cycle: 'month',
										default: 1,
										default_price: '0.00000',
										description: '100 GB',
										discountable: 1,
										display_order: 10,
										featured: 0,
										links: [],
										prices: {
											'1': {
												hour: '0.00959',
												month: '7.00',
											},
											'2': {
												hour: '0.00959',
												month: '7.00',
											},
											'3': {
												hour: '0.00959',
												month: '7.00',
											},
										},
										sub_title: '',
										title: '',
										value: '100',
									},
									{
										capabilities: {},
										comments: null,
										cycle: 'month',
										default: 0,
										default_price: '0.00000',
										description: '250 GB',
										discountable: 1,
										display_order: 20,
										featured: 0,
										links: [],
										prices: {
											'1': {
												hour: '0.02056',
												month: '15.00',
											},
											'2': {
												hour: '0.02056',
												month: '15.00',
											},
											'3': {
												hour: '0.02056',
												month: '15.00',
											},
										},
										sub_title: '',
										title: '',
										value: '250',
									},
									{
										capabilities: {},
										comments: null,
										cycle: 'month',
										default: 0,
										default_price: '0.00000',
										description: '500 GB',
										discountable: 1,
										display_order: 30,
										featured: 0,
										links: [],
										prices: {
											'1': {
												hour: '0.04112',
												month: '30.00',
											},
											'2': {
												hour: '0.04112',
												month: '30.00',
											},
											'3': {
												hour: '0.04112',
												month: '30.00',
											},
										},
										sub_title: '',
										title: '',
										value: '500',
									},
									{
										capabilities: {},
										comments: null,
										cycle: 'month',
										default: 0,
										default_price: '0.00000',
										description: '750 GB',
										discountable: 1,
										display_order: 40,
										featured: 0,
										links: [],
										prices: {
											'1': {
												hour: '0.05757',
												month: '42.00',
											},
											'2': {
												hour: '0.05757',
												month: '42.00',
											},
											'3': {
												hour: '0.05757',
												month: '42.00',
											},
										},
										sub_title: '',
										title: '',
										value: '750',
									},
									{
										capabilities: {},
										comments: null,
										cycle: 'month',
										default: 0,
										default_price: '0.00000',
										description: '1 TB',
										discountable: 1,
										display_order: 50,
										featured: 0,
										links: [],
										prices: {
											'1': {
												hour: '0.07538',
												month: '55.00',
											},
											'2': {
												hour: '0.07538',
												month: '55.00',
											},
											'3': {
												hour: '0.07538',
												month: '55.00',
											},
										},
										sub_title: '',
										title: '',
										value: '1000',
									},
									{
										capabilities: {},
										comments: null,
										cycle: 'month',
										default: 0,
										default_price: '0.00000',
										description: '1.5 TB',
										discountable: 1,
										display_order: 55,
										featured: 0,
										links: [],
										prices: {
											'1': {
												hour: '0.10280',
												month: '75.00',
											},
											'2': {
												hour: '0.10280',
												month: '75.00',
											},
											'3': {
												hour: '0.10280',
												month: '75.00',
											},
										},
										sub_title: '1.5 TB',
										title: '1.5 TB',
										value: '1500',
									},
									{
										capabilities: {},
										comments: null,
										cycle: 'month',
										default: 0,
										default_price: '0.00000',
										description: '2 TB',
										discountable: 1,
										display_order: 60,
										featured: 0,
										links: [],
										prices: {
											'1': {
												hour: '0.13706',
												month: '100.00',
											},
											'2': {
												hour: '0.13706',
												month: '100.00',
											},
											'3': {
												hour: '0.13706',
												month: '100.00',
											},
										},
										sub_title: '2 TB',
										title: '2 TB',
										value: '2000',
									},
									{
										capabilities: {},
										comments: null,
										cycle: 'month',
										default: 0,
										default_price: '0.00000',
										description: '2.5 TB',
										discountable: 1,
										display_order: 65,
										featured: 0,
										links: [],
										prices: {
											'1': {
												hour: '0.17133',
												month: '125.00',
											},
											'2': {
												hour: '0.17133',
												month: '125.00',
											},
											'3': {
												hour: '0.17133',
												month: '125.00',
											},
										},
										sub_title: '2.5 TB',
										title: '2.5 TB',
										value: '2500',
									},
								],
							},
						],
						prices: {
							'1': {
								hour: '0.00000',
								month: '0.00',
							},
							'2': {
								hour: '0.00000',
								month: '0.00',
							},
							'3': {
								hour: '0.00000',
								month: '0.00',
							},
							'493': {
								hour: '0.00000',
								month: '0.00',
							},
						},
						sub_title: 'Liquid Web',
						title: 'Liquid Web',
						value: 'AcronisLiquidWebStorage.VPS',
					},
					{
						capabilities: {},
						comments: null,
						cycle: 'month',
						default: 0,
						default_price: '0.00000',
						description: 'Acronis Cloud',
						discountable: 1,
						display_order: 5,
						featured: 0,
						links: [],
						options: [
							{
								automated: 0,
								display_order: 1,
								display_text: 'Storage Quota',
								key: 'AcronisStorageQuotaCloud',
								option_key_id: '2659039',
								public: 1,
								required: 1,
								values: [
									{
										capabilities: {},
										comments: null,
										cycle: 'month',
										default: 1,
										default_price: '0.00000',
										description: '100 GB',
										discountable: 1,
										display_order: 10,
										featured: 0,
										links: [],
										prices: {
											'1': {
												hour: '0.01645',
												month: '12.00',
											},
											'2': {
												hour: '0.01645',
												month: '12.00',
											},
											'3': {
												hour: '0.01645',
												month: '12.00',
											},
										},
										sub_title: '',
										title: '',
										value: '100',
									},
									{
										capabilities: {},
										comments: null,
										cycle: 'month',
										default: 0,
										default_price: '0.00000',
										description: '250 GB',
										discountable: 1,
										display_order: 20,
										featured: 0,
										links: [],
										prices: {
											'1': {
												hour: '0.04112',
												month: '30.00',
											},
											'2': {
												hour: '0.04112',
												month: '30.00',
											},
											'3': {
												hour: '0.04112',
												month: '30.00',
											},
										},
										sub_title: '',
										title: '',
										value: '250',
									},
									{
										capabilities: {},
										comments: null,
										cycle: 'month',
										default: 0,
										default_price: '0.00000',
										description: '500 GB',
										discountable: 1,
										display_order: 30,
										featured: 0,
										links: [],
										prices: {
											'1': {
												hour: '0.08224',
												month: '60.00',
											},
											'2': {
												hour: '0.08224',
												month: '60.00',
											},
											'3': {
												hour: '0.08224',
												month: '60.00',
											},
										},
										sub_title: '',
										title: '',
										value: '500',
									},
									{
										capabilities: {},
										comments: null,
										cycle: 'month',
										default: 0,
										default_price: '0.00000',
										description: '750 GB',
										discountable: 1,
										display_order: 40,
										featured: 0,
										links: [],
										prices: {
											'1': {
												hour: '0.12336',
												month: '90.00',
											},
											'2': {
												hour: '0.12336',
												month: '90.00',
											},
											'3': {
												hour: '0.12336',
												month: '90.00',
											},
										},
										sub_title: '',
										title: '',
										value: '750',
									},
									{
										capabilities: {},
										comments: null,
										cycle: 'month',
										default: 0,
										default_price: '0.00000',
										description: '1 TB',
										discountable: 1,
										display_order: 50,
										featured: 0,
										links: [],
										prices: {
											'1': {
												hour: '0.16447',
												month: '120.00',
											},
											'2': {
												hour: '0.16447',
												month: '120.00',
											},
											'3': {
												hour: '0.16447',
												month: '120.00',
											},
										},
										sub_title: '',
										title: '',
										value: '1000',
									},
									{
										capabilities: {},
										comments: null,
										cycle: 'month',
										default: 0,
										default_price: '0.00000',
										description: '1.5 TB',
										discountable: 1,
										display_order: 60,
										featured: 0,
										links: [],
										prices: {
											'1': {
												hour: '0.22615',
												month: '165.00',
											},
											'2': {
												hour: '0.22615',
												month: '165.00',
											},
											'3': {
												hour: '0.22615',
												month: '165.00',
											},
										},
										sub_title: '1.5 TB',
										title: '1.5 TB',
										value: '1500',
									},
									{
										capabilities: {},
										comments: null,
										cycle: 'month',
										default: 0,
										default_price: '0.00000',
										description: '2 TB',
										discountable: 1,
										display_order: 70,
										featured: 0,
										links: [],
										prices: {
											'1': {
												hour: '0.30154',
												month: '220.00',
											},
											'2': {
												hour: '0.30154',
												month: '220.00',
											},
											'3': {
												hour: '0.30154',
												month: '220.00',
											},
										},
										sub_title: '2 TB',
										title: '2 TB',
										value: '2000',
									},
									{
										capabilities: {},
										comments: null,
										cycle: 'month',
										default: 0,
										default_price: '0.00000',
										description: '2.5 TB',
										discountable: 1,
										display_order: 80,
										featured: 0,
										links: [],
										prices: {
											'1': {
												hour: '0.37692',
												month: '275.00',
											},
											'2': {
												hour: '0.37692',
												month: '275.00',
											},
											'3': {
												hour: '0.37692',
												month: '275.00',
											},
										},
										sub_title: '2.5 TB',
										title: '2.5 TB',
										value: '2500',
									},
								],
							},
						],
						prices: {
							'1': {
								hour: '0.00000',
								month: '0.00',
							},
							'2': {
								hour: '0.00000',
								month: '0.00',
							},
							'3': {
								hour: '0.00000',
								month: '0.00',
							},
							'493': {
								hour: '0.00000',
								month: '0.00',
							},
						},
						sub_title: 'Acronis Cloud',
						title: 'Acronis Cloud',
						value: 'AcronisCloudStorage.VPS',
					},
				],
			},
		],
		parent_product: {
			alias: null,
			code: 'AcronisBackup',
		},
		postConfigureInterstitialUri: null,
		prices: {
			'1': {
				hour: '0.00685',
				month: '5.00',
			},
			'2': {
				hour: '0.00685',
				month: '5.00',
			},
			'3': {
				hour: '0.00685',
				month: '5.00',
			},
			'493': {
				hour: '0.00685',
				month: '5.00',
			},
		},
		purchaseQuantityLimit: null,
		series: [null],
		sub_title: '',
		tax_code: null,
		title: '',
	},
};

export default standard;
