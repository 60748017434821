import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// redux
import { Field, change } from 'redux-form';
import connectAndWrap from 'utility/form/connectAndWrap';
import acronisSelectors, {
	formName,
} from 'modules/api/basket/item/detailsModule/acronisSelectors';

// MUI components
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

// custom components
import LWSelect from 'components/atoms/LWSelect';
import LWRadio from 'components/atoms/LWRadio';
import LWTypography from 'components/common/LWTypography';
import ProductOptionSet from 'components/ProductOptionSet';
import LWTooltip from 'components/common/LWTooltip';
import PaperBox from 'components/molecules/PaperBox';
import Verbiage from './Verbiage';

const lwLabel = 'Liquid Web (Off-Server)';
const cloudLabel = 'Acronis Cloud (Off-Server & Off-Site)';
const noOptionsLabel = 'No options available';
const toolTipText =
	'Liquid Web stores your backups in a Liquid Web datacenter. Acronis Cloud stores your backups in an Acronis datacenter. To change your storage location in the future, please contact Support.';

// input is passed in by magic from redux-forms.
const StoredOnRadio = ({ input }) => (
	<RadioGroup value={input.value} onChange={input.onChange}>
		<Grid container direction="row" spacing={4}>
			<Grid item>
				<FormControlLabel
					value="lw"
					control={
						<LWRadio color="primary" $isChecked={input.value === 'lw'} />
					}
					label={lwLabel}
				/>
			</Grid>
			<Grid item>
				<FormControlLabel
					value="cloud"
					control={
						<LWRadio color="primary" $isChecked={input.value === 'cloud'} />
					}
					label={cloudLabel}
				/>
			</Grid>
		</Grid>
	</RadioGroup>
);

const Options = ({ cloudAvailable, lwAvailable }) => {
	if (cloudAvailable && lwAvailable)
		return (
			<>
				<Field name="storedOn" component={StoredOnRadio} />
				<LWTooltip>{toolTipText}</LWTooltip>
			</>
		);

	return (
		<LWTypography bold data-testid="Acronis__StorageLocationLabel">
			{(() => {
				if (cloudAvailable) return cloudLabel;
				if (lwAvailable) return lwLabel;
				return noOptionsLabel;
			})()}
		</LWTypography>
	);
};

const Acronis = ({
	isLoading,
	setConfig,
	defaultValue, // from connected
	visibleOptions, // from connected
	selectedValue, // from connected
	backupsPlan, // from connected
	setSelectedValue, // from connected
	cloudAvailable, // from connected
	lwAvailable, // from connected
}) => {
	// Allows Product option set to know what the user just click so the UI can display the loading indicated in the correct tile.
	const [justSelected, setJustSelected] = useState('');

	const localSetConfig = (value) => {
		setConfig({ value });
		setJustSelected(value);
		setSelectedValue(value);
	};

	const setDropdown = (value) => {
		if (value !== 'No_Acronis') {
			localSetConfig(defaultValue);
		} else {
			localSetConfig('No_Acronis'); // No_Acronis
		}
	};

	useEffect(() => {
		if (!isLoading) setJustSelected('');
	}, [isLoading]);

	const setSelectedTile = ({ value }) => {
		localSetConfig(value);
	};

	return (
		<Box py={3}>
			<Grid container direction="column" spacing={4}>
				<Grid item>
					{/* The "Acronis Backups" value for this field is dynamically set to the selected product option.
                This way, when the selected tile changes, the dropdown can then toggle between the two configs of "No_Acronis"
                and whatever the user had last selected. */}
					<Box pl={3}>
						<Field
							name="backupsPlan"
							label="Backups Plan"
							options={[
								{
									label: 'No Backups',
									value: 'No_Acronis',
								},
								{
									label: 'Acronis Backups',
									value: 'Acronis',
								}, // selectedValue is the fallback during initialization.
							].filter(({ value }) => value)} // avoid the warnings when lastValue has not yet been initialized.
							onChange={setDropdown}
							component={LWSelect}
							isLoading={isLoading}
							data-testid="Acronis__ToggleDropdown"
						/>
					</Box>
				</Grid>
				{backupsPlan && backupsPlan !== 'No_Acronis' && (
					<Grid item>
						<Box pl={3}>
							<PaperBox>
								<Grid container direction="column" spacing={3}>
									<Grid item>
										{/* "Acronis Options...Liquid Web backup and storage solutions..." */}
										<Verbiage />
									</Grid>
									<Grid item>
										<Box display="flex" alignItems="center">
											<LWTypography
												BoxProps={{ pr: cloudAvailable && lwAvailable ? 3 : 1 }}
											>
												Store backups on:
											</LWTypography>
											<Options {...{ cloudAvailable, lwAvailable }} />
										</Box>
									</Grid>
									<Grid item>
										<ProductOptionSet
											isLoading={isLoading}
											selected={justSelected || selectedValue}
											handleSelect={setSelectedTile}
											options={visibleOptions}
										/>
									</Grid>
								</Grid>
							</PaperBox>
						</Box>
					</Grid>
				)}
			</Grid>
		</Box>
	);
};

Acronis.propTypes = {
	/** See ProductOptionSet docs. */
	options: PropTypes.array,
	/** The current value of the Acronis config */
	selectedValue: PropTypes.string,
	/** The form value that is set onChange for the backupsPlan dropdown. */
	backupsPlan: PropTypes.string,
	/** the initial redux-form values passed in by magic. */
	initialValue: PropTypes.shape({
		backupsPlan: PropTypes.string,
		storedOn: PropTypes.oneOf(['lw', 'cloud']),
	}),
	/** Passed down from ProductOptionDisplay */
	setConfig: PropTypes.func.isRequired,
	isLoading: PropTypes.bool,
};

const mapStateToProps = (state) => ({
	defaultValue: acronisSelectors.defaultValue(state),
	visibleOptions: acronisSelectors.productTileData(state),
	selectedValue: acronisSelectors.selectedValue(state),
	backupsPlan: acronisSelectors.formValues(state)?.backupsPlan,
	cloudAvailable: acronisSelectors.cloudAvailable(state),
	lwAvailable: acronisSelectors.lwAvailable(state),
	initialValues: {
		backupsPlan:
			acronisSelectors.selectedValue(state) !== 'No_Acronis'
				? 'Acronis'
				: 'No_Acronis',
		storedOn: acronisSelectors.selectedStoreBackupsOn(state),
	},
});

const mapDispatchToProps = (dispatch) => ({
	setSelectedValue: (value) =>
		dispatch(
			change(
				formName,
				'backupsPlan',
				value !== 'No_Acronis' ? 'Acronis' : 'No_Acronis',
			),
		),
});

const ConnectedBackupOptions = connectAndWrap({
	mapStateToProps,
	mapDispatchToProps,
	form: formName,
})(Acronis);

export { lwLabel, cloudLabel, noOptionsLabel, toolTipText };
export default ConnectedBackupOptions;
