import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Redirect } from 'react-router-dom';
import SubNavRoutes from 'components/routers/SubNavRoutes';
import asyncComponent from 'components/AsyncComponent';
import { roles } from 'utility/constants/roles';
import RoleRestrictedRoute from 'utility/routing/RoleRestrictedRoute';
import ServerGlobalComponents from 'components/pages/server/ServerGlobalComponents';

const ServerResize = asyncComponent(() =>
	import('containers/pages/servers/Resize'),
);

const BackupsAndImages = asyncComponent(() =>
	import('containers/pages/servers/BackupsAndImages'),
);

const Settings = asyncComponent(() =>
	import('containers/pages/servers/BackupsAndImages/cloud/Settings'),
);

const SupportAccess = asyncComponent(() =>
	import('containers/pages/servers/SupportAccess'),
);

const Bandwidth = asyncComponent(() =>
	import('containers/pages/servers/Bandwidth'),
);

const DestroyServer = asyncComponent(() =>
	import('containers/pages/servers/DestroyServer'),
);

const ChangeCpanelLicence = asyncComponent(() =>
	import('containers/pages/servers/ChangeCpanelLicence'),
);

const HostingDetails = asyncComponent(() =>
	import('containers/pages/servers/details/HostingDetails'),
);

const ServerReboot = asyncComponent(() =>
	import('containers/pages/servers/details/ServerReboot'),
);

const ScheduledTasks = asyncComponent(() =>
	import('containers/pages/servers/ScheduledTasks'),
);

const PublicIP = asyncComponent(() =>
	import('containers/pages/servers/PublicIP'),
);

const Firewall = asyncComponent(() =>
	import('containers/pages/servers/Firewall'),
);

const ServersRouter = (props) => {
	const { navData } = props;

	return (
		<React.Fragment>
			<ServerGlobalComponents />
			<Switch>
				<RoleRestrictedRoute
					exact
					path={navData.get('path')}
					component={navData.get('component')}
					whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
				/>
				<RoleRestrictedRoute
					path={`${navData.get('path')}/:serverType/:uniqId/acronis/add`}
					component={({ match }) => (
						<Redirect
							to={`${navData.get('path')}/${match.params.serverType}/${
								match.params.uniqId
							}/backups/settings?defaultSelection=Acronis`}
						/>
					)}
					whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
				/>
				<RoleRestrictedRoute
					path={`${navData.get('path')}/images`}
					component={BackupsAndImages}
					whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
					parent={navData}
				/>
				<RoleRestrictedRoute
					path={`${navData.get('path')}/:serverType/:uniqId/backups/settings`}
					component={Settings}
					whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
					parent={navData}
				/>
				<RoleRestrictedRoute
					path={`${navData.get('path')}/:serverType/:uniqId/backups`}
					component={BackupsAndImages}
					whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
					parent={navData}
				/>
				<RoleRestrictedRoute
					path={`${navData.get('path')}/:serverType/:uniqId/acronis`}
					component={BackupsAndImages}
					whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
					parent={navData}
				/>
				<RoleRestrictedRoute
					path={`${navData.get('path')}/:serverType/:uniqId/images`}
					component={BackupsAndImages}
					whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
					parent={navData}
				/>
				<RoleRestrictedRoute
					path={`${navData.get('path')}/:serverType/:uniqId/templates`}
					component={BackupsAndImages}
					whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
					parent={navData}
				/>
				<RoleRestrictedRoute
					exact
					path={`${navData.get('path')}/:serverType/:uniqId`}
					component={HostingDetails}
					whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
				/>
				<RoleRestrictedRoute
					path={`${navData.get('path')}/:serverType/:uniqId/scheduled-tasks`}
					component={ScheduledTasks}
					whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
				/>
				<RoleRestrictedRoute
					path={`${navData.get('path')}/scheduled-tasks`}
					component={ScheduledTasks}
					whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
				/>
				<RoleRestrictedRoute
					exact
					path={`${navData.get(
						'path',
					)}/:serverType/:uniqId/acronis/:acronisId/update`}
					component={({ match }) => (
						<Redirect
							to={`/services/acronis/${match.params.acronisId}/update`}
						/>
					)}
					whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
				/>
				<RoleRestrictedRoute
					exact
					path={`${navData.get(
						'path',
					)}/:serverType/:uniqId/acronis/:acronisId/manage`}
					component={({ match }) => (
						<Redirect to={`/services/acronis/${match.params.acronisId}`} />
					)}
					whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
				/>
				<RoleRestrictedRoute
					path={`${navData.get('path')}/:serverType/:uniqId/resize`}
					component={ServerResize}
					whitelistedRoles={[roles.PURCHASER]}
					parent={navData}
				/>
				<RoleRestrictedRoute
					path={`${navData.get('path')}/:serverType/:uniqId/destroy`}
					component={DestroyServer}
					whitelistedRoles={[roles.PURCHASER]}
				/>
				<RoleRestrictedRoute
					path={`${navData.get('path')}/:serverType/:uniqId/cpanel`}
					component={ChangeCpanelLicence}
					whitelistedRoles={[roles.PURCHASER]}
				/>
				<RoleRestrictedRoute
					exact
					path={`${navData.get('path')}/:type/:uniqId/reboot`}
					component={ServerReboot}
					whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
				/>
				<RoleRestrictedRoute
					exact
					path={`${navData.get('path')}/:serverType/:uniqId/ips/public`}
					component={PublicIP}
					whitelistedRoles={[roles.PURCHASER]}
				/>
				<RoleRestrictedRoute
					exact
					path={`${navData.get('path')}/:serverType/:uniqId/bandwidth`}
					component={Bandwidth}
					whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
				/>
				<RoleRestrictedRoute
					exact
					path={`${navData.get('path')}/:type/:uniqId/reboot`}
					component={ServerReboot}
					whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
				/>
				<RoleRestrictedRoute
					exact
					path={`${navData.get('path')}/:type/:uniqId/support-access`}
					component={SupportAccess}
					whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
				/>
				<RoleRestrictedRoute
					exact
					path={`${navData.get('path')}/:type/:uniqId/firewall`}
					component={Firewall}
					whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
				/>

				<SubNavRoutes
					subNavs={navData.get('subNav')}
					whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
				/>
			</Switch>
		</React.Fragment>
	);
};

ServersRouter.propTypes = {
	navData: PropTypes.object.isRequired,
};

export default ServersRouter;
