import { Map as ImmutableMap } from 'immutable';

const initialState = ImmutableMap({
	isOpen: false,
	serverDetails: null,
});

const unBlockIPDialog = (state = initialState, action) => {
	switch (action.type) {
		case 'UNBLOCK_IP_DIALOG_TOGGLE':
			return state.set('isOpen', action.isOpen).set('serverDetails', null);
		case 'SET_SERVER_DETAILS':
			return state
				.set('isOpen', action.isOpen)
				.set('serverDetails', action.serverDetails);
		default:
			return state;
	}
};

export default unBlockIPDialog;
