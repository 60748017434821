import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import get from 'lodash/get';
import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = ({ spacingPage }) => ({
	root: {
		display: 'block',
	},
	centered: {
		textAlign: 'center',
	},
	bold: {
		fontWeight: 'bold',
	},
	semiBold: {
		fontWeight: 600,
	},
	inline: {
		display: 'inline',
	},
	padded: {
		padding: spacingPage,
	},
});

const FTypography = ({ color, ...rest }) => {
	return <Typography {...rest} />;
};
// a styled component that is used only when color is passed in as a path in theme.js
const STypography = styled(FTypography)`
	color: ${(p) => get(p.theme, p.color)};
`;

const SSkeleton = styled(Skeleton)`
	${({ $inline }) =>
		$inline &&
		`
			display: inline;
		`}
`;

const LWTypography = (props) => {
	const {
		bold,
		semiBold,
		BoxProps,
		classes,
		centered,
		children,
		color,
		component,
		hide,
		inline,
		padded,
		isLoading,
		SkeletonProps,
		...rest
	} = props;

	// if we pass in a path in theme.js, use the generated styled component
	const Component = useMemo(
		() => (color.startsWith('palette') ? STypography : Typography),
		[color],
	);

	const content = useMemo(
		() => (
			<Component
				className={classNames({
					[classes.root]: true,
					[classes.centered]: centered,
					[classes.bold]: bold,
					[classes.semiBold]: semiBold,
					[classes.inline]: inline,
					[classes.padded]: padded,
				})}
				component={component}
				color={color}
				variant="body1"
				{...rest}
			>
				{isLoading ? (
					<SSkeleton variant="text" $inline={inline} {...SkeletonProps} />
				) : (
					children
				)}
			</Component>
		),
		[
			classes.root,
			classes.centered,
			classes.bold,
			classes.semiBold,
			classes.inline,
			classes.padded,
			centered,
			bold,
			semiBold,
			inline,
			padded,
			component,
			color,
			rest,
			isLoading,
			SkeletonProps,
			children,
		],
	);
	if (hide) return null;
	if (BoxProps) return <Box {...BoxProps}>{content}</Box>;
	return content;
};

LWTypography.propTypes = {
	classes: PropTypes.object.isRequired,
	bold: PropTypes.bool,
	centered: PropTypes.bool,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	color: PropTypes.string,
	component: PropTypes.string,
	hide: PropTypes.bool,
	inline: PropTypes.bool,
	SkeletonProps: PropTypes.shape({
		width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	}),
};

LWTypography.defaultProps = {
	bold: false,
	color: 'textPrimary',
	component: 'span',
	centered: false,
	children: null,
	hide: false,
	inline: false,
	SkeletonProps: { width: '50px' },
};

export { LWTypography };

export default withStyles(styles)(LWTypography);
