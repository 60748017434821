import React from 'react';
import styled from 'styled-components';
import LWTypography from 'components/common/LWTypography';

const SHostname = styled(LWTypography)`
	word-break: break-word;
`;

const GeneratedHostnameDisplay = ({ hostname }) => (
	<SHostname
		variant="h4"
		color="palette.common.accent[0]"
		data-testid="HostnameAndProjectContent__generatedHostname"
	>
		{/* Use a zero-width space to let the browser know it's safe to break at the periods */}
		{hostname.replace(/\./g, `.\u{200B}`)}
	</SHostname>
);

export default GeneratedHostnameDisplay;
