import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.asset.update;

const { actions, reducer, sagas, selectors } = createAPIModule({
	getStateSlice,
	actionType: 'ASSET_UPDATE',
	method: 'POST',
	url: '/asset/update.json',
});

export { actions, reducer, sagas, selectors };
