import { combineReducers } from 'redux';

import { reducer as remove } from './removeModule';
import { reducer as modify } from './modifyModule';
import { reducer as details } from './detailsModule';
import { reducer as add } from './addModule';
import { reducer as bulkModify } from './bulkModifyModule';
import { reducer as reset } from './resetModule';
import { reducer as extraOptions } from './extraOptionsModule';
import { reducer as clone } from './cloneModule';
import { reducer as assertProperties } from './assertPropertiesModule';
import { reducer as swap } from './swapModule';

import config from './config/configReducer';

export default combineReducers({
	bulkModify,
	config,
	remove,
	modify,
	details,
	add,
	reset,
	extraOptions,
	clone,
	assertProperties,
	swap,
});
