import getMarketplacePrice from 'utility/products/getMarketplacePrice';

const productsToProductTile = (products) =>
	products.map((elem) => {
		const {
			product_code: productCode,
			display_order: displayOrder,
			description,
			active,
			product,
		} = elem;
		const {
			features,
			title,
			product_description: productDescription,
			sub_title: subtitle,
			marketplacePrices,
		} = product || {};

		// TODO: Currently just using region 1, may need additional region logic later
		const { price, priceUnit } = getMarketplacePrice({
			marketplacePrices,
			region: 1,
		});

		return active
			? {
					value: productCode, // for productTiles
					productCode, // for sidesheetContents
					title,
					subtitle,
					fullDescription: description,
					description: productDescription,
					features,
					bundleFeatures: [], // This will map to 'bundleFeatures' in SideSheetContents when we have the data available.
					productTags: [], // This will map to 'productTags' in SideSheetContents when we have the data available.
					price,
					priceUnit,
					displayOrder,
					marketplacePrices,
			  }
			: null;
	});
export { productsToProductTile };
export default { productsToProductTile };
