import {
	all,
	call,
	put,
	select,
	spawn,
	take,
	takeLatest,
} from 'redux-saga/effects';
import { actions as serverRebootAPIActions } from 'modules/api/server/rebootModule';
import { actions as usertaskListActions } from 'modules/api/usertask/listModule';
import snackbarActions from 'modules/snackbar/snackbarActions';
import actions from './actions';
import * as selectors from './selectors';
import { successMessages } from './constants';

function* callRebootAPI({ uniqId, forceReboot = false, deferSeconds = null }) {
	const headers = {};

	if (deferSeconds) {
		headers['X-Meta-Timing-Defer'] = deferSeconds;
	}

	yield put(
		serverRebootAPIActions.fetchRaw({
			data: { params: { uniq_id: uniqId, force: forceReboot } },
			headers,
		}),
	);
}

function* refreshUsertaskList() {
	const uniqId = yield select(selectors.getUniqId);

	yield put(usertaskListActions.clear());
	yield put(
		usertaskListActions.fetch({
			uniq_id: uniqId,
			alsowith: ['action'],
			status: 'pending',
		}),
	);
}

function* handleRebootSuccess(payload) {
	const { code, requested } = payload;

	let message;
	if (code) {
		message = successMessages.scheduled;
	} else if (requested) {
		message = successMessages.requested;
	} else {
		message = successMessages.rebooted;
	}

	if (code) {
		yield call(refreshUsertaskList);
	}

	if (yield select(selectors.getIsDialogOpen)) {
		yield put(actions.closeDialog());
	}

	yield put(snackbarActions.pushMessage(message, 'success'));
}

function* handleInvokeReboot({ payload: requestPayload }) {
	yield put(serverRebootAPIActions.clear());

	const [{ type, payload }] = yield all([
		take([serverRebootAPIActions.setType, serverRebootAPIActions.errorType]),
		call(callRebootAPI, requestPayload),
	]);

	if (type === serverRebootAPIActions.setType) {
		yield call(handleRebootSuccess, payload);
	}
}

function* handleOpenRebootDialog() {
	yield put(serverRebootAPIActions.clear());
}

function* watchInvokeReboot() {
	yield takeLatest(actions.SERVER_REBOOT_INVOKE_REBOOT, handleInvokeReboot);
}

function* watchOpenRebootDialog() {
	yield takeLatest(actions.SERVER_REBOOT_OPEN_DIALOG, handleOpenRebootDialog);
}

export default function*() {
	yield spawn(watchInvokeReboot);
	yield spawn(watchOpenRebootDialog);
}
