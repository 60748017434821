import React from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import dialogActions from 'modules/dialogs/actions';

// api
import { selectors as assetDetailsSelectors } from 'modules/api/asset/detailsModule';
import basketActions from 'modules/basket/actions';

// MUI components
import Grid from '@material-ui/core/Grid';

// custom components
import LWTypography from 'components/common/LWTypography';
import ConfirmCancel from 'components/common/Dialogs/ConfirmCancel';

// This lives here because it is on topic and we may want to use is elsewhere someday.
const auxToolTipText =
	'This clone will be created using a ‘hot copy’ method, meaning that all data will be copied from the original VPS to the clone VPS.';

const CloneServerModalContents = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const productCode = useSelector(assetDetailsSelectors.productType);
	const region = useSelector(assetDetailsSelectors.regionId);
	const domain = useSelector(assetDetailsSelectors.domain);
	const uniqId = useSelector(assetDetailsSelectors.uniqId);

	return (
		<Grid container direction="column" spacing={3}>
			<Grid item>
				<LWTypography>
					You have chosen to clone: <b>{domain}</b>
				</LWTypography>
			</Grid>
			<Grid item>
				<LWTypography>
					To do this, a new VPS will be added to your cart with identical
					configuration, able to be changed before checkout.
				</LWTypography>
			</Grid>
			<Grid item>
				<LWTypography>
					After checkout, the clone will be created using a ‘hot copy’ method,
					meaning that all data will be copied from the original VPS to the
					clone VPS.{' ' /* intentional space */}
					<b>
						If the server is busy, this could lead to cloning incompletely
						written files and corrupted data.
					</b>
				</LWTypography>
			</Grid>

			<Grid item>
				<ConfirmCancel
					confirmText="Add to cart"
					cancel={() => {
						dispatch(dialogActions.close());
					}}
					confirm={() => {
						// TODO: NEWMAN-1782, get real property from BE. (I made up uniq_id_to_clone)
						dispatch(
							basketActions.addItem({
								productCode,
								region,
								properties: { uniq_id_to_clone: uniqId, domain },
								quickAdd: true,
							}),
						);
						history.push('/cart');
						dispatch(dialogActions.close());
					}}
				/>
			</Grid>
		</Grid>
	);
};

export { auxToolTipText };
export default CloneServerModalContents;
