import { createSelector } from 'reselect';

const routerSlice = (state) => state.router;

const getPathName = createSelector(
	routerSlice,
	(slice) => slice.location.pathname,
);

const getFullPathName = createSelector(
	routerSlice,
	(slice) => `${slice.location.pathname}${slice.location.search}`,
);

const getQueryParams = createSelector(
	routerSlice,
	(slice) => slice.location.query,
);

const getRootPathName = createSelector(
	routerSlice,
	(slice) => slice.location.pathname.split('/')[1],
);

export { getPathName, getFullPathName, getQueryParams, getRootPathName };
