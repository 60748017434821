import { call, put, takeLatest, select, take } from 'redux-saga/effects';
import { actions as addActions } from 'modules/api/basket/item/addModule';
import { actions as removeActions } from 'modules/api/basket/item/removeModule';

import { selectors as detailsSelectors } from 'modules/api/basket/detailsModule';
import { push } from 'connected-react-router';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';

import acronisProductMap, {
	acronisProductCodes,
} from 'utility/acronisProductMap';
import {
	DESTINATION,
	selectors as assetDetailsSelectors,
} from 'modules/api/asset/detailsModule';
import dialogActions from 'modules/dialogs/actions';
import basketActions from '../../actions';
import basketAcronisActions from '../../actions/acronis';
import detailsSaga from '../details';

function* handleRemoveAcronisResponse({ error, uuid }) {
	if (error) {
		yield put(basketActions.setError('remove Acronis'));
	} else {
		yield put(basketActions.itemRemoved({ uuid }));
	}
}

function* handleRemoveAcronis({ uuid }) {
	yield put(removeActions.fetch({ uuid }));
	const removeResult = yield take([
		removeActions.setType,
		removeActions.errorType,
	]);
	const error = removeResult?.type === removeActions.errorType;
	yield call(handleRemoveAcronisResponse, { error, uuid });
}

function* handleAddAcronisResponse({ error, addPayload } = {}) {
	if (error) {
		yield put(basketActions.setError('add Acronis'));
	} else if (addPayload) {
		yield put(basketActions.itemAdded(addPayload));
	}
}

function* addAcronis({ basketItemsByKey, serverId, addPayload } = {}) {
	// If items[ServerId] exists in basket, call remove the item, add it back with new configs
	if (basketItemsByKey[serverId]) {
		const uuidToEdit = basketItemsByKey[serverId]?.uuid;
		yield put(removeActions.fetch({ uuid: uuidToEdit }));
		const removeResult = yield take([
			removeActions?.setType,
			removeActions.errorType,
		]);
		yield call(handleAddAcronisResponse, {
			error: removeResult?.type === removeActions.errorType,
		});
		yield put(addActions.fetch(addPayload));
		const addResult = yield take([addActions.setType, addActions.errorType]);
		yield call(handleAddAcronisResponse, {
			error: addResult?.type === addActions.errorType,
		});
	} else {
		yield put(addActions.fetch(addPayload));
		const addResult = yield take([addActions.setType, addActions.errorType]);
		yield call(handleAddAcronisResponse, {
			error: addResult?.type === addActions.errorType,
			addPayload,
		});
	}
}

function* handleAddAcronis({ payload }) {
	const {
		quotaType,
		domain,
		backupType,
		serverId,
		value,
		region,
		serverProductCode,
		fromModal,
	} = payload;

	const currentIsLegacy = yield select(
		assetDetailsSelectors.backupPlanIsLegacy,
	);

	if (currentIsLegacy && !fromModal) {
		yield put(
			dialogActions.open({
				contentKey: 'AddAcronisModalContents',
				contentProps: { acronisObj: { ...payload } },
			}),
		);
		return;
	}

	// acronis already exists on this server in the basket
	yield call(detailsSaga, { init: true });
	const basketItems = (yield select(detailsSelectors.getBasketItems)) || [];

	const acronisItems = basketItems.filter((o) =>
		acronisProductCodes.includes(get(o, 'product_code')),
	);

	const basketItemsByKey = keyBy(
		acronisItems,
		(o) => o.properties.related_subaccnt,
	);

	const uuid = yield select(detailsSelectors.getBasketUuid);

	// Adding product_code:"AcronisBackup" to properties, client does not allow multiple acronis per server
	// TODO ask backend to only allow one per, force override

	const addPayload = {
		basket: uuid,
		product_code: acronisProductMap(serverProductCode),
		region,
		properties: {
			related_subaccnt: serverId,
		},
		configs: [
			{
				value: backupType,
				key: DESTINATION,
				num_units: null,
			},
			{
				parent_value: backupType,
				num_units: null,
				parent_key: DESTINATION,
				value,
				key: quotaType,
			},
		],
	};
	if (domain) {
		addPayload.properties.domain = domain;
	}
	yield call(addAcronis, { basketItemsByKey, serverId, addPayload });
	yield put(push('/cart'));
}

export {
	handleAddAcronis,
	handleRemoveAcronis,
	handleRemoveAcronisResponse,
	handleAddAcronisResponse,
	addAcronis,
};
export default function* root() {
	yield takeLatest(basketAcronisActions.ADD_ACRONIS, handleAddAcronis);
	yield takeLatest(basketAcronisActions.REMOVE_ACRONIS, handleRemoveAcronis);
}
