import { spawn } from 'redux-saga/effects';

import { sagas as listForKeySagas } from './listForKeyModule';
import { sagas as addSagas } from './addModule';
import { sagas as removeSagas } from './removeModule';
import { sagas as updateSagas } from './updateModule';
import { sagas as replaceSagas } from './replaceModule';

export default function* sagas() {
	yield spawn(listForKeySagas);
	yield spawn(removeSagas);
	yield spawn(replaceSagas);
	yield spawn(addSagas);
	yield spawn(updateSagas);
}
