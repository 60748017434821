import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import ConfirmationDialog from 'components/common/ConfirmationDialog';
import DialogStates from './DialogStates';

const ProjectAddDialog = ({
	clearAdd,
	addToProject,
	displaySnackbar,
	dialogProps,
	fetchAvailableAssets,
	fetchCategories,
	fetchPriceData,
	availableAssetsData,
	categoriesData,
	priceDataObj: { data: priceData },
	toggleDialog,
	added,
	isOpen,
}) => {
	const { projectId, projectName, toFetchAfter } = dialogProps;
	const [category, setCategory] = useState('');
	const [asset, setAsset] = useState({ uniq_id: '', domain: '', type: '' });
	const [assetCurrentProject, setAssetCurrentProject] = useState({
		uniqId: '',
		name: '',
	});

	const [showClusterVerbiage, setShowClusterVerbiage] = useState(false);

	// reset select fields on dialog open
	useEffect(() => {
		if (isOpen) {
			setCategory('');
			setAsset({ uniq_id: '', domain: '', type: '' });
			setAssetCurrentProject({ uniqId: '', name: '' });
			const region = 1; // TODO: remove hardcoded region when we have this data available
			fetchCategories();
			fetchPriceData(region);
			setShowClusterVerbiage(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isOpen]);

	useEffect(() => {
		if (added.hasData && toFetchAfter) {
			toggleDialog(false);
			toFetchAfter();
			displaySnackbar(`Successfully added ${asset.domain} to ${projectName}`);
			clearAdd();
			setCategory('');
			setAsset({ uniq_id: '', domain: '', type: '' });
			setAssetCurrentProject({ uniqId: '', name: '' });
		}
	}, [
		added.hasData,
		clearAdd,
		displaySnackbar,
		toFetchAfter,
		toggleDialog,
		asset.domain,
		projectName,
	]);

	const changeCategory = (e) => {
		fetchAvailableAssets({ category: e.target.value, projectId });
		setCategory(e.target.value);
	};

	const changeAsset = (e) => {
		const selectedUniqId = e.target.value;
		const assetData = availableAssetsData.assets.find(
			(a) => a.get('uniq_id') === selectedUniqId,
		);
		setAsset(assetData.toJS());
		setAssetCurrentProject({
			uniqId: assetData.get('project_id'),
			name: assetData.get('project_name'),
		});
	};

	const dialogStates = new DialogStates({
		showClusterVerbiage,
		assetCurrentProject,
		asset,
		priceData,
		categoriesData,
		projectName,
		category,
		changeCategory,
		availableAssetsData,
		changeAsset,
	});

	// the check, `category === 'servers'` is probably redundant.
	const thereIsMore =
		category === 'servers' && asset.clusterable && !showClusterVerbiage;
	const handleClose = () => {
		if (!thereIsMore) {
			toggleDialog(false);
		}
	};

	const handleConfirm = () => {
		if (thereIsMore) {
			setShowClusterVerbiage(true);
		} else {
			addToProject({ uniqId: asset.uniq_id, projectId });
		}
	};

	const confirmable = !!(
		dialogStates.errorTitle !== dialogStates.title &&
		category &&
		asset &&
		asset.uniq_id
	);

	return (
		<ConfirmationDialog
			confirmProps={{ disabled: !confirmable }}
			open={isOpen}
			onConfirm={handleConfirm}
			onCancel={() => toggleDialog(false)}
			onClose={handleClose}
			title={dialogStates.title}
			confirmText={dialogStates.confirmText}
		>
			<Box pt={2} px={1} width={['100%', '100%', 552]}>
				{dialogStates.contents}
			</Box>
		</ConfirmationDialog>
	);
};

ProjectAddDialog.propTypes = {
	clearAdd: PropTypes.func.isRequired,
	addToProject: PropTypes.func.isRequired,
	displaySnackbar: PropTypes.func.isRequired,
	fetchCategories: PropTypes.func.isRequired,
	fetchPriceData: PropTypes.func.isRequired,
	categoriesData: PropTypes.object.isRequired,
	fetchAvailableAssets: PropTypes.func.isRequired,
	availableAssetsData: PropTypes.object.isRequired,
	dialogProps: PropTypes.object,
	toggleDialog: PropTypes.func.isRequired,
	added: PropTypes.object.isRequired,
	isOpen: PropTypes.bool.isRequired,
};

ProjectAddDialog.defaultProps = {};

export default ProjectAddDialog;
