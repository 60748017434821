import axios from 'axios';
import { call, select } from 'redux-saga/effects';

const version = process.env.REACT_APP_VERSION;
const commitSha = process.env.REACT_APP_COMMIT_SHA
	? process.env.REACT_APP_COMMIT_SHA.substring(0, 8)
	: 'unknown';

export default function* axiosCreate() {
	// avoid use of selectors to prevent import loop
	const appConfig = yield select((state) => state.appConfig.get('data'));
	const localURL = yield call([localStorage, 'getItem'], 'apiEndpoint'); // Can allow overriding the default API for manual testing purposes
	const baseURL = localURL || appConfig.get('baseURL');
	return axios.create({
		baseURL,
		timeout: 60000,
		headers: {
			'X-Force-Compliant-HTTP-Status-Codes': 1,
			'X-MyLW-Version': version && version !== 'dev' ? version : commitSha,
		},
	});
}
