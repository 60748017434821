import countryRegionData from 'country-region-data';
import startCase from 'lodash/startCase';

const countryWithRegions = (inputCountry) =>
	inputCountry &&
	countryRegionData.find(
		(countryRegions) =>
			countryRegions.countryShortCode === inputCountry.toUpperCase() ||
			countryRegions.countryName === startCase(inputCountry),
	);

const countryOption = (inputCountry) =>
	inputCountry
		? {
				label: inputCountry.countryName,
				value: inputCountry.countryShortCode,
		  }
		: { label: null, value: '' };

const stateOption = (inputCountry, inputState) => {
	const myRegion = inputCountry?.regions?.find(
		(region) =>
			region.shortCode === (inputState && inputState.toUpperCase()) ||
			region.name.toLowerCase() === (inputState && inputState.toLowerCase()),
	);
	return myRegion ? { label: myRegion.name, value: myRegion.name } : null;
};

const getRegions = (inputCountry) => {
	const result = countryWithRegions(inputCountry?.value);
	return result?.regions?.map((region) => ({
		label: region.name,
		value: region.name,
	}));
};

export { countryWithRegions, countryOption, getRegions, stateOption };
