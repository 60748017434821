import { all, call, put, select, take } from 'redux-saga/effects';
import { init as initLWChat, startChat as startLWChat } from 'lw-chat-js';
import { selectors as appConfigSelectors } from 'modules/api/appConfigModule';
import {
	actions as issueJWTActions,
	selectors as issueJWTSelectors,
} from 'modules/api/salesforce/chat/auth/issuejwtModule';
import snackbarSaga from 'modules/snackbar/sagas';
import { hasAuthToken as hasAuthTokenSelector } from 'modules/auth/oidcSelectors';
import actions from '../actions';

function* initChat() {
	const config = yield select(appConfigSelectors.getChatConfig);
	const nativeConfig = config?.toJS();
	try {
		yield call(initLWChat, nativeConfig);
	} catch (error) {
		yield call(snackbarSaga, {
			error,
			errorMessage: 'Error initializing chat',
		});
	}
}

function* getStartChatOptions({
	isLoggedIn: isLoggedInArg,
	type: typeArg,
} = {}) {
	const isLoggedIn = isLoggedInArg || (yield select(hasAuthTokenSelector));

	if (!isLoggedIn) return undefined;

	const type =
		typeArg ||
		(yield all([
			take([issueJWTActions.setType, issueJWTActions.errorType]),
			put(issueJWTActions.fetch()),
		]))?.[0]?.type;

	if (type === issueJWTActions.setType) {
		const jwt = yield select(issueJWTSelectors.getJWT);
		return {
			extraDetails: {
				ChatAuthToken: jwt,
			},
		};
	}

	// If unable to fetch a JWT, fall back to unauthenticated
	return undefined;
}

function* handleStartChat() {
	yield put(actions.setIsStarting(true));

	try {
		const options = yield call(getStartChatOptions);
		yield call(startLWChat, options);
	} catch (error) {
		yield call(snackbarSaga, {
			error,
			errorMessage:
				'It looks like chat failed to initialize. Our chat service partner requires access to third-party cookies and browser session storage in order for chat to operate. Please ensure your browser is allowing third-party storage for this session, and try again.',
			autoHideDuration: 20000,
		});
	}

	yield put(actions.setIsStarting(false));
}

function* watchStartChat() {
	while (true) {
		yield take(actions.START_CHAT);
		yield call(handleStartChat);
	}
}

export { watchStartChat, handleStartChat, getStartChatOptions, initChat };
export default function* chatRootSaga() {
	yield all([call(initChat), call(watchStartChat)]);
}
