import { createSelector } from 'reselect';

const getStateSlice = (state) => state.transactionTimeline;

const selectors = {
	get: createSelector(
		getStateSlice,
		(slice) => slice,
	),
	getFilters: createSelector(
		getStateSlice,
		(slice) => slice.filters,
	),
};
export default selectors;
