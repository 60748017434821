import { put, take, takeLatest } from 'redux-saga/effects';
import basketActions from '../../actions';

function* handleAbandon({ payload: { addItemPayload, savedBasketUuid } }) {
	yield put(basketActions.reset());
	yield take(basketActions.BASKET_INITIALIZED);
	if (addItemPayload) {
		yield put(basketActions.addItem(addItemPayload));
	}
	if (savedBasketUuid) {
		yield put(basketActions.mergeSaved({ uuid: savedBasketUuid }));
	}
}

export { handleAbandon };
export default function* root() {
	yield takeLatest(basketActions.BASKET_ABANDON, handleAbandon);
}
