import { put, takeEvery, takeLatest } from 'redux-saga/effects';
import { actions as basketDetailsActions } from 'modules/api/basket/detailsModule';
import { actions as generateSavedActions } from 'modules/api/basket/generateSavedModule';
import basketActions from '../../actions';

// tested by src/containers/pages/shop/Cart/CartSharingPanel/CartSharing/CartSharing.test.js
function* clearGenerateSaved() {
	yield put(generateSavedActions.clear());
}

// tested by src/containers/pages/shop/Cart/CartSharingPanel/CartSharing/CartSharing.test.js
function* generateSaved({ payload: { uuid } }) {
	yield put(generateSavedActions.fetch({ uuid }));
}

export default function* root() {
	yield takeEvery(basketDetailsActions.setType, clearGenerateSaved);
	yield takeLatest(basketActions.BASKET_GENERATE_SAVED, generateSaved);
}
