import produce from 'immer';
import actions from './actions';

const INITIAL_FILTERS = {
	// TODO
	begin_date: null,
	// TODO
	end_date: null,
	invoice_id: '',
	title: null, // One of: 'invoice', 'payment' for API
};
const initialState = {
	filters: INITIAL_FILTERS,
};

/* eslint-disable default-case, no-param-reassign */
const reducer = (state = initialState, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case actions.TRANSACTION_TIMELINE_TOGGLE_CHANGE_FILTER:
				{
					const { filters } = state;
					const newFilters = {
						...filters,
						[action.payload.label]: action.payload.value,
					};
					draft.filters = newFilters;
				}
				break;

			case actions.TRANSACTION_TIMELINE_TOGGLE_CLEAR_FILTERS:
				draft.filters = INITIAL_FILTERS;
				break;
		}
	});

export default reducer;
