import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'utility/compose';
import { useHistory } from 'react-router';
import CompactCartSummary from 'containers/pages/cart/CompactCartSummary';
import { withStyles } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import TextField from '@material-ui/core/TextField';
import Smartphone from '@material-ui/icons/Smartphone';
import Close from '@material-ui/icons/Close';
import Edit from '@material-ui/icons/Edit';
import Done from '@material-ui/icons/Done';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LockIcon from '@material-ui/icons/Lock';
import { selectors as accountDetailsSelectors } from 'modules/api/account/detailsModule';
import {
	hasAuthToken,
	isAccountUser as isAccountUserSelector,
} from 'modules/auth/oidcSelectors';
import { getPathName } from 'modules/routeSelectors';
import {
	selectors as passphraseGetSelectors,
	actions as passphraseGetActions,
} from 'modules/api/account/chatpassword/getModule';
import {
	selectors as passphraseSetSelectors,
	actions as passphraseSetActions,
} from 'modules/api/account/chatpassword/setModule';
import snackbarActions from 'modules/snackbar/snackbarActions';
import manageRedirectActions from 'modules/manageRedirect/manageRedirectActions';
import SignInButton from 'components/atoms/SignInButton';
import LWLink from 'components/common/LWLink';
import LWButton from 'components/common/LWButton';
import LWTypography from 'components/common/LWTypography';
import UserProfile from 'components/common/UserProfile';
import FavoritesList from 'components/common/FavoritesList';

const marginEdge = '.75em';
const styles = ({
	maxWidth,
	spacing,
	palette,
	breakpoints,
	zIndex,
	mixins,
}) => ({
	accountBarInner: {
		maxWidth,
		margin: 'auto',
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		[breakpoints.up('md')]: {
			justifyContent: 'space-between',
		},
	},
	root: {
		background: palette.common.accent[0],
		maxHeight: '52px',
	},
	closeIconButton: {
		color: palette.common.white,
	},
	leftSide: {
		overflow: 'hidden',
		width: '100%',
		marginLeft: marginEdge,
		alignItems: 'center',
		display: 'flex',
		color: 'white',
		fontSize: '1.125em',
		[breakpoints.down('xs')]: {
			fontSize: '.875em',
		},
	},
	contact: {
		marginLeft: 'auto',
		paddingLeft: '1em',
		marginRight: marginEdge,
	},
	passphraseButtonContainer: {
		flex: 1,
		overflow: 'hidden',
		paddingLeft: spacing(2),
		alignItems: 'center',
		display: 'flex',
		'& .MuiButton-label': {
			padding: spacing(0.5),
			paddingRight: spacing(1),
		},
		'& .MuiSvgIcon-root': {
			marginRight: spacing(0.5),
			marginLeft: spacing(0.5),
		},
	},
	passphraseButton: {
		border: '1px solid white',
		borderRadius: '100px',
		padding: 0,
		color: 'white',
		'&& .MuiButton-label': {
			color: palette.primary.contrastText,
		},
		marginRight: '.3em',
		minWidth: '4em',
		[breakpoints.down('xs')]: {
			fontSize: '.875em',
		},
	},
	logout: {
		marginLeft: spacing(1),
		'&:hover': {
			textDecoration: 'none',
		},
		color: 'white',
		'&& .MuiButton-label': {
			color: palette.primary.contrastText,
		},
		...mixins.alignCenter,
	},
	phone: {
		display: 'flex',
		padding: '.5em 0em',
		alignItems: 'center',
	},
	phoneNumber: {
		color: 'white',
	},
	passphraseText: {
		overflow: 'auto',
		textAlign: 'center',
		fontSize: '.875em',
		margin: spacing(1),
		marginRight: spacing(2),
		display: 'flex',
		minWidth: '13.750em',
		fontWeight: 500,
		color: 'white',
	},
	icon: {
		color: 'white',
		display: 'flex',
		alignSelf: 'center',
	},
	supportPassphrase: {
		overflow: 'hidden',
		display: 'flex',
	},
	button: {
		'&&': {
			color: 'white',
			border: '1px solid transparent',
			'&:hover': {
				border: '1px solid white',
			},
			[breakpoints.down('sm')]: {
				padding: '4px',
			},
		},
	},
	rightSide: {
		marginRight: spacing(1),
		display: 'flex',
		alignItems: 'center',
	},
	buttons: {
		display: 'flex',
		alignItems: 'center',
		height: '100%',
		'&:last-child': {
			border: '0',
		},
	},
	actionButton: {
		color: 'white',
	},
	saveButton: {
		marginLeft: '.5em',
		marginRight: '.5em',
	},
	spinner: {
		color: 'white',
	},
	popup: {
		background: palette.common.bluebar,
		position: 'absolute',
		transform: 'translate(7em, 63%)',
		zIndex: zIndex.accountBarPopUp,
		width: '13em',
		padding: '.5em',
		'&::before': {
			content: '""',
			borderLeft: '5px solid transparent',
			borderRight: '5px solid transparent',
			borderBottom: '5px solid #3c98c5',
			top: '-5px',
			position: 'absolute',
		},
	},
	popupTop: {
		position: 'absolute',
		top: '5px',
		right: '5px',
	},
	passphraseLabel: {
		paddingBottom: '.5em',
	},
	passphraseContainer: {
		textAlign: 'center',
		fontWeight: '500',
		paddingBottom: '.5em',
	},
	textFieldContainer: {
		position: 'relative',
		display: 'flex',
		alignItems: 'center',
		width: '100%',
		margin: 'auto 8px',
	},
	textField: {
		[breakpoints.up('md')]: {
			margin: '5px',
			marginRight: spacing(2),
			zIndex: 2,
			position: 'absolute',
			top: -20,
		},
		marginRight: spacing(1),
		'& .MuiInputBase-input': {
			maxHeight: 25,
			padding: 3,
			borderRadius: 4,
		},
		'& .MuiFilledInput-multiline': {
			padding: 0,
		},
		'& .MuiFilledInput-input': {
			background: palette.common.white1,
		},
	},
	passphrase: {
		display: 'flex',
		flexDirection: 'row',
	},
	passPhraseTextMobile: {
		overflow: 'auto',
		flex: 5,
	},
	passPhraseIconMobile: {
		flex: 1,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
});
const AccountBar = ({
	classes,
	accountNumber,
	isLoggedIn,
	isAccountUser,
	isLoading,
	supportPassphrase,
	getSupportPassphrase,
	openManageRedirectDialog,
	path,
	clearSupportPassphrase,
	setSupportPassphrase,
	setSupportPassphraseLocal,
	updatedSupportPassphrase,
	openSnackbar,
}) => {
	const history = useHistory();
	const [showPopUp, setShowPopUp] = React.useState(false);
	const [writeModePassphrase, setWriteModePassphrase] = useState(false);
	const [writeModePassphraseMobile, setWriteModePassphraseMobile] = useState(
		false,
	);
	const [showSupportPassphrase, setShowSupportPassphrase] = useState(false);
	const [localSupportPassphrase, setLocalSupportPassphrase] = useState('');
	const [timeoutID, setTimeoutID] = useState(null);

	const startAutoHideTimer = () => {
		const test = setTimeout(() => {
			setShowSupportPassphrase(false);
			setShowPopUp(false);
		}, 10000);
		setTimeoutID(test);
	};

	const stopAutoHideTimer = () => {
		if (timeoutID) {
			clearTimeout(timeoutID);
		}
	};

	useEffect(() => {
		if (path) {
			setShowPopUp(false);
			setShowSupportPassphrase(false);
			clearTimeout(timeoutID);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [path]);

	useEffect(() => {
		if (!updatedSupportPassphrase.isLoading) {
			setWriteModePassphraseMobile(false);
		}
	}, [updatedSupportPassphrase.isLoading]);

	useEffect(() => {
		if (updatedSupportPassphrase.data) {
			setWriteModePassphrase(false);
			setSupportPassphraseLocal(updatedSupportPassphrase.data.get('password'));
			openSnackbar('Successfully updated support passphrase');
			clearSupportPassphrase();
			startAutoHideTimer();
		}
	}, [
		clearSupportPassphrase,
		openSnackbar,
		setSupportPassphraseLocal,
		updatedSupportPassphrase.data,
	]);

	const handleShowPassphraseClick = () => {
		if (!supportPassphrase.hasData) {
			getSupportPassphrase();
		}
		setShowSupportPassphrase(!showSupportPassphrase);
	};

	const handleSupportPassphraseChange = (e) =>
		setLocalSupportPassphrase(e.target.value);

	const saveSupportPassphrase = () =>
		setSupportPassphrase(localSupportPassphrase);

	const getPassphraseIcons = () => {
		const visibility = showSupportPassphrase ? (
			<Tooltip title="Hide passphrase">
				<IconButton
					onClick={() => {
						handleShowPassphraseClick();
						stopAutoHideTimer();
					}}
				>
					<LockOpenIcon className={classes.icon} />
				</IconButton>
			</Tooltip>
		) : null;
		let edit = null;
		if (showSupportPassphrase) {
			edit = writeModePassphrase;
			if (writeModePassphrase) {
				edit = (
					<Tooltip title="Cancel">
						<IconButton
							className={classes.closeIconButton}
							disabled={updatedSupportPassphrase.isLoading}
							onClick={() => {
								setWriteModePassphrase(false);
								startAutoHideTimer();
							}}
						>
							<Close />
						</IconButton>
					</Tooltip>
				);
			} else {
				edit = (
					<Tooltip title="Edit">
						<IconButton
							disabled={supportPassphrase.isLoading}
							onClick={() => {
								setWriteModePassphrase(true);
								stopAutoHideTimer();
							}}
						>
							<Edit className={classes.icon} />
						</IconButton>
					</Tooltip>
				);
			}
		}
		return (
			<>
				{edit}
				{visibility}
			</>
		);
	};

	const mobilePassphraseEditForm = () => {
		return (
			<form onSubmit={saveSupportPassphrase}>
				<TextField
					variant="filled"
					rowsMax={6}
					defaultValue={supportPassphrase.data}
					disabled={updatedSupportPassphrase.isLoading}
					fullWidth
					multiline
					onChange={handleSupportPassphraseChange}
					placeholder="Enter a passphrase here"
					classes={{ root: classes.textField }}
				/>
			</form>
		);
	};

	const supportPassphraseContent = () => {
		if (writeModePassphrase && showSupportPassphrase) {
			return (
				<>
					<div className={classes.textFieldContainer}>
						<TextField
							rowsMax={6}
							variant="filled"
							defaultValue={supportPassphrase.data}
							disabled={updatedSupportPassphrase.isLoading}
							fullWidth
							multiline
							onChange={handleSupportPassphraseChange}
							placeholder="Enter a passphrase here"
							classes={{ root: classes.textField }}
						/>
					</div>
					<LWButton
						className={classes.saveButton}
						color="secondary"
						disabled={
							updatedSupportPassphrase.isLoading ||
							localSupportPassphrase === supportPassphrase.data
						}
						onClick={saveSupportPassphrase}
						variant="contained"
						size="small"
						isLoading={updatedSupportPassphrase.isLoading}
					>
						Save
					</LWButton>
				</>
			);
		}

		if (showSupportPassphrase) {
			return (
				<div className={classes.supportPassphrase}>
					<span className={classes.passphraseText}>
						{isLoading && (
							<CircularProgress size={20} className={classes.spinner} />
						)}
						{!isLoading &&
							(supportPassphrase.data || 'No support passphrase found')}
					</span>
				</div>
			);
		}
		return null;
	};
	const supportPassphraseButton = (
		<div className={classes.passphraseButtonContainer}>
			{!showSupportPassphrase && (
				<Button
					size="small"
					variant="outlined"
					classes={{ root: classes.passphraseButton }}
					startIcon={<LockIcon />}
					onClick={() => {
						if (!supportPassphrase.hasData) {
							getSupportPassphrase();
						}
						setShowSupportPassphrase(true);
						startAutoHideTimer();
					}}
				>
					Show My Support Passphrase
				</Button>
			)}
			{supportPassphraseContent()}
			{getPassphraseIcons()}
		</div>
	);
	let left;
	let favoritesButton;
	if (isAccountUser) {
		left = (
			<React.Fragment>
				<LWTypography color="palette.common.white">{`Account #${accountNumber}`}</LWTypography>
				<Hidden xsDown>{supportPassphraseButton}</Hidden>
				<Hidden smUp>
					<IconButton
						onClick={() => {
							if (!supportPassphrase.hasData) {
								getSupportPassphrase();
							}
							setShowPopUp(!showPopUp);
							startAutoHideTimer();
						}}
						classes={{ root: classes.passphraseButton }}
					>
						<LockIcon className={classes.icon} />
					</IconButton>
					{showPopUp && (
						<ClickAwayListener
							onClickAway={() => {
								setShowPopUp(false);
								stopAutoHideTimer();
							}}
						>
							<div className={classes.popup}>
								<div className={classes.popupTop}>
									<IconButton
										size="small"
										onClick={() => {
											setShowPopUp(false);
											stopAutoHideTimer();
										}}
										className={classes.closeIconButton}
									>
										<Close />
									</IconButton>
								</div>
								<div className={classes.passphraseLabel}>
									{'Support Passphrase: '}
								</div>
								<div className={classes.passphraseContainer}>
									{isLoading && (
										<CircularProgress size={20} className={classes.spinner} />
									)}
									{!isLoading && (
										<div>
											{supportPassphrase.data ? (
												<div className={classes.passphrase}>
													<div className={classes.passPhraseTextMobile}>
														{writeModePassphraseMobile
															? mobilePassphraseEditForm()
															: supportPassphrase.data}
													</div>
													<div className={classes.passPhraseIconMobile}>
														{writeModePassphraseMobile ? (
															<IconButton
																disabled={
																	updatedSupportPassphrase.isLoading ||
																	localSupportPassphrase ===
																		supportPassphrase.data ||
																	!localSupportPassphrase
																}
																onClick={saveSupportPassphrase}
															>
																{updatedSupportPassphrase.isLoading ? (
																	<CircularProgress size={30} />
																) : (
																	<Done
																		className={classes.icon}
																		fontSize="small"
																	/>
																)}
															</IconButton>
														) : (
															<IconButton
																disabled={supportPassphrase.isLoading}
																onClick={() => {
																	setWriteModePassphraseMobile(true);
																	stopAutoHideTimer();
																}}
															>
																<Edit
																	className={classes.icon}
																	fontSize="small"
																/>
															</IconButton>
														)}
													</div>
												</div>
											) : (
												<div>No support passphrase found</div>
											)}
										</div>
									)}
								</div>
								<div className={classes.phone}>
									<Smartphone />
									<LWLink
										to="tel:1-800-580-4985"
										classes={{ root: classes.phoneNumber }}
									>
										{'1-800-580-4985'}
									</LWLink>
								</div>
								<div className={classes.phone}>
									<Smartphone />
									<LWLink
										to="tel:1-517-322-0434"
										classes={{ root: classes.phoneNumber }}
									>
										{'1-517-322-0434'}
									</LWLink>
									{' (Int’l.)'}
								</div>
							</div>
						</ClickAwayListener>
					)}
				</Hidden>
			</React.Fragment>
		);
		favoritesButton = (
			<FavoritesList
				classes={{ root: classes.actionButton }}
				onClick={openManageRedirectDialog}
			/>
		);
	}

	return (
		<span>
			<div className={classes.root}>
				<div className={classes.accountBarInner}>
					<LWTypography className={classes.leftSide} component="div">
						{left}
					</LWTypography>
					<div className={classes.rightSide}>
						<CompactCartSummary />
						{favoritesButton}
						{isLoggedIn && (
							<>
								<Tooltip title="My user profile">
									<IconButton>
										<UserProfile classes={{ root: classes.actionButton }} />
									</IconButton>
								</Tooltip>
								<Button
									variant="outlined"
									className={classes.button}
									size="small"
									onClick={() => history.push('/logout')}
								>
									Logout
								</Button>
							</>
						)}
						{!isLoggedIn && <SignInButton label="Login" accountBarStyle />}
					</div>
				</div>
			</div>
		</span>
	);
};

AccountBar.propTypes = {
	classes: PropTypes.object.isRequired,
	accountNumber: PropTypes.string,
	supportPassphrase: PropTypes.shape({
		hasData: PropTypes.bool.isRequired,
		data: PropTypes.string,
	}),
	isLoading: PropTypes.bool.isRequired,
	isLoggedIn: PropTypes.bool,
	getSupportPassphrase: PropTypes.func.isRequired,
	openManageRedirectDialog: PropTypes.func.isRequired,
	path: PropTypes.string.isRequired,
	setSupportPassphrase: PropTypes.func.isRequired,
	setSupportPassphraseLocal: PropTypes.func.isRequired,
	updatedSupportPassphrase: PropTypes.shape({
		data: PropTypes.object,
		hasData: PropTypes.bool.isRequired,
		isLoading: PropTypes.bool.isRequired,
		hasError: PropTypes.bool.isRequired,
	}).isRequired,
	clearSupportPassphrase: PropTypes.func.isRequired,
	openSnackbar: PropTypes.func.isRequired,
};

AccountBar.defaultProps = {
	isLoggedIn: null,
	supportPassphrase: '',
};

const mapStateToProps = (state) => ({
	accountDetails: accountDetailsSelectors.getData(state),
	accountNumber: accountDetailsSelectors.getAccnt(state),
	isLoggedIn: hasAuthToken(state),
	isAccountUser: isAccountUserSelector(state),
	supportPassphrase: {
		hasData: passphraseGetSelectors.hasData(state),
		data: passphraseGetSelectors.supportPassphrase(state),
	},
	isLoading: passphraseGetSelectors.isLoading(state),
	path: getPathName(state),
	updatedSupportPassphrase: {
		hasData: passphraseSetSelectors.hasData(state),
		data: passphraseSetSelectors.getData(state),
		isLoading: passphraseSetSelectors.isLoading(state),
		hasError: passphraseSetSelectors.hasError(state),
	},
});

const mapDispatchToProps = (dispatch) => ({
	clearSupportPassphrase: () => dispatch(passphraseSetActions.clear()),
	getSupportPassphrase: () => dispatch(passphraseGetActions.fetch()),
	setSupportPassphrase: (password) =>
		dispatch(passphraseSetActions.fetch({ password })),
	setSupportPassphraseLocal: (password) =>
		dispatch(passphraseGetActions.set({ password })),
	openManageRedirectDialog: () => dispatch(manageRedirectActions.setOpen(true)),
	openSnackbar: (message, color) =>
		dispatch(snackbarActions.pushMessage(message, color)),
});

export { AccountBar };

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	),
	withStyles(styles),
)(AccountBar);
