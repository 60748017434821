import { loadEmbeddedSvc, getEmbeddedSvc, watchEmbeddedServiceEvents } from './embedded-service';
import { mergeConfig, getAllConfig, getConfig } from './config';
import { init as initAnalytics } from './analytics';
import { init as initInvitationUI } from './invitation-ui';
import { getInitialExtraDetails, updateExtraDetail } from './extra-details';
import emitter from './emitter';
import './global-helpers';

const handlePrechatSurveySubmitted = (prechatFormData = []) => {
	if (prechatFormData.length) {
		emitter.emit('LWPrechatSurveySubmitted', prechatFormData);
	}

	const buttonDetail = prechatFormData.find(detail => detail.label === 'Route To');

	if (buttonDetail && buttonDetail.value) {
		return buttonDetail.value;
	}

	// If no "Route To" detail was submitted, we return undefined
	// so the chat will be routed to the default "All Chat" button.
	return undefined;
};

const doInitEmbeddedSvc = ([embeddedSvc, initialExtraDetails]) => {
	const config = getAllConfig();

	/* eslint-disable no-param-reassign */
	embeddedSvc.settings.directToButtonRouting = handlePrechatSurveySubmitted;
	embeddedSvc.settings.extraPrechatFormDetails = initialExtraDetails;
	embeddedSvc.settings.displayHelpButton = config.displayHelpButton;
	embeddedSvc.settings.storageDomain = config.storageDomain;
	embeddedSvc.settings.enabledFeatures = ['LiveAgent'];
	embeddedSvc.settings.entryFeature = 'LiveAgent';
	/* eslint-enable no-param-reassign */

	const afterInitPromise = new Promise(resolve => emitter.once('afterInit', resolve));

	const {
		baseCoreURL,
		communityEndpointURL,
		gslbBaseURL,
		orgId,
		eswConfigDevName,
		settings,
	} = config;

	embeddedSvc.init(
		baseCoreURL,
		communityEndpointURL,
		gslbBaseURL,
		orgId,
		eswConfigDevName,
		settings,
	);

	return afterInitPromise;
};

let initPromise = null;
const init = (config) => {
	if (initPromise) return initPromise;

	if (config) mergeConfig(config);

	initAnalytics();
	loadEmbeddedSvc();
	initInvitationUI();

	initPromise = Promise.all(
		[
			getEmbeddedSvc(),
			getInitialExtraDetails(),
			watchEmbeddedServiceEvents(),
		],
	).then(doInitEmbeddedSvc);

	return initPromise;
};

const awaitStartChatReady = () => {
	// The ESW script looks for the presence of an .embeddedServiceSidebar
	// element to determine whether component initialization has already
	// occurred or is in progress. If found, it short-circuits the button
	// click handler, and the "ready" event is never emitted.
	//
	// We're relying on an undocumented internal implementation detail of
	// the ESW script here. This may break without notice, but there seems
	// to be no better way to ensure the correct behavior.
	if (document.getElementsByClassName('embeddedServiceSidebar').length) {
		return Promise.resolve();
	}

	const chatStartTimeout = getConfig('chatStartTimeout');

	let resolver;

	const readyPromise = new Promise((resolve, reject) => {
		resolver = resolve;
		emitter.once('ready', resolver);
		setTimeout(reject, chatStartTimeout);
	});

	return readyPromise
		.finally(() => emitter.off('ready', resolver))
		.catch(() => {
			emitter.emit('LWButtonClickTimeout');
			throw new Error('Ready timed out');
		});
};

const updateExtraDetails = (extraDetails = {}) => (
	Promise.all(
		Object
			.entries(extraDetails)
			.map(([label, value]) => updateExtraDetail(label, value)),
	)
);

const startChat = ({ extraDetails } = {}) => {
	if (!initPromise) throw new Error('Chat has not yet been initialized');

	return initPromise
		.then(() => updateExtraDetails(extraDetails))
		.then(getEmbeddedSvc)
		.then(embeddedSvc => (
			Promise.all([
				awaitStartChatReady(),
				embeddedSvc.onHelpButtonClick(),
			])
		))
		.then(([result]) => result);
};

export {
	emitter,
	init,
	startChat,
};
