import { takeLatest, put, select } from 'redux-saga/effects';
import {
	selectors as listSelectors,
	actions as listActions,
} from 'modules/api/contact/listModule';
import { actions as updateActions } from 'modules/api/contact/updateModule';
import actions from './actions';

function* updateTaxInfo({ values }) {
	const { taxExempt, taxExemptId, taxId, taxExemptReason } = values;
	let id = yield select(listSelectors.getDefaultContactId);
	if (!id) {
		yield put(listActions.fetch());
		id = yield select(listSelectors.getDefaultContactId);
	}
	yield put(
		updateActions.fetch({
			id,
			tax_exempt: taxExempt || 0,
			tax_exempt_id: taxExemptId || undefined,
			tax_id: taxId || undefined,
			tax_exempt_reason: taxExemptReason || undefined,
		}),
	);
}

export default function* saga() {
	yield takeLatest(actions.TAX_INFO_UPDATE, updateTaxInfo);
}
