import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.account.user.stateData.remove;

const { actions, reducer, sagas, selectors } = createAPIModule({
	getStateSlice,
	actionType: 'ACCOUNT_USER_STATEDATA_REMOVE',
	url: '/account/user/statedata/remove.json',
});

export { actions, reducer, sagas, selectors };
