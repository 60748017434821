import { getConfig } from '../config';

const getInitialExtraDetails = () => {
	const preselectDepartment = getConfig('preselectDepartment');

	return [
		{
			label: 'preselectDepartment',
			value: preselectDepartment || '',
			displayToAgent: false,
		},
	];
};

export default getInitialExtraDetails;
