import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.server.shutdown;

const { actions, reducer, sagas, selectors } = createAPIModule({
	getStateSlice,
	actionType: 'SERVER_SHUTDOWN',
	url: '/server/shutdown.json',
});

export { actions, reducer, sagas, selectors };
