import { connect } from 'react-redux';
import basketActions from 'modules/basket/actions';
import { selectors as itemSelectors } from 'modules/api/basket/item/addModule';
import { selectors as detailsSelectors } from 'modules/api/basket/detailsModule';

import SideSheetContents from './SideSheetContents';

const mapStateToProps = (state, { productCode }) => ({
	itemsInCart:
		detailsSelectors.getBasketItemCounts(state) &&
		detailsSelectors.getBasketItemCounts(state)[productCode],
	quickAddIsLoading:
		detailsSelectors.isLoading(state) || itemSelectors.isLoading(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
	quickAdd: ({ region }) => {
		dispatch(
			basketActions.addItem({
				productCode: ownProps.productCode,
				quickAdd: true,
				region: region || ownProps.region || 1,
				relatedSubaccnt: ownProps.relatedSubaccnt,
				domain: ownProps.domain,
			}),
		);
	},
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(SideSheetContents);
