const getInitialPrechatFormDetails = () => [
	{
		label: 'Route To',
		value: '',
		transcriptFields: [
			'RouteTo__c',
		],
	},
	{
		label: 'Account Id',
		value: '',
		transcriptFields: [
			'AccountId',
		],
	},
	{
		label: 'Contact Id',
		value: '',
		transcriptFields: [
			'ContactId',
		],
	},
	{
		label: 'Name',
		value: '',
		transcriptFields: [
			'Visitor_Name__c',
		],
	},
	{
		label: 'Email',
		value: '',
		transcriptFields: [
			'Visitor_s_Email__c',
		],
	},
	{
		label: 'How Help',
		value: '',
		transcriptFields: [
			'How_Can_We_Help__c',
		],
	},
	{
		label: 'Company',
		value: '',
		transcriptFields: [
			'Company__c',
		],
	},
	{
		label: 'Subject',
		value: '',
		transcriptFields: [
			'Subject__c',
		],
	},
	{
		label: 'Department',
		value: '',
		transcriptFields: [
			'Requested_Department__c',
		],
	},
	{
		label: 'PII Restricted Country',
		value: '',
		transcriptFields: [
			'PII_Restricted_Country__c',
		],
	},
	{
		label: 'PII Data Processing',
		value: '',
		transcriptFields: [
			'PII_Data_Processing__c',
		],
	},
	{
		label: 'PII Contact',
		value: '',
		transcriptFields: [
			'PII_Marketing_Contact__c',
		],
	},
	{
		label: 'PII Detected Country',
		value: '',
		transcriptFields: [
			'PII_Detected_Country__c',
		],
	},
	{
		label: 'Authenticated',
		value: '',
		transcriptFields: [
			'Authentication_Status__c',
		],
	},
	{
		label: 'Type',
		value: '',
		transcriptFields: [
			'Type__c',
		],
	},
	{
		label: 'SubType',
		value: '',
		transcriptFields: [
			'SubType__c',
		],
	},
	{
		label: 'SupportPIN',
		value: '',
		transcriptFields: [
			'Visitor_PIN__c',
		],
	},
	{
		label: 'AccountNumber',
		value: '',
		transcriptFields: [
			'Visitor_Account_Number__c',
		],
	},
];

export default getInitialPrechatFormDetails;
