import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.account.ssh.key.add;

const { actions, reducer, sagas, selectors } = createAPIModule({
	getStateSlice,
	actionType: 'ACCOUNT_SSH_KEY_ADD',
	method: 'POST',
	url: 'account/ssh/key/add.json',
});

export { actions, reducer, sagas, selectors };
