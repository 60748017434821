import countryRegionData from 'country-region-data';

const countriesMappedToOptions = () =>
	countryRegionData
		.filter(
			(country) =>
				// from LWConfig/Country.yaml
				!['CU', 'IR', 'KP', 'SY'].includes(country.countryShortCode),
		)
		.map((country) => ({
			label: country.countryName,
			value: country.countryShortCode,
		}));

export default countriesMappedToOptions;
