import { Map as ImmutableMap, List as ImmutableList } from 'immutable';
import actions from './snackbarActions';

const initialState = ImmutableMap({
	isOpen: false,
	messages: ImmutableList(),
	color: null, // error, success, info, warning
	showIcon: null,
	autoHideDuration: 6000,
});

const snackbarReducer = (state = initialState, action) => {
	switch (action.type) {
		case actions.pushType:
			return state
				.set('isOpen', true)
				.update('messages', (messages) => messages.push(action.message))
				.set('color', action.color)
				.set('showIcon', action.showIcon)
				.set('autoHideDuration', action.autoHideDuration);
		case actions.shiftType:
			return (
				state
					.update('messages', (messages) => messages.shift())
					// eslint-disable-next-line no-confusing-arrow
					.update('isOpen', (isOpen) =>
						state.get('messages').size === 1 ? false : isOpen,
					)
					.set('color', null)
					.set('showIcon', null)
					.set('autoHideDuration', 6000)
			);
		default:
			return state;
	}
};

export default snackbarReducer;
