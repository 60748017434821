import { createSelector } from 'reselect';
import { bytes, toGibibyte, perMonth } from 'utility/format';
import { formValueSelector } from 'redux-form';
import { removeUndefinedKeys } from 'utility/tools/objects';
import { middleValues } from 'utility/middleValue';

import { selectors } from '.';

const formName = 'ConfigIdSliders';
const minOptionsToShowFilters = 5;

const formDataSelector = (state) => ({
	cores: formValueSelector(formName)(state, 'cores') || [],
	ram: formValueSelector(formName)(state, 'ram') || [],
	storage: formValueSelector(formName)(state, 'disk') || [],
	disk: formValueSelector(formName)(state, 'diskType') || [],
	filtersDisabled:
		formValueSelector(formName)(state, 'filtersDisabled') || false,
});

const selectedSize = createSelector(
	selectors.configId,
	(slice) => slice?.value,
);

const selectedPrice = createSelector(
	selectors.configId,
	(slice) => slice?.price,
);

const rawOptions = createSelector(
	selectors.configId,
	(slice) => slice?.options,
);

const currentExtraData = createSelector(
	selectors.configId,
	(slice) => slice?.extra_data,
);

const currentCores = createSelector(
	currentExtraData,
	(slice) => slice?.cores,
);

const currentRamRaw = createSelector(
	currentExtraData,
	(slice) => slice?.memory,
);

const currentRam = createSelector(
	currentRamRaw,
	(slice) => toGibibyte(slice),
);

const currentStorageRaw = createSelector(
	currentExtraData,
	(slice) => slice?.storage_size,
);

const currentStorage = createSelector(
	currentStorageRaw,
	(slice) => bytes(slice),
);

const isVPS = createSelector(
	selectors.productCode,
	(productCode) => /vps/i.test(productCode),
);

const isVM = createSelector(
	selectors.productCode,
	(productCode) => /vm/i.test(productCode),
);

const displayType = createSelector(
	isVM,
	(isVM_) => (isVM_ ? 'ProductOptionTable' : 'ProductOptionSet'),
);

const createOptionTileData = (
	{
		value,
		price_total: price,
		extra_data: {
			cpu_description: cpuRaw,
			cpu_speed: speed,
			cores,
			memory,
			storage_type: storageType,
			storage_size: storageSize,
			storage_raid: raid,
			cpu_hyperthreading: hyperthreading,
		} = {},
	},
	isVPS_, // passed in explicitly (this is not a selector)
	displayType_, // passed in explicitly (this is not a selector)
) => {
	const cpu = cpuRaw?.replace(/\s+/, ' ');
	const storageRaid = `${bytes(storageSize)}${
		storageType ? `, ${storageType}` : ''
	}${raid ? `, Raid-${raid}` : ''}`;

	const tableData = removeUndefinedKeys({
		cores: cores
			? {
					// TODO: hyperthreaded?
					display: `${cores}${
						speed ? ` @ ${Math.round(speed / 100) / 10}GHz` : ''
					}`,
					iconKey: hyperthreading ? 'HyperThreadedIcon' : '',
					value: cores.toString(),
			  }
			: undefined,
		// These properties drive the headers, so making them undefined keeps them from making a header.
		cpu: cpu ? { display: cpu, value: cpu } : undefined,
		ram: memory
			? { display: toGibibyte(memory), value: memory.toString() }
			: undefined,
		storage: storageSize
			? { display: storageRaid, value: storageSize.toString() }
			: undefined,
		price: price
			? { display: perMonth(price), value: price.toString() }
			: undefined,
	});

	const productOptionData = {
		value,
		data: price,
		price,
		listItems: [
			{
				value: `${isVPS_ ? 'vCPUs' : 'Cores'}: ${cores}`,
				key: 'vcpus',
				toolTipText: cpu,
			},
			...(speed
				? [
						{
							value: `Speed: ${speed}MHz`,
							key: 'speed',
						},
				  ]
				: []),
			{ value: `Ram: ${toGibibyte(memory)}`, key: 'ram' },
			{
				value: `Storage: ${storageRaid}`,
				key: 'storage',
			},
		],
	};

	// These are for filtering the tiles, not for the display.
	const filterProps = {
		cores: Number(cores),
		ram: Number(memory),
		storage: Number(storageSize),
		disk: storageType,
		value, // needed for filtering since we always show the selected value.
	};
	switch (displayType_) {
		case 'ProductOptionTable':
			return { productOptionData, tableData, ...filterProps };
		default:
			// ProductOptionSet
			return { ...productOptionData, ...filterProps };
	}
};

const selectedConfig = createSelector(
	selectors.configId,
	isVPS,
	displayType,
	(slice, isVPS_, displayType_) =>
		slice ? createOptionTileData(slice, isVPS_, displayType_) : null,
);

// returns an array designed to be consumed by ProductOptionSet or ProductOptionTable (controlled by displayType).
const sizeOptions = createSelector(
	rawOptions,
	isVPS,
	selectedConfig,
	displayType,
	(slice, isVPS_, selected, displayType_) => {
		if (slice)
			return [
				selected,
				...slice?.map((elem) =>
					createOptionTileData(elem, isVPS_, displayType_),
				),
			].filter((elem) => elem.value !== '0'); // Don't show a selected value of 0 (This is possible if coming from a deploy onto of private parent.)
		return [];
	},
);

// Builds the headersData out of the options. ProductOptionTable needs this.
// doing this dynamically so that it can work for more than just VM if it ever needs to.
const headerData = createSelector(
	sizeOptions,
	(options) =>
		Array.from(
			new Set(
				options.map(({ tableData }) => Object.keys(tableData || {})).flat(),
			),
		)
			.map((headerKey) => {
				switch (headerKey) {
					case 'cpu':
						return { key: headerKey, label: 'CPU(s)', order: 1 };
					case 'cores':
						return { key: headerKey, label: 'Cores / Speed', order: 2 };
					case 'ram':
						return { key: headerKey, label: 'Ram*', order: 3 };
					case 'storage':
						return { key: headerKey, label: 'Storage*', order: 4 };
					case 'price':
						return { key: headerKey, label: 'Price', order: 5 };
					default:
						return { key: headerKey, label: headerKey, order: Infinity };
				}
			})
			.sort((a, b) => a.order - b.order),
);

// TODO: this was copied from src/modules/api/server/availableConfigsModule.js. Make this function shared.
const sortedSet = (options) =>
	Array.from(new Set(options.filter((elem) => elem).sort((a, b) => a - b)));

const coresOptions = createSelector(
	sizeOptions,
	(slice) => slice && sortedSet(slice.map((elem) => elem.cores)),
);

const ramOptions = createSelector(
	sizeOptions,
	(slice) => slice && sortedSet(slice.map((elem) => elem.ram)),
);

const storageOptions = createSelector(
	sizeOptions,
	(slice) => slice && sortedSet(slice.map((elem) => elem.storage)),
);

// returns an array designed to be consumed by LWSelect.
const getDiskOptions = (tileData) => {
	const options = Array.from(
		new Set(
			tileData?.filter(({ disk: option }) => option).map(({ disk }) => disk),
		),
	);
	return (
		options?.map((option) => ({
			value: option,
			label: option,
		})) || []
	);
};

const diskOptions = createSelector(
	sizeOptions,
	(slice) => {
		return getDiskOptions(slice);
	},
);

const coresDefault = createSelector(
	coresOptions,
	(slice) => middleValues(slice),
);

const ramDefault = createSelector(
	ramOptions,
	(slice) => middleValues(slice),
);

const storageDefault = createSelector(
	storageOptions,
	(slice) => middleValues(slice),
);

const filteredOptions = createSelector(
	formDataSelector,
	sizeOptions,
	selectedSize,
	(sliderValues, options, selectedValue) => {
		const {
			cores: coresFilter,
			ram: ramFilter,
			storage: storageFilter,
			disk: diskFilter,
			filtersDisabled,
		} = sliderValues;
		if (filtersDisabled || options.length < minOptionsToShowFilters)
			return options;
		return options.filter(({ cores, ram, storage, disk, value }) => {
			if (value === selectedValue) return true;
			return (
				cores >= coresFilter[0] &&
				cores <= coresFilter[1] &&
				ram >= ramFilter[0] &&
				ram <= ramFilter[1] &&
				storage >= storageFilter[0] &&
				storage <= storageFilter[1] &&
				(diskFilter.length === 0 || diskFilter.includes(disk))
			);
		});
	},
);

const totalOptions = createSelector(
	sizeOptions,
	(options) => options?.length || 0,
);

const filterProps = createSelector(
	coresOptions,
	ramOptions,
	storageOptions,
	diskOptions,
	currentCores,
	currentRam,
	currentStorage,
	isVPS,
	(
		coresOptions_,
		ramOptions_,
		storageOptions_,
		diskOptions_,
		currentCores_,
		currentRam_,
		currentStorage_,
		isVPS_,
	) => ({
		cores: {
			options: coresOptions_ || [],
			virtual: isVPS_,
			current: currentCores_,
		},
		ram: {
			options: ramOptions_ || [],
			current: currentRam_,
		},
		disk: {
			options: storageOptions_ || [],
			current: currentStorage_,
			diskType: {
				options: diskOptions_,
			},
		},
		formName,
	}),
);

export { formName, minOptionsToShowFilters };
export default {
	selectedSize,
	selectedPrice,
	rawOptions,
	sizeOptions,
	coresOptions,
	ramOptions,
	storageOptions,
	diskOptions,
	coresDefault,
	ramDefault,
	storageDefault,
	filteredOptions,
	displayType,
	headerData,
	totalOptions,
	currentExtraData,
	currentCores,
	currentRamRaw,
	currentRam,
	currentStorageRaw,
	currentStorage,
	filterProps,
	isVPS,
	isVM,
};
