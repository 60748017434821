import React from 'react';
import DefaultContent from './DefaultContent';
import ClientFileReplication from './ClientFileReplication';
import PrimaryClusterable from './PrimaryClusterable';
import ReadOnlyClusterable from './ReadOnlyClusterable';
import ErrorContent from './ErrorContent';

class DialogStates {
	constructor(props) {
		Object.entries(props).forEach((entry) => {
			const [prop, value] = entry;
			this[prop] = value;
		});
	}

	errorTitle = 'That Was Unexpected';

	get confirmText() {
		if (this.showClusterVerbiage) {
			return 'Confirm';
		}
		return this.assetCurrentProject.uniqId
			? 'Transfer to this Project'
			: 'Add to Project';
	}

	get title() {
		if (this.showClusterVerbiage) {
			switch (this.asset.potential_role) {
				case 'client':
					return 'File Replication Enabled';
				case 'primary':
				case 'read-only':
					return `Database Clustering for ${this.asset.domain}`;
				default:
					return this.errorTitle;
			}
		}
		return 'Add Asset to Project';
	}

	get contents() {
		if (this.showClusterVerbiage) {
			switch (this.asset.potential_role) {
				case 'client':
					return <ClientFileReplication />;
				case 'primary':
					return <PrimaryClusterable priceData={this.priceData} />;
				case 'read-only':
					return <ReadOnlyClusterable />;
				default:
					return <ErrorContent />;
			}
		}
		return (
			<DefaultContent
				categoriesData={this.categoriesData}
				projectName={this.projectName}
				category={this.category}
				changeCategory={this.changeCategory}
				availableAssetsData={this.availableAssetsData}
				asset={this.asset}
				assetCurrentProject={this.assetCurrentProject}
				changeAsset={this.changeAsset}
			/>
		);
	}
}

export default DialogStates;
