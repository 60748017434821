import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';

const getStateSlice = (state) => state.network.dns.zone.delegation;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'NETWORK_DNS_ZONE_DELEGATION',
	method: 'POST',
	url: '/network/dns/zone/delegation.json',
});

const delegation = createSelector(
	getStateSlice,
	(slice) => slice.getIn(['data', 'delegation'], null),
);

const selectors = {
	delegation,
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };
