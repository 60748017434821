import React from 'react';
import LWTypography from 'components/common/LWTypography';

const Instructions = ({ projectOnly, productName }) => {
	if (projectOnly) {
		return (
			<LWTypography>
				Select or type in a project name to add {<b>{productName}</b>} to.
			</LWTypography>
		);
	}
	return (
		<>
			<LWTypography>
				To generate a hostname for your <b>{productName}</b>:
			</LWTypography>
			<LWTypography color="textSecondary" variant="body2">
				A. Select or type in a domain, then
			</LWTypography>
			<LWTypography color="textSecondary" variant="body2">
				B. Select or type in a project name to generate a sub-domain, and/or
			</LWTypography>
			<LWTypography color="textSecondary" variant="body2">
				C. Type in a custom sub-domain.
			</LWTypography>
		</>
	);
};
export default Instructions;
