import React from 'react';
import LWTypography from 'components/common/LWTypography';
import CheckedIcon from '@material-ui/icons/CheckCircle';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import styled from 'styled-components';

const SLWTypography = styled(LWTypography)`
	font-size: 0.75rem;
	color: ${(p) => p.theme.palette.primary.dark};
	font-weight: bold;
	padding-left: 10px;
`;

const Icon = ({ isLoading }) => {
	if (isLoading) return <CircularProgress size={20} />;
	return <CheckedIcon color="primary" />;
};

const Selected = ({ isLoading }) => (
	<Box display="inline-flex" alignItems="center">
		<Icon isLoading={isLoading} />
		<SLWTypography>Selected</SLWTypography>
	</Box>
);

export default Selected;
