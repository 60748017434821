const mockUsageData = {
	'424QV0': {
		usages: {
			servers: {
				quota: {
					allowed_overage: 0,
					max_usage: 1,
				},
				unit: 'quantity',
				value: 0,
			},
			storage: {
				quota: {
					allowed_overage: 0,
					max_usage: 1073741824000,
				},
				unit: 'bytes',
				value: 1073741824000 / 5,
			},
			vms: {
				quota: {
					allowed_overage: 0,
					max_usage: 0,
				},
				unit: 'quantity',
				value: 0,
			},
			web_hosting_servers: {
				quota: {
					allowed_overage: 0,
					max_usage: 0,
				},
				unit: 'quantity',
				value: 0,
			},
			websites: {
				quota: {
					allowed_overage: 0,
					max_usage: 0,
				},
				unit: 'quantity',
				value: 0,
			},
		},
	},
};

export default mockUsageData;
