import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Delete from '@material-ui/icons/Delete';
import classnames from 'classnames';
import analyticsActions from 'modules/gtm/actions';
import eventCategories from 'utility/constants/analyticEventCategories';
import LWTooltip from 'components/common/LWTooltip';
import dialogActions from 'modules/dialogs/actions';
import { compose } from 'redux';
import { connect } from 'react-redux';

const styles = (theme) => ({
	root: {
		display: 'flex',
	},
	popper: {
		zIndex: 999,
	},
	iconButton: {
		border: `1px solid ${theme.palette.divider}`,
		background: theme.palette.common.white,
		color: theme.palette.text.primary,
		'&:hover': {
			background: theme.palette.common.grey2,
		},
	},
	menuItem: {
		color: theme.palette.common.grey[7],
	},
	menuItemDestroy: {
		backgroundColor: theme.palette.background.red,
		color: '#fff',
		textAlign: 'center',
	},
	menuListHorizontal: {
		[theme.breakpoints.up('sm')]: {
			display: 'flex',
		},
	},
});

const RowActions = ({
	classes,
	rowActions,
	rowData,
	tooltip,
	light,
	destroy,
	disableDestroyButton,
	sendEvent,
	placement,
	openDialog, // from connected
}) => {
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const anchorRef = useRef(null);

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}
		setIsMenuOpen(false);
	};

	const handleClick = () => {
		setIsMenuOpen(!isMenuOpen);
	};

	if (tooltip) {
		return (
			<div className={classes.root}>
				<LWTooltip>{tooltip}</LWTooltip>
			</div>
		);
	}

	const menuListClasses = { padding: classes.menuListPadding };
	return (
		<div className={classes.root} data-testid="RowActions__Container">
			<IconButton
				className={classes.iconButton}
				onClick={handleClick}
				ref={anchorRef}
				variant="outlined"
			>
				<MoreVertIcon
					className={classnames({
						[classes.closeIcon]: isMenuOpen,
						[classes.light]: light,
					})}
				/>
			</IconButton>
			<Popper
				anchorEl={anchorRef.current}
				open={isMenuOpen}
				className={classes.popper}
				transition
				disablePortal
				modifiers={{
					flip: {
						enabled: false,
					},
					preventOverflow: {
						enabled: true,
					},
				}}
				placement={placement}
			>
				{({ TransitionProps }) => (
					<Grow {...TransitionProps} style={{ transformOrigin: 'right top' }}>
						<Paper>
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList classes={menuListClasses} onClick={handleClose}>
									{rowActions &&
										rowActions.map((action) => (
											<MenuItem
												key={action.name}
												className={classes.menuItem}
												onClick={() => {
													sendEvent({
														category: eventCategories.USER_INTERACTION,
														action: action.name,
														label: 'Row Options',
													});
													// The second arg is for functions commonly used in rowActionsHelpers.js
													action.onClick(rowData, { openDialog });
												}}
											>
												{action.name}
											</MenuItem>
										))}
									{destroy && (
										<MenuItem
											key="destroy"
											className={classes.menuItemDestroy}
											disabled={disableDestroyButton(rowData)}
											onClick={() => {
												destroy(rowData);
											}}
										>
											<Delete />
										</MenuItem>
									)}
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</div>
	);
};

RowActions.defaultProps = {
	light: false,
	rowActions: undefined,
	tooltip: null,
	destroy: undefined,
	disableDestroyButton: () => false,
	rowData: null,
	placement: 'bottom-end',
};

RowActions.propTypes = {
	classes: PropTypes.object.isRequired,
	rowActions: PropTypes.arrayOf(
		PropTypes.shape({
			sendEvent: PropTypes.bool,
			name: PropTypes.string.isRequired,
			onClick: PropTypes.func.isRequired,
		}),
	),
	rowData: PropTypes.object,
	tooltip: PropTypes.string,
	light: PropTypes.bool,
	destroy: PropTypes.func,
	disableDestroyButton: PropTypes.func,
	sendEvent: PropTypes.func.isRequired,
	placement: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => ({
	sendEvent: (payload) =>
		dispatch(analyticsActions.sendAnalyticsEvent(payload)),
	openDialog: ({ title, content, dialogProps, color }) =>
		dispatch(dialogActions.open({ title, content, dialogProps, color })),
});

export { RowActions };

export default compose(
	withStyles(styles),
	connect(
		null,
		mapDispatchToProps,
	),
)(RowActions);
