import React from 'react';
import ErrorIcon from '@material-ui/icons/Error';
import Info from '@material-ui/icons/Info';
import Warning from '@material-ui/icons/Warning';

const displayTypes = {
	ERROR: {
		name: 'error',
		icon: (props) => <ErrorIcon {...props} />,
	},
	WARNING: {
		name: 'warning',
		icon: (props) => <Warning {...props} />,
	},
	INFO: {
		name: 'info',
		icon: (props) => <Info {...props} />,
	},
	DEFAULT: {
		name: '',
		icon: null,
	},
};

export default displayTypes;
