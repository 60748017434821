import React from 'react';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';
import LWTypography from 'components/common/LWTypography';
import PropTypes from 'prop-types';

const flexDirectionOptions = ['row', 'column', 'row-reverse', 'column-reverse'];

const spacingDirection = {
	row: 'ml',
	column: 'mt',
	'row-reverse': 'mr',
	'column-reverse': 'mb',
};

const LabeledIcon = ({
	text,
	textProps,
	flexDirection = 'row',
	icon,
	color = 'primary.main',
	spacing = 0.5,
	isLoading = false,
}) => (
	<Box
		display="inline-flex"
		flexDirection={flexDirection}
		color={color}
		alignItems="center"
	>
		{isLoading ? <Skeleton variant="circle" width="1em" height="1em" /> : icon}
		<Box {...{ [spacingDirection[flexDirection]]: spacing }}>
			<LWTypography isLoading={isLoading} {...textProps}>
				{text}
			</LWTypography>
		</Box>
	</Box>
);

LabeledIcon.propTypes = {
	/** The text of your labeled icon. */
	text: PropTypes.string,
	/** supply props to the contained LWTypography. */
	textProps: PropTypes.object,
	/** Use this to change the orientation and order of the arrangement. */
	flexDirection: PropTypes.oneOf(flexDirectionOptions),
	/** The icon you are labeling */
	icon: PropTypes.node,
	/** The color of the icon. */
	color: PropTypes.string,
	/** Spacing between icon and text */
	spacing: PropTypes.number,
	/** Show loading state */
	isLoading: PropTypes.bool,
};

export default LabeledIcon;
