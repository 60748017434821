import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'utility/compose';
import { withStyles } from '@material-ui/core/styles';
import UserProfileIcon from '@material-ui/icons/PersonRounded';
import LWLink from 'components/common/LWLink';
import { selectors as appConfigSelectors } from 'modules/api/appConfigModule';

const styles = () => ({
	root: {
		display: 'flex',
	},
});

const UserProfile = ({ classes, idpHostname, idpClientId }) => (
	<LWLink
		classes={{ root: classes.root }}
		aria-label="User Profile"
		to={`${idpHostname}/user/?client_id=${idpClientId}`}
	>
		<UserProfileIcon />
	</LWLink>
);

UserProfile.propTypes = {
	classes: PropTypes.object.isRequired,
	idpHostname: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
	idpHostname: appConfigSelectors.getOpenIdConfig(state).get('authority'),
	idpClientId: appConfigSelectors.getOpenIdConfig(state).get('client_id'),
});

export { UserProfile };

export default compose(
	connect(mapStateToProps),
	withStyles(styles),
)(UserProfile);
