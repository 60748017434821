import { connect } from 'react-redux';
import analyticsActions from 'modules/gtm/actions';
import LWLink from './LWLink';

const mapDispatchToProps = (dispatch) => ({
	sendEvent: (payload) =>
		dispatch(analyticsActions.sendAnalyticsEvent(payload)),
});

export default connect(
	null,
	mapDispatchToProps,
)(LWLink);
