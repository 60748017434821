import React from 'react';
import ReactSelect from 'react-select';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import Switch from '@material-ui/core/Switch';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { emphasize } from '@material-ui/core/styles/colorManipulator';

const styles = (theme) => ({
	root: {},
	inputRoot: {
		color: theme.palette.other.inputText,
	},
	label: {
		color: theme.palette.other.inputLabel,
	},
});

const RenderFormHelper = ({ touched, error }) => {
	if (!(touched && error)) {
		return null;
	}
	return <FormHelperText>{error}</FormHelperText>;
};

RenderFormHelper.propTypes = {
	touched: PropTypes.bool.isRequired,
	error: PropTypes.string,
};

RenderFormHelper.defaultProps = {
	error: null,
};

const renderTextField = withStyles(styles)(
	({
		classes,
		label,
		input,
		InputProps,
		type = 'text',
		meta: { touched, invalid, error },
		...custom
	}) => (
		<TextField
			InputLabelProps={{
				classes: {
					root: classes.label,
				},
			}}
			InputProps={{
				classes: {
					root: classes.inputRoot,
				},
				...InputProps,
			}}
			classes={{
				root: classes.root,
			}}
			label={label}
			placeholder={label}
			error={touched && invalid}
			helperText={touched && error}
			type={type}
			{...input}
			{...custom}
		/>
	),
);

const checkBoxStyles = (theme) => ({
	formControlLabel: {
		color: theme.palette.text.primary,
	},
});

const renderCheckbox = withStyles(checkBoxStyles)(
	({ classes, input, label, div }) => (
		<div>
			<FormControlLabel
				classes={{
					label: classes.formControlLabel,
				}}
				control={
					<Checkbox color="primary" checked={!!input.value} {...input} />
				}
				label={label}
			/>
		</div>
	),
);

renderCheckbox.propTypes = {
	input: PropTypes.object.isRequired,
	label: PropTypes.string.isRequired,
};

const renderSwitch = ({
	label,
	labelPlacement,
	checked,
	color,
	disabled,
	input,
	handleChange,
	value,
}) => (
	<FormControlLabel
		control={
			<Switch
				color={color}
				checked={checked}
				disabled={disabled}
				onChange={input?.onChange || handleChange}
				value={input?.value || value}
			/>
		}
		label={label}
		labelPlacement={labelPlacement}
	/>
);

const renderRadioGroup = ({ classes, input, ...otherProps }) => (
	<RadioGroup {...input} {...otherProps} />
);

const renderSelectField = withStyles(styles)(
	({
		classes,
		input,
		label,
		meta: { touched, error },
		children,
		...custom
	}) => (
		<FormControl className={classes.root} error={touched && error} fullWidth>
			{label && <InputLabel>{label}</InputLabel>}
			<Select {...input} {...custom}>
				{children}
			</Select>
			<RenderFormHelper touched={touched} error={error} />
		</FormControl>
	),
);

// Caleb added this one because the above renderer does not display properly for variant 'outlined'.
// We should also not be begining our functional components with the prefix 'render' and lowercased.
const SelectField = ({
	options = [],
	input,
	meta: { touched, invalid, error },
	...rest
}) => (
	<TextField
		select
		{...rest}
		{...input}
		error={touched && invalid}
		helperText={touched && error}
	>
		{options.map(({ label, value }) => (
			<MenuItem value={value} key={value}>
				{label}
			</MenuItem>
		))}
	</TextField>
);

// The following styles and components are adapted from them Material UI react-select example
// https://v3.material-ui.com/demos/autocomplete/#autocomplete

const reactSelectStyles = (theme) => ({
	root: {
		flexGrow: 1,
		height: 250,
	},
	input: {
		display: 'flex',
		alignItems: 'center',
	},
	valueContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		flex: 1,
		alignItems: 'center',
		overflow: 'hidden',
	},
	chip: {
		margin: `${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`,
	},
	chipFocused: {
		backgroundColor: emphasize(
			theme.palette.type === 'light'
				? theme.palette.common.grey[3]
				: theme.palette.common.grey[7],
			0.08,
		),
	},
	noOptionsMessage: {
		padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
	},
	singleValue: {
		fontSize: 16,
	},
	disabled: {
		color: theme.palette.common.grey[3],
	},
	placeholder: {
		fontSize: 16,
	},
	paper: {
		position: 'absolute',
		zIndex: 2,
		marginTop: theme.spacing(1),
		left: 0,
		right: 0,
	},
	divider: {
		height: theme.spacing(2),
	},
});
const NoOptionsMessage = ({ selectProps, innerProps, children }) => (
	<Typography
		color="textSecondary"
		className={selectProps.classes.noOptionsMessage}
		{...innerProps}
	>
		{children}
	</Typography>
);

const inputComponent = ({ inputRef, ...props }) => (
	<div ref={inputRef} {...props} />
);

const Control = ({ selectProps, innerRef, innerProps, children }) => {
	const noValue = !selectProps.value?.value || selectProps.value.value === '';
	return (
		<TextField
			fullWidth
			InputProps={{
				inputComponent,
				inputProps: {
					className: selectProps.classes.input,
					inputRef: innerRef,
					children,
					...innerProps,
				},
			}}
			label={noValue ? selectProps.label : null}
			variant="outlined"
			{...selectProps.textFieldProps}
		/>
	);
};

const Option = ({ innerRef, isFocused, isSelected, innerProps, children }) => (
	<MenuItem
		buttonRef={innerRef}
		selected={isFocused}
		component="div"
		style={{
			fontWeight: isSelected ? 500 : 400,
		}}
		{...innerProps}
	>
		{children}
	</MenuItem>
);

const Placeholder = ({ selectProps, innerProps, children }) => (
	<Typography
		color="textSecondary"
		className={selectProps.classes.placeholder}
		{...innerProps}
	>
		{children}
	</Typography>
);

const SingleValue = ({ selectProps, innerProps, children }) => (
	<Typography
		className={classNames({
			[selectProps.classes.disabled]: selectProps.isDisabled,
			[selectProps.classes.singleValue]: true,
		})}
		{...innerProps}
	>
		{children}
	</Typography>
);

const ValueContainer = ({ selectProps, children }) => (
	<div className={selectProps.classes.valueContainer}>{children}</div>
);

ValueContainer.propTypes = {
	selectProps: PropTypes.object.isRequired,
	children: PropTypes.node.isRequired,
};

const MultiValue = ({ children, selectProps, isFocused, removeProps }) => (
	<Chip
		tabIndex={-1}
		label={children}
		className={classNames(selectProps.classes.chip, {
			[selectProps.classes.chipFocused]: isFocused,
		})}
		onDelete={removeProps.onClick}
		deleteIcon={<CancelIcon {...removeProps} />}
	/>
);

const Menu = ({ selectProps, innerProps, children }) => (
	<Paper square className={selectProps.classes.paper} {...innerProps}>
		{children}
	</Paper>
);

const components = {
	Control,
	Menu,
	MultiValue,
	NoOptionsMessage,
	Option,
	Placeholder,
	SingleValue,
	ValueContainer,
};

// Adapted from https://github.com/JedWatson/react-select/issues/1129
const renderReactSelect = withStyles(reactSelectStyles)(
	({ classes, input, options, ...rest }) => (
		<ReactSelect
			components={components}
			classes={classes}
			{...input}
			onBlur={() => {}}
			options={options}
			{...rest}
		/>
	),
);

// options object (passed into a redux-forms "Field" component via the "component" prop) is expected to be [{ label, value }].
// You must supply an id, or your snapshots will fail. Convention is <fileName>__<fieldname>.
const renderAutocomplete = ({ input, label, options, ...rest }) => (
	<Autocomplete
		value={input.value}
		getOptionLabel={(option) => option.label || option}
		getOptionSelected={({ value: optionValue }, { value: selectedValue }) => {
			if (!selectedValue) return optionValue === options[0].value;
			return optionValue === selectedValue;
		}}
		onChange={(e, value) => {
			input.onChange(value);
		}}
		renderInput={(params) => (
			<TextField
				{...params}
				inputProps={{
					...params.inputProps,
					'data-lpignore': true,
					autoComplete: 'nope',
				}}
				label={label}
				variant="outlined"
			/>
		)}
		{...{ options, ...rest }}
	/>
);

renderAutocomplete.propTypes = {
	id: PropTypes.string.isRequired,
	input: PropTypes.object.isRequired,
	label: PropTypes.string,
	options: PropTypes.arrayOf(
		PropTypes.shape({ label: PropTypes.string, value: PropTypes.string }),
	),
};

export {
	renderTextField,
	renderCheckbox,
	RenderFormHelper,
	renderSelectField,
	SelectField,
	renderRadioGroup,
	renderReactSelect,
	renderSwitch,
	renderAutocomplete,
};
