import React from 'react';
import Grid from '@material-ui/core/Grid';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import InputLabel from './InputLabel';

const DomainInput = ({
	showLabels,
	domain,
	setDomain,
	setInputDomain,
	inputDomain,
	domains,
	isLoading,
}) => (
	<Grid item container alignItems="center">
		{showLabels && (
			<Grid item>
				<InputLabel>A.</InputLabel>
			</Grid>
		)}
		<Grid xs item>
			<Autocomplete
				freeSolo
				fullWidth
				value={domain}
				onChange={(event, newValue) => {
					setDomain(newValue);
				}}
				inputValue={inputDomain}
				onInputChange={(event, newInputValue) => {
					// This is triggered on mount with a null event and empty newInputValue
					// that overwrites the default set in the state initialization
					if (event) setInputDomain(newInputValue);
				}}
				options={domains}
				renderInput={(params) => (
					<TextField
						{...params}
						inputProps={{ ...params.inputProps, 'data-lpignore': true }}
						label="Domain"
						variant="outlined"
						helperText={isLoading ? 'loading domains...' : ''}
					/>
				)}
			/>
		</Grid>
	</Grid>
);

DomainInput.propTypes = {
	showLabels: PropTypes.bool,
	domain: PropTypes.string,
	setDomain: PropTypes.func,
	setInputDomain: PropTypes.func,
	inputDomain: PropTypes.string,
	domains: PropTypes.array,
};

export default DomainInput;
