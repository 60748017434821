import React, { useState, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import LabeledText from 'components/atoms/LabeledText';
import LWSelect from 'components/atoms/LWSelect';
// The "About" button is not part of phase 1.
// import LWButton from 'components/common/LWButton';
import PropTypes from 'prop-types';
import { currency } from 'utility/format';
import isStorm from 'utility/isStorm';
import getMarketplacePrice from 'utility/products/getMarketplacePrice';
import { ActionButtons } from './SideSheetContentsAtoms';

const getLowestRegionId = (marketplacePrices) => {
	if (!marketplacePrices) return 1; // (Lansing)
	const regions = marketplacePrices.map((data) => data.region_id);
	return Math.min(...regions);
};

const SideSheetContents = ({
	quickAdd, // from connected version
	itemsInCart, // from connected version
	quickAddIsLoading, // from connected version
	productCode,
	details,
	fullDescription,
	description,
	features,
	bundleFeatures,
	productTags,
	showConfigure = true,
	marketplacePrices,
	// The "About" button is not part of phase 1.
	// aboutButtonText,
	// icon,
}) => {
	// We will consider whatever is the lowest region id to be the default one for pricing
	//
	// Products will generally be either available in most regions, and we'll want the default to be Lansing region,
	// or they will just be in a single region, which will still get set with this method
	const [region, setRegion] = useState(getLowestRegionId(marketplacePrices));

	// This component will be loaded sometimes before the data is available, need to update the region data when the marketplacePrices is updated
	useEffect(() => {
		setRegion(getLowestRegionId(marketplacePrices));
	}, [marketplacePrices]);
	if (!marketplacePrices || !productCode) return null;

	const { price, priceUnit } = getMarketplacePrice({
		marketplacePrices,
		region,
	});

	return (
		<Box px={4} py={2}>
			<LabeledText label="Details" text={details} />
			{isStorm(productCode) && (
				<LWSelect
					options={marketplacePrices?.map((priceData) => {
						const { region_id: value, region_name: label } = priceData;
						return { value, label };
					})}
					label="Region"
					value={region}
					onChange={(value) => {
						setRegion(value);
					}}
				/>
			)}
			<LabeledText label="Price" text={`${currency(price)} / ${priceUnit}`} />
			<ActionButtons
				{...{
					quickAddIsLoading,
					quickAdd,
					productCode,
					itemsInCart,
					showConfigure,
					region,
				}}
			/>
			<LabeledText label="Description" text={fullDescription?.split('\n')} />
			<LabeledText label="Hardware Summary" text={description} />
			<LabeledText label="Features" text={features} />
			<LabeledText label="Bundle Features" text={bundleFeatures} />
			<LabeledText label="Tags" text={productTags && productTags.join(', ')} />
			{/* The "About" button is not part of phase 1. */}
			{/* <LWButton variant="outlined" color="tertiary">
				{icon}
				<Box ml={1}>{aboutButtonText}</Box>
			</LWButton> */}
		</Box>
	);
};
// The "About" button is not part of phase 1.
// SideSheetContents.defaultProps = {
// icon: null,
// };

SideSheetContents.propTypes = {
	/** See ActionButtons.jsx documentation */
	quickAdd: PropTypes.func,
	/** See ActionButtons.jsx documentation */
	itemsInCart: PropTypes.number,
	/** See ActionButtons.jsx documentation */
	productCode: PropTypes.string,
	/** See ActionButtons.jsx documentation */
	quickAddIsLoading: PropTypes.bool,
	/** Text that appears below the Details header. */
	details: PropTypes.string,
	/** Text that appears below the Price header. */
	fullDescription: PropTypes.string,
	/** Text that appears below the Hardware Summary header. */
	description: PropTypes.string,
	/** An array of strings that appears below the Features header. */
	features: PropTypes.arrayOf(PropTypes.string),
	/** An array of strings that appears below the Bundle Features header. */
	bundleFeatures: PropTypes.arrayOf(PropTypes.string),
	/** An array of strings that appears below the Tags header. */
	productTags: PropTypes.arrayOf(PropTypes.string),
	// The "About" button is not part of phase 1.
	// aboutButtonText: PropTypes.string.isRequired,
	// icon: PropTypes.object,
	/** Display the configure button */
	showConfigure: PropTypes.bool,
	/** An array of objects containing the pricing data for each region. Component will render null is this is undefined. */
	marketplacePrices: PropTypes.arrayOf(
		PropTypes.shape({
			is_prepay: PropTypes.number.isRequired,
			price: PropTypes.object.isRequired,
			region: PropTypes.string.isRequired,
			region_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
				.isRequired, // API sometimes returns strings, sometimes numbers 🤦
			region_name: PropTypes.string.isRequired,
		}),
	),
};

export default SideSheetContents;
