export default {
	XVUT00: {
		configs: [
			{
				category: 'ssd',
				config_id: 1,
				cores: 1,
				is_baremetal: null,
				memory: 1024,
				price: 1,
				product_code: 'SS.VPS',
				storage_size: 25,
			},
			{
				category: 'ssd',
				config_id: 2,
				cores: 1,
				is_baremetal: null,
				memory: 2048,
				price: 12,
				product_code: 'SS.VPS',
				storage_size: 30,
			},
			{
				category: 'ssd',
				config_id: 3,
				cores: 2,
				is_baremetal: null,
				memory: 4096,
				price: 1,
				product_code: 'SS.VPS',
				storage_size: 100,
			},
			{
				category: 'ssd',
				config_id: 4,
				cores: 4,
				is_baremetal: null,
				memory: 8192,
				price: 1,
				product_code: 'SS.VPS',
				storage_size: 25,
			},
			{
				category: 'ssd',
				config_id: 5,
				cores: 4,
				is_baremetal: null,
				memory: 16384,
				price: 12,
				product_code: 'SS.VPS',
				storage_size: 30,
			},
			{
				category: 'ssd',
				config_id: 6,
				cores: 8,
				is_baremetal: null,
				memory: 32768,
				price: 1,
				product_code: 'SS.VPS',
				storage_size: 100,
			},
			{
				category: 'bare-metal',
				config_id: 1435,
				cores: 4,
				cpu_description: 'Intel(R) Xeon(R) CPU           E5606  @ 2.13GHz',
				cpu_speed: 2133,
				is_baremetal: '1',
				memory: 65536,
				price: 100,
				product_code: 'SS.VM',
				storage_raid: null,
				storage_size: 1000,
				storage_type: 'SATA',
			},
			{
				category: 'bare-metal',
				config_id: 8,
				cores: 8,
				cpu_description: 'Intel(R) Xeon(R) CPU           E5606  @ 2.13GHz',
				cpu_speed: 2133,
				is_baremetal: '1',
				memory: 65536,
				price: 175,
				product_code: 'SS.VM',
				storage_raid: null,
				storage_size: 1665,
				storage_type: 'SSD',
			},
			{
				category: 'bare-metal',
				config_id: 9,
				cores: 16,
				cpu_description: 'Intel(R) Xeon(R) CPU           E5606  @ 2.13GHz',
				cpu_speed: 2133,
				is_baremetal: '1',
				memory: 131072,
				price: 250,
				product_code: 'SS.VM',
				storage_raid: null,
				storage_size: 2500,
				storage_type: 'NVME',
			},
		],
	},
	'52LWYF': {
		configs: [
			{
				category: 'ssd',
				config_id: 2,
				cores: 1,
				is_baremetal: null,
				memory: 2048,
				price: 13,
				product_code: 'SS.VPS',
				storage_size: 30,
			},
			{
				category: 'ssd',
				config_id: 4,
				cores: 4,
				is_baremetal: null,
				memory: 8192,
				price: 1,
				product_code: 'SS.VPS',
				storage_size: 25,
			},
			{
				category: 'ssd',
				config_id: 5,
				cores: 4,
				is_baremetal: null,
				memory: 16384,
				price: 12,
				product_code: 'SS.VPS',
				storage_size: 30,
			},
			{
				category: 'ssd',
				config_id: 6,
				cores: 8,
				is_baremetal: null,
				memory: 32768,
				price: 1,
				product_code: 'SS.VPS',
				storage_size: 100,
			},
			{
				category: 'bare-metal',
				config_id: 1435,
				cores: 4,
				cpu_description: 'Intel(R) Xeon(R) CPU           E5606  @ 2.13GHz',
				cpu_speed: 2133,
				is_baremetal: '1',
				memory: 65536,
				price: 100,
				product_code: 'SS.VM',
				storage_raid: null,
				storage_size: 1000,
				storage_type: 'SATA',
			},
			{
				category: 'bare-metal',
				config_id: 8,
				cores: 8,
				cpu_description: 'Intel(R) Xeon(R) CPU           E5606  @ 2.13GHz',
				cpu_speed: 2133,
				is_baremetal: '1',
				memory: 65536,
				price: 175,
				product_code: 'SS.VM',
				storage_raid: null,
				storage_size: 1665,
				storage_type: 'SSD',
			},
			{
				category: 'bare-metal',
				config_id: 9,
				cores: 16,
				cpu_description: 'Intel(R) Xeon(R) CPU           E5606  @ 2.13GHz',
				cpu_speed: 2133,
				is_baremetal: '1',
				memory: 131072,
				price: 250,
				product_code: 'SS.VM',
				storage_raid: null,
				storage_size: 2500,
				storage_type: 'NVME',
			},
		],
	},
};
