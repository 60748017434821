import produce from 'immer';
import actions from './actions';

const initialState = {
	enabled: false,
};
/* eslint-disable default-case, no-param-reassign */
const reducer = (state = initialState, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case actions.ON:
				draft.enabled = true;
				break;
			case actions.OFF:
				draft.enabled = false;
				break;
		}
	});

export default reducer;
