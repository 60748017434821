import { takeEvery, put, take } from 'redux-saga/effects';
import snackbarActions from 'modules/snackbar/snackbarActions';
import { actions as requestActions } from 'modules/api/network/ip/requestModule';
import { actions as addActions } from 'modules/api/network/ip/addModule';

const snackMessages = {
	[requestActions.setType]: 'Your request has been submitted.',
	[requestActions.errorType]:
		'A problem occurred with the IP request. Please try submitting again.',
	[addActions.setType]: 'Successfully added IP(s)',
	[addActions.errorType]:
		'A problem occurred with the IP add. Please try submitting again.',
};

function* snacks() {
	const action = yield take([
		requestActions.setType,
		addActions.setType,
		requestActions.errorType,
		addActions.errorType,
	]);
	const status = yield action.type === requestActions.setType ||
	action.type === addActions.setType
		? 'success'
		: 'error';
	yield put(snackbarActions.pushMessage(snackMessages[action.type], status));
}

export default function* sagas() {
	yield takeEvery([requestActions.fetchType, addActions.fetchType], snacks);
}
