import { createSelector } from 'reselect';

const getStateSlice = (state) => state.errors;

const selectors = {
	errors: getStateSlice,
	currentError: createSelector(
		getStateSlice,
		// eslint-disable-next-line no-confusing-arrow
		(errs) => (errs.length > 0 ? errs[0] : null),
	),
};
export default selectors;
