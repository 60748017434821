import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';
import get from 'lodash/get';
import { zone as formatZone } from 'utility/format';
import { selectors as zoneList } from 'modules/api/network/zone/listModule';
import { getStateSlice as productConfigSlice } from 'modules/basket/productConfig/selectors';
import isStormFunc, {
	isCloudDedicated as isCloudDedicatedFunc,
	isVps as isVpsFunc,
} from 'utility/isStorm';

const getStateSlice = (state) => state.basketApi.item.details;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'BASKET_ITEM_DETAILS',
	method: 'POST',
	url: '/basket/item/details.json',
});

const extraData = createSelector(
	defaultSelectors.getNativeState,
	(slice) => slice?.data?.extra_data,
);

const itemConfigs = createSelector(
	defaultSelectors.getNativeState,
	(slice) => get(slice, ['data', 'configs']) || [],
);

const displayItemConfigs = createSelector(
	itemConfigs,
	// These configs need to exist for various reasons, but they should never display.
	(configs) =>
		configs.filter(
			({ key }) =>
				![
					'LiquidWebBackupPlan',
					'BandwidthOverage',
					'cPanelLicenseTier',
				].includes(key),
		),
);

const configGroups = createSelector(
	displayItemConfigs,
	(slice) => [
		...new Set(
			slice
				?.filter((config) => !config.parent_key)
				.sort(({ key_description: a }, { key_description: b }) => {
					if (a < b) return -1;
					if (a > b) return 1;
					return 0;
				})
				.sort((a, b) => a.key_group_display_order - b.key_group_display_order)
				.map((config) => config.key_group),
		),
	],
);

const properties = createSelector(
	defaultSelectors.getNativeState,
	(slice) => slice?.data?.properties,
);

const createdFrom = createSelector(
	properties,
	(slice) => slice?.create_from,
);

const publicSshKey = createSelector(
	properties,
	(slice) => slice?.public_ssh_key || 'No SSH Key',
);

const ipv6 = createSelector(
	properties,
	(slice) => slice?.use_ipv6,
);

const zone = createSelector(
	properties,
	(slice) => slice?.zone,
);

// Arrange configs in a {key: value} data structure so that we don't have to look or each one..
const keyMap = createSelector(
	itemConfigs,
	(configs) => {
		const configMap = {};
		configs.forEach((config) => {
			configMap[config.key] = config;
		});
		return configMap;
	},
);

const networkStorageConfig = createSelector(
	keyMap,
	({ NetworkStorage } = {}) => NetworkStorage || {},
);

const acronisConfig = createSelector(
	keyMap,
	({ Acronis } = {}) => Acronis || {},
);

const extraIpConfig = createSelector(
	keyMap,
	({ ExtraIp } = {}) => ExtraIp || {},
);

const bandwidth = createSelector(
	keyMap,
	({ Bandwidth } = {}) => Bandwidth || {},
);

const configId = createSelector(
	keyMap,
	({ ConfigId } = {}) => ConfigId || {},
);

const template = createSelector(
	keyMap,
	({ Template }) => Template || {},
);

const bandwidthOverage = createSelector(
	keyMap,
	({ BandwidthOverage }) => BandwidthOverage || {},
);

const region = createSelector(
	defaultSelectors.getNativeState,
	(slice) => slice?.data?.region,
);

const productCode = createSelector(
	defaultSelectors.getNativeState,
	(slice) => slice?.data?.productInfo?.product_code,
);

const os = createSelector(
	productCode,
	(productCode_) => (/\.WIN$/.test(productCode_) ? 'windows' : 'linux'),
);

const zoneDisplay = createSelector(
	zoneList.items,
	zone,
	(items, zone_) => formatZone(items, zone_),
);

const uuid = createSelector(
	defaultSelectors.getNativeState,
	(slice) => slice?.data?.uuid,
);

const isStorm = createSelector(
	productCode,
	(productCode_) => isStormFunc(productCode_),
);

const isCloudDedicated = createSelector(
	productCode,
	(productCode_) => isCloudDedicatedFunc(productCode_),
);

const isVps = createSelector(
	productCode,
	(productCode_) => isVpsFunc(productCode_),
);

// Sometime we have options inserted that call assertProperties instead of regular config set. We adjust those valueDescriptions as needed here.
const headersMap = createSelector(
	isStorm,
	productConfigSlice,
	(isStorm_, { deployOnto }) => {
		if (isStorm_)
			return {
				'Server Type':
					deployOnto === 'vps' || deployOnto === 'cloudDedicated'
						? 'Server OS, Zone, Size & Template'
						: 'Allocation from Private Parent',
			};
		return {};
	},
);

const selectors = {
	displayItemConfigs,
	itemConfigs,
	configGroups,
	networkStorageConfig,
	properties,
	acronisConfig,
	configId,
	template,
	createdFrom,
	publicSshKey,
	ipv6,
	extraIpConfig,
	extraData,
	bandwidth,
	productCode,
	region,
	zone,
	os,
	uuid,
	headersMap,
	isStorm,
	isCloudDedicated,
	isVps,
	bandwidthOverage,
	zoneDisplay,
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors, isStorm, headersMap };
