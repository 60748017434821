import {
	createAPIModule,
	createSelectors,
} from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';
import get from 'lodash/get';
import max from 'lodash/max';
import min from 'lodash/min';
import orderBy from 'lodash/orderBy';
import { isBasketAdmin } from 'modules/auth/oidcSelectors';
import isStorm from 'utility/isStorm';

const getStateSlice = (state) => state.basketApi.details;

const moduleKeys = {
	toClone: 'toClone',
};

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'BASKET_DETAILS',
	method: 'POST',
	url: '/basket/details.json',
});

const getBasketItems = createSelector(
	defaultSelectors.getNativeData,
	(slice) => slice?.items || [],
);

const getStormItemCount = createSelector(
	getBasketItems,
	(slice) =>
		slice.filter(({ product_code: productCode }) => isStorm(productCode))
			.length,
);

const hasStormItems = createSelector(
	getStormItemCount,
	(slice) => !!slice,
);

const getBasketItemCounts = createSelector(
	getBasketItems,
	(slice) => {
		const distictArray = Array.from(
			new Set(slice.map((elem) => elem.product_code)),
		);
		const countObj = {};
		distictArray.forEach((productCode) => {
			countObj[productCode] = slice.filter(
				(elem) => productCode === elem.product_code,
			).length;
		});
		return countObj;
	},
);

const getDomains = createSelector(
	getBasketItems,
	(items) =>
		items
			.map((item) => item.properties?.domain)
			.filter((item) => item)
			.filter((item, index, arr) => arr.indexOf(item) === index)
			.sort(),
);

const getDomainRegistrationDomains = createSelector(
	getBasketItems,
	(items) => {
		const ordered = orderBy(items, 'properties.domain');
		return ordered.filter(
			({ product_code: productCode }) => productCode === 'DREG',
		);
	},
);

const getProjects = createSelector(
	getBasketItems,
	(items) =>
		items
			.map((item) => item.properties?.project?.project_name)
			.filter((item) => item)
			.filter((item, index, arr) => arr.indexOf(item) === index)
			.sort(),
);

const getBasketUuid = createSelector(
	defaultSelectors.getNativeData,
	(slice) => slice?.uuid,
);

const getDurations = createSelector(
	getStateSlice,
	(slice) => {
		// Creates an array of durations
		// durations are unique

		const state = slice.toJS();

		const domains = get(state, 'data.items');

		const maxPeriods = new Set();
		const minPeriods = new Set();
		/* eslint-disable no-unused-expressions */
		domains &&
			domains.forEach((s) => {
				maxPeriods.add(Number(get(s, 'properties.max_period')));
				minPeriods.add(Number(get(s, 'properties.min_period')));
			});

		const maxDurations = Array.from(maxPeriods).sort();
		const minDurations = Array.from(minPeriods).sort();

		const durationsBetween = [];

		const maxValue = max(maxDurations);
		const minValue = min(minDurations);

		for (let i = minValue; i <= maxValue; i += 1) {
			durationsBetween.push(i);
		}

		return durationsBetween;
	},
);

const toCloneSelectors = createSelectors(
	getStateSlice,
	false,
	moduleKeys.toClone,
);

const cpqMetadata = createSelector(
	defaultSelectors.getNativeData,
	(slice) => slice?.cpqMetadata,
);

const isCpqBasket = createSelector(
	cpqMetadata,
	(slice) => !!slice,
);

const getOppId = createSelector(
	getStateSlice,
	(slice) => slice.getIn(['data', 'cpqMetadata', 'opportunity_id']),
);

const getQuoteName = createSelector(
	getStateSlice,
	(slice) => slice.getIn(['data', 'name']),
);

const isLocked = createSelector(
	getStateSlice,
	(slice) => !!slice.getIn(['data', 'locked']),
);

const getExpire = createSelector(
	getStateSlice,
	(slice) => slice.getIn(['data', 'expire'])?.split(' ')[0],
);

const hasRequiredDomains = createSelector(
	getBasketItems,
	(items) =>
		Boolean(
			items.reduce(
				(acc, item) =>
					acc &&
					(item.properties?.domain ||
						item.productInfo?.capabilities?.canOmitDomain),
				true,
			),
		),
);

const additionalInstructions = createSelector(
	getStateSlice,
	(slice) => slice.getIn(['data', 'metadata', 'additionalInstructions']),
);

const targetItems = createSelector(
	defaultSelectors.getNativeData,
	(slice) => slice?.metadata?.target_items,
);

const getCartName = createSelector(
	isBasketAdmin,
	getQuoteName,
	(admin, quoteName) => {
		if (admin) return quoteName || 'Unnamed Cart';
		return quoteName || 'My Cart';
	},
);

const discountSources = createSelector(
	getStateSlice,
	(slice) => slice.getIn(['data', 'discountSources'])?.toJS(),
);

const discountPercByItemUuid = createSelector(
	getBasketItems,
	discountSources,
	(items, discounts) => {
		const percObj = {};
		items.forEach(({ uuid }) => {
			// Should return a length of 1 or 0
			const discount = discounts.filter(
				({ metadata }) => metadata?.scope_uuid === uuid,
			);
			percObj[uuid] = {
				value: Number(discount[0]?.amount || 0)
					.toFixed(2)
					.toString(),
				uuid: discount[0]?.uuid,
			};
		});
		return percObj;
	},
);

const selectors = {
	getBasketItems,
	getStormItemCount,
	getBasketItemCounts,
	getBasketUuid,
	getDomains,
	getDomainRegistrationDomains,
	getDurations,
	cpqMetadata,
	isCpqBasket,
	getOppId,
	getProjects,
	hasRequiredDomains,
	hasStormItems,
	targetItems,
	toCloneSelectors,
	getQuoteName,
	isLocked,
	getExpire,
	additionalInstructions,
	getCartName,
	discountSources,
	discountPercByItemUuid,
	...defaultSelectors,
};

export { actions, moduleKeys, reducer, sagas, selectors };
