const injectScript = src => (
	new Promise((resolve, reject) => {
		const scriptEl = document.createElement('script');

		scriptEl.src = src;
		scriptEl.async = true;

		scriptEl.onload = () => resolve(scriptEl);
		scriptEl.onerror = () => reject(new Error(`Failed to load ${src}`));

		document.head.appendChild(scriptEl);
	})
);

export default injectScript;
