import { put, select, take, takeLatest, call } from 'redux-saga/effects';

import {
	actions as detailsActions,
	selectors as detailsSelectors,
} from 'modules/api/billing/payment/profile/detailsModule';

import { actions as hostedFormTokenActions } from 'modules/api/billing/payment/profile/hostedFormTokenModule';
import paymentFormActions from 'modules/paymentForm/actions';
import { actions as profileApplyActions } from 'modules/api/billing/payment/profile/applyModule';
import snackbarActions from 'modules/snackbar/snackbarActions';
import {
	selectors as bindAgreementSelectors,
	actions as bindAgreementActions,
} from 'modules/api/billing/paypal/agreement/bindAgreementModule';

function* handleApplyProfile() {
	const paymentDetailsData = yield select((state) =>
		detailsSelectors.getData(state),
	);
	const paymentDetailsDataJS = paymentDetailsData.toJS();
	// eslint-disable-next-line
	const { payment_profile_id } = paymentDetailsDataJS;
	yield put(detailsActions.fetch({ payment_profile_id }));
}
// TODO: use new snackbarSaga (src/modules/snackbar/sagas/sagas.js)
function* handleApplyProfileError() {
	yield put(
		snackbarActions.pushMessage({
			message: 'Failed to save payment data',
			color: 'error',
		}),
	);
}

function* handleInitFormToken(action) {
	const { payload } = action;

	if (payload) {
		// eslint-disable-next-line
		const { payment_method, communicator_url, reason } = payload;
		yield put(
			hostedFormTokenActions.fetch({
				communicator_url,
				payment_method,
				reason,
			}),
		);
	}
}

// This gets executed when a user returns from setting up a new PayPal billing agreement
function* handlePayPalReturn() {
	const params = new URLSearchParams(window.location.search);
	const show = params.get('show');
	if (show && show === 'paypal_agreement') {
		yield put(
			bindAgreementActions.fetch({
				set_pay_method: 1,
				token: params.get('ba_token'),
			}),
		);
		yield take(bindAgreementActions.setType);
		const success = yield select(bindAgreementSelectors.hasData);
		if (success) {
			yield put(detailsActions.fetch());
			yield put(
				snackbarActions.pushMessage(
					'Successfully added PayPal Billing Agreement',
				),
			);
		}
	}
}

export default function* paymentMethodRoot() {
	yield takeLatest(
		paymentFormActions.PAYMENT_FORM_INITIALIZE_HOSTED_FORM,
		handleInitFormToken,
	);
	yield takeLatest(profileApplyActions.setType, handleApplyProfile);
	yield takeLatest(profileApplyActions.errorType, handleApplyProfileError);
	yield call(handlePayPalReturn);
}
