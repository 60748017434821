import { put, takeLatest, select, call, take } from 'redux-saga/effects';
import {
	selectors as addDiscountSelectors,
	actions as addDiscountActions,
} from 'modules/api/basket/addDiscountModule';
import {
	selectors as updateDiscountSelectors,
	actions as updateDiscountActions,
} from 'modules/api/basket/updateDiscountModule';
import { selectors as detailsSelectors } from 'modules/api/basket/detailsModule';
import {
	selectors as itemModifySelectors,
	actions as itemModifyActions,
} from 'modules/api/basket/item/modifyModule';
import snackbarSaga from 'modules/snackbar/sagas';
import basketActions from '../../actions';

const successMessage = 'Your changes have been saved.';
const errorMessage =
	'There was an error trying to save those changes. Please try again.';

// Keeping this separate so that it's reusable.
function* addItemDiscountSaga({ discountPercent, itemUuid, discountUuid }) {
	const basketUuid = yield select(detailsSelectors.getBasketUuid);
	if (discountUuid) {
		yield put(
			updateDiscountActions.fetch({
				amount: discountPercent,
				uuid: basketUuid,
				discount: discountUuid,
			}),
		);
	} else {
		yield put(
			addDiscountActions.fetch({
				amount: discountPercent,
				uuid: basketUuid,
				type: 'cpq',
				metadata: { scope: 'item', scope_uuid: itemUuid },
			}),
		);
	}
}

function* hasError() {
	return (
		(yield select(itemModifySelectors.hasError)) ||
		(yield select(addDiscountSelectors.hasError)) ||
		(yield select(updateDiscountSelectors.hasError))
	);
}

function* adminItemModifySaga({
	properties,
	discountPercent,
	itemUuid,
	discountUuid,
}) {
	if (discountPercent)
		yield call(addItemDiscountSaga, {
			discountPercent,
			itemUuid,
			discountUuid,
		});
	if (properties)
		yield put(itemModifyActions.fetch({ properties, uuid: itemUuid }));
	if (discountPercent) {
		yield take([
			addDiscountActions.setType,
			addDiscountActions.errorType,
			updateDiscountActions.setType,
			updateDiscountActions.errorType,
		]);
	}
	if (properties) {
		yield take([itemModifyActions.setType, itemModifyActions.errorType]);
	}
	const error = yield call(hasError);
	yield call(snackbarSaga, { error, successMessage, errorMessage });
}

export { addItemDiscountSaga, adminItemModifySaga, hasError };
export default function* adminItemModifyRoot() {
	yield takeLatest(basketActions.BASKET_ADMIN_ITEM_MODIFY, adminItemModifySaga);
}
