import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import NumberField from 'components/atoms/NumberField';
import LWIconButton from 'components/atoms/LWIconButton';
import Remove from '@material-ui/icons/Remove';
import Add from '@material-ui/icons/Add';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const SIconButton = styled(LWIconButton)`
	margin-bottom: ${({ theme, $helpertext }) =>
		$helpertext ? theme.spacing(1) : theme.spacing(-0.5)}px;
	border: 1px solid ${({ theme }) => theme.palette.secondary.main};
`;

// if limit === undefined, there will be no limit.
const QuantitySelect = ({
	quantity,
	setQuantity,
	limit,
	helperText,
	label,
	justify,
	expanded,
	addDisabled = quantity >= limit,
	removeDisabled = quantity < 2,
	disabled, // in general
	BoxProps = { pt: 1 },
	...rest
}) => (
	<Box {...BoxProps}>
		<Grid container justify={justify} alignItems="center" spacing={2}>
			<Grid item>
				<SIconButton
					disabled={removeDisabled || disabled}
					onClick={() => setQuantity(quantity - 1)}
					$helpertext={helperText}
					data-testid="QuantitySelect__Remove"
				>
					<Remove />
				</SIconButton>
			</Grid>
			<Grid item xs={expanded ? 12 : 6} sm={expanded ? 9 : 3}>
				<NumberField
					label={label}
					value={quantity || ''}
					onChange={(number) => {
						setQuantity(Math.abs(Math.min(Number(number), limit)));
					}}
					helperText={helperText}
					data-testid="QuantitySelect__Input"
					disabled={disabled}
					{...rest}
				/>
			</Grid>
			<Grid item>
				<SIconButton
					disabled={addDisabled || disabled}
					onClick={() => setQuantity(quantity + 1)}
					$helpertext={helperText}
					data-testid="QuantitySelect__Add"
				>
					<Add />
				</SIconButton>
			</Grid>
		</Grid>
	</Box>
);

QuantitySelect.defaultProps = {
	limit: Infinity,
	helperText: '',
	label: 'Quantity',
	quantity: undefined,
	justify: 'center',
};

QuantitySelect.propTypes = {
	/** The function fired when "+" or "-" is click, or an "onChange" event is triggered in the text field. The new quantity is passed in as the first arg. */
	setQuantity: PropTypes.func.isRequired,
	/** The current value */
	quantity: PropTypes.number,
	/** The maximum number allowed */
	limit: PropTypes.number,
	/** The text that will appear below the field. For example: 7 slots remaining. */
	helperText: PropTypes.string,
	/** Label for the field (appears at the top) */
	label: PropTypes.string,
	/** Changes justify prop on Grid */
	justify: PropTypes.string,
	/** Controls when the remove button is disabled */
	removeDisabled: PropTypes.bool,
	/** Controls how wide the component is */
	expanded: PropTypes.bool,
	/** Controls styles of the box around the component */
	BoxProps: PropTypes.object,
};

export default QuantitySelect;
