import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';

const getStateSlice = (state) => state.utilities.info.whatismyip;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'UTILITIES_INFO_WHATISMYIP',
	method: 'POST',
	url: '/utilities/info/whatismyip.json',
});

const selectors = {
	ip: createSelector(
		getStateSlice,
		(slice) => slice.getIn(['data', 'ip']),
	),
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };
