import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import LWDialog from 'components/common/LWDialog';
import LWButton from 'components/common/LWButton';
import DialogActions from '@material-ui/core/DialogActions';

const SDialogActions = styled(DialogActions)`
	margin-top: ${(p) => p.theme.spacing(2)}px;
`;

const ConfirmationDialog = ({
	onClose,
	title,
	confirmText,
	cancelText,
	onConfirm,
	onCancel,
	color,
	closeOnConfirm,
	children,
	confirmProps,
	...dialogProps
}) => {
	const confirm = () => {
		if (onConfirm) {
			onConfirm();
		}
		if (onClose && closeOnConfirm) {
			onClose();
		}
	};
	const cancel = () => {
		if (onCancel) {
			onCancel();
		}
		if (onClose) {
			onClose();
		}
	};

	return (
		<LWDialog
			{...dialogProps}
			title={title}
			onClose={onClose}
			onCancel={cancel}
			color={color}
		>
			{children}
			<SDialogActions>
				<LWButton color="tertiary" onClick={cancel}>
					{cancelText}
				</LWButton>
				<LWButton
					{...confirmProps}
					onClick={confirm}
					variant="contained"
					color="secondary"
				>
					{confirmText}
				</LWButton>
			</SDialogActions>
		</LWDialog>
	);
};

ConfirmationDialog.propTypes = {
	title: PropTypes.string,
	confirmText: PropTypes.string,
	cancelText: PropTypes.string,
	onConfirm: PropTypes.func.isRequired,
	onCancel: PropTypes.func,
	closeOnConfirm: PropTypes.bool,
};

ConfirmationDialog.defaultProps = {
	title: 'Confirm',
	confirmText: 'Confirm',
	cancelText: 'Cancel',
	onCancel: () => {},
	closeOnConfirm: true,
};

export default ConfirmationDialog;
