import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import Divider from '@material-ui/core/Divider';
import LWTypography from 'components/common/LWTypography';
import { currency, perMonth } from 'utility/format';
import CartItem from 'components/cart/CartItem';

const styles = () => ({
	titleTypography: {
		fontWeight: 'bold',
	},
	totalTypography: {
		fontWeight: 'bold',
		fontSize: '1.25rem',
	},
});

const MiniCart = ({
	totalText = 'Total due today',
	appendix,
	overrideTotal,
	showTitle = true,
	classes,
	list = [],
	credits = [],
	perMonthLabelItems = true,
	perMonthLabelTotal = false,
}) => {
	const totalFormatter = perMonthLabelTotal ? perMonth : currency;
	return (
		<>
			{showTitle && <LWTypography variant="h5">Order Summary</LWTypography>}
			<List>
				{list.map(({ key: { title, subtitle } = {}, details, value }) => (
					<ListItem component={Box} py={4} key={title}>
						<CartItem
							title={title}
							subtitle={subtitle}
							item={{ totals: { item_price_total: value } }}
							hostnameRequired={false}
							details={details}
							perMonthLabel={perMonthLabelItems}
							readOnly
						/>
					</ListItem>
				))}
				<Divider />
				{credits.map((item) => (
					<ListItem component={Box} py={4} key={item.key.title}>
						<ListItemText
							primary={
								<LWTypography className={classes.titleTypography}>
									{item.key.title}
								</LWTypography>
							}
							secondary={
								item.key.subtitle && (
									<LWTypography>{item.key.subtitle}</LWTypography>
								)
							}
						/>
						{item.value && (
							<LWTypography align="right">
								{`+${currency(item.value)}`}
							</LWTypography>
						)}
					</ListItem>
				))}
				{credits.length ? <Divider /> : null}
				<Box pt={2} />
				<ListItem>
					<ListItemText
						primary={
							<LWTypography variant="h6" bold>
								{totalText}
							</LWTypography>
						}
					/>
					<LWTypography variant="h6" bold>
						{overrideTotal !== undefined
							? totalFormatter(overrideTotal)
							: totalFormatter(
									list.reduce((total, item) => {
										if (typeof item.value === 'number') {
											return total + item.value;
										}
										return total;
									}, 0) -
										credits.reduce((total, item) => {
											if (typeof item.value === 'number') {
												return total + item.value;
											}
											return total;
										}, 0),
							  )}
					</LWTypography>
				</ListItem>
				{appendix && (
					<LWTypography BoxProps={{ px: 2 }}>{appendix}</LWTypography>
				)}
			</List>
		</>
	);
};

MiniCart.propTypes = {
	classes: PropTypes.object.isRequired,
	totalText: PropTypes.string,
	credits: PropTypes.array,
	list: PropTypes.arrayOf(
		PropTypes.shape({
			/** TODO: this is not a key. */
			key: PropTypes.shape({
				title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
				subtitle: PropTypes.string,
			}),
			value: PropTypes.number,
			/** see CartItem.jsx for schema */
			details: PropTypes.array,
		}),
	).isRequired,
	overrideTotal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	showTitle: PropTypes.bool,
	/** should "/ mo" show up for each item? defaults to true */
	perMonthLabelItems: PropTypes.bool,
	/** should "/ mo" show up for the total? defaults to false */
	perMonthLabelTotal: PropTypes.bool,
};

export { MiniCart };
export default withStyles(styles)(MiniCart);
