const actions = {
	internalOnlyCheck: 'INTERNAL_ONLY_CHECK',
	check: (payload) => ({
		type: actions.internalOnlyCheck,
		payload,
	}),
	internalOnlySet: 'INTERNAL_ONLY_SET',
	set: (payload) => ({
		type: actions.internalOnlySet,
		payload,
	}),
};

export default actions;
