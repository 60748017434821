import { getCookie } from '../util/cookie';

const getInitialExtraDetails = () => {
	const piiCookie = getCookie('lwPiiPreferences');

	if (!piiCookie) return [];

	const piiDetails = JSON.parse(piiCookie);

	return Object.entries(piiDetails)
		.map(([key, value]) => ({
			label: `piiPref_${key}`,
			value,
			displayToAgent: false,
		}));
};

export default getInitialExtraDetails;
