import React from 'react';
import PropTypes from 'prop-types';
import styled, { useTheme } from 'styled-components';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import StyledBox from 'components/atoms/StyledBox';

import LWButton from 'components/common/LWButton';
import LWTooltip from 'components/common/LWTooltip';
import LWTypography from 'components/common/LWTypography';
import Selected from 'components/atoms/iconsWithText/Selected';

import { currency } from 'utility/format';

const SPaper = styled(Paper)`
	border: 2px solid transparent;
	${({ $isDisabled, $isCurrent, theme }) =>
		!$isDisabled &&
		!$isCurrent &&
		`
			&:hover {
				transition: 0.2s ease border-color;
				border: 2px solid ${theme.palette.primary.dark};
			}
			cursor: pointer;
		`}
	${({ $isDisabled, $isLoading, theme }) =>
		$isDisabled &&
		!$isLoading &&
		`
			background: ${theme.palette.common.grey2};
		`}
	${({ $isSelected, theme }) =>
		$isSelected &&
		`
			background:${theme.palette.primary.faint};
			border: 2px solid ${theme.palette.primary.main};
		`}
`;

const WrapBox = styled(Box)`
	${({ theme, $variant }) =>
		theme.breakpoints.down($variant === 'landscape' ? 'xs' : 'xl')} {
		flex-wrap: wrap;
	}
`;

const component = ({ container }) => {
	switch (container) {
		case 'Paper':
			return SPaper;
		case 'List':
			return StyledBox;
		default:
			return SPaper;
	}
};

const ProductOption = ({
	action,
	actionText,
	handleSelect = () => {},
	isCurrent = false,
	currentText = 'Current Plan',
	isDisabled = false,
	isSelected = false,
	price,
	isRecommended = false,
	displayValue = '',
	description = '',
	isDisabledTooltip = '',
	title = '',
	listItems = [],
	value,
	data,
	isLoading = false,
	container = 'Paper',
	variant = 'portrait',
	icon = null,
}) => {
	const copy = {
		get helperText() {
			if (isSelected) {
				return <Selected isLoading={isLoading} />;
			}
			const helperTextProps = {
				variant: 'caption',
				bold: true,
				color: 'palette.primary.dark',
			};
			if (isCurrent) {
				return <LWTypography {...helperTextProps}>{currentText}</LWTypography>;
			}
			if (isRecommended && !isDisabled) {
				return <LWTypography {...helperTextProps}>Recommended</LWTypography>;
			}
			if (action) {
				return (
					<LWButton
						color="secondary"
						disabled={isDisabled}
						onClick={() => {
							action(value);
						}}
						variant="outlined"
					>
						{actionText}
					</LWButton>
				);
			}
			return null;
		},
	};
	const theme = useTheme();
	const isPortrait = variant === 'portrait';
	const Component = component({ container });
	return (
		<Component
			$isDisabled={isDisabled}
			$isLoading={isLoading}
			$isCurrent={isCurrent}
			$isSelected={isSelected}
			onClick={() => !isDisabled && !isCurrent && handleSelect({ value, data })}
			component={Box}
			padding={4}
			height="100%"
			data-testid={`ProductOption__${isCurrent ? 'isCurrent' : 'isNotCurrent'}`}
			{...(container === 'List' && { divider: true, $between: false })}
		>
			<Box position="relative" height="0px" left={-24} top={-24}>
				{!!isDisabledTooltip && isDisabled && (
					<LWTooltip>{isDisabledTooltip}</LWTooltip>
				)}
			</Box>
			<WrapBox display="flex" $variant={variant}>
				{icon && (
					<Box
						height={theme.spacing(8)}
						display="flex"
						alignItems="center"
						pr={3}
					>
						<img src={icon} alt="brand-logo" width={theme.spacing(16)} />
					</Box>
				)}
				<Grid
					component={Box}
					container
					direction="row"
					justify="space-between"
					height="100%"
				>
					{/* Top section (Bound to top) */}
					<Grid item container spacing={2} justify="space-between">
						<Grid
							container
							item
							wrap="nowrap"
							justify="space-between"
							direction={isPortrait ? 'column' : 'row'}
							spacing={2}
							alignItems="center"
						>
							{title && (
								<Grid item>
									<LWTypography
										variant="h6"
										color="palette.common.accent[0]"
										data-testid="ProductOption__Title"
									>
										{title}
									</LWTypography>
								</Grid>
							)}
							{displayValue && (
								<Grid item>
									<LWTypography
										variant="h4"
										bold
										color="palette.common.accent[0]"
										data-testid="ProductOption_displayValue"
										noWrap
									>
										{displayValue}
									</LWTypography>
								</Grid>
							)}
							<Grid item>
								<Box display="flex" alignItems="baseline">
									<LWTypography variant="h4" data-testid="ProductOption_price">
										{currency(price)}
									</LWTypography>
									<LWTypography BoxProps={{ pl: 0.5 }}>/month</LWTypography>
								</Box>
							</Grid>
						</Grid>
						{description && (
							<Grid item xs={isPortrait ? 12 : 9}>
								<LWTypography
									paragraph={listItems.length > 0 && Boolean(description)}
								>
									{description}
								</LWTypography>
							</Grid>
						)}
					</Grid>
					{/* Bottom section (Bound to bottom) */}
					<Grid
						item
						container
						direction={isPortrait ? 'column' : 'row'}
						justify="space-between"
						wrap="nowrap"
					>
						<Grid item container direction="column">
							{listItems.map((content) => (
								<Grid item key={content.key}>
									<Box
										display="flex"
										alignItems="center"
										minHeight="20px" // Prevent tooltips from messing things up.
									>
										<LWTypography variant="body2">{content.value}</LWTypography>
										{content.toolTipText && (
											<LWTooltip>{content.toolTipText}</LWTooltip>
										)}
									</Box>
								</Grid>
							))}
						</Grid>

						{copy.helperText && (
							<Grid item>
								<Box
									align="center"
									width="100%"
									minHeight={theme.spacing(isPortrait ? 5.5 : 3.5)}
									pt={isPortrait ? 2 : 0} // ensure spacing when the list items are directly above.
									alignItems={isPortrait ? 'center' : 'right'}
									display="flex"
									flexDirection="column"
									justifyContent="flex-end" // puts it at the bottom.
									height="100%" // We'll need this is the list items are too tall.
								>
									{copy.helperText}
								</Box>
							</Grid>
						)}
					</Grid>
				</Grid>
			</WrapBox>
		</Component>
	);
};

ProductOption.propTypes = {
	/** Topmost information on the tile. */
	title: PropTypes.string,
	/** Appears below title in large bold type. */
	displayValue: PropTypes.string,
	/** The price per month for the option. */
	price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
	/** The paragraph that appears under `price` */
	description: PropTypes.string,
	/** A list of features that appears under the description. */
	listItems: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string.isRequired,
			value: PropTypes.string.isRequired,
			toolTipText: PropTypes.string,
		}),
	),
	/** When true, `currentText` is displayed and selection is disabled. */
	isCurrent: PropTypes.bool,
	/** If true, 'Recommended' will be displayed at the bottom. Overridden by `isDisabled`, `isSelected`, and `isCurrent` */
	isRecommended: PropTypes.bool,
	/** If true, will disable the tile and display a tooltip in the upper-left corner if `isDisabledTooltip` is defined. */
	isDisabled: PropTypes.bool,
	/** If true, it will change the styles on the tile when the tile is disabled. If false, the tile will be disabled but still show as a normal tile. */
	isLoading: PropTypes.bool,
	/** If isDisabled is set to true, this is the text encapsulated by a tool tip in the upper left corner. */
	isDisabledTooltip: PropTypes.string,
	/** Passed to handleSelect when the tile is clicked. It is not displayed. */
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
	/** If true, causes the checkbox icon with 'selected' to appear at the bottom. */
	isSelected: PropTypes.bool,
	/** Fired when a tile is clicked and passes in `value` as the only argument. */
	handleSelect: PropTypes.func,
	/** Defaults to 'Current Plan' but can be customized. Appears when `isCurrent` is true */
	currentText: PropTypes.string,
	/** An icon that displays on the left side in landscape mode only. Pass in the export from a .png */
	icon: PropTypes.string,
	/** execute this when action button is clicked */
	action: PropTypes.func,
	/** Display this text in the action button */
	actionText: PropTypes.string,
	/**  Controls styling and DOM composition of container elements */
	container: PropTypes.oneOf(['Paper', 'List']),
};

export default ProductOption;
