import React from 'react';
import LWTypography from 'components/common/LWTypography';

const InputLabel = ({ children }) => (
	<LWTypography color="textSecondary" variant="body2" BoxProps={{ mr: 1.5 }}>
		{children}
	</LWTypography>
);

export default InputLabel;
