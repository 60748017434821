export default {
	assets: [
		{
			category: 'Domains',
			domain: 'mctestersons.com',
			project_id: '',
			project_name: '',
			type: 'DNS.ZONE',
			uniq_id: '5B5CDX',
		},
		{
			category: 'Domains',
			domain: 'fireflyssltest.com',
			project_id: 'ABC123',
			project_name: 'Other Project',
			type: 'DREG',
			uniq_id: '6J4B8T',
		},
		{
			category: 'Domains',
			domain: 'anewdomain.com',
			project_id: 'QETE32',
			project_name: 'Special Project',
			type: 'DNS.ZONE',
			uniq_id: '2HK7VP',
		},
		{
			category: 'Domains',
			domain: 'thismagicalfantastic.com',
			project_id: '',
			project_name: '',
			type: 'DREG',
			uniq_id: 'UPW3AP',
		},
		{
			category: 'Domains',
			domain: 'adomainforbob.com',
			project_id: 'F7T6H5',
			project_name: "bob's project",
			type: 'DREG',
			uniq_id: 'E3ZNE4',
		},
		{
			category: 'Domains',
			domain: 'whateadomainnoway.com',
			project_id: '',
			project_name: '',
			type: 'DREG',
			uniq_id: 'GG8ME7',
		},
		{
			category: 'Domains',
			domain: 'whateadomainnoway.net',
			project_id: '',
			project_name: '',
			type: 'DREG',
			uniq_id: '3Y14SD',
		},
		{
			category: 'Domains',
			domain: '1546545548.perltastic.com',
			project_id: '',
			project_name: '',
			type: 'SSL.DV',
			uniq_id: '45J487',
		},
		{
			category: 'Domains',
			domain: 'www.whateveromgthisthing.com',
			project_id: 'U765TY',
			project_name: 'My Awesome Project!',
			type: 'SSL.DV',
			uniq_id: '7WAAWX',
		},
		{
			category: 'Services',
			domain: 'block storage tester',
			project_id: '',
			project_name: '',
			type: 'SS.SBS',
			uniq_id: '0DHB06',
		},
		{
			category: 'Servers',
			domain: 'test.ye4v1mqh8qvsyml8zyvvizlsieiwiixh.com',
			project_id: '',
			project_name: '',
			type: 'DS.MinimalForTests',
			uniq_id: 'F95TV0',
		},
		{
			category: 'Servers',
			domain: 'test.bghupdps3kcmyav6q0u11wnt0z02ogwd.com',
			project_id: '',
			project_name: '',
			type: 'DS.MinimalForTests',
			uniq_id: '4KP25A',
		},
		{
			category: 'Servers',
			domain: 'beyondhosting.mctesterson.example.com',
			project_id: '',
			project_name: '',
			type: 'BH.VM',
			uniq_id: '9KLB2X',
			clusterable: true,
			potential_role: 'primary',
		},
		{
			category: 'Servers',
			domain: 'host.backupsandimagestest.com',
			project_id: 'HGTRFE',
			project_name: 'Images Project',
			type: 'SS.VPS',
			uniq_id: '78554A',
		},
		{
			category: 'Servers',
			domain: 'paypergigbackups.testing.com',
			project_id: '',
			project_name: '',
			type: 'SS.VPS',
			uniq_id: '7CNH4M',
		},
		{
			category: 'Servers',
			domain: 'privateparent',
			project_id: '',
			project_name: '',
			type: 'SS.PP',
			uniq_id: 'Z8064R',
		},
		{
			category: 'Servers',
			domain: 'child1.mctesterson.com',
			project_id: '',
			project_name: '',
			type: 'SS.VPS',
			uniq_id: 'UQZ3HX',
		},
		{
			category: 'Servers',
			domain: 'clouddedi.mctesterson.com',
			project_id: '',
			project_name: '',
			type: 'SS.VM',
			uniq_id: 'VSUWQ0',
		},
		{
			category: 'Domains',
			domain: 'bobsknees.com',
			project_id: '',
			project_name: '',
			type: 'DREG',
			uniq_id: 'Q9TK16',
		},
		{
			category: 'Services',
			domain: 'kjhgjkh.3378300.loadbalancer.info',
			project_id: '',
			project_name: '',
			type: 'SS.LB',
			uniq_id: 'QC2UA4',
		},
		{
			category: 'Services',
			domain: 'g.3378300.loadbalancer.info',
			project_id: '',
			project_name: '',
			type: 'SS.LB',
			uniq_id: 'XNWC9W',
		},
		{
			category: 'Domains',
			domain: 'www.c.com',
			project_id: '',
			project_name: '',
			type: 'SSL.DV',
			uniq_id: 'UAKBRK',
		},
		{
			category: 'Domains',
			domain: 'forbes.com',
			project_id: '',
			project_name: '',
			type: 'DNS.ZONE',
			uniq_id: 'CC78XF',
		},
		{
			category: 'Domains',
			domain: 'sdfsd.com',
			project_id: '',
			project_name: '',
			type: 'DREG',
			uniq_id: 'K2W4NB',
		},
		{
			category: 'Servers',
			domain: 'strictdedi.mctestersons.com',
			project_id: '',
			project_name: '',
			type: 'DS.1270v5',
			uniq_id: 'CB72FJ',
		},
	],
};
