import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';
import { selectors as primaryTagSelectors } from 'modules/api/tags/product/primaryModule';
import { productsToProductTile } from './mapToComponent';

const getStateSlice = (state) => state.product.marketplace.productsForTags;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'PRODUCT_MARKETPLACE_PRODUCTSFORTAGS',
	url: '/product/marketplace/productsForTags.json',
});

const products = createSelector(
	defaultSelectors.getNativeData,
	(slice) => slice && productsToProductTile(slice?.paged_data?.items),
);

const tags = createSelector(
	defaultSelectors.getNativeData,
	primaryTagSelectors.getNativeData,
	(slice, primaryTagsData) => {
		const primaryTags = primaryTagsData?.tags || [];
		return slice?.unique_tags?.filter((tag) => !primaryTags?.includes(tag));
	},
);

const pageData = createSelector(
	defaultSelectors.getNativeData,
	(slice) => {
		const { page_num: pageNum, page_size: pageSize, item_total: itemTotal } =
			slice?.paged_data || {};
		return { pageNum, pageSize, itemTotal };
	},
);

const selectors = {
	getStateSlice,
	products,
	tags,
	pageData,
	...defaultSelectors,
};
export { actions, reducer, sagas, selectors };
