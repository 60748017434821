const actions = {
	SERVER_RESIZE_ASSET_DETAILS: 'SERVER_RESIZE_ASSET_DETAILS',
	fetchAssetDetails: ({ assetType, uniqId }) => ({
		type: actions.SERVER_RESIZE_ASSET_DETAILS,
		assetType,
		uniqId,
	}),
	SERVER_RESIZE_INIT_PARENT_LIST: 'SERVER_RESIZE_INIT_PARENT_LIST',
	initParentList: () => ({
		type: actions.SERVER_RESIZE_INIT_PARENT_LIST,
	}),
	SERVER_RESIZE_RESIZE: 'SERVER_RESIZE_RESIZE',
	resize: ({ uniqId, newSize, assetType }) => ({
		type: actions.SERVER_RESIZE_RESIZE,
		uniqId,
		newSize,
		assetType,
	}),
	SERVER_RESIZE_INIT_OPTIONS: 'SERVER_RESIZE_INIT_OPTIONS',
	initOptions: ({ assetType, uniqId }) => ({
		type: actions.SERVER_RESIZE_INIT_OPTIONS,
		assetType,
		uniqId,
	}),
};

export default actions;
