const actions = {
	PROJECT_ADD_DIALOG_TOGGLE: 'PROJECT_ADD_DIALOG_TOGGLE',
	toggleDialog: (isOpen) => ({
		type: actions.PROJECT_ADD_DIALOG_TOGGLE,
		isOpen,
	}),
	PROJECT_ADD_DIALOG_SET_PROPS: 'PROJECT_ADD_DIALOG_SET_PROPS',
	setProps: (dialogProps) => ({
		type: actions.PROJECT_ADD_DIALOG_SET_PROPS,
		dialogProps,
	}),
};

export default actions;
