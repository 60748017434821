import { spawn } from 'redux-saga/effects';

import { sagas as balanceSagas } from './balanceModule';
import { sagas as changeMonthlyBillDaySagas } from './changeMonthlyBillDayModule';
import invoiceSagas from './invoice/invoiceSagas';
import paypalSagas from './paypal/paypalSagas';
import creditCardSagas from './creditcard/creditCardSaga';
import paymentSagas from './payment/paymentSagas';

export default function* sagas() {
	yield spawn(balanceSagas);
	yield spawn(changeMonthlyBillDaySagas);
	yield spawn(invoiceSagas);
	yield spawn(creditCardSagas);
	yield spawn(paypalSagas);
	yield spawn(paymentSagas);
}
