import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';

const getStateSlice = (state) => state.asset.connections;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'ASSET_CONNECTIONS',
	method: 'POST',
	url: '/asset/connections.json',
});

const selectors = {
	hasThreatStack: createSelector(
		getStateSlice,
		(slice) => {
			const details = slice.getIn(['data', 'details']);
			if (!details) return false;
			return !!Object.values(details.toJS()).find(
				(detail) => detail?.type === 'ThreatStack',
			);
		},
	),
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };
