// TODO: Put all Status files in the same folder. See components/Asset/Status/

import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Box from '@material-ui/core/Box';
import LWTypography from 'components/common/LWTypography';
import Icon from './Icon';

// Four levels of notifications: 'normal', 'pending', 'warning', and 'high'
// These will correspond to the 'green', 'spinning blue', 'orange', and 'red' notifications in the UI

// TODO: replace this style with MUI props.
const STitle = styled(LWTypography)`
	display: block;
	color: ${({ theme }) => theme.palette.text.secondary};
`;

// TODO: replace this style with MUI props.
const SText = styled(LWTypography)`
	color: ${({ theme }) => theme.palette.common.white1};
	font-weight: bold;
	${({ listview }) =>
		listview &&
		css`
			color: ${({ theme }) => theme.palette.text.primary};
			font-weight: normal;
		`}
`;

const Status = ({
	level = 'pending',
	message,
	title = null,
	listView = true,
	iconOnly = false,
	BoxProps,
}) => (
	<Box display="inline-flex" alignItems="center" title={message} {...BoxProps}>
		<Icon level={level} />
		{!iconOnly && (
			<Box py={0.5} px={1}>
				{title && <STitle variant="caption">{title}</STitle>}
				<SText
					variant={title ? 'body2' : 'body1'}
					inline
					listview={listView ? 1 : 0}
				>
					{message}
				</SText>
			</Box>
		)}
	</Box>
);

Status.propTypes = {
	/** Optional title that appears before the status. */
	title: PropTypes.string,
	/** Words the display next to the LED. */
	message: PropTypes.string,
	/** Determines the type of LED used. */
	level: PropTypes.oneOf(['normal', 'pending', 'warning', 'high']),
	/** makes text white when false. TODO: give this prop a better name. */
	listView: PropTypes.bool,
	/** TODO: remove this prop, and don't pass in strings from components/Asset/Status if they shouldn't be displayed. */
	iconOnly: PropTypes.bool,
};

export default Status;
