import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';

const getStateSlice = (state) => state.account.chatpassword.get;

const { actions, reducer, sagas, selectors: moduleSelectors } = createAPIModule(
	{
		getStateSlice,
		actionType: 'ACCOUNT_CHATPASSWORD_GET',
		url: '/account/chatpassword/get.json',
	},
);

const selectors = {
	supportPassphrase: createSelector(
		getStateSlice,
		(slice) => slice.getIn(['data', 'password']),
	),
	...moduleSelectors,
};

export { actions, reducer, sagas, selectors };
