const actions = {
	DESTROY_ON_DESTROY: 'DESTROY_ON_DESTROY',
	onDestroy: ({
		cancellationComment,
		cleanupAssets,
		removeAcronis,
		acronisId,
		uniqId,
		rating,
		cancellationReason,
		status,
	}) => ({
		type: actions.DESTROY_ON_DESTROY,
		payload: {
			cancellationComment,
			cancellationReason,
			removeAcronis,
			acronisId,
			cleanupAssets,
			uniqId,
			rating,
			status,
		},
	}),
};

export default actions;
