import { all, call, delay, put, take, select } from 'redux-saga/effects';
import { actions as configSetActions } from 'modules/api/basket/item/config/setModule';
import snackbarSaga from 'modules/snackbar/sagas';
import {
	actions as itemDetailsActions,
	selectors as itemDetailsSelectors,
} from 'modules/api/basket/item/detailsModule';
import basketActions from '../../actions';
import detailsSaga from '../details';

const alsowith = ['alerts'];

function* alertsHandler(alerts) {
	yield all(
		alerts
			.map((alert) => {
				if (alert.details.valid) {
					switch (alert.type) {
						case 'license':
							// This shouldn't be in the linter. From MDN:
							// "Unlike functions defined by function expressions or by the Function constructor,
							// a function defined by a function declaration can be used before the function declaration itself."
							// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Functions#constructor_vs._declaration_vs._expression
							// eslint-disable-next-line no-use-before-define
							return call(handleSetItemConfig, {
								key: alert.details.valid.key,
								skipAlertsHandler: true,
								value: alert.details.valid.value,
							});
						default:
							// !TODO NEWMAN-1676 display user warning message?
							return false;
					}
				}
				// no solution provided by the API
				return false;
			})
			.filter((elem) => elem),
	);
}

function* handleSetItemConfig({
	basketItem: passedInBasketItem /** pass in, or get from itemDetails */,
	key,
	numUnits,
	parentKey,
	parentValue,
	skipAlertsHandler,
	value,
}) {
	if (process.env.NODE_ENV !== 'test') yield delay(250); // Here to mitigate user button mashing, but makes tests fail.

	let basketItem = passedInBasketItem;
	while (!basketItem) {
		// Should only ever happen if the basket/item/details call is currently not yet completed
		if (yield select(itemDetailsSelectors.isLoading))
			yield take([itemDetailsActions.setType]);
		basketItem = (yield select(itemDetailsSelectors.getNativeData))?.uuid;
	}

	yield put(
		configSetActions.fetch({
			alsowith,
			basket_item: basketItem,
			key,
			num_units: numUnits,
			parent_key: parentKey,
			parent_value: parentValue,
			value,
		}),
	);
	const setResult = yield take([
		configSetActions.setType,
		configSetActions.errorType,
	]);
	const error = setResult?.type === configSetActions.errorType;
	yield call(snackbarSaga, {
		error,
		errorMessage: 'Failed to update item config',
	});
	// check alerts
	if (!skipAlertsHandler && setResult?.payload?.alerts) {
		yield call(alertsHandler, setResult.payload.alerts);
	}

	yield put(
		basketActions.fetchItemDetails({
			uuid: basketItem,
		}),
	);

	yield call(detailsSaga);
}

export { alertsHandler };
export default handleSetItemConfig;
