import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import dialogActions from 'modules/dialogs/actions';
import basketActions from 'modules/basket/actions';

// MUI components
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';

// custom components
import ConfirmCancel from 'components/common/Dialogs/ConfirmCancel';

const OpportunityModalContents = () => {
	const dispatch = useDispatch();
	const [oppId, setOppId] = useState();

	const closeDialog = () => dispatch(dialogActions.close());

	const onSubmit = () => {
		closeDialog();
		dispatch(basketActions.assertMetadata({ opp_id: oppId }));
	};
	return (
		<Box px={1} pt={2} pb={1}>
			<Box pb={2}>
				<TextField
					fullWidth
					variant="outlined"
					label="Opportunity Number"
					value={oppId}
					onChange={(event) => {
						setOppId(event.target.value);
					}}
				/>
			</Box>
			<ConfirmCancel
				confirmText="Save"
				confirm={onSubmit}
				cancel={closeDialog}
			/>
		</Box>
	);
};

export default OpportunityModalContents;
