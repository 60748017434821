import actions from 'modules/account/taxInfo/actions';
import { actions as updateActions } from 'modules/api/contact/updateModule';

const submit = (values, dispatch, { pristine }) => {
	if (pristine) {
		// No need to send old info, but we should tell the client that it happened so things can keep moving.
		dispatch(updateActions.set(values));
		return;
	}
	dispatch(actions.updateTaxInfo(values));
};

export default submit;
