const actions = {
	BASKET_DECREMENT_NEW_IP_COUNT: 'BASKET_DECREMENT_NEW_IP_COUNT',
	// This is for removing new IPs from the "selectedList" action table in "IPTables.jsx"
	// There is no need for an incrementNewIp since this is does using QuantitySelect.jsx, which simply uses setNewIpCount.
	decrementNewIp: () => ({
		type: actions.BASKET_DECREMENT_NEW_IP_COUNT,
	}),
	BASKET_SET_NEW_IP_COUNT: 'BASKET_SET_NEW_IP_COUNT',
	setNewIpCount: ({ newIpCount, skipApiUpdate }) => ({
		type: actions.BASKET_SET_NEW_IP_COUNT,
		newIpCount,
		skipApiUpdate, // used for init.
	}),
	BASKET_ASSERT_IP_POOL: 'BASKET_ASSERT_IP_POOL',
	assertIpPool: () => ({
		type: actions.BASKET_ASSERT_IP_POOL,
	}),
};

export default actions;
