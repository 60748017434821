import React from 'react';
import LWTypography from 'components/common/LWTypography';
import DetourDialogContent from 'components/structural/DetourDialogContent';

class NavLinkHelper {
	constructor(props) {
		Object.entries(props).forEach(([key, item]) => {
			this[key] = item;
		});
	}

	get linkContents() {
		const { navLink, classes } = this;
		const BadgeFromConfig = navLink.Badge;
		const contents = (
			<LWTypography
				className={classes?.linkTypography}
				color="palette.primary.dark"
			>
				{navLink.name}
			</LWTypography>
		);
		return BadgeFromConfig ? (
			<BadgeFromConfig>{contents}</BadgeFromConfig>
		) : (
			contents
		);
	}

	get onClick() {
		const {
			navLink,
			configObj,
			closeDialog,
			openManageRedirectDialog,
			openDialog,
		} = this;

		if (navLink.configKey) {
			let route;
			if (navLink.configPath) {
				route = `https://${configObj.hosts[navLink.configKey]}/${
					navLink.configPath
				}`;
			} else {
				route = `https://${configObj.hosts[navLink.configKey]}`;
			}

			return () =>
				openDialog({
					title: 'Temporary Detour',
					content: (
						<DetourDialogContent route={route} closeDialog={closeDialog} />
					),
				});
		}
		if (navLink.notYetImplemented) {
			return openManageRedirectDialog;
		}
		if (navLink.onClick) {
			return navLink.onClick;
		}
		return null;
	}
}

export default NavLinkHelper;
