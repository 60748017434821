import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.network.dns.domain.nameserver.assign;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'NETWORK_DNS_DOMAIN_NAMESERVER_ASSIGN',
	method: 'POST',
	url: '/network/dns/domain/nameserver/assign.json',
});

const selectors = {
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };
