const actions = {
	OPEN: 'SIDESHEET_OPEN',
	open: ({ title, content }) => ({
		type: actions.OPEN,
		title,
		content,
	}),
	IS_LOADING: 'SIDESHEET_IS_LOADING',
	isLoading: () => ({
		type: actions.IS_LOADING,
	}),
	SIDESHEET_OPEN_CART_PREVIEW: 'SIDESHEET_OPEN_CART_PREVIEW',
	openCartPreview: ({ title, content }) => ({
		type: actions.SIDESHEET_OPEN_CART_PREVIEW,
		title,
		content,
	}),
	CLOSE: 'SIDESHEET_CLOSE',
	close: () => ({
		type: actions.CLOSE,
	}),
	SET_TITLE: 'SIDESHEET_SET_TITLE',
	setTitle: (title) => ({
		type: actions.SET_TITLE,
		title,
	}),
	SET_CONTENT: 'SIDESHEET_SET_CONTENT',
	setContent: (content) => ({
		type: actions.SET_CONTENT,
		content,
	}),
};

export default actions;
