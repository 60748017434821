import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';

const getStateSlice = (state) => state.basketApi.claim;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'BASKET_CLAIM',
	method: 'POST',
	url: '/basket/claim.json',
});

const uuid = createSelector(
	defaultSelectors.getNativeData,
	(slice) => slice?.uuid,
);

const selectors = {
	uuid,
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };
