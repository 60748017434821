const convertEmptyObjectToUndef = (object) =>
	Object.entries(object).length === 0 ? undefined : object;

// TODO: consider replacing with this: https://stackoverflow.com/questions/39085399/lodash-remove-items-recursively/39087474#39087474
const removeUndefinedKeys = (object) => {
	const cleanObject = object;
	Object.keys(cleanObject).forEach((key) => {
		if (typeof cleanObject[key] === 'object') {
			cleanObject[key] = convertEmptyObjectToUndef(
				removeUndefinedKeys(cleanObject[key]),
			);
		}
		if (cleanObject[key] === undefined || cleanObject[key] === null) {
			delete cleanObject[key];
		}
	});
	return cleanObject;
};

export { removeUndefinedKeys, convertEmptyObjectToUndef };
