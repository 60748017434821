import { call } from 'redux-saga/effects';
import axios from './localAjaxAxiosClient';

export default function* sendApiRequest(axiosData) {
	try {
		const axiosRequest = axiosData;
		const response = yield call(axios, axiosRequest);
		return { response, data: response.data };
	} catch (error) {
		return { error };
	}
}
