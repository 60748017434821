import { combineReducers } from 'redux';

import { reducer as available } from './availableModule';
import { reducer as create } from './createModule';
import { reducer as details } from './detailsModule';
import { reducer as list } from './listModule';
import { reducer as update } from './updateModule';
import { reducer as roles } from './rolesModule';
import stateData from './stateData/stateDataReducer';

export default combineReducers({
	available,
	create,
	list,
	details,
	update,
	roles,
	stateData,
});
