const actionType = 'ERRORS';
const actions = {
	pushType: `${actionType}_PUSH_ERROR`,
	pushError: ({ message = null, verb = null, color = null }) => ({
		type: `${actionType}_PUSH_ERROR`,
		message,
		verb,
		color,
	}),
	shiftType: `${actionType}_SHIFT_ERROR`,
	shiftError: () => ({
		type: `${actionType}_SHIFT_ERROR`,
	}),
	removeType: `${actionType}_REMOVE_ERROR`,
	removeError: ({ index }) => ({
		type: `${actionType}_REMOVE_ERROR`,
		index,
	}),
};

export default actions;
