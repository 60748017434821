import { spawn } from 'redux-saga/effects';

import { sagas as createSagas } from './createModule';
import { sagas as deleteSagas } from './deleteModule';
import { sagas as listSagas } from './listModule';
import { sagas as multiCreateAndUpdateSagas } from './multiCreateAndUpdateModule';
import { sagas as updateSagas } from './updateModule';

export default function* recordSagas() {
	yield spawn(createSagas);
	yield spawn(deleteSagas);
	yield spawn(listSagas);
	yield spawn(multiCreateAndUpdateSagas);
	yield spawn(updateSagas);
}
