/* eslint-disable camelcase */

const domains = {
	ADD_DOMAIN: 'ADD_DOMAIN',
	addDomain: ({ index, uuid, domain, max_period, min_period }) => ({
		type: domains.ADD_DOMAIN,
		index,
		uuid,
		domain,
		max_period,
		min_period,
	}),
	REMOVE_DOMAIN: 'REMOVE_DOMAIN',
	removeDomain: ({ index, uuid }) => ({
		type: domains.REMOVE_DOMAIN,
		uuid,
		index,
	}),
	BULK_EDIT: 'BULK_EDIT',
	bulkEdit: ({ duration, autoRenewPrivacy, autoRenewRegistration }) => ({
		type: domains.BULK_EDIT,
		duration,
		autoRenewPrivacy,
		autoRenewRegistration,
	}),
	EDIT_NAME_SERVERS: 'EDIT_NAME_SERVERS',
	editNameServers: ({ nameServers }) => ({
		type: domains.EDIT_NAME_SERVERS,
		nameServers,
	}),
	TOGGLE_LOADING: 'TOGGLE_LOADING',
	toggleLoading: ({ key, value }) => ({
		type: domains.TOGGLE_LOADING,
		key,
		value,
	}),
	SUBMIT_WHO_IS: 'SUBMIT_WHO_IS',
	submitWhoIs: ({ whoIs }) => ({
		type: domains.SUBMIT_WHO_IS,
		whoIs,
	}),
	GET_DETAILS: 'GET_DETAILS',
	getDetails: () => ({
		type: domains.GET_DETAILS,
	}),

	EDIT_DOMAIN_BASKET_ITEM: 'EDIT_DOMAIN_BASKET_ITEM',
	editDomainBasketItem: ({
		uuid,
		index,
		autoRenewPrivacy,
		autoRenewRegistration,
		duration,
		configs,
	}) => ({
		type: domains.EDIT_DOMAIN_BASKET_ITEM,
		uuid,
		index,
		autoRenewPrivacy,
		autoRenewRegistration,
		duration,
		configs,
	}),
};

export default domains;
