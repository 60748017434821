import { combineReducers } from 'redux';

import { reducer as add } from './addModule';
import { reducer as available } from './availableModule';
import { reducer as availableAssets } from './availableAssetsModule';
import { reducer as categories } from './categoriesModule';
import { reducer as create } from './createModule';
import { reducer as deleteReducer } from './deleteModule';
import { reducer as details } from './detailsModule';
import { reducer as list } from './listModule';
import { reducer as rename } from './renameModule';
import { reducer as remove } from './removeModule';

export default combineReducers({
	add,
	available,
	availableAssets,
	categories,
	create,
	delete: deleteReducer,
	details,
	list,
	rename,
	remove,
});
