import { getEmbeddedSvc } from './embedded-service';

import getInitialAffiliateDetails from './extra-details/affiliate';
import getInitialAuthToken from './extra-details/auth-token';
import getInitialCouponDetails from './extra-details/coupon';
import getInitialGoogleAnalyticsDetails from './extra-details/google-analytics';
import getInitialPiiDetails from './extra-details/pii-prefs';
import getInitialPreselectDepartment from './extra-details/preselect-department';
import getInitialTrackingCookieDetails from './extra-details/tracking-cookies';
import getInitialUrlDetails from './extra-details/url';
import getInitialPrechatFormDetails from './extra-details/prechat-form';

const getInitialAsyncDetails = () => Promise.all([
	getInitialGoogleAnalyticsDetails(),
]).then(results => results.flat());

const getInitialExtraDetails = () => (
	getInitialAsyncDetails()
		.then(asyncDetails => [
			...asyncDetails,

			...getInitialAffiliateDetails(),
			...getInitialAuthToken(),
			...getInitialCouponDetails(),
			...getInitialPiiDetails(),
			...getInitialPreselectDepartment(),
			...getInitialTrackingCookieDetails(),
			...getInitialUrlDetails(),
			...getInitialPrechatFormDetails(),

		])
);

const getExtraDetail = label => getEmbeddedSvc()
	.then((embeddedSvc) => {
		const { settings: { extraPrechatFormDetails } } = embeddedSvc;

		if (!extraPrechatFormDetails) return undefined;

		return extraPrechatFormDetails.find(detail => detail.label === label);
	});

const updateExtraDetail = (label, value) => getExtraDetail(label)
	.then((detail) => {
		if (!detail) return undefined;

		// eslint-disable-next-line no-param-reassign
		detail.value = value;

		return detail;
	});

export {
	getInitialExtraDetails,
	getExtraDetail,
	updateExtraDetail,
};
