import actions from './actions';

const initialState = {
	noHostnameError: false,
	isCustomerPreview: false,
};

const reducer = (state = initialState, { type, payload }) => {
	const { error, isCustomerPreview } = payload || {};
	switch (type) {
		case actions.CART_CHECKOUT_NO_HOSTNAME_ERROR:
			return { ...state, noHostnameError: error };
		case actions.CART_SET_CUSTOMER_PREVIEW: {
			const setTo =
				isCustomerPreview === undefined
					? !state.isCustomerPreview
					: isCustomerPreview;
			return { ...state, isCustomerPreview: setTo };
		}
		default:
			return state;
	}
};

export default reducer;
