import React from 'react';
import { connect } from 'react-redux';
import dialogActions from 'modules/dialogs/actions';
import basketActions from 'modules/basket/actions';
import QuantitySelectModalContents from 'components/common/QuantitySelectModalContents';

const CloneModalContents = ({ title, uuid, closeDialog, clone }) => (
	<QuantitySelectModalContents
		instructionsTop={
			<>
				How many clones would you like to create of
				<b> {title}</b>?
			</>
		}
		instructionsBottom="Note: Cloning products will create exact copies and add them to your cart. Be sure to view details like hostnames and projects and adjust them to your liking before checkout."
		onConfirm={(quantity) => {
			clone({ quantity, uuid });
			closeDialog();
		}}
		onClose={closeDialog}
		confirmText="Create Clone"
		pluralize
	/>
);

const mapDispatchToProps = (dispatch) => ({
	clone: ({ quantity, uuid }) => {
		dispatch(basketActions.cloneItem({ quantity, uuid }));
	},
	closeDialog: () => dispatch(dialogActions.close()),
});

const ConnectedCloneModalContents = connect(
	null,
	mapDispatchToProps,
)(CloneModalContents);

export default ConnectedCloneModalContents;
