import { connect } from 'react-redux';
import dialogActions from 'modules/dialogs/actions';

import { selectors as cloneSelectors } from 'modules/api/basket/item/cloneModule';
import CloneLink from './CloneLink';

const mapStateToProps = (state) => ({
	isCloning: cloneSelectors.isLoading(state),
	dialogOpen: state.dialogs.open,
});

const mapDispatchToProps = (dispatch) => ({
	openDialog: ({ title, content, dialogProps, color }) =>
		dispatch(dialogActions.open({ title, content, dialogProps, color })),
});

const ConnectedCloneLink = connect(
	mapStateToProps,
	mapDispatchToProps,
)(CloneLink);

export default ConnectedCloneLink;
