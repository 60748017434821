import { combineReducers } from 'redux';

import { reducer as list } from './listModule';
import { reducer as setDefault } from './setDefaultModule';
import { reducer as details } from './detailsModule';

export default combineReducers({
	list,
	setDefault,
	details,
});
