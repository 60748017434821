import { call, put, take, takeLatest, select } from 'redux-saga/effects';
import { actions as resetActions } from 'modules/api/basket/item/resetModule';
import { actions as itemDetailsActions } from 'modules/api/basket/item/detailsModule';
import templateSelectors from 'modules/api/basket/item/detailsModule/templateSelectors';

import snackbarSaga from 'modules/snackbar/sagas';
import { handleAddCloningVps } from '../addItem';
import basketActions from '../../actions';

function* handleResult({ error, result }) {
	if (!error) {
		yield put(itemDetailsActions.set(result?.payload));
		const uniqIdToClone = yield select(templateSelectors.uniqIdToClone);
		if (uniqIdToClone)
			// This saga has its own error handling.
			yield call(handleAddCloningVps, {
				uniqIdToClone,
				itemUuid: result?.payload.uuid,
			});
	}
	yield call(snackbarSaga, {
		error,
		errorMessage: 'Failed to reset configs',
		successMessage: 'Configs have been reset',
	});
}

function* resetItemConfigSaga({ payload: { itemUuid: uuid } }) {
	yield put(
		resetActions.fetch({
			uuid,
			alsowith: ['productInfo', 'totals', ['configs', 'options', 'totals']],
		}),
	);
	const result =
		(yield take([resetActions.setType, resetActions.errorType])) || {};
	const error = result.type === resetActions.errorType;
	yield call(handleResult, { error, result });
}

export { resetItemConfigSaga, handleResult };
export default function* resetItemConfigRoot() {
	yield takeLatest(basketActions.BASKET_ITEM_CONFIG_RESET, resetItemConfigSaga);
}
