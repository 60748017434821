const copyElementContents = (el) => {
	const range = document.createRange();
	range.selectNodeContents(el);
	const sel = window.getSelection();
	sel.removeAllRanges();
	sel.addRange(range);
	document.execCommand('copy');
	sel.removeAllRanges();
};

export default copyElementContents;
