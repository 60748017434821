import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
	actions as addActions,
	selectors as addSelectors,
} from 'modules/api/project/addModule';
import {
	actions as listActions,
	selectors as listSelectors,
} from 'modules/api/project/listModule';
import { selectors } from 'modules/addToProjectDialog/selectors';
import actions from 'modules/addToProjectDialog/actions';
import snackbarActions from 'modules/snackbar/snackbarActions';
import AddToProjectDialog from './AddToProjectDialog';

const mapStateToProps = (state) => ({
	added: {
		hasData: addSelectors.hasData(state),
		isLoading: addSelectors.isLoading(state),
	},
	list: {
		items: listSelectors.getItems(state),
	},
	isOpen: selectors.isOpen(state),
	dialogProps: selectors.dialogProps(state),
});

const mapDispatchToProps = (dispatch) => ({
	clearAdd: () => dispatch(addActions.clear()),
	addToProject: ({ uniqId, projectId }) =>
		dispatch(addActions.fetch({ project_id: projectId, assets: [uniqId] })),
	displaySnackbar: (message) => dispatch(snackbarActions.pushMessage(message)),
	fetchList: () => dispatch(listActions.fetch()),
	toggleDialog: (value) => dispatch(actions.toggleDialog(value)),
	setAddToProjectDialogProps: (props) => dispatch(actions.setProps(props)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withRouter(AddToProjectDialog));
