const getInitialExtraDetails = () => [
	{
		label: 'googlePageUrl',
		value: window.location.href,
		displayToAgent: true,
		transcriptFields: ['Google_Page_Url__c'],
	},
];

export default getInitialExtraDetails;
