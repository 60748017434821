import React from 'react';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ConfirmationDialog from 'components/common/ConfirmationDialog';
import LWTypography from 'components/common/LWTypography';

const AddToProjectDialog = ({
	clearAdd,
	addToProject,
	displaySnackbar,
	dialogProps,
	fetchList,
	list,
	toggleDialog,
	added,
	isOpen,
}) => {
	const { uniqId, assetName, toFetchAfter } = dialogProps;
	const [projectId, setProjectId] = React.useState('');
	const [projectName, setProjectName] = React.useState('');

	React.useEffect(() => {
		if (added.hasData && toFetchAfter) {
			toggleDialog(false);
			toFetchAfter();
			displaySnackbar(`Successfully added ${assetName} to ${projectName}`);
			clearAdd();
			setProjectId('');
			setProjectName('');
		}
	}, [
		added.hasData,
		assetName,
		clearAdd,
		displaySnackbar,
		projectName,
		toFetchAfter,
		toggleDialog,
	]);
	React.useEffect(() => {
		if (isOpen) {
			fetchList();
		}
	}, [fetchList, isOpen]);

	return (
		<ConfirmationDialog
			open={isOpen}
			onConfirm={() => {
				addToProject({ uniqId, projectId });
			}}
			onClose={() => toggleDialog(false)}
			title="Add Asset to Project"
		>
			<LWTypography>
				Select the project you would like to add {assetName} to.
			</LWTypography>
			<FormControl>
				<Select
					onChange={(e) => {
						setProjectId(e.target.value);
						setProjectName(e.currentTarget.dataset.projectName);
					}}
					value={projectId}
					variant="outlined"
				>
					{list.items
						? list.items.map((item) => (
								<MenuItem
									value={item.get('project_id')}
									data-project-name={item.get('project_name')}
									key={item.get('project_id')}
								>
									{item.get('project_name')}
								</MenuItem>
						  ))
						: null}
				</Select>
			</FormControl>
		</ConfirmationDialog>
	);
};

AddToProjectDialog.propTypes = {
	clearAdd: PropTypes.func.isRequired,
	addToProject: PropTypes.func.isRequired,
	displaySnackbar: PropTypes.func.isRequired,
	dialogProps: PropTypes.object,
	fetchList: PropTypes.func.isRequired,
	list: PropTypes.object.isRequired,
	toggleDialog: PropTypes.func.isRequired,
	added: PropTypes.object.isRequired,
	isOpen: PropTypes.bool.isRequired,
};

AddToProjectDialog.defaultProps = {};

export default AddToProjectDialog;
