import { spawn, takeEvery, put, select, call } from 'redux-saga/effects';
import openidActions from 'modules/openid/actions';
import { sagas as authorizeSagas } from './authorizeModule';
import { actions as logoutActions, sagas as logoutSagas } from './logoutModule';

function* logoutSaga() {
	// axios doesn't do 'no-cors'. https://github.com/axios/axios/issues/1358 So here we are...
	const customerCommunityHost = yield select((state) =>
		state.appConfig.getIn(['data', 'hosts', 'customer_community']),
	);
	const finalURL = `https://${customerCommunityHost.replace(
		/\/s$/,
		'',
	)}/secur/deleteSessionCookie.jsp`;
	yield call(fetch, finalURL, {
		method: 'GET',
		mode: 'no-cors',
		credentials: 'include',
	});
	yield put(logoutActions.fetch());
}

export default function* sagas() {
	yield spawn(authorizeSagas);
	yield spawn(logoutSagas);
	yield takeEvery([openidActions.LOGOUT], logoutSaga);
}
