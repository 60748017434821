import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';

const HomeRouter = (props) => {
	const { navData } = props;
	return (
		<Switch>
			<Route
				exact
				path={navData.get('path')}
				component={navData.get('component')}
			/>
		</Switch>
	);
};

HomeRouter.propTypes = {
	navData: PropTypes.object.isRequired,
};

export default HomeRouter;
