import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.basketApi.item.reset;

const { actions, reducer, sagas, selectors } = createAPIModule({
	getStateSlice,
	actionType: 'BASKET_ITEM_RESET',
	method: 'POST',
	url: '/basket/item/reset.json',
});

export { actions, reducer, sagas, selectors };
