import React from 'react';
import ReimageDialogContents from 'components/structural/ReimageDialogContents';
import { connect } from 'react-redux';

import { selectors as assetDetailsSelectors } from 'modules/api/asset/detailsModule';
import { selectors as backupListSelectors } from 'modules/api/storm/backup/listModule';

import restoreActions from 'modules/server/backupsAndImages/restore/actions';
import dialogActions from 'modules/dialogs/actions';

const BackupConfirmationDialogContents = ({
	initializeBackup,
	id,
	uniqId,
	...props
}) => {
	return <ReimageDialogContents {...props} />;
};

const mapStateToProps = (state, props) => {
	const backup = backupListSelectors.selected(state, props);
	return {
		confirmText: 'Restore',
		sourceTitle: 'Selected Backup',
		sourceDetails: [
			new Date(backup.time_taken).toString(), // TODO: correct this using date formatting library
			`${backup.size} GB`,
			assetDetailsSelectors.domain(state),
			assetDetailsSelectors.templateDescription(state),
			// props?.template_description, // TODO: missing from API response
		],
		targetTitle: 'Selected Server',
		targetDetails: [
			assetDetailsSelectors.domain(state),
			assetDetailsSelectors.instance(state)?.template_description,
		],
		verb: 'restoration',
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	initializeBackup: ({ id, uniqId }) => {
		dispatch(restoreActions.initializeServer({ id, uniqId }));
	},
	onConfirm: ({ rebuild }) => {
		dispatch(
			restoreActions.restoreBackup({
				force: rebuild,
				id: props.id,
				uniqId: props.uniqId,
			}),
		);
	},
	onCancel: () => {
		dispatch(dialogActions.close());
	},
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(BackupConfirmationDialogContents);
