import { all, call, take, race } from 'redux-saga/effects';

import { USER_FOUND, USER_EXPIRED } from 'redux-oidc';

import { sagas as appConfigSaga } from 'modules/api/appConfigModule';
import storyOrTest from 'utility/tools/storyOrTest';

import interworxSagas from 'modules/interworx/sagas';
import account from './account/accountSagas';
import { sagas as availableAssets } from './availableAssets';
import basket from './basket/sagas';
import gtmSagas from './gtm/sagas';
import transactionTimelineSaga from './billing/transactionTimeline/saga';
import paymentForm from './paymentForm/sagas';
import acronis from './acronis/sagas';
import navTrackerSagas from './navTracker/sagas';
import wpSagas from './www-api/wwwSagas';
import chatSagas from './chat/sagas';
import sshkeysSagas from './sshkeys/sagas';
import authSagas from './auth/authSagas';
import paymentSagas from './payment/paymentSagas';
import renewDialogSagas from './renewDialog/sagas';
import maintenanceModeSagas from './maintenanceMode/sagas';
import marketplaceSagas from './marketplace/sagas';
import notificationsSagas from './notifications/sagas';
import onetimesecret from './onetimesecret/sagas';
import openidSagas from './openid/openidSagas';
import serverSagas from './server/sagas';
import serverBackAndImagesSagas from './server/backupsAndImages/sagas';
import serverRebootSagas from './server/reboot/sagas';
import taskSchedulerSagas from './server/taskScheduler/sagas';
import statusSheetSagas from './statusSheet/sagas';
import usertaskSagas from './usertask/sagas';
import internalOnlySagas from './internalOnly/sagas';
import apiRootSaga from './api/apiRootSaga';
import { sagas as threatStack } from './threatStack';

// Sagas that require the user authentication to be completed first
function* authenticatedSagas() {
	yield take(USER_FOUND);

	yield race({
		parallel: all([
			call(navTrackerSagas),
			call(paymentForm),
			call(notificationsSagas),
			call(account),
		]),
		cancelled: take(USER_EXPIRED),
	});
}

export default function* rootSaga() {
	// Need to have the app config before anything else can operate
	const { error: appConfigError } = yield call(appConfigSaga);

	if (appConfigError) return;

	if (!storyOrTest()) yield call(gtmSagas); // We don't want this injected js in our tests or stories.

	yield all([
		call(authenticatedSagas),
		call(availableAssets),
		call(renewDialogSagas),
		call(interworxSagas),
		call(apiRootSaga),
		call(authSagas),
		call(onetimesecret),
		call(basket),
		call(acronis),
		call(transactionTimelineSaga),
		call(serverSagas),
		call(serverBackAndImagesSagas),
		call(wpSagas),
		call(chatSagas),
		call(paymentSagas),
		call(serverRebootSagas),
		call(usertaskSagas),
		call(sshkeysSagas),
		call(statusSheetSagas),
		call(openidSagas),
		call(internalOnlySagas),
		call(maintenanceModeSagas),
		call(marketplaceSagas),
		call(threatStack),
		call(taskSchedulerSagas),
	]);
}
