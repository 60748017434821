import { combineReducers } from 'redux';
import backup from './backup/backupReducer';
import image from './image/imageReducer';
import template from './template/templateReducer';

import privateReducer from './private/privateReducer';

export default combineReducers({
	backup,
	image,
	template,
	private: privateReducer,
});
