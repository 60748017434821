import {
	createAPIModule,
	createSelectors,
} from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.billing.changeMonthlyBillDay;

const moduleKeys = {
	UNCHANGED: 'unchanged',
};

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'BILLING_CHANGEMONTHLYBILLDAY',
	url: '/billing/changeMonthlyBillDay.json',
});

const unchangedSelectors = createSelectors(
	getStateSlice,
	false,
	moduleKeys.UNCHANGED,
);

const selectors = {
	unchangedSelectors,
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors, moduleKeys };
