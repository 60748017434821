import React from 'react';
import LWButton from 'components/common/LWButton';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const SLWButton = styled(LWButton)`
	color: ${({ theme }) => theme.palette.primary.dark};
	font-weight: 600;
	min-width: 71px;
	transition: opacity 0.25s;
`;

function DismissButton({ action, visible, closeSheet, notificationCount }) {
	const handleClick = () => {
		if (visible) {
			action();
			if (notificationCount === 1) closeSheet();
		}
	};

	return (
		<SLWButton
			onClick={handleClick}
			variant="text"
			color="primary"
			style={{ opacity: Number(visible) }}
		>
			Dismiss
		</SLWButton>
	);
}

DismissButton.propTypes = {
	action: PropTypes.func.isRequired,
	visible: PropTypes.bool,
	closeSheet: PropTypes.func.isRequired,
	notificationCount: PropTypes.number.isRequired,
};

DismissButton.defaultProps = {
	visible: true,
};

export default DismissButton;
