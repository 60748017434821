import { createSelector } from 'reselect';

const getStateSlice = (state) => state.notificationsReducer;

const selectors = {
	getStateSlice,
	isLoading: createSelector(
		getStateSlice,
		(slice) => slice.pending !== 0,
	),
	getError: createSelector(
		getStateSlice,
		(slice) => slice.error,
	),
	hasError: createSelector(
		getStateSlice,
		(slice) => slice.error,
	),
	getData: createSelector(
		getStateSlice,
		(slice) => slice.data,
	),
};

export default selectors;
