import { spawn } from 'redux-saga/effects';

import { sagas as updateSagas } from './updateModule';
import { sagas as addSagas } from './addModule';
import { sagas as listSagas } from './listModule';
import { sagas as removeSagas } from './removeModule';

export default function* sagas() {
	yield spawn(listSagas);
	yield spawn(removeSagas);
	yield spawn(updateSagas);
	yield spawn(addSagas);
}
