import { createSelector } from 'reselect';
import { Map as ImmutableMap } from 'immutable';
import { roles as rolesData } from 'utility/constants/roles';

const getOidcStateSlice = (state) => state.reduxOidc;

const hasAuthToken = createSelector(
	[getOidcStateSlice],
	(oidc) => !!oidc.getIn(['user', 'access_token']),
);

const decodedPayload = createSelector(
	getOidcStateSlice,
	(oidc) => {
		const idToken = oidc.getIn(['user', 'id_token'], '');
		const idTokenParts = idToken.split('.');
		let profile = '';
		if (idTokenParts && idTokenParts[1])
			profile = JSON.parse(atob(idTokenParts[1]));
		return profile;
	},
);

const profile = createSelector(
	getOidcStateSlice,
	(oidc) => {
		return oidc.getIn(['user', 'profile'], ImmutableMap());
	},
);

const givenName = createSelector(
	profile,
	(userProfile) => userProfile.get('given_name'),
);

const familyName = createSelector(
	profile,
	(userProfile) => userProfile.get('family_name'),
);

const email = createSelector(
	profile,
	(userProfile) => userProfile.get('email'),
);

const username = createSelector(
	decodedPayload,
	(payload) => payload.username,
);

const roles = createSelector(
	decodedPayload,
	(payload) => payload.role,
);

const hasRoles = createSelector(
	decodedPayload,
	(payload) => !!payload.role,
);

const group = createSelector(
	decodedPayload,
	(payload) => payload.group,
);

const hasGroup = createSelector(
	decodedPayload,
	(payload) => !!payload.group,
);

const overrides = createSelector(
	decodedPayload,
	(payload) => payload.override,
);

const isMasquerade = createSelector(
	decodedPayload,
	(payload) => !!payload?.masquerade,
);

const isBasketAdmin = createSelector(
	group,
	(payload = []) => payload?.includes('basket-admin'),
);

// This user has an actual account. If no token exists, or logged in as basket admin, this is not the case
const isAccountUser = createSelector(
	hasAuthToken,
	isBasketAdmin,
	(hasAuthTokenSelector, isBasketAdminSelector) =>
		hasAuthTokenSelector && !isBasketAdminSelector,
);

const isOwner = createSelector(
	roles,
	(userRoles = []) =>
		userRoles?.includes(rolesData.ACCOUNT_OWNER.key) ||
		userRoles?.includes(rolesData.SECONDARY_OWNER.key),
);

const isSuspended = createSelector(
	roles,
	(userRoles = []) => userRoles?.includes(rolesData.SUSPENDED_OWNER.key),
);

const isSetupUser = createSelector(
	roles,
	(userRoles = []) => userRoles?.includes(rolesData.SETUP_USER.key),
);

const mainRole = createSelector(
	roles,
	(userRoles) => {
		if (userRoles?.includes(rolesData.SUSPENDED_OWNER.key))
			return rolesData.SUSPENDED_OWNER.key; // Always return this first as it's the most important role if they have it set
		if (userRoles?.includes(rolesData.BASKET_ADMIN.key))
			return rolesData.BASKET_ADMIN.key;
		if (userRoles?.includes(rolesData.ACCOUNT_OWNER.key))
			return rolesData.ACCOUNT_OWNER.key;
		if (userRoles?.includes(rolesData.SECONDARY_OWNER.key))
			return rolesData.SECONDARY_OWNER.key;
		if (userRoles?.includes(rolesData.PURCHASER.key))
			return rolesData.PURCHASER.key;
		if (userRoles?.includes(rolesData.TECHNICIAN.key))
			return rolesData.TECHNICIAN.key;
		if (userRoles?.includes(rolesData.SETUP_USER.key))
			return rolesData.SETUP_USER.key;
		return null;
	},
);

const isTokenExpired = createSelector(
	getOidcStateSlice,
	(oidc) => {
		const tokenExpiration = oidc.getIn(['user', 'expires_at']);
		if (!tokenExpiration) return true;
		return new Date().getTime() / 1000 > tokenExpiration;
	},
);

const clearOpenidSession = createSelector(
	getOidcStateSlice,
	(oidc) => oidc.setIn(['user', '']),
);

const getAuthToken = createSelector(
	[getOidcStateSlice],
	(oidc) => oidc.getIn(['user', 'access_token'], ''),
);

const isKeroOnly = createSelector(
	hasRoles,
	hasGroup,
	hasAuthToken,
	(rolesPayload, groupPayload, authToken) => {
		return !rolesPayload && !groupPayload && authToken;
	},
);

const isLoggedInAndNotBasketAdmin = createSelector(
	hasAuthToken,
	isBasketAdmin,
	(loggedIn, basketAdmin) => loggedIn && !basketAdmin,
);

export {
	getOidcStateSlice,
	getAuthToken,
	hasAuthToken,
	hasGroup,
	decodedPayload,
	clearOpenidSession,
	isTokenExpired,
	username,
	givenName,
	familyName,
	email,
	roles,
	group,
	profile,
	overrides,
	isAccountUser,
	isMasquerade,
	isBasketAdmin,
	isKeroOnly,
	isOwner,
	isSuspended,
	isSetupUser,
	mainRole,
	isLoggedInAndNotBasketAdmin,
};
