import Immutable from 'immutable';

const initialState = Immutable.fromJS({
	isInternalUser: false,
});

const internalOnly = (state = initialState, action) => {
	switch (action.type) {
		case 'INTERNAL_ONLY_SET':
			return state.set('isInternalUser', action.payload);
		default:
			return state;
	}
};

export default internalOnly;
