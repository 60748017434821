import React, { useEffect, useCallback, useMemo } from 'react';
import { Route, Redirect } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import authActions from 'modules/auth/authActions';
import {
	roles as userRolesSelector,
	isSuspended as isSuspendedSelector,
	hasAuthToken,
	isSetupUser as isSetupUserSelector,
	isBasketAdmin as isBasketAdminSelector,
} from 'modules/auth/oidcSelectors';
import { getQueryParams } from 'modules/routeSelectors';
import { masterRoles } from 'utility/constants/roles';
import AuthenticatedRoute from 'containers/structural/AuthenticatedRoute';
import UnauthorizedPage from 'components/structural/UnauthorizedPage';

const RoleRestrictedRoute = ({
	component: Component,
	whitelistedRoles,
	isBasketAdmin,
	isSuspended,
	isSetupUser,
	isLoggedIn,
	userRoles,
	fetchUserDetails,
	allowUnauth,
	queryParams,
	...rest
}) => {
	useEffect(() => {
		if (!userRoles && isLoggedIn && !isBasketAdmin && isLoggedIn) {
			fetchUserDetails();
		}
	}, [fetchUserDetails, userRoles, isLoggedIn, isBasketAdmin]);
	const whiteListedRoleKeys = whitelistedRoles.map((role) => role.key);
	const masterRoleKeys = masterRoles.map((role) => role.key);
	const hasProperRoles = useMemo(() => {
		return (
			userRoles &&
			(userRoles.some((role) => masterRoleKeys.includes(role)) ||
				userRoles.some((role) => whiteListedRoleKeys.includes(role)))
		);
	}, [masterRoleKeys, userRoles, whiteListedRoleKeys]);

	const getRenderComponent = useCallback(
		(props) => {
			if (isBasketAdmin || (allowUnauth && !isLoggedIn))
				return <Component {...props} {...rest} />; // TODO: Figure out why this is changing state even on unmount
			if (!userRoles) return null; // TODO: remove this? hasProperRoles would be false so we would return the unauthed page below..
			// if user is suspended, they no longer recieve the god powers of master roles
			if (hasProperRoles) {
				return <Component {...props} {...rest} />;
			}
			if (isLoggedIn && isSetupUser) {
				return <Redirect to="/shop/marketplace" />;
			}
			return (
				<UnauthorizedPage
					isSuspended={isSuspended}
					roles={whitelistedRoles.length > 0 ? whitelistedRoles : masterRoles}
				/>
			);
		},
		[
			allowUnauth,
			hasProperRoles,
			isBasketAdmin,
			isLoggedIn,
			isSuspended,
			isSetupUser,
			rest,
			userRoles,
			whitelistedRoles,
		],
	);

	if (allowUnauth && !isLoggedIn)
		return <Route {...rest} render={getRenderComponent} />;
	return <AuthenticatedRoute {...rest} component={getRenderComponent} />;
};

const mapStateToProps = (state) => ({
	userRoles: userRolesSelector(state),
	isBasketAdmin: isBasketAdminSelector(state),
	isSetupUser: isSetupUserSelector(state),
	isSuspended: isSuspendedSelector(state),
	isLoggedIn: hasAuthToken(state),
	queryParams: getQueryParams(state),
});

const mapDispatchToProps = (dispatch) => ({
	fetchUserDetails: (timeout) => dispatch(authActions.userDetailsInit(timeout)),
});

RoleRestrictedRoute.propTypes = {
	component: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
	whitelistedRoles: PropTypes.array,
	isSuspended: PropTypes.bool,
	isSetupUser: PropTypes.bool,
	isLoggedIn: PropTypes.bool.isRequired,
	userRoles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	fetchUserDetails: PropTypes.func,
	allowUnauth: PropTypes.bool,
};

RoleRestrictedRoute.defaultProps = {
	whitelistedRoles: [],
	allowUnauth: false,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(RoleRestrictedRoute);
