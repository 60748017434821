import produce from 'immer';
import actions from './actions';

const initialState = {
	canAutoReboot: false,
	isDialogOpen: false,
	domain: null,
	uniqId: null,
};

/* eslint-disable default-case, no-param-reassign */
const reducer = (state = initialState, action) =>
	produce(state, (draft) => {
		const { payload } = action;
		switch (action.type) {
			case actions.SERVER_REBOOT_OPEN_DIALOG:
				draft.canAutoReboot = payload.canAutoReboot;
				draft.domain = payload.domain;
				draft.isDialogOpen = true;
				draft.uniqId = payload.uniqId;
				break;

			case actions.SERVER_REBOOT_CLOSE_DIALOG:
				draft.isDialogOpen = false;
				draft.uniqId = null;
				break;
			case actions.SERVER_REBOOT_INVOKE_REBOOT:
				draft.uniqId = payload.uniqId;
				break;
		}
	});

export default reducer;
