import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';

import {
	actions as renewActions,
	selectors as renewSelectors,
} from 'modules/api/ssl/certificate/renewModule';
import { selectors as productSelectors } from 'modules/api/product/detailsModule';
import { getPayload } from 'modules/renewDialog/selectors';
import RenewSSLForm from './RenewSSLForm';

const form = 'renewSSLForm';
const formSelector = formValueSelector(form);

const mapStateToProps = (state) => ({
	renewDialogObj: {
		payload: getPayload(state),
	},
	renewObj: {
		isLoading: renewSelectors.isLoading(state),
	},
	initialValues: {
		numberOfYears: 1,
	},
	selected: {
		numberOfYears: formSelector(state, 'numberOfYears'),
	},
	product: {
		isLoading: productSelectors.isLoading(state),
		oneYearPrice: productSelectors.sslRenewalOneYearPrice(state),
		twoYearPrice: productSelectors.sslRenewalTwoYearPrice(state),
	},
});

const mapDispatchToProps = (dispatch) => ({
	renewSSL: ({ duration, uniqId }) =>
		dispatch(
			renewActions.fetch({
				duration,
				uniq_id: uniqId,
			}),
		),
});

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	),
	reduxForm({
		form,
		enableReinitialize: true,
	}),
)(RenewSSLForm);
