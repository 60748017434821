import { createSelector } from 'reselect';

const getStateSlice = (state) => state.renewDialog;

const getFetchAfter = createSelector(
	getStateSlice,
	(renewDialog) => renewDialog.toFetchAfter,
);

const getPayload = createSelector(
	getStateSlice,
	(renewDialog) => renewDialog.payload,
);

const getProductType = createSelector(
	getStateSlice,
	(renewDialog) => renewDialog.productType,
);

const getDialogOpen = createSelector(
	getStateSlice,
	(renewDialog) => renewDialog.dialogOpen,
);

const getError = createSelector(
	getStateSlice,
	(renewDialog) => renewDialog.error,
);

const getTitle = createSelector(
	getStateSlice,
	(renewDialog) => renewDialog.title,
);

export {
	getStateSlice,
	getDialogOpen,
	getError,
	getTitle,
	getPayload,
	getFetchAfter,
	getProductType,
};
