const NOTIFICATION_SEVERITY = 'Notification';
const ERROR_SEVERITY = 'Error';
const WARNING_SEVERITY = 'Warning';
const CRITICAL_SEVERITY = 'Critical';

export {
	NOTIFICATION_SEVERITY,
	ERROR_SEVERITY,
	WARNING_SEVERITY,
	CRITICAL_SEVERITY,
};
