const actions = {
	SET_THREATSTACK_PAGE: 'SET_THREATSTACK_PAGE',
	setPageNum: (pageNum) => ({
		type: actions.SET_THREATSTACK_PAGE,
		pageNum,
	}),
	SET_THREATSTACK_ITEMS_PER_PAGE: 'SET_THREATSTACK_ITEMS_PER_PAGE',
	setItemsPerPage: (pageSize) => ({
		type: actions.SET_THREATSTACK_ITEMS_PER_PAGE,
		pageSize,
	}),
	FETCH_THREATSTACK: 'FETCH_THREATSTACK',
	fetch: ({ init }) => ({
		type: actions.FETCH_THREATSTACK,
		init,
	}),
};

export default actions;
