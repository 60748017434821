import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';

const getStateSlice = (state) => state.basketApi.generateSaved;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'BASKET_GENERATE_SAVED',
	method: 'POST',
	url: '/basket/generateSaved.json',
});

const getSourceUuid = createSelector(
	defaultSelectors.getNativeData,
	(slice) => slice?.source_uuid, // TODO: get the correct schema.
);

const getUuid = createSelector(
	defaultSelectors.getNativeData,
	(slice) => slice?.uuid, // TODO: get the correct schema.
);

const selectors = {
	getSourceUuid,
	getUuid,
	...defaultSelectors,
};
export { actions, reducer, sagas, selectors };
