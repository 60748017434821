import { combineReducers } from 'redux';

import { reducer as ip } from './ipModule';
import { reducer as dynamicChild } from './dynamicChildModule';
import { reducer as servers } from './serversModule';

export default combineReducers({
	dynamicChild,
	ip,
	servers,
});
