import { spawn } from 'redux-saga/effects';
import { sagas as applyModules } from './applyModule';
import { sagas as detailsSagas } from './detailsModule';
import { sagas as hostedFormTokenSagas } from './hostedFormTokenModule';

export default function* sagas() {
	yield spawn(hostedFormTokenSagas);
	yield spawn(applyModules);
	yield spawn(detailsSagas);
}
