import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.monitoring.bandwidth.stats;

const { actions, reducer, sagas, selectors } = createAPIModule({
	getStateSlice,
	actionType: 'MONITORING_BANDWIDTH_STATS',
	url: '/monitoring/bandwidth/stats.json',
});

export { actions, reducer, sagas, selectors };
