import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.network.loadbalancer.details;

const { actions, reducer, sagas, selectors } = createAPIModule({
	getStateSlice,
	actionType: 'NETWORK_LOADBALANCER_DETAILS',
	url: '/network/loadbalancer/details.json',
});

export { actions, reducer, sagas, selectors };
