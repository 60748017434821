import { put, select, call, delay, takeLatest } from 'redux-saga/effects';
import { change } from 'redux-form';
import extraIpSelectors from 'modules/api/basket/item/detailsModule/extraIpSelectors';
import { isEqual } from 'lodash';
import basketActions from 'modules/basket/actions';
import { itemAssertProperties } from 'modules/basket/sagas/itemAssertProperties';
import {
	selectors as itemDetailsSelectors,
	actions as itemDetailsActions,
} from 'modules/api/basket/item/detailsModule';
// import detailsSaga from 'modules/basket/details';
import actions from './actions';
import selectors, { formName } from './selectors';

// TODO: write unit tests.
function* updateExtraIpConfig() {
	const extraIpCount = yield select(selectors.extraIpCount);
	const currentApiValue = yield select(extraIpSelectors.extraIpNumUnits);
	if (extraIpCount === currentApiValue) return;
	yield put(
		basketActions.setItemConfig({
			key: 'ExtraIp',
			numUnits: extraIpCount,
			value: 1,
		}),
	);
}

function* updateIpPool() {
	const currentValuesIds = yield select(selectors.currentValuesIds);
	const selectedPooledIps = yield select(extraIpSelectors.selectedPooledIps);
	if (isEqual(currentValuesIds, selectedPooledIps)) return;
	yield call(itemAssertProperties, {
		properties: {
			ip_pool: currentValuesIds,
		},
	});
}

function* updateIpPoolForm() {
	yield put(
		// set the form to however the api module ended up.
		change(
			formName,
			'selected',
			yield select(extraIpSelectors.initialIpPoolValues),
		),
	);
}

function* updateIpCount({ skipApiUpdate } = {}) {
	if (skipApiUpdate) return; // used for init
	if (process.env.NODE_ENV !== 'test') {
		yield delay(3000);
	}
	yield call(updateExtraIpConfig); // Set the count to api according to redux.
	yield call(updateIpPool); // Set the ip pool according to redux

	const uuid = yield select(itemDetailsSelectors.uuid);
	// TODO: Limit our fetchItemDetails calls. They are happening at the beginning of the takes,
	// and are therefore sometimes fetching old data. That's why this one is here:
	yield put(basketActions.fetchItemDetails({ uuid }));
}

export default function*() {
	yield takeLatest(
		[
			actions.BASKET_SET_NEW_IP_COUNT,
			actions.BASKET_DECREMENT_NEW_IP_COUNT,
			actions.BASKET_ASSERT_IP_POOL,
		],
		updateIpCount,
	);
	yield takeLatest([itemDetailsActions.setType], updateIpPoolForm);
}
