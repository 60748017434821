import { getList } from 'testUtils/helperFunctions';
import { standard, alsowithData, backupsKeys } from './details';

const path = '/asset/';

export { backupsKeys };
export default {
	[`${path}details.json`]: ({ uniq_id: uniqId, alsowith = [] }) => {
		let res = standard[uniqId];
		alsowith.forEach((key) => {
			res = { ...res, ...{ [key]: alsowithData[key][uniqId] } };
		});
		return res;
	},
	[`${path}list.json`]: ({
		alsowith = [],
		// category, /* feel free to add this in. */
		page_num: pageNum,
		page_size: pageSize,
	}) => getList({ pageSize, pageNum, data: standard, alsowith, alsowithData }),
};
