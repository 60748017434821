import { spawn } from 'redux-saga/effects';

import { sagas as cancelTransferSagas } from './cancelTransferModule';
import { sagas as createOutgoingSagas } from './createOutgoingModule';
import { sagas as createIncomingSagas } from './createIncomingModule';
import { sagas as deleteSagas } from './deleteModule';
import { sagas as detailsSagas } from './detailsModule';
import { sagas as listSagas } from './listModule';
import { sagas as resendEmailSagas } from './resendEmailModule';
import { sagas as sendEPPCodeSagas } from './sendEPPCodeModule';

export default function* transferSagas() {
	yield spawn(cancelTransferSagas);
	yield spawn(createIncomingSagas);
	yield spawn(createOutgoingSagas);
	yield spawn(deleteSagas);
	yield spawn(detailsSagas);
	yield spawn(listSagas);
	yield spawn(resendEmailSagas);
	yield spawn(sendEPPCodeSagas);
}
