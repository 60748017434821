import React from 'react';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import LWTooltip from 'components/common/LWTooltip';

const ForceReboot = ({ forceReboot, setForceReboot, ...rest }) => {
	return (
		<Grid alignItems="center" container {...rest}>
			<FormControlLabel
				control={
					<Switch
						checked={forceReboot}
						onChange={() => setForceReboot(!forceReboot)}
						value="force"
						inputProps={{ 'aria-label': 'force reboot' }}
					/>
				}
				label="Use Force"
			/>
			<LWTooltip>
				If the server is unresponsive, you can enable this option to perform a
				forceful reboot.
			</LWTooltip>
		</Grid>
	);
};

ForceReboot.propTypes = {
	forceReboot: PropTypes.bool.isRequired,
	setForceReboot: PropTypes.func.isRequired,
};

export default ForceReboot;
