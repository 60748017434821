const actions = {
	ON: 'MAINTENANCE_MODE_ON',
	on: (payload) => ({
		type: actions.ON,
		payload,
	}),
	OFF: 'MAINTENANCE_MODE_OFF',
	off: (payload) => ({
		type: actions.OFF,
		payload,
	}),
};

export default actions;
