const acronisProductMap = (serverProductCode) => {
	switch (serverProductCode) {
		case 'SS.VPS':
		case 'SS.VPS.WT':
			return 'Acronis.VPS';
		case 'SS.VPS.WIN':
			return 'Acronis.VPS.WIN';
		case 'SS.VM':
		case 'SS.VM.R':
			return 'Acronis.VM';
		case 'SS.VM.WIN':
			return 'Acronis.VM.WIN';
		default:
			return 'AcronisBackup';
	}
};

const acronisProductCodes = [
	'Acronis.VPS',
	'Acronis.VPS.WIN',
	'Acronis.VM',
	'Acronis.VM.WIN',
	'AcronisBackup',
];

const acronisDestinationOptionsMap = (acronisProductCode) => {
	switch (acronisProductCode) {
		case 'Acronis.VPS':
			return {
				LW: 'AcronisLiquidWebStorage.VPS',
				ACRONIS: 'AcronisCloudStorage.VPS',
			};
		case 'Acronis.VPS.WIN':
			return {
				LW: 'AcronisLiquidWebStorage.VPS.W',
				ACRONIS: 'AcronisCloudStorage.VPS.W',
			};
		case 'Acronis.VM':
			return {
				LW: 'AcronisLiquidWebStorage.VM',
				ACRONIS: 'AcronisCloudStorage.VM',
			};
		case 'Acronis.VM.WIN':
			return {
				LW: 'AcronisLiquidWebStorage.VM.W',
				ACRONIS: 'AcronisCloudStorage.VM.W',
			};
		default:
			return {
				LW: 'AcronisLiquidWebStorage',
				ACRONIS: 'AcronisCloudStorage',
			};
	}
};

export { acronisProductCodes, acronisDestinationOptionsMap };
export default acronisProductMap;
