import { createSelector } from 'reselect';
import { formValueSelector } from 'redux-form';
// api
import { selectors as availableConfigsSelectors } from 'modules/api/server/availableConfigsModule';
import { selectors as assetDetailsSelectors } from 'modules/api/asset/detailsModule';

import { toInt } from 'utility/format';

const formName = 'CloudSliderOptionsForm';
const formValuesSelector = (state) => {
	const selector = formValueSelector(formName);
	return selector(
		state,
		'ram',
		'cores',
		'disk',
		'diskType',
		'filtersDisabled',
		'selectedOption',
	);
};

// Accepts an array of options, and the current value. Returns the two values in the options array that are immediately above and below the current.
const getFilterDefaults = ({ options, current }) => {
	if (!options.length) return [0, 0];
	const reversed = [...options].reverse(); // Don't use reverse directly on this array. You will cry, I promise.
	const min = reversed.find((option) => option < current) || options[0];
	const max =
		options.find(
			(option) => Math.round(option) > Math.round(current) && option !== min,
		) || options.slice(-1)[0];
	return [min !== max ? min : options[0], max];
};

const getStateSlice = (state) => state.serverStore.resize.cloudConfig;

const ramDefault = createSelector(
	availableConfigsSelectors.ram,
	assetDetailsSelectors.RAM,
	(options, current) => {
		return getFilterDefaults({
			options,
			current: toInt(current),
		});
	},
);

const coresDefault = createSelector(
	availableConfigsSelectors.cores,
	assetDetailsSelectors.CPUint,
	(options, current) => {
		return getFilterDefaults({
			options,
			current,
		});
	},
);

const storageDefault = createSelector(
	availableConfigsSelectors.storage,
	assetDetailsSelectors.storageSize,
	(options, current) => {
		return getFilterDefaults({
			options,
			current,
		});
	},
);
const selectors = {
	getStateSlice,
	ramDefault,
	coresDefault,
	storageDefault,
	formValuesSelector,
};

export {
	ramDefault,
	coresDefault,
	storageDefault,
	getStateSlice,
	formValuesSelector,
	formName,
};
export default selectors;
