import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';
import { List as ImmutableList } from 'immutable';
import { getType } from 'modules/api/asset/listModule';
import displayName from 'utility/assetDisplayNames';

const getStateSlice = (state) => state.project.details;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'PROJECT_DETAILS',
	url: '/project/details.json',
});

const domains = createSelector(
	getStateSlice,
	(slice) => slice.getIn(['data', 'domains'], ImmutableList()),
);
const servers = createSelector(
	getStateSlice,
	(slice) => slice.getIn(['data', 'servers']),
);

const withType = createSelector(
	getStateSlice,
	(slice) =>
		slice
			.getIn(['data', 'servers'], ImmutableList())
			.map((asset) => asset.set('assetType', getType(asset))),
);

const withDisplayName = createSelector(
	withType,
	(slice) =>
		slice.map((asset) =>
			asset.set(
				'display',
				displayName(asset.get('assetType'), asset.get('type')),
			),
		),
);

const services = createSelector(
	getStateSlice,
	(slice) => slice.getIn(['data', 'services'], ImmutableList()),
);

const selectors = {
	projectName: createSelector(
		getStateSlice,
		(slice) => slice.getIn(['data', 'project_name']),
	),
	domains,
	servers,
	services,
	withDisplayName,
	numberOfAssets: createSelector(
		domains,
		servers,
		services,
		(Domains, Servers, Services) => {
			return Domains && Servers && Services
				? Domains.size + Servers.size + Services.size
				: null;
		},
	),
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };
