import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import NumberField from 'components/atoms/NumberField';
import CartItemExpansion from 'components/cart/CartItem/CartItemExpansion';
import LWButton from 'components/common/LWButton';
import LWTypography from 'components/common/LWTypography';
import { toNumberOrEmptyString } from 'utility/format';

const Admin = ({
	adminItemModify, // from connected
	isLoading, // from connected
	itemUuid,
	itemProperties = {},
	discountPercByItemUuid,
	customerNotes,
	internalNotes,
	error,
	maxDiscount,
}) => {
	const discountPercent = discountPercByItemUuid[itemUuid]?.value;
	const [newDiscountPercent, setNewDiscountPercent] = useState(discountPercent);
	const [newCustomerNotes, setNewCustomerNotes] = useState(customerNotes);
	const [newInternalNotes, setNewInternalNotes] = useState(internalNotes);
	const [isExpanded, setIsExpanded] = useState(false);
	const [saveClicked, setSaveClicked] = useState(false);
	useEffect(() => {
		setNewDiscountPercent(discountPercent);
	}, [discountPercent]);
	useEffect(() => {
		if (error) {
			setIsExpanded(true);
			setSaveClicked(false);
		} else if (saveClicked && !isLoading) {
			setSaveClicked(false);
			setIsExpanded(false);
		}
	}, [error, isLoading, saveClicked]);

	const showDiscountWarning = Number(newDiscountPercent) > Number(maxDiscount);

	const saveChanges = () => {
		const obj = {};
		// If there are changes to properties, add them to the object.
		if (
			newCustomerNotes !== customerNotes ||
			newInternalNotes !== internalNotes
		) {
			obj.properties = {
				...itemProperties,
				customerNotes: newCustomerNotes,
				internalNotes: newInternalNotes,
			};
		}

		// If there are changes to the discount, add it to the object.
		if (
			Number(newDiscountPercent).toFixed(2) !==
			Number(discountPercent).toFixed(2)
		) {
			obj.discountPercent = newDiscountPercent;
			obj.discountUuid = discountPercByItemUuid[itemUuid].uuid;
		}

		adminItemModify({
			...obj,
			itemUuid,
		});
		// Put setSaveClicked at the end of the stack so isLoading gets set first.
		setTimeout(() => setSaveClicked(true));
	};

	return (
		<CartItemExpansion title="Admin" {...{ isExpanded, setIsExpanded }}>
			<Box width="100%">
				<Box maxWidth={300} mt={-2.5}>
					<NumberField
						label="Discount Percent"
						inputProps={{
							min: 0,
							max: 100,
							step: 1,
						}}
						onChange={(value) => {
							setNewDiscountPercent(value);
						}}
						value={toNumberOrEmptyString(newDiscountPercent)}
						disabled={isLoading}
					/>
					{showDiscountWarning && (
						<LWTypography
							BoxProps={{ align: 'center', width: '100%', mt: -0.5 }}
							bold
							variant="caption"
							color="palette.primary.dark"
						>
							{`Max discount before required approval: ${maxDiscount}%`}
						</LWTypography>
					)}
				</Box>
				<TextField
					label="Customer Notes"
					variant="outlined"
					multiline
					rows={5}
					onChange={(e) => {
						setNewCustomerNotes(e.target.value);
					}}
					margin="normal"
					value={newCustomerNotes}
					disabled={isLoading}
				/>
				<TextField
					label="Internal Notes"
					variant="outlined"
					multiline
					rows={5}
					onChange={(e) => {
						setNewInternalNotes(e.target.value);
					}}
					margin="normal"
					value={newInternalNotes}
					disabled={isLoading}
				/>
				<LWButton isLoading={isLoading} onClick={saveChanges}>
					Save
				</LWButton>
				{/* TODO: Once we have Ken's new error component, put one right here with the error string. */}
			</Box>
		</CartItemExpansion>
	);
};

Admin.propTypes = {
	itemUuid: PropTypes.string.isRequired,
	itemProperties: PropTypes.object,
	discountPercByItemUuid: PropTypes.object,
	customerNotes: PropTypes.string,
	internalNotes: PropTypes.string,
	adminItemModify: PropTypes.func.isRequired,
	isLoading: PropTypes.bool,
	error: PropTypes.string,
	maxDiscount: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

Admin.defaultProps = {
	discountPercByItemUuid: {},
	customerNotes: '',
	internalNotes: '',
	maxDiscount: 100,
};

export default Admin;
