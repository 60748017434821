import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import LWDialog from 'components/common/LWDialog';
import unblockIPDialogActions from 'modules/unblockIPDialog/unblockIPDialogActions';
import {
	actions as unblockIPActions,
	selectors as unblockIPSelectors,
} from 'modules/api/server/WHM/firewall/unblockipModule';
import snackbarActions from 'modules/snackbar/snackbarActions';
import { selectors as listSelectors } from 'modules/api/asset/listModule';
import {
	actions as supportRequestCreateActions,
	selectors as supportRequestCreateSelectors,
} from 'modules/api/support/request/createModule';
import UnblockIPForm from './UnblockIpForm';
import UnblockIPSupportForm from './UnblockIpSupportForm';

const styles = () => ({
	root: {},
});
const UnblockIPDialog = ({
	classes,
	closeDialog,
	isOpen,
	serverDetails,
	submitUnblockIP,
	data,
	isLoading,
	hasError,
	error,
	openSnackbar,
	cPanelServerList,
	submitUnblockIPSupportRequest,
}) => {
	const [
		unblockIPSupportDialogIsOpen,
		setunblockIPSupportDialogIsOpen,
	] = useState(false);
	const [supportIPDetails, setSupportIPDetails] = useState(null);
	const [errorMsg, setErrorMsg] = useState(null);
	const [showTextField, setShowTextField] = useState(false);
	const onDialogClose = useCallback(() => {
		setunblockIPSupportDialogIsOpen(false);
		setShowTextField(false);
		closeDialog();
	}, [setunblockIPSupportDialogIsOpen, setShowTextField, closeDialog]);

	useEffect(() => {
		if (data) {
			onDialogClose();
			openSnackbar(data.data);
		}
	}, [data, onDialogClose, openSnackbar, error]);

	useEffect(() => {
		if (error && hasError) {
			setunblockIPSupportDialogIsOpen(true);
			if (typeof error === 'string') {
				setErrorMsg(error);
			} else {
				setErrorMsg(error.getIn(['data', 'full_message']));
			}
		}
	}, [hasError, error]);

	const handleSubmitUnblockIPSetValues = (values) => {
		const cPanelServerListArray = cPanelServerList.toJS();
		const serverDetailsForSupportForm = cPanelServerListArray.find(
			(x) => x.uniq_id === values.serverUniqId,
		);
		const serverDetailsForSupportDialog = {
			ip: values.ip,
			server: serverDetailsForSupportForm || serverDetails,
		};
		setSupportIPDetails(serverDetailsForSupportDialog);
		submitUnblockIP(values);
	};
	const handleSubmitRequest = (values) => {
		submitUnblockIPSupportRequest(values);
	};

	return (
		<LWDialog
			title="Unblock IPs"
			onClose={onDialogClose}
			maxWidth="sm"
			fullWidth
			open={isOpen}
			className={classes.root}
		>
			{unblockIPSupportDialogIsOpen ? (
				<UnblockIPSupportForm
					serverDetails={supportIPDetails}
					errorMsg={errorMsg}
					onClose={onDialogClose}
					onSubmit={(val) => handleSubmitRequest(val)}
					showTextField={showTextField}
					setShowTextField={setShowTextField}
				/>
			) : (
				<UnblockIPForm
					serverDetails={serverDetails}
					onClose={onDialogClose}
					onSubmit={(values) => handleSubmitUnblockIPSetValues(values)}
					submitPending={isLoading}
				/>
			)}
		</LWDialog>
	);
};

UnblockIPDialog.defaultProps = {
	data: null,
};

UnblockIPDialog.propTypes = {
	classes: PropTypes.object.isRequired,
	closeDialog: PropTypes.func.isRequired,
	submitUnblockIP: PropTypes.func.isRequired,
	hasError: PropTypes.bool,
	data: PropTypes.object,
	openSnackbar: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	isOpen: state.unBlockIPDialog.get('isOpen'),
	serverDetails: state.unBlockIPDialog.get('serverDetails'),
	data: unblockIPSelectors.getNativeData(state),
	isLoading: unblockIPSelectors.isLoading(state),
	hasError: unblockIPSelectors.hasError(state),
	error: unblockIPSelectors.getError(state),
	cPanelServerList: listSelectors.getcPanelServers(state),
	errorSupportRequest: supportRequestCreateSelectors.getError(state),
});

const mapDispatchToProps = (dispatch) => ({
	closeDialog: () => dispatch(unblockIPDialogActions.toggleDialog(false)),
	submitUnblockIP: ({ ip, serverUniqId, whitelist }) =>
		dispatch(unblockIPActions.fetch({ ip, uniq_id: serverUniqId, whitelist })),
	openSnackbar: (message, color) =>
		dispatch(snackbarActions.pushMessage(message, color)),
	submitUnblockIPSupportRequest: ({ description }) =>
		dispatch(
			supportRequestCreateActions.fetch({
				queue: 'Linux_Support_Simple_New',
				subject: `my.liquidweb.com unblock IP from`,
				description,
			}),
		),
});

export { UnblockIPDialog };

export default compose(
	withStyles(styles),
	connect(
		mapStateToProps,
		mapDispatchToProps,
	),
)(UnblockIPDialog);
