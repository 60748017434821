import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import FileCopy from '@material-ui/icons/FileCopy';
import LWDialog from 'components/common/LWDialog';
import LWTypography from 'components/common/LWTypography';
import LWLink from 'components/common/LWLink';
import DialogActions from '@material-ui/core/DialogActions';
import copyElementContents from 'utility/copyElementContents';
import LWButton from 'components/common/LWButton';
import moment from 'moment';

const SReferLinkText = styled(Box)`
	border: 1px solid ${(p) => p.theme.palette.common.grey2};
	border-radius: 4px;
	overflow-wrap: break-word;
`;

const ReferAFriendDialog = ({
	isOpen,
	closeDialog,
	referAFriendCode,
	wwwHostname,
	cartHostname,
	openSnackbar,
}) => {
	const referLinkTextRef = useRef(null);

	const copyToClipboard = () => {
		copyElementContents(referLinkTextRef.current);
		openSnackbar('Refer a Friend link copied to clipboard.');
	};

	return (
		<LWDialog
			title="Refer a Friend"
			onClose={closeDialog}
			maxWidth="sm"
			fullWidth
			open={isOpen}
		>
			<LWTypography>
				Do you love Liquid Web? Share us with a friend and earn a{' '}
				{moment() < moment('2021-04-01 00:00:00') ? '$100 ' : '$50 '}
				hosting credit!
			</LWTypography>
			<LWTypography>
				<LWLink
					to={`${wwwHostname}/kb/refer-a-friend-get-50-for-every-friend-you-refer-to-liquid-web/`}
				>
					Learn More
				</LWLink>
			</LWTypography>
			<SReferLinkText p={2} mt={2} mb={1}>
				<LWTypography>
					<span ref={referLinkTextRef}>
						{`${cartHostname}/refer-a-friend/${referAFriendCode}`}
					</span>
				</LWTypography>
			</SReferLinkText>
			{document.queryCommandSupported('copy') && (
				<DialogActions>
					<LWButton
						variant="contained"
						color="secondary"
						endIcon={<FileCopy />}
						onClick={() => copyToClipboard()}
					>
						Copy To Clipboard
					</LWButton>
				</DialogActions>
			)}
		</LWDialog>
	);
};

ReferAFriendDialog.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	closeDialog: PropTypes.func.isRequired,
	referAFriendCode: PropTypes.string,
	wwwHostname: PropTypes.string.isRequired,
	openSnackbar: PropTypes.func.isRequired,
};

ReferAFriendDialog.defaultProps = {
	referAFriendCode: null,
};

export default ReferAFriendDialog;
