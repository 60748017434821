import { combineReducers } from 'redux';

import { reducer as deleteModule } from './deleteModule';
import { reducer as lookup } from './lookupModule';
import { reducer as set } from './setModule';

export default combineReducers({
	delete: deleteModule,
	lookup,
	set,
});
