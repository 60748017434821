import { connect } from 'react-redux';
import compose from 'utility/compose';
import { reduxForm, formValueSelector, change } from 'redux-form';
import AddressForm from 'components/forms/AddressForm';

const formName = 'AccountCreateAddressForm';
const formSelector = formValueSelector(formName);

const mapStateToProps = (state) => ({
	formValues: formSelector(
		state,
		'address',
		'address2',
		'city',
		'state',
		'country',
		'postalCode',
	),
	selectedCountry: formSelector(state, 'country'),
});

const mapDispatchToProps = (dispatch) => ({
	changeFieldValue: (field, value) => dispatch(change(formName, field, value)),
});

export { formName, formSelector };
export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	),
	reduxForm({
		form: formName,
		enableReinitialize: true,
	}),
)(AddressForm);
