import 'react-table/react-table.css';
import './App.css';
import './fonts.css';

import React from 'react';
import AppConfigGate from 'utility/appConfig/AppConfigGate';
import { ConnectedRouter } from 'connected-react-router';
import {
	StylesProvider,
	ThemeProvider as MuiThemeProvider,
} from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import OpenIDProviderWrapper from 'utility/openid/OpenIDProviderWrapper';
import MaintenanceModeGate from 'containers/structural/MaintenanceModeGate';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import RouteHandler from 'components/routers/RouteHandler';

import { history, store } from './store';
import theme from './theme';

const App = () => (
	<Provider store={store}>
		<MuiPickersUtilsProvider utils={MomentUtils}>
			<MuiThemeProvider theme={theme}>
				<StylesProvider injectFirst>
					<ThemeProvider theme={theme}>
						<AppConfigGate>
							<ConnectedRouter history={history}>
								<OpenIDProviderWrapper>
									<MaintenanceModeGate>
										<RouteHandler />
									</MaintenanceModeGate>
								</OpenIDProviderWrapper>
							</ConnectedRouter>
						</AppConfigGate>
					</ThemeProvider>
				</StylesProvider>
			</MuiThemeProvider>
		</MuiPickersUtilsProvider>
	</Provider>
);

export default App;
