/**
 * The OpenID specification says that when an ID token is issued from
 * the Authorization Endpoint with an access_token, the ID token is
 * REQUIRED to include an at_hash claim.
 *
 * https://openid.net/specs/openid-connect-core-1_0.html#HybridIDToken
 *
 * Liquid Web's identity provider (Kero) departs from the spec by
 * omitting this value. This module is a temporary shim to allow the
 * oidc-client library to accept a non-compliant response.
 *
 * Kero should implement at_hash as soon as possible, and then this
 * shim should be removed.
 */

/* eslint-disable no-underscore-dangle, prefer-const, vars-on-top, no-var, prefer-template, radix, camelcase */
import { ResponseValidator as OriginalResponseValidator } from 'oidc-client/src/ResponseValidator';
import { Log } from 'oidc-client';

export default class CustomResponseValidator extends OriginalResponseValidator {
	// Almost verbatim copy of the super class's method of the same
	// name, except that we're allowing id_token to omit the at_hash
	// claim.
	_validateAccessToken(response) {
		if (!response.profile) {
			Log.error(
				'ResponseValidator._validateAccessToken: No profile loaded from id_token',
			);
			return Promise.reject(new Error('No profile loaded from id_token'));
		}

		if (!response.profile.at_hash) {
			Log.error(
				'ResponseValidator._validateAccessToken: No at_hash in id_token',
			);

			// The OpenID spec calls for us to bail out here, but we're
			// overriding in order to accommodate a departure from the
			// spec in Kero.
		}

		if (!response.id_token) {
			Log.error('ResponseValidator._validateAccessToken: No id_token');
			return Promise.reject(new Error('No id_token'));
		}

		let jwt = this._joseUtil.parseJwt(response.id_token);
		if (!jwt || !jwt.header) {
			Log.error(
				'ResponseValidator._validateAccessToken: Failed to parse id_token',
				jwt,
			);
			return Promise.reject(new Error('Failed to parse id_token'));
		}

		var hashAlg = jwt.header.alg;
		if (!hashAlg || hashAlg.length !== 5) {
			Log.error(
				'ResponseValidator._validateAccessToken: Unsupported alg:',
				hashAlg,
			);
			return Promise.reject(new Error('Unsupported alg: ' + hashAlg));
		}

		var hashBits = hashAlg.substr(2, 3);
		if (!hashBits) {
			Log.error(
				'ResponseValidator._validateAccessToken: Unsupported alg:',
				hashAlg,
				hashBits,
			);
			return Promise.reject(new Error('Unsupported alg: ' + hashAlg));
		}

		hashBits = parseInt(hashBits);
		if (hashBits !== 256 && hashBits !== 384 && hashBits !== 512) {
			Log.error(
				'ResponseValidator._validateAccessToken: Unsupported alg:',
				hashAlg,
				hashBits,
			);
			return Promise.reject(new Error('Unsupported alg: ' + hashAlg));
		}

		let sha = 'sha' + hashBits;
		var hash = this._joseUtil.hashString(response.access_token, sha);
		if (!hash) {
			Log.error(
				'ResponseValidator._validateAccessToken: access_token hash failed:',
				sha,
			);
			return Promise.reject(new Error('Failed to validate at_hash'));
		}

		var left = hash.substr(0, hash.length / 2);
		var left_b64u = this._joseUtil.hexToBase64Url(left);
		if (left_b64u !== response.profile.at_hash) {
			Log.error(
				'ResponseValidator._validateAccessToken: Failed to validate at_hash',
				left_b64u,
				response.profile.at_hash,
			);

			// The OpenID spec calls for us to bail out here, but we're
			// overriding in order to accommodate a departure from the
			// spec in Kero.
		}

		Log.debug('ResponseValidator._validateAccessToken: success');

		return Promise.resolve(response);
	}
}
