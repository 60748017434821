import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const { actions, reducer, sagas, selectors } = createAPIModule({
	getStateSlice: (state) => state.asset.remove,
	actionType: 'ASSET_REMOVE',
	method: 'POST',
	url: '/asset/remove.json',
});

export { actions, reducer, sagas, selectors };
