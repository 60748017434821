import Immutable from 'immutable';
import flatten from 'arr-flatten';
import {
	all,
	call,
	cancel,
	delay,
	fork,
	put,
	select,
	take,
	takeEvery,
	takeLatest,
} from 'redux-saga/effects';
import { createSelector } from 'reselect';
import { getFullPathName } from 'modules/routeSelectors';
import storyOrTest from 'utility/tools/storyOrTest';
import snackbarSaga from 'modules/snackbar/sagas';
import get from 'lodash/get';
import axiosClientSaga from './saga/axiosClientSaga';

// we only need mockAPISaga for tests and stories. We don't want test data in production builds.
// To use this for development purposes, simply comment the logic around the require.
// eslint-disable-next-line func-names
let mockAPISaga = function*() {
	const noMockMessage = 'mock api calls not allowed in this environment.';
	// eslint-disable-next-line no-console
	yield console.error(noMockMessage);
	return { data: null, error: noMockMessage };
};
if (storyOrTest()) {
	// eslint-disable-next-line global-require
	mockAPISaga = require('utility/redux/saga/mockApiSaga').default;
}

const PAGE_TOTAL_KEY = 'page_total';
const PAGE_NUM_KEY = 'page_num';
const ITEMS_KEY = 'items';
const ITEM_TOTAL_KEY = 'item_total';
const ITEM_COUNT_KEY = 'item_count';
const PAGE_SIZE_KEY = 'page_size';
const POLL_SECONDS = 30;

const mockedModuleKeys = ['mocked'];

const createAPIActions = (actionType, prefix) => ({
	actionType,
	fetchRawType: `${prefix}/${actionType}_FETCH_RAW`,
	fetchRaw: (payload, moduleKey, isBackgroundPolling) => ({
		type: `${prefix}/${actionType}_FETCH_RAW`,
		moduleKey,
		payload,
		isBackgroundPolling,
	}),
	fetchType: `${prefix}/${actionType}_FETCH`,
	fetch: (payload, moduleKey) => ({
		type: `${prefix}/${actionType}_FETCH`,
		moduleKey,
		payload,
	}),
	fetchAndPollType: `${prefix}/${actionType}_FETCH_AND_POLL`,
	fetchAndPoll: (payload, moduleKey, { init } = {}) => ({
		type: `${prefix}/${actionType}_FETCH_AND_POLL`,
		moduleKey,
		payload,
		init,
	}),
	initType: `${prefix}/${actionType}_INIT`,
	init: (payload, moduleKey) => ({
		type: `${prefix}/${actionType}_INIT`,
		moduleKey,
		payload,
	}),
	cancelPollingType: `${prefix}/${actionType}_CANCEL_POLLING`,
	cancelPolling: (payload, moduleKey) => ({
		type: `${prefix}/${actionType}_CANCEL_POLLING`,
		moduleKey,
		payload,
	}),
	overrideType: `${prefix}/${actionType}_OVERRIDE`,
	override: (payload, moduleKey) => ({
		type: `${prefix}/${actionType}_OVERRIDE`,
		moduleKey,
		payload,
	}),
	setType: `${prefix}/${actionType}_SET`,
	set: (payload, moduleKey) => ({
		type: `${prefix}/${actionType}_SET`,
		moduleKey,
		payload,
	}),
	errorType: `${prefix}/${actionType}_ERROR`,
	error: (payload, moduleKey) => ({
		type: `${prefix}/${actionType}_ERROR`,
		moduleKey,
		payload,
	}),
	clearType: `${prefix}/${actionType}_CLEAR`,
	clear: (moduleKey) => ({
		type: `${prefix}/${actionType}_CLEAR`,
		moduleKey,
	}),
	fetchGuestAuthType: `${prefix}/${actionType}_FETCH_GUEST_AUTH`,
	fetchGuestAuth: (payload, moduleKey) => ({
		type: `${prefix}/${actionType}_FETCH_GUEST_AUTH`,
		moduleKey,
		payload,
	}),
});

const getPath = (moduleKey, fieldKey) => {
	let setPath = [fieldKey];
	setPath = fieldKey ? flatten(setPath) : [];
	if (moduleKey) setPath.unshift(moduleKey);
	return setPath;
};

const createAPIReducer = (actions) => {
	const initialState = Immutable.fromJS({
		data: null,
		pending: 0,
		error: null,
		isBackgroundPolling: false,
	});

	const checkAndInitiateModuleKey = (moduleKey, state) => {
		if (state.has(moduleKey)) return state;

		return state.set(moduleKey, initialState);
	};

	return (state = initialState, action) => {
		const getPathFromKey = (key) => getPath(action.moduleKey, key);
		let initializedState = state;
		switch (action.type) {
			case actions.overrideType:
				return initializedState.setIn(
					getPathFromKey('data'),
					Immutable.fromJS(action.payload),
				);
			case actions.fetchRawType:
				if (action.moduleKey)
					initializedState = checkAndInitiateModuleKey(action.moduleKey, state);
				return initializedState
					.updateIn(getPathFromKey('pending'), (pending) => pending + 1)
					.setIn(
						getPathFromKey('isBackgroundPolling'),
						action.isBackgroundPolling,
					)
					.setIn(getPathFromKey('error'), null);
			case actions.setType:
				if (action.moduleKey)
					initializedState = checkAndInitiateModuleKey(action.moduleKey, state);
				return initializedState
					.setIn(getPathFromKey('data'), Immutable.fromJS(action.payload))
					.updateIn(getPathFromKey('pending'), (pending) =>
						Math.max(pending - 1, 0),
					)
					.setIn(getPathFromKey('error'), null);
			case actions.errorType:
				if (action.moduleKey)
					initializedState = checkAndInitiateModuleKey(action.moduleKey, state);
				return initializedState
					.setIn(getPathFromKey('error'), Immutable.fromJS(action.payload))
					.updateIn(getPathFromKey('pending'), (pending) =>
						Math.max(pending - 1, 0),
					)
					.setIn(getPathFromKey('data'), null);
			case actions.clearType:
				if (action.moduleKey)
					initializedState = checkAndInitiateModuleKey(action.moduleKey, state);
				return initializedState
					.setIn(getPathFromKey('data'), null)
					.setIn(getPathFromKey('pending'), 0)
					.setIn(getPathFromKey('error'), null);
			default:
				return initializedState;
		}
	};
};

const createAPISaga = ({
	actions,
	url,
	client,
	method,
	extraHeaders,
	getStateSlice,
}) => {
	// TODO: figure out how to get to the module keys' selectors directly.
	function* localHasData(moduleKey) {
		return !!(moduleKey
			? (yield select(getStateSlice))?.toJS()?.[moduleKey]?.data
			: (yield select(getStateSlice))?.toJS()?.data);
	}

	function* performFetchRaw(action) {
		const { payload = {} } = action;

		const apiClient = mockedModuleKeys.includes(action.moduleKey)
			? mockAPISaga
			: client;

		const { data, error } = yield call(apiClient, {
			method,
			url,
			data: payload.data,
			headers: {
				...extraHeaders,
				...payload.headers,
			},
			auth: payload.auth,
		});
		if (error) {
			const { response, message } = error;
			if (response) yield put(actions.error(response, action.moduleKey));
			else if (message) yield put(actions.error(message, action.moduleKey));
			else yield put(actions.error(error, action.moduleKey));
		} else if (data?.errors) {
			yield put(actions.error(data.errors, action.moduleKey));
		} else {
			yield put(actions.set(data, action.moduleKey));
		}
	}

	function* performFetch(action) {
		let { payload = {} } = action;
		payload = {
			data: {
				params: {
					...payload,
				},
			},
		};
		yield put(actions.fetchRaw(payload, action.moduleKey));
	}

	// This will only execute if it lacks a successful response.
	function* performInit(action) {
		if (!(yield call(localHasData, action.moduleKey)))
			yield call(performFetch, action);
	}

	function* fetchAndPollBackgroundPolling({
		payload,
		action,
		initialPathName,
	}) {
		yield delay(POLL_SECONDS * 1000);
		try {
			// continue until navigating away from page
			while (initialPathName === (yield select(getFullPathName))) {
				yield put(actions.fetchRaw(payload, action.moduleKey, true));
				yield delay(POLL_SECONDS * 1000);
			}
		} catch {
			yield call(snackbarSaga, {
				error: true,
				errorMessage: 'The data on this page may no longer be up to date.',
			});
		}
	}

	function* performFetchAndPoll(action) {
		const { moduleKey, init, payload } = action;
		const initialPathName = yield select(getFullPathName);
		const rawPayload = {
			data: {
				params: {
					...payload,
				},
			},
		};
		const hideLoading = init && (yield call(localHasData, moduleKey));
		// Inital load is done on it's own as isBackgroundPolling is false
		// on the first call (this allows isLoading to be true)
		yield put(actions.fetchRaw(rawPayload, moduleKey, hideLoading));

		const backgroundPollingTask = yield fork(fetchAndPollBackgroundPolling, {
			payload: rawPayload,
			action,
			initialPathName,
		});

		yield take(actions.cancelPollingType);
		yield cancel(backgroundPollingTask);
	}

	function* performFetchGuestAuth(action) {
		let { payload = {} } = action;
		const guestAuthCreds = yield select((state) =>
			state.appConfig.getIn(['data', 'guestAuth']),
		);

		payload = {
			auth: guestAuthCreds.toJS(),
			data: {
				params: {
					...payload,
				},
			},
		};
		yield put(actions.fetchRaw(payload, action.moduleKey));
	}

	function* watchFetch() {
		yield takeEvery(actions.fetchType, performFetch);
	}

	function* watchPerformInit() {
		yield takeLatest(actions.initType, performInit);
	}

	function* watchFetchAndPoll() {
		yield takeLatest(actions.fetchAndPollType, performFetchAndPoll);
	}

	function* watchFetchGuestAuth() {
		yield takeEvery(actions.fetchGuestAuthType, performFetchGuestAuth);
	}

	function* watchFetchRaw() {
		yield takeEvery(actions.fetchRawType, performFetchRaw);
	}

	return function* combinedSaga() {
		return yield all([
			call(watchFetch),
			call(watchFetchAndPoll),
			call(watchFetchRaw),
			call(watchFetchGuestAuth),
			call(watchPerformInit), // We only want to call this once per session per module.
		]);
	};
};

const createSelectors = (
	getStateSlice,
	isPaginated, // TODO: make this accept an object instead of args to make code maintainable.
	moduleKey,
	customSelectors = {},
) => {
	const getPathFromField = (field) => getPath(moduleKey, field);

	const curriedCreateSelector = (selector) =>
		createSelector(
			getStateSlice,
			selector,
		);

	const getNativeState = createSelector(
		getStateSlice,
		(slice) => {
			if (slice) {
				if (moduleKey) {
					return get(Immutable.fromJS(slice).toJS(), getPathFromField());
				}
				return Immutable.fromJS(slice).toJS();
			}
			return slice;
		},
	);

	const isLoading = createSelector(
		getNativeState,
		(slice) => !!slice && !!slice.pending && !slice.isBackgroundPolling,
	);

	const getError = curriedCreateSelector((slice) =>
		slice.getIn(getPathFromField('error')),
	);

	const getErrorString = createSelector(
		getError,
		(error) =>
			(() => {
				if (typeof error === 'object') {
					const data = error?.toJS()?.data || {};
					return data.public_message || data.details || data.full_message;
				}
				return error;
			})()?.toString() || '',
	);

	const hasError = curriedCreateSelector(
		(slice) => !!slice.getIn(getPathFromField('error')),
	);
	const getData = curriedCreateSelector((slice) =>
		slice.getIn(getPathFromField('data')),
	);
	const getItems = curriedCreateSelector((slice) =>
		slice.getIn(getPathFromField(['data', ITEMS_KEY])),
	);
	const getNativeItems = curriedCreateSelector(
		(slice) => slice.getIn(getPathFromField(['data', ITEMS_KEY]))?.toJS() || [],
	);
	const hasData = createSelector(
		getNativeState,
		(slice) => !!get(slice, getPathFromField('data')),
	);
	const getNativeData = createSelector(
		getData,
		(data) => (data ? data.toJS() : null),
	);
	const getState = createSelector(
		getNativeData,
		getError,
		isLoading,
		(native, error, loading) => ({
			data: native,
			error,
			isLoading: loading,
		}),
	);

	// pagination
	// number of items on this page
	const getItemCount = curriedCreateSelector((slice) =>
		parseInt(slice.getIn(getPathFromField(['data', ITEM_COUNT_KEY])), 10),
	);
	// total number of items overall
	const getTotalItems = curriedCreateSelector((slice) =>
		parseInt(slice.getIn(getPathFromField(['data', ITEM_TOTAL_KEY])), 10),
	);
	// current page number
	const getPageNum = curriedCreateSelector((slice) =>
		parseInt(slice.getIn(getPathFromField(['data', PAGE_NUM_KEY])), 10),
	);
	// number of items per page
	const getPageSize = curriedCreateSelector((slice) =>
		parseInt(slice.getIn(getPathFromField(['data', PAGE_SIZE_KEY])), 10),
	);
	// total number of pages
	const getPageTotal = curriedCreateSelector((slice) =>
		parseInt(slice.getIn(getPathFromField(['data', PAGE_TOTAL_KEY])), 10),
	);

	const modifiedCustomSelectors = {};
	Object.keys(customSelectors).forEach(
		// eslint-disable-next-line no-return-assign
		(key) =>
			// eslint-disable-next-line no-return-assign
			(modifiedCustomSelectors[key] = createSelector(
				getNativeData,
				customSelectors[key],
			)),
	);

	return {
		getStateSlice,
		isLoading,
		getError,
		getErrorString,
		hasError,
		getData,
		getItems,
		getNativeItems,
		hasData,
		getNativeData,
		getNativeState,
		getState,
		getItemCount,
		getTotalItems,
		getPageNum,
		getPageSize,
		getPageTotal,
		...modifiedCustomSelectors,
	};
};

const createAPIModule = ({
	actionType,
	url,
	getStateSlice,
	client = axiosClientSaga,
	method = 'POST',
	extraHeaders = {},
	isPaginated = false,
	customSelectors = {},
	prefix = 'api',
	mocked = storyOrTest(),
}) => {
	const actions = createAPIActions(actionType, prefix);
	return {
		actions,
		reducer: createAPIReducer(actions),
		sagas: createAPISaga({
			actions,
			url,
			client: mocked ? mockAPISaga : client,
			method,
			extraHeaders,
			isPaginated,
			getStateSlice,
		}),
		selectors: createSelectors(
			getStateSlice,
			isPaginated,
			null,
			customSelectors,
		),
	};
};

export {
	createAPIActions,
	createAPIReducer,
	createAPISaga,
	createAPIModule,
	createSelectors,
};
