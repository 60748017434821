import React from 'react';

// redux
import { useDispatch } from 'react-redux';
import basketActions from 'modules/basket/actions';
import dialogActions from 'modules/dialogs/actions';

// custom components
import LWTypography from 'components/common/LWTypography';
import ConfirmCancel from 'components/common/Dialogs/ConfirmCancel';

const CreateNewCartModalContents = () => {
	const dispatch = useDispatch();
	const resetBasket = () => dispatch(basketActions.reset());
	const closeDialog = () => dispatch(dialogActions.close());

	const handleReset = () => {
		resetBasket();
		closeDialog();
	};

	return (
		<>
			<LWTypography paragraph>
				Creating a new cart will <b>abandon the current cart</b> in view.
			</LWTypography>
			<LWTypography paragraph>
				Are you sure you wish to create a new cart?
			</LWTypography>
			<ConfirmCancel
				confirmText="Continue"
				cancel={closeDialog}
				confirm={handleReset}
			/>
		</>
	);
};

export default CreateNewCartModalContents;
