const data = {
	item_count: 2,
	item_total: 2,
	items: [
		{
			category: 'SubaccntStatus',
			description: 'Backup Restore Failed',
			enddate: '2021-02-11 16:56:02',
			id: 14429183,
			last_alert: 'Backup Restore Failed',
			modifieddate: '2021-02-11 16:55:59',
			resolved: 0,
			severity: 'Error',
			startdate: '2021-02-11 16:55:59',
			system: 'SubaccntStatusAlert',
			system_identifier: null,
			uniq_id: '9A0AFQ',
		},
		{
			category: 'SubaccntStatus',
			description: 'Building',
			enddate: '2020-06-30 09:40:55',
			id: 13888654,
			last_alert: 'Building',
			modifieddate: '2020-06-30 09:37:35',
			resolved: 0,
			severity: 'Notification',
			startdate: '2020-06-30 09:37:35',
			system: 'SubaccntStatusAlert',
			system_identifier: null,
			uniq_id: '78554A',
		},
	],
	page_num: 1,
	page_size: 25,
	page_total: 1,
};

export default data;
