import axios from 'axios';
import { select } from 'redux-saga/effects';

export default function* axiosCreate() {
	// avoid use of selectors to prevent import loop
	const wwwHost = yield select((state) =>
		state.appConfig.getIn(['data', 'hosts', 'www']),
	);
	const baseURL = `https://${wwwHost}/lw-api/`;
	return axios.create({
		baseURL,
		timeout: 60000,
		withCredentials: true,
	});
}
