import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';
import { Map as ImmutableMap } from 'immutable';

const getStateSlice = (state) =>
	state.billing.creditcard.profile.oneTimeDetails;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'BILLING_CREDITCARD_PROFILE_ONETIMEDETAILS',
	url: '/billing/creditcard/profile/oneTimeDetails.json',
});

const selectors = {
	paymentMethod: createSelector(
		getStateSlice,
		(slice) => slice.getIn(['data', 'payment_method'], ImmutableMap()),
	),

	cc_token: createSelector(
		getStateSlice,
		(slice) => slice.getIn(['data', 'cc_token']),
	),

	issuer: createSelector(
		getStateSlice,
		(slice) => slice.getIn(['data', 'cc_issuer']),
	),
	ccExp: createSelector(
		getStateSlice,
		(slice) => slice.getIn(['data', 'cc_exp']),
	),
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };
