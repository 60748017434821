import { call, put, select } from 'redux-saga/effects';
import maintenanceModeActions from 'modules/maintenanceMode/actions';
import { enabled as maintenanceModeEnabledSelector } from 'modules/maintenanceMode/selectors';
import axiosClient from './axiosClient';

const currentPathname = (state) => state.router.location.pathname;

export { currentPathname };

//
/**
 * proxy to axios call. Handles standard actions from bad status codes, and errors in general.
 * @param {object} axiosData - The object passed along to axios
 * @returns {(object)} - Returns keys request (full axios request) and data on good response,
 * returns error on bad response
 */
export default function* sendApiRequest(axiosData) {
	const axios = yield call(axiosClient);
	try {
		const authedAxiosRequest = axiosData;
		const accessToken = yield select((state) =>
			state.reduxOidc.getIn(['user', 'access_token']),
		);
		if (accessToken) {
			authedAxiosRequest.headers = {
				...authedAxiosRequest.headers,
				Authorization: `Bearer ${accessToken}`,
			};
		} else {
			const guestAuthCreds = yield select((state) =>
				state.appConfig.getIn(['data', 'guestAuth']),
			);
			authedAxiosRequest.auth = guestAuthCreds.toJS();
		}
		const response = yield call(axios, authedAxiosRequest);
		return { response, data: response.data };
	} catch (error) {
		const { response, request } = error;
		if (response) {
			// server sent back bad status code
			switch (response.status) {
				case 401:
					// let private route/login handle that.
					if (axiosData.url.startsWith('/account/auth/')) break;
					yield put({ type: 'ACCOUNT_AUTH_TOKEN_CLEAR' });
					break;
				case 500:
					if (response.data.error_class === 'LW::Exception::API::Maintenance') {
						const maintenanceModeEnabled = yield select(
							maintenanceModeEnabledSelector,
						);
						if (!maintenanceModeEnabled) yield put(maintenanceModeActions.on());
					}
					// TODO: handle other 500 errors
					break;
				default:
					// TODO: handle unknown error
					break;
			}
		} else if (request) {
			// yield put(
			//	errorActions.pushError({
			//		message: `API request failed`,
			//	}),
			// );
		} else {
			// TODO: handle developer error
		}
		return { error };
	}
}
