import { spawn } from 'redux-saga/effects';

import { sagas as list } from './listModule';

import { sagas as restore } from './restoreModule';

export default function* sagas() {
	yield spawn(restore);
	yield spawn(list);
}
