import { createSelector } from 'reselect';
import { selectors as parentListSelectors } from 'modules/api/storm/private/parent/listModule';
import { selectors as assetDetailsSelectors } from 'modules/api/asset/detailsModule';

const isLoading = createSelector(
	parentListSelectors.isLoading,
	assetDetailsSelectors.isLoading,
	(parent, asset) => parent || asset,
);

const getStateSlice = (state) => state.serverStore.resize.options;

const deployOnto = createSelector(
	getStateSlice,
	(slice) => slice?.deployOnto,
);

const selectedPrivateParent = createSelector(
	parentListSelectors.getNativeData,
	deployOnto,
	(slice, toFind) => {
		return slice?.items?.find(({ uniq_id: uniqId }) => uniqId === toFind);
	},
);

const selectors = {
	getStateSlice,
	deployOnto,
	isLoading,
	selectedPrivateParent,
};

export { getStateSlice, deployOnto, isLoading, selectedPrivateParent };
export default selectors;
