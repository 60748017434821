import React from 'react';
import GenericError from 'components/common/GenericError';
import PropTypes from 'prop-types';
import LWDialog from 'components/common/LWDialog';
import RenewDomainForm from './forms/RenewDomainForm';
import RenewSSLForm from './forms/RenewSSLForm';

export const PRODUCT_TYPES = ['DOMAIN', 'SSL'];

const RenewDialog = ({
	renewDialogObj: { error: renewDialogError, dialogOpen, title, productType },
	toggleDialog,
	clearError,
}) => {
	const onClose = () => {
		toggleDialog(false);
		clearError();
	};

	return (
		<LWDialog
			title={title}
			onClose={onClose}
			maxWidth="sm"
			fullWidth
			open={dialogOpen}
		>
			<GenericError shown={!!renewDialogError} />
			{productType === 'DOMAIN' && <RenewDomainForm onClose={onClose} />}
			{productType === 'SSL' && <RenewSSLForm onClose={onClose} />}
		</LWDialog>
	);
};

RenewDialog.propTypes = {
	toggleDialog: PropTypes.func,
	renewDialogObj: PropTypes.shape({
		renewDialogError: PropTypes.string,
		dialogOpen: PropTypes.bool,
		title: PropTypes.any,
		productType: PropTypes.oneOf(PRODUCT_TYPES),
	}),
	clearError: PropTypes.func.isRequired,
};

export default RenewDialog;
