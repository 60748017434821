const listData = {
	item_count: 4,
	item_total: 4,
	items: [
		{
			action: 'Reboot',
			code: '2fb4bb56-c22b-4116-9c94-5d08153b1677',
			created_date: '2021-04-07 16:39:31.225498-04',
			executed_date: null,
			requester: 'customer',
			scheduled_date: '2021-04-19 17:36:59-04',
			status: 'pending',
			uniq_id: 'ABC123',
		},
		{
			action: 'Resize',
			code: '2fb4bb56-c22b-4116-9c94-5d08153b1678',
			created_date: '2021-04-07 16:40:31.225498-04',
			executed_date: null,
			requester: 'angrycustomer',
			scheduled_date: '2021-04-20 18:45:59-04',
			status: 'pending',
			uniq_id: 'ABC124',
		},
		{
			action: 'Reboot',
			code: '2fb4bb56-c22b-4116-9c94-5d08153b1679',
			created_date: '2021-04-07 16:41:31.225498-04',
			executed_date: null,
			requester: 'tom',
			scheduled_date: '2021-04-19 12:36:59-04',
			status: 'pending',
			uniq_id: 'ABC125',
		},
		{
			action: 'Resize',
			code: '2fb4bb56-c22b-4116-9c94-5d08153b16710',
			created_date: '2021-04-07 16:42:31.225498-04',
			executed_date: null,
			requester: 'sabrina',
			scheduled_date: '2021-04-19 20:36:59-04',
			status: 'pending',
			uniq_id: 'ABC126',
		},
	],
	page_num: 1,
	page_size: 25,
	page_total: 1,
};

export default listData;
