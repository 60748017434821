import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';
import { formatZone } from 'utility/format';

const getStateSlice = (state) => state.network.zone.list;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'NETWORK_ZONE_LIST',
	method: 'POST',
	url: '/network/zone/list.json',
});

const items = createSelector(
	defaultSelectors.getNativeData,
	(slice) => slice?.items || [],
);

const selectOptions = createSelector(
	items,
	(slice) =>
		slice.map((elem) => ({
			value: elem?.id,
			label: formatZone(elem),
		})),
);

const defaultZone = createSelector(
	items,
	(slice) => slice.find((elem) => elem?.is_default === 1),
);

const selectors = {
	items,
	selected: createSelector(
		items,
		(slice) => slice.find((zone) => zone?.is_default),
	),
	selectOptions,
	defaultZone,
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };
