const variantConfigs = {
	paymentMethod: {
		gridItemSettings: { xs: 12, sm: 6 },
		gridContainerSettings: { spacing: 4 },
	},
	accountCreate: {
		gridItemSettings: { xs: 12, sm: 6 },
		gridContainerSettings: { spacing: 4 },
	},
	default: {
		gridItemSettings: { xs: 12 },
		gridContainerSettings: { spacing: 2 },
	},
};

export default variantConfigs;
