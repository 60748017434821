import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import GenericError from 'components/common/GenericError';
import { withStyles } from '@material-ui/core/styles';
import errorActions from 'modules/error/errorActions';
import errorSelectors from 'modules/error/errorSelectors';

const styles = ({ palette }) => ({
	root: {},
	error: {
		backgroundColor: palette.common.red,
	},
	success: {
		backgroundColor: palette.common.blue3,
	},
	warning: {
		backgroundColor: palette.common.yellow1,
	},
});

const Errors = ({ classes, errors, removeError }) => {
	/*  eslint-disable react/no-array-index-key */
	return errors.map((error, key) => (
		<GenericError
			onClose={() => removeError({ index: key })}
			className={classes[error.color]}
			shown
			verb={error.verb}
			key={key}
		>
			{error.message}
		</GenericError>
	));
	/*  eslint-enable react/no-array-index-key */
};

Errors.defaultProps = {
	message: null,
};

Errors.propTypes = {
	classes: PropTypes.object.isRequired,
	closeError: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	errors: errorSelectors.errors(state),
	currentError: errorSelectors.currentError(state),
});

const mapDispatchToProps = (dispatch) => ({
	closeError: () => {
		dispatch(errorActions.shiftMessage());
	},
	removeError: ({ index }) => {
		dispatch(errorActions.removeError({ index }));
	},
});

export { Errors };

export default compose(
	withStyles(styles),
	connect(
		mapStateToProps,
		mapDispatchToProps,
	),
)(Errors);
