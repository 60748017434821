import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';

const getStateSlice = (state) => state.billing.invoice.details;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'BILLING_INVOICE_DETAILS',
	url: '/billing/invoice/details.json',
});

const isRevised = createSelector(
	defaultSelectors.getData,
	(data) => {
		if (!data) return null;

		const revision = data.get('revision');

		return revision === null || revision > 0;
	},
);

const selectors = {
	...defaultSelectors,
	isRevised,
};

export { actions, reducer, sagas, selectors };
