import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Zoom from '@material-ui/core/Zoom';
import { withRouter } from 'react-router-dom';
import LWTypography from 'components/common/LWTypography';
import {
	actions as currentActions,
	selectors as currentSelectors,
} from 'modules/api/notifications/currentModule';
import {
	actions as resolveActions,
	selectors as resolveSelectors,
} from 'modules/api/notifications/resolveModule';
import readActions from 'modules/notifications/actions';
import readSelectors from 'modules/notifications/selectors';
import { isLoggedInAndNotBasketAdmin as isLoggedInAndNotBasketAdminSelector } from 'modules/auth/oidcSelectors';

import NavButton from 'components/structural/topbar/NavButton';
import Notifications from '@material-ui/icons/Notifications';
import NotificationLine from 'containers/structural/notification/NotificationLine';
import { CRITICAL_SEVERITY } from 'utility/constants/notificationConstants';

const styles = (theme) => ({
	root: {
		textDecoration: 'none',
		display: 'flex',
	},
	hasNotifications: {
		color: theme.palette.secondary.main,
	},
	list: {},
	listWrapper: {
		backgroundColor: theme.palette.common.grey5,
	},
	listOuterWrapper: {
		backgroundColor: theme.palette.background.paper,
		paddingTop: '6em',
	},
	noNotificationsText: {
		color: theme.palette.secondary.contrastText,
		textAlign: 'center',
		verticalAlign: 'middle',
		padding: '2em',
	},
	dismissAllButton: {
		marginBottom: 0,
		margin: '1em',
		color: theme.palette.secondary.main,
		'&:hover': {
			backgroundColor: theme.palette.common.grey6,
		},
	},
	showMoreButton: {
		marginTop: '.2em',
		margin: '2em',
		color: theme.palette.secondary.main,
		'&:hover': {
			backgroundColor: theme.palette.common.grey6,
		},
	},
	dismissAllWrapper: {
		display: 'flex',
		justifyContent: 'space-between',
	},
});

const MAX_NOTIFICATIONS = 5;

class NotificationButton extends React.Component {
	state = {
		drawerOpen: false,
		dismissedNotifications: [],
		showMore: false,
	};

	componentDidMount = () => {
		const { fetchNotifications, isLoggedInAndNotBasketAdmin } = this.props;
		if (isLoggedInAndNotBasketAdmin) fetchNotifications();
	};

	componentDidUpdate = (prevProps) => {
		const {
			currentNotifications: prevNotifications,
			resolveNotificationResult: prevResolveResult,
			location: prevLocation,
		} = prevProps;
		const {
			currentNotifications,
			resolveNotificationResult: nextResolveResult,
			fetchNotifications,
			readNotifications,
			location,
			isLoggedInAndNotBasketAdmin,
		} = this.props;
		const { drawerOpen } = this.state;

		// we dont want to rely on dismissed state forever
		// so once the dismiss has been resolved, re-fetch notifications
		if (
			prevResolveResult === null &&
			nextResolveResult !== null &&
			!isLoggedInAndNotBasketAdmin
		)
			fetchNotifications();

		// Drawer should open automatically if we get a non-read critical notification
		if (
			!drawerOpen &&
			prevNotifications.isEmpty() &&
			!currentNotifications.isEmpty() &&
			currentNotifications.some(
				(x) => !readNotifications || !readNotifications.includes(x.get('id')),
			) &&
			currentNotifications.some((x) => x.get('severity') === CRITICAL_SEVERITY)
		)
			this.toggleDrawer();

		// Drawer should close automatically when navigating.
		if (drawerOpen && prevLocation.pathname !== location.pathname)
			this.toggleDrawer();
	};

	handleDismissNotification = (id) => {
		const { dismissedNotifications } = this.state;
		const fullDismissedNotifications = [id, ...dismissedNotifications];
		this.setState({ dismissedNotifications: fullDismissedNotifications });
		if (
			this.getNotificationsForDisplay().filter(
				(x) => !fullDismissedNotifications.includes(x.get('id')),
			).size === 0
		)
			this.toggleDrawer();
	};

	finalizeNotificationRemoval = (id) => {
		const { dismissNotification } = this.props;
		dismissNotification(id);
	};

	handleDismissAllNotifications = () => {
		const { dismissedNotifications } = this.state;
		const { dismissNotification } = this.props;
		const toBeDismissed = this.getNotificationsForDisplay()
			.map((x) => x.get('id'))
			.toJS();
		this.setState({
			dismissedNotifications: [...toBeDismissed, ...dismissedNotifications],
		});
		toBeDismissed.forEach((id) => dismissNotification(id));
		this.toggleDrawer();
	};

	handleShowMore = () => {
		this.setState({ showMore: true });
	};

	toggleDrawer = () => {
		const { drawerOpen } = this.state;
		const { setReadNotifications, currentNotifications } = this.props;

		// when user opens drawer, set all current notifications to read.
		if (!drawerOpen && currentNotifications)
			setReadNotifications(currentNotifications.map((x) => x.get('id')).toJS());
		this.setState({ drawerOpen: !drawerOpen });
	};

	menuKeyDown = (event) => {
		if (event.keyCode === 32) {
			this.toggleDrawer();
		}
	};

	getNotificationsForDisplay = (removeDismissed = false) => {
		const { showMore, dismissedNotifications } = this.state;
		let { currentNotifications } = this.props;
		if (!currentNotifications) return null;
		if (removeDismissed)
			currentNotifications = currentNotifications.filter(
				(x) => !dismissedNotifications.includes(x.get('id')),
			);
		if (!showMore)
			currentNotifications = currentNotifications.take(MAX_NOTIFICATIONS);
		return currentNotifications;
	};

	render() {
		const { classes, currentNotifications, readNotifications } = this.props;
		const { drawerOpen, dismissedNotifications, showMore } = this.state;
		const notificationsNotDismissed = this.getNotificationsForDisplay(true);
		const hasUnreadNotifications =
			currentNotifications &&
			currentNotifications.some(
				(x) => !readNotifications || !readNotifications.includes(x.get('id')),
			);
		return (
			<div className={classes.root}>
				<NavButton
					icon={(buttonProps) => <Notifications {...buttonProps} />}
					isLink={false}
					name="Alerts"
					onClick={this.toggleDrawer}
					badge={hasUnreadNotifications && { isDot: true }}
					classes={{
						iconWrapper:
							notificationsNotDismissed &&
							!notificationsNotDismissed.isEmpty() &&
							classes.hasNotifications,
					}}
				/>
				<Drawer
					className={classes.drawer}
					anchor="top"
					open={drawerOpen}
					onClose={() => this.toggleDrawer()}
				>
					<div className={classes.listOuterWrapper}>
						<div className={classes.listWrapper}>
							<div className={classes.dismissAllWrapper}>
								{(!notificationsNotDismissed ||
									notificationsNotDismissed.isEmpty()) && (
									<>
										<div />
										<LWTypography className={classes.noNotificationsText}>
											{'No unread notifications'}
										</LWTypography>
									</>
								)}
								<Button
									onClick={this.handleDismissAllNotifications}
									className={classes.dismissAllButton}
									variant="text"
									disableRipple
								>
									{'Dismiss All'}
								</Button>
							</div>
							{notificationsNotDismissed &&
								!notificationsNotDismissed.isEmpty() && (
									<div>
										<List className={classes.list}>
											{/* generate nav link home first since its out of order */}
											{currentNotifications &&
												this.getNotificationsForDisplay().map(
													(notification) => (
														<Zoom
															key={notification.get('id')}
															in={
																!dismissedNotifications.includes(
																	notification.get('id'),
																)
															}
															enter={false}
															onExited={() =>
																this.finalizeNotificationRemoval(
																	notification.get('id'),
																)
															}
															unmountOnExit
														>
															<NotificationLine
																notification={notification}
																handleDismissNotification={
																	this.handleDismissNotification
																}
															/>
														</Zoom>
													),
												)}
										</List>
									</div>
								)}
							{!showMore &&
								currentNotifications &&
								currentNotifications.size > MAX_NOTIFICATIONS && (
									<div className={classes.dissmissAllWrapper}>
										<Button
											onClick={this.handleShowMore}
											className={classes.showMoreButton}
											variant="text"
											disableRipple
										>
											{'Show More'}
										</Button>
									</div>
								)}
						</div>
					</div>
				</Drawer>
			</div>
		);
	}
}

NotificationButton.propTypes = {
	classes: PropTypes.object.isRequired,
	currentNotifications: ImmutablePropTypes.list,
	readNotifications: PropTypes.array,
	resolveNotificationResult: ImmutablePropTypes.map,

	fetchNotifications: PropTypes.func.isRequired,
	setReadNotifications: PropTypes.func.isRequired,
	dismissNotification: PropTypes.func.isRequired,
	location: PropTypes.shape({
		pathname: PropTypes.string,
	}),
};

NotificationButton.defaultProps = {
	currentNotifications: null,
	resolveNotificationResult: null,
	readNotifications: null,
	location: {
		pathname: '',
	},
};

const mapStateToProps = (state) => ({
	currentNotifications: currentSelectors.filteredAndSorted(state),
	readNotifications: readSelectors.getData(state),
	resolveNotificationResult: resolveSelectors.getData(state),
	isLoggedInAndNotBasketAdmin: isLoggedInAndNotBasketAdminSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
	fetchNotifications: () => dispatch(currentActions.fetch()),
	setReadNotifications: (ids) =>
		dispatch(
			// read will be a future api, so for now we just set the ids directly
			readActions.set(ids),
		),
	dismissNotification: (id) => dispatch(resolveActions.fetch({ id })),
});

export { NotificationButton };
export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	),
	withStyles(styles),
	withRouter,
)(NotificationButton);
