import { spawn } from 'redux-saga/effects';

import item from './item/itemSagas';
import { sagas as addCoupon } from './addCouponModule';
import { sagas as addDiscount } from './addDiscountModule';
import { sagas as acquire } from './acquireModule';
import { sagas as assertMetadata } from './assertMetadataModule';
import { sagas as claim } from './claimModule';
import { sagas as clone } from './cloneModule';
import { sagas as create } from './createModule';
import { sagas as details } from './detailsModule';
import { sagas as purchase } from './purchaseModule';
import { sagas as removeCoupon } from './removeCouponModule';
import { sagas as removeDiscount } from './removeDiscountModule';
import { sagas as setExpire } from './setExpireModule';
import { sagas as setName } from './setNameModule';
import { sagas as updateDiscount } from './updateDiscountModule';
import { sagas as generateSaved } from './generateSavedModule';

export default function* sagas() {
	yield spawn(addCoupon);
	yield spawn(addDiscount);
	yield spawn(acquire);
	yield spawn(assertMetadata);
	yield spawn(claim);
	yield spawn(clone);
	yield spawn(create);
	yield spawn(details);
	yield spawn(item);
	yield spawn(purchase);
	yield spawn(removeCoupon);
	yield spawn(removeDiscount);
	yield spawn(setExpire);
	yield spawn(setName);
	yield spawn(updateDiscount);
	yield spawn(generateSaved);
}
