import items, { uuids as itemUuids } from '../item/mockData/items';

const uuids = {
	largeBasket: '864ed1f5-771b-4e2f-9bad-f22b701a8007',
	largeBasket2: '11adb939-a982-4ae8-8776-8ef37a7fa63a',
	readyToPurchase: '22adb939-a982-4ae8-8776-8ef37a7fa63a',
	cpqBasket: 'bee5eb96-944e-4829-aa10-0e97fde2f02d',
	unnamedEmpty: 'c3945e7c-9126-47fb-8cf6-489c7ce394ff',
};

export { uuids };
export default {
	'864ed1f5-771b-4e2f-9bad-f22b701a8007': {
		account: 7599420,
		claimable: 1,
		cloneable: 1,
		created: '2020-11-19 08:45:36.681917-05',
		discount: '0',
		discountSources: [
			{
				amount: 5,
				type: 'cpq',
				discountSource: 'proportional',
				created: '2020-11-19 08:45:36.681917-05',
				metadata: {
					customerNote: 'From [salesrep@liquidweb.com]',
				},
				totals: {
					basket_discount_total: 5.0,
					item_discount_total: 10.0,
					config_discount_total: 20.0,
					discount_total: 5.0,
				},
				uuid: '1234-5678-9112-3456',
			},
			{
				amount: 5,
				type: 'coupon',
				discountSource: 'static',
				created: '2020-11-19 08:45:36.681917-05',
				metadata: {
					customerNote: 'an awesome coupon!',
					method: 'percent',
					// method percent fixed percent_mrc
				},
				totals: {
					basket_discount_total: 5.0,
					item_discount_total: 10.0,
					config_discount_total: 20.0,
					discount_total: 5.0,
				},
				uuid: '1234-5678-9112-3457',
			},
		],
		expire: null,
		items: Object.values(items),
		locked: 0,
		mergeable: 1,
		metadata: { target_items: ['53d1f1f4-7f2f-4206-9292-555000a4912d'] },
		modifiable: 1,
		name: 'largeBasket',
		owner: 'chelseadee',
		purchasable: 1,
		share_code: 'e196a829-1316-479b-987b-fab354556f25',
		status: 'empty',
		totals: {
			items_discount_total: '1',
			items_price_total: '309',
			items_subtotal: '309',
			items_tax_total: '0',
			total_basket_cost: '309',
		},
		uuid: '864ed1f5-771b-4e2f-9bad-f22b701a8007',
		valid: 1,
	},
	'11adb939-a982-4ae8-8776-8ef37a7fa63a': {
		account: 7599420,
		claimable: 1,
		cloneable: 1,
		created: '2020-11-19 08:45:36.681917-05',
		discount: '0',
		discountSources: [
			{
				amount: 5,
				type: 'cpq',
				discountSource: 'proportional',
				created: '2020-11-19 08:45:36.681917-05',
				metadata: {
					customerNote: 'From [salesrep@liquidweb.com]',
				},
				totals: {
					basket_discount_total: 5.0,
					item_discount_total: 10.0,
					config_discount_total: 20.0,
					discount_total: 5.0,
				},
				uuid: '1234-5678-9112-3456',
			},
			{
				amount: 5,
				type: 'coupon',
				discountSource: 'static',
				created: '2020-11-19 08:45:36.681917-05',
				metadata: {
					customerNote: 'an awesome coupon!',
					method: 'percent',
					// method percent fixed percent_mrc
				},
				totals: {
					basket_discount_total: 5.0,
					item_discount_total: 10.0,
					config_discount_total: 20.0,
					discount_total: 5.0,
				},
				uuid: '1234-5678-9112-3457',
			},
		],
		expire: null,
		items: Object.values(items),
		locked: 0,
		mergeable: 1,
		metadata: {},
		modifiable: 1,
		name: 'largeBasket2',
		owner: 'chelseadee',
		purchasable: 1,
		share_code: 'e196a829-1316-479b-987b-fab354556f25',
		status: 'empty',
		totals: {
			items_discount_total: '1',
			items_price_total: '309',
			items_subtotal: '309',
			items_tax_total: '0',
			total_basket_cost: '309',
		},
		uuid: '11adb939-a982-4ae8-8776-8ef37a7fa63a',
		valid: 1,
	},
	'22adb939-a982-4ae8-8776-8ef37a7fa63a': {
		account: 7599420,
		claimable: 1,
		cloneable: 1,
		created: '2020-11-19 08:45:36.681917-05',
		discount: '0',
		discountSources: [
			{
				amount: 5,
				type: 'cpq',
				discountSource: 'proportional',
				created: '2020-11-19 08:45:36.681917-05',
				metadata: {
					customerNote: 'From [salesrep@liquidweb.com]',
				},
				totals: {
					basket_discount_total: 5.0,
					item_discount_total: 10.0,
					config_discount_total: 20.0,
					discount_total: 5.0,
				},
				uuid: '1234-5678-9112-3456',
			},
			{
				amount: 5,
				type: 'coupon',
				discountSource: 'static',
				created: '2020-11-19 08:45:36.681917-05',
				metadata: {
					customerNote: 'an awesome coupon!',
					method: 'percent',
					// method percent fixed percent_mrc
				},
				totals: {
					basket_discount_total: 5.0,
					item_discount_total: 10.0,
					config_discount_total: 20.0,
					discount_total: 5.0,
				},
				uuid: '1234-5678-9112-3457',
			},
		],
		expire: null,
		items: [items[itemUuids.domainRegistraion]],
		locked: 0,
		mergeable: 1,
		metadata: {},
		modifiable: 1,
		name: 'readyToPurchase',
		owner: 'chelseadee',
		purchasable: 1,
		share_code: 'e196a829-1316-479b-987b-fab354556f25',
		status: 'empty',
		totals: {
			items_discount_total: '1',
			items_price_total: '309',
			items_subtotal: '309',
			items_tax_total: '0',
			total_basket_cost: '309',
		},
		uuid: '11adb939-a982-4ae8-8776-8ef37a7fa63a',
		valid: 1,
	},
	'bee5eb96-944e-4829-aa10-0e97fde2f02d': {
		account: 7599420,
		claimable: 1,
		cloneable: 1,
		cpqMetadata: {
			opportunity_id: 'of a lifetime',
		},
		created: '2020-11-19 08:45:36.681917-05',
		discount: '0',
		discountSources: [
			{
				amount: 5,
				type: 'cpq',
				discountSource: 'proportional',
				created: '2020-11-19 08:45:36.681917-05',
				metadata: {
					customerNote: 'From [salesrep@liquidweb.com]',
				},
				totals: {
					basket_discount_total: 5.0,
					item_discount_total: 10.0,
					config_discount_total: 20.0,
					discount_total: 5.0,
				},
				uuid: '1234-5678-9112-3456',
			},
			{
				amount: 5,
				type: 'coupon',
				discountSource: 'static',
				created: '2020-11-19 08:45:36.681917-05',
				metadata: {
					customerNote: 'an awesome coupon!',
					method: 'percent',
					// method percent fixed percent_mrc
				},
				totals: {
					basket_discount_total: 5.0,
					item_discount_total: 10.0,
					config_discount_total: 20.0,
					discount_total: 5.0,
				},
				uuid: '1234-5678-9112-3457',
			},
		],
		expire: null,
		items: [items[itemUuids.domainRegistraion]],
		locked: 0,
		mergeable: 1,
		metadata: {},
		modifiable: 1,
		name: 'cpqBasket',
		owner: 'aCpq',
		purchasable: 1,
		share_code: 'e196a829-1316-479b-987b-fab354556f25',
		status: 'empty',
		totals: {
			items_discount_total: '1',
			items_price_total: '309',
			items_subtotal: '309',
			items_tax_total: '0',
			total_basket_cost: '309',
		},
		uuid: 'bee5eb96-944e-4829-aa10-0e97fde2f02d',
		valid: 1,
	},
	'c3945e7c-9126-47fb-8cf6-489c7ce394ff': {
		account: 3378300,
		claimable: 1,
		cloneable: 1,
		created: '2021-08-11 08:57:59.621358-04',
		discount: '0.00000',
		discountSources: [
			{
				amount: null,
				created: '2021-08-11 08:58:02.693441-04',
				discountStrategy: 'proportional',
				metadata: {},
				totals: {
					basket_discount_subtotal: '0.00000',
					config_discount_subtotal: '0.00000',
					discount_total: '0.00000',
					item_discount_subtotal: '0.00000',
				},
				type: 'account',
				uuid: '30adec28-1e83-45e3-a96c-dd25abc54068',
			},
		],
		expire: null,
		items: [],
		locked: 0,
		mergeable: 1,
		metadata: {
			attribution: [
				{
					source: 'hubspot',
					source_data: {
						utk: 'a492b508b249d493deacde13f7a8bdce',
					},
				},
				{
					source: 'google_analytics',
					source_data: {
						cid: '1988884939.1619106862',
					},
				},
			],
		},
		modifiable: 1,
		name: null,
		nonce: 'c491855c-faa3-11eb-8257-20cf307037a1',
		owner: 'mctesterson',
		purchasable: 1,
		share_code: 'e641c1c8-8a23-4574-a9aa-fa235a584ad9',
		status: 'empty',
		totals: {
			items_discount_total: '0',
			items_prepay_discount_total: null,
			items_prepay_once_total: null,
			items_prepay_ongoing_total: null,
			items_price_total: '0',
			items_setup_fee_total: '0',
			items_subtotal: '0',
			items_tax_total: '0',
			total_basket_cost: '0',
		},
		unlockable: 1,
		uuid: 'c3945e7c-9126-47fb-8cf6-489c7ce394ff',
		valid: 1,
	},
};
