import { combineReducers } from 'redux';

import { reducer as details } from './detailsModule';
import { reducer as getBasicOptions } from './getBasicOptionsModule';
import { reducer as rules } from './rulesModule';
import ruleset from './ruleset/rulesetReducer';
import { reducer as update } from './updateModule';

export default combineReducers({
	details,
	getBasicOptions,
	rules,
	ruleset,
	update,
});
