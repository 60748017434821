import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { selectors as detailsSelectors } from 'modules/api/basket/detailsModule';
import CompactCartSummary from './CompactCartSummary';

const mapStateToProps = (state) => ({
	items: detailsSelectors.getBasketItems(state),
});

export default connect(mapStateToProps)(withRouter(CompactCartSummary));
