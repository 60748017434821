import { combineReducers } from 'redux';

import { reducer as deleteReducer } from './deleteModule';
import { reducer as details } from './detailsModule';
import { reducer as list } from './listModule';
import { reducer as update } from './updateModule';
import { reducer as create } from './createModule';
import { reducer as restore } from './restoreModule';

export default combineReducers({
	create,
	delete: deleteReducer,
	details,
	list,
	restore,
	update,
});
