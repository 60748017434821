import { spawn } from 'redux-saga/effects';

import { sagas as list } from './listModule';
import { sagas as restore } from './restoreModule';
import { sagas as details } from './detailsModule';

export default function* sagas() {
	yield spawn(list);
	yield spawn(restore);
	yield spawn(details);
}
