import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';

const getStateSlice = (state) => state.storm.private.parent.list;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'STORM_PRIVATE_PARENT_LIST',
	method: 'POST',
	url: '/storm/private/parent/list.json',
});

const deployOntoOptions = createSelector(
	defaultSelectors.getNativeData,
	(slice) =>
		slice?.items?.map(({ uniq_id: uniqId, domain }) => ({
			label: `${domain} (Private VPS Parent)`,
			value: uniqId,
		})) || [],
);

const selectors = {
	deployOntoOptions,
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };
