import { call, put, take } from 'redux-saga/effects';
import basketActions from 'modules/basket/actions';
import { actions as createActions } from 'modules/api/basket/createModule';
import { actions as detailsActions } from 'modules/api/basket/detailsModule';

import storeUuid from 'modules/basket/sagas/storeUuid';

import {
	removeUndefinedKeys,
	convertEmptyObjectToUndef,
} from 'utility/tools/objects';
import { AttributionData } from 'modules/attribution';

function createMetadata({ oppId } = {}) {
	// including attributionData directly in the metadata here to avoid the extra fetch.
	const attributionData = new AttributionData();
	let metadata = {
		...(oppId ? { opp_id: oppId } : undefined),
		...attributionData.all,
	};

	metadata = removeUndefinedKeys(metadata);
	metadata = convertEmptyObjectToUndef(metadata);

	return metadata;
}

function* createBasket(oppId) {
	yield put(createActions.fetch({ metadata: createMetadata({ oppId }) }));
	const action = yield take(createActions.setType);
	yield call(storeUuid, { uuid: action?.payload?.uuid });
	yield put(detailsActions.set(action?.payload));
	yield put(basketActions.initialized());
}

export { createMetadata };
export default createBasket;
