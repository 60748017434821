import { combineReducers } from 'redux';

import { reducer as add } from './addModule';
import { reducer as listPublic } from './listPublicModule';
import { reducer as remove } from './removeModule';
import { reducer as request } from './requestModule';

export default combineReducers({
	add,
	listPublic,
	remove,
	request,
});
