const actions = {
	CART_CHECKOUT_NO_HOSTNAME_ERROR: 'CART_CHECKOUT_NO_HOSTNAME_ERROR',
	setNoHostnameError: ({ error }) => ({
		type: actions.CART_CHECKOUT_NO_HOSTNAME_ERROR,
		payload: { error },
	}),
	CART_SET_CUSTOMER_PREVIEW: 'CART_SET_CUSTOMER_PREVIEW',
	setIsCustomerPreview: ({ value }) => ({
		type: actions.CART_SET_CUSTOMER_PREVIEW,
		payload: { isCustomerPreview: value },
	}),
};

export default actions;
