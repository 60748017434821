import React from 'react';
import PropTypes from 'prop-types';
import DialogActions from '@material-ui/core/DialogActions';
import LWTypography from 'components/common/LWTypography';
import LWButton from 'components/common/LWButton';

const DetourDialogContent = ({
	route,
	displayRoute,
	closeDialog,
	buttonsVisible,
}) => (
	<div>
		<LWTypography>
			We are working on getting this feature added to My Liquid Web in the near
			future.
		</LWTypography>
		<LWTypography>
			{buttonsVisible && (
				<>
					For now, we can redirect you to&nbsp;
					<b>{displayRoute || route.substring(8, route.indexOf('.com'))}</b>
					&nbsp;where this feature exists.
				</>
			)}
		</LWTypography>
		{buttonsVisible && (
			<DialogActions>
				<LWButton color="tertiary" onClick={() => closeDialog()}>
					Cancel
				</LWButton>

				<LWButton
					variant="contained"
					onClick={() => {
						window.open(route, '_blank', 'noopener');
						closeDialog();
					}}
				>
					Continue to Manage
				</LWButton>
			</DialogActions>
		)}
	</div>
);

DetourDialogContent.propTypes = {
	route: PropTypes.string.isRequired,
	displayRoute: PropTypes.string,
	closeDialog: PropTypes.func.isRequired,
	buttonsVisible: PropTypes.bool,
};

DetourDialogContent.defaultProps = {
	displayRoute: null,
	buttonsVisible: true,
};

export default DetourDialogContent;
