import actions from './actions';

const defaultState = {
	/* oneOf: 'vps', 'cloudDedicated', or a private parent uniq_id */
	deployOnto: 'vps',
};

const options = (state = defaultState, action) => {
	const { type, deployOnto } = action;
	switch (type) {
		case actions.SET_SERVER_RESIZE_DEPLOY_ONTO:
			return { ...state, deployOnto };
		default:
			return state;
	}
};

export default options;
