import { createSelector } from 'reselect';

const getStateSlice = (state) => state.snackbar;

const messages = createSelector(
	getStateSlice,
	(slice) => slice.get('messages'),
);

const selectors = {
	autorenew: createSelector(
		getStateSlice,
		(slice) => slice.getIn(['data', 'autorenew_value'], ''),
	),
	isOpen: createSelector(
		getStateSlice,
		(slice) => slice.get('isOpen'),
	),
	messages,
	currentMessage: createSelector(
		messages,
		// eslint-disable-next-line no-confusing-arrow
		(msgs) => (msgs.size > 0 ? msgs.first() : null),
	),
	color: createSelector(
		getStateSlice,
		(slice) => slice.get('color'),
	),
	autoHideDuration: createSelector(
		getStateSlice,
		(slice) => slice.get('autoHideDuration'),
	),
};
export default selectors;
