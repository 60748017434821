import MonetizationOn from '@material-ui/icons/MonetizationOn';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Dns from '@material-ui/icons/DnsRounded';
import Language from '@material-ui/icons/LanguageRounded';
import SettingsSystemDaydream from '@material-ui/icons/SettingsSystemDaydreamRounded';
import Layers from '@material-ui/icons/LayersRounded';
import AddShoppingCart from '@material-ui/icons/AddShoppingCartRounded';
import ShoppingCart from '@material-ui/icons/ShoppingCartRounded';

const iconSelection = [
	{
		icon: Dns,
		prefix: '/servers',
	},
	{
		icon: Language,
		prefix: '/domain',
	},
	{
		icon: MonetizationOn,
		prefix: '/billing',
	},
	{
		icon: AccountCircle,
		prefix: '/account',
	},
	{
		icon: SettingsSystemDaydream,
		prefix: '/services',
	},
	{
		icon: Layers,
		prefix: '/projects',
	},
	{
		icon: AddShoppingCart,
		prefix: '/shop',
	},
	{
		icon: ShoppingCart,
		prefix: '/cart',
	},
];

const getIconFromPath = (path) => {
	const selection = iconSelection.find((x) => path.startsWith(x.prefix));
	return selection ? selection.icon : null;
};

export { iconSelection, getIconFromPath };
