import React from 'react';
import { call, takeLatest, put, take } from 'redux-saga/effects';
import { actions as productDetailsActions } from 'modules/api/product/detailsModule';
import SideSheetContents from 'components/marketplace/SideSheetContents';
import sideSheetActions from 'modules/sideSheet/actions';
import { actions as marketplaceActions } from '..';

function* openSidesheet({ payload }) {
	const {
		domain,
		features,
		fullDescription,
		marketplacePrices,
		productCode,
		relatedSubaccnt,
		showConfigure = true,
		title,
	} = payload;

	yield put(
		sideSheetActions.open({
			title,
			content: (
				<SideSheetContents
					productCode={productCode}
					marketplacePrices={marketplacePrices}
					fullDescription={fullDescription}
					features={features}
					showConfigure={showConfigure}
					relatedSubaccnt={relatedSubaccnt} // used in connected
					domain={domain} // used in connected
				/>
			),
		}),
	);
}

function* openSidesheetForProduct({ payload }) {
	const {
		productCode,
		relatedSubaccnt,
		domain,
		showConfigure = true,
	} = payload;

	yield put(sideSheetActions.isLoading());

	yield put(
		productDetailsActions.fetch({
			code: productCode,
			alsowith: ['marketplacePrices'],
		}),
	);
	const { payload: productDetailsPayload } = yield take(
		productDetailsActions.setType,
	);
	const {
		title,
		description,
		features,
		marketplacePrices,
	} = productDetailsPayload;

	yield call(openSidesheet, {
		payload: {
			productCode,
			fullDescription: description,
			domain,
			features,
			title,
			relatedSubaccnt,
			showConfigure,
			marketplacePrices,
		},
	});
}

export default function* sideSheetSagas() {
	yield takeLatest(marketplaceActions.OPEN_SIDESHEET, openSidesheet);
	yield takeLatest(
		marketplaceActions.OPEN_SIDESHEET_FOR_PRODUCT,
		openSidesheetForProduct,
	);
}
