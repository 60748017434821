const accountDetails = {
	accnt: '3378300',
	account_status: 'active',
	businessUnit: 'liquidweb',
	customerLifecycle: {
		cloud: 1,
		cloud_servers: 1,
		cloud_sites: 0,
		cloud_storm: 1,
		dedicated: 1,
		has_active_products: 1,
		has_whmlogin: 1,
		mwx: 1,
		new_account: 0,
		partner: 1,
		pbemail: 0,
	},
	highlights: [
		{
			count: '0',
			key: 'migrations',
		},
		{
			count: '0',
			key: 'secure-notes',
		},
	],
	level: 'enterprise',
	managementPortal: 'any',
	referAFriend: '415852825623',
};

export default accountDetails;
