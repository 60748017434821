import { createSelector } from 'reselect';
import { selectors as assetDetailsSelectors } from 'modules/api/asset/detailsModule';
import { selectors as productDetailsSelectors } from 'modules/api/product/detailsModule';
import { selectors as backupListSelectors } from 'modules/api/storm/backup/listModule';

const getStateSlice = (state) => state.serverBackupsAndImages.settings;

const isLoadingParent = createSelector(
	assetDetailsSelectors.isLoading,
	productDetailsSelectors.isLoading,
	(assetDetails, productDetails) => assetDetails || productDetails,
);

const maxQuotaPlan = createSelector(
	productDetailsSelectors.quotaPlanOptions,
	(plans) =>
		Number(
			plans
				.sort(
					({ value: value1 }, { value: value2 }) =>
						Number(value1) - Number(value2),
				)
				.slice(-1)[0]?.value,
		),
);

const dataBarMarks = createSelector(
	productDetailsSelectors.quotaPlanOptions,
	maxQuotaPlan,
	getStateSlice,
	assetDetailsSelectors.backupQuotaValue,
	(plans, max, backupsSettingsSlice, currentQuota) => {
		const selected = Number(backupsSettingsSlice?.selectedQuota?.value || 0);
		const current = Number(currentQuota);
		return plans
			.filter(({ value }) => {
				return Number(value) < max;
			})
			.map(({ value }) => ({
				value: Number(value),
				disabled: (selected || current) <= Number(value),
			}));
	},
);

const selectors = {
	getStateSlice,
	daysToRetain: createSelector(
		getStateSlice,
		(slice) => slice.daysToRetain,
	),
	isLoading: createSelector(
		isLoadingParent,
		backupListSelectors.isLoading,
		(isLoadingP, backupList) => isLoadingP || backupList,
	),
	isLoadingParent,
	dataBarMarks,
	maxQuotaPlan,
};
export default selectors;
