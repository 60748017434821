// TODO: put this into redux-forms and do validation.
import React, { useEffect } from 'react';
import HostnameAndProjectContent from './HostnameAndProjectContent';

const getDialogTitle = ({ variant }) => {
	switch (variant) {
		case 'privateParent':
			return 'Name & Project Selection';
		default:
			return 'Hostname & Project Selection';
	}
};

const HostnameProjectDialog = ({
	closeDialog, // from connected
	assetList, // from connected
	domains, // from connected
	basketDetails, // from connected
	fetchAssetList, // from connected
	fetchProjectList, // from connected
	fetchItemModify, // from connected
	fetchAssertProperties, // from connected
	projectList, // from connected
	serverAvailable, // from connected
	fetchServerAvailable, // from connected
	handleSubmit, // from connected (redux-form)
	setDialogTitle,
	itemDetails,
}) => {
	const itemUuid = itemDetails?.uuid;
	const itemProperties = itemDetails?.properties;
	const itemProductCode = itemDetails?.product_code;
	const canOmitDomain = itemDetails?.productInfo?.capabilities?.canOmitDomain;
	const productName =
		itemDetails?.productInfo?.title ||
		itemDetails?.productInfo?.product_description;

	const commonProps = {
		basketDetails,
		fetchProjectList,
		fetchItemModify,
		fetchAssertProperties,
		itemUuid,
		itemProperties,
		projectList,
		onClose: closeDialog,
	};

	const variant = (() => {
		if (canOmitDomain) return 'projectOnly';
		switch (itemProductCode) {
			case 'DREG':
				return 'projectOnly';
			case 'SS.PP':
				return 'privateParent';
			default:
				return 'hostname';
		}
	})();

	useEffect(() => {
		setDialogTitle(getDialogTitle({ variant }));
	}, [setDialogTitle, variant]);

	return (
		<HostnameAndProjectContent
			assetList={assetList}
			domains={domains}
			fetchAssetList={fetchAssetList}
			productName={productName}
			serverAvailable={serverAvailable}
			fetchServerAvailable={fetchServerAvailable}
			variant={variant}
			handleSubmit={handleSubmit}
			{...commonProps}
		/>
	);
};

const formName = 'HostnameProjectDialog';

export { formName };
export default HostnameProjectDialog;
