const backups = {
	'4035': {
		accnt: 3378300,
		features: {},
		hv_type: 'kvm',
		id: 4035,
		name: '2021-03-31 00:33:37',
		size: '13.07',
		template: 'CENTOS_7_CPANEL_CLOUDLINUX',
		time_taken: '2021-03-31 00:33:37',
		uniq_id: '7CNH4M',
	},
	'4022': {
		accnt: 3378300,
		features: {},
		hv_type: 'kvm',
		id: 4022,
		name: '2021-03-30 00:38:41',
		size: '13.05',
		template: 'CENTOS_7_CPANEL_CLOUDLINUX',
		time_taken: '2021-03-30 00:38:41',
		uniq_id: '7CNH4M',
	},
	'4009': {
		accnt: 3378300,
		features: {},
		hv_type: 'kvm',
		id: 4009,
		name: '2021-03-29 00:30:36',
		size: '13.03',
		template: 'CENTOS_7_CPANEL_CLOUDLINUX',
		time_taken: '2021-03-29 00:30:36',
		uniq_id: '7CNH4M',
	},
	'3995': {
		accnt: 3378300,
		features: {},
		hv_type: 'kvm',
		id: 3995,
		name: '2021-03-28 00:20:39',
		size: '13.01',
		template: 'CENTOS_7_CPANEL_CLOUDLINUX',
		time_taken: '2021-03-28 00:20:39',
		uniq_id: '7CNH4M',
	},
	'3983': {
		accnt: 3378300,
		features: {},
		hv_type: 'kvm',
		id: 3983,
		name: '2021-03-27 00:18:39',
		size: '12.98',
		template: 'CENTOS_7_CPANEL_CLOUDLINUX',
		time_taken: '2021-03-27 00:18:39',
		uniq_id: '7CNH4M',
	},
	'4443': {
		accnt: 3378300,
		features: {},
		hv_type: 'kvm',
		id: 4443,
		name: '2021-05-20 01:02:39',
		size: '9.89',
		template: 'CENTOS_7_CPANEL_CLOUDLINUX',
		time_taken: '2021-05-20 01:02:39',
		uniq_id: '6J4B8T',
	},
	'4441': {
		accnt: 3378300,
		features: {
			MsSQL: {
				count: 4,
				value: 'None',
			},
			WinAV: 'NOD32',
		},
		hv_type: 'kvm',
		id: 4441,
		name: '2021-05-20 00:36:48',
		size: '28.31',
		template: 'WINDOWS_2016_PLESK',
		time_taken: '2021-05-20 00:36:48',
		uniq_id: '2HK7VP',
	},
	'4439': {
		accnt: 3378300,
		features: {},
		hv_type: 'kvm',
		id: 4439,
		name: '2021-05-20 00:02:57',
		size: '1.91',
		template: 'CENTOS_7_UNMANAGED',
		time_taken: '2021-05-20 00:02:57',
		uniq_id: 'UQZ3HX',
	},
	'4438': {
		accnt: 3378300,
		features: {},
		hv_type: 'kvm',
		id: 4438,
		name: '2021-05-19 14:22:51',
		size: '9.89',
		template: 'CENTOS_7_CPANEL_CLOUDLINUX',
		time_taken: '2021-05-19 14:22:51',
		uniq_id: '6J4B8T',
	},
	'4437': {
		accnt: 3378300,
		features: {},
		hv_type: 'kvm',
		id: 4437,
		name: '2021-05-19 00:36:40',
		size: '9.89',
		template: 'CENTOS_7_CPANEL_CLOUDLINUX',
		time_taken: '2021-05-19 00:36:40',
		uniq_id: '6J4B8T',
	},
	'4434': {
		accnt: 3378300,
		features: {
			MsSQL: {
				count: 4,
				value: 'None',
			},
			WinAV: 'NOD32',
		},
		hv_type: 'kvm',
		id: 4434,
		name: '2021-05-19 00:12:40',
		size: '28.32',
		template: 'WINDOWS_2016_PLESK',
		time_taken: '2021-05-19 00:12:40',
		uniq_id: '2HK7VP',
	},
	'4433': {
		accnt: 3378300,
		features: {},
		hv_type: 'kvm',
		id: 4433,
		name: '2021-05-19 00:03:15',
		size: '1.91',
		template: 'CENTOS_7_UNMANAGED',
		time_taken: '2021-05-19 00:03:15',
		uniq_id: 'UQZ3HX',
	},
	'4431': {
		accnt: 3378300,
		features: {},
		hv_type: 'kvm',
		id: 4431,
		name: '2021-05-18 00:34:41',
		size: '10.00',
		template: 'CENTOS_7_CPANEL_CLOUDLINUX',
		time_taken: '2021-05-18 00:34:41',
		uniq_id: '6J4B8T',
	},
	'4428': {
		accnt: 3378300,
		features: {
			MsSQL: {
				count: 4,
				value: 'None',
			},
			WinAV: 'NOD32',
		},
		hv_type: 'kvm',
		id: 4428,
		name: '2021-05-18 00:11:38',
		size: '28.32',
		template: 'WINDOWS_2016_PLESK',
		time_taken: '2021-05-18 00:11:38',
		uniq_id: '2HK7VP',
	},
	'4426': {
		accnt: 3378300,
		features: {},
		hv_type: 'kvm',
		id: 4426,
		name: '2021-05-18 00:03:00',
		size: '1.90',
		template: 'CENTOS_7_UNMANAGED',
		time_taken: '2021-05-18 00:03:00',
		uniq_id: 'UQZ3HX',
	},
	'4425': {
		accnt: 3378300,
		features: {},
		hv_type: 'kvm',
		id: 4425,
		name: '2021-05-17 10:44:26',
		size: '9.99',
		template: 'CENTOS_7_CPANEL_CLOUDLINUX',
		time_taken: '2021-05-17 10:44:26',
		uniq_id: '6J4B8T',
	},
};

export default backups;
