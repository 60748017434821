import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import DividerBox from 'components/atoms/DividerBox';

const variants = {
	// used for cart badge, total panel
	blue: {
		border: '4px solid',
		borderRadius: 8,
		borderColor: 'primary.washed',
		bgcolor: 'primary.faint',
		padding: 2.5,
	},
	opaqueBlue: {
		bgcolor: 'primary.faintOpaque',
	},
	badge: {
		borderRadius: '24px 24px 8px 8px',
	},
	// used for dark card/table headers
	emphasized: {
		bgcolor: 'primary.dark',
		color: 'primary.contrastText',
	},
	// used for hostname needed messaging
	tag: {
		display: 'flex',
		alignItems: 'center',
		border: '2px solid',
		borderRadius: 4,
		borderColor: 'primary.dark',
		bgcolor: 'primary.faint',
		padding: 0.5,
	},
	grey: {
		border: '1px solid',
		borderColor: 'common.grey.2',
		borderRadius: '8px',
		bgcolor: 'common.grey.0',
	},
};

const getVariantProps = (variant) => {
	if (typeof variant === 'string') return variants[variant];
	return variant.reduce((acc, v) => {
		return { ...acc, ...variants[v] };
	}, {});
};

const StyledBox = ({
	$between,
	children,
	divider,
	dividerSide = 'bottom',
	variant = 'neutral',
	...rest
}) => {
	const variantProps = getVariantProps(variant);
	const Component = divider ? DividerBox : Box;
	const dividerProps = divider ? { $side: dividerSide, $between } : {};
	return (
		<Component p={2} {...dividerProps} {...variantProps} {...rest}>
			{children}
		</Component>
	);
};

const variantNames = [
	'badge',
	'blue',
	'emphasized',
	'faint',
	'grey',
	'neutral',
	'opaqueBlue',
	'tag',
];

StyledBox.propTypes = {
	/** String to determine which variant styles to display.
	    If an array of strings is passed in, later variants will override styles of
			earlier variants
	*/
	variant: PropTypes.oneOfType([
		PropTypes.oneOf(variantNames),
		PropTypes.arrayOf(PropTypes.oneOf(variantNames)),
	]),
};

export default StyledBox;
