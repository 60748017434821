import React from 'react';
import compose from 'utility/compose';
import { Field, reduxForm } from 'redux-form';
import { required } from 'redux-form-validators';
import { domainName } from 'utility/form/validators';
import { renderTextField } from 'utility/form/renderers';

const CREATE_TRANSFER_FORM_ID = 'createIncomingTransfer';

const CreateTransferForm = () => {
	return (
		<form>
			<Field
				name="domain"
				label="Domain"
				component={renderTextField}
				fullWidth
				margin="normal"
				validate={[(required(), domainName())]}
				variant="outlined"
			/>

			<Field
				name="eppCode"
				label="EPP Code"
				component={renderTextField}
				fullWidth
				margin="normal"
				validate={required()}
				variant="outlined"
			/>
		</form>
	);
};

CreateTransferForm.propTypes = {};

export { CREATE_TRANSFER_FORM_ID, CreateTransferForm };

export default compose(
	reduxForm({
		destroyOnUnmount: false,
		form: CREATE_TRANSFER_FORM_ID,
	}),
)(CreateTransferForm);
