import { useEffect, useState, useCallback } from 'react';

export default (apiData, ignoreEmpty = true) => {
	const [dirtyState, setDirtyState] = useState(apiData);
	const setPristine = useCallback(() => setDirtyState(apiData), [apiData]);
	useEffect(() => {
		if (ignoreEmpty && (apiData === null || apiData === undefined)) return;
		setDirtyState(apiData);
	}, [apiData, ignoreEmpty]);
	return [dirtyState, setDirtyState, setPristine];
};
