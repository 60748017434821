import { spawn } from 'redux-saga/effects';

import { sagas as createSagas } from './createModule';
import { sagas as detailsSagas } from './detailsModule';
import { sagas as executeSagas } from './executeModule';
import { sagas as updateSagas } from './updateModule';

export default function* sagas() {
	yield spawn(createSagas);
	yield spawn(detailsSagas);
	yield spawn(executeSagas);
	yield spawn(updateSagas);
}
