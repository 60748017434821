import { combineReducers } from 'redux';

import { reducer as bulkUpdateTTL } from './bulkUpdateTTLModule';
import { reducer as delegation } from './delegationModule';
import { reducer as details } from './detailsModule';
import { reducer as formatAsBind } from './formatAsBindModule';
import { reducer as summary } from './summaryModule';
import { reducer as create } from './createModule';
import { reducer as importZoneFile } from './importZoneFileModule';
import { reducer as parkableIps } from './parkableIpsModule';
import history from './history/historyReducer';

export default combineReducers({
	create,
	bulkUpdateTTL,
	delegation,
	details,
	formatAsBind,
	summary,
	history,
	importZoneFile,
	parkableIps,
});
