import { getCjReferralData, getCookie } from 'utility/tools/cookies';

const cookieToObj = (source, sourceKey, cookieKey) => {
	const cookie = getCookie(cookieKey);
	if (cookie) {
		return {
			source,
			source_data: { [sourceKey]: cookie },
		};
	}
	return null;
};

const parseIfObj = (thingToParse) => {
	if (typeof thingToParse === 'object') return thingToParse;
	if (typeof thingToParse === 'string') {
		try {
			return JSON.parse(thingToParse);
		} catch (e) {
			// eslint-disable-next-line no-console
			console.error(new Error(`"${thingToParse}" is not valid JSON.`));
		}
	}
	return {};
};

class AttributionData {
	getReferral = () => {
		const cookie = getCjReferralData();
		if (!cookie) return undefined;

		const { PartnerId: cjpid, AdId: cjaid, SubId: cjsid } = cookie;
		return {
			program: 'commission_junction',
			source_data: { cjpid, cjsid, cjaid },
		};
	};

	getAttribution = () => {
		const attributionArray = [];
		const { program, irpid, iradid, sharedid, clickid } =
			parseIfObj(getCookie('lwReferralData')) || {};
		if (program === 'impact_radius') {
			attributionArray.push({
				source: 'affiliate_referral',
				source_data: { program, irpid, iradid, sharedid, clickid },
			});
		}

		attributionArray.push(
			cookieToObj('hubspot', 'utk', 'hubspotutk'),
			cookieToObj('google_analytics', 'cid', 'googleanalyticscid'),
		);
		const finalArray = attributionArray.filter((elem) => elem);
		return finalArray.length ? finalArray : undefined;
	};

	get referral() {
		return this.getReferral();
	}

	get attribution() {
		return this.getAttribution();
	}

	get all() {
		return { referral: this.referral, attribution: this.attribution };
	}
}

export default AttributionData;
