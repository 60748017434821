const actions = {
	INITIALIZED: 'GTM_INITIALIZED',
	initialized: () => ({
		type: actions.INITIALIZED,
	}),
	ANALYTICS_EVENT: 'ANALYTICS_EVENT',
	sendAnalyticsEvent: ({
		category,
		action,
		label,
		value,
		nonInteraction = false,
	}) => ({
		type: actions.ANALYTICS_EVENT,
		payload: {
			category,
			action,
			label,
			value,
			nonInteraction,
		},
	}),
};

export default actions;
