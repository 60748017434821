import { createSelector } from 'reselect';
import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { getId } from 'utility/redux/selectorHelperFunctions/common';

const getStateSlice = (state) => state.storm.template.list;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'STORM_TEMPLATE_LIST',
	method: 'POST',
	url: '/storm/template/list.json',
});

const getSelected = createSelector(
	defaultSelectors.getNativeItems,
	getId,
	(slice, id) => slice.find((template) => template.name === id),
);

const selectors = {
	getSelected,
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };
