import { put, takeLatest } from 'redux-saga/effects';
import { actions as accountDetailsActions } from 'modules/api/account/detailsModule';

import { actions as oneTimeSecretReadActions } from 'modules/api/onetimesecret/readModule';

function* callDetailsSaga() {
	yield put(
		accountDetailsActions.fetch({
			alsowith: [
				'managementPortal',
				'businessUnit',
				'customerLifecycle',
				'highlights',
				'referAFriend',
			],
		}),
	);
}

export default function* rootSaga() {
	yield takeLatest(oneTimeSecretReadActions.setType, callDetailsSaga);
}
