import React from 'react';
import { Field } from 'redux-form';
import { renderSelectField } from 'utility/form/renderers';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import MiniCart from 'components/forms/MiniCart';
import MenuItem from '@material-ui/core/MenuItem';
import LWTypography from 'components/common/LWTypography';
import ConfirmCancel from 'components/common/Dialogs/ConfirmCancel';

const RenewSSLForm = ({
	renewDialogObj: {
		payload: { domain, uniqId },
	},
	product: { isLoading: isLoadingProduct, oneYearPrice, twoYearPrice },
	renewObj: { isLoading },
	renewSSL,
	selected,
	onClose,
}) => {
	const { numberOfYears } = selected;
	const prices = {
		1: oneYearPrice,
		2: twoYearPrice,
	};
	if (isLoadingProduct) {
		return <CircularProgress size={30} />;
	}
	return (
		<>
			<Grid container alignContent="center" spacing={2}>
				<Grid item xs={12}>
					<Field
						aria-label="number of years"
						name="numberOfYears"
						component={renderSelectField}
						label="Number of Years"
						fullWidth
					>
						<MenuItem value={1}>1</MenuItem>
					</Field>
				</Grid>

				<Box p={1}>
					<LWTypography variant="h2">Order Summary</LWTypography>
				</Box>
				<Grid item xs={12}>
					<MiniCart
						list={[
							{
								key: {
									title: domain,
									subtitle: 'SSL Renewal fee',
								},
								value: prices[numberOfYears],
							},
						]}
					/>
				</Grid>
			</Grid>
			<ConfirmCancel
				isLoading={isLoading}
				confirmText="Submit"
				confirm={() =>
					renewSSL({
						duration: numberOfYears,
						uniqId,
					})
				}
				cancel={onClose}
			/>
		</>
	);
};

export default RenewSSLForm;
