import React from 'react';
import { useDispatch } from 'react-redux';
import ConfirmCancel from 'components/common/Dialogs/ConfirmCancel';
import LWTypography from 'components/common/LWTypography';
import dialogActions from 'modules/dialogs/actions';
import PropTypes from 'prop-types';

const ConfigSelectModalContents = ({ variant, confirm }) => {
	const dispatch = useDispatch();
	return (
		<div data-testid="ConfigSelectModalContents__Container">
			<LWTypography paragraph>
				Changing the {variant} of this product{' '}
				<b>will reset other options selected</b> to display the correct options
				available for the new {variant}.
			</LWTypography>
			<LWTypography paragraph>
				Would you like to proceed with your change?
			</LWTypography>
			<ConfirmCancel
				confirmText="Proceed"
				cancel={() => dispatch(dialogActions.close())}
				confirm={confirm}
			/>
		</div>
	);
};

ConfigSelectModalContents.propTypes = {
	variant: PropTypes.string.isRequired,
	confirm: PropTypes.func.isRequired,
};

export default ConfigSelectModalContents;
