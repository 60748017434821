const actions = {
	BASKET_ABANDON: 'BASKET_ABANDON',
	abandon: (payload) => ({
		type: actions.BASKET_ABANDON,
		payload,
	}),
	BASKET_ADD_ITEM: 'BASKET_ADD_ITEM',
	addItem: (payload) => ({
		type: actions.BASKET_ADD_ITEM,
		payload,
	}),
	BASKET_CLAIM: 'BASKET_CLAIM',
	claim: ({ isManual = true } = {}) => ({
		type: actions.BASKET_CLAIM,
		isManual,
	}),
	BASKET_FETCH_ITEM_DETAILS: 'BASKET_FETCH_ITEM_DETAILS',
	fetchItemDetails: (payload) => ({
		type: actions.BASKET_FETCH_ITEM_DETAILS,
		payload,
	}),
	BASKET_INITIALIZE: 'BASKET_INITIALIZE',
	initialize: () => ({
		type: actions.BASKET_INITIALIZE,
	}),
	BASKET_ITEM_ADDED: 'BASKET_ITEM_ADDED',
	itemAdded: (payload) => ({
		type: actions.BASKET_ITEM_ADDED,
		payload,
	}),
	BASKET_ITEM_FETCH_EXTRA_OPTIONS: 'BASKET_ITEM_FETCH_EXTRA_OPTIONS',
	fetchItemExtraOptions: (payload) => ({
		type: actions.BASKET_ITEM_FETCH_EXTRA_OPTIONS,
		payload,
	}),
	BASKET_ITEM_REMOVED: 'BASKET_ITEM_REMOVED',
	itemRemoved: (payload) => ({
		type: actions.BASKET_ITEM_REMOVED,
		payload,
	}),
	BASKET_MERGE_SAVED: 'BASKET_MERGE_SAVED',
	mergeSaved: (payload) => ({
		type: actions.BASKET_MERGE_SAVED,
		payload,
	}),
	BASKET_GENERATE_SAVED: 'BASKET_GENERATE_SAVED',
	generateSaved: (payload) => ({
		type: actions.BASKET_GENERATE_SAVED,
		payload,
	}),
	BASKET_PURCHASE: 'BASKET_PURCHASE',
	purchase: () => ({
		type: actions.BASKET_PURCHASE,
	}),
	BASKET_REMOVE_ITEM: 'BASKET_REMOVE_ITEM',
	removeItem: (payload) => ({
		type: actions.BASKET_REMOVE_ITEM,
		payload,
	}),
	BASKET_RESET: 'BASKET_RESET',
	reset: () => ({
		type: actions.BASKET_RESET,
	}),
	BASKET_SET_ITEM_CONFIG: 'BASKET_SET_ITEM_CONFIG',
	setItemConfig: (payload) => ({
		type: actions.BASKET_SET_ITEM_CONFIG,
		...payload,
	}),
	BASKET_SET_ERROR: 'BASKET_SET_ERROR',
	setError: (text) => ({
		type: actions.BASKET_SET_ERROR,
		text,
	}),
	BASKET_SAVE_PURCHASE: 'BASKET_SAVE_PURCHASE',
	savePurchase: (basketDetails) => ({
		type: actions.BASKET_SAVE_PURCHASE,
		basketDetails,
	}),
	BASKET_SET_EXPIRE: 'BASKET_SET_EXPIRE',
	setExpire: ({ date }) => ({
		type: actions.BASKET_SET_EXPIRE,
		date,
	}),
	BASKET_ADMIN_ITEM_MODIFY: 'BASKET_ADMIN_ITEM_MODIFY',
	adminItemModify: ({
		properties,
		itemUuid,
		discountPercent,
		discountUuid,
	}) => ({
		type: actions.BASKET_ADMIN_ITEM_MODIFY,
		properties,
		itemUuid,
		discountPercent,
		discountUuid,
	}),
	BASKET_ITEM_ASSERT_PROPERTIES: 'BASKET_ITEM_ASSERT_PROPERTIES',
	itemChangeProperties: ({ properties, uuid }) => ({
		type: actions.BASKET_ITEM_ASSERT_PROPERTIES,
		properties,
		uuid,
	}),
	BASKET_ITEM_ASSERT_ZONE: 'BASKET_ITEM_ASSERT_ZONE',
	itemChangeZone: ({ zone, uuid }) => ({
		type: actions.BASKET_ITEM_ASSERT_ZONE, // can also be swap if new zone is a different region.
		zone,
		uuid,
	}),
	BASKET_ITEM_SWAP_OS: 'BASKET_ITEM_SWAP_OS',
	itemChangeOS: ({ os, uuid }) => ({
		type: actions.BASKET_ITEM_SWAP_OS,
		os,
		uuid,
	}),
	BASKET_ITEM_SWAP_REGION: 'BASKET_ITEM_SWAP_REGION',
	itemChangeRegion: ({ region, uuid }) => ({
		type: actions.BASKET_ITEM_SWAP_REGION,
		region,
		uuid,
	}),
	BASKET_ITEM_SWAP_STORM_TYPE: 'BASKET_ITEM_SWAP_STORM_TYPE',
	itemChangeStormType: ({ uuid }) => ({
		type: actions.BASKET_ITEM_SWAP_STORM_TYPE,
		uuid,
	}),
	BASKET_INITIALIZED: 'BASKET_INITIALIZED',
	initialized: () => ({
		type: actions.BASKET_INITIALIZED,
	}),
	BASKET_ASSERT_METADATA: 'BASKET_ASSERT_METADATA',
	assertMetadata: (metadata) => ({
		type: actions.BASKET_ASSERT_METADATA,
		metadata,
	}),
	BASKET_SET_CART_NAME: 'BASKET_SET_CART_NAME',
	setCartName: ({ name }) => ({
		type: actions.BASKET_SET_CART_NAME,
		payload: { name },
	}),
	BASKET_ITEM_CONFIG_RESET: 'BASKET_ITEM_CONFIG_RESET',
	resetItemConfigs: ({ itemUuid }) => ({
		type: actions.BASKET_ITEM_CONFIG_RESET,
		payload: { itemUuid },
	}),
	BASKET_CLONE_ITEM: 'BASKET_CLONE_ITEM',
	cloneItem: ({ uuid, quantity }) => ({
		type: actions.BASKET_CLONE_ITEM,
		payload: { uuid, quantity },
	}),
};

export default actions;
