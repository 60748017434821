import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import chatActions from 'modules/chat/actions';

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Slide from '@material-ui/core/Slide';
import Box from '@material-ui/core/Box';
import ChatIcon from '@material-ui/icons/QuestionAnswerRounded';

import LWTypography from 'components/common/LWTypography';
import StatusSpinner from 'components/atoms/StatusSpinner';

const showChatRoutes = ['cart', 'shop'];

const SDiv = styled.div`
	position: fixed;
	z-index: ${(p) => p.theme.zIndex.chatLoader};
	bottom: ${({ $isMobile }) => ($isMobile ? '24px' : 0)};
	right: 24px;
	cursor: ${({ $isLoading }) => ($isLoading ? 'auto' : 'pointer')};
`;

const ChatButton = ({ isLoading = false, rootPath = '' }) => {
	const dispatch = useDispatch();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const showChat = showChatRoutes.includes(rootPath);

	return (
		<SDiv
			$isMobile={isMobile}
			$isLoading={isLoading}
			onClick={
				isLoading
					? () => {}
					: () => {
							dispatch(chatActions.startChat());
					  }
			}
			data-testid="ChatButton__container"
		>
			<Slide
				direction="up"
				in={showChat || isLoading}
				mountOnEnter
				unmountOnExit
			>
				<Box
					px={2}
					py={1.25}
					color="common.white1"
					bgcolor="common.accent.1"
					borderRadius={isMobile ? '24px' : '8px 8px 0 0'}
					display="flex"
					alignItems="center"
				>
					{isLoading ? (
						<StatusSpinner
							color="palette.common.accent.1"
							backgroundColor="palette.common.white1"
						/>
					) : (
						<ChatIcon />
					)}

					<LWTypography color="palette.common.white1" BoxProps={{ ml: 1 }}>
						{isLoading ? 'Loading Chat...' : 'Chat'}
					</LWTypography>
				</Box>
			</Slide>
		</SDiv>
	);
};

ChatButton.propTypes = {
	/** If the chat is loading */
	isLoading: PropTypes.bool,
	rootPath: PropTypes.string,
};

export default ChatButton;
