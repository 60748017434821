import React, { useEffect, useState } from 'react';
import useDebounce from 'utility/effects/useDebounce';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';

import TextField from '@material-ui/core/TextField';
import { toNumberOrEmptyString } from 'utility/format';

const STextField = styled(TextField)`
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	input[type='number'] {
		-moz-appearance: textfield;
	}
`;

const DEBOUNCE_TIMER = 500;

const NumberField = ({
	variant = 'outlined',
	margin = 'normal',
	input, // passed in by magic from redux-forms.
	isLoading = false,
	min,
	max,
	value = '',
	onChange,
	...rest
}) => {
	const [errorText, setErrorText] = useState();
	const debouncedValue = useDebounce(value, DEBOUNCE_TIMER);
	useEffect(() => {
		if (debouncedValue === '' || !debouncedValue) return;
		if (typeof debouncedValue !== 'number') {
			setErrorText(`Please enter a number`);
		} else if (debouncedValue > max) {
			setErrorText(`Must be lower than ${max}`);
		} else if (debouncedValue < min) {
			setErrorText(`Must be higher than ${min}`);
		} else setErrorText();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedValue]);
	return (
		<STextField
			variant={variant}
			margin={margin}
			type="number"
			error={!!errorText}
			helperText={errorText}
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						{isLoading && <CircularProgress size={24} />}
					</InputAdornment>
				),
			}}
			value={input?.value || value}
			onChange={(e) => {
				if (onChange) onChange(toNumberOrEmptyString(e.target.value));
				if (input?.onChange)
					input.onChange(toNumberOrEmptyString(e.target.value));
			}}
			{...rest}
		/>
	);
};

NumberField.propTypes = {
	/** defaulted to outlined. Feel free to override. */
	variant: PropTypes.string,
	/** defaulted to normal. Feel free to override. */
	margin: PropTypes.string,
	/** used to display validation error */
	min: PropTypes.number,
	/** used to display validation error */
	max: PropTypes.number,
	/** Value of the input. Allows the field to be an empty string */
	value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	/** The onChange function is passed the new value as it's first arg. */
	onChange: PropTypes.func,
};

export default NumberField;
