import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.network.zone.details;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'NETWORK_ZONE_DETAILS',
	method: 'POST',
	url: '/network/zone/details.json',
});

export { actions, reducer, sagas, defaultSelectors };
