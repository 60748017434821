import produce from 'immer';
import actions from './actions';

const initialState = {
	open: false,
	content: undefined,
	color: undefined,
	title: undefined,
	dialogProps: null,
	contentKey: '',
	contentProps: undefined,
	dismissible: true,
};
/* eslint-disable default-case, no-param-reassign */
const reducer = (state = initialState, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case actions.SET_DIALOGS_OPEN:
				draft.open = true;
				draft.content = action.content;
				draft.title = action.title;
				draft.color = action.color;
				draft.dialogProps = action.dialogProps;
				draft.contentKey = action.contentKey;
				draft.contentProps = action.contentProps;
				draft.dismissible =
					action.dismissible === undefined ? true : action.dismissible;
				break;
			case actions.SET_DIALOGS_CLOSE:
				draft.open = false;
				draft.content = undefined;
				draft.color = undefined;
				draft.title = undefined;
				draft.dialogProps = undefined;
				draft.contentKey = '';
				draft.contentProps = undefined;
				draft.dismissible = true;
				break;
			case actions.DIALOGS_SET_TITLE:
				draft.title = action.title;
				break;
		}
	});

export default reducer;
