const actions = {
	toggleDialog: (isOpen) => ({
		type: `UNBLOCK_IP_DIALOG_TOGGLE`,
		isOpen,
	}),
	setServerDetails: (isOpen, serverDetails = null) => ({
		type: `SET_SERVER_DETAILS`,
		isOpen,
		serverDetails,
	}),
};

export default actions;
