import { put, select, takeLatest, take, call } from 'redux-saga/effects';
import get from 'lodash/get';
import {
	actions as basketItemDetailsActions,
	selectors as basketItemDetailsSelectors,
} from 'modules/api/basket/item/detailsModule';
import { actions as productConfigActions } from 'modules/basket/productConfig';
import { getPathName } from 'modules/routeSelectors';
import { push } from 'connected-react-router';
import isStorm from 'utility/isStorm';
import { deployOnto as deployOntoSelector } from 'modules/basket/productConfig/selectors';
import basketActions from '../../actions';

// Cloud items need to properly set the deployOnto dropdown.
function* initCloudItem() {
	// if there is a property called parent, we need to populate that data into the DeployOnto dropdown
	const parent = (yield select(basketItemDetailsSelectors.properties))?.parent;

	if (parent) {
		const current = yield select(deployOntoSelector);
		// This will cause an infinite loop if this is removed
		if (current !== parent)
			yield put(productConfigActions.setDeployOnto({ deployOnto: parent }));
	} else {
		const isCloudDedicated = yield select(
			basketItemDetailsSelectors.isCloudDedicated,
		);
		// make sure deployOnto dropdown is set to the expected init value.
		yield put(
			productConfigActions.setDeployOnto({
				deployOnto: isCloudDedicated ? 'cloudDedicated' : 'vps',
				noAssert: true,
			}),
		);
	}
}

function* fetchBasketItemDetails(action) {
	const uuid = get(action, 'payload.uuid');
	const pathArray = ((yield select(getPathName)) || '').split('/');
	if (pathArray[2] === 'config' && uuid && pathArray[3] !== uuid) {
		yield put(push(`/shop/config/${uuid}`));
	}

	yield put(
		basketItemDetailsActions.fetch({
			uuid,
			alsowith: ['productInfo', 'totals', ['configs', 'options', 'totals']],
		}),
	);
	yield take(basketItemDetailsActions.setType);
	if (isStorm(yield select(basketItemDetailsSelectors.productCode)))
		yield call(initCloudItem);
}

export { fetchBasketItemDetails, initCloudItem };
export default function*() {
	yield takeLatest(
		basketActions.BASKET_FETCH_ITEM_DETAILS,
		fetchBasketItemDetails,
	);
}
