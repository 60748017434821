import { combineReducers } from 'redux';

import { reducer as autorenew } from './autoRenewModule';
import { reducer as details } from './detailsModule';
import { reducer as enablePrivacy } from './enablePrivacyModule';
import { reducer as disablePrivacy } from './disablePrivacyModule';
import { reducer as renew } from './renewModule';
import { reducer as registrationDetails } from './registrationDetailsModule';
import { reducer as tldDetails } from './tldDetailsModule';
import transfer from './transfer/transferReducer';
import contact from './contact/contactReducer';
import nameserver from './nameserver/nameserverReducer';

export default combineReducers({
	autorenew,
	registrationDetails,
	details,
	renew,
	transfer,
	contact,
	nameserver,
	enablePrivacy,
	disablePrivacy,
	tldDetails,
});
