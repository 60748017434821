import React from 'react';
import { SBadge, SProgress } from './styled';

const Badge = ({ children, count, isLoading }) => {
	let content;
	if (count > 0) {
		content = count;
	}

	return (
		<SBadge badgeContent={isLoading ? <SProgress /> : content}>
			{children}
		</SBadge>
	);
};

export default Badge;
