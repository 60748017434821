import React from 'react';
import StyledBox from 'components/atoms/StyledBox';
import LWTypography from 'components/common/LWTypography';

const InfoBox = ({ details, ...rest }) => (
	<StyledBox variant="grey" {...rest}>
		{details
			.filter((detail) => detail)
			.map((detail, i) => {
				const props = {
					key: `${detail}`,
				};
				if (i === 0) {
					props.bold = true;
					props.variant = 'body2';
				} else {
					props.variant = 'body1';
				}
				return (
					<LWTypography key={detail} {...props}>
						{detail}
					</LWTypography>
				);
			})}
	</StyledBox>
);

export default InfoBox;
