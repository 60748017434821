import { getConfig } from './config';

const getGoogleAnalyticsObjectName = () => {
	window.GoogleAnalyticsObject = window.GoogleAnalyticsObject || 'ga';

	return window.GoogleAnalyticsObject;
};

/**
 * If Google Analytics has not yet completed the first phase
 * of its initialization, stub window.ga() so that we can begin
 * accepting commands.
 *
 * This method partially implements the standard analytics.js
 * code snippet.
 *
 * Be aware that commands enqueued using this stub will execute
 * before the ga('create', ...) command has run, meaning the tracker
 * object will not yet exist.
 */
const maybeStubGoogleAnalytics = () => {
	const gaName = getGoogleAnalyticsObjectName();

	if (window[gaName]) return;

	/* eslint-disable func-names, prefer-rest-params */
	window[gaName] = window[gaName] || function () {
		window[gaName].q = window[gaName].q || [];
		window[gaName].q.push(arguments);
	};
	/* eslint-enable func-names, prefer-rest-params */

	window[gaName].l = new Date().getTime();
};

const getGoogleAnalyticsObject = () => {
	const useGoogleAnalytics = getConfig('useGoogleAnalytics');

	if (!useGoogleAnalytics) return undefined;

	maybeStubGoogleAnalytics();

	const gaName = getGoogleAnalyticsObjectName();

	return window[gaName];
};

let awaitGAReadyPromise;
const awaitGoogleAnalyticsReady = () => {
	if (awaitGAReadyPromise) return awaitGAReadyPromise;

	const ga = getGoogleAnalyticsObject();

	if (!ga) return Promise.resolve(undefined);

	const trackerTimeout = getConfig('googleAnalyticsTrackerTimeout');

	awaitGAReadyPromise = new Promise((resolve, reject) => {
		ga((tracker) => {
			// Force into the next event loop to ensure all commands
			// in the command queue have run before any callbacks
			// waiting for this promise to resolve.
			setTimeout(() => resolve(tracker));
		});

		setTimeout(reject, trackerTimeout);
	}).catch(() => (
		// Fail gracefully in case of timeout
		Promise.resolve(undefined)
	));

	return awaitGAReadyPromise;
};

let getTrackerPromise;
const getTracker = () => {
	if (getTrackerPromise) return getTrackerPromise;

	getTrackerPromise = awaitGoogleAnalyticsReady()
		.then((tracker) => {
			if (tracker) return tracker;

			const ga = getGoogleAnalyticsObject();

			// It's possible (if we're relying on the GA stub)
			// that the tracker had not yet been created when
			// this callback was invoked. It may exist now,
			// however, given that we're in the next event loop.
			if (typeof ga.getAll === 'function') {
				return ga.getAll()[0];
			}

			return undefined;
		});

	return getTrackerPromise;
};

const getClientId = () => (
	getTracker()
		.then(tracker => (
			tracker ? tracker.get('clientId') : undefined
		))
);

const getTrackingId = () => (
	getTracker()
		.then(tracker => (
			tracker ? tracker.get('trackingId') : undefined
		))
);

const sendEvent = ({ category, action, label }) => (
	getTracker()
		.then((tracker) => {
			if (!tracker) return;

			tracker.send('event', category, action, label);
		})
);

export {
	getClientId,
	getTrackingId,
	sendEvent,
};
