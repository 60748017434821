import defaultConfig from './defaults';

let currentConfig = defaultConfig;

const mergeConfig = (newConfig) => {
	currentConfig = {
		...currentConfig,
		...newConfig,
		settings: {
			...currentConfig.settings,
			...newConfig.settings,
		},
	};

	return currentConfig;
};

const getAllConfig = () => currentConfig;

const setConfig = (key, value) => {
	currentConfig[key] = value;
};

const getConfig = key => currentConfig[key];

export {
	mergeConfig,
	getAllConfig,
	setConfig,
	getConfig,
};
