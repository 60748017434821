// TODO
// Request URL: https://api-public.newman.dev.liquidweb.com:20910/billing/payment/profile/hostedFormToken.json
// Status Code: 403 Forbidden
import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.billing.payment.profile.hostedFormToken;

const { actions, reducer, sagas, selectors } = createAPIModule({
	getStateSlice,
	actionType: 'BILLING_PAYMENT_PROFILE_HOSTEDFORMTOKEN',
	method: 'POST',
	url: '/billing/payment/profile/hostedFormToken.json',
});

export { actions, reducer, sagas, selectors };
