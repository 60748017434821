import { spawn } from 'redux-saga/effects';

import loadSagas from './load/loadSagas';
import bandwidthSagas from './bandwidth/bandwidthSagas';
import servicesSagas from './services/servicesSagas';

export default function* monitoringSagas() {
	yield spawn(loadSagas);
	yield spawn(bandwidthSagas);
	yield spawn(servicesSagas);
}
