import icons from './templateIcons';

const manageLevels = {
	linux: {
		'Core-Managed':
			'Includes complete support of the base operating system as well as Apache, Mysql and PHP. Proactive service restoration is provided.',
		'Fully-Managed':
			'Includes a control panel as well as complete support of base operating system and all control panel services. Proactive service restoration is provided.',
		'Self-Managed':
			'Includes support for hardware, network and virtualization layers.',
	},
	windows: {
		'Core-Managed':
			'Includes complete support of the base operating system as well as IIS, MSSQL, asp and asp.NET. Proactive service restoration is provided.',
		'Fully-Managed':
			'Includes Plesk as well as complete support of base operating system and all Plesk services. Proactive service restoration is provided.',
		'Self-Managed':
			'Includes support for hardware, network and virtualization layers.',
	},
};

const linuxOrWindows = (osString) => {
	if (/linux/i.test(osString)) return 'linux';
	if (/windows/i.test(osString)) return 'windows';
	return '';
};

// mock-ups have spaces instead of hyphens, so we change that here.
const supportLevelStringConvert = (supportLevel) =>
	supportLevel?.replace('-', ' ');

const getSupportLevelText = ({ os, supportLevel }) =>
	manageLevels[linuxOrWindows(os)][supportLevel];

// Used to create the display string. Each os is prefixed with 'Linux'; this should not display.
// Also used to fetch the icon.
const osStringConvert = (os) => os.replace('Linux', '');

const getIcon = ({ os, supportLevel }) =>
	supportLevel === 'Self-Managed'
		? icons[osStringConvert(os).toLowerCase()]
		: icons.lw;

const getDescriptionAndIcon = ({ os, supportLevel }) => ({
	description: getSupportLevelText({ supportLevel, os }) || '',
	icon: getIcon({ os, supportLevel }),
});

export {
	osStringConvert,
	supportLevelStringConvert,
	getDescriptionAndIcon,
	getIcon,
};
