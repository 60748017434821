import { combineReducers } from 'redux';
import auth from './auth/authReducer';
import billingAddress from './billingAddress/billingAddressReducer';
import chatpassword from './chatpassword/chatPasswordReducer';
import limits from './limits/limitsReducer';
import settings from './settings/settingsReducer';
import ssh from './ssh/sshReducer';
import user from './user/userReducer';
import { reducer as adCampaigns } from './adCampaignsModule';
import { reducer as details } from './detailsModule';
import { reducer as invoicesAndPaymentsTimeline } from './invoicesAndPaymentsTimelineModule';
import { reducer as signup } from './signupModule';
import { reducer as supportSummary } from './supportSummaryModule';

export default combineReducers({
	adCampaigns,
	auth,
	billingAddress,
	chatpassword,
	details,
	invoicesAndPaymentsTimeline,
	limits,
	settings,
	signup,
	ssh,
	supportSummary,
	user,
});
