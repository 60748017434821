import React from 'react';
import Box from '@material-ui/core/Box';
import LWTypography from 'components/common/LWTypography';

const ClientFileReplication = () => (
	<>
		<Box pb={3}>
			<LWTypography>
				This server is compatible with the File Replication service enabled on
				this project. The server will automatically be assigned to
				&apos;Client&apos; role to communicate with the &apos;Host&apos;.
			</LWTypography>
		</Box>
		<Box pb={2}>
			<LWTypography>
				Once added to the project, this server will be permanently tied to the
				File Replication service. The server cannot be removed from the project
				unless the server is destroyed.
			</LWTypography>
		</Box>
	</>
);

export default ClientFileReplication;
