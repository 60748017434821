const actions = {
	TOGGLE_DIALOG: 'RENEW_DIALOG_TOGGLE_DIALOG',
	toggleDialog: (dialogOpen) => ({
		type: actions.TOGGLE_DIALOG,
		dialogOpen,
	}),
	SET_ERROR: 'RENEW_DIALOG_SET_ERROR',
	setError: (error) => ({
		type: actions.SET_ERROR,
		error,
	}),
	SET_FETCH_AFTER: 'RENEW_DIALOG_SET_FETCH_AFTER',
	setFetchAfter: (toFetchAfter) => ({
		type: actions.SET_FETCH_AFTER,
		toFetchAfter,
	}),
	SET_TITLE: 'RENEW_DIALOG_SET_TITLE',
	setTitle: (title) => ({
		type: actions.SET_TITLE,
		title,
	}),
	SET_PAYLOAD: 'RENEW_DIALOG_SET_PAYLOAD',
	setPayload: (payload) => ({
		type: actions.SET_PAYLOAD,
		payload,
	}),
	SET_PRODUCT_TYPE: 'RENEW_DIALOG_SET_PRODUCT_TYPE',
	setProductType: (productType) => ({
		type: actions.SET_PRODUCT_TYPE,
		productType,
	}),
};

export default actions;
