import { getCookie } from '../util/cookie';

const getInitialExtraDetails = () => {
	const cookieValue = getCookie('LW_COUPON');

	if (!cookieValue) return [];

	const fields = cookieValue.match(/([^|]+)|/);

	if (!fields) return [];

	const couponCode = fields[1];

	if (!couponCode) return [];

	return [
		{
			label: 'SessionCouponCode__c',
			value: couponCode,
			displayToAgent: true,
			transcriptFields: ['SessionCouponCode__c'],
		},
	];
};

export default getInitialExtraDetails;
