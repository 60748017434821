import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';

const getStateSlice = (state) => state.account.auth.issuejwt;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'ACCOUNT_AUTH_ISSUEJWT',
	url: '/account/auth/issuejwt.json',
});

const selectors = {
	getJWT: createSelector(
		defaultSelectors.getData,
		(data) => data && data.get('jwt'),
	),
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };
