import React from 'react';
import { connect } from 'react-redux';
import { selectors as accountDetailsSelectors } from 'modules/api/account/detailsModule';
import PropTypes from 'prop-types';

import Badge from './Badge';

const SecureNotesBadge = ({ count, isLoading, children }) => {
	return (
		<Badge count={count} isLoading={isLoading}>
			{children}
		</Badge>
	);
};

SecureNotesBadge.propTypes = {
	count: PropTypes.string,
};
const mapStateToProps = (state) => ({
	count: accountDetailsSelectors.getSecureNotesCount(state),
	isLoading: accountDetailsSelectors.isLoading(state),
});

export default connect(mapStateToProps)(SecureNotesBadge);
