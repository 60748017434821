// https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie
const getCookie = (name) =>
	decodeURIComponent(
		document.cookie.replace(
			new RegExp(`(?:(?:^|.*;)\\s*${name}\\s*\\=\\s*([^;]*).*$)|^.*$`),
			'$1',
		),
	) || null;

const getCjReferralData = () => {
	const cookie = getCookie('CJPIDLW');
	const eventCookie = getCookie('CJEVENTLW');

	if (!cookie) {
		return null;
	}

	const fields = cookie.match(/pid=([^&]+)&aid=([^&]+)?&sid=([^&]+)?/);

	if (!fields) {
		return null;
	}

	return {
		Network: 'cj',
		PartnerId: fields[1],
		AdId: fields[2],
		SubId: fields[3],
		ClickId: eventCookie,
	};
};

const getLwReferralData = () => {
	const cookie = getCookie('lwReferralData');

	if (!cookie) {
		return null;
	}

	const cookieData = JSON.parse(cookie);

	switch (cookieData.program) {
		case 'impact_radius':
			return {
				Network: cookieData.program,
				PartnerId: cookieData.irpid,
				AdId: cookieData.iradid,
				SubId: cookieData.sharedid,
				ClickId: cookieData.clickid,
			};

		case 'awin':
			return {
				Network: cookieData.program,
				PartnerId: cookieData.awid,
				AdId: cookieData.aid,
				SubId: cookieData.sid,
				ClickId: cookieData.awc,
			};

		default:
			return null;
	}
};

export { getCookie, getCjReferralData, getLwReferralData };
