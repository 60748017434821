import { spawn, call } from 'redux-saga/effects';

import destorySagas from './destroySagas';
import ipSagas from './ip/sagas';
import { sagas as resizeSagas } from './resize';

export default function* sagas() {
	yield spawn(destorySagas);
	yield spawn(ipSagas);
	yield call(resizeSagas);
}
