import { call, put, select, take } from 'redux-saga/effects';
import { replace, goBack } from 'connected-react-router';
import basketActions from 'modules/basket/actions';
import dialogActions from 'modules/dialogs/actions';
import {
	removeUndefinedKeys,
	convertEmptyObjectToUndef,
} from 'utility/tools/objects';

import {
	selectors as assetDetailsSelectors,
	actions as assetDetailsActions,
} from 'modules/api/asset/detailsModule';

import {
	actions as addActions,
	selectors as addSelectors,
} from 'modules/api/basket/item/addModule';

import { selectors as detailsSelectors } from 'modules/api/basket/detailsModule';
import snackbarSaga from 'modules/snackbar/sagas';
import snackbarActions from 'modules/snackbar/snackbarActions';
import productCodeToRegion from 'utility/productCodeToRegion';
import detailsSaga from '../details';

// hanldeAddCloningVps is tested by CloneServerModalContents.test.js
function* handleAddCloningVps({ uniqIdToClone, itemUuid }) {
	let error = false;
	let templateValue = yield select(assetDetailsSelectors.templateValue);
	// If we do not currently have the correct asset loaded or lack a templateValue, call details.
	if (
		yield select(assetDetailsSelectors.uniqId) !== uniqIdToClone ||
			!templateValue
	) {
		yield put(
			assetDetailsActions.fetch({
				uniq_id: uniqIdToClone,
				alsowith: ['featureDetails'],
			}),
		);
		const detailsRes = yield take(
			assetDetailsActions.setType,
			assetDetailsActions.errorType,
		);
		if (detailsRes.type === assetDetailsActions.errorType) error = true;
		else templateValue = yield select(assetDetailsSelectors.templateValue);
	}
	// set the config
	if (!error) {
		yield put(
			basketActions.setItemConfig({
				key: 'Template',
				value: templateValue,
				basketItem: itemUuid,
			}),
		);
	}
	yield call(snackbarSaga, {
		error,
		errorMessage:
			'Something went wrong. Please remove this server from your cart and try again.',
	});
}

function* handleAddItemResponse({ error, quickAdd, addPayload }) {
	if (error) {
		yield put(
			snackbarActions.pushMessage('Failed to add item to the cart', 'error'),
		);
		if (!quickAdd) yield put(goBack()); // Takes them back to the tab in marketplace they were just on.
	} else {
		yield put(basketActions.itemAdded(addPayload));
		const addData = yield select(addSelectors.getNativeData);
		if (!quickAdd) yield put(replace(`/shop/config/${addData?.uuid}`));

		// Special handling for legacy Storm backup plans
		// Eventually, these will be set to "None" by default in the products data, but currently they are not, so we have to force them to "None"
		const backupOption = yield select(addSelectors.lwBackupOption);
		if (backupOption && backupOption.value !== 'None') {
			yield put(
				basketActions.setItemConfig({
					key: 'LiquidWebBackupPlan',
					value: 'None',
					basketItem: addData?.uuid,
				}),
			);
		}
		const uniqIdToClone = yield select(addSelectors.uniqIdToClone);
		if (uniqIdToClone) {
			yield call(handleAddCloningVps, {
				uniqIdToClone,
				itemUuid: addData?.uuid,
			});
		}

		yield call(detailsSaga);
	}
}

function* handleAddItem({
	payload: {
		productCode,
		region = 1,
		quickAdd,
		relatedSubaccnt, // TODO: route this through properties.
		domain, // TODO: route this through properties.
		properties: propertiesArg,
	},
}) {
	yield call(detailsSaga, { init: true });
	const uuid = yield select(detailsSelectors.getBasketUuid);
	// If basket is locked, can't add items, will need to prompt user to see if they want to abandon the cart
	const isLocked = yield select(detailsSelectors.isLocked);
	if (isLocked) {
		yield put(
			dialogActions.open({
				contentKey: 'AbandonCartModalContents',
				contentProps: {
					addItemPayload: {
						productCode,
						region,
						quickAdd,
						relatedSubaccnt,
						domain,
					},
				},
				color: 'danger',
				dismissible: false,
			}),
		);
		return; // regardless of the choice picked, user will either cancel out of this operation, or the add item will be re-trigged on a new basket
	}

	const properties = convertEmptyObjectToUndef(
		removeUndefinedKeys({
			related_subaccnt: relatedSubaccnt,
			domain,
			...propertiesArg,
		}),
	); // Can't pass an empty properties object to the API

	const addPayload = {
		basket: uuid,
		product_code: productCode,
		region: productCodeToRegion({ productCode, region }),
		properties,
		alsowith: ['configs'],
	};

	yield put(addActions.fetch(removeUndefinedKeys(addPayload)));
	const addResult = yield take([addActions.setType, addActions.errorType]);
	const error = addResult?.type === addActions.errorType;
	yield call(handleAddItemResponse, { error, quickAdd, addPayload });
}

export { handleAddItemResponse, handleAddCloningVps };
export default handleAddItem;
