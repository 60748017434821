import React from 'react';
import LWTypography from 'components/common/LWTypography';

const Verbiage = () => (
	<>
		<LWTypography variant="subtitle2" bold paragraph>
			Acronis Options
		</LWTypography>
		<LWTypography paragraph>
			Acronis Cyber Backups provide secure cloud backups with a self-service
			portal for easy backup management and restoration. These backups can be
			stored off-server in the Liquid Web Cloud, or off-site in Acronis Backup
			Cloud.
		</LWTypography>
	</>
);

export default Verbiage;
