import React from 'react';
import PropTypes from 'prop-types';
import ConfirmationDialog from 'components/common/ConfirmationDialog';
import LWTypography from 'components/common/LWTypography';

const DeleteDialog = ({
	clearDelete,
	deleteProject,
	displaySnackbar,
	history,
	dialogProps,
	toggleDialog,
	deleted,
	fetchList,
	isOpen,
}) => {
	const { projectId, projectName, redirect } = dialogProps;
	React.useEffect(() => {
		if (deleted) {
			if (redirect) {
				// redirect
				history.push('/projects');
			} else {
				// fetchProjects
				fetchList();
			}
			displaySnackbar(`Successfully deleted project ${projectName}`);
			clearDelete();
		}
	}, [
		clearDelete,
		deleted,
		displaySnackbar,
		fetchList,
		history,
		projectName,
		redirect,
	]);
	return (
		<ConfirmationDialog
			color="danger"
			open={isOpen}
			onConfirm={() => deleteProject({ projectId })}
			onClose={() => toggleDialog(false)}
			title="Delete Project?"
		>
			<LWTypography>
				Deleting a project{' '}
				<LWTypography inline bold>
					will not
				</LWTypography>{' '}
				delete the assets it contains, only the Project Dashboard your selection
				has generated. Once a project is deleted, it cannot be recovered.
			</LWTypography>
			<br />
			<LWTypography>
				Are you sure you want to delete{' '}
				<LWTypography inline bold>
					{projectName}
				</LWTypography>
				?
			</LWTypography>
		</ConfirmationDialog>
	);
};

DeleteDialog.propTypes = {
	redirect: PropTypes.bool,
};

DeleteDialog.defaultProps = {
	redirect: false,
};

export default DeleteDialog;
