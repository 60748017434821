const actions = {
	NAV_TRACKING_EVENT: 'NAV_TRACKING_EVENT',
	trackNav: ({ path, displayName, assetId = null, isAssetDomain = false }) => ({
		type: actions.NAV_TRACKING_EVENT,
		payload: {
			path,
			displayName,
			assetId,
			isAssetDomain,
		},
	}),
};

export default actions;
