import React from 'react';
import { connect } from 'react-redux';
import asyncComponent from 'components/AsyncComponent';
import { enabled as maintenanceModeEnabledSelector } from 'modules/maintenanceMode/selectors';

const MaintenancePage = asyncComponent(() =>
	import('containers/pages/error/Maintenance'),
);

const MaintenanceModeGate = ({ maintenanceModeEnabled, children }) =>
	maintenanceModeEnabled ? <MaintenancePage /> : children;

const mapStateToProps = (state) => ({
	maintenanceModeEnabled: maintenanceModeEnabledSelector(state),
});

export { MaintenanceModeGate };

export default connect(mapStateToProps)(MaintenanceModeGate);
