import produce from 'immer';
import actions from './actions';

const initialState = {
	dialogOpen: false,
	title: null,
	error: null,
	productType: null,
	payload: null,
	toFetchAfter: null,
};

/* eslint-disable default-case, no-param-reassign */
const reducer = (state = initialState, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case actions.SET_PAYLOAD:
				draft.payload = action.payload;
				break;
			case actions.SET_FETCH_AFTER:
				draft.toFetchAfter = action.toFetchAfter;
				break;
			case actions.SET_PRODUCT_TYPE:
				draft.productType = action.productType;
				break;
			case actions.SET_TITLE:
				draft.title = action.title;
				break;
			case actions.SET_ERROR:
				draft.error = action.error;
				break;
			case actions.TOGGLE_DIALOG:
				if (action.dialogOpen) {
					draft.error = null;
					draft.dialogOpen = action.dialogOpen;
				}

				draft.dialogOpen = action.dialogOpen;
				break;
		}
	});

export default reducer;
