import { put, takeLatest } from 'redux-saga/effects';
import { actions as extraOptionsActions } from 'modules/api/basket/item/extraOptionsModule';
import basketActions from '../../actions';

function* fetchExtraOptions({ payload }) {
	const { uuid } = payload;
	yield put(
		extraOptionsActions.fetch({
			uuid,
		}),
	);
}

export { fetchExtraOptions };

export default function* extraOptionsRoot() {
	yield takeLatest(
		basketActions.BASKET_ITEM_FETCH_EXTRA_OPTIONS,
		fetchExtraOptions,
	);
}
