import { spawn } from 'redux-saga/effects';

import { sagas as listSagas } from './listModule';
import { sagas as updateSagas } from './updateModule';
import { sagas as validateAddressSagas } from './validateAddressModule';
import { sagas as taxExemptReasonsSagas } from './taxExemptReasonsModule';

export default function* contactSagas() {
	yield spawn(updateSagas);
	yield spawn(listSagas);
	yield spawn(validateAddressSagas);
	yield spawn(taxExemptReasonsSagas);
}
