import produce from 'immer';
import actions from './actions';

const initialState = {
	apiParams: {},
	hostname: null,
	isOpen: false,
	type: null,
};

/* eslint-disable default-case, no-param-reassign */
const reducer = (state = initialState, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case actions.OPEN_DIALOG:
				draft.apiParams = action.payload.apiParams;
				draft.hostname = action.payload.hostname;
				draft.isOpen = true;
				draft.type = action.payload.type;
				break;
			case actions.CLOSE_DIALOG:
				draft.apiParams = {};
				draft.hostname = null;
				draft.isOpen = false;
				draft.type = null;
				break;
		}
	});

export default reducer;
