const actions = {
	SERVER_REBOOT_OPEN_DIALOG: 'SERVER_REBOOT_OPEN_DIALOG',
	openDialog: ({ domain, uniqId, canAutoReboot = false }) => ({
		type: actions.SERVER_REBOOT_OPEN_DIALOG,
		payload: {
			domain,
			uniqId,
			canAutoReboot,
		},
	}),

	SERVER_REBOOT_CLOSE_DIALOG: 'SERVER_REBOOT_CLOSE_DIALOG',
	closeDialog: () => ({
		type: actions.SERVER_REBOOT_CLOSE_DIALOG,
	}),

	SERVER_REBOOT_INVOKE_REBOOT: 'SERVER_REBOOT_INVOKE_REBOOT',
	invokeReboot: ({ uniqId, forceReboot = false, deferSeconds = null }) => ({
		type: actions.SERVER_REBOOT_INVOKE_REBOOT,
		payload: {
			uniqId,
			forceReboot,
			deferSeconds,
		},
	}),
};

export default actions;
