import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes } from 'styled-components';
import { ReactComponent as StatusSpinnerIcon } from 'images/status-icon-spinner.svg';
import useColor from 'utility/effects/useColor';

const rotate = keyframes`
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
`;

const FStatusSpinnerIcon = ({
	color,
	backgroundColor,
	centerMatchBackground,
	...rest
}) => {
	return <StatusSpinnerIcon {...rest} />;
};

const SStatusSpinner = styled(FStatusSpinnerIcon)`
	width: 1.5rem;
	height: 1.5rem;
	animation: ${rotate} 1s linear infinite;
	.outer {
		fill: ${({ backgroundColor }) => backgroundColor};
	}
	.spinner {
		fill: none;
		stroke-linecap: round;
		stroke-dasharray: 10 30;
		stroke-dashoffset: 20;
	}
	.center {
		fill: ${({ color, backgroundColor, centerMatchBackground }) =>
			centerMatchBackground ? backgroundColor : color};
	}
	.start {
		stop-color: ${({ color }) => color};
	}
	.stop {
		stop-color: ${({ backgroundColor }) => backgroundColor};
	}
`;

const StatusSpinner = ({
	color = 'palette.primary.dark',
	backgroundColor = 'palette.primary.light',
	centerMatchBackground = true,
}) => {
	const spinnerColor = useColor(color);
	const spinnerBackgroundColor = useColor(backgroundColor);

	return (
		<SStatusSpinner
			color={spinnerColor}
			backgroundColor={spinnerBackgroundColor}
			centerMatchBackground={centerMatchBackground}
		/>
	);
};

StatusSpinner.propTypes = {
	/** Can be any CSS color, or a theme property */
	color: PropTypes.string,
	/** Can be any CSS color, or a theme property */
	backgroundColor: PropTypes.string,
	/** Determines if the center of the spinner matches the background color or not */
	centerMatchBackground: PropTypes.bool,
};

export default StatusSpinner;
