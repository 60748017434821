import { getCookie } from '../util/cookie';

const cookieToSalesforceFieldMap = {
	_utma: 'Google_Utma__c',
	_utmc: 'Google_Utmc__c',
	_utmz: 'Google_Utmz__c',
	utm_source: 'Google_Utm_Source__c',
	utm_medium: 'Google_Utm_Medium__c',
	utm_channel: 'Google_Utm_Channel__c',
	utm_campaign: 'Google_Utm_Campaign__c',
	gclid: 'Google_Gclid__c',
};

const getInitialExtraDetails = () => (
	Object.entries(cookieToSalesforceFieldMap)
		.map(([cookieName, fieldName]) => (
			{
				label: fieldName,
				value: getCookie(cookieName),
				displayToAgent: true,
				transcriptFields: [fieldName],
			}
		))
		.filter(detail => detail.value !== null)
);

export default getInitialExtraDetails;
