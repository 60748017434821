import actions from './errorActions';

const initialState = [];

const errorReducer = (state = initialState, action) => {
	switch (action.type) {
		case actions.pushType:
			return [
				...state.slice(),
				{
					message: action.message,
					verb: action.verb,
					color: action.color,
				},
			];
		case actions.shiftType:
			return state.slice(0, state.errors.length - 1);
		case actions.removeType:
			return [
				...state.slice(0, action.index),
				...state.slice(action.index + 1),
			];
		default:
			return state;
	}
};

export default errorReducer;
