import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import LWButton from 'components/common/LWButton';
import LWTypography from 'components/common/LWTypography';
import { dateTimeLongWithTimeZone } from 'utility/constants/dateTime';

const SContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`;

const RebootButton = ({
	serverRebootResult,
	canAutoReboot,
	deferUntil,
	onClick,
	className,
	disabled,
}) => {
	const { isLoading } = serverRebootResult;

	let buttonText;
	switch (true) {
		case canAutoReboot && !!deferUntil:
			buttonText = `Reboot at ${deferUntil.format(dateTimeLongWithTimeZone)}`;
			break;
		case canAutoReboot:
			buttonText = 'Reboot Now';
			break;
		default:
			buttonText = 'Request a Reboot';
	}

	return (
		<SContainer className={className}>
			<LWButton
				variant="contained"
				isLoading={isLoading}
				onClick={onClick}
				disabled={disabled}
				color="secondary"
			>
				{buttonText}
			</LWButton>

			{canAutoReboot && deferUntil && (
				<Box mt={1} data-testid="RebootButton__TimeFromNow">
					<LWTypography>{deferUntil.fromNow()}</LWTypography>
				</Box>
			)}
		</SContainer>
	);
};

RebootButton.propTypes = {
	serverRebootResult: PropTypes.object.isRequired,
	canAutoReboot: PropTypes.bool.isRequired,
	deferUntil: PropTypes.instanceOf(moment),
	onClick: PropTypes.func.isRequired,
	className: PropTypes.string,
};

RebootButton.defaultProps = {
	deferUntil: null,
	className: null,
};

export default RebootButton;
