import { createSelector } from 'reselect';

const getStateSlice = (state) => state.taskScheduler;

const getApiParams = createSelector(
	getStateSlice,
	(taskScheduler) => taskScheduler.apiParams,
);

const getHostname = createSelector(
	getStateSlice,
	(taskScheduler) => taskScheduler.hostname,
);

const getIsOpen = createSelector(
	getStateSlice,
	(taskScheduler) => taskScheduler.isOpen,
);

const getType = createSelector(
	getStateSlice,
	(taskScheduler) => taskScheduler.type,
);

export { getStateSlice, getApiParams, getHostname, getIsOpen, getType };
