import produce from 'immer';
import actions from './actions';

const initialState = {
	cause: null,
};

/* eslint-disable default-case, no-param-reassign */
const reducer = (state = initialState, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case actions.DISALLOWED_USER_SET_CAUSE:
				draft.cause = action.payload.cause;
				break;
		}
	});

export default reducer;
