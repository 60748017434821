import { combineReducers } from 'redux';

import servicesReducer from './services/servicesReducer';
import { reducer as related } from './relatedModule';
import { reducer as list } from './listModule';

export default combineReducers({
	related,
	list,
	services: servicesReducer,
});
