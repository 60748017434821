import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import Box from '@material-ui/core/Box';
import WarningIcon from '@material-ui/icons/WarningRounded';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import LWDialog from 'components/common/LWDialog';
import LabeledIcon from 'components/atoms/LabeledIcon';
import LabeledText from 'components/atoms/LabeledText';
import ConfirmCancel from 'components/common/Dialogs/ConfirmCancel';
import taskSchedulerActions from 'modules/server/taskScheduler/actions';
import {
	getApiParams,
	getHostname,
	getIsOpen,
	getType,
} from 'modules/server/taskScheduler/selectors';
import supportedTasks from 'modules/server/taskScheduler/supportedTasks';
import CurrentTaskList from './CurrentTaskList';

const getErrorMessage = ({ isDateInPast, isDateAfter7Days }) => {
	if (isDateInPast) return 'Set time can not be in the past';
	if (isDateAfter7Days)
		return 'A resize cannot be scheduled further than 7 days from now';
	return null;
};

const TaskSchedulerDialog = () => {
	const dispatch = useDispatch();

	const params = useSelector(getApiParams);
	const hostname = useSelector(getHostname);
	const isOpen = useSelector(getIsOpen);
	const type = useSelector(getType);

	const { uniq_id: uniqId } = params;

	// KeyboardDateTimePicker stores this as a Moment.js object
	const [dateTimeObject, setDateTimeObject] = useState(null);
	const isDateInPast = !!dateTimeObject?.isBefore();
	const sevenDaysFromNow = moment().add(7, 'days');
	// Only restrict to 7 days if type is resize
	const isDateAfter7Days =
		type === 'resize' && dateTimeObject?.isAfter(sevenDaysFromNow);

	// reset this to null each time dialog opens
	useEffect(() => {
		setDateTimeObject(null);
	}, [isOpen]);

	const { label: typeLabel, selectors: taskApiSelector } =
		supportedTasks[type] || {};

	const isLoading = useSelector(taskApiSelector?.isLoading || (() => false));
	const closeDialog = () => dispatch(taskSchedulerActions.closeDialog());

	return (
		<LWDialog
			open={isOpen}
			title="Task Scheduler"
			onClose={closeDialog}
			onCancel={closeDialog}
			fullWidth
		>
			<Box display="flex" flexDirection="column">
				{hostname && (
					<Box mb={2}>
						<LabeledText
							inline
							variant="inline"
							label="Server"
							text={hostname}
						/>
					</Box>
				)}
				<Box mb={3}>
					<LabeledText inline variant="inline" label="Type" text={typeLabel} />
				</Box>
				<KeyboardDateTimePicker
					label="Date & Time"
					disablePast
					autoOk
					variant="inline"
					inputVariant="outlined"
					value={dateTimeObject}
					format="MM/DD/YYYY hh:mm a Z"
					onChange={setDateTimeObject}
					emptyLabel="Immediately"
					error={isDateInPast || isDateAfter7Days}
					helperText={getErrorMessage({ isDateInPast, isDateAfter7Days })}
				/>
				{uniqId && <CurrentTaskList uniqId={uniqId} />}
			</Box>
			{type === 'resize' && (
				<Box mt={3} mb={4}>
					<LabeledIcon
						text="Server will perform a reboot to complete the resize."
						icon={<WarningIcon />}
						color="error.main"
					/>
				</Box>
			)}
			<ConfirmCancel
				confirmText={dateTimeObject ? `Schedule ${typeLabel}` : typeLabel}
				cancel={closeDialog}
				isLoading={isLoading}
				disabled={isDateInPast || isDateAfter7Days}
				confirm={() =>
					dispatch(
						taskSchedulerActions.schedule({
							params,
							dateTime: dateTimeObject?.format(),
							type,
						}),
					)
				}
			/>
		</LWDialog>
	);
};

export default TaskSchedulerDialog;
