import { spawn } from 'redux-saga/effects';

import { sagas as deleteSagas } from './deleteModule';
import { sagas as lookup } from './lookupModule';
import { sagas as set } from './setModule';

export default function* sagas() {
	yield spawn(deleteSagas);
	yield spawn(lookup);
	yield spawn(set);
}
