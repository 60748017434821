import axios from 'axios';
import { select } from 'redux-saga/effects';

export default function* axiosCreate() {
	// avoid use of selectors to prevent import loop
	const openidHost = yield select((state) =>
		state.appConfig.getIn(['data', 'openId', 'authority']),
	);
	const baseURL = `${openidHost}/`;
	return axios.create({
		baseURL,
		timeout: 60000,
		withCredentials: true,
	});
}
