const getRegionLabel = (country) => {
	switch (country) {
		case 'United States':
			return 'State';
		case 'Canada':
			return 'Province / Territory';
		case 'United Kingdom':
			return 'County';
		case 'India':
			return 'State';
		case 'Australia':
			return 'State / Territory';
		default:
			return 'State / Province / Region';
	}
};

const getCityLabel = (country) => {
	switch (country) {
		case 'United States':
			return 'City';
		case 'Canada':
			return 'City';
		case 'Australia':
			return 'City';
		case 'United Kingdom':
			return 'Post Town';
		case 'India':
			return 'City / District';
		default:
			return 'Town / City';
	}
};

const getPostalCodeLabel = (country) => {
	switch (country) {
		case 'United States':
			return 'Zip Code';
		case 'United Kingdom':
			return 'Postcode';
		case 'India':
			return 'Pin Code';
		default:
			return 'Postal Code';
	}
};

const getTaxIdLabel = (country) => {
	switch (country) {
		case 'United States':
			return 'EIN';
		case 'Canada':
			return 'Business Number';
		case 'Australia':
			return 'ABN';
		case 'United Kingdom':
			return 'VAT Reg No';
		case 'India':
			return 'GST/PAN';
		default:
			return 'Business / Tax ID';
	}
};

const getTaxIdPrefix = (country) => {
	switch (country) {
		case 'AU':
			return 'ABN';
		case 'GR':
			return 'EL';
		case 'IS':
			return 'VSK';
		case 'GB':
			return 'GB';
		default:
			return null;
	}
};

export {
	getRegionLabel,
	getCityLabel,
	getPostalCodeLabel,
	getTaxIdLabel,
	getTaxIdPrefix,
};
