import React from 'react';
import PropTypes from 'prop-types';
import ProductOption from 'components/ProductOption';
import LWTypography from 'components/common/LWTypography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import optionSort from './productOptionSortValue';

// TODO: accept a `size` prop that affects the MUI Grid breakpoints.
// This would be nice to have: https://github.com/mui-org/material-ui/issues/8823

// TODO: Give this grid the ability to be 5 across for the small size.

const ProductOptionSet = ({
	action,
	actionText,
	handleSelect,
	selected = '',
	disabled = false,
	current = '',
	options = [],
	currentText,
	isLoading,
	input,
	variant = 'portrait',
	sort = true,
	container,
}) => {
	if (!options.length && isLoading) {
		return (
			<Box height={236} p={2}>
				<LWTypography isLoading={isLoading} SkeletonProps={{ width: '75%' }}>
					No Product Options Found
				</LWTypography>
			</Box>
		);
	}

	// TODO?: use a debouncer to create animation around disappearing/reappearing tiles?
	return (
		<Grid
			container
			spacing={container === 'List' ? 0 : 3}
			{...(container === 'List' ? { component: Paper } : {})}
		>
			{options
				.sort((a, b) => (sort ? optionSort(a) - optionSort(b) : 1))
				.map((option) => {
					const { value, isDisabled } = option;

					const isSelected = input?.value
						? value === input.value?.value
						: value === selected;
					const isCurrent = value === current;
					return (
						<Grid
							key={value}
							item
							xs={12}
							{...(variant === 'portrait' ? { sm: 6, md: 4, lg: 3 } : {})}
						>
							<ProductOption
								handleSelect={(e) => {
									if (input?.onChange) input.onChange(e);
									if (handleSelect) handleSelect(e);
								}}
								isDisabled={isLoading || isDisabled || disabled}
								isLoading={isLoading}
								isCurrent={isCurrent}
								isSelected={isSelected}
								action={action}
								actionText={actionText}
								currentText={currentText}
								{...option}
								container={container}
								variant={variant}
							/>
						</Grid>
					);
				})}
		</Grid>
	);
};

ProductOptionSet.propTypes = {
	/** Use this to set the value in the parent that is passed to an api. */
	handleSelect: PropTypes.func,
	/** See ProductOption documentation */
	options: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
				.isRequired,
			price: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
				.isRequired,
			displayValue: PropTypes.string,
			description: PropTypes.string,
			isRecommended: PropTypes.bool,
			isDisabled: PropTypes.bool,
			isDisabledTooltip: PropTypes.string,
			title: PropTypes.string,
			listItems: PropTypes.arrayOf(
				PropTypes.shape({
					key: PropTypes.string.isRequired,
					value: PropTypes.string.isRequired,
					toolTipText: PropTypes.string,
				}),
			),
		}),
	),
	/** The value active on the account. If an option's value matches this, the `currentText` will be displayed. */
	current: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	/** The set value used to determine which ProductOption is selected. */
	selected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	/** Defaults to 'Current Plan' in Production option. This is here for customizing. It is displayed when a option's value matches `current` */
	currentText: PropTypes.string,
	/** Sometimes (i.e. config screens) clicking a tile triggers an api call. Use this to control the load state */
	isLoading: PropTypes.bool,
	/** landscape or portrait. Defaults to portrait */
	variant: PropTypes.oneOf(['portrait', 'landscape']),
	/** execute this when action button is clicked */
	action: PropTypes.func,
	/** Display this text in the action button */
	actionText: PropTypes.string,
	/**  Controls styling and DOM composition of container elements */
	container: PropTypes.oneOf(['Paper', 'List']),
};

export default ProductOptionSet;
