import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { connect } from 'react-redux';
import compose from 'utility/compose';
import {
	givenName as givenNameSelector,
	familyName as familyNameSelector,
	email as emailSelector,
} from 'modules/auth/oidcSelectors';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { required, email as emailValidator } from 'redux-form-validators';
import { phoneNumber } from 'utility/form/validators';
import LWCard, { types as LWCARD_TYPES } from 'components/common/LWCard';
import { renderTextField } from 'utility/form/renderers';

const formName = 'AccountCreateContactForm';
const formSelector = formValueSelector(formName);

// Grid does not do different spacings for x and y :(
const p = { px: 1, py: 2 };

const AccountCreateContactForm = () => {
	return (
		<LWCard title="Primary Contact Info" type={LWCARD_TYPES.light}>
			<form>
				<Grid container>
					{/* makes the whole form take up 2/3s or block */}
					<Grid item xs={12} md={8}>
						<Grid container>
							<Grid item xs={12} sm={6}>
								<Box {...p}>
									<Field
										aria-label="First Name"
										name="firstname"
										label="First Name"
										component={renderTextField}
										validate={[required()]}
										variant="outlined"
									/>
								</Box>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Box {...p}>
									<Field
										aria-label="Last Name"
										name="lastname"
										label="Last Name"
										component={renderTextField}
										validate={[required()]}
										variant="outlined"
									/>
								</Box>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Box {...p}>
									<Field
										aria-label="Email"
										name="email"
										label="Email"
										component={renderTextField}
										validate={[required(), emailValidator()]}
										variant="outlined"
									/>
								</Box>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Box {...p}>
									<Field
										aria-label="Phone Number"
										name="phone"
										label="Phone Number"
										component={renderTextField}
										validate={[required(), phoneNumber()]}
										variant="outlined"
									/>
								</Box>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Box {...p}>
									<Field
										aria-label="Organization"
										name="organization"
										label="Organization"
										component={renderTextField}
										variant="outlined"
									/>
								</Box>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</form>
		</LWCard>
	);
};

AccountCreateContactForm.propTypes = {
	// eslint-disable-next-line react/no-unused-prop-types
	initialValues: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
	initialValues: {
		firstname: givenNameSelector(state),
		lastname: familyNameSelector(state),
		email: emailSelector(state),
	},
});

export { AccountCreateContactForm, formSelector, formName };
export default compose(
	connect(mapStateToProps),
	reduxForm({
		form: formName,
		enableReinitialize: true,
	}),
)(AccountCreateContactForm);
