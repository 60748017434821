import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';
import { List as ImmutableList } from 'immutable';

const getStateSlice = (state) => state.network.firewall.rules;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'NETWORK_FIREWALL_RULES',
	method: 'POST',
	url: '/network/firewall/rules.json',
});

const selectors = {
	rules: createSelector(
		getStateSlice,
		(slice) => slice.getIn(['data', 'rules'], ImmutableList()),
	),
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };
