import { defaultData, defaultParams } from 'modules/gtm/defaults';

import baskets from './events/baskets';

// https://rangle.gitbook.io/redux-beacon/index/google-tag-manager#event-definitions

const eventsMap = {
	'@@router/LOCATION_CHANGE': ({ payload }) => {
		if (payload.isFirstRendering) return null; // on initial app load, this is called twice, first time this is true;
		return {
			...defaultParams,
			event: 'lw.pageview',
			data: defaultData,
		};
	},
	ANALYTICS_EVENT: ({ payload }) => {
		return {
			...defaultParams,
			event: 'lw.structured',
			data: { ...defaultData, ...payload },
		};
	},
	...baskets,
};

export default eventsMap;
