import { spawn } from 'redux-saga/effects';

import { sagas as create } from './createModule';
import { sagas as details } from './detailsModule';
import { sagas as list } from './listModule';
import { sagas as update } from './updateModule';

export default function* sagas() {
	yield spawn(create);
	yield spawn(details);
	yield spawn(list);
	yield spawn(update);
}
