import { put, takeLatest, select, take } from 'redux-saga/effects';
import { actions as setExpireActions } from 'modules/api/basket/setExpireModule';
import {
	actions as detailsActions,
	selectors as detailsSelectors,
} from 'modules/api/basket/detailsModule';
import _ from 'lodash';
import basketActions from '../../actions';

function* setExpireSaga({ date }) {
	const uuid = yield select(detailsSelectors.getBasketUuid);
	yield put(
		setExpireActions.fetch({
			uuid,
			date,
		}),
	);
	const oldBasketDetails = yield select(detailsSelectors.getNativeData);
	const returnedData = (yield take(setExpireActions.setType))?.payload;
	yield put(detailsActions.set(_.merge(oldBasketDetails, returnedData)));
}

export { setExpireSaga };

export default function* setExpireRoot() {
	yield takeLatest(basketActions.BASKET_SET_EXPIRE, setExpireSaga);
}
