import { spawn } from 'redux-saga/effects';
import { sagas as add } from './addModule';
import { sagas as listPublic } from './listPublicModule';
import { sagas as remove } from './removeModule';
import { sagas as request } from './requestModule';

export default function* ipSagas() {
	yield spawn(add);
	yield spawn(listPublic);
	yield spawn(remove);
	yield spawn(request);
}
