import { put, take, select, call, takeLatest, delay } from 'redux-saga/effects';
import {
	actions as itemAssertPropertiesActions,
	selectors as itemAssertPropertiesSelectors,
} from 'modules/api/basket/item/assertPropertiesModule';
import {
	selectors as itemDetailsSelectors,
	actions as itemDetailsActions,
} from 'modules/api/basket/item/detailsModule';
import dialogActions from 'modules/dialogs/actions';
import snackbarSaga from 'modules/snackbar/sagas';
import { selectors as zoneListSelectors } from 'modules/api/network/zone/listModule';
import { actions as swapActions } from 'modules/api/basket/item/swapModule';
import { actions as configActions } from 'modules/basket/productConfig';
import privateParentSelectors from 'modules/api/basket/item/detailsModule/privateParentSelectors';
import { resetItemConfigSaga } from '../resetItemConfig/resetItemConfig';
import basketActions from '../../actions';

function* handleAssertPropertiesRes() {
	const res = yield take([
		itemAssertPropertiesActions.setType,
		itemAssertPropertiesActions.errorType,
	]);
	const error = res.type === itemAssertPropertiesActions.errorType;
	yield call(snackbarSaga, {
		error,
		errorMessage: yield select(itemAssertPropertiesSelectors.getErrorString),
	});
	return { res, error };
}

function* itemAssertProperties({ properties, delayed = false, uuid: uuidArg }) {
	if (delayed) yield delay(2000);

	let itemDetails = yield select(itemDetailsSelectors.getNativeData);
	const uuid = uuidArg || itemDetails?.uuid;
	yield put(
		itemAssertPropertiesActions.fetch({
			properties,
			uuid,
		}),
	);
	const { res, error } = yield call(handleAssertPropertiesRes);
	if (!error) {
		yield put(dialogActions.close()); // TODO: find out what needs this and move it there.
		itemDetails = yield select(itemDetailsSelectors.getNativeData);
		// Only push this in if the loaded item is the one with properties being asserted.
		if (itemDetails?.uuid === uuid) {
			yield put(
				itemDetailsActions.set({
					...itemDetails,
					...res?.payload,
				}),
			);
		}
	} else {
		// Refresh basket/item/details to be safe.
		yield put(basketActions.fetchItemDetails({ uuid }));
	}
	return !error;
}

// New zone is in [basketItem].properties.zone.
function* changeZones({ zone, uuid }) {
	yield put(dialogActions.close());
	const itemRegion = yield select(itemDetailsSelectors.region);
	const zoneList = yield select(zoneListSelectors.items);
	const zoneRegion = zoneList.find(
		({ id }) => id.toString() === zone.toString(),
	).region.id;
	let swapFailure = false; // if we do a swap, and it fails, we'll use this to stop everything.
	let didSwap = false;
	let newUuid; // If undefined by the time we're done checking swap stuff, we'll use the one that was passed in.
	if (zoneRegion !== itemRegion) {
		didSwap = true;
		yield put(
			basketActions.itemChangeRegion({
				region: zoneRegion,
				uuid,
			}),
		);
		const { type, payload } = yield take([
			swapActions.setType,
			swapActions.errorType,
		]);
		swapFailure = type === swapActions.errorType;
		newUuid = payload?.uuid;
	}
	let assertFailure = swapFailure; // if we do an assert, and it fails, we'll use this to stop the reset.
	if (!swapFailure) {
		assertFailure = !(yield call(itemAssertProperties, {
			properties: { zone },
			uuid: newUuid || uuid,
		}));
	}
	// if a swap happened, the item is brand new and there's no need to reset
	if (!assertFailure && !didSwap)
		yield call(resetItemConfigSaga, { payload: { itemUuid: uuid } });
}

function* setPrivateParent({ deployOnto, noAssert }) {
	// No-op is usually needed for initing a new product. Removing this will cause an infinite loop.
	if (noAssert) return;
	const { parent } = (yield select(itemDetailsSelectors.properties)) || {};
	if (parent !== deployOnto) {
		yield put(basketActions.setItemConfig({ key: 'ConfigId', value: 0 }));

		// defaulting to 1's just in case initializing didn't go well.
		const initCpu = (yield select(privateParentSelectors.initialCpu)) || 1;
		const initMemory = (yield select(privateParentSelectors.initialRam)) || 1;
		const initDisk = (yield select(privateParentSelectors.initialDisk)) || 1;

		yield call(itemAssertProperties, {
			properties: {
				parent: deployOnto,
				vcpu: initCpu,
				memory: initMemory,
				diskspace: initDisk,
			},
		});
	}
}

export {
	itemAssertProperties,
	changeZones,
	handleAssertPropertiesRes,
	setPrivateParent,
};
export default function* itemModifyRoot() {
	yield takeLatest(
		[basketActions.BASKET_ITEM_ASSERT_PROPERTIES],
		itemAssertProperties,
	);
	yield takeLatest([basketActions.BASKET_ITEM_ASSERT_ZONE], changeZones);
	yield takeLatest([configActions.SET_PRIVATE_PARENT], setPrivateParent);
}
