import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ImmutablePropTypes from 'react-immutable-proptypes';
import compose from 'utility/compose';
import { useHistory } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import StarIcon from '@material-ui/icons/Star';
import StarEmptyIcon from '@material-ui/icons/StarBorder';
import LWDialog from 'components/common/LWDialog';
import LWTypography from 'components/common/LWTypography';
import FavoritesToggle from 'components/structural/FavoritesToggle';
import {
	actions as listForKeyActions,
	selectors as listForKeySelectors,
	moduleKeys,
} from 'modules/api/account/user/stateData/listForKeyModule';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Tooltip from '@material-ui/core/Tooltip';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import { getIconFromPath } from 'utility/constants/pathIcons';
import LWLink from 'components/common/LWLink';

const styles = (theme) => ({
	root: {},
	icon: {
		color: theme.palette.common.white1,
	},
	starEmptyIcon: {
		float: 'left',
		marginRight: '1em',
		color: theme.palette.common.grey4,
	},
});

const FavoritesList = ({
	classes,
	favoritesData,
	isLoading,
	fetchFavorites,
}) => {
	const history = useHistory();

	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [localFavoritesData, setLocalFavoritesData] = useState(null);
	const [hasFetched, setHasFetched] = useState(false);
	useEffect(() => {
		if (isDialogOpen) {
			fetchFavorites();
			setHasFetched(true);
		}
	}, [fetchFavorites, isDialogOpen]);

	// We dont want to change the list as its updated
	// We want to get a snapshot of the favorites when it opens, and keep it till it closes
	useEffect(() => {
		if (
			isDialogOpen && // need this to prevent timing bug
			!isLoading && // dont set before above fetch finishes
			hasFetched && // if we already have data dont set on first run
			!localFavoritesData // dont reset after first setting
		) {
			setLocalFavoritesData(favoritesData);
		}
	}, [favoritesData, hasFetched, isDialogOpen, isLoading, localFavoritesData]);
	const favoritesList = localFavoritesData && localFavoritesData.get('data');

	const closeDialog = () => {
		setIsDialogOpen(false);
		setLocalFavoritesData(null);
		setHasFetched(false);
	};
	return (
		<span className={classes.root}>
			<Tooltip title="Manage my favorites">
				<IconButton
					className={classes.icon}
					aria-label="Favorites"
					onClick={() => setIsDialogOpen(true)}
				>
					<StarIcon />
				</IconButton>
			</Tooltip>
			<LWDialog
				title="Favorites"
				onClose={closeDialog}
				open={isDialogOpen}
				isLoading={!localFavoritesData && isLoading}
				maxWidth="md"
				fullWidth
			>
				{favoritesList && favoritesList.isEmpty() && (
					<>
						<StarEmptyIcon fontSize="large" className={classes.starEmptyIcon} />
						<LWTypography variant="body1">
							{'You don’t have any favorites.'}
						</LWTypography>
						<LWTypography variant="body1">
							{
								'Click the star next to a page title to add it to your favorites.'
							}
						</LWTypography>
					</>
				)}
				<List>
					{favoritesList &&
						favoritesList.map((favorite, index) => {
							if (!favorite.get('path') || !favorite.get('displayName'))
								return null;
							const Icon = getIconFromPath(favorite.get('path')) || null;
							return (
								<React.Fragment key={favorite.get('path')}>
									<ListItem>
										<ListItemIcon>{Icon && <Icon />}</ListItemIcon>
										<ListItemText
											primary={
												<LWLink
													onClick={() => {
														closeDialog();
														history.push(favorite.get('path'));
													}}
												>
													{favorite.get('displayName')}
												</LWLink>
											}
											secondary={favorite.get('path')}
										/>
										<ListItemSecondaryAction>
											<FavoritesToggle
												displayName={favorite.get('displayName')}
												path={favorite.get('path')}
											/>
										</ListItemSecondaryAction>
									</ListItem>
									{index !== favoritesList.size - 1 ? (
										<Divider key={Math.random().toString()} />
									) : null}
								</React.Fragment>
							);
						})}
				</List>
			</LWDialog>
		</span>
	);
};

FavoritesList.propTypes = {
	classes: PropTypes.object.isRequired,
	favoritesData: ImmutablePropTypes.map,
	isLoading: PropTypes.bool.isRequired,

	fetchFavorites: PropTypes.func.isRequired,
};

FavoritesList.defaultProps = {
	favoritesData: null,
};

const mapStateToProps = (state) => ({
	favoritesData: listForKeySelectors.favoritesSelectors.getData(state),
	isLoading: listForKeySelectors.favoritesSelectors.isLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
	fetchFavorites: () =>
		dispatch(
			listForKeyActions.fetch(
				{
					key: moduleKeys.FAVORITES,
				},
				moduleKeys.FAVORITES,
			),
		),
});
export { FavoritesList };
export default compose(
	withStyles(styles),
	connect(
		mapStateToProps,
		mapDispatchToProps,
	),
)(FavoritesList);
