import { spawn } from 'redux-saga/effects';

import { sagas as createSagas } from './createModule';
import { sagas as listSagas } from './listModule';
import { sagas as readSagas } from './readModule';

export default function* sagas() {
	yield spawn(createSagas);
	yield spawn(listSagas);
	yield spawn(readSagas);
}
