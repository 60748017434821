import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.basketApi.updateDiscount;

const { actions, reducer, sagas, selectors } = createAPIModule({
	getStateSlice,
	actionType: 'BASKET_UPDATE_DISCOUNT',
	method: 'POST',
	url: '/basket/updateDiscount.json',
});

export { actions, reducer, sagas, selectors };
