const data = {
	'4679032': {
		broadcast: '67.43.15.63',
		gateway: '67.43.15.1',
		id: 4679032,
		ip: '67.43.15.30',
		netmask: '255.255.255.192',
		network: '67.43.15.30/32',
	},
	'4952507': {
		broadcast: '67.43.15.63',
		gateway: '67.43.15.1',
		id: 4952507,
		ip: '67.43.15.43',
		netmask: '255.255.255.192',
		network: '67.43.15.43/32',
	},
	'4994391': {
		broadcast: '67.43.15.63',
		gateway: '67.43.15.1',
		id: 4994391,
		ip: '67.43.15.54',
		netmask: '255.255.255.192',
		network: '67.43.15.54/32',
	},
	'4942561': {
		broadcast: '67.43.15.63',
		gateway: '67.43.15.1',
		id: 4942561,
		ip: '67.43.15.55',
		netmask: '255.255.255.192',
		network: '67.43.15.55/32',
	},
	'4944544': {
		broadcast: '67.43.15.63',
		gateway: '67.43.15.1',
		id: 4944544,
		ip: '67.43.15.57',
		netmask: '255.255.255.192',
		network: '67.43.15.57/32',
	},
	'5158419': {
		broadcast: null,
		gateway: '2607:fad0:3713:0:0:0:0:1',
		id: 5158419,
		ip: '2607:fad0:3713:10::',
		netmask: 'ffff:ffff:ffff:0:0:0:0:0',
		network: '2607:fad0:3713:10::/64',
	},
	'5158427': {
		broadcast: null,
		gateway: '2607:fad0:3713:0:0:0:0:1',
		id: 5158427,
		ip: '2607:fad0:3713:11::',
		netmask: 'ffff:ffff:ffff:0:0:0:0:0',
		network: '2607:fad0:3713:11::/64',
	},
	'5158422': {
		broadcast: null,
		gateway: '2607:fad0:3713:0:0:0:0:1',
		id: 5158422,
		ip: '2607:fad0:3713:400::',
		netmask: 'ffff:ffff:ffff:0:0:0:0:0',
		network: '2607:fad0:3713:400::/64',
	},
	'5158423': {
		broadcast: null,
		gateway: '2607:fad0:3713:0:0:0:0:1',
		id: 5158423,
		ip: '2607:fad0:3713:402::',
		netmask: 'ffff:ffff:ffff:0:0:0:0:0',
		network: '2607:fad0:3713:402::/64',
	},
	'5158425': {
		broadcast: null,
		gateway: '2607:fad0:3713:0:0:0:0:1',
		id: 5158425,
		ip: '2607:fad0:3713:4004::',
		netmask: 'ffff:ffff:ffff:0:0:0:0:0',
		network: '2607:fad0:3713:4004::/64',
	},
};

export default data;
