const variantConfigs = {
	paymentMethod: {
		einPt: 0,
		gridItemSettings: { xs: 12, sm: 6, md: 4 },
		gridContainerSettings: { spacing: 4 },
		gridItemSpacerSettings: {
			sm: 6,
			md: 8,
		},
		showCountry: true,
		showUseEinButton: false,
	},
	default: {
		einPt: 2,
		gridItemSettings: { xs: 7 },
		gridContainerSettings: { spacing: 2 },
		gridItemSpacerSettings: { xs: 5 },
		showCountry: false,
		showUseEinButton: true,
	},
};

export default variantConfigs;
