import details from './details';

const path = '/account/billingaddress/';

export default {
	[`${path}details.json`]: () => {
		// The 'testUser' localStorage property is assigned in src/testUtils/helperFunctions.js
		switch (window.localStorage.getItem('testUser')) {
			case 'incompleteAddress':
			case 'owner': // has paypal, so no billing address.
				return null;
			default:
				return details;
		}
	},
};
