import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import LWTypography from 'components/common/LWTypography';
import CartItemExpansion from 'components/cart/CartItem/CartItemExpansion';

const Detail = ({ detail }) => (
	<Box display="flex" flexDirection="column">
		<Box display="flex" marginY={0.5}>
			<LWTypography variant="subtitle2" color="palette.common.accent.0" bold>
				{detail.title} :&nbsp;
			</LWTypography>
			<LWTypography variant="body2">{detail.subtitle}</LWTypography>
		</Box>
		<Box>
			{detail.children?.map((child) => (
				<ChildDetails child={child} key={`${child.title}.${child.value}`} />
			))}
		</Box>
	</Box>
);

const ChildDetails = ({ child }) => (
	<LWTypography
		variant="subtitle2"
		color="palette.text.secondary"
		BoxProps={{
			display: 'flex',
			marginLeft: 2,
			marginY: 0.5,
		}}
	>
		{`${child.title} : ${child.subtitle}`}
	</LWTypography>
);

const Details = ({ details = [], customerNotes = '', salesRepEmail }) => {
	// Admin needs outside control of this. TODO: find a better way to do this.
	const [isExpanded, setIsExpanded] = useState(false);
	return (
		<CartItemExpansion {...{ isExpanded, setIsExpanded }} title="Details">
			<Box display="flex" flexDirection="column">
				<Box>
					{details.map((detail) => (
						<Detail detail={detail} key={`${detail.title}.${detail.value}`} />
					))}
				</Box>
				{customerNotes && (
					<Box mt={1}>
						<LWTypography variant="body2">
							{`Notes from ${salesRepEmail || 'your representative'}:`}
						</LWTypography>
						<LWTypography variant="body2" color="palette.text.secondary">
							{customerNotes}
						</LWTypography>
					</Box>
				)}
			</Box>
		</CartItemExpansion>
	);
};

Details.propTypes = {
	details: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			subtitle: PropTypes.string.isRequired,
			children: PropTypes.arrayOf(
				PropTypes.shape({
					title: PropTypes.string.isRequired,
					subtitle: PropTypes.string.isRequired,
				}),
			),
		}),
	),
	customerNotes: PropTypes.string,
	salesRepEmail: PropTypes.string.isRequired,
};

export default Details;
