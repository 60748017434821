import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.project.remove;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'PROJECT_REMOVE',
	url: '/project/remove.json',
});

const selectors = {
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };
