export default {
	'100054': {
		domains: [
			{
				categories: ['DNS'],
				dns: {
					uniq_id: 'K7M0C4',
				},
				domain: 'wiggletastical.com',
				is_subdomain: 0,
				project_id: 100054,
				project_name: 'Cameron Test',
			},
			{
				categories: ['DNS'],
				dns: {
					uniq_id: '4WARXU',
				},
				domain: 'adomainforbob.info',
				dreg: {
					autorenew: 1,
					expiration: '2020-07-20 13:04:00',
					redemption: 0,
					renewable: 0,
					uniq_id: 'M1RE0M',
				},
				is_subdomain: 0,
				project_id: 100054,
				project_name: 'Cameron Test',
			},
			{
				categories: ['SSL'],
				dns: {
					uniq_id: '7FV3NT',
				},
				domain: 'fireflyssltest.com',
				dreg: {
					autorenew: 0,
					expiration: '2020-11-06 15:38:16',
					redemption: 0,
					renewable: 0,
					uniq_id: '6J4B8T',
				},
				is_subdomain: 0,
				project_id: 100054,
				project_name: 'Cameron Test',
				ssl: {
					autorenew: 1,
					details: {},
					expiration: null,
					renewable: 0,
					type: 'DV',
					uniq_id: '3QL04R',
					wildcard: 0,
				},
			},
		],
		project_id: 100054,
		project_name: 'Cameron Test',
		servers: [
			{
				categories: ['Dedicated', 'AuthDedicated', 'StrictDedicated'],
				domain: 'test.ye4v1mqh8qvsyml8zyvvizlsieiwiixh.com',
				ip: '127.0.0.1',
				mesDetails: {},
				powerStatus: 'unknown',
				status: 'Active',
				type: 'DS.MinimalForTests',
				uniq_id: 'F95TV0',
			},
			{
				categories: ['StrictDedicated', 'AuthDedicated', 'Dedicated'],
				domain: 'test.bghupdps3kcmyav6q0u11wnt0z02ogwd.com',
				ip: '127.0.0.1',
				mesDetails: {},
				powerStatus: 'unknown',
				status: 'Active',
				type: 'DS.MinimalForTests',
				uniq_id: '4KP25A',
			},
			{
				categories: [
					'Dedicated',
					'Provisioned',
					'VirtualDedicated',
					'AuthDedicated',
					'CloudVPS',
				],
				domain: 'child1.mctesterson.com',
				ip: '67.43.15.30',
				mesDetails: {},
				powerStatus: 'unknown',
				status: 'Active',
				type: 'SS.VPS',
				uniq_id: 'UQZ3HX',
			},
		],
		services: [
			{
				categories: [
					'Service',
					'WordPress',
					'MWPV4Platform',
					'ManagedApplication',
				],
				domain: 'mm2k142w-liquidwebsites.com',
				ip: '127.0.0.1',
				mesDetails: {},
				status: 'Active',
				type: 'WP.Platform.V4',
				uniq_id: 'EMFLB4',
			},
		],
	},
	'100990': {
		domains: [],
		project_id: 100990,
		project_name: 'all the things',
		servers: [],
		services: [
			{
				categories: ['Service', 'ServiceDiscountable', 'ManagedEngineering'],
				domain: 'bundleopenstackmes.bundleopenstackfr.wellthisisborken.com',
				ip: '127.0.0.1',
				mesDetails: {
					domain: 'bundleopenstackmes.bundleopenstackfr.wellthisisborken.com',
					mes_role: 'service',
					mes_type: 'file_replication',
					uniq_id: 'MU4UBQ',
				},
				status: 'Active',
				type: 'Replication.NFS',
				uniq_id: 'MU4UBQ',
			},
			{
				categories: ['ManagedEngineering', 'Service', 'ServiceDiscountable'],
				domain: 'bundleopenstackmes.bundleopenstackdb.wellthisisborken.com',
				ip: '127.0.0.1',
				mesDetails: {},
				status: 'Active',
				type: 'DatabaseClustering.Service',
				uniq_id: '5Q45JD',
			},
		],
	},
	'100303': {
		domains: [],
		project_id: 100303,
		project_name: 'File Rep One',
		servers: [
			{
				categories: [
					'AuthDedicated',
					'CloudVPS',
					'Provisioned',
					'Dedicated',
					'VirtualDedicated',
				],
				domain: 'host.backupsandimagestest.com',
				ip: '67.43.15.26',
				mesDetails: {},
				powerStatus: 'unknown',
				status: 'Active',
				type: 'SS.VPS',
				uniq_id: '78554A',
			},
		],
		services: [],
	},
	'100005': {
		domains: [
			{
				categories: ['DNS'],
				dns: {
					uniq_id: 'RBQJKG',
				},
				domain: 'whatadomain.com',
				is_subdomain: 0,
				project_id: 100005,
				project_name: 'A super special project n 2ame',
			},
			{
				categories: ['DNS'],
				domain: 'testinganewdomainunique.com',
				dreg: {
					autorenew: 0,
					expiration: '2020-12-14 19:02:35',
					redemption: 0,
					renewable: 0,
					uniq_id: 'YEAH9G',
				},
				is_subdomain: 0,
				project_id: 100005,
				project_name: 'A super special project n 2ame',
			},
			{
				categories: ['DNS'],
				domain: 'asldfkjsldkjfdsljf.com',
				dreg: {
					autorenew: 0,
					expiration: '2021-12-04 21:15:43',
					redemption: 0,
					renewable: 1,
					uniq_id: 'DAZL9X',
				},
				is_subdomain: 0,
				project_id: 100005,
				project_name: 'A super special project n 2ame',
			},
			{
				categories: ['DNS'],
				dns: {
					uniq_id: 'MX8L5P',
				},
				domain: 'superspecialdomain.com',
				is_subdomain: 0,
				project_id: 100005,
				project_name: 'A super special project n 2ame',
			},
			{
				categories: ['SSL'],
				domain: '1541789411.fireflyssltest.com',
				is_subdomain: 1,
				project_id: 100005,
				project_name: 'A super special project n 2ame',
				ssl: {
					autorenew: 1,
					details: {
						CN: '1541789411.fireflyssltest.com',
						OU: 'Domain Control Validated',
						SAN: ['1541789411.fireflyssltest.com'],
					},
					expiration: '2020-11-09 14:01:38',
					renewable: 0,
					type: 'DV',
					uniq_id: 'ARUC7S',
					wildcard: 0,
				},
			},
			{
				categories: ['SSL'],
				domain: 'www.c.com',
				is_subdomain: 1,
				project_id: 100005,
				project_name: 'A super special project n 2ame',
				ssl: {
					autorenew: 1,
					details: {},
					expiration: '2020-12-25 12:12:12',
					renewable: 0,
					type: 'DV',
					uniq_id: 'UAKBRK',
					wildcard: 0,
				},
			},
		],
		project_id: 100005,
		project_name: 'A super special project n 2ame',
		servers: [],
		services: [],
	},
	'100035': {
		domains: [
			{
				categories: ['DNS'],
				dns: {
					uniq_id: 'NQ0D7X',
				},
				domain: 'ourshenanigans.com',
				is_subdomain: 0,
				project_id: 100035,
				project_name: 'Here is a new project',
			},
		],
		project_id: 100035,
		project_name: 'Here is a new project',
		servers: [],
		services: [
			{
				categories: ['Service'],
				domain: 'gb.whatevertestingthings.com',
				ip: '127.0.0.1',
				mesDetails: {},
				status: 'New',
				type: 'GUARD.Special',
				uniq_id: 'N0QFWW',
			},
		],
	},
	'100036': {
		domains: [
			{
				categories: ['DNS'],
				dns: {
					uniq_id: 'ECEJDE',
				},
				domain: 'adskiremote.co.uk',
				is_subdomain: 0,
				project_id: 100036,
				project_name: 'another damn project',
			},
			{
				categories: ['DNS'],
				dns: {
					uniq_id: 'RDQZWD',
				},
				domain: 'adomainforbob.net',
				dreg: {
					autorenew: 0,
					expiration: '2020-07-20 13:03:58',
					redemption: 0,
					renewable: 0,
					uniq_id: 'HXR04W',
				},
				is_subdomain: 0,
				project_id: 100036,
				project_name: 'another damn project',
			},
			{
				categories: ['DNS'],
				dns: {
					uniq_id: '1NMZTS',
				},
				domain: 'mydomainfortesting.com',
				is_subdomain: 0,
				project_id: 100036,
				project_name: 'another damn project',
			},
		],
		project_id: 100036,
		project_name: 'another damn project',
		servers: [],
		services: [],
	},
	'100031': {
		domains: [],
		project_id: 100031,
		project_name: 'wiggle',
		servers: [],
		services: [],
	},
	'101987': {
		domains: [],
		project_id: 101987,
		project_name: 'OpenStack DB Replication',
		servers: [],
		services: [],
	},
	'101988': {
		domains: [],
		project_id: 101988,
		project_name: 'OpenStack File Replication',
		servers: [],
		services: [],
	},
	'105070': {
		domains: [],
		project_id: 105070,
		project_name: 'theBEST',
		servers: [],
		services: [
			{
				categories: ['Service'],
				domain: 'best.com.hi.com',
				ip: null,
				mesDetails: {},
				status: 'New',
				type: 'ThreatStack',
				uniq_id: 'N96CZF',
			},
		],
	},
	'102426': {
		domains: [],
		project_id: 102426,
		project_name: 'New Project 1',
		servers: [
			{
				categories: ['Dedicated', 'VirtualDedicated', 'NocworxOpenstack'],
				domain: 'fs.andthenanotherthinghappened.com',
				ip: '69.160.55.120',
				mesDetails: {
					domain: 'bundleopenstackmes.andthenanotherthinghappened.com',
					mes_role: 'host',
					mes_type: 'file_replication',
					uniq_id: 'M96MN5',
				},
				powerStatus: 'on',
				status: 'Active',
				type: 'Cloud.VM.FS',
				uniq_id: 'WFTN6D',
			},
		],
		services: [
			{
				categories: ['ManagedEngineering', 'Service', 'ServiceDiscountable'],
				domain: 'bundleopenstackmes.andthenanotherthinghappened.com',
				ip: '127.0.0.1',
				mesDetails: {
					domain: 'bundleopenstackmes.andthenanotherthinghappened.com',
					mes_role: 'service',
					mes_type: 'file_replication',
					uniq_id: 'M96MN5',
				},
				status: 'Active',
				type: 'Replication.NFS',
				uniq_id: 'M96MN5',
			},
		],
	},
	'102430': {
		domains: [],
		project_id: 102430,
		project_name: 'New Project 2',
		servers: [
			{
				categories: ['VirtualDedicated', 'Dedicated', 'NocworxOpenstack'],
				domain: 'app1.folksneedopenstack.com',
				ip: '69.160.55.77',
				mesDetails: {
					domain: 'bundleopenstackmes.folksneedopenstack.com',
					mes_role: 'client',
					mes_type: 'file_replication',
					uniq_id: 'XZ8VX4',
				},
				powerStatus: 'on',
				status: 'Active',
				type: 'Cloud.VM.WebApp',
				uniq_id: 'D24WGV',
			},
			{
				categories: ['VirtualDedicated', 'Dedicated', 'NocworxOpenstack'],
				domain: 'fs.folksneedopenstack.com',
				ip: '69.160.55.83',
				mesDetails: {
					domain: 'bundleopenstackmes.folksneedopenstack.com',
					mes_role: 'host',
					mes_type: 'file_replication',
					uniq_id: 'XZ8VX4',
				},
				powerStatus: 'on',
				status: 'Active',
				type: 'Cloud.VM.FS',
				uniq_id: '777Q66',
			},
			{
				categories: [
					'VirtualDedicated',
					'LoadBalancer',
					'Dedicated',
					'NocworxOpenstack',
				],
				domain: 'lb1.folksneedopenstack.com',
				ip: '69.160.55.121',
				mesDetails: {
					domain: 'bundleopenstackmes.folksneedopenstack.com',
					mes_role: 'lb',
					mes_type: 'file_replication',
					uniq_id: 'XZ8VX4',
				},
				powerStatus: 'on',
				status: 'Active',
				type: 'Cloud.VM.LB',
				uniq_id: 'L7HW2B',
			},
		],
		services: [
			{
				categories: ['Service', 'ServiceDiscountable', 'ManagedEngineering'],
				domain: 'bundleopenstackmes.folksneedopenstack.com',
				ip: '127.0.0.1',
				mesDetails: {
					domain: 'bundleopenstackmes.folksneedopenstack.com',
					mes_role: 'service',
					mes_type: 'file_replication',
					uniq_id: 'XZ8VX4',
				},
				status: 'Active',
				type: 'Replication.NFS',
				uniq_id: 'XZ8VX4',
			},
		],
	},
	'102659': {
		domains: [],
		project_id: 102659,
		project_name: 'New Project 3',
		servers: [
			{
				categories: ['Dedicated', 'VirtualDedicated', 'NocworxOpenstack'],
				domain:
					'bundleopenstackprimarydb.bundleopenstackdb.folksneedopenstack.com',
				ip: '69.160.55.115',
				mesDetails: {
					domain: 'bundleopenstackmes.bundleopenstackdb.folksneedopenstack.com',
					mes_role: 'primary',
					mes_type: 'db_replication',
					uniq_id: 'M0E34U',
				},
				powerStatus: 'on',
				status: 'Active',
				type: 'Cloud.VM.DB',
				uniq_id: '0P9L4E',
			},
			{
				categories: ['NocworxOpenstack', 'VirtualDedicated', 'Dedicated'],
				domain:
					'bundleopenstackreadonly.bundleopenstackdb.folksneedopenstack.com',
				ip: '69.160.55.118',
				mesDetails: {
					domain: 'bundleopenstackmes.bundleopenstackdb.folksneedopenstack.com',
					mes_role: 'read-only',
					mes_type: 'db_replication',
					uniq_id: 'M0E34U',
				},
				powerStatus: 'on',
				status: 'Active',
				type: 'Cloud.VM.DB',
				uniq_id: 'CG6416',
			},
		],
		services: [
			{
				categories: ['ManagedEngineering', 'ServiceDiscountable', 'Service'],
				domain: 'bundleopenstackmes.bundleopenstackdb.folksneedopenstack.com',
				ip: '127.0.0.1',
				mesDetails: {
					domain: 'bundleopenstackmes.bundleopenstackdb.folksneedopenstack.com',
					mes_role: 'service',
					mes_type: 'db_replication',
					uniq_id: 'M0E34U',
				},
				status: 'Active',
				type: 'DatabaseClustering.Service',
				uniq_id: 'M0E34U',
			},
		],
	},
};
