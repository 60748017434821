import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.sslCertificate.renew;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'SSL_CERTIFICATE_RENEW',
	method: 'POST',
	url: '/ssl/certificate/renew.json',
});

const selectors = {
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };
