import {
	actions as resizeActions,
	selectors as resizeSelectors,
} from 'modules/api/server/resizeModule';
import {
	actions as rebootActions,
	selectors as rebootSelectors,
} from 'modules/api/server/rebootModule';

export default {
	resize: {
		label: 'Resize',
		action: resizeActions,
		selectors: resizeSelectors,
		redirectPath: ({ uniq_id: uniqId }) => `/go/${uniqId}`,
	},
	reboot: {
		label: 'Reboot',
		action: rebootActions,
		selectors: rebootSelectors,
		redirectPath: ({ uniq_id: uniqId }) => `/go/${uniqId}`,
	},
};
