import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';
import { selectors as assetDetailsSelectors } from 'modules/api/asset/detailsModule';

const getStateSlice = (state) => state.storage.block.cluster.list;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'STORAGE_BLOCK_CLUSTER_LIST',
	method: 'POST',
	url: '/storage/block/cluster/list.json',
});

const selectors = {
	filtered: createSelector(
		defaultSelectors.getNativeData,
		assetDetailsSelectors.regionId,
		(slice, region) => {
			if (!slice || !region) return {};
			// eslint-disable-next-line
			return slice.items.find((cluster) => {
				if (cluster.region.id === region) {
					return cluster;
				}
			});
		},
	),
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };
