import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';
import { Map, List } from 'immutable';
import {
	countryWithRegions as CountryWithRegions,
	countryOption as CountryOption,
	stateOption as StateOption,
} from 'utility/countryRegionConverter';
import emailTypes from 'utility/constants/emailTypes';
import phoneTypes from 'utility/constants/phoneTypes';

const getStateSlice = (state) => state.contact.list;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'CONTACT_LIST',
	isPaginated: true,
	url: '/contact/list.json',
});

const getEmailByType = (defaultContact, type) =>
	defaultContact
		.get('emails', List())
		.filter((email) => email.get('type') === type);
const getPhoneByType = (defaultContact, type) =>
	defaultContact
		.get('phones', List())
		.filter((phone) => phone.get('type') === type);

const getDefaultContactWithDefaultValues = createSelector(
	defaultSelectors.getItems,
	(data) => {
		if (!data || data.isEmpty()) return Map();
		// currently the default contact is recognized as the first contact in the list
		// and the first data object in each inner array. We may change that in the future
		// but thats how the system currently works.
		const defaultContact = data
			.filter((contact) => contact.get('type') === 'invoice')
			.first()
			.map((value) => {
				// enom returns null strings as empty objects, which is obnoxious. This irons that out.
				if (!Map.isMap(value) || !value.isEmpty()) return value;
				return null;
			});
		return defaultContact
			.set(
				'defaultEmailAddress',
				defaultContact.getIn(['emails', 0, 'address']),
			)
			.set('defaultPhoneNumber', defaultContact.getIn(['phones', 0, 'number']));
	},
);

const getDefaultContact = createSelector(
	getDefaultContactWithDefaultValues,
	(contact) => contact?.toJS(),
);

const country = createSelector(
	getDefaultContactWithDefaultValues,
	(defaultContact) => defaultContact.get('country'),
);
const countryWithRegions = createSelector(
	country,
	CountryWithRegions,
);
const state = createSelector(
	getDefaultContactWithDefaultValues,
	(defaultContact) => defaultContact.get('state'),
);

const selectors = {
	getDefaultContactWithDefaultValues,
	getDefaultContact,
	getDefaultContactId: createSelector(
		getDefaultContactWithDefaultValues,
		(defaultContact) => defaultContact && defaultContact.get('id'),
	),
	getMainEmails: createSelector(
		getDefaultContactWithDefaultValues,
		(defaultContact) =>
			defaultContact && getEmailByType(defaultContact, emailTypes.MAIN),
	),
	getCCEmails: createSelector(
		getDefaultContactWithDefaultValues,
		(defaultContact) =>
			defaultContact && getEmailByType(defaultContact, emailTypes.CC),
	),
	getOfficePhones: createSelector(
		getDefaultContactWithDefaultValues,
		(defaultContact) =>
			defaultContact && getPhoneByType(defaultContact, phoneTypes.OFFICE),
	),
	getHomePhones: createSelector(
		getDefaultContactWithDefaultValues,
		(defaultContact) =>
			defaultContact && getPhoneByType(defaultContact, phoneTypes.HOME),
	),
	getCellPhones: createSelector(
		getDefaultContactWithDefaultValues,
		(defaultContact) =>
			defaultContact && getPhoneByType(defaultContact, phoneTypes.CELL),
	),
	getFaxPhones: createSelector(
		getDefaultContactWithDefaultValues,
		(defaultContact) =>
			defaultContact && getPhoneByType(defaultContact, phoneTypes.FAX),
	),
	address: createSelector(
		getDefaultContactWithDefaultValues,
		(defaultContact) => defaultContact.get('address'),
	),
	address2: createSelector(
		getDefaultContactWithDefaultValues,
		(defaultContact) => defaultContact.get('address2'),
	),
	city: createSelector(
		getDefaultContactWithDefaultValues,
		(defaultContact) => defaultContact.get('city'),
	),
	state,
	stateOption: createSelector(
		countryWithRegions,
		state,
		StateOption,
	),
	stateOptions: createSelector(
		countryWithRegions,
		(myCountry) =>
			myCountry &&
			myCountry.regions.map((region) => ({
				label: region.name,
				value: region.name,
			})),
	),
	postalCode: createSelector(
		getDefaultContactWithDefaultValues,
		(defaultContact) => defaultContact.get('postal_code'),
	),
	country,
	countryWithRegions,
	countryOption: createSelector(
		countryWithRegions,
		CountryOption,
	),
	taxId: createSelector(
		getDefaultContactWithDefaultValues,
		(defaultContact) => {
			const taxId = defaultContact.get('tax_id');
			// normalizing the data, it should never come back as empty string, but does for some old accounts
			if (taxId !== undefined && taxId !== null && taxId.length === 0)
				return null;
			return taxId;
		},
	),
	taxExempt: createSelector(
		getDefaultContactWithDefaultValues,
		(defaultContact) => !!parseInt(defaultContact.get('tax_exempt'), 10),
	),
	taxExemptId: createSelector(
		getDefaultContactWithDefaultValues,
		(defaultContact) => defaultContact.get('tax_exempt_id'),
	),
	taxExemptReason: createSelector(
		getDefaultContactWithDefaultValues,
		(defaultContact) => defaultContact.get('tax_exempt_reason'),
	),
	fullName: createSelector(
		getDefaultContactWithDefaultValues,
		(defaultContact) =>
			`${defaultContact.get('fname')} ${
				defaultContact.get('mname') ? `${defaultContact.get('mname')} ` : ''
			}${defaultContact.get('lname')}`,
	),
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };
