import { getConfig } from './config';
import injectScript from './util/inject-script';
import emitter from './emitter';

const watchableEmbeddedServiceEvents = [
	'onAgentJoinedConference',
	'onAgentLeftConference',
	'onAgentMessage',
	'onAgentRichMessage',
	'onChasitorMessage',
	'onChatConferenceEnded',
	'onChatConferenceInitiated',
	'onChatEndedByAgent',
	'onChatEndedByChasitor',
	'onChatReconnectSuccessful',
	'onChatRequestSuccess',
	'onChatTransferSuccessful',
	'onConnectionError',
	'onIdleTimeoutOccurred',
	'onQueueUpdate',
	'onClickSubmitButton',
	'onHelpButtonClick',
	'onInviteAccepted',
	'onInviteRejected',
	'afterInit',
	'afterMaximize',
	'afterDestroy',
	'afterMinimize',
	'ready',
];

let loadPromise = null;
const loadEmbeddedSvc = () => {
	if (loadPromise) return loadPromise;

	if (window.embedded_svc) return Promise.resolve();

	const url = getConfig('eswScriptURL');

	loadPromise = injectScript(url)
		.catch((err) => {
			emitter.emit('LWESWScriptLoadFailure');
			throw err;
		});

	return loadPromise;
};

const getEmbeddedSvc = () => {
	if (window.embedded_svc) return Promise.resolve(window.embedded_svc);

	return loadEmbeddedSvc().then(() => window.embedded_svc);
};

const watchEmbeddedServiceEvents = () => {
	const { emit } = emitter;

	return getEmbeddedSvc()
		.then((embeddedSvc) => {
			watchableEmbeddedServiceEvents.forEach((eventName) => {
				embeddedSvc.addEventHandler(
					eventName,
					emit.bind(emitter, eventName),
				);
			});
		});
};

export {
	getEmbeddedSvc,
	loadEmbeddedSvc,
	watchEmbeddedServiceEvents,
};
