import React from 'react';

export default function asyncComponent(importComponent) {
	class AsyncComponent extends React.Component {
		constructor(props) {
			super(props);

			this.state = {
				Component: null,
			};
		}

		async componentDidMount() {
			this.mounted = true;
			const { default: Component } = await importComponent();

			if (this.mounted) {
				this.setState({
					Component,
				});
			}
		}

		componentWillUnmount() {
			this.mounted = false;
		}

		render() {
			const { Component } = this.state;

			return Component ? <Component {...this.props} /> : null;
		}
	}

	return AsyncComponent;
}
