import React from 'react';
import { connect } from 'react-redux';
import compose from 'utility/compose';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import chatActions from 'modules/chat/actions';
import Help from '@material-ui/icons/HelpRounded';
import Person from '@material-ui/icons/PersonRounded';
import { ReactComponent as LWLogo } from 'images/logo-no-text.svg';
import Immutable from 'immutable';
import Logo from 'components/structural/topbar/Logo';
import NavAppBar from 'components/structural/topbar/NavAppBar';
import NavButton from 'components/structural/topbar/NavButton';
import { navLinks } from 'config/routingConfig';
import MenuButton from 'components/structural/topbar/MenuButton';
import {
	hasAuthToken as hasAuthTokenSelector,
	hasGroup,
	isKeroOnly as isKeroOnlySelector,
	isSetupUser as isSetupUserSelector,
} from 'modules/auth/oidcSelectors';
import { selectors as appConfigSelectors } from 'modules/api/appConfigModule';
import manageRedirectActions from 'modules/manageRedirect/manageRedirectActions';
import ButtonBase from '@material-ui/core/ButtonBase';
import { selectors as accountDetailsSelectors } from 'modules/api/account/detailsModule';
import NotificationButton from '../notification/NotificationButton';

// shrinking width to fit help button, prevent horizontal scroll
const SLogoWrapper = styled(Box)`
	@media (max-width: 1104px) {
		flex: 0 0 70px;
		padding-left: ${({ theme }) => theme.spacing(1)};
		overflow: hidden;
	}
`;

// TODO: make this a component wrapped in Box. Use both here and in MenuButton.jsx
const SLWLogo = styled(LWLogo)`
	width: 36px;
	height: 36px;
`;

const SSupportButton = styled(NavButton)`
	background: transparent;
	& svg {
		fill: ${({ theme }) => theme.palette.common.accent[1]};
	}
	& span {
		color: ${({ theme }) => theme.palette.common.accent[1]};
	}
`;

const SHelp = styled(Help)`
	fill: ${({ theme }) => theme.palette.common.blue4};
	font-size: 3em;
`;

const SMenuItem = styled(MenuItem)`
	color: ${({ theme }) => theme.palette.common.blue2};
`;

const NavBar = ({
	isLoggedIn = false,
	isKeroOnly = false,
	isSetupUser = false,
	isLoadingAccountDetails = false,
	openManageRedirectDialog,
	customerCommunity,
	idpHostname,
	idpClientId,
	secureNotesCount,
	startChat,
	wwwHostname,
}) => {
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const renderMainNavButtons = () =>
		navLinks
			.filter((navData) => !navData.get('navHidden'))
			.map((navData) => {
				if (
					(!isLoggedIn && !navData.get('allowUnauth')) ||
					isKeroOnly ||
					(isSetupUser && !navData.get('allowUnauth'))
				)
					return null;

				let badge;
				if (
					navData.get('name') === 'Account' &&
					!isLoadingAccountDetails &&
					secureNotesCount > 0
				) {
					badge = { content: secureNotesCount };
				}

				return (
					<NavButton
						badge={badge}
						key={navData.get('name')}
						name={navData.get('name')}
						icon={navData.get('icon')}
						path={navData.get('path')}
						subNavData={navData.get('subNav')}
						onClick={
							navData.get('notYetImplemented') ? openManageRedirectDialog : null
						}
					/>
				);
			})
			.toArray();

	const helpSubNavData = Immutable.fromJS({
		helpCenter: {
			name: 'Help Center',
			onClick: () => window.open(customerCommunity, '_blank', 'noopener'),
		},
		myTickets: {
			onClick: () =>
				window.open(`${customerCommunity}/open-tickets`, '_blank', 'noopener'),
			name: 'My Tickets',
		},
		liquidWebStatus: {
			onClick: () =>
				window.open('https://status.liquidweb.com/', '_blank', 'noopener'),
			name: 'Liquid Web Status',
		},
		openATicket: {
			onClick: () =>
				window.open(
					`${customerCommunity}/contactsupport`,
					'_blank',
					'noopener',
				),
			name: 'Open a Ticket',
		},
		chatWithAHuman: {
			onClick: () => startChat(),
			name: 'Chat with a Human',
		},
	});

	return (
		<NavAppBar>
			<Hidden smDown>
				<SLogoWrapper display="flex">
					<Logo to={isLoggedIn && !isSetupUser ? '/' : wwwHostname} />
				</SLogoWrapper>
				<Box display="flex">
					{!isLoadingAccountDetails && (isSetupUser || !isLoggedIn) && (
						<NavButton
							name="www"
							icon={(buttonProps) => <SLWLogo {...buttonProps} />}
							path={wwwHostname}
							isExternal
						/>
					)}
					{renderMainNavButtons()}
					{!(isKeroOnly || isSetupUser || isLoggedIn) && (
						<NavButton
							name="Create Account"
							icon={(buttonProps) => <Person {...buttonProps} />}
							path={`${idpHostname}/signup?client_id=${idpClientId}&amp;response_type=token%20id_token&amp;scope=openid%20profile%20grants`}
							isExternal
						/>
					)}
					{isLoggedIn && !isSetupUser && (
						<>
							<NotificationButton />
							<SSupportButton
								name="Support"
								icon={(buttonProps) => <Help {...buttonProps} />}
								subNavData={helpSubNavData}
							/>
						</>
					)}
				</Box>
			</Hidden>
			<Hidden mdUp>
				<>
					<MenuButton
						customerCommunity={customerCommunity}
						isKeroOnly={isKeroOnly}
						idpClientId={idpClientId}
						idpHostname={idpHostname}
						isLoadingAccountDetails={isLoadingAccountDetails}
						isLoggedIn={isLoggedIn}
						isSetupUser={isSetupUser}
						secureNotesCount={secureNotesCount}
						wwwHostname={wwwHostname}
					/>

					{isLoggedIn && !isSetupUser && (
						<>
							<NotificationButton />

							<Box
								component={ButtonBase}
								ml="auto"
								mr="auto"
								onClick={handleClick}
							>
								<SHelp />
							</Box>
							<Menu
								id="simple-menu"
								anchorEl={anchorEl}
								keepMounted
								open={Boolean(anchorEl)}
								onClose={handleClose}
							>
								<SMenuItem
									onClick={() =>
										window.open(customerCommunity, '_blank', 'noopener')
									}
								>
									Help Center
								</SMenuItem>
								<SMenuItem
									onClick={() =>
										window.open(
											`${customerCommunity}open-tickets`,
											'_blank',
											'noopener',
										)
									}
								>
									My Tickets
								</SMenuItem>
								<SMenuItem
									onClick={() =>
										window.open(
											'https://status.liquidweb.com/',
											'_blank',
											'noopener',
										)
									}
								>
									Liquid Web Status
								</SMenuItem>
								<SMenuItem
									onClick={() =>
										window.open(
											`${customerCommunity}contactsupport`,
											'_blank',
											'noopener',
										)
									}
								>
									Open a Ticket
								</SMenuItem>
								<SMenuItem onClick={() => startChat()}>
									Chat with a Human
								</SMenuItem>
							</Menu>
						</>
					)}
				</>
				<Logo />
			</Hidden>
		</NavAppBar>
	);
};

NavBar.propTypes = {
	/** User has an auth token, and is logged in to an account */
	isLoggedIn: PropTypes.bool,
	/** User is logged in, but account isn't active yet */
	isSetupUser: PropTypes.bool,
	/** User is logged in, but does not have an account yet */
	isKeroOnly: PropTypes.bool,
	openManageRedirectDialog: PropTypes.func.isRequired,
	/** URL to Salesforce customer community */
	customerCommunity: PropTypes.string.isRequired,
	/** Hostname of Kero (OpenID) server */
	idpHostname: PropTypes.string.isRequired,
	/** OpenID client ID */
	idpClientId: PropTypes.string.isRequired,
	/** Status of account/details API loading state */
	isLoadingAccountDetails: PropTypes.bool,
};

const mapStateToProps = (state) => ({
	isLoggedIn: hasAuthTokenSelector(state) && hasGroup(state),
	isKeroOnly: isKeroOnlySelector(state),
	customerCommunity: appConfigSelectors.getCustomerCommunityHostname(state),
	wwwHostname: appConfigSelectors.getWwwHostname(state),
	idpHostname: appConfigSelectors.getOpenIdConfig(state).get('authority'),
	idpClientId: appConfigSelectors.getOpenIdConfig(state).get('client_id'),
	secureNotesCount: accountDetailsSelectors.getSecureNotesCount(state),
	isLoadingAccountDetails: accountDetailsSelectors.isLoading(state),
	isSetupUser: isSetupUserSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
	startChat: () => dispatch(chatActions.startChat()),
	openManageRedirectDialog: (e, url) => {
		e.preventDefault();
		dispatch(manageRedirectActions.setOpen(true, url, false));
	},
});

export { NavBar };
export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	),
)(NavBar);
