import { all, select, put, take, takeEvery, call } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { actions as openidLogoutActions } from 'modules/openid/logoutModule';
import {
	hasAuthToken as hasAuthTokenSelector,
	username as usernameSelector,
	roles as rolesSelector,
	group as groupSelector,
	isBasketAdmin as isBasketAdminSelector,
} from 'modules/auth/oidcSelectors';
import {
	actions as userDetailsActions,
	moduleKeys as userDetailsModuleKeys,
} from 'modules/api/account/user/detailsModule';
import {
	actions as accountDetailActions,
	selectors as accountDetailsSelectors,
} from 'modules/api/account/detailsModule';
import { getPathName } from 'modules/routeSelectors';

import disallowedUserActions from 'modules/disallowedUser/actions';
import { CAUSES as disallowedUserCauses } from 'modules/disallowedUser/constants';
import { USER_FOUND, USER_EXPIRED } from 'redux-oidc';

import authActions from './authActions';

function* handleLogin() {
	yield take(USER_FOUND);

	const roles = yield select(rolesSelector);
	const group = yield select(groupSelector);
	const isBasketAdmin = yield select(isBasketAdminSelector);
	if (!isBasketAdmin && group && (!roles || !roles.includes('AccountLogin'))) {
		yield put(
			disallowedUserActions.setCause(disallowedUserCauses.NO_ACCOUNT_LOGIN),
		);
	}

	if (!isBasketAdmin) {
		yield all([
			take([accountDetailActions.setType, accountDetailActions.errorType]),
			put(
				accountDetailActions.fetch({
					alsowith: [
						'businessUnit',
						'customerLifecycle',
						'highlights',
						'managementPortal',
						'referAFriend',
					],
				}),
			),
		]);
	}

	const managementPortal = yield select(
		accountDetailsSelectors.managementPortal,
	);
	if (managementPortal !== 'manage') {
		yield put(authActions.userDetailsInit());
	} else {
		yield put(
			disallowedUserActions.setCause(disallowedUserCauses.WRONG_PORTAL),
		);
	}

	const accountStatus = yield select(accountDetailsSelectors.getAccountStatus);
	switch (accountStatus) {
		case 'pending-new':
			yield put(push('/shop/marketplace'));
			break;
		case 'suspended': {
			const path = yield select(getPathName);
			if (
				!path.startsWith('/account/billing') &&
				!path.startsWith('/billing')
			) {
				// Don't want to redirect if they are already at a billing page
				yield put(push('/account/billing'));
			}
			break;
		}
		default:
	}
}

function* handleSessionLogout() {
	yield call([localStorage, localStorage.clear]);
	// This is needed here to force the window to refresh
	yield window.location.assign('/');
}

function* watchSessionEnd() {
	yield take(USER_FOUND); // Never start this saga until a logged in user is encountered
	yield takeEvery(
		[openidLogoutActions.setType, USER_EXPIRED, 'ACCOUNT_AUTH_TOKEN_CLEAR'],
		handleSessionLogout,
	);
}

function* handleInitUserDetails({ timeout }) {
	const username = yield select(usernameSelector);
	const isBasketAdmin = yield select(isBasketAdminSelector);
	const hasAuthToken = yield select(hasAuthTokenSelector);
	if (!isBasketAdmin && hasAuthToken) {
		yield put(
			userDetailsActions.fetch(
				{ username, timeout },
				userDetailsModuleKeys.LOGGED_IN_USER,
			),
		);
	}

	yield take(userDetailsActions.setType); // TODO handle errorType
}

function* initUserDetails() {
	yield takeEvery([authActions.USER_DETAILS_INIT], handleInitUserDetails);
}

export default function* rootSaga() {
	yield all([handleLogin(), watchSessionEnd(), initUserDetails()]);
}
