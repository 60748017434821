import { getConfig, getAllConfig } from './config';
import { getExtraDetail, updateExtraDetail } from './extra-details';
import { sendEvent } from './analytics';

const globalHelpersObjectName = 'lwChatEmbeddedSvcHelpers';

const globalHelpers = {
	config: {
		getConfig,
		getAllConfig,
	},
	extraDetails: {
		getExtraDetail,
		updateExtraDetail,
	},
	// This now uses our analytics.js to implement analytics more
	// generically, but we're preserving the googleAnalytics name
	// for compatibility in existing apps. Its API is the same.
	googleAnalytics: {
		sendEvent,
	},
};

window[globalHelpersObjectName] = globalHelpers;

export default globalHelpers;
