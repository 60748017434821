import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import LWButton from 'components/common/LWButton';
import LWTypography from 'components/common/LWTypography';

import AddressDataCard from './AddressDataCard';

const ValidateContent = ({
	isLoading,
	setIsOpen,
	submitValidated,
	submitOriginal,
	addressData,
}) => {
	return (
		<>
			<LWTypography variant="body1" centered>
				{'We found a match for your address. '}
			</LWTypography>
			<LWTypography variant="body1" centered paragraph>
				{'Does this look accurate?'}
			</LWTypography>

			<AddressDataCard title="Verified Address" addressData={addressData} />
			<Box display="flex" justifyContent="flex-end">
				<LWButton
					BoxProps={{ paddingRight: 2 }}
					color="tertiary"
					isLoading={isLoading}
					onClick={() => setIsOpen(false)}
					variant="outlined"
				>
					Cancel
				</LWButton>
				<LWButton
					BoxProps={{ paddingRight: 2 }}
					color="secondary"
					isLoading={isLoading}
					onClick={submitOriginal}
					variant="outlined"
				>
					Use mine instead
				</LWButton>
				<LWButton
					color="secondary"
					isLoading={isLoading}
					onClick={submitValidated}
					variant="contained"
				>
					Use verified address
				</LWButton>
			</Box>
		</>
	);
};

ValidateContent.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	addressData: PropTypes.shape({
		address: PropTypes.string,
		address2: PropTypes.string,
		city: PropTypes.string,
		country: PropTypes.string,
		postal_code: PropTypes.string,
		state: PropTypes.string,
	}),
	setIsOpen: PropTypes.func.isRequired,
	submitValidated: PropTypes.func.isRequired,
	submitOriginal: PropTypes.func.isRequired,
};

export default ValidateContent;
