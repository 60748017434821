const listForKey = ({ key, data }) => {
	switch (key) {
		case 'maxWidth':
			return {
				data: [
					{
						uuid: '6a782f3a-d41a-11eb-9888-a70547e2f8d6',
						value: data ? JSON.parse(data)?.[0].value : true,
					},
				],
				key: 'maxWidth',
			};
		case 'navTracking':
			return {
				data: [
					{
						assetId: 'AWBBF5',
						count: 1,
						displayName: 'Dedicated Hosting for cpanelspecial.mctestersons.com',
						isAssetDomain: false,
						navigatedOn: ['2020-03-20T21:12:22.725Z'],
						path: '/servers/cloud/AWBBF5',
						uuid: '7dfa9e34-6aef-11ea-8c6d-db15a219fb7d',
					},
				],
				key: 'navTracking',
			};
		default:
			return { data: [{ value: 'no key was passed in.' }] };
	}
};

export default listForKey;
