import { connect } from 'react-redux';
import { compose } from 'redux';
import snackbarActions from 'modules/snackbar/snackbarActions';
import snackbarSelectors from 'modules/snackbar/snackbarSelectors';
import {
	isOpen as statusSheetIsOpen,
	height as statusSheetHeight,
} from 'modules/statusSheet/selectors';
import Snackbar from './Snackbar';

const mapStateToProps = (state) => ({
	isOpen: snackbarSelectors.isOpen(state),
	message: snackbarSelectors.currentMessage(state),
	color: snackbarSelectors.color(state),
	statusSheet: {
		isOpen: statusSheetIsOpen(state),
		height: statusSheetHeight(state),
	},
	autoHideDuration: snackbarSelectors.autoHideDuration(state),
});

const mapDispatchToProps = (dispatch) => ({
	closeSnackbar: () => {
		dispatch(snackbarActions.shiftMessage());
	},
});

export default compose(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	),
)(Snackbar);
