import { spawn } from 'redux-saga/effects';

import servicesSagas from './services/servicesSagas';
import { sagas as relatedSagas } from './relatedModule';
import { sagas as listSagas } from './listModule';

export default function* sagas() {
	yield spawn(relatedSagas);
	yield spawn(listSagas);
	yield spawn(servicesSagas);
}
