import { combineReducers } from 'redux';

import { reducer as list } from './listModule';
import { reducer as restore } from './restoreModule';
import { reducer as details } from './detailsModule';

export default combineReducers({
	list,
	restore,
	details,
});
