import keyBy from 'lodash/keyBy';
import get from 'lodash/get';
import {
	createAPIModule,
	createSelectors,
} from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';
import { List as ImmutableList } from 'immutable';
import {
	DESTINATION,
	selectors as assetDetailsSelectors,
} from 'modules/api/asset/detailsModule';
import {
	findOptionValue,
	optionValues,
	getOptionPrice,
	getPrice,
} from 'utility/products/options';
import { perMonth } from 'utility/format';
import {
	acronisProductCodes,
	acronisDestinationOptionsMap,
} from 'utility/acronisProductMap';
import { selectors as varietyProductOptionsSelectors } from '../../licensing/varietyProductOptionsModule';

const getStateSlice = (state) => state.product.details;

const moduleKeys = {
	ACRONIS: 'ACRONIS',
	templates: 'TEMPLATES',
};

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'PRODUCT_DETAILS',
	url: '/product/details.json',
});

const acronisSelectors = {
	...createSelectors(getStateSlice, false, moduleKeys.ACRONIS, {
		code: (slice) => slice?.code,
	}),
};

const capabilities = createSelector(
	defaultSelectors.getNativeState,
	(slice) => slice?.data?.capabilities || [],
);

const canAcronisBackup = createSelector(
	capabilities,
	(slice) => slice?.canAcronisBackup,
);

const getPrices = createSelector(
	getStateSlice,
	(slice) => slice?.getIn(['data', 'prices'])?.toJS() || null,
);

const getAcronisPrices = createSelector(
	acronisSelectors.getNativeState,
	(slice) => slice?.data?.prices || null,
);

const getAcronisBasePrice = createSelector(
	getAcronisPrices,
	assetDetailsSelectors.regionId,
	(acronisPrices, regionId) => acronisPrices?.[regionId]?.month,
);

const options = createSelector(
	getStateSlice,
	(slice) => slice.getIn(['data', 'options'], ImmutableList()),
);

const nativeOptions = createSelector(
	defaultSelectors.getNativeData,
	(data) => data?.options,
);

const imagePricing = createSelector(
	nativeOptions,
	assetDetailsSelectors.regionId,
	(options_, region) =>
		getOptionPrice({
			key: 'ImageStorage',
			value: '1GBMO',
			region,
			productOptions: options_,
		}),
);

const controlPanel = createSelector(
	options,
	(slice) =>
		slice ? slice.find((option) => option.get('key') === 'ControlPanel') : null,
);
const cPanel = createSelector(
	controlPanel,
	(slice) =>
		slice
			? slice
					.get('values')
					.find((value) => value.get('value').startsWith('cPanel'))
			: null,
);

const cPanelLicenseTier = createSelector(
	cPanel,
	options,
	(cPanelSlice, optionsSlice) => {
		const slice = cPanelSlice ? cPanelSlice.get('options') : optionsSlice;
		if (slice.isEmpty()) return null;

		return slice
			.find((option) => option.get('key') === 'cPanelLicenseTier')
			.get('values')
			.toJS();
	},
);

const getAcronisProductCode = createSelector(
	acronisSelectors.getNativeState,
	(slice) => slice?.data?.code,
);

const acronisDestinationOptions = createSelector(
	getAcronisProductCode,
	(acronisProductCode) => acronisDestinationOptionsMap(acronisProductCode),
);

const getAcronisBackupOptions = createSelector(
	acronisSelectors.getNativeState,
	getAcronisProductCode,
	(slice, acronisProductCode) => {
		if (acronisProductCodes.includes(acronisProductCode)) {
			const { LW, ACRONIS } = acronisDestinationOptionsMap(acronisProductCode);
			const optionsArr = get(slice, ['data', 'options'], []);
			const optionsObj = keyBy(optionsArr, 'key');

			const valuesArr = get(optionsObj, `${DESTINATION}.values`, []);

			const valuesObj = keyBy(valuesArr, 'value');
			const productOptions = {
				[ACRONIS]: valuesObj[ACRONIS],
				[LW]: valuesObj[LW],
			};
			return productOptions;
		}
		return [];
	},
);

const acronisHasLWOptions = createSelector(
	getAcronisBackupOptions,
	getAcronisProductCode,
	(acronisOptions, acronisProductCode) => {
		const { LW } = acronisDestinationOptionsMap(acronisProductCode);
		return acronisOptions?.[LW]?.options[0].values?.length > 0;
	},
);

const acronisHasCloudOptions = createSelector(
	getAcronisBackupOptions,
	getAcronisProductCode,
	(acronisOptions, acronisProductCode) => {
		const { ACRONIS } = acronisDestinationOptionsMap(acronisProductCode);
		return acronisOptions?.[ACRONIS]?.options[0].values?.length > 0;
	},
);

const cPanelLicensePrices = createSelector(
	cPanelLicenseTier,
	varietyProductOptionsSelectors.cPanel,
	(cPanelLicenseTierOptions, varietyProductOptions) => {
		if (varietyProductOptions && cPanelLicenseTierOptions) {
			return cPanelLicenseTierOptions.map((cPanelLicenseTierValue) => {
				let value;
				Object.values(varietyProductOptions).forEach((varietyProductOption) => {
					if (cPanelLicenseTierValue.value === varietyProductOption) {
						value = cPanelLicenseTierValue;
					}
				});
				return value;
			});
		}
		return null;
	},
);

const sslRenewal = createSelector(
	options,
	(slice) => slice.find((option) => option.get('key') === 'SSLCertificate'),
);

const sslTermination = createSelector(
	options,
	(slice) =>
		slice.find(
			(option) => option.get('key') === 'LoadBalancerSSLTermination',
		) &&
		slice
			.find((option) => option.get('key') === 'LoadBalancerSSLTermination')
			.toJS(),
);

const loadBalancerNode = createSelector(
	options,
	(slice) =>
		slice.find((option) => option.get('key') === 'LoadBalancerNode') &&
		slice.find((option) => option.get('key') === 'LoadBalancerNode').toJS(),
);

const liquidWebBackupPlan = createSelector(
	defaultSelectors.getNativeState,
	(slice) => optionValues('LiquidWebBackupPlan', slice?.data?.options),
);

const backupsDisplayMap = {
	None: () => 'No Daily Backups',
	Daily: ({ price }) => `Pay per GB (${perMonth(price)} per GB)`,
	Quota: () => 'Quota Pricing',
};

const quotaPlanOptions = createSelector(
	liquidWebBackupPlan,
	(lwbp) => {
		const quota = findOptionValue('Quota', lwbp);
		return (
			optionValues('BackupQuota', quota?.options)
				?.sort((a, b) => a.display_order - b.display_order)
				.map((option) => {
					const price = getPrice(option)?.month;
					return {
						price,
						displayValue: option?.description,
						value: option.value,
						data: price,
					};
				}) || []
		);
	},
);

const backupDisplay = createSelector(
	liquidWebBackupPlan,
	canAcronisBackup,
	assetDetailsSelectors.backupPlan,
	(backupPlans, acronisBackup, currentBackupPlan) => {
		// If they can't do Acronis, return the options returned from product details
		if (!acronisBackup) return 'legacyOnly';
		// If they do not have backups yet and they are able to do Acronis backups, or if they already have Acronis, only allow selecting Acronis
		if (currentBackupPlan === 'None' || currentBackupPlan === 'Acronis')
			return 'acronisOnly';
		// If we make it here, that means they have a legacy backup, and can switch to Acronis. In this case, they can choose anything.
		return 'any';
	},
);

const backupPlanOptions = createSelector(
	liquidWebBackupPlan,
	backupDisplay,
	assetDetailsSelectors.backupPlan,
	(backupPlans, displayOptions, currentBackupPlan) => {
		const noBackupOption = [{ value: 'None', label: 'No Daily Backups' }];
		const legacyBackupOption =
			backupPlans
				?.filter(({ value }) => value === currentBackupPlan)
				?.map(({ value, prices }) => ({
					value,
					label: backupsDisplayMap[value]?.({ price: prices?.[1]?.month }), // TODO: get region
				})) || [];
		const acronisBackupOption = [
			{ value: 'Acronis', label: 'Acronis Backups' },
		];

		switch (displayOptions) {
			case 'legacyOnly':
				return [...noBackupOption, ...legacyBackupOption];
			case 'acronisOnly':
				return [...noBackupOption, ...acronisBackupOption];
			default:
				return [
					...noBackupOption,
					...legacyBackupOption,
					...acronisBackupOption,
				];
		}
	},
);

const sslRenewalOneYear = createSelector(
	sslRenewal,
	(slice) =>
		slice &&
		slice
			.get('values')
			.find(
				(value) => value.get('description') === '1 Year DomainSSL Certificate',
			),
);

const sslRenewalTwoYear = createSelector(
	sslRenewal,
	(slice) =>
		slice &&
		slice
			.get('values')
			.find(
				(value) => value.get('description') === '2 Year DomainSSL Certificate',
			),
);

// values are '1IP', '2IPs', or '3IPs'
const getIpPrice = (value, regionId) => {
	return createSelector(
		options,
		(slice) => {
			let price = optionValues('ExtraIp', slice.toJS());
			price = findOptionValue('PublicIPs', price);
			price = getOptionPrice({
				key: 'PublicIPAddresses',
				value,
				region: regionId,
				productOptions: price && price.options,
			});
			return price && price.month;
		},
	);
};

const selectors = {
	getStateSlice,
	acronisSelectors,
	options,
	bhvmSizes: createSelector(
		options,
		(slice) => {
			if (slice) {
				const nocworx = slice.find((option) =>
					option ? option.get('key') === 'NocworxSKU' : null,
				);
				if (nocworx) return nocworx.get('values');
				return null;
			}
			return null;
		},
	),
	code: createSelector(
		getStateSlice,
		(slice) => slice.getIn(['data', 'code']),
	),
	controlPanel,
	cPanel,
	sslTermination,
	cPanelLicenseTier,
	cPanelLicensePrices,
	loadBalancerNode,
	liquidWebBackupPlan,
	backupDisplay,
	backupPlanOptions,
	quotaPlanOptions,
	getAcronisBackupOptions,
	getAcronisProductCode,
	acronisDestinationOptions,
	acronisHasLWOptions,
	acronisHasCloudOptions,
	sslRenewal,
	sslRenewalOneYearPrice: createSelector(
		sslRenewalOneYear,
		(slice) =>
			slice &&
			slice
				.get('prices')
				.get('1')
				.get('one-time'),
	),
	sslRenewalTwoYearPrice: createSelector(
		sslRenewalTwoYear,
		(slice) =>
			slice &&
			slice
				.get('prices')
				.get('1')
				.get('one-time'),
	),
	getPrices,
	getAcronisPrices,
	getAcronisBasePrice,
	// Note, getIpPrice is a function that returns a selector, not the selector itself.
	// To use, pass in (('1IP' || '2IPs' || '3IPs'), region_id).
	// regionId should come from modules/api/asset/detailsModule.js.
	getIpPrice,
	imagePricing,
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors, moduleKeys };
