import React from 'react';
import styled from 'styled-components';
import { Redirect, Route, Switch } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import AccountBalanceBar from 'components/structural/AccountBalanceBar';
import AccountBar from 'containers/structural/AccountBar';
import Errors from 'components/structural/Errors';
import Navbar from 'containers/structural/NavBar';
import AuthenticatedRoute from 'containers/structural/AuthenticatedRoute';
import asyncComponent from 'components/AsyncComponent';
import { navMap } from 'config/routingConfig';
import CartRouter from 'components/routers/CartRouter';
import ChatRouter from 'components/routers/ChatRouter';
import SupportRouter from 'components/routers/SupportRouter';
import GlobalComponents from 'components/structural/GlobalComponents';
import RoleRestrictedRoute from 'utility/routing/RoleRestrictedRoute';
import { roles } from 'utility/constants/roles';

import { ACCOUNT_CREATE_PATH } from 'components/routers/AccountRouter';

const SideSheet = asyncComponent(() => import('components/common/SideSheet'));

const Cart = asyncComponent(() => import('containers/pages/shop/Cart'));
const CartMerge = asyncComponent(() =>
	import('containers/pages/cart/CartMerge'),
);
const Logout = asyncComponent(() => import('containers/structural/Logout'));
const Go = asyncComponent(() => import('containers/structural/Go'));
const NotFound = asyncComponent(() => import('containers/structural/NotFound'));
const LiveChatLoading = asyncComponent(() =>
	import('containers/structural/LiveChatLoading'),
);
const LiveChatUnavailable = asyncComponent(() =>
	import('containers/structural/LiveChatUnavailable'),
);
const OpenIDReturn = asyncComponent(() =>
	import('containers/structural/OpenIDReturn'),
);
const OpenIDSilentReturn = asyncComponent(() =>
	import('components/structural/OpenIDSilentReturn'),
);
const News = asyncComponent(() => import('containers/pages/login/News'));
const InvoicePDFDisplay = asyncComponent(() =>
	import('containers/pages/billing/invoice/InvoicePDFDisplay'),
);
const VNCConsole = asyncComponent(() =>
	import('containers/pages/servers/beyond-hosting/VNCConsole'),
);
const AccountCreate = asyncComponent(() =>
	import('containers/pages/account/create'),
);

const ScrollY = styled(Box)`
	overflow-y: scroll;
`;

const AuthenticatedRoutes = () => (
	<>
		<Switch>
			<Route path="/logout">
				<Logout />
			</Route>
			<AuthenticatedRoute path="/go/:uniqId" component={Go} />
			<AuthenticatedRoute path="/account/create" component={AccountCreate} />
			{navMap.valueSeq().map((rootNav) => (
				<AuthenticatedRoute
					exact={rootNav.get('isExact')}
					key={rootNav.get('path')}
					path={rootNav.get('path')}
					component={rootNav.get('router')}
				/>
			))}
			<AuthenticatedRoute path="/cart" component={CartRouter} />
			<AuthenticatedRoute path="/chat" component={ChatRouter} />
			<AuthenticatedRoute path="/support" component={SupportRouter} />
			<Route>
				<NotFound />
			</Route>
		</Switch>
	</>
);

const UnAuthenticatedRoutes = () => (
	<>
		<Switch>
			{navMap.valueSeq().map((rootNav) => {
				if (rootNav.get('allowUnauth')) {
					const Component = rootNav.get('router');
					return (
						<Route
							exact={rootNav.get('isExact')}
							key={rootNav.get('path')}
							path={rootNav.get('path')}
						>
							<Component />
						</Route>
					);
				}
				return null;
			})}
			<RoleRestrictedRoute
				exact
				allowUnauth
				whitelistedRoles={[roles.PURCHASER, roles.SETUP_USER]}
				path="/cart/:basketUuid?"
				component={Cart}
			/>
			<RoleRestrictedRoute
				exact
				allowUnauth
				whitelistedRoles={[roles.PURCHASER, roles.SETUP_USER]}
				path="/cart/saved/:uuid?"
				component={CartMerge}
			/>
			{/* Using AuthenticatedRoute here forces the user to redirect to the login screen if they hit a route that isn't listed above */}
			<AuthenticatedRoute path="/" key="unauthed" component={() => <div />} />
		</Switch>
	</>
);

const KeroOnlyRoutes = () => (
	<Switch>
		<AuthenticatedRoute path={ACCOUNT_CREATE_PATH} component={AccountCreate} />
		<Route path="/logout">
			<Logout />
		</Route>
		<Redirect to={ACCOUNT_CREATE_PATH} />
	</Switch>
);

const externalSite = () => (
	<Switch>
		<Route path="/external/chatLoading">
			<LiveChatLoading />
		</Route>
		<Route path="/external/chatUnavailable">
			<LiveChatUnavailable />
		</Route>
		<Route path="/external/openid/return">
			<OpenIDReturn />
		</Route>
		<Route path="/external/openid/silent-return">
			<OpenIDSilentReturn />
		</Route>
		<Route path="/external/news">
			<News />
		</Route>
		<Route path="/external/invoicepdf/:id">
			<InvoicePDFDisplay />
		</Route>
		<Route path="/external/vnc-console/:uniqId">
			<VNCConsole />
		</Route>
		<Route>
			<NotFound />
		</Route>
	</Switch>
);

const Routes = ({ isLoggedIn, isKeroOnly }) => {
	if (isLoggedIn && isKeroOnly) return <KeroOnlyRoutes />;
	if (isLoggedIn) return <AuthenticatedRoutes />;
	return <UnAuthenticatedRoutes />;
};

const PageStructure = ({ isLoggedIn, isKeroOnly }) => (
	<Box display="inline-flex" width="100%" maxHeight="100vh">
		<ScrollY display="block" flexGrow={1}>
			<Navbar />
			<AccountBar />
			{isLoggedIn && !isKeroOnly && <AccountBalanceBar />}
			<Errors />
			<GlobalComponents />
			<Routes isLoggedIn={isLoggedIn} isKeroOnly={isKeroOnly} />
		</ScrollY>
		<SideSheet />
	</Box>
);

const RouteHandler = ({ isLoggedIn, isKeroOnly }) => {
	return (
		<Switch>
			<Route path="/external" component={externalSite} />
			<Route>
				<PageStructure isLoggedIn={isLoggedIn} isKeroOnly={isKeroOnly} />
			</Route>
		</Switch>
	);
};

export default RouteHandler;
