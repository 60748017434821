import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const { actions, reducer, sagas, selectors } = createAPIModule({
	getStateSlice: (state) => state.delete.remove,
	actionType: 'DELETE_REMOVE',
	method: 'POST',
	url: '/delete/remove.json',
});

export { actions, reducer, sagas, selectors };
