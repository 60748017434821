import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.account.user.stateData.replace;

const { actions, reducer, sagas, selectors } = createAPIModule({
	getStateSlice,
	actionType: 'ACCOUNT_USER_STATEDATA_REPLACE',
	url: '/account/user/statedata/replace.json',
});

export { actions, reducer, sagas, selectors };
