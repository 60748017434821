const items = {
	'1': {
		id: '1',
		is_default: 0,
		name: 'Zone A',
		region: {
			id: 1,
			name: 'US Central',
		},
		status: 'Open',
		valid_source_hvs: {
			xen: 1,
		},
	},
	'40460': {
		id: '40460',
		is_default: 0,
		name: 'Zone C',
		region: {
			id: 1,
			name: 'US Central',
		},
		status: 'Open',
		valid_source_hvs: {
			kvm: 1,
			xen: 1,
		},
	},
	'45412': {
		id: '45412',
		is_default: 1,
		name: 'Zone D',
		region: {
			id: 1,
			name: 'US Central',
		},
		status: 'Open',
		valid_source_hvs: {
			kvm: 1,
			xen: 1,
		},
	},
	'86172': {
		id: '86172',
		is_default: 0,
		name: 'Zone F',
		region: {
			id: 2,
			name: 'US West',
		},
		status: 'Open',
		valid_source_hvs: {
			kvm: 1,
			xen: 1,
		},
	},
};

export default items;
