import { spawn } from 'redux-saga/effects';

import cpanel from './cpanel/cpanelSagas';
import { sagas as controlPanelPrices } from './controlPanelPricesModule';
import { sagas as varieties } from './varietiesModule';
import { sagas as varietyProductOptions } from './varietyProductOptionsModule';

export default function* sagas() {
	yield spawn(controlPanelPrices);
	yield spawn(cpanel);
	yield spawn(varieties);
	yield spawn(varietyProductOptions);
}
