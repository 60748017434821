import React from 'react';
import PropTypes from 'prop-types';

// MUI components
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';

// custom components
import LWTypography from 'components/common/LWTypography';

const PaperBox = ({
	title,
	titleVariant = 'subtitle2',
	padding = 4,
	'data-testid': testId,
	children,
}) => (
	<Paper data-testid={testId} component={Box} p={padding} variant="outlined">
		{title && (
			<LWTypography variant={titleVariant} bold paragraph>
				{title}
			</LWTypography>
		)}
		{children}
	</Paper>
);

PaperBox.propTypes = {
	title: PropTypes.string,
	titleVariant: PropTypes.string,
	padding: PropTypes.number,
};

export default PaperBox;
