const details = {
	paypal: {
		payment_method: 'paypal_agreement',
	},
	creditCard: {
		account_orders: '433',
		account_status: 'active',
		cc_exp: '04/2024',
		issuer: 'mastercard',
		issuer_number: '555555',
		masked_number: 'XXXX4444',
		payment_method: 'credit_card',
		payment_profile_id: '1837470191',
	},
};

export default details;
