import { combineReducers } from 'redux';

import cpanel from './cpanel/cpanelReducer';
import { reducer as controlPanelPrices } from './controlPanelPricesModule';
import { reducer as varieties } from './varietiesModule';
import { reducer as varietyProductOptions } from './varietyProductOptionsModule';

export default combineReducers({
	controlPanelPrices,
	cpanel,
	varieties,
	varietyProductOptions,
});
