import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';

const getStateSlice = (state) => state.billing.payment.profile.details;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'BILLING_PAYMENT_PROFILE_DETAILS',
	url: '/billing/payment/profile/details.json',
});

// TODO: getPaymentMethod
const paymentMethod = createSelector(
	defaultSelectors.getNativeData,
	(slice) => slice?.payment_method || '',
);

const getPaymentMethodIsPaypal = createSelector(
	paymentMethod,
	(slice) => {
		switch (slice) {
			case 'paypal':
			case 'onetime_paypal':
			case 'paypal_agreement':
				return true;
			default:
				return false;
		}
	},
);

const selectors = {
	paymentMethod,
	getPaymentMethodIsPaypal,
	maskedNumber: createSelector(
		getStateSlice,
		(number) => {
			const maskedNumber = number.getIn(['data', 'masked_number'], '');
			return maskedNumber ? `x${maskedNumber.slice(4)} ` : null;
		},
	),
	issuer: createSelector(
		getStateSlice,
		(slice) => slice.getIn(['data', 'issuer']),
	),
	ccExp: createSelector(
		getStateSlice,
		(slice) => slice.getIn(['data', 'cc_exp']),
	),
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };
