import produce from 'immer';
import { actions as addCouponActions } from 'modules/api/basket/addCouponModule';
import basketActions from '../actions';

const initialState = {
	errorText: null,
	coupon: null,
	purchase: {
		basketDetails: {},
	},
	acronis: {
		isLoading: false,
	},
};
/* eslint-disable default-case, no-param-reassign */
const reducer = (state = initialState, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case basketActions.BASKET_SAVE_PURCHASE:
				draft.purchase.basketDetails = action.basketDetails;
				break;
			case basketActions.BASKET_SET_ERROR:
				draft.errorText = action.text;
				break;
			case addCouponActions.setType:
				draft.coupon = action.payload.coupon_code;
				break;
			default:
		}
	});

export default reducer;
