import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';

const getStateSlice = (state) => state.account.auth.twoFactor.disable;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'ACCOUNT_AUTH_TWOFACTOR_DISABLE',
	url: '/account/auth/twofactor/disable.json',
});

const selectors = {
	disabled: createSelector(
		getStateSlice,
		(slice) => slice.getIn(['data', 'disabled']),
	),
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };
