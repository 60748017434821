import { call, takeLatest, select, put } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import {
	actions as assetDetailsActions,
	selectors as assetDetailsSelectors,
} from 'modules/api/asset/detailsModule';

import { actions as assetListActions } from 'modules/api/asset/listModule';

import { actions as imageRestoreActions } from 'modules/api/storm/image/restoreModule';
import { actions as backupRestoreActions } from 'modules/api/storm/backup/restoreModule';
import { actions as templateRestoreActions } from 'modules/api/storm/template/restoreModule';

import dialogActions from 'modules/dialogs/actions';

import actions from './actions';

function* fetchAssetDetails({ uniqId }) {
	yield put(
		assetDetailsActions.fetch({
			uniq_id: uniqId,
			alsowith: ['instance', 'featureDetails'],
		}),
	);
}
function* initializeServer({ uniqId }) {
	const detailsUniqId = yield select(assetDetailsSelectors.uniqId);
	if (detailsUniqId !== uniqId) {
		yield call(fetchAssetDetails, { uniqId });
	}
}

function* openImageConfirmDialog({ id, uniqId } = {}) {
	if (uniqId) {
		yield call(initializeServer, { uniqId });
	}
	yield put(
		dialogActions.open({
			color: 'danger',
			contentKey: 'ImageConfirmationDialogContents',
			contentProps: { uniqId, id },
			dialogProps: {
				maxWidth: 'md',
			},
		}),
	);
}

function* openImageDialog({ id, uniqId } = {}) {
	if (uniqId) {
		yield call(initializeServer, { uniqId });
	}
	if (!uniqId) {
		yield put(
			assetListActions.fetch({
				category: ['CloudBareMetal', 'CloudVPS'],
				page_size: 9999,
			}),
		);
	}
	yield put(
		dialogActions.open({
			contentKey: uniqId
				? 'ImageConfirmationDialogContents'
				: 'ImageServerSelectDialogContents',
			color: uniqId ? 'danger' : 'initial',
			contentProps: { uniqId, id },
			dialogProps: {
				maxWidth: uniqId ? 'md' : 'sm',
			},
		}),
	);
}
function* restoreImage({ force, id, uniqId }) {
	yield put(
		imageRestoreActions.fetch({
			force,
			id,
			uniq_id: uniqId,
		}),
	);
	yield put(dialogActions.close());
	yield put(push(`/go/${uniqId}`));
}

function* restoreBackup({ force, id, uniqId }) {
	yield put(
		backupRestoreActions.fetch({
			force,
			id,
			uniq_id: uniqId,
		}),
	);
	yield put(dialogActions.close());
	yield put(push(`/go/${uniqId}`));
}

function* restoreTemplate({ force, id, uniqId }) {
	yield put(
		templateRestoreActions.fetch({
			force,
			id,
			uniq_id: uniqId,
		}),
	);
	yield put(dialogActions.close());
	yield put(push(`/go/${uniqId}`));
}

function* root() {
	yield takeLatest(
		actions.BACKUPS_AND_IMAGES_RESTORE_OPEN_IMAGE_DIALOG,
		openImageDialog,
	);
	yield takeLatest(
		actions.BACKUPS_AND_IMAGES_RESTORE_OPEN_IMAGE_CONFIRM_DIALOG,
		openImageConfirmDialog,
	);
	yield takeLatest(
		actions.BACKUPS_AND_IMAGES_RESTORE_INITIALIZE_SERVER,
		initializeServer,
	);
	yield takeLatest(actions.BACKUPS_AND_IMAGES_RESTORE_IMAGE, restoreImage);
	yield takeLatest(actions.BACKUPS_AND_IMAGES_RESTORE_BACKUP, restoreBackup);
	yield takeLatest(
		actions.BACKUPS_AND_IMAGES_RESTORE_TEMPLATE,
		restoreTemplate,
	);
}

export default root;
