import { put, takeLatest } from 'redux-saga/effects';
import interworxActions from 'modules/interworx/actions';
import { actions as controlPanelUpdateActions } from 'modules/api/salesforce/account/interworx/updateModule';
import snackbarActions from 'modules/snackbar/snackbarActions';

function* handleUpdate({ controlPanel }) {
	yield put(
		controlPanelUpdateActions.fetch({ interworx_migration: controlPanel }),
	);
}

function* handleSuccess() {
	// Display message once updating pref is successful
	yield put(
		snackbarActions.pushMessage('Control panel preference updated', 'success'),
	);

	yield put(controlPanelUpdateActions.clear());
}

export default function* rootSaga() {
	yield takeLatest(interworxActions.UPDATE, handleUpdate);
	yield takeLatest(controlPanelUpdateActions.setType, handleSuccess);
}
