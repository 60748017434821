import { put, takeLatest, call, take } from 'redux-saga/effects';
// TODO, set state from response instead of calling details.
// import { actions as itemActions } from 'modules/api/basket/item/detailsModule';
import { actions as cloneActions } from 'modules/api/basket/item/cloneModule';
import basketActions from 'modules/basket/actions';
import snackbarSagas from 'modules/snackbar/sagas';

function* cloneItemSaga({ payload: { uuid, quantity } }) {
	yield put(
		cloneActions.fetch({
			uuid,
			quantity,
		}),
	);
	const result = yield take([cloneActions.setType, cloneActions.errorType]);
	const error = result?.type === cloneActions.errorType;
	yield call(snackbarSagas, {
		error,
		errorMessage: 'Failed to clone item',
		successMessage: 'Successfully cloned item',
	});
}

export { cloneItemSaga };

export default function* cloneItemRoot() {
	yield takeLatest(basketActions.BASKET_CLONE_ITEM, cloneItemSaga);
}
