import { createAPIModule } from 'utility/redux/apiModuleHelpers';
// import { createSelector } from 'reselect';

const getStateSlice = (state) => state.storage.block.volume.delete;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'STORAGE_BLOCK_VOLUME_DELETE',
	method: 'POST',
	url: '/storage/block/volume/delete.json',
});

const selectors = {
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };
