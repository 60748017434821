import { combineReducers } from 'redux';

import { reducer as details } from './detailsModule';
import { reducer as list } from './listModule';
import { reducer as possibleNodes } from './possibleNodesModule';
import { reducer as available } from './availableModule';
import { reducer as update } from './updateModule';
import { reducer as strategies } from './strategiesModule';

export default combineReducers({
	details,
	list,
	possibleNodes,
	available,
	update,
	strategies,
});
