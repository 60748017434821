import React from 'react';
import ServerRebootDialog from 'containers/pages/servers/RebootDialog';

const ServerGlobalComponents = () => (
	<React.Fragment>
		<ServerRebootDialog />
	</React.Fragment>
);

export default ServerGlobalComponents;
