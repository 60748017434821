import { createSelector } from 'reselect';
import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { selectors as assetListSelectors } from 'modules/api/asset/listModule';

const getStateSlice = (state) => state.storage.block.volume.list;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'STORAGE_BLOCK_VOLUME_LIST',
	method: 'POST',
	url: '/storage/block/volume/list.json',
});

// TODO make object maps to reduce operation complexity
const itemsFilteredByAsset = createSelector(
	defaultSelectors.getNativeItems,
	assetListSelectors.getNativeItems,
	(volumes, assets) =>
		volumes.filter((volume) =>
			// Need to list only volumes that have matching asset data
			assets.find((asset) => asset.uniq_id === volume.uniq_id),
		),
);

const selectors = {
	itemsFilteredByAsset,
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };
