import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LWDialog from 'components/common/LWDialog';
import GenericError from 'components/common/GenericError';
import LWTypography from 'components/common/LWTypography';
import ForceReboot from 'components/pages/server/reboot/ForceReboot';
import RebootButton from 'components/pages/server/reboot/RebootButton';
import DialogActions from '@material-ui/core/DialogActions';
import LWButton from 'components/common/LWButton';

const RebootDialog = ({
	closeDialog,
	canAutoReboot,
	isOpen = false,
	domain = 'Server',
	uniqId,
	invokeReboot,
	serverRebootResult,
	history,
}) => {
	const [forceReboot, setForceReboot] = useState(false);

	const { error: rebootError } = serverRebootResult;

	const handleMoreOptionsClick = () => {
		closeDialog();
		history.push(`/servers/details/${uniqId}/reboot`);
	};

	return (
		<LWDialog
			title={`Reboot ${domain}`}
			onClose={closeDialog}
			open={isOpen}
			maxWidth="sm"
			fullWidth
		>
			{!!rebootError && (
				<Box mb={2}>
					<GenericError shown />
				</Box>
			)}

			<Grid container alignItems="center">
				{canAutoReboot && (
					<ForceReboot
						forceReboot={forceReboot}
						setForceReboot={setForceReboot}
					/>
				)}

				{!canAutoReboot && (
					<LWTypography variant="body1">
						A reboot request will be submitted, and a Helpful Human will respond
						shortly.
					</LWTypography>
				)}
			</Grid>
			<DialogActions>
				<LWButton color="tertiary" onClick={closeDialog}>
					Cancel
				</LWButton>
				{canAutoReboot && (
					<LWButton color="tertiary" onClick={handleMoreOptionsClick}>
						More Options
					</LWButton>
				)}
				<RebootButton
					canAutoReboot={canAutoReboot}
					serverRebootResult={serverRebootResult}
					onClick={() => {
						invokeReboot({ forceReboot, uniqId });
					}}
				/>
			</DialogActions>
		</LWDialog>
	);
};

RebootDialog.propTypes = {
	closeDialog: PropTypes.func.isRequired,
	canAutoReboot: PropTypes.bool.isRequired,
	isOpen: PropTypes.bool.isRequired,
	domain: PropTypes.string,
	uniqId: PropTypes.string,
	invokeReboot: PropTypes.func.isRequired,
	serverRebootResult: PropTypes.object,
	history: PropTypes.object.isRequired,
};

export { RebootDialog };

export default withRouter(RebootDialog);
