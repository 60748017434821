import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.network.ip.remove;

const { actions, reducer, sagas, selectors } = createAPIModule({
	getStateSlice,
	actionType: 'NETWORK_IP_REMOVE',
	method: 'POST',
	url: '/network/ip/remove.json',
});

export { actions, reducer, sagas, selectors };
