import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@material-ui/core/Box';
import ListItem from '@material-ui/core/ListItem';
import LWTypography from 'components/common/LWTypography';
import { selectors as usertaskListSelectors } from 'modules/api/usertask/listModule';
import taskSchedulerActions from 'modules/server/taskScheduler/actions';

const SListItem = styled(ListItem)`
	padding-top: ${({ theme }) => theme.spacing(0.5)}px;
	padding-bottom: ${({ theme }) => theme.spacing(0.5)}px;
	display: flex;
	justify-content: space-between;
`;

const ScrollBox = styled(Box)`
	overflow-y: auto;
	max-height: ${({ theme }) => theme.spacing(12.5)}px;
`;

const CurrentTaskList = ({ uniqId }) => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(taskSchedulerActions.getCurrentTasks(uniqId));
	}, [dispatch, uniqId]);

	const tasks =
		useSelector(usertaskListSelectors?.getNativeState)?.data?.items || [];

	if (tasks.length === 0) return null;

	return (
		<>
			<Box mt={4} display="flex" justifyContent="space-between">
				<LWTypography variant="subtitle2" color="textSecondary">
					Other scheduled tasks
				</LWTypography>
				<LWTypography variant="subtitle2" color="textSecondary">
					Date / Time
				</LWTypography>
			</Box>
			<ScrollBox>
				{tasks.map((task) => {
					const { action, scheduled_date: date } = task;

					const momentDate = moment(date);
					return (
						<SListItem disableGutters divider key={momentDate.format()}>
							<LWTypography>{action}</LWTypography>
							<Box display="flex" alignItems="center">
								<LWTypography BoxProps={{ mr: 1 }}>{`${momentDate.fromNow(
									true,
								)} from now`}</LWTypography>
								<LWTypography
									variant="body2"
									color="textSecondary"
								>{`(${momentDate.format(
									'MM/DD/YYYY hh:mm a Z',
								)})`}</LWTypography>
							</Box>
						</SListItem>
					);
				})}
			</ScrollBox>
		</>
	);
};

CurrentTaskList.propTypes = {
	uniqId: PropTypes.string.isRequired,
};

export default CurrentTaskList;
