import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';
import { List } from 'immutable';

const getStateSlice = (state) => state.account.supportSummary;

const {
	actions,
	reducer,
	sagas,
	selectors: supportSummarySelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'ACCOUNT_SUPPORTSUMMARY',
	url: '/account/supportsummary.json',
});

const getSupportTickets = createSelector(
	getStateSlice,
	(supportSummary) =>
		supportSummary.getIn(['data', 'tickets', 'support'], List()),
);

const selectors = {
	getSupportTickets,
	...supportSummarySelectors,
};

export { actions, reducer, sagas, selectors };
