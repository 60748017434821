const initialState = {
	selectedTags: {},
	activePrimaryTag: '',
	page: 1,
	itemsPerPage: 8,
	selectedTab: '',
};

const marketplace = (state = initialState, action) => {
	const {
		type,
		tags,
		activePrimaryTag,
		page,
		itemsPerPage,
		selectedTab,
	} = action;
	switch (type) {
		case 'SET_MARKETPLACE_TAGS':
			return {
				...state,
				selectedTags: { ...state.selectedTags, [state.activePrimaryTag]: tags },
			};
		case 'SET_PRIMARY_MARKETPLACE_TAG':
			return { ...state, activePrimaryTag };
		case 'SET_MARKETPLACE_PAGE': // used for pagination.
			return { ...state, page };
		case 'SET_MARKETPLACE_ITEMS_PER_PAGE': // used for pagination.
			return {
				...state,
				itemsPerPage,
				page: Math.floor((state.itemsPerPage / itemsPerPage) * (page - 1)) + 1,
			};
		case 'SET_MARKETPLACE_SELECTED_TAB': // used to recall the selected tab regardless of hard-coded or data-driven.
			return { ...state, selectedTab };
		default:
			return state;
	}
};

export default marketplace;
