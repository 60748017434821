import { put, select } from 'redux-saga/effects';
import { actions as assertMetadataActions } from 'modules/api/basket/assertMetadataModule';
import { selectors as detailsSelectors } from 'modules/api/basket/detailsModule';

import AttributionData from './AttributionData';

// This saga should always come after a uuid is established.
function* update() {
	const attributionData = new AttributionData();
	if (attributionData.referral || attributionData.attribution) {
		const uuid = yield select(detailsSelectors.getBasketUuid);
		yield put(
			assertMetadataActions.fetch({
				uuid,
				metadata: attributionData.all,
			}),
		);
	}
}

export default { update };
