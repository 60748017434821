import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.account.signup;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'ACCOUNT_SIGNUP',
	url: '/account/signup.json',
});

const selectors = { ...defaultSelectors };

export { actions, reducer, sagas, selectors };
