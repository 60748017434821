import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.network.ip.add;

const { actions, reducer, sagas, selectors } = createAPIModule({
	getStateSlice,
	actionType: 'NETWORK_IP_ADD',
	method: 'POST',
	url: '/network/ip/add.json',
});

export { actions, reducer, sagas, selectors };
