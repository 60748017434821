import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';

const getStateSlice = (state) => state.monitoring.load.graph;

const { actions, reducer, sagas, selectors: moduleSelectors } = createAPIModule(
	{
		getStateSlice,
		actionType: 'MONITORING_LOAD_GRAPH',
		method: 'POST',
		url: '/monitoring/load/graph.json',
	},
);

const content = createSelector(
	getStateSlice,
	(slice) => slice.getIn(['data', 'content']),
);

const selectors = {
	content,
	...moduleSelectors,
};

export { actions, reducer, sagas, selectors };
