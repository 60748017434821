import { takeLatest, put, select } from 'redux-saga/effects';
import { actions as filterTagActions } from 'modules/api/product/marketplace/productsForTagsModule';
import { actions as primaryTagActions } from 'modules/api/tags/product/primaryModule';
import { actions as marketplaceActions } from '..';
import marketplaceSelectors from '../selectors';

function* handleSetTags() {
	const { activePrimaryTag, selectedTags, page, itemsPerPage } = yield select(
		marketplaceSelectors,
	);

	yield put(
		filterTagActions.fetch({
			tags: {
				one_of: [activePrimaryTag],
				must_have: selectedTags[activePrimaryTag],
			},
			page_num: page,
			page_size: itemsPerPage,
			alsowith: ['product', ['product', 'features', 'marketplacePrices']],
			order_by: { field: 'display_order', sort: 'asc' },
		}),
	);
}

// When the primary tags load, put the first result as the selected tag if no activePrimaryTag exists.
// activePrimaryTag will have been passed in from the url and set on init.
function* handleInitPrimaryTag({ payload }) {
	const initTag = payload?.tags?.[0];
	const { activePrimaryTag } = yield select(marketplaceSelectors);
	yield put(marketplaceActions.setPrimaryTag(activePrimaryTag || initTag));
}

function* filterSaga() {
	yield takeLatest(
		[
			marketplaceActions.SET_MARKETPLACE_TAGS,
			marketplaceActions.SET_PRIMARY_MARKETPLACE_TAG,
			marketplaceActions.SET_MARKETPLACE_PAGE,
			marketplaceActions.SET_MARKETPLACE_ITEMS_PER_PAGE,
		],
		handleSetTags,
	);
	yield takeLatest(primaryTagActions.setType, handleInitPrimaryTag);
}

export default filterSaga;
