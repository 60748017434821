import React from 'react';
import LWLink from 'components/common/LWLink';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCartRounded';
import IconButton from '@material-ui/core/IconButton';
import styled from 'styled-components';
import Badge from '@material-ui/core/Badge';
import PropTypes from 'prop-types';

const SLink = styled(LWLink)`
	svg {
		fill: white;
	}
	.MuiCircularProgress-root {
		color: white;
	}
	display: inline-flex;
	position: relative;
`;

const CompactCartSummary = ({ items }) => {
	return (
		<SLink to="/cart">
			<IconButton>
				<Badge badgeContent={items.length} color="primary">
					<ShoppingCartIcon />
				</Badge>
			</IconButton>
		</SLink>
	);
};

CompactCartSummary.propTypes = {
	items: PropTypes.array,
};

export default CompactCartSummary;
