import { spawn } from 'redux-saga/effects';

import config from './config/configSagas';
import { sagas as remove } from './removeModule';
import { sagas as add } from './addModule';
import { sagas as details } from './detailsModule';
import { sagas as modify } from './modifyModule';
import { sagas as bulkModify } from './bulkModifyModule';
import { sagas as reset } from './resetModule';
import { sagas as extraOptions } from './extraOptionsModule';
import { sagas as clone } from './cloneModule';
import { sagas as assertProperties } from './assertPropertiesModule';
import { sagas as swap } from './swapModule';

export default function* sagas() {
	yield spawn(bulkModify);
	yield spawn(config);
	yield spawn(extraOptions);
	yield spawn(remove);
	yield spawn(add);
	yield spawn(details);
	yield spawn(modify);
	yield spawn(reset);
	yield spawn(clone);
	yield spawn(assertProperties);
	yield spawn(swap);
}
