import { createSelector } from 'reselect';

import { selectors as itemDetailsSelectors } from 'modules/api/basket/item/detailsModule';

const getStateSlice = (state) => state.basket.productConfig;

const deployOnto = createSelector(
	getStateSlice,
	(slice) => slice.deployOnto,
);

const generateGetNeedsSwap = (value) => {
	return createSelector(
		itemDetailsSelectors.isCloudDedicated,
		itemDetailsSelectors.isVps,
		(isCloudDedicated, isVps) =>
			(isCloudDedicated && value !== 'cloudDedicated') ||
			(isVps && value === 'cloudDedicated'),
	);
};

const selectors = { getStateSlice, deployOnto };

export { getStateSlice, deployOnto, generateGetNeedsSwap };
export default selectors;
