import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';

const SIconButton = styled(IconButton)`
	background-color: ${({ theme }) => theme.palette.common.white};
	border: 1px solid ${({ theme }) => theme.palette.common.grey[2]};
	color: ${(p) =>
		p.$variant === 'emphasized' ? p.theme.palette.primary.dark : 'inherit'};
`;

export default SIconButton;
