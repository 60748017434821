import { reducer as appConfig } from 'modules/api/appConfigModule';
import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as form } from 'redux-form';
import { immutableReducer as reduxOidc } from 'redux-oidc';
import account from './api/account/accountReducer';
import accountRedux from './account/accountReducer';
import acronis from './acronis/reducer';
import addToProjectDialog from './addToProjectDialog/reducer';
import asset from './api/asset/assetReducer';
import basket from './basket/reducer';
import basketDomains from './basket/reducer/domains';
import basketApi from './api/basket/basketReducer';
import billing from './api/billing/billingReducer';
import cart from './cart/reducer';
import chat from './chat/reducer';
import contact from './api/contact/contactReducer';
import deleteProjectDialog from './deleteProjectDialog/reducer';
import renameProjectDialog from './renameProjectDialog/reducer';
import domain from './api/domain/domainReducer';
import errors from './error/errorReducer';
import firewallPresets from './firewallPresets/reducer';
import internalOnly from './internalOnly/reducer';
import gtm from './gtm/reducer';
import licensing from './api/licensing/licensingReducer';
import maintenanceMode from './maintenanceMode/reducer';
import manageRedirect from './manageRedirect/manageRedirectReducer';
import marketplace from './marketplace/reducer';
import monitoring from './api/monitoring/monitoringReducer';
import network from './api/network/networkReducer';
import notifications from './api/notifications/notificationsReducer';
import onetimesecret from './api/onetimesecret/onetimesecretReducer';
import product from './api/product/productReducer';
import project from './api/project/projectReducer';
import projectAddDialog from './projectAddDialog/reducer';
import salesforce from './api/salesforce/salesforceReducer';
// TODO: give api state it's own layer so that we don't have this naming conflict on server.
import server from './api/server/serverReducer';
import serverStore from './server/reducer';
import sideSheet from './sideSheet/reducer';
import statusSheet from './statusSheet/reducer';
import dialogs from './dialogs/reducer';
import snackbar from './snackbar/snackbarReducer';
import sshkeys from './sshkeys/reducer';
import sslCertificate from './api/ssl/certificate/sslCertificateReducer';
import storage from './api/storage/storageReducer';
import storm from './api/storm/stormReducer';
import support from './api/support/supportReducer';
import usertask from './api/usertask/usertaskReducer';
import tags from './api/tags/tagsReducer';
import transactionTimeline from './billing/transactionTimeline/reducer';
import vpn from './api/vpn/vpnReducer';
import acronisAPI from './api/acronis/acronisReducer';
import www from './www-api/wwwReducer';
import paymentForm from './paymentForm/reducer';
import payment from './payment/paymentReducer';
import unBlockIPDialog from './unblockIPDialog/unblockIPDialogReducer';
import referAFriendDialog from './referAFriendDialog/reducer';
import utilities from './api/utilities/utilitiesReducer';
import openid from './openid/openidReducer';
import serverReboot from './server/reboot/reducer';
import serverBackupsAndImages from './server/backupsAndImages/reducer';
import taskScheduler from './server/taskScheduler/reducer';
import usertaskOperations from './usertask/reducer';
import disallowedUser from './disallowedUser/reducer';
import notificationsReducer from './notifications/reducer'; // need an API reducer module, then just call this notifications
import renewDialog from './renewDialog/reducer';
import { reducer as threatStack } from './threatStack';

export default (history) =>
	combineReducers({
		accountRedux,
		acronisAPI,
		acronis,
		addToProjectDialog,
		projectAddDialog,
		asset,
		renewDialog,
		server,
		serverStore,
		network,
		renameProjectDialog,
		deleteProjectDialog,
		sslCertificate,
		basket,
		basketApi,
		paymentForm,
		support,
		www,
		cart,
		manageRedirect,
		marketplace,
		salesforce,
		snackbar,
		usertask,
		vpn,
		transactionTimeline,
		payment,
		appConfig,
		router: connectRouter(history),
		account,
		form,
		notifications,
		billing,
		maintenanceMode,
		monitoring,
		domain,
		basketDomains,
		licensing,
		contact,
		chat,
		sshkeys,
		onetimesecret,
		unBlockIPDialog,
		referAFriendDialog,
		utilities,
		errors,
		reduxOidc,
		openid,
		product,
		project,
		// TODO: have a hierarchy of reducers in the server folder and adjust selectors
		serverReboot,
		serverBackupsAndImages,
		usertaskOperations,
		disallowedUser,
		notificationsReducer,
		storage,
		storm,
		internalOnly,
		sideSheet,
		statusSheet,
		dialogs,
		firewallPresets,
		gtm,
		tags,
		taskScheduler,
		threatStack,
	});
