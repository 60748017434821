import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';
import { List as ImmutableList } from 'immutable';

const getStateSlice = (state) => state.account.adCampaigns;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'ACCOUNT_ADCAMPAIGNS',
	url: '/account/adCampaigns.json',
});

const getAds = createSelector(
	getStateSlice,
	(slice) => slice.getIn(['data', 'campaigns'], ImmutableList()),
);

const getRandomAd = createSelector(
	getStateSlice,
	(slice) =>
		slice
			.getIn(['data', 'campaigns'], ImmutableList())
			.sortBy(Math.random)
			.get(0),
);

const selectors = {
	getAds,
	getRandomAd,
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };
