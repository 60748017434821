import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';
import { List as ImmutableList } from 'immutable';

const getStateSlice = (state) => state.support.systemStatus.incidentSummary;

const { actions, reducer, sagas, selectors: moduleSelectors } = createAPIModule(
	{
		getStateSlice,
		actionType: 'SUPPORT_SYSTEM_STATUS_INCIDENTSUMMARY',
		method: 'POST',
		url: '/support/systemStatus/incidentSummary.json',
	},
);

const currentIncidents = createSelector(
	getStateSlice,
	(slice) =>
		slice
			.getIn(['data', 'cloudsites', 'unresolved'], ImmutableList())
			.concat(slice.getIn(['data', 'liquidweb', 'unresolved'], ImmutableList()))
			.sort(
				(a, b) => new Date(b.get('created_at')) - new Date(a.get('created_at')),
			),
);

const upcomingIncidents = createSelector(
	getStateSlice,
	(slice) =>
		slice
			.getIn(['data', 'cloudsites', 'upcoming'], ImmutableList())
			.concat(slice.getIn(['data', 'liquidweb', 'upcoming'], ImmutableList()))
			.sort(
				(a, b) =>
					new Date(b.get('scheduled_for')) - new Date(a.get('scheduled_for')),
			),
);

const selectors = {
	currentIncidents,
	upcomingIncidents,
	...moduleSelectors,
};

export { actions, reducer, sagas, selectors };
