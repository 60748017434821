import { call, put, takeLatest, select, take } from 'redux-saga/effects';
import { actions as assertMetadataActions } from 'modules/api/basket/assertMetadataModule';
import snackbarActions from 'modules/snackbar/snackbarActions';

import {
	actions as detailsActions,
	selectors as detailsSelectors,
} from 'modules/api/basket/detailsModule';
import _ from 'lodash';
import { BASKET_UUID } from 'utility/constants/baskets';

import basketActions from '../../actions';

function* assertMetadataSaga({ metadata }) {
	const uuid = yield call([localStorage, 'getItem'], BASKET_UUID);

	yield put(
		assertMetadataActions.fetch({
			uuid,
			metadata,
		}),
	);
	const oldBasketDetails = yield select(detailsSelectors.getNativeData);
	const payload = (yield take(assertMetadataActions.setType))?.payload;
	yield put(detailsActions.set(_.merge(oldBasketDetails, payload)));
	if (metadata.additionalInstructions)
		yield put(snackbarActions.pushMessage('Additional Instructions saved.'));
}

export { assertMetadataSaga };

export default function* assertMetadataRoot() {
	yield takeLatest(basketActions.BASKET_ASSERT_METADATA, assertMetadataSaga);
}
