import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import {
	actions as deleteActions,
	selectors as deleteSelectors,
} from 'modules/api/project/deleteModule';
import { actions as listActions } from 'modules/api/project/listModule';
import { selectors } from 'modules/deleteProjectDialog/selectors';
import snackbarActions from 'modules/snackbar/snackbarActions';
import actions from 'modules/deleteProjectDialog/actions';
import DeleteDialog from './DeleteDialog';

const mapStateToProps = (state) => ({
	deleted: deleteSelectors.hasData(state),
	isOpen: selectors.isOpen(state),
	dialogProps: selectors.dialogProps(state),
});

const mapDispatchToProps = (dispatch) => ({
	clearDelete: () => dispatch(deleteActions.clear()),
	deleteProject: ({ projectId }) =>
		dispatch(deleteActions.fetch({ id: projectId })),
	displaySnackbar: (message) => dispatch(snackbarActions.pushMessage(message)),
	fetchList: () => dispatch(listActions.fetch()),
	toggleDialog: (value) => dispatch(actions.toggleDialog(value)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withRouter(DeleteDialog));
