import { put, takeLatest, take, select } from 'redux-saga/effects';
import { actions as setNameActions } from 'modules/api/basket/setNameModule';
import {
	actions as detailsActions,
	selectors as detailsSelectors,
} from 'modules/api/basket/detailsModule';
import basketActions from 'modules/basket/actions';
import _ from 'lodash';

function* setCartNameSaga({ payload: { name } }) {
	const uuid = yield select(detailsSelectors.getBasketUuid);
	yield put(setNameActions.fetch({ uuid, name }));
	const returnedData = (yield take(setNameActions.setType))?.payload;
	const oldBasketDetails = yield select(detailsSelectors.getNativeData);

	yield put(detailsActions.set(_.merge(oldBasketDetails, returnedData)));
}

export { setCartNameSaga };

export default function* setCartNameRoot() {
	yield takeLatest(basketActions.BASKET_SET_CART_NAME, setCartNameSaga);
}
