import { round } from 'lodash';

const float = (num, fixedDigits = 2) => {
	return parseFloat(num).toFixed(fixedDigits);
};

const currency = (
	num,
	options = { dollarSign: true, commas: true, raw: false },
) => {
	let formattedNum;

	// Return the passed in values if invalid.
	if (Number.isNaN(num) || num === '' || num === null || num === undefined) {
		return '';
	}
	const fixedDigits = Object.prototype.hasOwnProperty.call(
		options,
		'fixedDigits',
	)
		? options.fixedDigits
		: 2;
	formattedNum = float(num, fixedDigits);
	const raw = formattedNum;
	if (options.commas) {
		formattedNum = (formattedNum += '').replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	}
	if (options.dollarSign) {
		formattedNum = (formattedNum += '').replace(/(^-?)/, '$1$');
	}
	if (options.sign && !/^-/.test(formattedNum)) {
		formattedNum = `+${formattedNum}`;
	}
	if (options.raw) {
		return { display: formattedNum, raw };
	}

	return formattedNum;
};

const bytes = (inSize) => {
	const size = typeof inBytes === 'string' ? parseFloat(inSize) : inSize;
	return size >= 1000 ? `${size / 1000}TB` : `${size}GB`;
};

// not to be confused with giga bytes.
const toGibibyte = (megabitsArg) => {
	const megabits = Number(megabitsArg);
	return megabits < 1024 ? `${megabits}MB` : `${round(megabits / 1024, 1)}GB`; // TODO: label 'GiB'?
};

const perMonth = (aNumber, options) => {
	return `${currency(aNumber, options)} / mo`;
};

const perYear = (aNumber) => {
	return `${currency(aNumber)} / yr`;
};

// This regex should not be relied upon as a secure way of sanitizing input.
// For more information: https://stackoverflow.com/questions/5002111/how-to-strip-html-tags-from-string-in-javascript
const stripHTML = (text = '') => text.replace(/<\/?[^>]+(>|$)/g, '');

const toInt = (number) =>
	Math.round(
		typeof number === 'number'
			? number
			: Number(number?.replace(/[a-zA-Z]/g, '') || 0),
	);

const toNumberOrEmptyString = (number) => {
	if (typeof number === 'number' || number === '') return number;
	const converted = Number(number);
	if (Number.isNaN(converted)) return '';
	return converted;
};

const formatZone = (item) => {
	return `${item?.name} - ${item?.region?.name}`;
};

const zone = (items, zoneId) => {
	const data = items.find((item) => item.id === zoneId);
	return formatZone(data);
};

export {
	currency,
	bytes,
	toGibibyte,
	float,
	perMonth,
	perYear,
	stripHTML,
	toInt,
	toNumberOrEmptyString,
	zone,
	formatZone,
};
