import { getClientId, getTrackingId } from '../google-analytics';

const formatDetails = ([clientId, trackingId]) => {
	if (!clientId || !trackingId) {
		return [
			{
				label: 'googleTrackingUnavailable',
				value: true,
				displayToAgent: false,
			},
		];
	}

	return [
		{
			label: 'googleClientId',
			value: clientId,
			displayToAgent: true,
			transcriptFields: ['Google_Client_Id__c'],
		},
		{
			label: 'googleTrackingId',
			value: trackingId,
			displayToAgent: true,
			transcriptFields: ['Google_Tracking_Id__c'],
		},
	];
};

const getInitialExtraDetails = () => (
	Promise.all([getClientId(), getTrackingId()])
		.then(formatDetails)
);

export default getInitialExtraDetails;
