import { combineReducers } from 'redux';

import account from './account/accountReducer';
import article from './article/articleReducer';
import chat from './chat/chatReducer';

export default combineReducers({
	account,
	article,
	chat,
});
