// used to sort ProductOptionSet.

const productOptionSortValue = ({ price, value }) => {
	// i.e. ['100 GB', '1 TB'] converts to GB and returns a number.
	let valueArray = value.toString().split(' ');
	if (valueArray[1] === 'TB') {
		return Number(valueArray[0]) * 1000;
	}

	if (valueArray[1] === 'GB') {
		return Number(valueArray[0]);
	}

	// 'l7b-mh-1.db8' takes the number from the end and returns it as a number
	valueArray = value.toString().split('.');
	if (valueArray[1]) {
		const myString = valueArray[1].replace(/[a-z|A-Z]/g, '');
		return Number(myString);
	}

	return Number(price);
};

export default productOptionSortValue;
