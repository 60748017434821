import { put, take, select, takeLatest } from 'redux-saga/effects';
import {
	actions as whatsMyIpActions,
	selectors as whatsMyIpSelectors,
} from 'modules/api/utilities/info/whatismyipModule';
import {
	actions as officeIpActions,
	selectors as officeIpSelectors,
} from 'modules/api/network/util/isOfficeIpModule';
import internalOnlyActions from './actions';

function* checkInternalUser({ payload }) {
	const guest = payload?.guest;
	// First get the users IP address
	if (guest) yield put(whatsMyIpActions.fetchGuestAuth());
	else yield put(whatsMyIpActions.fetch());

	yield put(whatsMyIpActions.fetch());
	yield take(whatsMyIpActions.setType);
	const ip = yield select(whatsMyIpSelectors.ip);

	// Next, check if that IP is internal
	if (guest) yield put(officeIpActions.fetchGuestAuth({ ip }));
	else yield put(officeIpActions.fetch({ ip }));

	yield take(officeIpActions.setType);
	const isOfficeIp = yield select(officeIpSelectors.isOfficeIp);

	yield put(internalOnlyActions.set(isOfficeIp));
}

export default function* rootSaga() {
	yield takeLatest(internalOnlyActions.internalOnlyCheck, checkInternalUser);
}
