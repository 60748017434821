import { combineReducers } from 'redux';

import { reducer as details } from './detailsModule';
import { reducer as cancelTransfer } from './cancelTransferModule';
import { reducer as createOutgoing } from './createOutgoingModule';
import { reducer as createIncoming } from './createIncomingModule';
import { reducer as deleteReducer } from './deleteModule';
// deleteReducer because delete is a reserved keyword
import { reducer as list } from './listModule';
import { reducer as resendEmail } from './resendEmailModule';
import { reducer as sendEPPCode } from './sendEPPCodeModule';

export default combineReducers({
	cancelTransfer,
	createOutgoing,
	createIncoming,
	delete: deleteReducer,
	details,
	list,
	resendEmail,
	sendEPPCode,
});
