const actions = {
	OPEN: 'STATUSSHEET_OPEN',
	open: () => ({
		type: actions.OPEN,
	}),
	CLOSE: 'STATUSSHEET_CLOSE',
	close: () => ({
		type: actions.CLOSE,
	}),
	HIDE_NOTIFICATIONS: 'STATUSSHEET_HIDE_NOTIFICATIONS',
	hideNotifications: (ids) => ({
		type: actions.HIDE_NOTIFICATIONS,
		ids,
	}),
	DISMISS_NOTIFICATION: 'STATUSSHEET_DISMISS_NOTIFICATION',
	dismissNotification: (id) => ({
		type: actions.DISMISS_NOTIFICATION,
		id,
	}),
	SET_HEIGHT: 'STATUSSHEET_SET_HEIGHT',
	setHeight: (size) => ({
		type: actions.SET_HEIGHT,
		size,
	}),
};

export default actions;
