import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

export const styles = (theme) => ({
	root: {
		boxShadow: 'none',
		zIndex: theme.zIndex.appBar,
		backgroundColor: theme.palette.background.default,
	},
	container: {
		width: '100%',
		maxWidth: theme.maxWidth,
		margin: 'auto',
	},
	toolbar: {
		paddingRight: 0,
		paddingLeft: 0,
		zIndex: theme.zIndex.appBar,
		height: '100%',
		justifyContent: 'space-between',
	},
});

const NavAppBar = (props) => {
	const { classes, children } = props;
	return (
		<AppBar position="sticky" className={classes.root}>
			<div className={classes.container}>
				<Toolbar className={classes.toolbar}>{children}</Toolbar>
			</div>
		</AppBar>
	);
};

NavAppBar.propTypes = {
	classes: PropTypes.object.isRequired,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
};

export default withStyles(styles)(NavAppBar);
