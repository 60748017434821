const defaultConfig = {
	// embedded_svc initialization parameters
	baseCoreURL: 'https://liquidweb.my.salesforce.com',
	communityEndpointURL: 'https://help.liquidweb.com/public',
	gslbBaseURL: 'https://service.force.com',
	orgId: '00D30000000pmDy',
	eswConfigDevName: 'Nexcess', // TODO - use LW as default
	settings: {
		baseLiveAgentContentURL: 'https://c.la3-c2-ph2.salesforceliveagent.com/content',
		deploymentId: '5720c0000004aCZ',
		buttonId: '5733a00000000EN', // TODO - get LW default button id
		baseLiveAgentURL: 'https://d.la3-c2-ph2.salesforceliveagent.com/chat',
		eswLiveAgentDevName: 'EmbeddedServiceLiveAgent_Parent04I3a0000008OJXEA2_174e9c371a7',
		isOfflineSupportEnabled: false,
		chatbotAvatarImgURL: 'https://media.liquidweb.com/salesforce/img/liquid-web-logo.jpg',
	},

	// Embedded Service for Web script location
	eswScriptURL: 'https://service.force.com/embeddedservice/5.0/esw.min.js',

	// Whether the built in chat button should be used
	displayHelpButton: false,

	// Registrable domain where embedded_svc should store its cookies
	storageDomain: 'liquidweb.com',

	// When the chat button is clicked, the amount of time
	// to wait for the ESW script to report "ready" before
	// assuming something went wrong. Not necessarily a hard
	// failure. The user could just have a slow connection.
	chatStartTimeout: 30000,

	// One of:
	//   dataLayer         Google Tag Manager dataLayer events
	//   googleAnalytics   Send events to Google Analytics directly
	analyticsMode: 'dataLayer',

	// The context for web analytics action names (context:object_action)
	analyticsActionContext: 'lw',

	// Disable if Google Analytics is not implemented.
	useGoogleAnalytics: true,

	// Amount of time to wait for Google Analytics to return
	// a tracker object. The script may block for up to this
	// duration in some situations, such as when an ad blocker
	// has replaced window.ga with a no-op function.
	googleAnalyticsTrackerTimeout: 2500,

	// Determines display details for the pre-chat survey
	preselectDepartment: null,

	// Whether HTML and style rules for proactive chat
	// invitations should be added to the document during
	// initialization.
	injectInvitationUI: false,

	invitationUIHeaderText: 'Need help?',
	invitationUIBodyText: 'We\'re Here To Help You',
};

export default defaultConfig;
