import { getList } from 'testUtils/helperFunctions';
import details from './details';

const path = '/network/zone/';

export default {
	[`${path}list.json`]: ({ page_size: pageSize, page_num: pageNum }) =>
		getList({ pageSize, pageNum, data: details }),
	[`${path}details.json`]: ({ id }) => details[id.toString()],
	[`${path}setDefault.json`]: ({ id }) => ({
		default_zone: id.toString(),
	}),
};
