import React from 'react';
import MiniCart from 'components/forms/MiniCart';
import { useDispatch, useSelector } from 'react-redux';
import { selectors as assetDetailsSelectors } from 'modules/api/asset/detailsModule';

// eslint-disable-next-line import/no-cycle
import backupsSettingsActions from 'modules/server/backupsAndImages/settings/actions';
import dialogActions from 'modules/dialogs/actions';
import ConfirmCancel from 'components/common/Dialogs/ConfirmCancel';

const TEST_ID = 'QuotaSettingsDialogContent';
const QuotaSettingsDialogContent = ({ selectedQuota }) => {
	const dispatch = useDispatch();
	const domain = useSelector(assetDetailsSelectors.domain);
	const uniqId = useSelector(assetDetailsSelectors.uniqId);

	const handleSubmit = () =>
		dispatch(backupsSettingsActions.update({ uniqId, fromModal: true }));
	const handleCancel = () => dispatch(dialogActions.close());
	const price = selectedQuota?.price ? Number(selectedQuota?.price) : undefined;
	return (
		<div data-testid={TEST_ID}>
			<MiniCart
				totalText="New Monthly Cost"
				showTitle={false}
				overrideTotal={price}
				list={[
					{
						key: {
							title: 'Backups Quota Plan',
							subtitle: domain,
						},
						value: price,
					},
				]}
				perMonthLabelItems={false}
				perMonthLabelTotal
			/>
			<ConfirmCancel
				confirm={handleSubmit}
				confirmText="Submit Order"
				cancel={handleCancel}
			/>
		</div>
	);
};

export { TEST_ID };
export default QuotaSettingsDialogContent;
