import { spawn } from 'redux-saga/effects';
import backups from './backup/backupSagas';
import image from './image/imageSagas';
import template from './template/templateSagas';
import privateSagas from './private/privateSagas';

export default function* stormSaga() {
	yield spawn(backups);
	yield spawn(image);
	yield spawn(privateSagas);
	yield spawn(template);
}
