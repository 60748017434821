import { getCookie } from '../util/cookie';

const getLWReferralData = () => {
	const cookieValue = getCookie('lwReferralData');

	if (!cookieValue) return undefined;

	const cookieData = JSON.parse(cookieValue);

	switch (cookieData.program) {
	case 'impact_radius':
		return {
			Network: cookieData.program,
			PartnerId: cookieData.irpid,
			AdId: cookieData.iradid,
			SubId: cookieData.sharedid,
			ClickId: cookieData.clickid,
		};

	case 'awin':
		return {
			Network: cookieData.program,
			PartnerId: cookieData.awid,
			AdId: cookieData.aid,
			SubId: cookieData.sid,
			ClickId: cookieData.awc,
		};

	default:
		return undefined;
	}
};

const getCJReferralData = () => {
	const pidCookie = getCookie('CJPIDLW');
	const eventCookie = getCookie('CJEVENTLW');

	if (!pidCookie) return undefined;

	const fields = pidCookie.match(/pid=([^&]+)&aid=([^&]+)?&sid=([^&]+)?/);

	if (!fields) return undefined;

	return {
		Network: 'cj',
		PartnerId: fields[1],
		AdId: fields[2],
		SubId: fields[3],
		ClickId: eventCookie,
	};
};

const getAffiliateData = () => getLWReferralData() || getCJReferralData();

const getInitialExtraDetails = () => {
	const affiliateData = getAffiliateData();

	if (!affiliateData) return [];

	return Object.entries(affiliateData)
		.map(([key, value]) => ({
			label: `Affiliate${key}__c`,
			value,
			displayToAgent: true,
			transcriptFields: [`Affiliate${key}__c`],
		}));
};

export default getInitialExtraDetails;
