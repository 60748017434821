const actions = {
	ACCOUNT_ADDRESS_TOGGLE_DIALOG: 'ACCOUNT_ADDRESS_TOGGLE_DIALOG',
	toggleDialog: (isValidateDialogOpen) => ({
		type: actions.ACCOUNT_ADDRESS_TOGGLE_DIALOG,
		isValidateDialogOpen,
	}),
	ACCOUNT_ADDRESS_SET_VALIDATE: 'ACCOUNT_ADDRESS_SET_VALIDATE',
	// called when validation first begins
	setValidate: ({ address, submit, effect }) => ({
		type: actions.ACCOUNT_ADDRESS_SET_VALIDATE,
		address,
		submit,
		effect,
	}),
	ACCOUNT_ADDRESS_SUBMIT_ORIGINAL: 'ACCOUNT_ADDRESS_SUBMIT_ORIGINAL',
	submitOriginal: () => ({
		type: actions.ACCOUNT_ADDRESS_SUBMIT_ORIGINAL,
	}),
	ACCOUNT_ADDRESS_SUBMIT_VALIDATED: 'ACCOUNT_ADDRESS_SUBMIT_VALIDATED',
	submitValidated: () => ({
		type: actions.ACCOUNT_ADDRESS_SUBMIT_VALIDATED,
	}),
};

export default actions;
