import { combineReducers } from 'redux';

import { reducer as update } from './updateModule';
import { reducer as list } from './listModule';
import { reducer as remove } from './removeModule';
import { reducer as add } from './addModule';

export default combineReducers({
	update,
	list,
	remove,
	add,
});
