import actions from './actions';

const initialState = {
	selectedPlan: 'None',
	daysToRetain: 2,
	selectedQuota: { value: '0', price: 0.0 },
};

const reducer = (
	state = initialState,
	{ type, selectedPlan, daysToRetain, selectedQuota },
) => {
	switch (type) {
		case actions.SERVER_BACKUPS_AND_IMAGES_SET_SELECTED_PLAN:
			return { ...state, selectedPlan };
		case actions.BACKUPS_AND_IMAGES_SETTINGS_SET_DAYS_TO_RETAIN:
			return { ...state, daysToRetain };
		case actions.SERVER_BACKUPS_AND_IMAGES_SET_SELECTED_QUOTA:
			return { ...state, selectedQuota };
		default:
			return state;
	}
};

export default reducer;
