// TODO: This is a molecule, not an atom, and should go in the molecules folder..

import React from 'react';
import PropTypes from 'prop-types';
import ProjectIcon from '@material-ui/icons/LayersRounded';
import LabeledIcon from 'components/atoms/LabeledIcon';

const ProjectName = ({
	children,
	color = 'text.disabled',
	textColor = 'textSecondary',
	isLoading = false,
}) => (
	<LabeledIcon
		icon={<ProjectIcon />}
		color={color}
		textProps={{ color: textColor, variant: 'subtitle2' }}
		text={children}
		isLoading={isLoading}
	/>
);

ProjectName.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
	color: PropTypes.string,
	textColor: PropTypes.string,
	isLoading: PropTypes.bool,
};

export default ProjectName;
