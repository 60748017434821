import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';
import { selectors as paymentDetailsSelectors } from 'modules/api/billing/payment/profile/detailsModule';

import {
	countryOption as getCountryOption,
	countryWithRegions as getCountryWithRegions,
	stateOption as getStateOption,
} from 'utility/countryRegionConverter';

const getStateSlice = (state) => state.account.billingAddress.details;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'ACCOUNT_BILLINGADDRESS_DETAILS',
	url: '/account/billingaddress/details.json',
});

const billingAddress = createSelector(
	defaultSelectors.getNativeData,
	paymentDetailsSelectors.getPaymentMethodIsPaypal,
	(slice, isPaypal) => {
		const {
			address,
			address2,
			city,
			country,
			postal_code: postalCode,
			state,
			full_name: fullName,
		} = slice || {};
		if (isPaypal) {
			return null; // Once we are able to get an address from paypal, we will want to use that.
		}
		return {
			address,
			address2,
			city,
			country,
			postalCode,
			state,
			fullName,
		};
	},
);

const countryWithRegions = createSelector(
	billingAddress,
	(addressObj) => addressObj && getCountryWithRegions(addressObj.country),
);

const billingAddressFormInit = createSelector(
	billingAddress,
	countryWithRegions,
	(addressObj, country) =>
		addressObj && {
			...addressObj,
			country: getCountryOption(country),
			state: getStateOption(country, addressObj.state),
		},
);

const selectors = {
	getStateSlice,
	billingAddress,
	billingAddressFormInit,
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };
