import { spawn } from 'redux-saga/effects';

import dnsSagas from './dns/dnsSagas';
import firewallSagas from './firewall/firewallSagas';
import ipSagas from './ip/ipSagas';
import loadbalancer from './loadbalancer/sagas';
import privateSagas from './private/privateSagas';
import utilSagas from './util/utilSagas';
import zoneSagas from './zone/zoneSagas';

export default function* networkSagas() {
	yield spawn(dnsSagas);
	yield spawn(firewallSagas);
	yield spawn(ipSagas);
	yield spawn(loadbalancer);
	yield spawn(privateSagas);
	yield spawn(utilSagas);
	yield spawn(zoneSagas);
}
