import { createSelector } from 'reselect';
import { selectors } from '.';

const formName = 'acronisStormConfig';

const formValues = ({ form }) => form[formName]?.values;

const options = createSelector(
	selectors.acronisConfig,
	(slice) => slice?.options || [],
);

// The initial value the second option of the Acronis config dropdown.
const defaultValue = createSelector(
	options,
	(slice) => slice[1]?.value,
);

const selectedValue = createSelector(
	selectors.acronisConfig,
	(slice) => slice?.value,
);

const selectedStoreBackupsOn = createSelector(
	selectedValue,
	(slice) => (/Acronis_AC/.test(slice) ? 'cloud' : 'lw'), // defaults to 'lw' if slice is undefined.
);

const selectedData = createSelector(
	selectors.acronisConfig,
	(slice) => (slice?.value !== 'No_Acronis' ? [slice] : []),
);

const lwOptions = createSelector(
	options,
	selectedData,
	(unselected, selected) =>
		[...unselected, ...selected].filter(({ value }) =>
			/Acronis_LW/.test(value),
		),
);

const lwAvailable = createSelector(
	lwOptions,
	(lwOptions_) => !!lwOptions_.length,
);

const cloudOptions = createSelector(
	options,
	selectedData,
	(unselected, selected) =>
		[...unselected, ...selected].filter(({ value }) =>
			/Acronis_AC/.test(value),
		),
);

const cloudAvailable = createSelector(
	cloudOptions,
	(cloudOptions_) => !!cloudOptions_.length,
);

const productTileData = createSelector(
	lwOptions,
	cloudOptions,
	formValues,
	(lw, cloud, values) => {
		const allOptions = (() => {
			switch (values?.storedOn) {
				case 'lw':
					return lw;
				case 'cloud':
					return cloud;
				default:
					return [];
			}
		})();
		return allOptions.map(
			({ value, price, value_description: description }) => ({
				value,
				price,
				displayValue: /\d+(G|T|M)B/.exec(description)?.[0],
			}),
		);
	},
);

export { formName };
export default {
	selectedValue,
	defaultValue,
	productTileData,
	options,
	selectedData,
	selectedStoreBackupsOn,
	lwAvailable,
	cloudAvailable,
	formValues,
};
