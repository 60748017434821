import { combineReducers } from 'redux';

import { reducer as create } from './createModule';
import { reducer as list } from './listModule';
import { reducer as authenticate } from './authenticateModule';

export default combineReducers({
	create,
	list,
	authenticate,
});
