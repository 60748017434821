import { getList } from 'testUtils/helperFunctions';
import details from './details';
import availableAssets from './availableAssets';

const path = '/project/';

export default {
	[`${path}details.json`]: ({ id }) => details[id.toString()],
	[`${path}list.json`]: ({ page_size: pageSize, page_num: pageNum }) =>
		getList({ pageSize, pageNum, data: details }),
	[`${path}availableAssets.json`]: availableAssets,
	[`${path}add.json`]: {
		success: '1',
	},
	[`${path}available.json`]: {
		available: 1,
	},
	[`${path}categories.json`]: {
		categories: ['Servers', 'Domains', 'Services'],
	},
	[`${path}create.json`]: {
		success: '1',
	},
	[`${path}delete.json`]: {
		success: '1',
	},
	[`${path}rename.json`]: {
		success: 1,
	},
};
