import cloudTypes from 'utility/constants/cloudTypes';

const displayName = (type, productCode) => {
	switch (type) {
		case 'dedicated':
			return 'Dedicated';
		case 'open stack':
			return 'Cloud Server';
		case 'cloud':
		case 'cloud hosting':
			return cloudTypes[productCode] || 'Cloud';
		case 'managed app':
			return 'Managed App';
		case 'private parent':
			return 'Private VPS Parent';
		default:
			return 'Server';
	}
};

export default displayName;
