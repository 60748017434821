import { connect } from 'react-redux';
import {
	hasAuthToken,
	isKeroOnly as isKeroOnlySelector,
} from 'modules/auth/oidcSelectors';
import RouteHandler from './RouteHandler';

const mapStateToProps = (state) => ({
	isLoggedIn: hasAuthToken(state),
	isKeroOnly: isKeroOnlySelector(state),
});

export default connect(mapStateToProps)(RouteHandler);
