const actionType = 'BILLING_PAYMENT';
const actions = {
	createType: `${actionType}_CREATE`,
	create: ({ method, amount, stage, cvv }) => ({
		type: `${actionType}_CREATE`,
		method,
		amount,
		stage,
		cvv,
	}),
	confirmType: `${actionType}_CONFIRM`,
	confirm: ({ method, amount, cvv }) => ({
		type: `${actionType}_CONFIRM`,
		method,
		amount,
		cvv,
	}),
	setStageType: `${actionType}_STAGE_SET`,
	setStage: ({ stage }) => ({
		type: `${actionType}_STAGE_SET`,
		stage,
	}),
	fetchPayPalType: `${actionType}_PAYPAL_FETCH`,
	fetchPayPal: ({ id }) => ({
		type: `${actionType}_PAYPAL_FETCH`,
		id,
	}),
};

export default actions;
