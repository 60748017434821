import React, { useState } from 'react';
import PropTypes from 'prop-types';

// redux
import { useDispatch } from 'react-redux';
import dialogActions from 'modules/dialogs/actions';
import acronisActions from 'modules/acronis/actions';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

// custom components
import LWTypography from 'components/common/LWTypography';
import ConfirmCancel from 'components/common/Dialogs/ConfirmCancel';

const AcronisCancelConfirmationDialogContents = ({ serverId, uniqId }) => {
	const [canSubmit, setCanSubmit] = useState(false);

	const dispatch = useDispatch();
	const closeDialog = () => dispatch(dialogActions.close());
	const submitCancel = () =>
		dispatch(acronisActions.submitCancel({ serverId, uniqId }));

	const handleSubmit = () => {
		closeDialog();
		submitCancel();
	};

	return (
		<>
			<Grid container direction="column">
				<Box m={2}>
					<Box mb={2}>
						<LWTypography gutterBottom>
							<b>All Acronis backup storage will be deleted.</b>
						</LWTypography>
					</Box>
					<Box mb={2}>
						<LWTypography>
							Are you sure you wish to cancel your Acronis plan?
						</LWTypography>
					</Box>
				</Box>
				<Box m={2}>
					<FormControl>
						<FormControlLabel
							control={
								<Checkbox
									color="primary"
									checked={canSubmit}
									onChange={(event) => setCanSubmit(event.target.checked)}
								/>
							}
							label="Yes, I confirm and acknowledge cancelling my plan will delete the backups for this server."
						/>
					</FormControl>
				</Box>
			</Grid>
			<ConfirmCancel
				confirmText="Cancel Acronis and Delete Backups"
				disabled={!canSubmit}
				cancel={closeDialog}
				confirm={handleSubmit}
			/>
		</>
	);
};

AcronisCancelConfirmationDialogContents.propTypes = {
	serverId: PropTypes.string,
	uniqId: PropTypes.string.isRequired,
};

export default AcronisCancelConfirmationDialogContents;
