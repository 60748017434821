import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => {
	return state.server.WHM.firewall.unblockIP;
};

const { actions, reducer, sagas, selectors } = createAPIModule({
	getStateSlice,
	actionType: 'UNBLOCK_IP_TEST',
	method: 'POST',
	url: '/server/whm/firewall/unblockip.json',
});

export { actions, reducer, sagas, selectors };
