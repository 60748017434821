import styled from 'styled-components';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const ExpansionArrow = styled(ExpandMoreIcon)`
	color: ${({ theme }) => theme.palette.secondary.dark};
	transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	${({ $expanded }) => `transform: rotate(${$expanded ? '180' : '0'}deg);`}
`;

export default ExpansionArrow;
