import PropTypes from 'prop-types';

const getPaginationDispatchProps = (dispatch, actions) => ({
	setPage: (pageNumber) => dispatch(actions.setPage(pageNumber)),
	nextPage: () => dispatch(actions.nextPage()),
	previousPage: () => dispatch(actions.previousPage()),
	lastPage: () => dispatch(actions.lastPage()),
	firstPage: () => dispatch(actions.firstPage()),
});

const paginationDispatchPropTypes = {
	setPage: PropTypes.func.isRequired,
	nextPage: PropTypes.func.isRequired,
	previousPage: PropTypes.func.isRequired,
	lastPage: PropTypes.func.isRequired,
	firstPage: PropTypes.func.isRequired,
};

const getPaginationStateProps = (selectors, state) => ({
	itemCount: selectors.getItemCount(state),
	totalItems: selectors.getTotalItems(state),
	pageNum: selectors.getPageNum(state),
	pageSize: selectors.getPageSize(state),
	pageTotal: selectors.getPageTotal(state),
});

export {
	getPaginationDispatchProps,
	paginationDispatchPropTypes,
	getPaginationStateProps,
};
