const actions = {
	NOTIFICATIONS_READ_SET: 'NOTIFICATIONS_READ_SET',
	set: (payload) => ({
		type: actions.NOTIFICATIONS_READ_SET,
		payload,
	}),
	POLL_NOTIFICATIONS: 'POLL_NOTIFICATIONS',
	pollNotifications: ({ category, toFetchAfter, uniqId }) => ({
		type: actions.POLL_NOTIFICATIONS,
		category,
		toFetchAfter,
		uniqId,
	}),
};

export default actions;
