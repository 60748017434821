import React from 'react';

import { Provider as ReduxProvider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { store, history } from 'store';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import OpenIDProviderWrapper from 'utility/openid/OpenIDProviderWrapper';
import MomentUtils from '@date-io/moment';

import { GlobalStylesStorybook } from 'components/styles/GlobalStyles';
import {
	MuiThemeProvider,
	StylesProvider,
	createMuiTheme,
} from '@material-ui/core/styles';
import { ThemeProvider } from 'styled-components';
import theme, { theme as rawTheme } from 'theme';

const generateClassName = (rule, sheet) => {
	const styleString = false; // readableStyles(rule);
	return (
		sheet.options.classNamePrefix +
		(styleString ? `\n${styleString}\n` : `-${rule.key}`)
	);
};

const Providers = ({ noClassNameIdx, children, customTheme, authWrap }) => (
	<ReduxProvider store={store}>
		<MuiPickersUtilsProvider utils={MomentUtils}>
			<MuiThemeProvider theme={customTheme || theme}>
				<StylesProvider
					injectFirst
					generateClassName={noClassNameIdx ? generateClassName : undefined}
				>
					<ThemeProvider theme={theme}>
						<GlobalStylesStorybook />
						<ConnectedRouter history={history}>
							{authWrap ? (
								<OpenIDProviderWrapper>{children}</OpenIDProviderWrapper>
							) : (
								children
							)}
						</ConnectedRouter>
					</ThemeProvider>
				</StylesProvider>
			</MuiThemeProvider>
		</MuiPickersUtilsProvider>
	</ReduxProvider>
);

const TestProviders = ({ children }) => {
	const customTheme = createMuiTheme({
		props: { MuiWithWidth: { initialWidth: 'md' } },
		...rawTheme,
	});
	return (
		<Providers noClassNameIdx customTheme={customTheme} authWrap>
			{children}
		</Providers>
	);
};

export { TestProviders };
export default Providers;
