import produce from 'immer';
import actions from './actions';

const MODES = {
	ADD: 'ADD',
	REMOVE: 'REMOVE',
	EDIT: 'EDIT',
};
const initialState = {
	id: null,
	mode: null,
	publicKeyName: '',
	publicKeyValue: '',
	errors: [],
};

/* eslint-disable default-case, no-param-reassign */
const reducer = (state = initialState, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case actions.SSH_KEYS_CLOSE_DIALOG:
				draft.mode = null;
				draft.id = null;
				draft.errors = [];
				break;
			case actions.SSH_KEYS_ADD_KEY:
				draft.publicKeyName = '';
				draft.publicKeyValue = '';
				draft.mode = MODES.ADD;
				break;
			case actions.SSH_KEYS_EDIT_KEY:
				draft.publicKeyName = action.payload.publicKeyName;
				draft.publicKeyValue = action.payload.publicKeyValue;
				draft.mode = MODES.EDIT;
				draft.id = action.payload.id;
				break;
			case actions.SSH_KEYS_REMOVE_KEY:
				draft.publicKeyName = action.payload.publicKeyName;
				draft.publicKeyValue = action.payload.publicKeyValue;
				draft.mode = MODES.REMOVE;
				draft.id = action.payload.id;
				break;
			case actions.SSH_KEYS_ERROR:
				draft.errors = action.payload;
				break;
			case actions.SSH_KEYS_ADD_CONFIRM:
			case actions.SSH_KEYS_EDIT_CONFIRM:
			case actions.SSH_KEYS_REMOVE_CONFIRM:
				draft.errors = [];
				break;
			case actions.SSH_KEYS_CHANGE_VALUE:
				draft[action.payload.label] = action.payload.value;
				draft.errors = [];
				break;
		}
	});

export default reducer;
