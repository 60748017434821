const sshKeys = {
	'385': {
		id: 385,
		public_key_name: 'whatever3',
		public_key_value:
			'ssh-rsa AAAAB3NzaC1yc2EAAAADAQABAAABAQC+8sbsdZmBcpwzDMv4goSHvj/L5L+7ALqYoiDKc3o85aiwqphYOZedxdD4nl52Wom6KfVPF5VBfUCEJyDAWppI7B8pc0AURpyFwPM19BpMKThEueuaHkURLvT4eB7tACAzgM9IUDChhaEF87xw906NK+3ujlDmBVC/DdJYU6kMM9hlzmLJb+wg5SpyWS8C+t0uiqq+1tFXZx7RbCIXhVWlPTfwA6ICbS6AmT989toewXB0ENaDxiFBNYVR8xdNtRWK5UnaMzqhHAmuokMH7Dynlcl0kjztPkTWyLrvbptMaBXn6UknahZu0yRhf9umR8zpomRmBl8D9u1WIP17gxI9 benito@darkhouse',
	},
	'165': {
		id: 165,
		public_key_name: 'bbbbbc',
		public_key_value:
			'ssh-rsa AAAAB3NzaC1yc2EAAAADAQABAAACAQDECfg3bWfHVzC0BsJi8zKmYbZj2/jRvlwxMnQdG7kZBMAlTlXiamVFSlsKba2qvE8KwxYOjofCQJwZjj1/Q0GyD3HgNyNEFVvv1CUBYlsr1ggQdMh9UpspPseQB5Wm6DWfyKDpqxj8UK58nWWWZLiXTFpHxh/tGje5hXGhJq/tpItzU+TlWqgTC0fwObO2Iwdgw7R/n/ccAjABCQEgG8AuWrFOjgLUrD4iIK/67ccjtVDeuf/MTQGcl7FsLBfrei4Ce9wSzXkrV5tXzt6orrGoBj0NNTMbGcfyix910rDZdeYkiJys4kx+R0FHYSTL+si1IyjDISTSxQm0oEc34LK7kIa+xAYDoDnRgNGbzdEvoHqdfhOMRHHvERlbm8Pk2epLjwjuXgd1G6FV11gfIwtKHhZieYNDirgn6ZrdVDT6pr8kb3QFz+0n18xFDRAF29wm9FWUD6FP0ruMsJcsNph7mHzviToantgPrdhKymsEWbB9b6l/rYwrktsq6yPVgQ6t3YzwcYPw+GTt8gauIWjBEp8I0j5sLoA7G5XBDMBfKjAwQrjC6rMqD6D342gpEs5STsw2b2p7xaSJcoAAdXDf5Gk//qE3yvIHiiJJAvdy+1pYyaDT707zkHCsQX540XIv/1+jJllL9yPJvbJBfrQM7NP7LqEGn8UWeaGi2BZGfQ== /home/benito/.ssh/id_rsa_devadmin',
	},
	'178': {
		id: 178,
		public_key_name: 'whatever',
		public_key_value:
			'ssh-rsa AAAAB3NzaC1yc2EAAAADAQABAAABAQC+8sbsdZmBcpwzDMv4goSHvj/L5L+7ALqYoiDKc3o85aiwqphYOZedxdD4nl52Wom6KfVPF5VBfUCEJyDAWppI7B8pc0AURpyFwPM19BpMKThEueuaHkURLvT4eB7tACAzgM9IUDChhaEF87xw906NK+3ujlDmBVC/DdJYU6kMM9hlzmLJb+wg5SpyWS8C+t0uiqq+1tFXZx7RbCIXhVWlPTfwA6ICbS6AmT989toewXB0ENaDxiFBNYVR8xdNtRWK5UnaMzqhHAmuokMH7Dynlcl0kjztPkTWyLrvbptMaBXn6UknahZu0yRhf9umR8zpomRmBl8D9u1WIP17gxI9 benito@darkhouse',
	},
	'3425': {
		id: 3425,
		public_key_name: 'whateverathing',
		public_key_value:
			'ssh-rsa AAAAB3NzaC1yc2EAAAADAQABAAACAQC1Jr0O/MsffCrS9PbwTarGfSsqJGYaAnIJyTeGPcV59xA6oyDA+PTdcv6tif6GVpWWx64IpvXjmYh6+1msYmN2LxGpTOMAOZU4iniOqekDB8kqYFKivcqWLfYig2RBNBA9dh4+C4KSyWWvZd/lh29P5nts3WWNwctsnQbyVzU021M0exYSUs+oEFS4R2JCgz93CzjKPMHmZ3wfgvR/OFwpa9AvIQOYa68fXGIsG4LPV6Fk+tpQVzSzyqP/LtWYzQGGkZtG1VnwRwBPmoONYSV/06zGaTTZQeqvdqUJYqqthg1vwo3rD9uoO2BuodU5ESCoo2JFwoaLJA2JknCPnm8kykAdXOqY/Gwr88ggy6y10KrYkz2qTsnk2od5wjA7IVLqxNTjqUvPCRNeBoZl/819/AABCLvUf8RLXticHfdapB+dUYi3O6RfjhHBHO0W6yMouDSCL1bKr+uZmm8Pq2nyRFHDP7BcVToQ3XW3JxTUJgoXJXOAOXphG8+wm3BfoVmks4KstW/ZMFxIGB127e43nLJ6jkVGqWic2fWhlNaUnet8Daa8aon64+RyZsTT2kMXkYQak9R4QwLNV83wAL1QXJNO7um40JuFvUFilV9wZAheKLiyUiewmrwIYMHUjB+uj3AIXb9blzAMbW+H3j7bkdwYXXVMzwASILMD6ZftNw==',
	},
	'3427': {
		id: 3427,
		public_key_name: 'wonkykey',
		public_key_value:
			'ssh-rsa AAAAB3NzaC1yc2EAAAADAQABAAACAQC1Jr0O/MsffCrS9PbwTarGfSsqJGYaAnIJyTeGPcV59xA6oyDA+PTdcv6tif6GVpWWx64IpvXjmYh6+1msYmN2LxGpTOMAOZU4iniOqekDB8kqYFKivcqWLfYig2RBNBA9dh4+C4KSyWWvZd/lh29P5nts3WWNwctsnQbyVzU021M0exYSUs+oEFS4R2JCgz93CzjKPMHmZ3wfgvR/OFwpa9AvIQOYa68fXGIsG4LPV6Fk+tpQVzSzyqP/LtWYzQGGkZtG1VnwRwBPmoONYSV/06zGaTTZQeqvdqUJYqqthg1vwo3rD9uoO2BuodU5ESCoo2JFwoaLJA2JknCPnm8kykAdXOqY/Gwr88ggy6y10KrYkz2qTsnk2od5wjA7IVLqxNTjqUvPCRNeBoZl/819/AABCLvUf8RLXticHfdapB+dUYi3O6RfjhHBHO0W6yMouDSCL1bKr+uZmm8Pq2nyRFHDP7BcVToQ3XW3JxTUJgoXJXOAOXphG8+wm3BfoVmks4KstW/ZMFxIGB127e43nLJ6jkVGqWic2fWhlNaUnet8Daa8aon64+RyZsTT2kMXkYQak9R4QwLNV83wAL1QXJNO7um40JuFvUFilV9wZAheKLiyUiewmrwIYMHUjB+uj3AIXb9blzAMbW+H3j7bkdwYXXVMzwASILMD6ZftNw==',
	},
};

export default sshKeys;
