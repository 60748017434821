import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'react-router-dom';
import RoleRestrictedRoute from 'utility/routing/RoleRestrictedRoute';
import SubNavRoutes from 'components/routers/SubNavRoutes';
import asyncComponent from 'components/AsyncComponent';
import { roles } from 'utility/constants/roles';

const DomainDetails = asyncComponent(() =>
	import('containers/pages/domains/details'),
);

const DomainRegistration = asyncComponent(() =>
	import('containers/pages/domains/details/registration/DomainRegistration'),
);

const DomainDNS = asyncComponent(() =>
	import('containers/pages/domains/details/dns/DomainDNS'),
);

const CreateDNSZone = asyncComponent(() =>
	import('containers/pages/domains/details/dns/CreateDNSZone'),
);

const CreateTransfer = asyncComponent(() =>
	import('containers/pages/domains/CreateTransfer'),
);

const DomainsRouter = (props) => {
	const { navData } = props;
	return (
		<Switch>
			<RoleRestrictedRoute
				exact
				path={navData.get('path')}
				component={navData.get('component')}
				whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
			/>
			<RoleRestrictedRoute
				exact
				path={`${navData.get('path')}/create-transfer`}
				component={CreateTransfer}
				whitelistedRoles={[roles.PURCHASER]}
			/>
			<RoleRestrictedRoute
				path={`${navData.get('path')}/dashboard/:domain`}
				component={DomainDetails}
				whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
			/>
			<RoleRestrictedRoute
				path={`${navData.get('path')}/registration/:domain`}
				component={DomainRegistration}
				whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
			/>
			{/* <Route path={`${navData.get('path')}/ssl/:domain`} component={DomainSSL} /> */}
			<RoleRestrictedRoute
				path={`${navData.get('path')}/dns/add`}
				component={CreateDNSZone}
				whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
			/>

			<RoleRestrictedRoute
				path={`${navData.get('path')}/dns/:domain`}
				component={DomainDNS}
				whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
			/>
			<SubNavRoutes
				subNavs={navData.get('subNav')}
				whitelistedRoles={[roles.PURCHASER, roles.TECHNICIAN]}
			/>
		</Switch>
	);
};

DomainsRouter.propTypes = {
	navData: PropTypes.object.isRequired,
};

export default DomainsRouter;
