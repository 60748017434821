import items, { uuids } from './items';
import modify from './modify';
import set from './set';
import remove from './remove';

// Use these if you're just looking for a single item without mocking an api.
const singleProcessor = items['0244dfc2-6c20-4afa-9a52-8eb2e39bfe9d'];
const dreg = items['bf8f8ae5-2a2b-4172-aaf6-9def7974ea8a'];
const acronisBackup = items['34646e1d-3cf4-4efd-8f15-297a8afe4d7c'];
const privateParent = items['57d96751-bd05-4842-8d0d-54765f29139f'];
const vps = items[uuids['SS.VPS']];
const cloudDedicated = items[uuids['SS.VM']];

const path = '/basket/item/';

export {
	singleProcessor,
	dreg,
	acronisBackup,
	privateParent,
	vps,
	cloudDedicated,
};
export default {
	[`${path}details.json`]: ({ /* alsowith, */ uuid }) => {
		// TODO: mock alsowiths
		return items[uuid];
	},
	[`${path}modify.json`]: ({ properties, uuid }) => ({
		...modify,
		properties,
		uuid,
	}),
	[`${path}remove.json`]: remove,
	[`${path}assertProperties.json`]: ({ properties, uuid }) => ({
		...modify,
		properties: { ...modify.properties, ...properties },
		uuid,
	}),
	[`${path}swap.json`]: ({ uuid, product_code: productCode, region }) => ({
		uuid: uuids['UUID.ONLY'], // so that we can verify that the uuid is different
		// returning the following to get snapshots. These are not actually returned in the real api.
		oldUuid: uuid,
		productCode,
		region,
	}),
	[`${path}config/set.json`]: (params) => {
		// using both key and value to get full control over mock responses.
		return { ...set[params.key]?.[params.value], ...params };
	}, // just some data so the tests know that it happened.
	[`${path}add.json`]: (params) => ({ uuid: params.basket, ...params }), // so far, tests only need the uuid returned
};
