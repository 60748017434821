import randomNumberBetween from 'utility/randomNumberBetween';
import acquire from './acquire';
import details from './details';
import item from '../item/mockData';

const path = '/basket/';

export default {
	[`${path}addDiscount.json`]: {
		success: 1,
	},
	[`${path}acquire.json`]: ({
		uuid = 'ab3dd57c-a2aa-4de9-9fd5-afe115c7cffc',
	}) => acquire[uuid],
	[`${path}claim.json`]: ({ uuid }) => ({ uuid }),
	[`${path}details.json`]: ({
		uuid = '864ed1f5-771b-4e2f-9bad-f22b701a8007', // defaults to "largeBasket" test data.
	}) => details[uuid],
	[`${path}removeDiscount.json`]: {
		success: 1,
	},
	[`${path}create.json`]: () => ({
		uuid: '864ed1f5-771b-4e2f-9bad-f22b701a8007',
	}),
	[`${path}generateSaved.json`]: ({ uuid }) => ({
		uuid: randomNumberBetween(10000000000, 99999999999),
		source_uuid: uuid,
	}),
	...item,
};
