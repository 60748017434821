import {
	createAPIModule,
	createSelectors,
} from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.asset.measurements.lookup;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'ASSET_MEASUREMENTS_LOOKUP',
	method: 'POST',
	url: '/asset/measurements/lookup.json',
});

const moduleKeys = {
	LOAD: 'load',
	BANDWIDTH: 'bandwidth',
	MEMORY: 'memory',
};

const selectors = {
	...defaultSelectors,
	loadSelectors: createSelectors(getStateSlice, false, moduleKeys.LOAD),
	bandwidthSelectors: createSelectors(
		getStateSlice,
		false,
		moduleKeys.BANDWIDTH,
	),
	memorySelectors: createSelectors(getStateSlice, false, moduleKeys.MEMORY),
};

export { actions, reducer, sagas, selectors, moduleKeys };
