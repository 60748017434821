import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';

const getStateSlice = (state) => state.basketApi.removeDiscount;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	actionType: 'BASKET_REMOVEDISCOUNT',
	method: 'POST',
	url: '/basket/removeDiscount.json',
});

/* params 
basket-level
  uuid: <basket_uuid>,
  type: "cpq",
  amount: <percentage -- 12.34, 5, 10, etc>,
  metadata: { scope: "basket" }

  basket-item level
  uuid: <basket_uuid>,
    type: "cpq",
    amount: <percentage -- 12.34, 5, 10, etc>,
    metadata: { scope: "item", scope_uuid: <item_uuid> },
*/

const selectors = {
	getSuccess: createSelector(
		defaultSelectors.getData,
		(data) => data && data.get('success'),
	),
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };
