import { spawn } from 'redux-saga/effects';

import { sagas as details } from './detailsModule';
import { sagas as update } from './updateModule';
import { sagas as list } from './listModule';

export default function* sagas() {
	yield spawn(details);
	yield spawn(update);
	yield spawn(list);
}
