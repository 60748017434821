import { combineReducers } from 'redux';

import { reducer as create } from './createModule';
import { reducer as details } from './detailsModule';
import { reducer as list } from './listModule';
import { reducer as update } from './updateModule';

export default combineReducers({
	create,
	details,
	list,
	update,
});
