/* eslint-disable camelcase */
const actions = {
	PAYMENT_FORM_INITIALIZE_HOSTED_FORM: 'PAYMENT_FORM_INITIALIZE_HOSTED_FORM',
	initializeHostedForm: ({
		type = 'add',
		payment_method = 'credit_card',
		communicator_url,
		reason,
		paymentProfileId,
	}) => ({
		type: actions.PAYMENT_FORM_INITIALIZE_HOSTED_FORM,
		payload: {
			type,
			payment_method,
			reason,
			communicator_url,
			paymentProfileId,
		},
	}),
	PAYMENT_FORM_TOGGLE_OPEN_MODAL: 'PAYMENT_FORM_TOGGLE_OPEN_MODAL',
	toggleOpenModal: ({ isOpen }) => ({
		type: actions.PAYMENT_FORM_TOGGLE_OPEN_MODAL,
		payload: {
			isOpen,
		},
	}),
	PAYMENT_FORM_TOGGLE_IFRAME_LOADING: 'PAYMENT_FORM_TOGGLE_IFRAME_LOADING',
	toggleIframeLoading: ({ isLoading }) => ({
		type: actions.PAYMENT_FORM_TOGGLE_IFRAME_LOADING,
		payload: {
			isLoading,
		},
	}),
	PAYMENT_FORM_TOGGLE_LOADING: 'PAYMENT_FORM_TOGGLE_LOADING',
	toggleIsLoading: ({ isLoading }) => ({
		type: actions.PAYMENT_FORM_TOGGLE_LOADING,
		payload: {
			isLoading,
		},
	}),
};

export default actions;
