import { createMuiTheme } from '@material-ui/core/styles';

const theme = {
	palette: {
		common: {
			grey: {
				// Custom colors
				0: '#fafafa',
				1: '#00000014',
				2: '#0000001F',
				3: '#00000033',
				4: '#00000052',
				5: '#00000062',
				6: '#00000098',
				7: '#000000DD',
			},
			// Custom colors
			accent: {
				0: '#3C485F',
				1: '#65375A',
			},
			// Custom colors
			orange: '#EAC000',
			yellowgreen: '#CEDA07',
			green: '#7BC730',
			bluegreen: '#52B380',
			blue: '#4EA0AA',
			bluepurple: '#4563A2',
			// TODO remove, not a MUI key
			black2: '#3d3d3d',
			white1: '#ffffff',
			white2: '#f5f5f5',
			red: '#c62828',
			red2: '#f1c9c9',
			grey0: '#fafafa',
			grey1: '#e9e9e9',
			grey2: '#d2d2d2',
			grey3: '#a4a4a4',
			grey4: '#787878',
			grey5: '#646667',
			grey6: '#434343',
			green1: '#e1f0e7',
			green2: '#c3e0ce',
			green3: '#a6d3b6',
			green4: '#89c29e',
			green5: '#7cc32e',
			green6: '#4caf50',
			green7: '#d2ebd3',
			blue1: '#9cd2eb',
			blue2: '#6cc2e6',
			blue3: '#04b2e1',
			blue4: '#00345f',
			blue5: '#5ed1fd',
			blue6: '#d7f3ff',
			blue7: '#d5ed20',
			blue8: '#30a9d3',
			blue9: '#03a0ca00',
			blue10: '#30a6e2',
			orange1: '#efb061',
			purple1: '#894b78',
			purple3: '#633454',
			azure: '#26aae1',
			bluebar: '#2b6c98',
			yellow1: '#dbba00',
			yellow3: '#ffcc00',
			yellow4: '#ffeda5', // highlighted text background
		},
		background: {
			default: '#fff',
			dark: '#45556a',
			darker: '#2e3c4b',
			red: '#e0735e',
		},
		other: {
			info: '#9cd2eb',
			link: '#26aae1',
			purpleHeader: '#7d4265',
			inputText: '#585858',
			inputLabel: '#787878',
		},
		secondary: {
			light: '#FFE274',
			alt: '#FC9300',
			main: '#FCB043', // orange
			dark: '#C58108',
			contrastText: '#fff',
			// Custom, not MUI keys
			faint: '#FCB04315',
			washed: '#fcb0431a',
			faded: '#FCB04389',
		},
		primary: {
			light: '#5ED1FD',
			alt: '#1264A3',
			main: '#03A0CA', // blue
			dark: '#007199',
			contrastText: '#fff',
			// Custom, not MUI keys
			faint: '#03A0CA15',
			faintOpaque: '#ECF8FB',
			washed: '#03A0CA60',
			faded: '#03A0CA89',
		},
		error: {
			main: '#C62828',
			contrastText: '#fff',
		},
		success: {
			main: '#4CAF50',
		},
		text: {
			primary: '#000000DD',
			secondary: '#00000098',
			disabled: '#00000062',
			error: '#C62828',
			// TODO remove, not a MUI key
			light: '#d2d2d2',
		},
	},
	mixins: {
		content: {
			width: '100%',
			margin: '0em',
		},
		info: {
			color: '#fff',
		},
		warning: {
			color: '#fff',
		},
		error: {
			color: '#fff',
		},
		verticalCenter: {
			position: 'relative',
			top: '50%',
			transform: 'translateY(-50%)',
		},
		horizontalCenter: {
			width: 'fit-content',
			margin: '0 auto',
		},
		subheader: {
			fontSize: '1rem',
			color: '#a4a4a4',
		},
		subtitle: {
			fontSize: '0.75em',
			secondary: '#a4a4a4',
		},
		alignCenter: {
			display: 'flex',
			alignItems: 'center',
		},
		hidden: {
			color: 'transparent',
			textShadow: 'rgba(0, 0, 0, 0.95) 0 0 10px',
		},
	},
	spacingPage: '2em',
	typography: {
		fontFamily: 'Open Sans',
		h1: {
			fontSize: '2.5rem',
			fontWeight: 600,
		},
		h2: {
			fontSize: '2rem',
			fontWeight: 700,
		},
		h4: { fontSize: '1.75rem' },
		h5: {
			fontSize: '1.5rem',
			fontWeight: 600,
		},
		h6: {
			fontSize: '1.25rem',
		},
		subtitle1: {
			fontWeight: 600,
			fontSize: '1.125rem',
			lineHeight: 'inherit',
		},
		subtitle2: {
			fontSize: '.875rem',
			lineHeight: 'inherit',
		},
		caption: {
			fontSize: '.75rem',
			lineHeight: 'inherit',
		},
		body2: {
			fontSize: '.875rem',
		},
		button: {
			fontWeight: 600,
		},
	},
	overrides: {
		MuiDialog: {
			paper: {
				borderRadius: 16,
			},
		},
		MuiDialogContent: {
			root: {
				padding: '16px 24px',
			},
		},
		MuiDialogActions: {
			spacing: {
				'& > :not(:first-child)': {
					marginLeft: 16,
				},
			},
		},
		MuiAutocomplete: {
			popper: {
				zIndex: 1400, // Need this to display the autocomplete properly in modals
			},
		},
		MuiCheckbox: {
			root: {
				color: '#000',
			},
		},
		MuiFormControlLabel: {
			root: {
				width: 'fit-content',
			},
			label: {
				color: '#000000DD',
			},
		},
		MuiTypography: {
			h2: {
				color: '#4a4a4a',
			},
		},
		MuiIconButton: {
			root: {
				padding: 6,
			},
		},
		MuiButtonBase: {
			root: { borderWidth: '2px' },
		},
		MuiPopover: {
			root: {
				zIndex: [1400, '!important'],
			},
		},
		MuiCardContent: {
			root: {
				padding: '32px',
				'&:last-child': {
					paddingBottom: '32px',
				},
			},
		},
		MuiFormControl: {
			root: {
				width: '100%',
			},
		},
		MuiPaper: {
			root: {
				borderRadius: '8px',
			},
			rounded: {
				borderRadius: '8px',
			},
			outlined: {
				boxShadow:
					'0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
			},
		},
		MuiTabs: {
			root: {
				borderRadius: '0px',
				'& button': {
					minWidth: '0px',
				},
			},
		},
	},
	maxWidth: 1200,
	sideSheetWidth: 400,
	zIndex: {
		// https://material-ui.com/customization/z-index/
		appBar: 1400,
		accountBarPopUp: 98,
		snackbar: 1401,
		chatLoader: 2000,
		sideSheet: 1402,
	},
};

export { theme };
export default createMuiTheme(theme);
