import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import classNames from 'classnames';

const styles = ({ palette, mixins }) => ({
	root: {
		display: 'flex',
		alignItems: 'stretch',
	},
	content: {
		display: 'flex',
		justifyContent: 'space-between',
		width: '100%',
	},
	header: {},
	titleTypography: {
		fontSize: '1.5em',
		fontWeight: '300',
		color: palette.secondary.contrastText,
	},
	contentTypography: {
		fontSize: '16px',
	},
	icon: {
		color: palette.common.white1,
	},
	link: {
		color: palette.other.link,
	},
	info: {
		...mixins.info,
	},
	warning: {
		...mixins.warning,
	},
	error: {
		...mixins.error,
	},
});

const LWHorizontalCard = (props) => {
	const { classes, children, type, rightChildren } = props;
	return (
		<Card className={classes.root} square>
			{type.icon && (
				<CardHeader
					className={classNames(classes.header, classes[type.name])}
					title={type.icon({
						className: classNames(classes.icon, classes[type.name]),
					})}
					disableTypography
				/>
			)}
			<CardContent className={classes.content}>
				<Typography
					className={classes.contentTypography}
					color="textPrimary"
					component="div"
				>
					{children}
				</Typography>
				{rightChildren}
			</CardContent>
		</Card>
	);
};

LWHorizontalCard.propTypes = {
	classes: PropTypes.object.isRequired,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	type: PropTypes.object.isRequired,
	rightChildren: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
};

LWHorizontalCard.defaultProps = {
	children: null,
};

export { LWHorizontalCard };

export default withStyles(styles)(LWHorizontalCard);
