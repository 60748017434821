import { takeEvery, select, put } from 'redux-saga/effects';
import {
	actions as listActions,
	moduleKeys,
} from 'modules/api/asset/listModule';
import actions from './actions';
import stateSelectors from './selectors';

const alsowith = ['ancestorServer'];

// TODO: call fetchThreatStack ({poll: false}) after ThreatStack is purchased.
export function* fetchThreatStack({ poll }) {
	const { pageNum, pageSize } = yield select(stateSelectors);
	if (poll) yield put(listActions.cancelPolling({}, moduleKeys.THREAT_STACK));
	yield put(
		listActions[poll ? 'fetchAndPoll' : 'fetch'](
			{
				type: 'ThreatStack',
				alsowith,
				page_size: pageSize,
				page_num: pageNum,
			},
			moduleKeys.THREAT_STACK,
			{ init: true }, // does nothing in fetch
		),
	);
}

export default function* root() {
	yield takeEvery(
		[
			actions.FETCH_THREATSTACK,
			actions.SET_THREATSTACK_PAGE,
			actions.SET_THREATSTACK_ITEMS_PER_PAGE,
		],
		fetchThreatStack,
		{ poll: true },
	);
}
