import { combineReducers } from 'redux';

import { reducer as details } from './detailsModule';
import { reducer as update } from './updateModule';
import { reducer as list } from './listModule';

export default combineReducers({
	details,
	update,
	list,
});
