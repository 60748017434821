import React from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

const ParentNameInput = ({ parentName, setParentName }) => {
	return (
		<Grid xs item>
			<TextField
				value={parentName}
				onChange={({ target }) => {
					// TODO: use redux-forms to do correct validation.
					setParentName(target.value.replace(/[^a-zA-Z0-9 ]/g, ''));
				}}
				label="Parent Name"
				variant="outlined"
				inputProps={{ 'data-lpignore': true }}
			/>
		</Grid>
	);
};

ParentNameInput.propTypes = {
	parentName: PropTypes.string,
	setParentName: PropTypes.func,
};

export default ParentNameInput;
