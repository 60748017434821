import { combineReducers } from 'redux';

import { reducer as listForKey } from './listForKeyModule';
import { reducer as add } from './addModule';
import { reducer as remove } from './removeModule';
import { reducer as replace } from './replaceModule';
import { reducer as update } from './updateModule';

export default combineReducers({
	listForKey,
	remove,
	replace,
	add,
	update,
});
