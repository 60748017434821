/* eslint-disable camelcase */
import { select, put, take, takeEvery, all } from 'redux-saga/effects';
import queryString from 'query-string';
import { selectors as oneTimeDetailsSelectors } from 'modules/api/billing/creditcard/profile/oneTimeDetailsModule';
import { selectors as appConfigSelectors } from 'modules/api/appConfigModule';
import {
	actions as createPaypalPaymentActions,
	selectors as createPaypalPaymentSelectors,
} from 'modules/api/billing/paypal/payment/createModule';
import { actions as detailsPaypalPaymentActions } from 'modules/api/billing/paypal/payment/detailsModule';
import { actions as executePaypalPaymentActions } from 'modules/api/billing/paypal/payment/executeModule';
import { actions as updatePaypalPaymentActions } from 'modules/api/billing/paypal/payment/updateModule';
import { actions as makePaymentModuleActions } from 'modules/api/billing/payment/makeModule';
import { actions as accountDetailActions } from 'modules/api/account/detailsModule';
import { actions as balanceActions } from 'modules/api/billing/balanceModule';
import paymentFormActions from 'modules/paymentForm/actions';
import paymentActions from './paymentActions';

function* createPayment(action) {
	switch (action.method) {
		case 'onetime_paypal': {
			const url = window.location.origin;
			if (action.stage !== 'editing') return;
			yield put(
				createPaypalPaymentActions.fetch({
					requested_amount: action.amount,
					returnURL: `${url}/billing/pay`,
					lineItems: [
						{
							amount: action.amount,
							description: 'My Liquid Web Payment',
						},
					],
				}),
			);
			yield take([
				createPaypalPaymentActions.setType,
				createPaypalPaymentActions.errorType,
			]);
			const approvalUrl = yield select(
				createPaypalPaymentSelectors.approvalUrl,
			);
			if (approvalUrl) {
				window.location.assign(approvalUrl);
			}
			break;
		}
		case 'paypal':
		case 'credit_card':
			yield put(paymentActions.setStage({ stage: 'confirm' }));
			break;
		case 'onetime_credit_card':
			// calling one time details
			// show authorize modal
			yield put(paymentFormActions.toggleOpenModal({ isOpen: true }));
			break;

		default:
			break;
	}
}

function* listenCreatePayment() {
	yield takeEvery(paymentActions.createType, createPayment);
}

function* fetchPayPal(action) {
	yield put(paymentActions.setStage({ stage: 'confirm' }));
	yield put(detailsPaypalPaymentActions.fetch({ id: action.id }));
	const params = queryString.parse(window.location.search);
	const detailsAction = yield take([
		detailsPaypalPaymentActions.setType,
		detailsPaypalPaymentActions.errorType,
	]);
	if (detailsAction.type !== detailsPaypalPaymentActions.setType) return;
	yield put(
		paymentActions.create({
			method: 'onetime_paypal',
			amount: detailsAction.payload.requested_amount,
			stage: 'confirm',
		}),
	);
	if (params.status === 'success') {
		yield put(
			updatePaypalPaymentActions.fetch({
				id: params.lwpaymentid,
				payer_id: params.PayerID,
				status: params.status,
			}),
		);
	}
}

function* listenFetchPayPal() {
	yield takeEvery(paymentActions.fetchPayPalType, fetchPayPal);
}

function* confirmPayment(action) {
	const { amount, cvv } = action;
	let paymentSuccessful = false;
	switch (action.method) {
		case 'onetime_paypal': {
			const params = new URLSearchParams(window.location.search);
			yield put(
				executePaypalPaymentActions.fetch({ id: params.get('lwpaymentid') }),
			);
			const result = yield take([
				executePaypalPaymentActions.setType,
				executePaypalPaymentActions.errorType,
			]);
			if (result.type === executePaypalPaymentActions.setType) {
				paymentSuccessful = true;
			}
			break;
		}
		case 'paypal': {
			const url = yield select(appConfigSelectors.getSelfHostname);

			yield put(
				createPaypalPaymentActions.fetch({
					requested_amount: action.amount,
					returnURL: `${url}/billing/pay`,
					lineItems: [
						{
							amount: action.amount,
							description: 'My Liquid Web Payment - PayPal',
						},
					],
				}),
			);
			const result = yield take([
				createPaypalPaymentActions.setType,
				createPaypalPaymentActions.errorType,
			]);
			if (result.type === createPaypalPaymentActions.setType) {
				paymentSuccessful = true;
			}
			break;
		}
		case 'credit_card': {
			yield put(makePaymentModuleActions.fetch({ amount, card_code: cvv }));
			const result = yield take([
				makePaymentModuleActions.setType,
				makePaymentModuleActions.errorType,
			]);
			if (result.type === makePaymentModuleActions.setType) {
				paymentSuccessful = true;
			}
			break;
		}
		case 'onetime_credit_card': {
			const oneTimeDetails = yield select((state) =>
				oneTimeDetailsSelectors.getData(state),
			);
			const oneTimeDetailsJS = oneTimeDetails.toJS();
			const { payment_profile_id } = oneTimeDetailsJS;

			yield put(
				makePaymentModuleActions.fetch({
					amount,
					payment_profile_id,
				}),
			);
			const result = yield take([
				makePaymentModuleActions.setType,
				makePaymentModuleActions.errorType,
			]);
			if (result.type === makePaymentModuleActions.setType) {
				paymentSuccessful = true;
			}
			break;
		}
		default:
			break;
	}
	if (paymentSuccessful) {
		yield put(balanceActions.fetch());
		yield put(paymentActions.setStage({ stage: 'complete' }));
		yield put(
			accountDetailActions.fetch({
				alsowith: [
					'businessUnit',
					'customerLifecycle',
					'highlights',
					'managementPortal',
				],
			}),
		);
	}
}

function* resetPaymentStage({ payload }) {
	const { location } = payload;
	const { lwpaymentid } = queryString.parse(location.search);
	if (location.pathname === '/account/billing/pay' && !lwpaymentid) {
		yield put(paymentActions.setStage({ stage: 'editing' }));
	}
}

function* listenConfirmPayment() {
	yield takeEvery(paymentActions.confirmType, confirmPayment);
}

function* listenRouteChanges() {
	yield takeEvery('@@router/LOCATION_CHANGE', resetPaymentStage);
}

export default function* rootSaga() {
	yield all([
		listenCreatePayment(),
		listenFetchPayPal(),
		listenConfirmPayment(),
		listenRouteChanges(),
	]);
}
