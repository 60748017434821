// faking the jwt structure so we can get the profile object.

/* To use these tokens for testing, pass in the corresponding variable
name without the "Token" suffix to the DispatchWrapper in helperFunctions.js
via the authLevel prop */

const fakeJwtify = (obj) => `.${btoa(JSON.stringify(obj))}.`;

const adminToken = fakeJwtify({ group: ['basket-admin'] });
const userToken = fakeJwtify({
	role: ['AccountLogin'],
});

// paymenth method will return as paypal for this token during test runs.
const ownerToken = fakeJwtify({
	iss: 'https://login.dev.liquidweb.com',
	sub: 'FcXpQ13nQgWtpo6rwv31TA',
	aud: 'wallaby_manage',
	exp: 1624808063,
	iat: 1624462463,
	jti: 'sqI3VIojQ8-nQsXvodxkVQ',
	nonce: '482514d01cf34bb5b71674ba2bd7e694',
	email: 'scathey@liquidweb.com',
	username: 'mctesterson',
	name: 'Sabrina McTesterson',
	family_name: 'McTesterson',
	given_name: 'Sabrina',
	group: ['account-3378300'],
	role: ['AccountOwner', 'AccountLogin'],
});
const unauthedToken = fakeJwtify({
	id: '1e2d99b6210c4a67bcb9600c8972d06a',
	created: 1628506635,
	request_type: 'si:r',
	nonce: 'a017759732374c6f9e419df5c4b4a5d4',
	redirect_uri: 'https://my.dev.liquidweb.com/external/openid/return',
	authority: 'https://login.dev.liquidweb.com',
	client_id: 'kLl1YXSEQXqERnpsmIPePQ',
	response_mode: null,
	scope: 'openid profile grants',
	extraTokenParams: {},
});

/* For these next few tokens, we apparently need the USER_FOUND action dispatched before
react-router-dom will work. If we're going to dispatch that action, we may as well be set
up to add more information into the tokens. Feel free to fill them in as needed. */

// src/modules/api/account/billingAddress/mockData/index.js
const incompleteAddressToken = fakeJwtify({});
const incompletePaymentToken = fakeJwtify({});

export {
	adminToken,
	userToken,
	ownerToken,
	unauthedToken,
	incompleteAddressToken,
	incompletePaymentToken,
};
