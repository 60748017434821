import { combineReducers } from 'redux';

import { reducer as connections } from './connectionsModule';
import { reducer as listReducer } from './listModule';
import { reducer as isValidReducer } from './isValidModule';
import { reducer as detailsReducer } from './detailsModule';
import { reducer as remove } from './removeModule';
import { reducer as deleteReducer } from './deleteModule';
import measurements from './measurements/measurementsReducer';
import { reducer as update } from './updateModule';

export default combineReducers({
	connections,
	delete: deleteReducer,
	details: detailsReducer,
	remove,
	measurements,
	isValid: isValidReducer,
	list: listReducer,
	update,
});
