import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import InputLabel from './InputLabel';

const SubDomainInput = ({ showLabels, inputSubdomain, setInputSubdomain }) => (
	<Grid item container alignItems="center">
		{showLabels && (
			<Grid item>
				<InputLabel>C.</InputLabel>
			</Grid>
		)}
		<Grid xs item>
			<TextField
				label="Custom Sub-domain (optional)"
				variant="outlined"
				value={inputSubdomain}
				inputProps={{ 'data-lpignore': true }}
				onChange={(e) => {
					setInputSubdomain(e.target.value);
				}}
			/>
		</Grid>
	</Grid>
);

SubDomainInput.propTypes = {
	showLabels: PropTypes.bool,
	inputSubdomain: PropTypes.string,
	setInputSubdomain: PropTypes.func,
};

export default SubDomainInput;
