import { createAPIModule } from 'utility/redux/apiModuleHelpers';

const getStateSlice = (state) => state.salesforce.account.interworx.update;

const { actions, reducer, sagas, selectors } = createAPIModule({
	getStateSlice,
	actionType: 'SALESFORCE_ACCOUNT_INTERWORX_UPDATE',
	method: 'POST',
	url: '/salesforce/account/interworx/update.json',
});

export { actions, reducer, sagas, selectors };
