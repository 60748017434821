export default {
	'ab3dd57c-a2aa-4de9-9fd5-afe115c7cffc': {
		account: 3378300,
		claimable: 1,
		cloneable: 1,
		created: '2021-07-02 11:56:52.982734-04',
		discount: '0.00000',
		expire: null,
		locked: 0,
		mergeable: 1,
		metadata: { target_items: ['53d1f1f4-7f2f-4206-9292-555000a4912d'] },
		modifiable: 1,
		name: null,
		owner: 'mctesterson',
		purchasable: 1,
		share_code: 'debf8261-eb88-4260-8efe-1297067a85da',
		status: 'empty',
		unlockable: 1,
		uuid: '864ed1f5-771b-4e2f-9bad-f22b701a8007',
		valid: 1,
	},
	'25923e9c-0760-46bb-98f9-b91e8f81e5c2': {
		account: 3378300,
		claimable: 1,
		cloneable: 1,
		created: '2021-07-02 11:56:52.982734-04',
		discount: '0.00000',
		expire: null,
		locked: 0,
		mergeable: 1,
		metadata: {},
		modifiable: 1,
		name: null,
		owner: 'mctesterson',
		purchasable: 1,
		share_code: 'debf8261-eb88-4260-8efe-1297067a85da',
		status: 'empty',
		unlockable: 1,
		uuid: '11adb939-a982-4ae8-8776-8ef37a7fa63a',
		valid: 1,
	},
};
