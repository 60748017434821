import { call, delay } from 'redux-saga/effects';
import randomNumberBetween from 'utility/randomNumberBetween';
import storyOrTest from 'utility/tools/storyOrTest';
import mockApi from './mockApiClient';

// This changes during tests to test load states.
const testing = () => process.env.NODE_ENV === 'test';

/**
 * proxy to axios call. Handles standard actions from bad status codes, and errors in general.
 * @param {object} axiosData - The object passed along to axios
 * @returns {(object)} - Returns keys request (full axios request) and data on good response,
 * returns error on bad response
 */

export default function* sendApiRequest(apiData) {
	const response = yield call(mockApi, apiData);
	if (!storyOrTest())
		// eslint-disable-next-line no-console
		console.log('Mock API called', { request: apiData, response });
	if (apiData.url !== '/appConfig.json' && !testing())
		// for testing load states.
		yield delay(randomNumberBetween(1, 1000));
	return { response, data: response };
}
