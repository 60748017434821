import React from 'react';
import ReimageDialogContents from 'components/structural/ReimageDialogContents';
import { connect } from 'react-redux';

import { selectors as assetDetailsSelectors } from 'modules/api/asset/detailsModule';
import { selectors as imageListSelectors } from 'modules/api/storm/image/listModule';

import restoreActions from 'modules/server/backupsAndImages/restore/actions';
import dialogActions from 'modules/dialogs/actions';
import { dateTimeLong } from 'utility/datetime';

const ImageConfirmationDialog = ({ id, uniqId, ...props }) => {
	return <ReimageDialogContents {...props} />;
};

const mapStateToProps = (state, props) => {
	const image = imageListSelectors.selected(state, props);
	return {
		confirmText: 'Reimage Server',
		sourceTitle: 'Selected Image',
		sourceDetails: [
			dateTimeLong(image?.time_taken),
			`${image?.size} GB`,
			image?.source_hostname,
			image?.template_description,
		],
		targetTitle: 'Selected Server',
		targetDetails: [
			assetDetailsSelectors.domain(state),
			assetDetailsSelectors.instance(state)?.template_description,
		],
		verb: 'reimaging',
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	onConfirm: ({ rebuild }) => {
		dispatch(
			restoreActions.restoreImage({
				force: rebuild,
				id: props.id,
				uniqId: props.uniqId,
			}),
		);
	},
	onCancel: () => {
		dispatch(dialogActions.close());
	},
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(ImageConfirmationDialog);
