import produce from 'immer';
import actions from './actions';

const initialState = {
	isOpen: false,
	type: null,
	data: {},
};

/* eslint-disable default-case, no-param-reassign */
const reducer = (state = initialState, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case actions.SERVER_BACKUPS_IMAGES_HANDLE_DIALOG:
				Object.assign(draft, state, action.payload);
			// TODO figure out why closing restore dialog sets data to null
		}
	});

export default reducer;
