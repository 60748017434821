import { spawn } from 'redux-saga/effects';

import { sagas as allSagas } from './allModule';
import { sagas as currentSagas } from './currentModule';
import { sagas as detailsSagas } from './detailsModule';
import { sagas as resolveSagas } from './resolveModule';

export default function* notificationSagas() {
	yield spawn(allSagas);
	yield spawn(currentSagas);
	yield spawn(detailsSagas);
	yield spawn(resolveSagas);
}
