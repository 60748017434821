import { spawn } from 'redux-saga/effects';

import { sagas as listSagas } from './listModule';
import { sagas as setDefaultSagas } from './setDefaultModule';
import { sagas as detailsSagas } from './detailsModule';

export default function* zoneSagas() {
	yield spawn(listSagas);
	yield spawn(setDefaultSagas);
	yield spawn(detailsSagas);
}
