import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import LWTypography from 'components/common/LWTypography';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import LWButton from 'components/common/LWButton';
import PropTypes from 'prop-types';

const ActionButtons = ({
	quickAddIsLoading = false,
	quickAdd,
	productCode,
	itemsInCart = 0,
	showConfigure = true,
	region = 1,
}) => {
	const history = useHistory();

	const quickAddText = useMemo(() => {
		if (quickAddIsLoading) {
			return 'Adding...';
		}

		if (!itemsInCart) {
			return '';
		}

		return `Added (${itemsInCart}) to Cart!`;
	}, [itemsInCart, quickAddIsLoading]);

	const configure = () => {
		history.push(`/shop/add/${productCode}?region=${region}`);
	};

	return (
		<>
			<Box mt={2} whiteSpace="nowrap">
				<Box display="flex" pb={1}>
					<LWButton
						isLoading={quickAddIsLoading}
						onClick={() => quickAdd({ region })}
						endIcon={<AddShoppingCartIcon />}
					>
						Quick Add
					</LWButton>
					<LWTypography
						BoxProps={{ ml: 2.25, mt: 1 }}
						variant="caption"
						color="textSecondary"
					>
						{quickAddText}
					</LWTypography>
				</Box>

				{showConfigure && (
					<Box pr={1}>
						<LWButton
							disabled={quickAddIsLoading}
							onClick={configure}
							endIcon={<ArrowForwardIcon />}
						>
							Add & Configure
						</LWButton>
					</Box>
				)}
			</Box>
		</>
	);
};

ActionButtons.propTypes = {
	/** Function that fires a Redux action creator when user clicks "Quick Add". */
	quickAdd: PropTypes.func.isRequired,
	/** The number of items in cart with the current product code. */
	itemsInCart: PropTypes.number,
	/** The code used to take the user to the config screen */
	productCode: PropTypes.string.isRequired,
	/** If true, the Quick Add and Configure buttons are disabled and the secondary text changes to "Adding..." */
	quickAddIsLoading: PropTypes.bool,
	/** Display the configure button */
	showConfigure: PropTypes.bool,
	/** Region being used to add the item. */
	region: PropTypes.number,
};

export default ActionButtons;
