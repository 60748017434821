const uuids = {
	'SS.VPS': '92ebf965-830f-40af-8b39-42845e57366f',
	'SS.PP': '1d30e04b-0491-4455-956e-5f947fac7221',
	'SS.VM': '99473347-1a21-4fdd-8b65-09a3a23b3e86',
	'SS.VPS2': '0b5fb0f9-0fea-4b66-a859-6b0cdb044cd7', // a VPS with less stuff.
	'SS.VPS3': 'c965fa1d-6c7b-44a7-8063-cda48e40ed25',
	'SS.VPS.WIN': 'f1fbe9cb-5a11-4a27-942b-36e4286427c9',
	'UUID.ONLY': '54251fe4-b35c-4dcc-bd12-6e1ec953c8a2',
	Dedicated: '0244dfc2-6c20-4afa-9a52-8eb2e39bfe9d',
	domainRegistraion: 'bf8f8ae5-2a2b-4172-aaf6-9def7974ea8a',
};

const items = {
	'54251fe4-b35c-4dcc-bd12-6e1ec953c8a2': {
		uuid: '54251fe4-b35c-4dcc-bd12-6e1ec953c8a2',
	},
	'bf8f8ae5-2a2b-4172-aaf6-9def7974ea8a': {
		base_price: '0.00000',
		basket_uuid: 'eb65e5e0-7e2e-4f63-96b1-350f937eab65',
		configs: [
			{
				discount: '0.00000',
				is_extra: 0,
				key: 'DomainRegistrationAutoRenew',
				key_description: 'Domain Registration AutoRenew',
				key_display_order: 1,
				key_group: 'Misc. Options',
				key_group_display_order: 2147483647,
				num_units: null,
				options: [
					{
						is_extra: 0,
						key: 'DomainRegistrationAutoRenew',
						key_description: 'Domain Registration AutoRenew',
						key_display_order: 1,
						num_units: null,
						price: 0,
						price_delta: 0,
						price_total: 0,
						price_total_delta: 0,
						value: 'Disabled',
						value_comments: null,
						value_description: 'AutoRenew Disabled',
						value_display_order: 0,
					},
					{
						is_extra: 0,
						key: 'DomainRegistrationAutoRenew',
						key_description: 'Domain Registration AutoRenew',
						key_display_order: 1,
						num_units: null,
						price: 0,
						price_delta: 0,
						price_total: 0,
						price_total_delta: 0,
						value: 'Off',
						value_comments: null,
						value_description: 'AutoRenew Off',
						value_display_order: 0,
					},
				],
				parent_key: null,
				parent_value: null,
				price: '0.00000',
				price_total: '0.00000',
				tax: null,
				totals: {
					subtotal_cost: '0.00000',
					total_cost: '0.00000',
				},
				valid: 1,
				value: 'On',
				value_comments: null,
				value_description: 'AutoRenew On',
				value_display_order: 2,
				value_price_time_unit: 'one-time',
			},
			{
				discount: '0.00000',
				is_extra: 0,
				key: 'DomainPrivacy',
				key_description: 'Domain Privacy',
				key_display_order: 1,
				key_group: 'Misc. Options',
				key_group_display_order: 2147483647,
				num_units: null,
				options: [
					{
						is_extra: 0,
						key: 'DomainPrivacy',
						key_description: 'Domain Privacy',
						key_display_order: 1,
						num_units: null,
						price: 0,
						price_delta: -15,
						price_total: 0,
						price_total_delta: -15,
						value: 'Off',
						value_comments: null,
						value_description: 'Off',
						value_display_order: 1,
					},
				],
				parent_key: null,
				parent_value: null,
				price: '15.00000',
				price_total: '15.00000',
				tax: null,
				totals: {
					subtotal_cost: '15.00000',
					total_cost: '15.00000',
				},
				valid: 1,
				value: 'On',
				value_comments: null,
				value_description: 'On (Purchased, enabled)',
				value_display_order: 2,
				value_price_time_unit: 'one-time',
			},
			{
				discount: '0.00000',
				is_extra: 0,
				key: 'DomainRegistration',
				key_description: 'Domain Registration',
				key_display_order: null,
				key_group: 'Misc. Options',
				key_group_display_order: 2147483647,
				num_units: 1,
				options: [],
				parent_key: null,
				parent_value: null,
				price: '8.95000',
				price_total: '8.95000',
				tax: null,
				totals: {
					subtotal_cost: '8.95000',
					total_cost: '8.95000',
				},
				valid: 1,
				value: 'Registration.Enom',
				value_comments: null,
				value_description: 'Enom Domain Registration',
				value_display_order: 1,
				value_price_time_unit: 'one-time',
			},
		],
		created: '2021-05-03 11:09:21.565588-04',
		discount: '0.00000',
		productInfo: {
			alias: null,
			capabilities: {
				AutoActivate: 1,
			},
			credit_eligible: 1,
			cycle: 'one-time',
			discountable: 1,
			product_code: 'DREG',
			product_description: 'Registered Domain',
			product_display_group: 'Network',
			sub_title: '',
			tax_code: 'SW050300',
			title: '',
		},
		product_code: 'DREG',
		properties: {
			Address1: '2705 Ena Dr',
			Address2: null,
			City: 'Lansing',
			Country: 'US',
			EmailAddress: 'sabcatlibra@gmail.com',
			FirstName: 'Sabrina',
			JobTitle: 'individual',
			LastName: 'McTesterson',
			OrganizationName: "McTesterson's Widgets",
			Phone: '5172854692',
			PostalCode: '48933',
			StateProvince: 'MI',
			domain: 'thebest.org',
			max_period: '10',
			min_period: '1',
			nameServers: ['ns.liquidweb.com', 'ns1.liquidweb.com'],
			project: {
				project_name: 'Cameron-Test',
			},
		},
		region: 1,
		tax: null,
		totals: {
			configs_discount_total: '0.00000',
			configs_price_total: '23.95000',
			configs_subtotal: '23.95000',
			configs_tax_total: '0',
			configs_total_cost: '23.95000',
			item_discount_total: '0.00000',
			item_prepay_auto_renew: null,
			item_prepay_discount: null,
			item_prepay_once: null,
			item_prepay_ongoing: null,
			item_prepay_term_max: null,
			item_price_total: '23.95000',
			item_setup_fee: '0',
			item_subtotal: '23.95000',
			item_tax_total: '0',
			item_total_cost: '23.95000',
			subtotal_base_cost: '0.00000',
			total_base_cost: '0.00000',
		},
		uuid: 'bf8f8ae5-2a2b-4172-aaf6-9def7974ea8a',
		valid: 1,
	},
	'0244dfc2-6c20-4afa-9a52-8eb2e39bfe9d': {
		base_price: '199',
		basket_uuid: 'd75ab8c2-b210-4458-82eb-a12597d60a1b',
		configs: [
			{
				discount: '0',
				key: 'Processor',
				key_description: 'Processor',
				key_display_order: 1,
				key_group: 'Server Options',
				num_units: null,
				parent_key: null,
				parent_value: null,
				price: '0',
				price_total: '0',
				tax: null,
				totals: {
					subtotal_cost: '0',
					total_cost: '0',
				},
				valid: 1,
				value: 'Intel.E31230.v5.4cores.1socket',
				value_comments:
					'<i>Up to <font color="green"><b>3.80 GHz</b></font> per Core!</i>',
				value_description: 'Intel Xeon E3-1230 v5 Quad-Core',
				value_display_order: 1,
				value_price_time_unit: 'month',
			},
			{
				discount: '0',
				key: 'OS',
				key_description: 'Operating System',
				key_display_order: 3,
				key_group: 'Server Options',
				num_units: null,
				parent_key: null,
				parent_value: null,
				price: '0',
				price_total: '0',
				tax: null,
				totals: {
					subtotal_cost: '0',
					total_cost: '0',
				},
				valid: 1,
				value: 'Linux',
				value_comments:
					'<i><font color="green">CloudLinux is available with our Fully Managed & Self Managed Images.</font></i>',
				value_description: 'Linux OS',
				value_display_order: 50,
				value_price_time_unit: 'month',
			},
			{
				discount: '0',
				key: 'centos',
				key_description: 'Linux OS',
				key_display_order: 10,
				key_group: null,
				num_units: null,
				parent_key: 'OS',
				parent_value: 'Linux',
				price: '0',
				price_total: '0',
				tax: null,
				totals: {
					subtotal_cost: '0',
					total_cost: '0',
				},
				valid: 1,
				value: 'CentOs764Bit',
				value_comments: null,
				value_description: 'CentOs 7 - 64Bit',
				value_display_order: 5,
				value_price_time_unit: 'month',
			},
			{
				discount: '0',
				key: 'WebServer',
				key_description: 'Web Server',
				key_display_order: 21,
				key_group: null,
				num_units: null,
				parent_key: 'OS',
				parent_value: 'Linux',
				price: '0',
				price_total: '0',
				tax: null,
				totals: {
					subtotal_cost: '0',
					total_cost: '0',
				},
				valid: 1,
				value: 'Apache',
				value_comments: null,
				value_description: 'Apache',
				value_display_order: 0,
				value_price_time_unit: 'month',
			},
			{
				discount: '0',
				key: 'RAM',
				key_description: 'Memory',
				key_display_order: 6,
				key_group: 'Server Options',
				num_units: null,
				parent_key: null,
				parent_value: null,
				price: '90',
				price_total: '90',
				tax: null,
				totals: {
					subtotal_cost: '90',
					total_cost: '90',
				},
				valid: 1,
				value: '64GB',
				value_comments: null,
				value_description: '64GB DDR3 SDRAM',
				value_display_order: 2,
				value_price_time_unit: 'month',
			},
			{
				discount: '0',
				key: 'HD1',
				key_description: 'Primary hard drive',
				key_display_order: 9,
				key_group: 'Storage and Backups',
				num_units: null,
				parent_key: null,
				parent_value: null,
				price: '0',
				price_total: '0',
				tax: null,
				totals: {
					subtotal_cost: '0',
					total_cost: '0',
				},
				valid: 1,
				value: '2SSD1100',
				value_comments: null,
				value_description: '2 x SSD',
				value_display_order: 0,
				value_price_time_unit: 'month',
			},
			{
				discount: '0',
				key: 'PrimaryDriveSize',
				key_description: 'Drive Size',
				key_display_order: 0,
				key_group: null,
				num_units: null,
				parent_key: 'HD1',
				parent_value: '2SSD1100',
				price: '0',
				price_total: '0',
				tax: null,
				totals: {
					subtotal_cost: '0',
					total_cost: '0',
				},
				valid: 1,
				value: 'SSD240GB',
				value_comments: null,
				value_description: '240 GB SSD',
				value_display_order: 1,
				value_price_time_unit: 'month',
			},
			{
				discount: '0',
				key: 'PrimaryRAID',
				key_description: 'RAID Level Configuration',
				key_display_order: 1,
				key_group: null,
				num_units: null,
				parent_key: 'HD1',
				parent_value: '2SSD1100',
				price: '0',
				price_total: '0',
				tax: null,
				totals: {
					subtotal_cost: '0',
					total_cost: '0',
				},
				valid: 1,
				value: 'SWRAID1',
				value_comments: null,
				value_description: 'Software RAID 1',
				value_display_order: 0,
				value_price_time_unit: 'month',
			},
			{
				discount: '0',
				key: 'HD2',
				key_description: 'Backup Hard Drive',
				key_display_order: 12,
				key_group: 'Storage and Backups',
				num_units: null,
				parent_key: null,
				parent_value: null,
				price: '0',
				price_total: '0',
				tax: null,
				totals: {
					subtotal_cost: '0',
					total_cost: '0',
				},
				valid: 1,
				value: '1SATAHDDBK',
				value_comments: null,
				value_description: 'Single SATA HDD (7,200 RPM)',
				value_display_order: 1,
				value_price_time_unit: 'month',
			},
			{
				discount: '0',
				key: 'BackupDriveSize',
				key_description: 'Drive Size',
				key_display_order: null,
				key_group: null,
				num_units: null,
				parent_key: 'HD2',
				parent_value: '1SATAHDDBK',
				price: '0',
				price_total: '0',
				tax: null,
				totals: {
					subtotal_cost: '0',
					total_cost: '0',
				},
				valid: 1,
				value: 'SATA1TB',
				value_comments: null,
				value_description: '1 TB SATA HDD (7,200 RPM)',
				value_display_order: 0,
				value_price_time_unit: 'month',
			},
			{
				discount: '0',
				key: 'HD3',
				key_description: 'Tertiary Hard Drive',
				key_display_order: 15,
				key_group: 'Storage and Backups',
				num_units: null,
				parent_key: null,
				parent_value: null,
				price: '0',
				price_total: '0',
				tax: null,
				totals: {
					subtotal_cost: '0',
					total_cost: '0',
				},
				valid: 1,
				value: 'NODRIVE',
				value_comments: null,
				value_description: 'No Tertiary Drive',
				value_display_order: 1,
				value_price_time_unit: 'month',
			},
			{
				discount: '0',
				key: 'NVMe',
				key_description: 'NVMe SSD Storage',
				key_display_order: 16,
				key_group: 'Storage and Backups',
				num_units: null,
				parent_key: null,
				parent_value: null,
				price: '0',
				price_total: '0',
				tax: null,
				totals: {
					subtotal_cost: '0',
					total_cost: '0',
				},
				valid: 1,
				value: 'NoNVMe',
				value_comments: null,
				value_description: 'No NVMe Storage',
				value_display_order: 0,
				value_price_time_unit: 'month',
			},
			{
				discount: '0',
				key: 'GUARD',
				key_description: 'Remote Backup',
				key_display_order: 20,
				key_group: 'Storage and Backups',
				num_units: null,
				parent_key: null,
				parent_value: null,
				price: '0',
				price_total: '0',
				tax: null,
				totals: {
					subtotal_cost: '0',
					total_cost: '0',
				},
				valid: 1,
				value: 'NoGuardian',
				value_comments: null,
				value_description: 'No Remote Backup Needed',
				value_display_order: 0,
				value_price_time_unit: 'month',
			},
			{
				discount: '0',
				key: 'SERVERCHASSIS',
				key_description: 'Server Chassis',
				key_display_order: 21,
				key_group: null,
				num_units: null,
				parent_key: null,
				parent_value: null,
				price: '0',
				price_total: '0',
				tax: null,
				totals: {
					subtotal_cost: '0',
					total_cost: '0',
				},
				valid: 1,
				value: 'RegularChassis',
				value_comments: null,
				value_description: 'Standard - Single PSU - No Hot Swap Bays',
				value_display_order: 0,
				value_price_time_unit: 'month',
			},
			{
				discount: '0',
				key: 'DDOSProtection',
				key_description: 'DDOS Protection',
				key_display_order: 33,
				key_group: 'Security Options',
				num_units: null,
				parent_key: null,
				parent_value: null,
				price: '0',
				price_total: '0',
				tax: null,
				totals: {
					subtotal_cost: '0',
					total_cost: '0',
				},
				valid: 1,
				value: 'CoreroDDoSProtection',
				value_comments: null,
				value_description: 'Standard DDoS Attack Protection (up to 2gbps)',
				value_display_order: 0,
				value_price_time_unit: 'month',
			},
			{
				discount: '0',
				key: 'ExtraIp',
				key_description: 'Extra IP Addresses',
				key_display_order: 36,
				key_group: 'Network Options',
				num_units: null,
				parent_key: null,
				parent_value: null,
				price: '0',
				price_total: '0',
				tax: null,
				totals: {
					subtotal_cost: '0',
					total_cost: '0',
				},
				valid: 1,
				value: 'PublicIPs',
				value_comments: null,
				value_description: 'Public IP Addresses',
				value_display_order: 1,
				value_price_time_unit: 'month',
			},
			{
				discount: '0',
				key: 'PublicIPAddresses',
				key_description: 'Public IP Addresses',
				key_display_order: 1,
				key_group: null,
				num_units: null,
				parent_key: 'ExtraIp',
				parent_value: 'PublicIPs',
				price: '0',
				price_total: '0',
				tax: null,
				totals: {
					subtotal_cost: '0',
					total_cost: '0',
				},
				valid: 1,
				value: '1IP',
				value_comments:
					'<font color="green">Requests for more than 3 IPs will require additional information.</font>',
				value_description: '1 Public IP Address',
				value_display_order: 0,
				value_price_time_unit: 'month',
			},
			{
				discount: '0',
				key: 'Bandwidth',
				key_description: 'Bandwidth',
				key_display_order: 39,
				key_group: 'Network Options',
				num_units: null,
				parent_key: null,
				parent_value: null,
				price: '0',
				price_total: '0',
				tax: null,
				totals: {
					subtotal_cost: '0',
					total_cost: '0',
				},
				valid: 1,
				value: 'OBW.5000',
				value_comments: null,
				value_description: '5 TB Outbound Bandwidth',
				value_display_order: 0,
				value_price_time_unit: 'static-month',
			},
			{
				discount: '0',
				key: 'PortSpeed',
				key_description: 'Port Speed',
				key_display_order: null,
				key_group: null,
				num_units: null,
				parent_key: 'Bandwidth',
				parent_value: 'OBW.5000',
				price: '0',
				price_total: '0',
				tax: null,
				totals: {
					subtotal_cost: '0',
					total_cost: '0',
				},
				valid: 1,
				value: '100',
				value_comments: null,
				value_description: '100M uplink port',
				value_display_order: 0,
				value_price_time_unit: 'month',
			},
			{
				discount: '0',
				key: 'SetupFee',
				key_description: 'Setup Fee',
				key_display_order: 45,
				key_group: null,
				num_units: null,
				parent_key: null,
				parent_value: null,
				price: '0',
				price_total: '0',
				tax: null,
				totals: {
					subtotal_cost: '0',
					total_cost: '0',
				},
				valid: 1,
				value: 'setup0',
				value_comments: null,
				value_description: 'No setup fee',
				value_display_order: 70,
				value_price_time_unit: 'month',
			},
			{
				discount: '0',
				key: 'ControlPanel',
				key_description: 'Management & Control Panel',
				key_display_order: 4,
				key_group: 'Server Options',
				num_units: null,
				parent_key: null,
				parent_value: null,
				price: '20',
				price_total: '20',
				tax: null,
				totals: {
					subtotal_cost: '20',
					total_cost: '20',
				},
				valid: 1,
				value: 'interworx',
				value_comments: '<font color="green"><i>(Linux CentOS Only)</i></font>',
				value_description: 'Interworx - Fully Managed',
				value_display_order: 10,
				value_price_time_unit: 'month',
			},
			{
				discount: '0',
				key: 'DediAcronis',
				key_description: 'Acronis Backups',
				key_display_order: null,
				key_group: 'Storage and Backups',
				num_units: null,
				parent_key: null,
				parent_value: null,
				price: '0',
				price_total: '0',
				tax: null,
				totals: {
					subtotal_cost: '0',
					total_cost: '0',
				},
				valid: 1,
				value: 'noacronis',
				value_comments: null,
				value_description: 'No Backups Required',
				value_display_order: 0,
				value_price_time_unit: 'month',
			},
		],
		created: '2020-12-01 13:30:17.754-05',
		discount: '0',
		productInfo: {
			alias: 'single-processor-1230v5',
			credit_eligible: 1,
			cycle: 'month',
			discountable: 1,
			product_code: 'DS.1230v5',
			product_description:
				'Dedicated Server - Single Intel Xeon CPU - US Central Zone',
			product_display_group: 'Dedicated',
			sub_title: 'Intel Xeon E3-1230 v5, 4-core',
			tax_code: 'SW050400',
			title: 'Single Processor',
		},
		product_code: 'DS.1230v5',
		properties: {},
		region: 1,
		tax: null,
		totals: {
			configs_discount_total: '0',
			configs_price_total: '110',
			configs_subtotal: '110',
			configs_tax_total: '0',
			configs_total_cost: '110',
			item_discount_total: '0',
			item_price_total: '309',
			item_subtotal: '309',
			item_tax_total: '0',
			item_total_cost: '309',
			subtotal_base_cost: '199',
			total_base_cost: '199',
		},
		uuid: '0244dfc2-6c20-4afa-9a52-8eb2e39bfe9d',
		valid: 1,
	},
	'34646e1d-3cf4-4efd-8f15-297a8afe4d7c': {
		base_price: '10.00000',
		basket_uuid: '7594257b-90a2-4fe9-9d58-6db6a64063d2',
		configs: [
			{
				discount: '0.00000',
				is_extra: 0,
				key: 'AcronisStorageDestination',
				key_description: 'Backup Storage Destination',
				key_display_order: 10,
				key_group: 'Misc. Options',
				key_group_display_order: 2147483647,
				num_units: null,
				parent_key: null,
				parent_value: null,
				price: '0.00000',
				price_total: '0.00000',
				tax: null,
				totals: {
					subtotal_cost: '0.00000',
					total_cost: '0.00000',
				},
				valid: 1,
				value: 'AcronisLiquidWebStorage',
				value_comments: null,
				value_description: 'Liquid Web',
				value_display_order: 10,
				value_price_time_unit: 'month',
			},
			{
				discount: '0.00000',
				is_extra: 0,
				key: 'AcronisStorageQuotaLiquidWeb',
				key_description: 'Storage Quota',
				key_display_order: 1,
				key_group: 'Misc. Options',
				key_group_display_order: 2147483647,
				num_units: null,
				parent_key: 'AcronisStorageDestination',
				parent_value: 'AcronisLiquidWebStorage',
				price: '10.00000',
				price_total: '10.00000',
				tax: null,
				totals: {
					subtotal_cost: '10.00000',
					total_cost: '10.00000',
				},
				valid: 1,
				value: '100',
				value_comments: null,
				value_description: '100 GB',
				value_display_order: 10,
				value_price_time_unit: 'month',
			},
		],
		created: '2021-02-22 07:44:30.316569-05',
		discount: '6.00000',
		productInfo: {
			alias: null,
			capabilities: {
				locationable: 1,
				noIP: 1,
				noServer: 1,
				noUsername: 1,
			},
			credit_eligible: 1,
			cycle: 'month',
			discountable: 1,
			product_code: 'AcronisBackup',
			product_description: 'Acronis Cyber Backups',
			product_display_group: 'Services',
			sub_title: '',
			tax_code: null,
			title: '',
		},
		product_code: 'AcronisBackup',
		properties: {
			domain: 'host.folksneedopenstack.com',
			related_subaccnt: 'B9W8C8',
		},
		region: 1,
		tax: null,
		totals: {
			configs_discount_total: '0.00000',
			configs_price_total: '10.00000',
			configs_subtotal: '10.00000',
			configs_tax_total: '0',
			configs_total_cost: '10.00000',
			item_discount_total: '6.00000',
			item_prepay_auto_renew: null,
			item_prepay_discount: null,
			item_prepay_once: null,
			item_prepay_ongoing: null,
			item_prepay_term_max: null,
			item_price_total: '20.00000',
			item_setup_fee: '0',
			item_subtotal: '14.00000',
			item_tax_total: '0',
			item_total_cost: '14.00000',
			subtotal_base_cost: '4.00000',
			total_base_cost: '4.00000',
		},
		uuid: '34646e1d-3cf4-4efd-8f15-297a8afe4d7c',
		valid: 1,
	},
	'57d96751-bd05-4842-8d0d-54765f29139f': {
		base_price: '0.00000',
		basket_uuid: '7cf3a87e-8c4e-46d7-9be7-dca1633cb36a',
		configs: [
			{
				discount: '0.00000',
				is_extra: 0,
				key: 'ConfigId',
				key_description: 'Server Type',
				key_display_order: 1,
				key_group: 'Misc. Options',
				key_group_display_order: 2147483647,
				num_units: null,
				options: [
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 138,
						price_delta: 48,
						price_total: 138,
						price_total_delta: 48,
						value: '85',
						value_comments: null,
						value_description:
							"'Intel(R) Xeon(R) CPU           E5420  @ 2.50GHz', 2493MHz, 4 Core, Sockets: 2",
						value_display_order: 60,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 150,
						price_delta: 60,
						price_total: 150,
						price_total_delta: 60,
						value: '95',
						value_comments: null,
						value_description:
							"'Intel(R) Core(TM)2 Quad CPU    Q9400  @ 2.66GHz', 2663MHz, 4 Core, Sockets: 1",
						value_display_order: 65,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 150,
						price_delta: 60,
						price_total: 150,
						price_total_delta: 60,
						value: '92',
						value_comments: null,
						value_description:
							"'Intel(R) Xeon(R) CPU E5-2620 0 @ 2.00GHz', 1999MHz, 6 Core, Sockets: 2",
						value_display_order: 63,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 150,
						price_delta: 60,
						price_total: 150,
						price_total_delta: 60,
						value: '93',
						value_comments: null,
						value_description:
							"'AMD FX(tm)-6100 Six-Core Processor', 3314MHz, 6 Core, Sockets: 1",
						value_display_order: 61,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 200,
						price_delta: 110,
						price_total: 200,
						price_total_delta: 110,
						value: '103',
						value_comments: null,
						value_description:
							"'Intel(R) Core(TM)2 Quad CPU    Q9400  @ 2.66GHz', 2663MHz, 4 Core, Sockets: 1",
						value_display_order: 68,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 200,
						price_delta: 110,
						price_total: 200,
						price_total_delta: 110,
						value: '94',
						value_comments: null,
						value_description:
							"'Intel(R) Core(TM)2 Quad CPU    Q9400  @ 2.66GHz', 2663MHz, 4 Core, Sockets: 1",
						value_display_order: 66,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 250,
						price_delta: 160,
						price_total: 250,
						price_total_delta: 160,
						value: '96',
						value_comments: null,
						value_description: 'VPS 2000 Plus',
						value_display_order: 70,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 400,
						price_delta: 310,
						price_total: 400,
						price_total_delta: 310,
						value: '89',
						value_comments: null,
						value_description: '2GB',
						value_display_order: 72,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 500,
						price_delta: 410,
						price_total: 500,
						price_total_delta: 410,
						value: '90',
						value_comments: null,
						value_description:
							"'Intel(R) Core(TM)2 Quad CPU    Q9400  @ 2.66GHz', 2663MHz, 4 Core, Sockets: 1",
						value_display_order: 74,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 600,
						price_delta: 510,
						price_total: 600,
						price_total_delta: 510,
						value: '91',
						value_comments: null,
						value_description:
							"'Intel(R) Xeon(R) CPU E5-2620 0 @ 2.00GHz', 1999MHz, 6 Core, Sockets: 2",
						value_display_order: 75,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 750,
						price_delta: 660,
						price_total: 750,
						price_total_delta: 660,
						value: '87',
						value_comments: null,
						value_description:
							"'Intel(R) Core(TM)2 Quad CPU    Q6600  @ 2.40GHz', 2400MHz, 4 Core, Sockets: 1",
						value_display_order: 77,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 50,
						price_delta: -40,
						price_total: 50,
						price_total_delta: -40,
						value: '107',
						value_comments: null,
						value_description:
							"'Intel(R) Core(TM)2 Quad CPU    Q9400  @ 2.66GHz', 2663MHz, 4 Core, Sockets: 1",
						value_display_order: 31,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 100,
						price_delta: 10,
						price_total: 100,
						price_total_delta: 10,
						value: '105',
						value_comments: null,
						value_description:
							"'AMD Phenom(tm) II X6 1055T Processor', 2812MHz, 6 Core, Sockets: 1",
						value_display_order: 53,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: null,
						price_delta: -90,
						price_total: null,
						price_total_delta: -90,
						value: '112',
						value_comments: null,
						value_description:
							"'Intel(R) Core(TM)2 Quad CPU    Q9400  @ 2.66GHz', 2662MHz, 4 Core, Sockets: 1",
						value_display_order: 2,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 100,
						price_delta: 10,
						price_total: 100,
						price_total_delta: 10,
						value: '113',
						value_comments: null,
						value_description:
							"'Intel(R) Core(TM) i5 CPU         760  @ 2.80GHz', 2799MHz, 4 Core, Sockets: 1",
						value_display_order: 49,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 100,
						price_delta: 10,
						price_total: 100,
						price_total_delta: 10,
						value: '114',
						value_comments: null,
						value_description:
							"'Intel(R) Core(TM)2 Quad CPU    Q9400  @ 2.66GHz', 2663MHz, 4 Core, Sockets: 1",
						value_display_order: 46,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 100,
						price_delta: 10,
						price_total: 100,
						price_total_delta: 10,
						value: '115',
						value_comments: null,
						value_description:
							"'Intel(R) Xeon(R) CPU           E5506  @ 2.13GHz', 2133MHz, 4 Core, Sockets: 2",
						value_display_order: 44,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 100,
						price_delta: 10,
						price_total: 100,
						price_total_delta: 10,
						value: '116',
						value_comments: null,
						value_description:
							"'Dual-Core AMD Opteron(tm) Processor 2212', 2000MHz, 2 Core, Sockets: 2",
						value_display_order: 55,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 100,
						price_delta: 10,
						price_total: 100,
						price_total_delta: 10,
						value: '117',
						value_comments: null,
						value_description:
							"'Intel(R) Xeon(R) CPU           X3440  @ 2.53GHz', 2533MHz, 4 Core, Sockets: 1",
						value_display_order: 41,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 100,
						price_delta: 10,
						price_total: 100,
						price_total_delta: 10,
						value: '118',
						value_comments: null,
						value_description:
							"'Intel(R) Core(TM) i5 CPU         760  @ 2.80GHz', 2800MHz, 4 Core, Sockets: 1",
						value_display_order: 52,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 100,
						price_delta: 10,
						price_total: 100,
						price_total_delta: 10,
						value: '119',
						value_comments: null,
						value_description:
							"'Intel(R) Xeon(R) CPU           E5606  @ 2.13GHz', 2133MHz, 4 Core, Sockets: 2",
						value_display_order: 37,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 100,
						price_delta: 10,
						price_total: 100,
						price_total_delta: 10,
						value: '120',
						value_comments: null,
						value_description:
							"'Intel(R) Xeon(R) CPU           X5650  @ 2.67GHz', 2666MHz, 6 Core, Sockets: 2",
						value_display_order: 38,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 100,
						price_delta: 10,
						price_total: 100,
						price_total_delta: 10,
						value: '121',
						value_comments: null,
						value_description:
							"'Intel(R) Core(TM) i5 CPU         760  @ 2.80GHz', 2800MHz, 4 Core, Sockets: 1",
						value_display_order: 34,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 150,
						price_delta: 60,
						price_total: 150,
						price_total_delta: 60,
						value: '106',
						value_comments: null,
						value_description:
							"'Intel(R) Core(TM) i5 CPU         760  @ 2.80GHz', 2800MHz, 4 Core, Sockets: 1",
						value_display_order: 64,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 100,
						price_delta: 10,
						price_total: 100,
						price_total_delta: 10,
						value: '128',
						value_comments: null,
						value_description:
							"'Quad-Core AMD Opteron(tm) Processor 2378', 2400MHz, 4 Core, Sockets: 2",
						value_display_order: 42,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 100,
						price_delta: 10,
						price_total: 100,
						price_total_delta: 10,
						value: '129',
						value_comments: null,
						value_description:
							"'Intel(R) Core(TM) i5 CPU         760  @ 2.80GHz', 2800MHz, 4 Core, Sockets: 1",
						value_display_order: 35,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 100,
						price_delta: 10,
						price_total: 100,
						price_total_delta: 10,
						value: '242',
						value_comments: null,
						value_description:
							"'Intel(R) Core(TM) i5 CPU         750  @ 2.67GHz', 2666MHz, 4 Core, Sockets: 1",
						value_display_order: 50,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 100,
						price_delta: 10,
						price_total: 100,
						price_total_delta: 10,
						value: '98',
						value_comments: null,
						value_description:
							"'AMD Athlon(tm) 64 X2 Dual Core Processor 5000+', 2600MHz, 2 Core, Sockets: 1",
						value_display_order: 54,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 115,
						price_delta: 25,
						price_total: 115,
						price_total_delta: 25,
						value: '158',
						value_comments: null,
						value_description:
							"'Intel(R) Core(TM) i5 CPU         750  @ 2.67GHz', 2666MHz, 4 Core, Sockets: 1",
						value_display_order: 58,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 20,
						price_delta: -70,
						price_total: 20,
						price_total_delta: -70,
						value: '245',
						value_comments: null,
						value_description:
							"'Intel(R) Core(TM) i5 CPU         760  @ 2.80GHz', 2800MHz, 4 Core, Sockets: 1",
						value_display_order: 26,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 100,
						price_delta: 10,
						price_total: 100,
						price_total_delta: 10,
						value: '160',
						value_comments: null,
						value_description: 'BH VPS 12G',
						value_display_order: 40,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 50,
						price_delta: -40,
						price_total: 50,
						price_total_delta: -40,
						value: '167',
						value_comments: null,
						value_description: 'test create config',
						value_display_order: 30,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 100,
						price_delta: 10,
						price_total: 100,
						price_total_delta: 10,
						value: '168',
						value_comments: null,
						value_description: '4GB - 8vCPU',
						value_display_order: 39,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 275,
						price_delta: 185,
						price_total: 275,
						price_total_delta: 185,
						value: '130',
						value_comments: null,
						value_description:
							"'AMD Opteron 6128', 2000MHz, 8 Core, Sockets: 2",
						value_display_order: 71,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 110,
						price_delta: 20,
						price_total: 110,
						price_total_delta: 20,
						value: '131',
						value_comments: null,
						value_description:
							"'Intel Core i5-760', 2800MHz, 4 Core, Sockets: 1",
						value_display_order: 56,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 490,
						price_delta: 400,
						price_total: 490,
						price_total_delta: 400,
						value: '132',
						value_comments: null,
						value_description:
							"'Intel(R) Xeon(R) CPU E5-2620 v2 @ 2.10GHz', 2100MHz, 6 Core, Sockets: 2",
						value_display_order: 73,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 1,
						price_delta: -89,
						price_total: 1,
						price_total_delta: -89,
						value: '151',
						value_comments: null,
						value_description:
							"'Intel(R) Xeon(R) CPU E3-1270 V2 @ 3.50GHz', 3499MHz, 4 Core, Sockets: 1",
						value_display_order: 14,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 10,
						price_delta: -80,
						price_total: 10,
						price_total_delta: -80,
						value: '170',
						value_comments: null,
						value_description: 'Storm 7GIG',
						value_display_order: 19,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 10,
						price_delta: -80,
						price_total: 10,
						price_total_delta: -80,
						value: '169',
						value_comments: null,
						value_description:
							"'Intel(R) Xeon(R) CPU           E5520  @ 2.27GHz', 2266MHz, 4 Core, Sockets: 2",
						value_display_order: 23,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 10,
						price_delta: -80,
						price_total: 10,
						price_total_delta: -80,
						value: '174',
						value_comments: null,
						value_description:
							"'Intel(R) Core(TM) i5 CPU         760  @ 2.80GHz', 2800MHz, 4 Core, Sockets: 1",
						value_display_order: 22,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 10,
						price_delta: -80,
						price_total: 10,
						price_total_delta: -80,
						value: '172',
						value_comments: null,
						value_description:
							"'Intel(R) Core(TM)2 Quad CPU    Q9400  @ 2.66GHz', 2663MHz, 4 Core, Sockets: 1",
						value_display_order: 20,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 750,
						price_delta: 660,
						price_total: 750,
						price_total_delta: 660,
						value: '155',
						value_comments: null,
						value_description:
							"(Intel(R) Xeon(R) CPU E3-1220 V2 @ 3.10GHz', 3100MHz, 4 Core, Sockets: 1)",
						value_display_order: 78,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 10,
						price_delta: -80,
						price_total: 10,
						price_total_delta: -80,
						value: '175',
						value_comments: null,
						value_description:
							"'Intel(R) Xeon(R) CPU E3-1220 V2 @ 3.10GHz', 3100MHz, 4 Core, Sockets: 1",
						value_display_order: 25,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 5,
						price_delta: -85,
						price_total: 5,
						price_total_delta: -85,
						value: '3',
						value_comments: null,
						value_description: '6GB',
						value_display_order: 15,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 250,
						price_delta: 160,
						price_total: 250,
						price_total_delta: 160,
						value: '178',
						value_comments: null,
						value_description: "rob's bm config",
						value_display_order: 69,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 1,
						price_delta: -89,
						price_total: 1,
						price_total_delta: -89,
						value: '8',
						value_comments: null,
						value_description:
							"'Intel(R) Xeon(R) CPU           X3440  @ 2.53GHz', 2534MHz, 4 Core, Sockets: 1",
						value_display_order: 3,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 1,
						price_delta: -89,
						price_total: 1,
						price_total_delta: -89,
						value: '26',
						value_comments: null,
						value_description:
							"'Intel(R) Xeon(R) CPU           X3440  @ 2.53GHz', 2534MHz, 8 Core, Sockets: 1",
						value_display_order: 7,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 75,
						price_delta: -15,
						price_total: 75,
						price_total_delta: -15,
						value: '184',
						value_comments: null,
						value_description:
							"'Intel(R) Core(TM) i5 CPU         760  @ 2.80GHz', 2800MHz, 4 Core, Sockets: 1",
						value_display_order: 32,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 10,
						price_delta: -80,
						price_total: 10,
						price_total_delta: -80,
						value: '34',
						value_comments: null,
						value_description:
							"'Intel(R) Xeon(R) CPU           X3440  @ 2.53GHz', 2534MHz, 8 Core, Sockets: 1",
						value_display_order: 17,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 10,
						price_delta: -80,
						price_total: 10,
						price_total_delta: -80,
						value: '37',
						value_comments: null,
						value_description:
							"'Intel(R) Xeon(R) CPU           X3440  @ 2.53GHz', 2534MHz, 4 Core, Sockets: 1",
						value_display_order: 24,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 1,
						price_delta: -89,
						price_total: 1,
						price_total_delta: -89,
						value: '38',
						value_comments: null,
						value_description:
							"'Intel(R) Xeon(R) CPU           X3440  @ 2.53GHz', 2534MHz, 4 Core, Sockets: 1",
						value_display_order: 12,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 100,
						price_delta: 10,
						price_total: 100,
						price_total_delta: 10,
						value: '189',
						value_comments: null,
						value_description:
							"'Intel(R) Xeon(R) CPU           E5620  @ 2.40GHz', 2400MHz, 4 Core, Sockets: 2",
						value_display_order: 36,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 50,
						price_delta: -40,
						price_total: 50,
						price_total_delta: -40,
						value: '40',
						value_comments: null,
						value_description:
							"'Intel(R) Xeon(R) CPU           X3440  @ 2.53GHz', 2534MHz, 4 Core, Sockets: 1",
						value_display_order: 29,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 10,
						price_delta: -80,
						price_total: 10,
						price_total_delta: -80,
						value: '23',
						value_comments: null,
						value_description:
							"'Intel(R) Xeon(R) CPU E3-1220 V2 @ 3.10GHz', 3100MHz, 4 Core, Sockets: 1",
						value_display_order: 21,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 50,
						price_delta: -40,
						price_total: 50,
						price_total_delta: -40,
						value: '159',
						value_comments: null,
						value_description:
							"'AMD Athlon(tm) 64 X2 Dual Core Processor 5000+', 2600MHz, 2 Core, Sockets: 1",
						value_display_order: 28,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 100,
						price_delta: 10,
						price_total: 100,
						price_total_delta: 10,
						value: '181',
						value_comments: null,
						value_description:
							"'Intel(R) Core(TM) i5 CPU         760  @ 2.80GHz', 2799MHz, 4 Core, Sockets: 1",
						value_display_order: 48,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 1,
						price_delta: -89,
						price_total: 1,
						price_total_delta: -89,
						value: '165',
						value_comments: null,
						value_description:
							'Bare-metal Test - 4gig ram, 500gig disk, 4 vcpu',
						value_display_order: 6,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 200,
						price_delta: 110,
						price_total: 200,
						price_total_delta: 110,
						value: '185',
						value_comments: null,
						value_description:
							"'Intel(R) Core(TM) i5 CPU         750  @ 2.67GHz', 2666MHz, 4 Core, Sockets: 1",
						value_display_order: 67,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 1,
						price_delta: -89,
						price_total: 1,
						price_total_delta: -89,
						value: '134',
						value_comments: null,
						value_description:
							"'Intel(R) Xeon(R) CPU E3-1270 V2 @ 3.50GHz', 3499MHz, 4 Core, Sockets: 1",
						value_display_order: 8,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 100,
						price_delta: 10,
						price_total: 100,
						price_total_delta: 10,
						value: '187',
						value_comments: null,
						value_description:
							"'Intel(R) Core(TM) i5 CPU         750  @ 2.67GHz', 2666MHz, 4 Core, Sockets: 1",
						value_display_order: 43,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 100,
						price_delta: 10,
						price_total: 100,
						price_total_delta: 10,
						value: '191',
						value_comments: null,
						value_description:
							"'Intel(R) Xeon(R) CPU           E5420  @ 2.50GHz', 2493MHz, 4 Core, Sockets: 2",
						value_display_order: 47,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 100,
						price_delta: 10,
						price_total: 100,
						price_total_delta: 10,
						value: '192',
						value_comments: null,
						value_description:
							"'Intel(R) Xeon(R) CPU E3-1220 V2 @ 3.10GHz', 3100MHz, 4 Core, Sockets: 1",
						value_display_order: 51,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 1,
						price_delta: -89,
						price_total: 1,
						price_total_delta: -89,
						value: '42',
						value_comments: null,
						value_description:
							"'Intel(R) Xeon(R) CPU           X3440  @ 2.53GHz', 2534MHz, 4 Core, Sockets: 1",
						value_display_order: 11,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 1,
						price_delta: -89,
						price_total: 1,
						price_total_delta: -89,
						value: '46',
						value_comments: null,
						value_description:
							"'Intel(R) Xeon(R) CPU E3-1220 V2 @ 3.10GHz', 3099MHz, 4 Core, Sockets: 1",
						value_display_order: 13,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 1,
						price_delta: -89,
						price_total: 1,
						price_total_delta: -89,
						value: '136',
						value_comments: null,
						value_description:
							"'Intel(R) Core(TM) i5 CPU         760  @ 2.80GHz', 2800MHz, 4 Core, Sockets: 1",
						value_display_order: 5,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 10,
						price_delta: -80,
						price_total: 10,
						price_total_delta: -80,
						value: '171',
						value_comments: null,
						value_description:
							"'Intel(R) Core(TM) i5 CPU         760  @ 2.80GHz', 2800MHz, 4 Core, Sockets: 1",
						value_display_order: 18,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 1,
						price_delta: -89,
						price_total: 1,
						price_total_delta: -89,
						value: '144',
						value_comments: null,
						value_description:
							"'Intel(R) Xeon(R) CPU E3-1270 V2 @ 3.50GHz', 3500MHz, 4 Core, Sockets: 1",
						value_display_order: 9,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 1,
						price_delta: -89,
						price_total: 1,
						price_total_delta: -89,
						value: '137',
						value_comments: null,
						value_description:
							"'Intel(R) Xeon(R) CPU E3-1220 V2 @ 3.10GHz', 3299MHz, 4 Core, Sockets: 1",
						value_display_order: 4,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 1,
						price_delta: -89,
						price_total: 1,
						price_total_delta: -89,
						value: '5',
						value_comments: null,
						value_description:
							"'AMD Phenom(tm) II X6 1055T Processor', 2800MHz, 6 Core, Sockets: 1",
						value_display_order: 10,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 22,
						price_delta: -68,
						price_total: 22,
						price_total_delta: -68,
						value: '7',
						value_comments: null,
						value_description:
							"'Intel(R) Xeon(R) CPU           E5620  @ 2.40GHz', 2400MHz, 4 Core, Sockets: 2",
						value_display_order: 27,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 150,
						price_delta: 60,
						price_total: 150,
						price_total_delta: 60,
						value: '6',
						value_comments: null,
						value_description:
							"'Intel(R) Xeon(R) CPU           E5606  @ 2.13GHz', 2133MHz, 4 Core, Sockets: 2",
						value_display_order: 62,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 0,
						price_delta: -90,
						price_total: 0,
						price_total_delta: -90,
						value: '150',
						value_comments: null,
						value_description:
							"'Intel(R) Xeon(R) CPU E3-1270 V2 @ 3.50GHz', 3500MHz, 4 Core, Sockets: 1",
						value_display_order: 1,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 750,
						price_delta: 660,
						price_total: 750,
						price_total_delta: 660,
						value: '49',
						value_comments: null,
						value_description:
							"'Intel(R) Xeon(R) CPU           E5506  @ 2.13GHz', 2133MHz, 4 Core, Sockets: 2",
						value_display_order: 76,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 9,
						price_delta: -81,
						price_total: 9,
						price_total_delta: -81,
						value: '50',
						value_comments: null,
						value_description:
							"'Intel(R) Xeon(R) CPU E3-1230 v5 @ 3.40GHz', 3599MHz, 4 Core, Sockets: 1",
						value_display_order: 16,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 115,
						price_delta: 25,
						price_total: 115,
						price_total_delta: 25,
						value: '9',
						value_comments: null,
						value_description:
							"'AMD FX(tm)-6100 Six-Core Processor', 3300MHz, 3 Core, Sockets: 2",
						value_display_order: 57,
					},
					{
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 125,
						price_delta: 35,
						price_total: 125,
						price_total_delta: 35,
						value: '10',
						value_comments: null,
						value_description:
							"'Intel(R) Xeon(R) CPU           E5506  @ 2.13GHz', 2133MHz, 4 Core, Sockets: 2",
						value_display_order: 59,
					},
				],
				parent_key: null,
				parent_value: null,
				price: '90.00000',
				price_total: '90.00000',
				tax: null,
				totals: {
					subtotal_cost: '90.00000',
					total_cost: '90.00000',
				},
				valid: 1,
				value: '100',
				value_comments: null,
				value_description:
					"'AMD Athlon(tm) 64 X2 Dual Core Processor 5000+', 2600MHz, 2 Core, Sockets: 1",
				value_display_order: 33,
				value_price_time_unit: 'month',
			},
		],
		created: '2021-05-05 06:06:27.659788-04',
		discount: '0.00000',
		productInfo: {
			alias: null,
			capabilities: {
				ATSLogin: 1,
				autoRename: 1,
				bandwidthStats: 0,
				canThreatStack: 1,
				credentials: 1,
				destroy: 1,
				emailable: 1,
				manAddIp: 0,
				manReboot: 0,
				manRemoveIp: 0,
				managed: 0,
				multiplePublicIPs: 0,
				networking: 0,
				presentable: 1,
				privateParentInstanceAdd: 1,
				privateParentInstanceList: 1,
				reverseDNSEdit: 0,
				serviceMonitoring: 0,
				serviceMonitoringBasic: 0,
				serviceMonitoringDns: 0,
				serviceMonitoringLinux: 0,
				serviceMonitoringMachineMysqld: 1,
				sonar: 0,
			},
			credit_eligible: 1,
			cycle: 'month',
			discountable: 1,
			product_code: 'SS.PP',
			product_description: 'Storm Private Parent',
			product_display_group: 'Storm PP',
			sub_title: '',
			tax_code: 'SW054000',
			title: '',
		},
		product_code: 'SS.PP',
		properties: {},
		region: 1,
		tax: null,
		totals: {
			configs_discount_total: '0.00000',
			configs_price_total: '90.00000',
			configs_subtotal: '90.00000',
			configs_tax_total: '0',
			configs_total_cost: '90.00000',
			item_discount_total: '0.00000',
			item_prepay_auto_renew: null,
			item_prepay_discount: null,
			item_prepay_once: null,
			item_prepay_ongoing: null,
			item_prepay_term_max: null,
			item_price_total: '90.00000',
			item_setup_fee: '0',
			item_subtotal: '90.00000',
			item_tax_total: '0',
			item_total_cost: '90.00000',
			subtotal_base_cost: '0.00000',
			total_base_cost: '0.00000',
		},
		uuid: '57d96751-bd05-4842-8d0d-54765f29139f',
		valid: 1,
	},
	'92ebf965-830f-40af-8b39-42845e57366f': {
		base_price: '0.00000',
		basket_uuid: 'd68aef1a-776c-48ea-bee8-7993eb552b78',
		configs: [
			{
				discount: '0.00000',
				extra_data: {
					active: 1,
					deprecated: 0,
					os: 'LinuxCentOS',
					os_license: null,
					support_level: 'Self-Managed',
					zone_availability: {
						'40460': 1,
						'45412': 1,
					},
				},
				is_extra: 0,
				key: 'Template',
				key_description: 'Server Type',
				key_display_order: 2,
				key_group: 'Misc. Options',
				key_group_display_order: 2147483647,
				num_units: null,
				options: [
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'LinuxFedora',
							os_license: null,
							support_level: 'Self-Managed',
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 0,
						price_delta: 0,
						price_total: 0,
						price_total_delta: 0,
						value: 'FEDORA_33_SERVER_UNMANAGED',
						value_comments: null,
						value_description: 'Fedora 33 Server 64-bit Self-managed',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							extra_software: 'Interworx',
							os: 'LinuxCloudLinux',
							os_license: null,
							support_level: 'Fully-Managed',
							zone_availability: {
								'40460': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 0,
						price_delta: 0,
						price_total: 0,
						price_total_delta: 0,
						value: 'CLOUDLINUX_7_INTERWORX',
						value_comments: null,
						value_description: 'CloudLinux 7 w/ Interworx',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							extra_software: 'cwp',
							os: 'LinuxCentOS',
							os_license: null,
							support_level: null,
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 0,
						price_delta: 0,
						price_total: 0,
						price_total_delta: 0,
						value: 'CENTOS_7_CWP',
						value_comments: null,
						value_description: 'CentOS 7 w/ CentOS Web Panel',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'LinuxUbuntu',
							os_license: null,
							support_level: 'Core-Managed',
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 1,
						price_delta: 1,
						price_total: 1,
						price_total_delta: 1,
						value: 'UBUNTU_1804_COREMANAGED',
						value_comments: null,
						value_description: 'Ubuntu 18.04 64-bit Core-managed',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'LinuxDebian',
							os_license: null,
							support_level: 'Self-Managed',
							zone_availability: {
								'1': 1,
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 1,
						price_delta: 1,
						price_total: 1,
						price_total_delta: 1,
						value: 'DEBIAN_8_UNMANAGED',
						value_comments: null,
						value_description: 'Debian 8 64-bit Self-managed',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'LinuxCentOS',
							os_license: null,
							support_level: 'Core-Managed',
							zone_availability: {
								'40460': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 0,
						price_delta: 0,
						price_total: 0,
						price_total_delta: 0,
						value: 'CENTOS_7_COREMANAGED',
						value_comments: 'THIS IS A TEST',
						value_description: 'CentOS 7.0 64-bit CoreManaged',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'LinuxAlmaLinux',
							os_license: null,
							support_level: 'Self-Managed',
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 0,
						price_delta: 0,
						price_total: 0,
						price_total_delta: 0,
						value: 'ALMALINUX_30_SERVER_UNMANAGED',
						value_comments: null,
						value_description: 'AlmaLinux 30 Server 64-bit Self-managed',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							extra_software: 'Plesk',
							os: 'LinuxCentOS',
							os_license: null,
							support_level: 'Fully-Managed',
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 1,
						price_delta: 1,
						price_total: 1,
						price_total_delta: 1,
						value: 'CENTOS_7_PLESK',
						value_comments: null,
						value_description: 'CentOS 7 64-bit Plesk',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'LinuxUbuntu',
							os_license: null,
							support_level: 'Self-Managed',
							zone_availability: {
								'1': 1,
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 0,
						price_delta: 0,
						price_total: 0,
						price_total_delta: 0,
						value: 'UBUNTU_1604_UNMANAGED',
						value_comments: null,
						value_description: 'Ubuntu 16.04 LTS 64-bit Self-Managed',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'LinuxUbuntu',
							os_license: null,
							support_level: 'Self-Managed',
							zone_availability: {
								'1': 1,
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 0,
						price_delta: 0,
						price_total: 0,
						price_total_delta: 0,
						value: 'UBUNTU_1804_UNMANAGED',
						value_comments: null,
						value_description: 'Ubuntu 18.04 LTS 64-bit Self-Managed',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							extra_software: 'Cpanel',
							os: 'LinuxCentOS',
							os_license: null,
							support_level: 'Fully-Managed',
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 15,
						price_delta: 15,
						price_total: 15,
						price_total_delta: 15,
						value: 'CENTOS_7_CPANEL',
						value_comments: null,
						value_description: 'CentOS 7 64-bit with cPanel Fully-managed',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							extra_software: 'Cpanel',
							os: 'LinuxCloudLinux',
							os_license: null,
							support_level: 'Fully-Managed',
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 15,
						price_delta: 15,
						price_total: 15,
						price_total_delta: 15,
						value: 'CENTOS_7_CPANEL_CLOUDLINUX',
						value_comments: null,
						value_description: 'CentOS 7 64-bit cPanel w/CloudLinux',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'LinuxAlpine',
							os_license: null,
							support_level: 'Self-Managed',
							zone_availability: {
								'40460': 1,
								'86172': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 0,
						price_delta: 0,
						price_total: 0,
						price_total_delta: 0,
						value: 'ALPINE_LINUX',
						value_comments: null,
						value_description: 'Alpine Linux 64-bit Self-managed',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							extra_software: 'Cpanel',
							os: 'LinuxCentOS',
							os_license: null,
							support_level: 'Fully-Managed',
							zone_availability: {
								'1': 1,
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 15,
						price_delta: 15,
						price_total: 15,
						price_total_delta: 15,
						value: 'CENTOS_8_CPANEL',
						value_comments: null,
						value_description: 'CentOS 8 64-bit with cPanel Fully-managed',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'LinuxUbuntu',
							os_license: null,
							support_level: 'Core-Managed',
							zone_availability: {
								'40460': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 10,
						price_delta: 10,
						price_total: 10,
						price_total_delta: 10,
						value: 'UBUNTU_1604_COREMANAGED',
						value_comments: null,
						value_description: 'Ubuntu 16.04 64-bit Core-managed',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'LinuxUbuntu',
							os_license: null,
							support_level: 'Self-Managed',
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 0,
						price_delta: 0,
						price_total: 0,
						price_total_delta: 0,
						value: 'UBUNTU_2004_UNMANAGED',
						value_comments: null,
						value_description: 'Ubuntu 20.04 LTS 64-bit Self-Managed',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'LinuxDebian',
							os_license: null,
							support_level: 'Self-Managed',
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 0,
						price_delta: 0,
						price_total: 0,
						price_total_delta: 0,
						value: 'DEBIAN_9_UNMANAGED',
						value_comments: null,
						value_description: 'Debian 9 64-bit Self-managed',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							extra_software: 'Interworx',
							os: 'LinuxCentOS',
							os_license: null,
							support_level: 'Fully-Managed',
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 5,
						price_delta: 5,
						price_total: 5,
						price_total_delta: 5,
						value: 'CENTOS_7_INTERWORX',
						value_comments: null,
						value_description: 'CentOS 7 w/ Interworx',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'LinuxUbuntu',
							os_license: null,
							support_level: 'Self-Managed',
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 0,
						price_delta: 0,
						price_total: 0,
						price_total_delta: 0,
						value: 'UBUNTU_2004_DJANGO',
						value_comments: null,
						value_description: 'Ubuntu 20.04 LTS 64-bit Django',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'LinuxCentOS',
							os_license: null,
							support_level: 'Self-Managed',
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 1,
						price_delta: 1,
						price_total: 1,
						price_total_delta: 1,
						value: 'CENTOS_8_UNMANAGED',
						value_comments: null,
						value_description: 'CentOS 8 64-bit Self-managed',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'LinuxDebian',
							os_license: null,
							support_level: 'Self-Managed',
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 0,
						price_delta: 0,
						price_total: 0,
						price_total_delta: 0,
						value: 'DEBIAN_10_UNMANAGED',
						value_comments: null,
						value_description: 'Debian 10 64-bit Self-managed',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'LinuxFedora',
							os_license: null,
							support_level: 'Self-Managed',
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 0,
						price_delta: 0,
						price_total: 0,
						price_total_delta: 0,
						value: 'FEDORA_32_SERVER_UNMANAGED',
						value_comments: null,
						value_description: 'Fedora 32 Server 64-bit Self-managed',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'LinuxCentOS',
							os_license: null,
							support_level: 'Self-Managed',
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 0,
						price_delta: 0,
						price_total: 0,
						price_total_delta: 0,
						value: 'CENTOS_STREAM_UNMANAGED',
						value_comments: null,
						value_description: 'CentOS Stream 64-bit Self-managed',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'LinuxFedora',
							os_license: null,
							support_level: 'Self-Managed',
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 0,
						price_delta: 0,
						price_total: 0,
						price_total_delta: 0,
						value: 'FEDORA_31_SERVER_UNMANAGED',
						value_comments: null,
						value_description: 'Fedora 31 Server 64-bit Self-managed',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'LinuxCentOS',
							os_license: null,
							support_level: 'Self-Managed',
							zone_availability: {
								'1': 1,
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 0,
						price_delta: 0,
						price_total: 0,
						price_total_delta: 0,
						value: 'ALMALINUX_8_UNMANAGED',
						value_comments: null,
						value_description: 'AlmaLinux 8 64-bit Self-managed',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'LinuxCentOS',
							os_license: null,
							support_level: 'Core-Managed',
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 0,
						price_delta: 0,
						price_total: 0,
						price_total_delta: 0,
						value: 'CENTOS_8_COREMANAGED',
						value_comments: null,
						value_description: 'CentOS 8 64-bit CoreManaged',
						value_display_order: 0,
					},
				],
				parent_key: null,
				parent_value: null,
				price: '0.00000',
				price_total: '10.00000',
				tax: null,
				totals: {
					subtotal_cost: '0.00000',
					total_cost: '0.00000',
				},
				valid: 1,
				value: 'CENTOS_7_UNMANAGED',
				value_comments: null,
				value_description: 'CentOS 7 64-bit Self-managed',
				value_display_order: 0,
				value_price_time_unit: 'month',
			},
			{
				discount: '0.00000',
				is_extra: 0,
				key: 'LiquidWebBackupPlan',
				key_description: 'Backup Plan',
				key_display_order: 5,
				key_group: 'Misc. Options',
				key_group_display_order: 2147483647,
				num_units: null,
				options: [
					{
						is_extra: 0,
						key: 'LiquidWebBackupPlan',
						key_description: 'Backup Plan',
						key_display_order: 5,
						num_units: null,
						price: 0,
						price_delta: 0,
						price_total: 0,
						price_total_delta: 0,
						value: 'None',
						value_comments: null,
						value_description: 'No Backups',
						value_display_order: 1,
					},
					{
						is_extra: 0,
						key: 'LiquidWebBackupPlan',
						key_description: 'Backup Plan',
						key_display_order: 5,
						num_units: null,
						price: 0,
						price_delta: 0,
						price_total: 0,
						price_total_delta: 0,
						value: 'Daily',
						value_comments: null,
						value_description: 'As-you-go Backups',
						value_display_order: 2,
					},
				],
				parent_key: null,
				parent_value: null,
				price: '0.00000',
				price_total: '0.00000',
				tax: null,
				totals: {
					subtotal_cost: '0.00000',
					total_cost: '0.00000',
				},
				valid: 1,
				value: 'Quota',
				value_comments: null,
				value_description: 'Quota-based Backups',
				value_display_order: 3,
				value_price_time_unit: 'month',
			},
			{
				discount: '0.00000',
				is_extra: 0,
				key: 'BandwidthOverage',
				key_description: 'Bandwidth Overage',
				key_display_order: 4,
				key_group: 'Misc. Options',
				key_group_display_order: 2147483647,
				num_units: -1,
				options: [],
				parent_key: null,
				parent_value: null,
				price: '0.25000',
				price_total: '0.00000',
				tax: null,
				totals: {
					subtotal_cost: '0.00000',
					total_cost: '0.00000',
				},
				valid: 1,
				value: '1GB',
				value_comments: null,
				value_description: '%{units} GB Overage',
				value_display_order: 1,
				value_price_time_unit: 'nontemporal',
			},
			{
				discount: '0.00000',
				is_extra: 0,
				key: 'ExtraIp',
				key_description: 'Extra IP Addresses',
				key_display_order: 6,
				key_group: 'Network Options',
				key_group_display_order: 4,
				num_units: 1,
				options: [],
				parent_key: null,
				parent_value: null,
				price: '1.99000',
				price_total: '1.99000',
				tax: null,
				totals: {
					subtotal_cost: '0.00000',
					total_cost: '0.00000',
				},
				valid: 1,
				value: '1',
				value_comments: null,
				value_description: '%{units} Additional Public IPs',
				value_display_order: 1,
				value_price_time_unit: 'month',
			},
			{
				discount: '0.00000',
				is_extra: 0,
				key: 'BackupQuota',
				key_description: 'Quota Pricing',
				key_display_order: 1,
				key_group: 'Misc. Options',
				key_group_display_order: 2147483647,
				num_units: null,
				options: [
					{
						is_extra: 0,
						key: 'BackupQuota',
						key_description: 'Quota Pricing',
						key_display_order: 1,
						num_units: null,
						parent_key: 'LiquidWebBackupPlan',
						parent_value: 'Quota',
						price: 23,
						price_delta: 13,
						price_total: 23,
						price_total_delta: 13,
						value: '250',
						value_comments: null,
						value_description: '250 GB',
						value_display_order: 10,
					},
					{
						is_extra: 0,
						key: 'BackupQuota',
						key_description: 'Quota Pricing',
						key_display_order: 1,
						num_units: null,
						parent_key: 'LiquidWebBackupPlan',
						parent_value: 'Quota',
						price: 45,
						price_delta: 35,
						price_total: 45,
						price_total_delta: 35,
						value: '500',
						value_comments: null,
						value_description: '500 GB',
						value_display_order: 15,
					},
					{
						is_extra: 0,
						key: 'BackupQuota',
						key_description: 'Quota Pricing',
						key_display_order: 1,
						num_units: null,
						parent_key: 'LiquidWebBackupPlan',
						parent_value: 'Quota',
						price: 70,
						price_delta: 60,
						price_total: 70,
						price_total_delta: 60,
						value: '1000',
						value_comments: null,
						value_description: '1000 GB',
						value_display_order: 20,
					},
					{
						is_extra: 0,
						key: 'BackupQuota',
						key_description: 'Quota Pricing',
						key_display_order: 1,
						num_units: null,
						parent_key: 'LiquidWebBackupPlan',
						parent_value: 'Quota',
						price: 130,
						price_delta: 120,
						price_total: 130,
						price_total_delta: 120,
						value: '2000',
						value_comments: null,
						value_description: '2000 GB',
						value_display_order: 25,
					},
					{
						is_extra: 0,
						key: 'BackupQuota',
						key_description: 'Quota Pricing',
						key_display_order: 1,
						num_units: null,
						parent_key: 'LiquidWebBackupPlan',
						parent_value: 'Quota',
						price: 0,
						price_delta: -10,
						price_total: 0,
						price_total_delta: -10,
						value: '50',
						value_comments: null,
						value_description: '50 GB',
						value_display_order: 1,
					},
				],
				parent_key: 'LiquidWebBackupPlan',
				parent_value: 'Quota',
				price: '10.00000',
				price_total: '10.00000',
				tax: null,
				totals: {
					subtotal_cost: '10.00000',
					total_cost: '10.00000',
				},
				valid: 1,
				value: '100',
				value_comments: null,
				value_description: '100 GB',
				value_display_order: 5,
				value_price_time_unit: 'month',
			},
			{
				discount: '0.00000',
				is_extra: 0,
				key: 'Bandwidth',
				key_description: 'Bandwidth',
				key_display_order: 3,
				key_group: 'Network Options',
				key_group_display_order: 4,
				num_units: null,
				options: [
					{
						is_extra: 0,
						key: 'Bandwidth',
						key_description: 'Bandwidth',
						key_display_order: 3,
						num_units: null,
						price: 50,
						price_delta: 50,
						price_total: 50,
						price_total_delta: 50,
						value: 'SS.15000',
						value_comments: null,
						value_description: '15 TB Monthly Transfer',
						value_display_order: 4,
					},
					{
						is_extra: 0,
						key: 'Bandwidth',
						key_description: 'Bandwidth',
						key_display_order: 3,
						num_units: null,
						price: 100,
						price_delta: 100,
						price_total: 100,
						price_total_delta: 100,
						value: 'SS.20000',
						value_comments: null,
						value_description: '20 TB Monthly Transfer',
						value_display_order: 5,
					},
				],
				parent_key: null,
				parent_value: null,
				price: '0.00000',
				price_total: '50.00000',
				tax: null,
				totals: {
					subtotal_cost: '0.00000',
					total_cost: '0.00000',
				},
				valid: 1,
				value: 'SS.10000',
				value_comments: null,
				value_description: '10 TB Monthly Transfer',
				value_display_order: 3,
				value_price_time_unit: 'static-month',
			},
			{
				discount: '0.00000',
				is_extra: 0,
				key: 'NetworkStorage',
				key_description: 'Network Storage',
				key_display_order: null,
				key_group: 'Misc. Options',
				key_group_display_order: 2147483647,
				num_units: null,
				options: [
					{
						is_extra: 0,
						key: 'NetworkStorage',
						key_description: 'Network Storage',
						key_display_order: null,
						num_units: null,
						price: 1,
						price_delta: 1,
						price_total: 1,
						price_total_delta: 1,
						value: '10gb',
						value_comments: null,
						value_description: '10GB',
						value_display_order: 5,
					},
					{
						is_extra: 0,
						key: 'NetworkStorage',
						key_description: 'Network Storage',
						key_display_order: null,
						num_units: null,
						price: 2,
						price_delta: 2,
						price_total: 2,
						price_total_delta: 2,
						value: '20gb',
						value_comments: null,
						value_description: '20GB',
						value_display_order: 10,
					},
				],
				parent_key: null,
				parent_value: null,
				price: '0.00000',
				price_total: '0.90000',
				tax: null,
				totals: {
					subtotal_cost: '0.00000',
					total_cost: '0.00000',
				},
				valid: 1,
				value: 'no_storage',
				value_comments: null,
				value_description: 'None',
				value_display_order: 0,
				value_price_time_unit: 'month',
			},
			{
				discount: '0.00000',
				is_extra: 1,
				key: 'cPanelLicenseTier',
				key_description: 'cPanel License Tier',
				key_display_order: 0,
				key_group: 'Misc. Options',
				key_group_display_order: 2147483647,
				num_units: null,
				options: [
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 0,
						price_delta: 0,
						price_total: 0,
						price_total_delta: 0,
						value: 'admin_cloud_5',
						value_comments: null,
						value_description: 'Admin',
						value_display_order: 15,
					},
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 10,
						price_delta: 10,
						price_total: 10,
						price_total_delta: 10,
						value: 'plus_cloud_50',
						value_comments: null,
						value_description: 'Plus',
						value_display_order: 25,
					},
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 20,
						price_delta: 20,
						price_total: 20,
						price_total_delta: 20,
						value: 'premier_cloud_100',
						value_comments: null,
						value_description: 'Premier Fixed 100',
						value_display_order: 35,
					},
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 125,
						price_delta: 125,
						price_total: 125,
						price_total_delta: 125,
						value: 'premier_cloud_1000',
						value_comments: null,
						value_description: 'Premier Fixed 1000',
						value_display_order: 140,
					},
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 25,
						price_delta: 25,
						price_total: 25,
						price_total_delta: 25,
						value: 'premier_cloud_150',
						value_comments: null,
						value_description: 'Premier Fixed 150',
						value_display_order: 40,
					},
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 185,
						price_delta: 185,
						price_total: 185,
						price_total_delta: 185,
						value: 'premier_cloud_1500',
						value_comments: null,
						value_description: 'Premier Fixed 1500',
						value_display_order: 200,
					},
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 30,
						price_delta: 30,
						price_total: 30,
						price_total_delta: 30,
						value: 'premier_cloud_200',
						value_comments: null,
						value_description: 'Premier Fixed 200',
						value_display_order: 45,
					},
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 35,
						price_delta: 35,
						price_total: 35,
						price_total_delta: 35,
						value: 'premier_cloud_250',
						value_comments: null,
						value_description: 'Premier Fixed 250',
						value_display_order: 50,
					},
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 40,
						price_delta: 40,
						price_total: 40,
						price_total_delta: 40,
						value: 'premier_cloud_300',
						value_comments: null,
						value_description: 'Premier Fixed 300',
						value_display_order: 55,
					},
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 45,
						price_delta: 45,
						price_total: 45,
						price_total_delta: 45,
						value: 'premier_cloud_350',
						value_comments: null,
						value_description: 'Premier Fixed 350',
						value_display_order: 60,
					},
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 55,
						price_delta: 55,
						price_total: 55,
						price_total_delta: 55,
						value: 'premier_cloud_400',
						value_comments: null,
						value_description: 'Premier Fixed 400',
						value_display_order: 70,
					},
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 65,
						price_delta: 65,
						price_total: 65,
						price_total_delta: 65,
						value: 'premier_cloud_450',
						value_comments: null,
						value_description: 'Premier Fixed 450',
						value_display_order: 80,
					},
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 75,
						price_delta: 75,
						price_total: 75,
						price_total_delta: 75,
						value: 'premier_cloud_500',
						value_comments: null,
						value_description: 'Premier Fixed 500',
						value_display_order: 90,
					},
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 85,
						price_delta: 85,
						price_total: 85,
						price_total_delta: 85,
						value: 'premier_cloud_600',
						value_comments: null,
						value_description: 'Premier Fixed 600',
						value_display_order: 100,
					},
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 95,
						price_delta: 95,
						price_total: 95,
						price_total_delta: 95,
						value: 'premier_cloud_700',
						value_comments: null,
						value_description: 'Premier Fixed 700',
						value_display_order: 110,
					},
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 105,
						price_delta: 105,
						price_total: 105,
						price_total_delta: 105,
						value: 'premier_cloud_800',
						value_comments: null,
						value_description: 'Premier Fixed 800',
						value_display_order: 120,
					},
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 115,
						price_delta: 115,
						price_total: 115,
						price_total_delta: 115,
						value: 'premier_cloud_900',
						value_comments: null,
						value_description: 'Premier Fixed 900',
						value_display_order: 130,
					},
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 5,
						price_delta: 5,
						price_total: 5,
						price_total_delta: 5,
						value: 'pro_cloud_30',
						value_comments: null,
						value_description: 'Pro',
						value_display_order: 20,
					},
				],
				parent_key: null,
				parent_value: null,
				price: '0.00000',
				price_total: '0.00000',
				tax: null,
				totals: {
					subtotal_cost: '0.00000',
					total_cost: '0.00000',
				},
				valid: 1,
				value: 'none',
				value_comments: null,
				value_description: 'None',
				value_display_order: 0,
				value_price_time_unit: 'month',
			},
			{
				discount: '0.00000',
				is_extra: 0,
				key: 'Acronis',
				key_description: 'Acronis Cyber Backup',
				key_display_order: 0,
				key_group: 'Storage and Backups',
				key_group_display_order: 2,
				num_units: null,
				options: [
					{
						is_extra: 0,
						key: 'Acronis',
						key_description: 'Acronis Cyber Backup',
						key_display_order: 0,
						num_units: null,
						price: 40,
						price_delta: 40,
						price_total: 40,
						price_total_delta: 40,
						value: 'Acronis_AC250',
						value_comments: null,
						value_description: 'Acronis Cloud - 250GB Storage',
						value_display_order: 45,
					},
					{
						is_extra: 0,
						key: 'Acronis',
						key_description: 'Acronis Cyber Backup',
						key_display_order: 0,
						num_units: null,
						price: 70,
						price_delta: 70,
						price_total: 70,
						price_total_delta: 70,
						value: 'Acronis_AC500',
						value_comments: null,
						value_description: 'Acronis Cloud - 500GB Storage',
						value_display_order: 50,
					},
					{
						is_extra: 0,
						key: 'Acronis',
						key_description: 'Acronis Cyber Backup',
						key_display_order: 0,
						num_units: null,
						price: 130,
						price_delta: 130,
						price_total: 130,
						price_total_delta: 130,
						value: 'Acronis_AC1001',
						value_comments: null,
						value_description: 'Acronis Cloud - 1TB Storage',
						value_display_order: 55,
					},
					{
						is_extra: 0,
						key: 'Acronis',
						key_description: 'Acronis Cyber Backup',
						key_display_order: 0,
						num_units: null,
						price: 175,
						price_delta: 175,
						price_total: 175,
						price_total_delta: 175,
						value: 'Acronis_AC1500',
						value_comments: null,
						value_description: 'Acronis Cloud - 1.5TB Storage',
						value_display_order: 60,
					},
					{
						is_extra: 0,
						key: 'Acronis',
						key_description: 'Acronis Cyber Backup',
						key_display_order: 0,
						num_units: null,
						price: 285,
						price_delta: 285,
						price_total: 285,
						price_total_delta: 285,
						value: 'Acronis_AC2000',
						value_comments: null,
						value_description: 'Acronis Cloud - 2TB Storage',
						value_display_order: 65,
					},
					{
						is_extra: 0,
						key: 'Acronis',
						key_description: 'Acronis Cyber Backup',
						key_display_order: 0,
						num_units: null,
						price: 285,
						price_delta: 285,
						price_total: 285,
						price_total_delta: 285,
						value: 'Acronis_AC2500',
						value_comments: null,
						value_description: 'Acronis Cloud - 2.5TB Storage',
						value_display_order: 70,
					},
					{
						is_extra: 0,
						key: 'Acronis',
						key_description: 'Acronis Cyber Backup',
						key_display_order: 0,
						num_units: null,
						price: 310,
						price_delta: 310,
						price_total: 310,
						price_total_delta: 310,
						value: 'Acronis_AC3000',
						value_comments: null,
						value_description: 'Acronis Cloud - 3TB Storage',
						value_display_order: 75,
					},
					{
						is_extra: 0,
						key: 'Acronis',
						key_description: 'Acronis Cyber Backup',
						key_display_order: 0,
						num_units: null,
						price: 510,
						price_delta: 510,
						price_total: 510,
						price_total_delta: 510,
						value: 'Acronis_AC5000',
						value_comments: null,
						value_description: 'Acronis Cloud - 5TB Storage',
						value_display_order: 80,
					},
				],
				parent_key: null,
				parent_value: null,
				price: '0.130000',
				price_total: '0.11000',
				tax: null,
				totals: {
					subtotal_cost: '0.00000',
					total_cost: '0.00000',
				},
				valid: 1,
				value: 'Acronis_AC249',
				value_comments: null,
				value_description: 'Acronis Cloud - 249GB Storage',
				value_display_order: 0,
				value_price_time_unit: 'month',
			},
			{
				discount: '0.00000',
				extra_data: {
					category: 'ssd',
					config_id: '4',
					cores: 1,
					is_baremetal: 0,
					memory: 440,
					price: 1,
					product_code: 'SS.VPS',
					storage_size: 25,
				},
				is_extra: 0,
				key: 'ConfigId',
				key_description: 'Server Type',
				key_display_order: 1,
				key_group: 'Misc. Options',
				key_group_display_order: 2147483647,
				num_units: null,
				options: [
					{
						extra_data: {
							category: 'ssd',
							config_id: '164',
							cores: 2,
							is_baremetal: 0,
							memory: 1024,
							price: 12,
							product_code: 'SS.VPS',
							storage_size: 30,
						},
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 12,
						price_delta: 11,
						price_total: 12,
						price_total_delta: 11,
						value: '164',
						value_comments: null,
						value_description: '1GB',
						value_display_order: 19,
					},
					{
						extra_data: {
							category: 'ssd',
							config_id: '1',
							cores: 2,
							is_baremetal: 0,
							memory: 1700,
							price: 1,
							product_code: 'SS.VPS',
							storage_size: 100,
						},
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 1,
						price_delta: 0,
						price_total: 1,
						price_total_delta: 0,
						value: '1',
						value_comments: null,
						value_description: '2GB',
						value_display_order: 6,
					},
					{
						extra_data: {
							category: 'ssd',
							config_id: '164',
							cores: 4,
							is_baremetal: 0,
							memory: 1024,
							price: 12,
							product_code: 'SS.VPS',
							storage_size: 30,
						},
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 12,
						price_delta: 11,
						price_total: 12,
						price_total_delta: 11,
						value: '165',
						value_comments: null,
						value_description: '4GB',
						value_display_order: 19,
					},
					{
						extra_data: {
							category: 'ssd',
							config_id: '1',
							cores: 8,
							is_baremetal: 0,
							memory: 1700,
							price: 1,
							product_code: 'SS.VPS',
							storage_size: 100,
						},
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 1,
						price_delta: 0,
						price_total: 1,
						price_total_delta: 0,
						value: '166',
						value_comments: null,
						value_description: '8GB',
						value_display_order: 6,
					},
					{
						extra_data: {
							category: 'ssd',
							config_id: '1',
							cores: 16,
							is_baremetal: 0,
							memory: 1700,
							price: 1,
							product_code: 'SS.VPS',
							storage_size: 100,
						},
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 1,
						price_delta: 0,
						price_total: 1,
						price_total_delta: 0,
						value: '167',
						value_comments: null,
						value_description: '16GB',
						value_display_order: 6,
					},
				],
				parent_key: null,
				parent_value: null,
				price: '1.00000',
				price_total: '1.00000',
				tax: null,
				totals: {
					subtotal_cost: '1.00000',
					total_cost: '1.00000',
				},
				valid: 1,
				value: '4',
				value_comments: null,
				value_description: 'VPS 2000 Plus',
				value_display_order: 7,
				value_price_time_unit: 'month',
			},
		],
		created: '2021-05-12 14:06:40.886299-04',
		discount: '0.00000',
		extra_data: {
			available_backups: [
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4230,
					name: null,
					size: '1.94',
					template: 'FEDORA_33_SERVER_UNMANAGED',
					time_taken: '2021-04-24 00:02:52',
					uniq_id: '78554A',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4237,
					name: null,
					size: '1.94',
					template: 'CLOUDLINUX_7_INTERWORX',
					time_taken: '2021-04-25 00:02:54',
					uniq_id: '78554A',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4243,
					name: null,
					size: '1.92',
					template: 'CENTOS_7_CWP',
					time_taken: '2021-04-26 00:02:40',
					uniq_id: '78554A',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4251,
					name: null,
					size: '1.93',
					template: 'UBUNTU_1804_COREMANAGED',
					time_taken: '2021-04-27 00:02:54',
					uniq_id: '78554A',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4258,
					name: null,
					size: '1.94',
					template: 'DEBIAN_8_UNMANAGED',
					time_taken: '2021-04-28 00:02:59',
					uniq_id: '78554A',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4266,
					name: null,
					size: '1.95',
					template: 'CENTOS_7_UNMANAGED',
					time_taken: '2021-04-29 00:21:26',
					uniq_id: '78554A',
				},
			],
			available_images: [
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: '1',
					template: 'FEDORA_33_SERVER_UNMANAGED',
					name: 'test image 2',
					size: '1.46',
					source_hostname: 'host.backupsandimagestest.com',
					source_uniq_id: '78554A',
					template_description: 'CentOS 7 64-bit Self-managed',
					time_taken: '2020-01-14 16:25:06',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: '2',
					template: 'CLOUDLINUX_7_INTERWORX',
					name: 'image2',
					size: '7.13',
					source_hostname: 'paypergigbackups.testing.com',
					source_uniq_id: '',
					template_description: 'CentOS 7 64-bit cPanel w/CloudLinux',
					time_taken: '2020-01-16 16:53:47',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: '4',
					template: 'CENTOS_7_CWP',
					name: 'testing a rename',
					size: '1.50',
					source_hostname: 'host.backupsandimagestest.com',
					source_uniq_id: '78554A',
					template_description: 'CentOS 7 64-bit Self-managed',
					time_taken: '2020-01-20 11:45:59',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: '10',
					template: 'UBUNTU_1804_COREMANAGED',
					name: 'givemeaname',
					size: '1.50',
					source_hostname: 'host.backupsandimagestest.com',
					source_uniq_id: '78554A',
					template_description: 'CentOS 7 64-bit Self-managed',
					time_taken: '2020-01-21 17:43:39',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: '11',
					template: 'DEBIAN_8_UNMANAGED',
					name: 'create image name',
					size: '1.50',
					source_hostname: 'host.backupsandimagestest.com',
					source_uniq_id: '78554A',
					template_description: 'CentOS 7 64-bit Self-managed',
					time_taken: '2020-01-21 18:01:32',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: '13',
					template: 'CENTOS_7_COREMANAGED',
					name: 'new image',
					size: '1.51',
					source_hostname: 'host.backupsandimagestest.com',
					source_uniq_id: '78554A',
					template_description: 'CentOS 7 64-bit Self-managed',
					time_taken: '2020-01-23 14:08:27',
				},
			],
			available_parents: [
				{
					accnt: 3378300,
					bucket_uniq_id: null,
					config_description:
						"'Intel(R) Xeon(R) CPU           E5620  @ 2.40GHz', 2400MHz, 4 Core, Sockets: 2",
					config_id: 7,
					create_date: '2020-01-28 12:01:35',
					diskDetails: { allocated: 195, snapshots: 93 },
					domain: 'privateparent',
					id: '12424845',
					license_state: 'mixed',
					project_name: null,
					region_id: 1,
					resources: {
						diskspace: { free: 1561, total: 1849, used: 288 },
						memory: { free: 25717, total: 32229, used: 6512 },
					},
					salesforce_asset: '02i2F000003L6NwQAK',
					status: 'Active',
					subaccnt: '12424845',
					type: 'SS.PP',
					uniq_id: 'Z8064R',
					vcpu: 16,
					zone: {
						availability_zone: 'C',
						description: 'Zone C',
						hv_type: 'kvm',
						id: 40460,
						legacy: 0,
						name: 'b3s7z1',
						region: { id: 1, name: 'US Central' },
						status: 'open',
						valid_source_hvs: ['xen', 'kvm'],
					},
				},
			],
			ip_pool: {
				subaccnt: 'E6WU20',
				unused_assignments: [
					{
						begin_range: '67.43.15.12',
						broadcast: '67.43.15.63',
						end_range: '67.43.15.12',
						gateway: '67.43.15.1',
						id: 4807057,
						ip: '67.43.15.12',
						is_legacy_storm: 1,
						is_storm_anchor: 0,
						netmask: '255.255.255.192',
						network: '67.43.15.12/32',
						zone_id: 40460,
					},
				],
			},
		},
		productInfo: {
			alias: 'storm-vps',
			capabilities: {
				ATSLogin: 1,
				AutoActivate: 1,
				autoAddIp: 1,
				autoPrivateNetworking: 1,
				autoReboot: 1,
				autoRemoveIp: 1,
				autoRename: 1,
				autoShutdown: 1,
				bandwidthStats: 1,
				canAcronisBackup: 1,
				canHotplugRamVcpu: 1,
				canThreatStack: 1,
				canUseSpiceConsole: 1,
				changeBWOptions: 1,
				clone: 1,
				create: 1,
				credentials: 1,
				destroy: 1,
				emailable: 1,
				extraAuth: 1,
				guardian: 1,
				image: 1,
				manAddIp: 1,
				manReboot: 1,
				manRemoveIp: 1,
				managed: 1,
				migratableDestination: 1,
				migratableSource: 1,
				multiplePublicIPs: 1,
				networking: 1,
				presentable: 1,
				provBackup: 1,
				provFirewall: 1,
				reimage: 1,
				rescueBoot: 1,
				resize: 1,
				restoreFromTemplate: 1,
				reverseDNSEdit: 1,
				rootLogin: 1,
				serviceMonitoring: 1,
				serviceMonitoringBasic: 1,
				serviceMonitoringDns: 1,
				serviceMonitoringLinux: 1,
				serviceMonitoringMachineMysqld: 1,
				serviceMonitoringSystem: 1,
				sonar: 1,
				volumeAttach: 1,
				volumeDetach: 1,
				webConsole: 1,
			},
			credit_eligible: 1,
			cycle: 'month',
			discountable: 1,
			product_code: 'SS.VPS',
			product_description: 'LiquidWeb Storm VPS',
			product_display_group: 'Storm VPS',
			sub_title: '',
			tax_code: 'SW050400',
			title: '',
		},
		product_code: 'SS.VPS',
		properties: {
			account_default_region: 1,
			account_default_zone: '45412',
			zone: '40460',
			create_from: { type: 'backup', id: 4266 },
			cpu: 1,
			disk: 15,
			memory: 512,
			parent: 'Z8064R',
		},
		region: 1,
		tax: null,
		totals: {
			configs_discount_total: '0.00000',
			configs_price_total: '33.00000',
			configs_subtotal: '33.00000',
			configs_tax_total: '0',
			configs_total_cost: '33.00000',
			item_discount_total: '0.00000',
			item_prepay_auto_renew: null,
			item_prepay_discount: null,
			item_prepay_once: null,
			item_prepay_ongoing: null,
			item_prepay_term_max: null,
			item_price_total: '33.00000',
			item_setup_fee: '0',
			item_subtotal: '33.00000',
			item_tax_total: '0',
			item_total_cost: '33.00000',
			subtotal_base_cost: '0.00000',
			total_base_cost: '0.00000',
		},
		uuid: '92ebf965-830f-40af-8b39-42845e57366f',
		valid: 1,
	},
	'99473347-1a21-4fdd-8b65-09a3a23b3e86': {
		base_price: '0.00000',
		basket_uuid: 'd68aef1a-776c-48ea-bee8-7993eb552b78',
		configs: [
			{
				discount: '0.00000',
				extra_data: {
					active: 1,
					deprecated: 0,
					os: 'LinuxCentOS',
					os_license: null,
					support_level: 'Self-Managed',
					zone_availability: {
						'40460': 1,
						'45412': 1,
					},
				},
				is_extra: 0,
				key: 'Template',
				key_description: 'Server Type',
				key_display_order: 2,
				key_group: 'Misc. Options',
				key_group_display_order: 2147483647,
				num_units: null,
				options: [
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'LinuxUbuntu',
							os_license: null,
							support_level: 'Self-Managed',
							zone_availability: {
								'1': 1,
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 0,
						price_delta: 0,
						price_total: 0,
						price_total_delta: 0,
						value: 'UBUNTU_1604_UNMANAGED',
						value_comments: null,
						value_description: 'Ubuntu 16.04 LTS 64-bit Self-Managed',
						value_display_order: 1,
						tags: ['Not Popular'],
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'Windows',
							os_license: null,
							support_level: 'Core-Managed',
							zone_availability: {
								'1': 1,
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 10,
						price_delta: 1,
						price_total: 1,
						price_total_delta: 1,
						value: 'WINDOWS_2012R2_COREMANAGED',
						value_comments: null,
						value_description:
							'Windows Server 2012 R2 64-bit Standard Edition Core-Managed',
						value_display_order: 2,
						tags: ['Popular'],
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'LinuxUbuntu',
							os_license: null,
							support_level: 'Self-Managed',
							zone_availability: {
								'1': 1,
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 100,
						price_delta: 0,
						price_total: 0,
						price_total_delta: 0,
						value: 'UBUNTU_1804_UNMANAGED',
						value_comments: null,
						value_description: 'Ubuntu pricey Self-Managed',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'LinuxFedora',
							os_license: null,
							support_level: 'Self-Managed',
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 0,
						price_delta: 0,
						price_total: 0,
						price_total_delta: 0,
						value: 'FEDORA_33_SERVER_UNMANAGED',
						value_comments: null,
						value_description: 'Fedora 33 Server 64-bit Self-managed',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'LinuxUbuntu',
							os_license: null,
							support_level: 'Core-Managed',
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 1,
						price_delta: 1,
						price_total: 1,
						price_total_delta: 1,
						value: 'UBUNTU_1804_COREMANAGED',
						value_comments: null,
						value_description: 'Ubuntu 18.04 64-bit Core-managed',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							extra_software: 'cwp',
							os: 'LinuxCentOS',
							os_license: null,
							support_level: null,
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 0,
						price_delta: 0,
						price_total: 0,
						price_total_delta: 0,
						value: 'CENTOS_7_CWP',
						value_comments: null,
						value_description: 'CentOS 7 w/ CentOS Web Panel',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							extra_software: 'Plesk',
							os: 'LinuxCentOS',
							os_license: null,
							support_level: 'Fully-Managed',
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 1,
						price_delta: 1,
						price_total: 1,
						price_total_delta: 1,
						value: 'CENTOS_7_PLESK',
						value_comments: null,
						value_description: 'CentOS 7 64-bit Plesk',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'LinuxFedora',
							os_license: null,
							support_level: 'Self-Managed',
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 0,
						price_delta: 0,
						price_total: 0,
						price_total_delta: 0,
						value: 'FEDORA_30_SERVER_UNMANAGED',
						value_comments: null,
						value_description: 'Fedora 30 Server 64-bit Self-managed',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'LinuxCentOS',
							os_license: null,
							support_level: 'Core-Managed',
							zone_availability: {
								'40460': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 0,
						price_delta: 0,
						price_total: 0,
						price_total_delta: 0,
						value: 'CENTOS_7_COREMANAGED',
						value_comments: 'THIS IS A TEST',
						value_description: 'CentOS 7.0 64-bit CoreManaged',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'LinuxDebian',
							os_license: null,
							support_level: 'Self-Managed',
							zone_availability: {
								'1': 1,
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 1,
						price_delta: 1,
						price_total: 1,
						price_total_delta: 1,
						value: 'DEBIAN_8_UNMANAGED',
						value_comments: null,
						value_description: 'Debian 8 64-bit Self-managed',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							extra_software: 'Interworx',
							os: 'LinuxCentOS',
							os_license: null,
							support_level: 'Fully-Managed',
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 5,
						price_delta: 5,
						price_total: 5,
						price_total_delta: 5,
						value: 'CENTOS_7_INTERWORX',
						value_comments: null,
						value_description: 'CentOS 7 w/ Interworx',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'LinuxDebian',
							os_license: null,
							support_level: 'Self-Managed',
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 0,
						price_delta: 0,
						price_total: 0,
						price_total_delta: 0,
						value: 'DEBIAN_9_UNMANAGED',
						value_comments: null,
						value_description: 'Debian 9 64-bit Self-managed',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							extra_software: 'Plesk',
							os: 'Windows',
							os_license: null,
							support_level: 'Fully-Managed',
							zone_availability: {
								'1': 1,
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 1,
						price_delta: 1,
						price_total: 1,
						price_total_delta: 1,
						value: 'WINDOWS_2012R2_PLESK',
						value_comments: null,
						value_description:
							'Windows Server 2012 R2 Fully Managed with Plesk 12',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'LinuxCentOS',
							os_license: null,
							support_level: 'Self-Managed',
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 1,
						price_delta: 1,
						price_total: 1,
						price_total_delta: 1,
						value: 'CENTOS_8_UNMANAGED',
						value_comments: null,
						value_description: 'CentOS 8 64-bit Self-managed',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'LinuxUbuntu',
							os_license: null,
							support_level: 'Self-Managed',
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 0,
						price_delta: 0,
						price_total: 0,
						price_total_delta: 0,
						value: 'UBUNTU_2004_DJANGO',
						value_comments: null,
						value_description: 'Ubuntu 20.04 LTS 64-bit Django',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'LinuxCentOS',
							os_license: null,
							support_level: 'Self-Managed',
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 0,
						price_delta: 0,
						price_total: 0,
						price_total_delta: 0,
						value: 'CENTOS_STREAM_UNMANAGED',
						value_comments: null,
						value_description: 'CentOS Stream 64-bit Self-managed',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'LinuxFedora',
							os_license: null,
							support_level: 'Self-Managed',
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 0,
						price_delta: 0,
						price_total: 0,
						price_total_delta: 0,
						value: 'FEDORA_32_SERVER_UNMANAGED',
						value_comments: null,
						value_description: 'Fedora 32 Server 64-bit Self-managed',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'LinuxDebian',
							os_license: null,
							support_level: 'Self-Managed',
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 0,
						price_delta: 0,
						price_total: 0,
						price_total_delta: 0,
						value: 'DEBIAN_10_UNMANAGED',
						value_comments: null,
						value_description: 'Debian 10 64-bit Self-managed',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'LinuxCentOS',
							os_license: null,
							support_level: 'Core-Managed',
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 0,
						price_delta: 0,
						price_total: 0,
						price_total_delta: 0,
						value: 'CENTOS_8_COREMANAGED',
						value_comments: null,
						value_description: 'CentOS 8 64-bit CoreManaged',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'LinuxCentOS',
							os_license: null,
							support_level: 'Self-Managed',
							zone_availability: {
								'1': 1,
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 0,
						price_delta: 0,
						price_total: 0,
						price_total_delta: 0,
						value: 'ALMALINUX_8_UNMANAGED',
						value_comments: null,
						value_description: 'AlmaLinux 8 64-bit Self-managed',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'LinuxFedora',
							os_license: null,
							support_level: 'Self-Managed',
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 0,
						price_delta: 0,
						price_total: 0,
						price_total_delta: 0,
						value: 'FEDORA_31_SERVER_UNMANAGED',
						value_comments: null,
						value_description: 'Fedora 31 Server 64-bit Self-managed',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							extra_software: 'Cpanel',
							os: 'LinuxCloudLinux',
							os_license: null,
							support_level: 'Fully-Managed',
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 20,
						price_delta: 20,
						price_total: 20,
						price_total_delta: 20,
						value: 'CENTOS_7_CPANEL_CLOUDLINUX',
						value_comments: null,
						value_description: 'CentOS 7 64-bit cPanel w/CloudLinux',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							extra_software: 'Cpanel',
							os: 'LinuxCentOS',
							os_license: null,
							support_level: 'Fully-Managed',
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 1,
						price_delta: 1,
						price_total: 1,
						price_total_delta: 1,
						value: 'CENTOS_7_CPANEL',
						value_comments: null,
						value_description: 'CentOS 7 64-bit with cPanel Fully-managed',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'LinuxAlpine',
							os_license: null,
							support_level: 'Self-Managed',
							zone_availability: {
								'40460': 1,
								'86172': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 0,
						price_delta: 0,
						price_total: 0,
						price_total_delta: 0,
						value: 'ALPINE_LINUX',
						value_comments: null,
						value_description: 'Alpine Linux 64-bit Self-managed',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'LinuxUbuntu',
							os_license: null,
							support_level: 'Core-Managed',
							zone_availability: {
								'40460': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 10,
						price_delta: 10,
						price_total: 10,
						price_total_delta: 10,
						value: 'UBUNTU_1604_COREMANAGED',
						value_comments: null,
						value_description: 'Ubuntu 16.04 64-bit Core-managed',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'LinuxUbuntu',
							os_license: null,
							support_level: 'Self-Managed',
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 0,
						price_delta: 0,
						price_total: 0,
						price_total_delta: 0,
						value: 'UBUNTU_2004_UNMANAGED',
						value_comments: null,
						value_description: 'Ubuntu 20.04 LTS 64-bit Self-Managed',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'Windows',
							os_license: null,
							support_level: 'Self-Managed',
							zone_availability: {
								'1': 1,
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 9,
						price_delta: 9,
						price_total: 9,
						price_total_delta: 9,
						value: 'WINDOWS_2012R2_UNMANAGED',
						value_comments: null,
						value_description:
							'Windows Server 2012 R2 64-bit Standard Edition Self-Managed',
						value_display_order: 0,
					},
				],
				parent_key: null,
				parent_value: null,
				price: '0.00000',
				price_total: '0.00000',
				tax: null,
				totals: {
					subtotal_cost: '0.00000',
					total_cost: '0.00000',
				},
				valid: 1,
				value: 'CENTOS_7_UNMANAGED',
				value_comments: null,
				value_description: 'CentOS 7 64-bit Self-managed',
				value_display_order: 0,
				value_price_time_unit: 'month',
			},
			{
				discount: '0.00000',
				is_extra: 0,
				key: 'Bandwidth',
				key_description: 'Bandwidth',
				key_display_order: 3,
				key_group: 'Network Options',
				key_group_display_order: 4,
				num_units: null,
				options: [
					{
						is_extra: 0,
						key: 'Bandwidth',
						key_description: 'Bandwidth',
						key_display_order: 3,
						num_units: null,
						price: 50,
						price_delta: 50,
						price_total: 50,
						price_total_delta: 50,
						value: 'SS.6000',
						value_comments: null,
						value_description: '6000 GB Monthly Transfer',
						value_display_order: 1,
					},
					{
						is_extra: 0,
						key: 'Bandwidth',
						key_description: 'Bandwidth',
						key_display_order: 3,
						num_units: null,
						price: 150,
						price_delta: 150,
						price_total: 150,
						price_total_delta: 150,
						value: 'SS.8000',
						value_comments: null,
						value_description: '8000 GB Monthly Transfer',
						value_display_order: 2,
					},
					{
						is_extra: 0,
						key: 'Bandwidth',
						key_description: 'Bandwidth',
						key_display_order: 3,
						num_units: null,
						price: 250,
						price_delta: 250,
						price_total: 250,
						price_total_delta: 250,
						value: 'SS.10000',
						value_comments: null,
						value_description: '10 TB Monthly Transfer',
						value_display_order: 3,
					},
					{
						is_extra: 0,
						key: 'Bandwidth',
						key_description: 'Bandwidth',
						key_display_order: 3,
						num_units: null,
						price: 450,
						price_delta: 450,
						price_total: 450,
						price_total_delta: 450,
						value: 'SS.15000',
						value_comments: null,
						value_description: '15 TB Monthly Transfer',
						value_display_order: 4,
					},
					{
						is_extra: 0,
						key: 'Bandwidth',
						key_description: 'Bandwidth',
						key_display_order: 3,
						num_units: null,
						price: 750,
						price_delta: 750,
						price_total: 750,
						price_total_delta: 750,
						value: 'SS.20000',
						value_comments: null,
						value_description: '20 TB Monthly Transfer',
						value_display_order: 5,
					},
				],
				parent_key: null,
				parent_value: null,
				price: '0.00000',
				price_total: '3.00000',
				tax: null,
				totals: {
					subtotal_cost: '0.00000',
					total_cost: '0.00000',
				},
				valid: 1,
				value: 'SS.5000',
				value_comments: null,
				value_description: '10000 GB (5000 in / 5000 out)',
				value_display_order: 0,
				value_price_time_unit: 'static-month',
			},
			{
				discount: '0.00000',
				is_extra: 0,
				key: 'BandwidthOverage',
				key_description: 'Bandwidth Overage',
				key_display_order: 4,
				key_group: 'Misc. Options',
				key_group_display_order: 2147483647,
				num_units: -1,
				options: [],
				parent_key: null,
				parent_value: null,
				price: '0.25000',
				price_total: '0.00000',
				tax: null,
				totals: {
					subtotal_cost: '0.00000',
					total_cost: '0.00000',
				},
				valid: 1,
				value: '1GB',
				value_comments: null,
				value_description: '%{units} GB Overage',
				value_display_order: 1,
				value_price_time_unit: 'nontemporal',
			},
			{
				discount: '0.00000',
				is_extra: 0,
				key: 'ExtraIp',
				key_description: 'Extra IP Addresses',
				key_display_order: 6,
				key_group: 'Network Options',
				key_group_display_order: 4,
				num_units: 1,
				options: [],
				parent_key: null,
				parent_value: null,
				price: '1.00000',
				price_total: '0.00000',
				tax: null,
				totals: {
					subtotal_cost: '0.00000',
					total_cost: '0.00000',
				},
				valid: 1,
				value: '1',
				value_comments: null,
				value_description: '%{units} Additional Public IPs',
				value_display_order: 1,
				value_price_time_unit: 'month',
			},
			{
				discount: '0.00000',
				is_extra: 0,
				key: 'LiquidWebBackupPlan',
				key_description: 'Backup Plan',
				key_display_order: 5,
				key_group: 'Misc. Options',
				key_group_display_order: 2147483647,
				num_units: null,
				options: [
					{
						is_extra: 0,
						key: 'LiquidWebBackupPlan',
						key_description: 'Backup Plan',
						key_display_order: 5,
						num_units: null,
						price: 0,
						price_delta: 0,
						price_total: 0,
						price_total_delta: 0,
						value: 'None',
						value_comments: null,
						value_description: 'No Backups',
						value_display_order: 1,
					},
					{
						is_extra: 0,
						key: 'LiquidWebBackupPlan',
						key_description: 'Backup Plan',
						key_display_order: 5,
						num_units: null,
						price: 0,
						price_delta: 0,
						price_total: 0,
						price_total_delta: 0,
						value: 'Quota',
						value_comments: null,
						value_description: 'Quota-based Backups',
						value_display_order: 3,
					},
				],
				parent_key: null,
				parent_value: null,
				price: '0.00000',
				price_total: '0.00000',
				tax: null,
				totals: {
					subtotal_cost: '0.00000',
					total_cost: '0.00000',
				},
				valid: 1,
				value: 'Daily',
				value_comments: null,
				value_description: 'As-you-go Backups',
				value_display_order: 2,
				value_price_time_unit: 'month',
			},
			{
				discount: '0.00000',
				is_extra: 0,
				key: 'BackupStorage',
				key_description: 'Backup Storage',
				key_display_order: 1,
				key_group: 'Misc. Options',
				key_group_display_order: 2147483647,
				num_units: -1,
				options: [],
				parent_key: 'LiquidWebBackupPlan',
				parent_value: 'Daily',
				price: '0.00016',
				price_total: '0.00000',
				tax: null,
				totals: {
					subtotal_cost: '0.00000',
					total_cost: '0.00000',
				},
				valid: 1,
				value: '1GBHR',
				value_comments: null,
				value_description: '%{units} GB Hours',
				value_display_order: 1,
				value_price_time_unit: 'hour',
			},
			{
				discount: '0.00000',
				is_extra: 0,
				key: 'BackupDay',
				key_description: 'Backup Days',
				key_display_order: 2,
				key_group: 'Misc. Options',
				key_group_display_order: 2147483647,
				num_units: 0,
				options: [],
				parent_key: 'LiquidWebBackupPlan',
				parent_value: 'Daily',
				price: '0.00000',
				price_total: '0.00000',
				tax: null,
				totals: {
					subtotal_cost: '0.00000',
					total_cost: '0.00000',
				},
				valid: 1,
				value: '1',
				value_comments: null,
				value_description: '%{units} Retention Days',
				value_display_order: 1,
				value_price_time_unit: 'month',
			},
			{
				discount: '0.00000',
				is_extra: 1,
				key: 'cPanelLicenseTier',
				key_description: 'cPanel License Tier',
				key_display_order: 0,
				key_group: 'Misc. Options',
				key_group_display_order: 2147483647,
				num_units: null,
				options: [
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 0,
						price_delta: 0,
						price_total: 0,
						price_total_delta: 0,
						value: 'plus_cloud_50',
						value_comments: null,
						value_description: 'Plus',
						value_display_order: 25,
					},
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 10,
						price_delta: 10,
						price_total: 10,
						price_total_delta: 10,
						value: 'premier_cloud_100',
						value_comments: null,
						value_description: 'Premier Fixed 100',
						value_display_order: 35,
					},
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 115,
						price_delta: 115,
						price_total: 115,
						price_total_delta: 115,
						value: 'premier_cloud_1000',
						value_comments: null,
						value_description: 'Premier Fixed 1000',
						value_display_order: 140,
					},
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 15,
						price_delta: 15,
						price_total: 15,
						price_total_delta: 15,
						value: 'premier_cloud_150',
						value_comments: null,
						value_description: 'Premier Fixed 150',
						value_display_order: 40,
					},
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 175,
						price_delta: 175,
						price_total: 175,
						price_total_delta: 175,
						value: 'premier_cloud_1500',
						value_comments: null,
						value_description: 'Premier Fixed 1500',
						value_display_order: 200,
					},
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 20,
						price_delta: 20,
						price_total: 20,
						price_total_delta: 20,
						value: 'premier_cloud_200',
						value_comments: null,
						value_description: 'Premier Fixed 200',
						value_display_order: 45,
					},
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 25,
						price_delta: 25,
						price_total: 25,
						price_total_delta: 25,
						value: 'premier_cloud_250',
						value_comments: null,
						value_description: 'Premier Fixed 250',
						value_display_order: 50,
					},
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 30,
						price_delta: 30,
						price_total: 30,
						price_total_delta: 30,
						value: 'premier_cloud_300',
						value_comments: null,
						value_description: 'Premier Fixed 300',
						value_display_order: 55,
					},
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 35,
						price_delta: 35,
						price_total: 35,
						price_total_delta: 35,
						value: 'premier_cloud_350',
						value_comments: null,
						value_description: 'Premier Fixed 350',
						value_display_order: 60,
					},
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 45,
						price_delta: 45,
						price_total: 45,
						price_total_delta: 45,
						value: 'premier_cloud_400',
						value_comments: null,
						value_description: 'Premier Fixed 400',
						value_display_order: 70,
					},
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 55,
						price_delta: 55,
						price_total: 55,
						price_total_delta: 55,
						value: 'premier_cloud_450',
						value_comments: null,
						value_description: 'Premier Fixed 450',
						value_display_order: 80,
					},
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 65,
						price_delta: 65,
						price_total: 65,
						price_total_delta: 65,
						value: 'premier_cloud_500',
						value_comments: null,
						value_description: 'Premier Fixed 500',
						value_display_order: 90,
					},
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 75,
						price_delta: 75,
						price_total: 75,
						price_total_delta: 75,
						value: 'premier_cloud_600',
						value_comments: null,
						value_description: 'Premier Fixed 600',
						value_display_order: 100,
					},
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 85,
						price_delta: 85,
						price_total: 85,
						price_total_delta: 85,
						value: 'premier_cloud_700',
						value_comments: null,
						value_description: 'Premier Fixed 700',
						value_display_order: 110,
					},
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 95,
						price_delta: 95,
						price_total: 95,
						price_total_delta: 95,
						value: 'premier_cloud_800',
						value_comments: null,
						value_description: 'Premier Fixed 800',
						value_display_order: 120,
					},
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 105,
						price_delta: 105,
						price_total: 105,
						price_total_delta: 105,
						value: 'premier_cloud_900',
						value_comments: null,
						value_description: 'Premier Fixed 900',
						value_display_order: 130,
					},
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 0,
						price_delta: 0,
						price_total: 0,
						price_total_delta: 0,
						value: 'pro_cloud_30',
						value_comments: null,
						value_description: 'Pro',
						value_display_order: 20,
					},
				],
				parent_key: null,
				parent_value: null,
				price: '0.00000',
				price_total: '0.00000',
				tax: null,
				totals: {
					subtotal_cost: '0.00000',
					total_cost: '0.00000',
				},
				valid: 1,
				value: 'none',
				value_comments: null,
				value_description: 'None',
				value_display_order: 0,
				value_price_time_unit: 'month',
			},
			{
				discount: '0.00000',
				is_extra: 0,
				key: 'Acronis',
				key_description: 'Acronis Cyber Backup',
				key_display_order: 0,
				key_group: 'Storage and Backups',
				key_group_display_order: 2,
				num_units: null,
				options: [
					{
						is_extra: 0,
						key: 'Acronis',
						key_description: 'Acronis Cyber Backup',
						key_display_order: 0,
						num_units: null,
						price: 0,
						price_delta: -130,
						price_total: 0,
						price_total_delta: -130,
						value: 'No_Acronis',
						value_comments: null,
						value_description: 'No Acronis Cyber Backup Required',
						value_display_order: 0,
					},
					{
						is_extra: 0,
						key: 'Acronis',
						key_description: 'Acronis Cyber Backup',
						key_display_order: 0,
						num_units: null,
						price: 25,
						price_delta: -105,
						price_total: 25,
						price_total_delta: -105,
						value: 'Acronis_LW250',
						value_comments: null,
						value_description: 'Liquid Web Cloud - 250GB Storage',
						value_display_order: 5,
					},
					{
						is_extra: 0,
						key: 'Acronis',
						key_description: 'Acronis Cyber Backup',
						key_display_order: 0,
						num_units: null,
						price: 65,
						price_delta: -65,
						price_total: 65,
						price_total_delta: -65,
						value: 'Acronis_LW1000',
						value_comments: null,
						value_description: 'Liquid Web Cloud - 1TB Storage',
						value_display_order: 15,
					},
					{
						is_extra: 0,
						key: 'Acronis',
						key_description: 'Acronis Cyber Backup',
						key_display_order: 0,
						num_units: null,
						price: 235,
						price_delta: 105,
						price_total: 235,
						price_total_delta: 105,
						value: 'Acronis_LW5000',
						value_comments: null,
						value_description: 'Liquid Web Cloud - 5TB Storage',
						value_display_order: 40,
					},
					{
						is_extra: 0,
						key: 'Acronis',
						key_description: 'Acronis Cyber Backup',
						key_display_order: 0,
						num_units: null,
						price: 40,
						price_delta: -90,
						price_total: 40,
						price_total_delta: -90,
						value: 'Acronis_AC250',
						value_comments: null,
						value_description: 'Acronis Cloud - 250GB Storage',
						value_display_order: 45,
					},
					{
						is_extra: 0,
						key: 'Acronis',
						key_description: 'Acronis Cyber Backup',
						key_display_order: 0,
						num_units: null,
						price: 70,
						price_delta: -60,
						price_total: 70,
						price_total_delta: -60,
						value: 'Acronis_AC500',
						value_comments: null,
						value_description: 'Acronis Cloud - 500GB Storage',
						value_display_order: 50,
					},
					{
						is_extra: 0,
						key: 'Acronis',
						key_description: 'Acronis Cyber Backup',
						key_display_order: 0,
						num_units: null,
						price: 175,
						price_delta: 45,
						price_total: 175,
						price_total_delta: 45,
						value: 'Acronis_AC1500',
						value_comments: null,
						value_description: 'Acronis Cloud - 1.5TB Storage',
						value_display_order: 60,
					},
					{
						is_extra: 0,
						key: 'Acronis',
						key_description: 'Acronis Cyber Backup',
						key_display_order: 0,
						num_units: null,
						price: 285,
						price_delta: 155,
						price_total: 285,
						price_total_delta: 155,
						value: 'Acronis_AC2000',
						value_comments: null,
						value_description: 'Acronis Cloud - 2TB Storage',
						value_display_order: 65,
					},
					{
						is_extra: 0,
						key: 'Acronis',
						key_description: 'Acronis Cyber Backup',
						key_display_order: 0,
						num_units: null,
						price: 285,
						price_delta: 155,
						price_total: 285,
						price_total_delta: 155,
						value: 'Acronis_AC2500',
						value_comments: null,
						value_description: 'Acronis Cloud - 2.5TB Storage',
						value_display_order: 70,
					},
					{
						is_extra: 0,
						key: 'Acronis',
						key_description: 'Acronis Cyber Backup',
						key_display_order: 0,
						num_units: null,
						price: 40,
						price_delta: -90,
						price_total: 40,
						price_total_delta: -90,
						value: 'Acronis_LW500',
						value_comments: null,
						value_description: 'Liquid Web Cloud - 500GB Storage',
						value_display_order: 10,
					},
					{
						is_extra: 0,
						key: 'Acronis',
						key_description: 'Acronis Cyber Backup',
						key_display_order: 0,
						num_units: null,
						price: 85,
						price_delta: -45,
						price_total: 85,
						price_total_delta: -45,
						value: 'Acronis_LW1500',
						value_comments: null,
						value_description: 'Liquid Web Cloud - 1.5TB Storage',
						value_display_order: 20,
					},
					{
						is_extra: 0,
						key: 'Acronis',
						key_description: 'Acronis Cyber Backup',
						key_display_order: 0,
						num_units: null,
						price: 110,
						price_delta: -20,
						price_total: 110,
						price_total_delta: -20,
						value: 'Acronis_LW2000',
						value_comments: null,
						value_description: 'Liquid Web Cloud - 2TB Storage',
						value_display_order: 25,
					},
					{
						is_extra: 0,
						key: 'Acronis',
						key_description: 'Acronis Cyber Backup',
						key_display_order: 0,
						num_units: null,
						price: 160,
						price_delta: 30,
						price_total: 160,
						price_total_delta: 30,
						value: 'Acronis_LW3000',
						value_comments: null,
						value_description: 'Liquid Web Cloud - 3TB Storage',
						value_display_order: 35,
					},
					{
						is_extra: 0,
						key: 'Acronis',
						key_description: 'Acronis Cyber Backup',
						key_display_order: 0,
						num_units: null,
						price: 310,
						price_delta: 180,
						price_total: 310,
						price_total_delta: 180,
						value: 'Acronis_AC3000',
						value_comments: null,
						value_description: 'Acronis Cloud - 3TB Storage',
						value_display_order: 75,
					},
					{
						is_extra: 0,
						key: 'Acronis',
						key_description: 'Acronis Cyber Backup',
						key_display_order: 0,
						num_units: null,
						price: 510,
						price_delta: 380,
						price_total: 510,
						price_total_delta: 380,
						value: 'Acronis_AC5000',
						value_comments: null,
						value_description: 'Acronis Cloud - 5TB Storage',
						value_display_order: 80,
					},
				],
				parent_key: null,
				parent_value: null,
				price: '130.00000',
				price_total: '130.00000',
				tax: null,
				totals: {
					subtotal_cost: '130.00000',
					total_cost: '130.00000',
				},
				valid: 1,
				value: 'Acronis_AC1000',
				value_comments: null,
				value_description: 'Acronis Cloud - 1TB Storage',
				value_display_order: 55,
				value_price_time_unit: 'month',
			},
			{
				discount: '0.00000',
				extra_data: {
					category: 'bare-metal',
					config_id: '6',
					cores: 32,
					cpu_description: 'Intel(R) Xeon(R) CPU           E5606  @ 2.13GHz',
					cpu_speed: 2133,
					is_baremetal: 1,
					memory: 30494,
					price: 100,
					product_code: 'SS.VM',
					storage_raid: '10',
					storage_size: 1665,
					storage_type: 'VINAL',
				},
				is_extra: 0,
				key: 'ConfigId',
				key_description: 'Server Type',
				key_display_order: 1,
				key_group: 'Misc. Options',
				key_group_display_order: 2147483647,
				num_units: null,
				options: [
					{
						extra_data: {
							category: 'bare-metal',
							config_id: '9',
							cores: 18,
							cpu_description: 'AMD FX(tm)-6100 Six-Core Processor',
							cpu_speed: 3300,
							is_baremetal: 1,
							memory: 14672,
							price: 90,
							product_code: 'SS.VM',
							storage_raid: '1',
							storage_size: 828,
							storage_type: 'SATA',
							cpu_hyperthreading: 1,
						},
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 90,
						price_delta: -10,
						price_total: 90,
						price_total_delta: -10,
						value: '9',
						value_comments: null,
						value_description:
							"'AMD FX(tm)-6100 Six-Core Processor', 3300MHz, 3 Core, Sockets: 2",
						value_display_order: 41,
					},
					{
						extra_data: {
							category: 'bare-metal',
							config_id: '10',
							cores: 32,
							cpu_description:
								'Intel(R) Xeon(R) CPU           E5506  @ 2.13GHz',
							cpu_speed: 2133,
							is_baremetal: 1,
							memory: 22607,
							price: 75,
							product_code: 'SS.VM',
							storage_raid: '10',
							storage_size: 1665,
							storage_type: 'SATA',
							cpu_hyperthreading: 0,
						},
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 75,
						price_delta: -25,
						price_total: 75,
						price_total_delta: -25,
						value: '10',
						value_comments: null,
						value_description:
							"'Intel(R) Xeon(R) CPU           E5506  @ 2.13GHz', 2133MHz, 4 Core, Sockets: 2",
						value_display_order: 40,
					},
					{
						extra_data: {
							category: 'bare-metal',
							config_id: '9',
							cores: 18,
							cpu_description: 'AMD FX(tm)-6100 Six-Core Processor',
							cpu_speed: 3300,
							is_baremetal: 1,
							memory: 14672,
							price: 90,
							product_code: 'SS.VM',
							storage_raid: '1',
							storage_size: 828,
							storage_type: 'VINAL',
						},
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 150,
						price_delta: -10,
						price_total: 90,
						price_total_delta: -10,
						value: '11',
						value_comments: null,
						value_description:
							"'AMD FX(tm)-6100 Six-Core Processor', 3300MHz, 3 Core, Sockets: 2",
						value_display_order: 41,
					},
					{
						extra_data: {
							category: 'bare-metal',
							config_id: '10',
							cores: 32,
							cpu_description:
								'Intel(R) Xeon(R) CPU           E5506  @ 2.13GHz',
							cpu_speed: 2133,
							is_baremetal: 1,
							memory: 22607,
							price: 75,
							product_code: 'SS.VM',
							storage_raid: '10',
							storage_size: 1665,
							storage_type: 'SATA',
						},
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 175,
						price_delta: -25,
						price_total: 75,
						price_total_delta: -25,
						value: '12',
						value_comments: null,
						value_description:
							"'Intel(R) Xeon(R) CPU           E5506  @ 2.13GHz', 2133MHz, 4 Core, Sockets: 2",
						value_display_order: 40,
					},
				],
				parent_key: null,
				parent_value: null,
				price: '100.00000',
				price_total: '100.00000',
				tax: null,
				totals: {
					subtotal_cost: '100.00000',
					total_cost: '100.00000',
				},
				valid: 1,
				value: '6',
				value_comments: null,
				value_description:
					"'Intel(R) Xeon(R) CPU           E5606  @ 2.13GHz', 2133MHz, 4 Core, Sockets: 2",
				value_display_order: 62,
				value_price_time_unit: 'month',
			},
		],
		created: '2021-05-12 13:53:24.566914-04',
		discount: '0.00000',
		extra_data: {
			available_backups: [
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4230,
					name: null,
					size: '1.94',
					template: 'UBUNTU_1604_UNMANAGED',
					time_taken: '2021-04-24 00:02:52',
					uniq_id: '78554A',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4237,
					name: null,
					size: '1.94',
					template: 'WINDOWS_2012R2_COREMANAGED',
					time_taken: '2021-04-25 00:02:54',
					uniq_id: '78554A',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4243,
					name: null,
					size: '1.92',
					template: 'UBUNTU_1804_UNMANAGED',
					time_taken: '2021-04-26 00:02:40',
					uniq_id: '78554A',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4251,
					name: null,
					size: '1.93',
					template: 'FEDORA_33_SERVER_UNMANAGED',
					time_taken: '2021-04-27 00:02:54',
					uniq_id: '78554A',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4258,
					name: null,
					size: '1.94',
					template: 'CENTOS_7_PLESK',
					time_taken: '2021-04-28 00:02:59',
					uniq_id: '78554A',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4266,
					name: null,
					size: '1.95',
					template: 'DEBIAN_8_UNMANAGED',
					time_taken: '2021-04-29 00:21:26',
					uniq_id: '78554A',
				},
			],
			available_images: [
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: '1',
					template: 'UBUNTU_1604_UNMANAGED',
					name: 'test image 2',
					size: '1.46',
					source_hostname: 'host.backupsandimagestest.com',
					source_uniq_id: '78554A',
					template_description: 'CentOS 7 64-bit Self-managed',
					time_taken: '2020-01-14 16:25:06',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: '2',
					template: 'WINDOWS_2012R2_COREMANAGED',
					name: 'image2',
					size: '7.13',
					source_hostname: 'paypergigbackups.testing.com',
					source_uniq_id: '',
					template_description: 'CentOS 7 64-bit cPanel w/CloudLinux',
					time_taken: '2020-01-16 16:53:47',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: '4',
					template: 'UBUNTU_1804_UNMANAGED',
					name: 'testing a rename',
					size: '1.50',
					source_hostname: 'host.backupsandimagestest.com',
					source_uniq_id: '78554A',
					template_description: 'CentOS 7 64-bit Self-managed',
					time_taken: '2020-01-20 11:45:59',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: '10',
					template: 'FEDORA_33_SERVER_UNMANAGED',
					name: 'givemeaname',
					size: '1.50',
					source_hostname: 'host.backupsandimagestest.com',
					source_uniq_id: '78554A',
					template_description: 'CentOS 7 64-bit Self-managed',
					time_taken: '2020-01-21 17:43:39',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: '11',
					template: 'CENTOS_7_PLESK',
					name: 'create image name',
					size: '1.50',
					source_hostname: 'host.backupsandimagestest.com',
					source_uniq_id: '78554A',
					template_description: 'CentOS 7 64-bit Self-managed',
					time_taken: '2020-01-21 18:01:32',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: '13',
					template: 'DEBIAN_8_UNMANAGED',
					name: 'new image',
					size: '1.51',
					source_hostname: 'host.backupsandimagestest.com',
					source_uniq_id: '78554A',
					template_description: 'CentOS 7 64-bit Self-managed',
					time_taken: '2020-01-23 14:08:27',
				},
			],
			ip_pool: {
				subaccnt: 'E6WU20',
				unused_assignments: [
					{
						begin_range: '67.43.15.12',
						broadcast: '67.43.15.63',
						end_range: '67.43.15.12',
						gateway: '67.43.15.1',
						id: 4807057,
						ip: '67.43.15.12',
						is_legacy_storm: 1,
						is_storm_anchor: 0,
						netmask: '255.255.255.192',
						network: '67.43.15.12/32',
						zone_id: 40460,
					},
				],
			},
		},
		productInfo: {
			alias: null,
			capabilities: {
				ATSLogin: 1,
				autoAddIp: 1,
				autoPrivateNetworking: 1,
				autoReboot: 1,
				autoRemoveIp: 1,
				autoRename: 1,
				autoShutdown: 1,
				bandwidthStats: 1,
				canHotplugRamVcpu: 1,
				canThreatStack: 1,
				canUseSpiceConsole: 1,
				changeBWOptions: 1,
				clone: 1,
				create: 1,
				credentials: 1,
				destroy: 1,
				emailable: 1,
				extraAuth: 1,
				guardian: 1,
				image: 1,
				manAddIp: 1,
				manReboot: 1,
				manRemoveIp: 1,
				managed: 1,
				migratableDestination: 1,
				migratableSource: 1,
				multiplePublicIPs: 1,
				networking: 1,
				presentable: 1,
				provBackup: 1,
				provFirewall: 1,
				reimage: 1,
				rescueBoot: 1,
				resize: 1,
				restoreFromTemplate: 1,
				reverseDNSEdit: 1,
				rootLogin: 1,
				serviceMonitoring: 1,
				serviceMonitoringBasic: 1,
				serviceMonitoringDns: 1,
				serviceMonitoringLinux: 1,
				serviceMonitoringMachineMysqld: 1,
				sonar: 1,
				volumeAttach: 1,
				volumeDetach: 1,
				webConsole: 1,
			},
			credit_eligible: 1,
			cycle: 'month',
			discountable: 1,
			product_code: 'SS.VM',
			product_description: 'LiquidWeb SmartServer',
			product_display_group: 'Cloud Dedicated',
			sub_title: '',
			tax_code: 'SW050400',
			title: '',
		},
		product_code: 'SS.VM',
		properties: {
			account_default_region: 1,
			account_default_zone: '45412',
			zone: '40460',
			create_from: { type: 'image', id: 4 },
			public_ssh_key: 'whatever',
			use_ipv6: true,
			ip_pool: ['67.43.15.12'],
		},
		region: 1,
		tax: null,
		totals: {
			configs_discount_total: '0.00000',
			configs_price_total: '120.00000',
			configs_subtotal: '120.00000',
			configs_tax_total: '0',
			configs_total_cost: '120.00000',
			item_discount_total: '0.00000',
			item_prepay_auto_renew: null,
			item_prepay_discount: null,
			item_prepay_once: null,
			item_prepay_ongoing: null,
			item_prepay_term_max: null,
			item_price_total: '120.00000',
			item_setup_fee: '0',
			item_subtotal: '120.00000',
			item_tax_total: '0',
			item_total_cost: '120.00000',
			subtotal_base_cost: '0.00000',
			total_base_cost: '0.00000',
		},
		uuid: '99473347-1a21-4fdd-8b65-09a3a23b3e86',
		valid: 1,
	},
	'1d30e04b-0491-4455-956e-5f947fac7221': {
		base_price: '0.00000',
		basket_uuid: 'd68aef1a-776c-48ea-bee8-7993eb552b78',
		configs: [
			{
				discount: '0.00000',
				extra_data: {
					category: 'bare-metal',
					config_id: '9',
					cores: 18,
					cpu_description: 'AMD FX(tm)-6100 Six-Core Processor',
					cpu_speed: 3300,
					is_baremetal: 1,
					memory: 14672,
					price: 115,
					product_code: 'SS.PP',
					storage_raid: '1',
					storage_size: 828,
					storage_type: 'SATA',
				},
				is_extra: 0,
				key: 'ConfigId',
				key_description: 'Server Type',
				key_display_order: 1,
				key_group: 'Misc. Options',
				key_group_display_order: 2147483647,
				num_units: null,
				options: [
					{
						extra_data: {
							category: 'bare-metal',
							config_id: '10',
							cores: 32,
							cpu_description:
								'Intel(R) Xeon(R) CPU           E5506  @ 2.13GHz',
							cpu_speed: 2133,
							is_baremetal: 1,
							memory: 22607,
							price: 125,
							product_code: 'SS.PP',
							storage_raid: '10',
							storage_size: 1665,
							storage_type: 'SATA',
						},
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 125,
						price_delta: 10,
						price_total: 125,
						price_total_delta: 10,
						value: '10',
						value_comments: null,
						value_description:
							"'Intel(R) Xeon(R) CPU           E5506  @ 2.13GHz', 2133MHz, 4 Core, Sockets: 2",
						value_display_order: 59,
					},
					{
						extra_data: {
							category: 'bare-metal',
							config_id: '6',
							cores: 32,
							cpu_description:
								'Intel(R) Xeon(R) CPU           E5606  @ 2.13GHz',
							cpu_speed: 2133,
							is_baremetal: 1,
							memory: 30494,
							price: 150,
							product_code: 'SS.PP',
							storage_raid: '10',
							storage_size: 1665,
							storage_type: 'VINAL',
						},
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 150,
						price_delta: 35,
						price_total: 150,
						price_total_delta: 35,
						value: '6',
						value_comments: null,
						value_description:
							"'Intel(R) Xeon(R) CPU           E5606  @ 2.13GHz', 2133MHz, 4 Core, Sockets: 2",
						value_display_order: 62,
					},
					{
						extra_data: {
							category: 'bare-metal',
							config_id: '10',
							cores: 32,
							cpu_description:
								'Intel(R) Xeon(R) CPU           E5506  @ 2.13GHz',
							cpu_speed: 2133,
							is_baremetal: 1,
							memory: 22607,
							price: 125,
							product_code: 'SS.PP',
							storage_raid: '10',
							storage_size: 1665,
							storage_type: 'SATA',
						},
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 125,
						price_delta: 10,
						price_total: 125,
						price_total_delta: 10,
						value: '11',
						value_comments: null,
						value_description:
							"'Intel(R) Xeon(R) CPU           E5506  @ 2.13GHz', 2133MHz, 4 Core, Sockets: 2",
						value_display_order: 59,
					},
					{
						extra_data: {
							category: 'bare-metal',
							config_id: '6',
							cores: 32,
							cpu_description:
								'Intel(R) Xeon(R) CPU           E5606  @ 2.13GHz',
							cpu_speed: 2133,
							is_baremetal: 1,
							memory: 30494,
							price: 150,
							product_code: 'SS.PP',
							storage_raid: '10',
							storage_size: 1665,
							storage_type: 'VINAL',
						},
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 150,
						price_delta: 35,
						price_total: 150,
						price_total_delta: 35,
						value: '7',
						value_comments: null,
						value_description:
							"'Intel(R) Xeon(R) CPU           E5606  @ 2.13GHz', 2133MHz, 4 Core, Sockets: 2",
						value_display_order: 62,
					},
				],
				parent_key: null,
				parent_value: null,
				price: '115.00000',
				price_total: '115.00000',
				tax: null,
				totals: {
					subtotal_cost: '115.00000',
					total_cost: '115.00000',
				},
				valid: 1,
				value: '9',
				value_comments: null,
				value_description:
					"'AMD FX(tm)-6100 Six-Core Processor', 3300MHz, 3 Core, Sockets: 2",
				value_display_order: 57,
				value_price_time_unit: 'month',
			},
		],
		created: '2021-05-12 15:43:46.829804-04',
		discount: '0.00000',
		productInfo: {
			alias: null,
			capabilities: {
				ATSLogin: 1,
				autoRename: 1,
				bandwidthStats: 0,
				canThreatStack: 1,
				credentials: 1,
				destroy: 1,
				emailable: 1,
				manAddIp: 0,
				manReboot: 0,
				manRemoveIp: 0,
				managed: 0,
				multiplePublicIPs: 0,
				networking: 0,
				presentable: 1,
				privateParentInstanceAdd: 1,
				privateParentInstanceList: 1,
				reverseDNSEdit: 0,
				serviceMonitoring: 0,
				serviceMonitoringBasic: 0,
				serviceMonitoringDns: 0,
				serviceMonitoringLinux: 0,
				serviceMonitoringMachineMysqld: 1,
				sonar: 0,
			},
			credit_eligible: 1,
			cycle: 'month',
			discountable: 1,
			product_code: 'SS.PP',
			product_description: 'Storm Private Parent',
			product_display_group: 'Storm PP',
			sub_title: '',
			tax_code: 'SW054000',
			title: '',
		},
		product_code: 'SS.PP',
		properties: {
			account_default_region: 1,
			account_default_zone: '45412',
			zone: '40460',
		},
		region: 1,
		tax: null,
		totals: {
			configs_discount_total: '0.00000',
			configs_price_total: '115.00000',
			configs_subtotal: '115.00000',
			configs_tax_total: '0',
			configs_total_cost: '115.00000',
			item_discount_total: '0.00000',
			item_prepay_auto_renew: null,
			item_prepay_discount: null,
			item_prepay_once: null,
			item_prepay_ongoing: null,
			item_prepay_term_max: null,
			item_price_total: '115.00000',
			item_setup_fee: '0',
			item_subtotal: '115.00000',
			item_tax_total: '0',
			item_total_cost: '115.00000',
			subtotal_base_cost: '0.00000',
			total_base_cost: '0.00000',
		},
		uuid: '1d30e04b-0491-4455-956e-5f947fac7221',
		valid: 1,
	},
	'f1fbe9cb-5a11-4a27-942b-36e4286427c9': {
		base_price: '0.00000',
		basket_uuid: 'acb07119-6b38-4857-8c11-6f159ae58138',
		configs: [
			{
				discount: '0.00000',
				extra_data: { tags: [] },
				is_extra: 0,
				key: 'ExtraIp',
				key_description: 'Extra IP Addresses',
				key_display_order: 5,
				key_group: 'Network Options',
				key_group_display_order: 4,
				num_units: 0,
				options: [],
				parent_key: null,
				parent_value: null,
				price: '1.00000',
				price_total: '0.00000',
				tax: null,
				totals: { subtotal_cost: '0.00000', total_cost: '0.00000' },
				valid: 1,
				value: '1',
				value_comments: null,
				value_description: '%{units} Additional Public IPs',
				value_display_order: 1,
				value_price_time_unit: 'month',
			},
			{
				discount: '0.00000',
				extra_data: { tags: [] },
				is_extra: 0,
				key: 'BandwidthOverage',
				key_description: 'Bandwidth Overage',
				key_display_order: 4,
				key_group: 'Misc. Options',
				key_group_display_order: 2147483647,
				num_units: -1,
				options: [],
				parent_key: null,
				parent_value: null,
				price: '0.25000',
				price_total: '0.00000',
				tax: null,
				totals: { subtotal_cost: '0.00000', total_cost: '0.00000' },
				valid: 1,
				value: '1GB',
				value_comments: null,
				value_description: '%{units} GB Overage',
				value_display_order: 1,
				value_price_time_unit: 'nontemporal',
			},
			{
				discount: '0.00000',
				extra_data: { tags: [] },
				is_extra: 0,
				key: 'WinAV',
				key_description: 'Anti-Virus',
				key_display_order: 6,
				key_group: 'Misc. Options',
				key_group_display_order: 2147483647,
				num_units: null,
				options: [
					{
						is_extra: 0,
						key: 'WinAV',
						key_description: 'Anti-Virus',
						key_display_order: 6,
						num_units: null,
						price: 0,
						price_delta: -10,
						price_total: 0,
						price_total_delta: -10,
						value: 'None',
						value_comments: null,
						value_description: 'No Anti-virus',
						value_display_order: 2,
					},
				],
				parent_key: null,
				parent_value: null,
				price: '10.00000',
				price_total: '10.00000',
				tax: null,
				totals: { subtotal_cost: '10.00000', total_cost: '10.00000' },
				valid: 1,
				value: 'NOD32',
				value_comments: null,
				value_description: 'ESET File Security',
				value_display_order: 1,
				value_price_time_unit: 'month',
			},
			{
				discount: '0.00000',
				extra_data: { tags: [] },
				is_extra: 0,
				key: 'MsSQL',
				key_description: 'MS SQL Server',
				key_display_order: 8,
				key_group: 'Misc. Options',
				key_group_display_order: 2147483647,
				num_units: 0,
				options: [
					{
						is_extra: 0,
						key: 'MsSQL',
						key_description: 'MS SQL Server',
						key_display_order: 8,
						num_units: 1,
						price: 0,
						price_delta: 0,
						price_total: 0,
						price_total_delta: 0,
						value: '2012.Express',
						value_comments: null,
						value_description: 'SQL Server 2012 - Express Edition',
						value_display_order: 7,
					},
					{
						is_extra: 0,
						key: 'MsSQL',
						key_description: 'MS SQL Server',
						key_display_order: 8,
						num_units: 1,
						price: 18.75,
						price_delta: 18.75,
						price_total: 18.75,
						price_total_delta: 18.75,
						value: '2012.Web',
						value_comments: null,
						value_description: 'SQL Server 2012 - Web Edition',
						value_display_order: 7,
					},
					{
						is_extra: 0,
						key: 'MsSQL',
						key_description: 'MS SQL Server',
						key_display_order: 8,
						num_units: 1,
						price: 75,
						price_delta: 75,
						price_total: 75,
						price_total_delta: 75,
						value: '2012.Standard',
						value_comments: null,
						value_description: 'SQL Server 2012 - Standard Edition',
						value_display_order: 7,
					},
					{
						is_extra: 0,
						key: 'MsSQL',
						key_description: 'MS SQL Server',
						key_display_order: 8,
						num_units: 1,
						price: 300,
						price_delta: 300,
						price_total: 300,
						price_total_delta: 300,
						value: '2012.Enterprise',
						value_comments: null,
						value_description: 'SQL Server 2012 - Enterprise Edition',
						value_display_order: 7,
					},
					{
						is_extra: 0,
						key: 'MsSQL',
						key_description: 'MS SQL Server',
						key_display_order: 8,
						num_units: 1,
						price: 18.75,
						price_delta: 18.75,
						price_total: 18.75,
						price_total_delta: 18.75,
						value: '2014.Web',
						value_comments: null,
						value_description: 'SQL Server 2014 - Web Edition',
						value_display_order: 8,
					},
					{
						is_extra: 0,
						key: 'MsSQL',
						key_description: 'MS SQL Server',
						key_display_order: 8,
						num_units: 1,
						price: 300,
						price_delta: 300,
						price_total: 300,
						price_total_delta: 300,
						value: '2014.Enterprise',
						value_comments: null,
						value_description: 'SQL Server 2014 - Enterprise Edition',
						value_display_order: 8,
					},
					{
						is_extra: 0,
						key: 'MsSQL',
						key_description: 'MS SQL Server',
						key_display_order: 8,
						num_units: 1,
						price: 0,
						price_delta: 0,
						price_total: 0,
						price_total_delta: 0,
						value: '2014.Express',
						value_comments: null,
						value_description: 'SQL Server 2014 - Express Edition',
						value_display_order: 8,
					},
					{
						is_extra: 0,
						key: 'MsSQL',
						key_description: 'MS SQL Server',
						key_display_order: 8,
						num_units: 1,
						price: 75,
						price_delta: 75,
						price_total: 75,
						price_total_delta: 75,
						value: '2014.Standard',
						value_comments: null,
						value_description: 'SQL Server 2014 - Standard Edition',
						value_display_order: 8,
					},
					{
						is_extra: 0,
						key: 'MsSQL',
						key_description: 'MS SQL Server',
						key_display_order: 8,
						num_units: 1,
						price: 0,
						price_delta: 0,
						price_total: 0,
						price_total_delta: 0,
						value: '2016.Express',
						value_comments: null,
						value_description: 'SQL Server 2016 - Express Edition',
						value_display_order: 9,
					},
					{
						is_extra: 0,
						key: 'MsSQL',
						key_description: 'MS SQL Server',
						key_display_order: 8,
						num_units: 1,
						price: 18.75,
						price_delta: 18.75,
						price_total: 18.75,
						price_total_delta: 18.75,
						value: '2016.Web',
						value_comments: null,
						value_description: 'SQL Server 2016 - Web Edition',
						value_display_order: 10,
					},
					{
						is_extra: 0,
						key: 'MsSQL',
						key_description: 'MS SQL Server',
						key_display_order: 8,
						num_units: 1,
						price: 75,
						price_delta: 75,
						price_total: 75,
						price_total_delta: 75,
						value: '2016.Standard',
						value_comments: null,
						value_description: 'SQL Server 2016 - Standard Edition',
						value_display_order: 11,
					},
					{
						is_extra: 0,
						key: 'MsSQL',
						key_description: 'MS SQL Server',
						key_display_order: 8,
						num_units: 1,
						price: 300,
						price_delta: 300,
						price_total: 300,
						price_total_delta: 300,
						value: '2016.Enterprise',
						value_comments: null,
						value_description: 'SQL Server 2016 - Enterprise Edition',
						value_display_order: 12,
					},
					{
						is_extra: 0,
						key: 'MsSQL',
						key_description: 'MS SQL Server',
						key_display_order: 8,
						num_units: null,
						price: 0,
						price_delta: 0,
						price_total: 0,
						price_total_delta: 0,
						value: '2019.Express',
						value_comments: null,
						value_description: 'SQL Server 2019 - Express Edition',
						value_display_order: 13,
					},
					{
						is_extra: 0,
						key: 'MsSQL',
						key_description: 'MS SQL Server',
						key_display_order: 8,
						num_units: null,
						price: 18.75,
						price_delta: 18.75,
						price_total: 18.75,
						price_total_delta: 18.75,
						value: '2019.Web',
						value_comments: null,
						value_description: 'SQL Server 2019 - Web Edition',
						value_display_order: 14,
					},
					{
						is_extra: 0,
						key: 'MsSQL',
						key_description: 'MS SQL Server',
						key_display_order: 8,
						num_units: null,
						price: 75,
						price_delta: 75,
						price_total: 75,
						price_total_delta: 75,
						value: '2019.Standard',
						value_comments: null,
						value_description: 'SQL Server 2019 - Standard Edition',
						value_display_order: 15,
					},
					{
						is_extra: 0,
						key: 'MsSQL',
						key_description: 'MS SQL Server',
						key_display_order: 8,
						num_units: null,
						price: 300,
						price_delta: 300,
						price_total: 300,
						price_total_delta: 300,
						value: '2019.Enterprise',
						value_comments: null,
						value_description: 'SQL Server 2019 - Enterprise Edition',
						value_display_order: 16,
					},
				],
				parent_key: null,
				parent_value: null,
				price: '0.00000',
				price_total: '0.00000',
				tax: null,
				totals: { subtotal_cost: '0.00000', total_cost: '0.00000' },
				valid: 1,
				value: 'None',
				value_comments: null,
				value_description: 'No SQL Server',
				value_display_order: 1,
				value_price_time_unit: 'month',
			},
			{
				discount: '0.00000',
				extra_data: { tags: [] },
				is_extra: 0,
				key: 'Bandwidth',
				key_description: 'Bandwidth',
				key_display_order: 3,
				key_group: 'Network Options',
				key_group_display_order: 4,
				num_units: null,
				options: [
					{
						is_extra: 0,
						key: 'Bandwidth',
						key_description: 'Bandwidth',
						key_display_order: 3,
						num_units: null,
						price: 50,
						price_delta: 50,
						price_total: 50,
						price_total_delta: 50,
						value: 'SS.6000',
						value_comments: null,
						value_description: '6000 GB Monthly Transfer',
						value_display_order: 1,
					},
					{
						is_extra: 0,
						key: 'Bandwidth',
						key_description: 'Bandwidth',
						key_display_order: 3,
						num_units: null,
						price: 150,
						price_delta: 150,
						price_total: 150,
						price_total_delta: 150,
						value: 'SS.8000',
						value_comments: null,
						value_description: '8000 GB Monthly Transfer',
						value_display_order: 2,
					},
					{
						is_extra: 0,
						key: 'Bandwidth',
						key_description: 'Bandwidth',
						key_display_order: 3,
						num_units: null,
						price: 250,
						price_delta: 250,
						price_total: 250,
						price_total_delta: 250,
						value: 'SS.10000',
						value_comments: null,
						value_description: '10 TB Monthly Transfer',
						value_display_order: 3,
					},
					{
						is_extra: 0,
						key: 'Bandwidth',
						key_description: 'Bandwidth',
						key_display_order: 3,
						num_units: null,
						price: 450,
						price_delta: 450,
						price_total: 450,
						price_total_delta: 450,
						value: 'SS.15000',
						value_comments: null,
						value_description: '15 TB Monthly Transfer',
						value_display_order: 4,
					},
					{
						is_extra: 0,
						key: 'Bandwidth',
						key_description: 'Bandwidth',
						key_display_order: 3,
						num_units: null,
						price: 750,
						price_delta: 750,
						price_total: 750,
						price_total_delta: 750,
						value: 'SS.20000',
						value_comments: null,
						value_description: '20 TB Monthly Transfer',
						value_display_order: 5,
					},
				],
				parent_key: null,
				parent_value: null,
				price: '0.00000',
				price_total: '0.00000',
				tax: null,
				totals: { subtotal_cost: '0.00000', total_cost: '0.00000' },
				valid: 1,
				value: 'SS.5000',
				value_comments: null,
				value_description: '10000 GB (5000 in / 5000 out)',
				value_display_order: 0,
				value_price_time_unit: 'static-month',
			},
			{
				discount: '0.00000',
				extra_data: {
					active: 1,
					deprecated: 0,
					extra_software: 'Plesk',
					os: 'Windows',
					os_license: null,
					support_level: 'Fully-Managed',
					tags: ['Fully-Managed', 'Plesk'],
					zone_availability: { '1': 1, '40460': 1, '45412': 1, '86172': 1 },
				},
				is_extra: 0,
				key: 'Template',
				key_description: 'Server Type',
				key_display_order: 2,
				key_group: 'Server Options',
				key_group_display_order: 1,
				num_units: null,
				options: [
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'Windows',
							os_license: null,
							support_level: 'Core-Managed',
							zone_availability: { '1': 1, '40460': 1, '45412': 1, '86172': 1 },
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 1,
						price_delta: 0,
						price_total: 1,
						price_total_delta: 0,
						value: 'WINDOWS_2012R2_COREMANAGED',
						value_comments: null,
						value_description:
							'Windows Server 2012 R2 64-bit Standard Edition Core-Managed',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'Windows',
							os_license: null,
							support_level: 'Self-Managed',
							zone_availability: { '40460': 1, '45412': 1 },
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 1,
						price_delta: 0,
						price_total: 1,
						price_total_delta: 0,
						value: 'WINDOWS_2012_UNMANAGED',
						value_comments: null,
						value_description:
							'Windows 2012 64-bit Standard Edition Self-Managed',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'Windows',
							os_license: null,
							support_level: 'Core-Managed',
							zone_availability: { '40460': 1 },
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 1,
						price_delta: 0,
						price_total: 1,
						price_total_delta: 0,
						value: 'WINDOWS_2019_COREMANAGED',
						value_comments: null,
						value_description:
							'Windows Server 2019 64-bit Standard Edition CoreManaged',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'Windows',
							os_license: 'Windows2016',
							support_level: 'Core-Managed',
							zone_availability: { '1': 1, '40460': 1, '45412': 1, '86172': 1 },
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 1,
						price_delta: 0,
						price_total: 1,
						price_total_delta: 0,
						value: 'WINDOWS_2016_COREMANAGED',
						value_comments: null,
						value_description:
							'Windows Server 2016 64-bit Standard Edition Core-Managed',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'Windows',
							os_license: null,
							support_level: 'Self-Managed',
							zone_availability: { '1': 1, '40460': 1, '45412': 1, '86172': 1 },
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 99,
						price_delta: 98,
						price_total: 99,
						price_total_delta: 98,
						value: 'WINDOWS_2016_UNMANAGED',
						value_comments: null,
						value_description:
							'Windows Server 2016 64-bit Standard Edition Self-Managed',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							extra_software: 'Plesk',
							os: 'Windows',
							os_license: null,
							support_level: 'Fully-Managed',
							zone_availability: { '40460': 1 },
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 0,
						price_delta: -1,
						price_total: 0,
						price_total_delta: -1,
						value: 'WINDOWS_2019_PLESK',
						value_comments: null,
						value_description:
							'Windows Server 2019 64-bit Standard Edition with Plesk',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							extra_software: 'Plesk',
							os: 'Windows',
							os_license: null,
							support_level: 'Fully-Managed',
							zone_availability: { '40460': 1 },
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 20,
						price_delta: 19,
						price_total: 20,
						price_total_delta: 19,
						value: 'WINDOWS_2012_PLESK',
						value_comments: null,
						value_description:
							'Windows 2012 64-bit Standard Edition Fully Managed with Plesk 11.5',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							extra_software: 'Plesk',
							os: 'Windows',
							os_license: null,
							support_level: 'Fully-Managed',
							zone_availability: { '1': 1, '40460': 1, '45412': 1, '86172': 1 },
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 1,
						price_delta: 0,
						price_total: 1,
						price_total_delta: 0,
						value: 'WINDOWS_2016_PLESK',
						value_comments: null,
						value_description:
							'Windows Server 2016 64-bit Standard Edition Fully Managed with Plesk',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'Windows',
							os_license: null,
							support_level: 'Self-Managed',
							zone_availability: { '1': 1, '40460': 1, '45412': 1, '86172': 1 },
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 1,
						price_delta: 0,
						price_total: 1,
						price_total_delta: 0,
						value: 'WINDOWS_2012R2_UNMANAGED',
						value_comments: null,
						value_description:
							'Windows Server 2012 R2 64-bit Standard Edition Self-Managed',
						value_display_order: 0,
					},
				],
				parent_key: null,
				parent_value: null,
				price: '1.00000',
				price_total: '1.00000',
				tax: null,
				totals: { subtotal_cost: '1.00000', total_cost: '1.00000' },
				valid: 1,
				value: 'WINDOWS_2012R2_PLESK',
				value_comments: null,
				value_description: 'Windows Server 2012 R2 Fully Managed with Plesk 12',
				value_display_order: 0,
				value_price_time_unit: 'month',
			},
			{
				discount: '0.00000',
				extra_data: { tags: [] },
				is_extra: 0,
				key: 'LiquidWebBackupPlan',
				key_description: 'Backup Plan',
				key_display_order: null,
				key_group: 'Misc. Options',
				key_group_display_order: 2147483647,
				num_units: null,
				options: [
					{
						is_extra: 0,
						key: 'LiquidWebBackupPlan',
						key_description: 'Backup Plan',
						key_display_order: null,
						num_units: null,
						price: 0,
						price_delta: 0,
						price_total: 0,
						price_total_delta: 0,
						value: 'Daily',
						value_comments: null,
						value_description: 'As-you-go Backups',
						value_display_order: 2,
					},
					{
						is_extra: 0,
						key: 'LiquidWebBackupPlan',
						key_description: 'Backup Plan',
						key_display_order: null,
						num_units: null,
						price: 0,
						price_delta: 0,
						price_total: 0,
						price_total_delta: 0,
						value: 'Quota',
						value_comments: null,
						value_description: 'Quota-based Backups',
						value_display_order: 3,
					},
				],
				parent_key: null,
				parent_value: null,
				price: '0.00000',
				price_total: '0.00000',
				tax: null,
				totals: { subtotal_cost: '0.00000', total_cost: '0.00000' },
				valid: 1,
				value: 'None',
				value_comments: null,
				value_description: 'No Backups',
				value_display_order: 1,
				value_price_time_unit: 'month',
			},
			{
				discount: '0.00000',
				extra_data: { tags: [] },
				is_extra: 0,
				key: 'License',
				key_description: 'Licensing Options',
				key_display_order: null,
				key_group: 'Misc. Options',
				key_group_display_order: 2147483647,
				num_units: null,
				options: [
					{
						is_extra: 0,
						key: 'License',
						key_description: 'Licensing Options',
						key_display_order: null,
						num_units: null,
						price: 10,
						price_delta: -10,
						price_total: 10,
						price_total_delta: -10,
						value: 'Windows2012',
						value_comments: null,
						value_description: 'Windows 2012 License Fee',
						value_display_order: 1,
					},
				],
				parent_key: null,
				parent_value: null,
				price: '20.00000',
				price_total: '20.00000',
				tax: null,
				totals: { subtotal_cost: '20.00000', total_cost: '20.00000' },
				valid: 1,
				value: 'Windows',
				value_comments: null,
				value_description: 'Windows License',
				value_display_order: 0,
				value_price_time_unit: 'month',
			},
			{
				discount: '0.00000',
				extra_data: { tags: [] },
				is_extra: 0,
				key: 'WindowsLicense',
				key_description: 'Windows License',
				key_display_order: null,
				key_group: 'Misc. Options',
				key_group_display_order: 2147483647,
				num_units: null,
				options: [
					{
						is_extra: 0,
						key: 'WindowsLicense',
						key_description: 'Windows License',
						key_display_order: null,
						num_units: null,
						price: 20,
						price_delta: 0,
						price_total: 20,
						price_total_delta: 0,
						value: 'Windows2012',
						value_comments: null,
						value_description: 'Windows 2012 Standard',
						value_display_order: 5,
					},
					{
						is_extra: 0,
						key: 'WindowsLicense',
						key_description: 'Windows License',
						key_display_order: null,
						num_units: null,
						price: 20,
						price_delta: 0,
						price_total: 20,
						price_total_delta: 0,
						value: 'Windows2016',
						value_comments: null,
						value_description: 'Windows 2016 Standard',
						value_display_order: 10,
					},
					{
						is_extra: 0,
						key: 'WindowsLicense',
						key_description: 'Windows License',
						key_display_order: null,
						num_units: null,
						price: 30,
						price_delta: 10,
						price_total: 30,
						price_total_delta: 10,
						value: '6CoreWin',
						value_comments: null,
						value_description: 'Windows License - 6 Cores',
						value_display_order: 15,
					},
					{
						is_extra: 0,
						key: 'WindowsLicense',
						key_description: 'Windows License',
						key_display_order: null,
						num_units: null,
						price: 30,
						price_delta: 10,
						price_total: 30,
						price_total_delta: 10,
						value: '8CoreWin',
						value_comments: null,
						value_description: 'Windows License - 8 Cores',
						value_display_order: 20,
					},
					{
						is_extra: 0,
						key: 'WindowsLicense',
						key_description: 'Windows License',
						key_display_order: null,
						num_units: null,
						price: 30,
						price_delta: 10,
						price_total: 30,
						price_total_delta: 10,
						value: '4CoreWin',
						value_comments: null,
						value_description: 'Windows License - 4 Cores',
						value_display_order: 12,
					},
				],
				parent_key: null,
				parent_value: null,
				price: '20.00000',
				price_total: '20.00000',
				tax: null,
				totals: { subtotal_cost: '20.00000', total_cost: '20.00000' },
				valid: 1,
				value: 'Windows',
				value_comments: null,
				value_description: 'Windows License',
				value_display_order: 0,
				value_price_time_unit: 'month',
			},
			{
				discount: '0.00000',
				extra_data: { tags: [] },
				is_extra: 0,
				key: 'MSFTOFFICE',
				key_description: 'Microsoft Office Licensing',
				key_display_order: null,
				key_group: 'Misc. Options',
				key_group_display_order: 2147483647,
				num_units: null,
				options: [],
				parent_key: null,
				parent_value: null,
				price: '0.00000',
				price_total: '0.00000',
				tax: null,
				totals: { subtotal_cost: '0.00000', total_cost: '0.00000' },
				valid: 1,
				value: 'NoLicense',
				value_comments: null,
				value_description: 'No License Required',
				value_display_order: 0,
				value_price_time_unit: 'month',
			},
			{
				discount: '0.00000',
				extra_data: { tags: [] },
				is_extra: 0,
				key: 'MSFTLIC',
				key_description: 'Microsoft Terminal Licensing',
				key_display_order: null,
				key_group: 'Misc. Options',
				key_group_display_order: 2147483647,
				num_units: null,
				options: [],
				parent_key: null,
				parent_value: null,
				price: '25.00000',
				price_total: '25.00000',
				tax: null,
				totals: { subtotal_cost: '25.00000', total_cost: '25.00000' },
				valid: 1,
				value: 'RDPLicPack',
				value_comments: null,
				value_description: 'RDP Connection Pack Set of 5',
				value_display_order: 0,
				value_price_time_unit: 'month',
			},
			{
				discount: '0.00000',
				extra_data: { tags: [] },
				is_extra: 0,
				key: 'Acronis',
				key_description: 'Acronis Cyber Backup',
				key_display_order: 0,
				key_group: 'Storage and Backups',
				key_group_display_order: 2,
				num_units: null,
				options: [
					// cloud options have been removed for src/containers/pages/shop/ProductConfig/Group/ProductOption/Acronis/TestWrapper.jsx
					{
						is_extra: 0,
						key: 'Acronis',
						key_description: 'Acronis Cyber Backup',
						key_display_order: 0,
						num_units: null,
						price: 25,
						price_delta: 25,
						price_total: 25,
						price_total_delta: 25,
						value: 'Acronis_LW251',
						value_comments: null,
						value_description: 'Liquid Web Cloud - 250GB Storage',
						value_display_order: 5,
					},
					{
						is_extra: 0,
						key: 'Acronis',
						key_description: 'Acronis Cyber Backup',
						key_display_order: 0,
						num_units: null,
						price: 40,
						price_delta: 40,
						price_total: 40,
						price_total_delta: 40,
						value: 'Acronis_LW500',
						value_comments: null,
						value_description: 'Liquid Web Cloud - 500GB Storage',
						value_display_order: 10,
					},
					{
						is_extra: 0,
						key: 'Acronis',
						key_description: 'Acronis Cyber Backup',
						key_display_order: 0,
						num_units: null,
						price: 65,
						price_delta: 65,
						price_total: 65,
						price_total_delta: 65,
						value: 'Acronis_LW1000',
						value_comments: null,
						value_description: 'Liquid Web Cloud - 1TB Storage',
						value_display_order: 15,
					},
					{
						is_extra: 0,
						key: 'Acronis',
						key_description: 'Acronis Cyber Backup',
						key_display_order: 0,
						num_units: null,
						price: 85,
						price_delta: 85,
						price_total: 85,
						price_total_delta: 85,
						value: 'Acronis_LW1500',
						value_comments: null,
						value_description: 'Liquid Web Cloud - 1.5TB Storage',
						value_display_order: 20,
					},
					{
						is_extra: 0,
						key: 'Acronis',
						key_description: 'Acronis Cyber Backup',
						key_display_order: 0,
						num_units: null,
						price: 110,
						price_delta: 110,
						price_total: 110,
						price_total_delta: 110,
						value: 'Acronis_LW2000',
						value_comments: null,
						value_description: 'Liquid Web Cloud - 2TB Storage',
						value_display_order: 25,
					},
					{
						is_extra: 0,
						key: 'Acronis',
						key_description: 'Acronis Cyber Backup',
						key_display_order: 0,
						num_units: null,
						price: 160,
						price_delta: 160,
						price_total: 160,
						price_total_delta: 160,
						value: 'Acronis_LW3000',
						value_comments: null,
						value_description: 'Liquid Web Cloud - 3TB Storage',
						value_display_order: 35,
					},
					{
						is_extra: 0,
						key: 'Acronis',
						key_description: 'Acronis Cyber Backup',
						key_display_order: 0,
						num_units: null,
						price: 235,
						price_delta: 235,
						price_total: 235,
						price_total_delta: 235,
						value: 'Acronis_LW5000',
						value_comments: null,
						value_description: 'Liquid Web Cloud - 5TB Storage',
						value_display_order: 40,
					},
					{
						is_extra: 0,
						key: 'Acronis',
						key_description: 'Acronis Cyber Backup',
						key_display_order: 0,
						num_units: null,
						price: 135,
						price_delta: 135,
						price_total: 135,
						price_total_delta: 135,
						value: 'Acronis_LW2500',
						value_comments: null,
						value_description: 'Liquid Web Cloud - 2.5TB Storage',
						value_display_order: 30,
					},
				],
				parent_key: null,
				parent_value: null,
				price: '0.00000',
				price_total: '0.00000',
				tax: null,
				totals: { subtotal_cost: '0.00000', total_cost: '0.00000' },
				valid: 1,
				value: 'Acronis_LW250',
				value_comments: '`',
				value_description: 'No Acronis Cyber Backup Required',
				value_display_order: 0,
				value_price_time_unit: 'month',
			},
			{
				discount: '0.00000',
				extra_data: {
					category: 'ssd',
					config_id: '4',
					cores: 1,
					is_baremetal: 0,
					memory: 440,
					price: 1,
					product_code: 'SS.VPS.WIN',
					storage_size: 25,
					tags: ['TestLoad2'],
				},
				is_extra: 0,
				key: 'ConfigId',
				key_description: 'Server Type',
				key_display_order: 1,
				key_group: 'Server Options',
				key_group_display_order: 1,
				num_units: null,
				options: [
					{
						extra_data: {
							category: 'ssd',
							config_id: '1',
							cores: 2,
							is_baremetal: 0,
							memory: 1700,
							price: 1,
							product_code: 'SS.VPS.WIN',
							storage_size: 100,
						},
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 1,
						price_delta: 0,
						price_total: 1,
						price_total_delta: 0,
						value: '1',
						value_comments: null,
						value_description: '2GB',
						value_display_order: 8,
					},
				],
				parent_key: null,
				parent_value: null,
				price: '1.00000',
				price_total: '1.00000',
				tax: null,
				totals: { subtotal_cost: '1.00000', total_cost: '1.00000' },
				valid: 1,
				value: '4',
				value_comments: null,
				value_description: 'VPS 2000 Plus',
				value_display_order: 12,
				value_price_time_unit: 'month',
			},
		],
		created: '2021-06-25 05:58:48.384622-04',
		discount: '0.00000',
		extra_data: {
			available_backups: [
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4230,
					name: null,
					size: '1.94',
					template: 'CENTOS_7_UNMANAGED',
					time_taken: '2021-04-24 00:02:52',
					uniq_id: '78554A',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4237,
					name: null,
					size: '1.94',
					template: 'CENTOS_7_UNMANAGED',
					time_taken: '2021-04-25 00:02:54',
					uniq_id: '78554A',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4243,
					name: null,
					size: '1.92',
					template: 'CENTOS_7_UNMANAGED',
					time_taken: '2021-04-26 00:02:40',
					uniq_id: '78554A',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4251,
					name: null,
					size: '1.93',
					template: 'CENTOS_7_UNMANAGED',
					time_taken: '2021-04-27 00:02:54',
					uniq_id: '78554A',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4258,
					name: null,
					size: '1.94',
					template: 'CENTOS_7_UNMANAGED',
					time_taken: '2021-04-28 00:02:59',
					uniq_id: '78554A',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4266,
					name: null,
					size: '1.95',
					template: 'CENTOS_7_UNMANAGED',
					time_taken: '2021-04-29 00:21:26',
					uniq_id: '78554A',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4273,
					name: null,
					size: '1.95',
					template: 'CENTOS_7_UNMANAGED',
					time_taken: '2021-04-30 00:22:10',
					uniq_id: '78554A',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4280,
					name: null,
					size: '1.96',
					template: 'CENTOS_7_UNMANAGED',
					time_taken: '2021-05-01 00:25:52',
					uniq_id: '78554A',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4287,
					name: null,
					size: '1.86',
					template: 'CENTOS_7_UNMANAGED',
					time_taken: '2021-05-02 00:22:59',
					uniq_id: '78554A',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4294,
					name: null,
					size: '1.84',
					template: 'CENTOS_7_UNMANAGED',
					time_taken: '2021-05-03 00:24:09',
					uniq_id: '78554A',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4301,
					name: null,
					size: '1.85',
					template: 'CENTOS_7_UNMANAGED',
					time_taken: '2021-05-04 00:21:50',
					uniq_id: '78554A',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4308,
					name: null,
					size: '1.85',
					template: 'CENTOS_7_UNMANAGED',
					time_taken: '2021-05-05 00:22:59',
					uniq_id: '78554A',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4315,
					name: null,
					size: '1.86',
					template: 'CENTOS_7_UNMANAGED',
					time_taken: '2021-05-06 00:22:53',
					uniq_id: '78554A',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4357,
					name: null,
					size: '1.86',
					template: 'CENTOS_7_UNMANAGED',
					time_taken: '2021-05-07 00:21:33',
					uniq_id: '78554A',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4363,
					name: null,
					size: '1.87',
					template: 'CENTOS_7_UNMANAGED',
					time_taken: '2021-05-08 00:15:40',
					uniq_id: '78554A',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4370,
					name: null,
					size: '1.88',
					template: 'CENTOS_7_UNMANAGED',
					time_taken: '2021-05-09 00:15:43',
					uniq_id: '78554A',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4377,
					name: null,
					size: '1.86',
					template: 'CENTOS_7_UNMANAGED',
					time_taken: '2021-05-10 00:22:42',
					uniq_id: '78554A',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4384,
					name: null,
					size: '1.87',
					template: 'CENTOS_7_UNMANAGED',
					time_taken: '2021-05-11 00:15:42',
					uniq_id: '78554A',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4391,
					name: null,
					size: '1.88',
					template: 'CENTOS_7_UNMANAGED',
					time_taken: '2021-05-12 00:37:46',
					uniq_id: '78554A',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4398,
					name: null,
					size: '1.88',
					template: 'CENTOS_7_UNMANAGED',
					time_taken: '2021-05-13 00:06:32',
					uniq_id: '78554A',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4403,
					name: null,
					size: '1.89',
					template: 'CENTOS_7_UNMANAGED',
					time_taken: '2021-05-14 00:02:49',
					uniq_id: '78554A',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4409,
					name: null,
					size: '1.89',
					template: 'CENTOS_7_UNMANAGED',
					time_taken: '2021-05-15 00:03:09',
					uniq_id: '78554A',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4413,
					name: null,
					size: '1.90',
					template: 'CENTOS_7_UNMANAGED',
					time_taken: '2021-05-16 00:03:01',
					uniq_id: '78554A',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4419,
					name: null,
					size: '1.89',
					template: 'CENTOS_7_UNMANAGED',
					time_taken: '2021-05-17 00:03:13',
					uniq_id: '78554A',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4426,
					name: null,
					size: '1.90',
					template: 'CENTOS_7_UNMANAGED',
					time_taken: '2021-05-18 00:03:00',
					uniq_id: '78554A',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4433,
					name: null,
					size: '1.91',
					template: 'CENTOS_7_UNMANAGED',
					time_taken: '2021-05-19 00:03:15',
					uniq_id: '78554A',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4439,
					name: null,
					size: '1.91',
					template: 'CENTOS_7_UNMANAGED',
					time_taken: '2021-05-20 00:02:57',
					uniq_id: '78554A',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4445,
					name: null,
					size: '1.92',
					template: 'CENTOS_7_UNMANAGED',
					time_taken: '2021-05-21 00:02:57',
					uniq_id: '78554A',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4452,
					name: null,
					size: '1.93',
					template: 'CENTOS_7_UNMANAGED',
					time_taken: '2021-05-22 00:03:15',
					uniq_id: '78554A',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4457,
					name: null,
					size: '1.93',
					template: 'CENTOS_7_UNMANAGED',
					time_taken: '2021-05-23 00:02:59',
					uniq_id: '78554A',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4461,
					name: null,
					size: '1.92',
					template: 'CENTOS_7_UNMANAGED',
					time_taken: '2021-05-24 00:02:56',
					uniq_id: '78554A',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4464,
					name: null,
					size: '1.93',
					template: 'CENTOS_7_UNMANAGED',
					time_taken: '2021-05-25 17:00:33',
					uniq_id: '78554A',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4472,
					name: null,
					size: '1.93',
					template: 'CENTOS_7_UNMANAGED',
					time_taken: '2021-05-26 00:02:56',
					uniq_id: '78554A',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4478,
					name: null,
					size: '1.96',
					template: 'CENTOS_7_UNMANAGED',
					time_taken: '2021-05-27 00:03:09',
					uniq_id: '78554A',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4483,
					name: null,
					size: '1.96',
					template: 'CENTOS_7_UNMANAGED',
					time_taken: '2021-05-28 00:02:56',
					uniq_id: '78554A',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4489,
					name: null,
					size: '1.97',
					template: 'CENTOS_7_UNMANAGED',
					time_taken: '2021-05-29 00:02:58',
					uniq_id: '78554A',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4495,
					name: null,
					size: '1.98',
					template: 'CENTOS_7_UNMANAGED',
					time_taken: '2021-05-30 00:02:59',
					uniq_id: '78554A',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4501,
					name: null,
					size: '1.96',
					template: 'CENTOS_7_UNMANAGED',
					time_taken: '2021-05-31 00:02:57',
					uniq_id: '78554A',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4508,
					name: null,
					size: '1.97',
					template: 'CENTOS_7_UNMANAGED',
					time_taken: '2021-06-01 00:03:11',
					uniq_id: '78554A',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4513,
					name: null,
					size: '1.90',
					template: 'CENTOS_7_UNMANAGED',
					time_taken: '2021-06-02 00:02:56',
					uniq_id: '78554A',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4519,
					name: null,
					size: '1.91',
					template: 'CENTOS_7_UNMANAGED',
					time_taken: '2021-06-03 00:02:59',
					uniq_id: '78554A',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4525,
					name: null,
					size: '1.91',
					template: 'CENTOS_7_UNMANAGED',
					time_taken: '2021-06-04 00:02:57',
					uniq_id: '78554A',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4531,
					name: null,
					size: '1.92',
					template: 'CENTOS_7_UNMANAGED',
					time_taken: '2021-06-05 00:02:55',
					uniq_id: '78554A',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4537,
					name: null,
					size: '1.92',
					template: 'CENTOS_7_UNMANAGED',
					time_taken: '2021-06-06 00:02:55',
					uniq_id: '78554A',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4543,
					name: null,
					size: '1.91',
					template: 'CENTOS_7_UNMANAGED',
					time_taken: '2021-06-07 00:02:57',
					uniq_id: '78554A',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4549,
					name: null,
					size: '1.91',
					template: 'CENTOS_7_UNMANAGED',
					time_taken: '2021-06-08 00:02:56',
					uniq_id: '78554A',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4555,
					name: null,
					size: '1.91',
					template: 'CENTOS_7_UNMANAGED',
					time_taken: '2021-06-09 00:02:40',
					uniq_id: '78554A',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4562,
					name: null,
					size: '1.92',
					template: 'CENTOS_7_UNMANAGED',
					time_taken: '2021-06-10 00:03:01',
					uniq_id: '78554A',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4572,
					name: null,
					size: '1.93',
					template: 'CENTOS_7_UNMANAGED',
					time_taken: '2021-06-11 00:03:31',
					uniq_id: '78554A',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4579,
					name: null,
					size: '1.93',
					template: 'CENTOS_7_UNMANAGED',
					time_taken: '2021-06-12 00:03:48',
					uniq_id: '78554A',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4586,
					name: null,
					size: '1.94',
					template: 'CENTOS_7_UNMANAGED',
					time_taken: '2021-06-13 00:03:39',
					uniq_id: '78554A',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4593,
					name: null,
					size: '1.91',
					template: 'CENTOS_7_UNMANAGED',
					time_taken: '2021-06-14 00:03:40',
					uniq_id: '78554A',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4613,
					name: null,
					size: '1.93',
					template: 'CENTOS_7_UNMANAGED',
					time_taken: '2021-06-17 00:03:26',
					uniq_id: '78554A',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4621,
					name: null,
					size: '1.94',
					template: 'CENTOS_7_UNMANAGED',
					time_taken: '2021-06-18 00:03:30',
					uniq_id: '78554A',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4628,
					name: null,
					size: '1.95',
					template: 'CENTOS_7_UNMANAGED',
					time_taken: '2021-06-19 00:03:37',
					uniq_id: '78554A',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4655,
					name: null,
					size: '1.94',
					template: 'CENTOS_7_UNMANAGED',
					time_taken: '2021-06-23 00:03:28',
					uniq_id: '78554A',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4662,
					name: null,
					size: '1.94',
					template: 'CENTOS_7_UNMANAGED',
					time_taken: '2021-06-24 00:03:29',
					uniq_id: '78554A',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4670,
					name: null,
					size: '1.94',
					template: 'CENTOS_7_UNMANAGED',
					time_taken: '2021-06-25 00:03:51',
					uniq_id: '78554A',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4627,
					name: null,
					size: '28.84',
					template: 'WINDOWS_2016_PLESK',
					time_taken: '2021-06-19 00:03:23',
					uniq_id: 'AU4P17',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4634,
					name: null,
					size: '28.82',
					template: 'WINDOWS_2016_PLESK',
					time_taken: '2021-06-20 00:03:17',
					uniq_id: 'AU4P17',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4641,
					name: null,
					size: '28.85',
					template: 'WINDOWS_2016_PLESK',
					time_taken: '2021-06-21 00:03:17',
					uniq_id: 'AU4P17',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4648,
					name: null,
					size: '28.86',
					template: 'WINDOWS_2016_PLESK',
					time_taken: '2021-06-22 00:03:19',
					uniq_id: 'AU4P17',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4656,
					name: null,
					size: '28.89',
					template: 'WINDOWS_2016_PLESK',
					time_taken: '2021-06-23 00:03:42',
					uniq_id: 'AU4P17',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4663,
					name: null,
					size: '28.87',
					template: 'WINDOWS_2016_PLESK',
					time_taken: '2021-06-24 00:03:42',
					uniq_id: 'AU4P17',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4669,
					name: null,
					size: '28.90',
					template: 'WINDOWS_2016_PLESK',
					time_taken: '2021-06-25 00:03:37',
					uniq_id: 'AU4P17',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4645,
					name: null,
					size: '9.80',
					template: 'CENTOS_7_CPANEL_CLOUDLINUX',
					time_taken: '2021-06-21 00:32:39',
					uniq_id: 'JADPA5',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4652,
					name: null,
					size: '9.82',
					template: 'CENTOS_7_CPANEL_CLOUDLINUX',
					time_taken: '2021-06-22 00:32:43',
					uniq_id: 'JADPA5',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4659,
					name: null,
					size: '9.86',
					template: 'CENTOS_7_CPANEL_CLOUDLINUX',
					time_taken: '2021-06-23 00:32:41',
					uniq_id: 'JADPA5',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4666,
					name: null,
					size: '9.90',
					template: 'CENTOS_7_CPANEL_CLOUDLINUX',
					time_taken: '2021-06-24 00:32:41',
					uniq_id: 'JADPA5',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: 4673,
					name: null,
					size: '9.93',
					template: 'CENTOS_7_CPANEL_CLOUDLINUX',
					time_taken: '2021-06-25 00:32:43',
					uniq_id: 'JADPA5',
				},
			],
			available_images: [
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: '1',
					name: 'test image 2',
					size: '1.46',
					source_hostname: 'host.backupsandimagestest.com',
					source_uniq_id: '78554A',
					template: 'CENTOS_7_UNMANAGED',
					template_description: 'CentOS 7 64-bit Self-managed',
					time_taken: '2020-01-14 16:25:06',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: '2',
					name: 'image2',
					size: '7.13',
					source_hostname: 'paypergigbackups.testing.com',
					source_uniq_id: '',
					template: 'CENTOS_7_CPANEL_CLOUDLINUX',
					template_description: 'CentOS 7 64-bit cPanel w/CloudLinux',
					time_taken: '2020-01-16 16:53:47',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: '4',
					name: 'testing a rename',
					size: '1.50',
					source_hostname: 'host.backupsandimagestest.com',
					source_uniq_id: '78554A',
					template: 'CENTOS_7_UNMANAGED',
					template_description: 'CentOS 7 64-bit Self-managed',
					time_taken: '2020-01-20 11:45:59',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: '10',
					name: 'givemeaname',
					size: '1.50',
					source_hostname: 'host.backupsandimagestest.com',
					source_uniq_id: '78554A',
					template: 'CENTOS_7_UNMANAGED',
					template_description: 'CentOS 7 64-bit Self-managed',
					time_taken: '2020-01-21 17:43:39',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: '11',
					name: 'create image name',
					size: '1.50',
					source_hostname: 'host.backupsandimagestest.com',
					source_uniq_id: '78554A',
					template: 'CENTOS_7_UNMANAGED',
					template_description: 'CentOS 7 64-bit Self-managed',
					time_taken: '2020-01-21 18:01:32',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: '13',
					name: 'new image',
					size: '1.51',
					source_hostname: 'host.backupsandimagestest.com',
					source_uniq_id: '78554A',
					template: 'CENTOS_7_UNMANAGED',
					template_description: 'CentOS 7 64-bit Self-managed',
					time_taken: '2020-01-23 14:08:27',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: '14',
					name: 'newimage test',
					size: '1.56',
					source_hostname: 'child.mctesterson.com',
					source_uniq_id: 'UQZ3HX',
					template: 'CENTOS_7_UNMANAGED',
					template_description: 'CentOS 7 64-bit Self-managed',
					time_taken: '2020-02-05 10:23:57',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: '18',
					name: 'sdafds',
					size: '1.51',
					source_hostname: 'host.backupsandimagestest.com',
					source_uniq_id: '78554A',
					template: 'CENTOS_7_UNMANAGED',
					template_description: 'CentOS 7 64-bit Self-managed',
					time_taken: '2020-04-20 12:22:24',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: '19',
					name: '---',
					size: '1.51',
					source_hostname: 'host.backupsandimagestest.com',
					source_uniq_id: '78554A',
					template: 'CENTOS_7_UNMANAGED',
					template_description: 'CentOS 7 64-bit Self-managed',
					time_taken: '2020-04-20 12:24:58',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: '20',
					name: 'test image 2',
					size: '1.51',
					source_hostname: 'host.backupsandimagestest.com',
					source_uniq_id: '78554A',
					template: 'CENTOS_7_UNMANAGED',
					template_description: 'CentOS 7 64-bit Self-managed',
					time_taken: '2020-04-20 12:29:42',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: '21',
					name: 'aaadsfsdf',
					size: '1.53',
					source_hostname: 'host.backupsandimagestest.com',
					source_uniq_id: '78554A',
					template: 'CENTOS_7_UNMANAGED',
					template_description: 'CentOS 7 64-bit Self-managed',
					time_taken: '2020-04-24 22:58:01',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: '22',
					name: 'asdfasdfds',
					size: '1.53',
					source_hostname: 'host.backupsandimagestest.com',
					source_uniq_id: '78554A',
					template: 'CENTOS_7_UNMANAGED',
					template_description: 'CentOS 7 64-bit Self-managed',
					time_taken: '2020-04-25 11:12:49',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: '23',
					name: 'sdfsdf',
					size: '1.53',
					source_hostname: 'host.backupsandimagestest.com',
					source_uniq_id: '78554A',
					template: 'CENTOS_7_UNMANAGED',
					template_description: 'CentOS 7 64-bit Self-managed',
					time_taken: '2020-04-25 11:15:03',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: '24',
					name: 'asdfsdf',
					size: '1.53',
					source_hostname: 'host.backupsandimagestest.com',
					source_uniq_id: '78554A',
					template: 'CENTOS_7_UNMANAGED',
					template_description: 'CentOS 7 64-bit Self-managed',
					time_taken: '2020-04-25 11:17:31',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: '25',
					name: 'asdf',
					size: '1.53',
					source_hostname: 'host.backupsandimagestest.com',
					source_uniq_id: '78554A',
					template: 'CENTOS_7_UNMANAGED',
					template_description: 'CentOS 7 64-bit Self-managed',
					time_taken: '2020-04-25 11:18:20',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: '26',
					name: 'zzzz',
					size: '1.59',
					source_hostname: 'clouddedi.mctesterson.com',
					source_uniq_id: '',
					template: 'CENTOS_7_UNMANAGED',
					template_description: 'CentOS 7 64-bit Self-managed',
					time_taken: '2020-04-25 11:35:59',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: '27',
					name: 'aaaaa',
					size: '1.59',
					source_hostname: 'clouddedi.mctesterson.com',
					source_uniq_id: '',
					template: 'CENTOS_7_UNMANAGED',
					template_description: 'CentOS 7 64-bit Self-managed',
					time_taken: '2020-04-29 14:49:14',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: '28',
					name: 'asdfsdfdsfa',
					size: '1.59',
					source_hostname: 'clouddedi.mctesterson.com',
					source_uniq_id: '',
					template: 'CENTOS_7_UNMANAGED',
					template_description: 'CentOS 7 64-bit Self-managed',
					time_taken: '2020-04-29 14:50:44',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: '29',
					name: 'dsafasdf',
					size: '1.59',
					source_hostname: 'clouddedi.mctesterson.com',
					source_uniq_id: '',
					template: 'CENTOS_7_UNMANAGED',
					template_description: 'CentOS 7 64-bit Self-managed',
					time_taken: '2020-04-29 14:52:49',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: '30',
					name: 'asdfsd',
					size: '1.59',
					source_hostname: 'clouddedi.mctesterson.com',
					source_uniq_id: '',
					template: 'CENTOS_7_UNMANAGED',
					template_description: 'CentOS 7 64-bit Self-managed',
					time_taken: '2020-04-29 14:53:55',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: '31',
					name: 'gggg',
					size: '1.59',
					source_hostname: 'clouddedi.mctesterson.com',
					source_uniq_id: '',
					template: 'CENTOS_7_UNMANAGED',
					template_description: 'CentOS 7 64-bit Self-managed',
					time_taken: '2020-04-29 14:57:17',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: '32',
					name: 'sfdsd',
					size: '1.59',
					source_hostname: 'clouddedi.mctesterson.com',
					source_uniq_id: '',
					template: 'CENTOS_7_UNMANAGED',
					template_description: 'CentOS 7 64-bit Self-managed',
					time_taken: '2020-04-29 14:58:29',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: '33',
					name: 'sdfdfd',
					size: '1.59',
					source_hostname: 'clouddedi.mctesterson.com',
					source_uniq_id: '',
					template: 'CENTOS_7_UNMANAGED',
					template_description: 'CentOS 7 64-bit Self-managed',
					time_taken: '2020-04-29 14:59:44',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: '34',
					name: 'asdfds',
					size: '1.59',
					source_hostname: 'clouddedi.mctesterson.com',
					source_uniq_id: '',
					template: 'CENTOS_7_UNMANAGED',
					template_description: 'CentOS 7 64-bit Self-managed',
					time_taken: '2020-04-29 15:31:15',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: '35',
					name: 'z',
					size: '1.59',
					source_hostname: 'clouddedi.mctesterson.com',
					source_uniq_id: '',
					template: 'CENTOS_7_UNMANAGED',
					template_description: 'CentOS 7 64-bit Self-managed',
					time_taken: '2020-04-29 15:35:06',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: '36',
					name: 'sdfdsf',
					size: '1.59',
					source_hostname: 'clouddedi.mctesterson.com',
					source_uniq_id: '',
					template: 'CENTOS_7_UNMANAGED',
					template_description: 'CentOS 7 64-bit Self-managed',
					time_taken: '2020-04-29 15:38:52',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: '37',
					name: 'sfsd',
					size: '1.59',
					source_hostname: 'clouddedi.mctesterson.com',
					source_uniq_id: '',
					template: 'CENTOS_7_UNMANAGED',
					template_description: 'CentOS 7 64-bit Self-managed',
					time_taken: '2020-04-29 15:43:04',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: '38',
					name: 'xcvxc',
					size: '1.59',
					source_hostname: 'clouddedi.mctesterson.com',
					source_uniq_id: '',
					template: 'CENTOS_7_UNMANAGED',
					template_description: 'CentOS 7 64-bit Self-managed',
					time_taken: '2020-04-29 15:44:35',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: '39',
					name: 'f',
					size: '1.59',
					source_hostname: 'clouddedi.mctesterson.com',
					source_uniq_id: '',
					template: 'CENTOS_7_UNMANAGED',
					template_description: 'CentOS 7 64-bit Self-managed',
					time_taken: '2020-04-29 15:46:04',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: '40',
					name: 'xb',
					size: '1.59',
					source_hostname: 'clouddedi.mctesterson.com',
					source_uniq_id: '',
					template: 'CENTOS_7_UNMANAGED',
					template_description: 'CentOS 7 64-bit Self-managed',
					time_taken: '2020-04-29 15:48:40',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: '41',
					name: 'uy',
					size: '1.59',
					source_hostname: 'clouddedi.mctesterson.com',
					source_uniq_id: '',
					template: 'CENTOS_7_UNMANAGED',
					template_description: 'CentOS 7 64-bit Self-managed',
					time_taken: '2020-04-29 15:56:34',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: '42',
					name: 'v',
					size: '1.59',
					source_hostname: 'clouddedi.mctesterson.com',
					source_uniq_id: '',
					template: 'CENTOS_7_UNMANAGED',
					template_description: 'CentOS 7 64-bit Self-managed',
					time_taken: '2020-04-29 15:57:58',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: '43',
					name: 'sdsdsd',
					size: '1.59',
					source_hostname: 'clouddedi.mctesterson.com',
					source_uniq_id: '',
					template: 'CENTOS_7_UNMANAGED',
					template_description: 'CentOS 7 64-bit Self-managed',
					time_taken: '2020-04-29 16:03:12',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: '44',
					name: 'xcv',
					size: '1.59',
					source_hostname: 'clouddedi.mctesterson.com',
					source_uniq_id: '',
					template: 'CENTOS_7_UNMANAGED',
					template_description: 'CentOS 7 64-bit Self-managed',
					time_taken: '2020-04-29 16:05:23',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: '45',
					name: 'asdf',
					size: '1.59',
					source_hostname: 'clouddedi.mctesterson.com',
					source_uniq_id: '',
					template: 'CENTOS_7_UNMANAGED',
					template_description: 'CentOS 7 64-bit Self-managed',
					time_taken: '2020-05-05 11:44:58',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: '46',
					name: 'adsf',
					size: '1.59',
					source_hostname: 'clouddedi.mctesterson.com',
					source_uniq_id: '',
					template: 'CENTOS_7_UNMANAGED',
					template_description: 'CentOS 7 64-bit Self-managed',
					time_taken: '2020-05-05 12:18:56',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: '47',
					name: 'asdf',
					size: '1.59',
					source_hostname: 'clouddedi.mctesterson.com',
					source_uniq_id: '',
					template: 'CENTOS_7_UNMANAGED',
					template_description: 'CentOS 7 64-bit Self-managed',
					time_taken: '2020-05-05 12:20:20',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: '48',
					name: 's',
					size: '1.59',
					source_hostname: 'clouddedi.mctesterson.com',
					source_uniq_id: '',
					template: 'CENTOS_7_UNMANAGED',
					template_description: 'CentOS 7 64-bit Self-managed',
					time_taken: '2020-05-05 12:21:59',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: '49',
					name: 'zzz',
					size: '1.59',
					source_hostname: 'clouddedi.mctesterson.com',
					source_uniq_id: '',
					template: 'CENTOS_7_UNMANAGED',
					template_description: 'CentOS 7 64-bit Self-managed',
					time_taken: '2020-05-05 12:25:04',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: '50',
					name: 'sdf',
					size: '1.59',
					source_hostname: 'clouddedi.mctesterson.com',
					source_uniq_id: '',
					template: 'CENTOS_7_UNMANAGED',
					template_description: 'CentOS 7 64-bit Self-managed',
					time_taken: '2020-05-05 13:10:22',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: '51',
					name: 'asdf',
					size: '1.59',
					source_hostname: 'clouddedi.mctesterson.com',
					source_uniq_id: '',
					template: 'CENTOS_7_UNMANAGED',
					template_description: 'CentOS 7 64-bit Self-managed',
					time_taken: '2020-05-05 13:14:31',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: '52',
					name: 'A',
					size: '1.59',
					source_hostname: 'clouddedi.mctesterson.com',
					source_uniq_id: '',
					template: 'CENTOS_7_UNMANAGED',
					template_description: 'CentOS 7 64-bit Self-managed',
					time_taken: '2020-05-05 13:19:12',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: '53',
					name: 'ss',
					size: '1.59',
					source_hostname: 'clouddedi.mctesterson.com',
					source_uniq_id: '',
					template: 'CENTOS_7_UNMANAGED',
					template_description: 'CentOS 7 64-bit Self-managed',
					time_taken: '2020-05-05 13:23:30',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: '54',
					name: 'ff',
					size: '1.59',
					source_hostname: 'clouddedi.mctesterson.com',
					source_uniq_id: '',
					template: 'CENTOS_7_UNMANAGED',
					template_description: 'CentOS 7 64-bit Self-managed',
					time_taken: '2020-05-05 13:24:57',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: '55',
					name: 'dfg',
					size: '1.59',
					source_hostname: 'clouddedi.mctesterson.com',
					source_uniq_id: '',
					template: 'CENTOS_7_UNMANAGED',
					template_description: 'CentOS 7 64-bit Self-managed',
					time_taken: '2020-05-05 13:27:00',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: '56',
					name: 'aa',
					size: '1.59',
					source_hostname: 'clouddedi.mctesterson.com',
					source_uniq_id: '',
					template: 'CENTOS_7_UNMANAGED',
					template_description: 'CentOS 7 64-bit Self-managed',
					time_taken: '2020-05-05 13:48:35',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: '57',
					name: 'sdfdsf',
					size: '1.59',
					source_hostname: 'clouddedi.mctesterson.com',
					source_uniq_id: '',
					template: 'CENTOS_7_UNMANAGED',
					template_description: 'CentOS 7 64-bit Self-managed',
					time_taken: '2020-05-05 13:50:05',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: '58',
					name: 'adf',
					size: '1.59',
					source_hostname: 'clouddedi.mctesterson.com',
					source_uniq_id: '',
					template: 'CENTOS_7_UNMANAGED',
					template_description: 'CentOS 7 64-bit Self-managed',
					time_taken: '2020-05-05 13:52:31',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: '59',
					name: 'adsf',
					size: '1.59',
					source_hostname: 'clouddedi.mctesterson.com',
					source_uniq_id: '',
					template: 'CENTOS_7_UNMANAGED',
					template_description: 'CentOS 7 64-bit Self-managed',
					time_taken: '2020-05-05 13:53:42',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: '60',
					name: 'asdf',
					size: '1.59',
					source_hostname: 'clouddedi.mctesterson.com',
					source_uniq_id: '',
					template: 'CENTOS_7_UNMANAGED',
					template_description: 'CentOS 7 64-bit Self-managed',
					time_taken: '2020-05-05 14:01:01',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: '61',
					name: 'sdf',
					size: '1.59',
					source_hostname: 'clouddedi.mctesterson.com',
					source_uniq_id: '',
					template: 'CENTOS_7_UNMANAGED',
					template_description: 'CentOS 7 64-bit Self-managed',
					time_taken: '2020-05-05 14:03:19',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: '62',
					name: 'fdn',
					size: '1.59',
					source_hostname: 'clouddedi.mctesterson.com',
					source_uniq_id: '',
					template: 'CENTOS_7_UNMANAGED',
					template_description: 'CentOS 7 64-bit Self-managed',
					time_taken: '2020-05-06 10:35:14',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: '65',
					name: 'my image is special',
					size: '7.57',
					source_hostname: 'cpanel.mctestersons.com',
					source_uniq_id: '',
					template: 'CENTOS_7_CPANEL',
					template_description: 'CentOS 7 64-bit with cPanel Fully-managed',
					time_taken: '2020-07-15 09:42:24',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: '69',
					name: 'test-cbulock',
					size: '7.61',
					source_hostname: 'cpanel.mctestersons.com',
					source_uniq_id: '',
					template: 'CENTOS_7_CPANEL',
					template_description: 'CentOS 7 64-bit with cPanel Fully-managed',
					time_taken: '2021-04-12 16:34:26',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: '70',
					name: 'testing-cbulock3',
					size: '7.60',
					source_hostname: 'cpanel.mctestersons.com',
					source_uniq_id: '',
					template: 'CENTOS_7_CPANEL',
					template_description: 'CentOS 7 64-bit with cPanel Fully-managed',
					time_taken: '2021-04-12 17:48:02',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: '71',
					name: 'testing4-cbulock',
					size: '7.60',
					source_hostname: 'cpanel.mctestersons.com',
					source_uniq_id: '',
					template: 'CENTOS_7_CPANEL',
					template_description: 'CentOS 7 64-bit with cPanel Fully-managed',
					time_taken: '2021-04-12 17:54:22',
				},
				{
					accnt: 3378300,
					hv_type: 'kvm',
					id: '72',
					name: 'beepboop',
					size: '7.62',
					source_hostname: 'cpanel.mctestersons.com',
					source_uniq_id: '',
					template: 'CENTOS_7_CPANEL',
					template_description: 'CentOS 7 64-bit with cPanel Fully-managed',
					time_taken: '2021-04-13 16:50:21',
				},
			],
			available_parents: [
				{
					accnt: 3378300,
					bucket_uniq_id: null,
					config_description:
						"'Intel(R) Xeon(R) CPU           E5620  @ 2.40GHz', 2400MHz, 4 Core, Sockets: 2",
					config_id: 7,
					create_date: '2020-01-28 12:01:35',
					diskDetails: { allocated: 195, snapshots: 93 },
					domain: 'privateparent',
					id: '12424845',
					license_state: 'mixed',
					project_name: null,
					region_id: 1,
					resources: {
						diskspace: { free: 1561, total: 1849, used: 288 },
						memory: { free: 25717, total: 32229, used: 6512 },
					},
					salesforce_asset: '02i2F000003L6NwQAK',
					status: 'Active',
					subaccnt: '12424845',
					type: 'SS.PP',
					uniq_id: 'Z8064R',
					vcpu: 16,
					zone: {
						availability_zone: 'C',
						description: 'Zone C',
						hv_type: 'kvm',
						id: 40460,
						legacy: 0,
						name: 'b3s7z1',
						region: { id: 1, name: 'US Central' },
						status: 'open',
						valid_source_hvs: ['xen', 'kvm'],
					},
				},
			],
			ip_pool: {
				subaccnt: 'E6WU20',
				unused_assignments: [
					{
						begin_range: '67.43.15.5',
						broadcast: '67.43.15.63',
						end_range: '67.43.15.5',
						gateway: '67.43.15.1',
						id: 5208056,
						ip: '67.43.15.5',
						is_legacy_storm: 1,
						is_storm_anchor: 0,
						netmask: '255.255.255.192',
						network: '67.43.15.5/32',
						zone_id: 40460,
					},
					{
						begin_range: '67.43.15.6',
						broadcast: '67.43.15.63',
						end_range: '67.43.15.6',
						gateway: '67.43.15.1',
						id: 5208057,
						ip: '67.43.15.6',
						is_legacy_storm: 1,
						is_storm_anchor: 0,
						netmask: '255.255.255.192',
						network: '67.43.15.6/32',
						zone_id: 40460,
					},
					{
						begin_range: '67.43.15.12',
						broadcast: '67.43.15.63',
						end_range: '67.43.15.12',
						gateway: '67.43.15.1',
						id: 4807057,
						ip: '67.43.15.12',
						is_legacy_storm: 1,
						is_storm_anchor: 0,
						netmask: '255.255.255.192',
						network: '67.43.15.12/32',
						zone_id: 40460,
					},
					{
						begin_range: '67.43.15.15',
						broadcast: '67.43.15.63',
						end_range: '67.43.15.15',
						gateway: '67.43.15.1',
						id: 5208058,
						ip: '67.43.15.15',
						is_legacy_storm: 1,
						is_storm_anchor: 0,
						netmask: '255.255.255.192',
						network: '67.43.15.15/32',
						zone_id: 40460,
					},
					{
						begin_range: '67.43.15.27',
						broadcast: '67.43.15.63',
						end_range: '67.43.15.27',
						gateway: '67.43.15.1',
						id: 5208059,
						ip: '67.43.15.27',
						is_legacy_storm: 1,
						is_storm_anchor: 0,
						netmask: '255.255.255.192',
						network: '67.43.15.27/32',
						zone_id: 40460,
					},
					{
						begin_range: '67.43.15.31',
						broadcast: '67.43.15.63',
						end_range: '67.43.15.31',
						gateway: '67.43.15.1',
						id: 5208060,
						ip: '67.43.15.31',
						is_legacy_storm: 1,
						is_storm_anchor: 0,
						netmask: '255.255.255.192',
						network: '67.43.15.31/32',
						zone_id: 40460,
					},
					{
						begin_range: '67.43.15.33',
						broadcast: '67.43.15.63',
						end_range: '67.43.15.33',
						gateway: '67.43.15.1',
						id: 5208061,
						ip: '67.43.15.33',
						is_legacy_storm: 1,
						is_storm_anchor: 0,
						netmask: '255.255.255.192',
						network: '67.43.15.33/32',
						zone_id: 40460,
					},
					{
						begin_range: '67.43.15.34',
						broadcast: '67.43.15.63',
						end_range: '67.43.15.34',
						gateway: '67.43.15.1',
						id: 5208062,
						ip: '67.43.15.34',
						is_legacy_storm: 1,
						is_storm_anchor: 0,
						netmask: '255.255.255.192',
						network: '67.43.15.34/32',
						zone_id: 40460,
					},
					{
						begin_range: '67.43.15.35',
						broadcast: '67.43.15.63',
						end_range: '67.43.15.35',
						gateway: '67.43.15.1',
						id: 5208063,
						ip: '67.43.15.35',
						is_legacy_storm: 1,
						is_storm_anchor: 0,
						netmask: '255.255.255.192',
						network: '67.43.15.35/32',
						zone_id: 40460,
					},
					{
						begin_range: '67.43.15.38',
						broadcast: '67.43.15.63',
						end_range: '67.43.15.38',
						gateway: '67.43.15.1',
						id: 5208064,
						ip: '67.43.15.38',
						is_legacy_storm: 1,
						is_storm_anchor: 0,
						netmask: '255.255.255.192',
						network: '67.43.15.38/32',
						zone_id: 40460,
					},
					{
						begin_range: '67.43.15.39',
						broadcast: '67.43.15.63',
						end_range: '67.43.15.39',
						gateway: '67.43.15.1',
						id: 5208065,
						ip: '67.43.15.39',
						is_legacy_storm: 1,
						is_storm_anchor: 0,
						netmask: '255.255.255.192',
						network: '67.43.15.39/32',
						zone_id: 40460,
					},
				],
			},
		},
		productInfo: {
			alias: null,
			capabilities: {
				ATSLogin: 0,
				autoAddIp: 1,
				autoPrivateNetworking: 1,
				autoReboot: 1,
				autoRemoveIp: 1,
				autoRename: 1,
				autoShutdown: 1,
				bandwidthStats: 1,
				canAcronisBackup: 1,
				canHotplugRamVcpu: 1,
				canThreatStack: 1,
				canUseSpiceConsole: 1,
				changeBWOptions: 1,
				clone: 1,
				create: 1,
				credentials: 1,
				destroy: 1,
				emailable: 1,
				extraAuth: 1,
				guardian: 1,
				image: 1,
				manAddIp: 1,
				manReboot: 1,
				manRemoveIp: 1,
				managed: 1,
				multiplePublicIPs: 1,
				networking: 1,
				presentable: 1,
				provBackup: 1,
				provFirewall: 1,
				reimage: 1,
				rescueBoot: 1,
				resize: 1,
				restoreFromTemplate: 1,
				reverseDNSEdit: 1,
				rootLogin: 0,
				serviceMonitoring: 1,
				serviceMonitoringBasic: 1,
				serviceMonitoringDns: 1,
				serviceMonitoringLinux: 0,
				serviceMonitoringMachineMysqld: 1,
				serviceMonitoringWin: 1,
				sonar: 0,
				volumeAttach: 1,
				volumeDetach: 1,
				webConsole: 1,
			},
			credit_eligible: 1,
			cycle: 'month',
			discountable: 1,
			product_code: 'SS.VPS.WIN',
			product_description: 'LiquidWeb Windows Smart VPS',
			product_display_group: 'Storm VPS',
			sub_title: 'A subtitle',
			tax_code: null,
			title: 'Stormy VPS Windoze',
		},
		product_code: 'SS.VPS.WIN',
		properties: {
			account_default_region: 1,
			account_default_zone: '45412',
			zone: '40460',
			// 52LWYF is a linux server: TODO: get a widows server into our asset/details test dat and use that uniq_id instead.
			uniq_id_to_clone: '52LWYF', // TODO: NEWMAN-1782, get real property from BE. (I made up uniq_id_to_clone)
		},
		region: 1,
		tax: null,
		totals: {
			configs_discount_total: '0.00000',
			configs_price_total: '77.00000',
			configs_subtotal: '77.00000',
			configs_tax_total: '0',
			configs_total_cost: '77.00000',
			item_discount_total: '0.00000',
			item_prepay_auto_renew: null,
			item_prepay_discount: null,
			item_prepay_once: null,
			item_prepay_ongoing: null,
			item_prepay_term_max: null,
			item_price_total: '77.00000',
			item_setup_fee: '0',
			item_subtotal: '77.00000',
			item_tax_total: '0',
			item_total_cost: '77.00000',
			subtotal_base_cost: '0.00000',
			total_base_cost: '0.00000',
		},
		uuid: 'f1fbe9cb-5a11-4a27-942b-36e4286427c9',
		valid: 1,
	},
	'0b5fb0f9-0fea-4b66-a859-6b0cdb044cd7': {
		base_price: '0.00000',
		basket_uuid: 'a712600a-66de-4ce4-9b9e-7177f208cd86',
		configs: [
			{
				discount: '0.00000',
				extra_data: {
					tags: [],
				},
				is_extra: 0,
				key: 'BandwidthOverage',
				key_description: 'Bandwidth Overage',
				key_display_order: 4,
				key_group: 'Misc. Options',
				key_group_display_order: 2147483647,
				num_units: -1,
				options: [],
				parent_key: null,
				parent_value: null,
				price: '0.25000',
				price_total: '0.00000',
				tax: null,
				totals: {
					subtotal_cost: '0.00000',
					total_cost: '0.00000',
				},
				valid: 1,
				value: '1GB',
				value_comments: null,
				value_description: '%{units} GB Overage',
				value_display_order: 1,
				value_price_time_unit: 'nontemporal',
			},
			{
				discount: '0.00000',
				extra_data: {
					tags: [],
				},
				is_extra: 0,
				key: 'LiquidWebBackupPlan',
				key_description: 'Backup Plan',
				key_display_order: 5,
				key_group: 'Misc. Options',
				key_group_display_order: 2147483647,
				num_units: null,
				options: [
					{
						is_extra: 0,
						key: 'LiquidWebBackupPlan',
						key_description: 'Backup Plan',
						key_display_order: 5,
						num_units: null,
						price: 0,
						price_delta: 0,
						price_total: 0,
						price_total_delta: 0,
						value: 'None',
						value_comments: null,
						value_description: 'No Backups',
						value_display_order: 1,
					},
					{
						is_extra: 0,
						key: 'LiquidWebBackupPlan',
						key_description: 'Backup Plan',
						key_display_order: 5,
						num_units: null,
						price: 0,
						price_delta: 0,
						price_total: 0,
						price_total_delta: 0,
						value: 'Daily',
						value_comments: null,
						value_description: 'As-you-go Backups',
						value_display_order: 2,
					},
				],
				parent_key: null,
				parent_value: null,
				price: '0.00000',
				price_total: '0.00000',
				tax: null,
				totals: {
					subtotal_cost: '0.00000',
					total_cost: '0.00000',
				},
				valid: 1,
				value: 'Quota',
				value_comments: null,
				value_description: 'Quota-based Backups',
				value_display_order: 3,
				value_price_time_unit: 'month',
			},
			{
				discount: '0.00000',
				extra_data: {
					tags: [],
				},
				is_extra: 0,
				key: 'ExtraIp',
				key_description: 'Extra IP Addresses',
				key_display_order: 6,
				key_group: 'Network Options',
				key_group_display_order: 4,
				num_units: 0,
				options: [],
				parent_key: null,
				parent_value: null,
				price: '1.99000',
				price_total: '0.00000',
				tax: null,
				totals: {
					subtotal_cost: '0.00000',
					total_cost: '0.00000',
				},
				valid: 1,
				value: '1',
				value_comments: null,
				value_description: '%{units} Additional Public IPs',
				value_display_order: 1,
				value_price_time_unit: 'month',
			},
			{
				discount: '0.00000',
				extra_data: {
					tags: [],
				},
				is_extra: 0,
				key: 'BackupQuota',
				key_description: 'Quota Pricing',
				key_display_order: 1,
				key_group: 'Misc. Options',
				key_group_display_order: 2147483647,
				num_units: null,
				options: [
					{
						is_extra: 0,
						key: 'BackupQuota',
						key_description: 'Quota Pricing',
						key_display_order: 1,
						num_units: null,
						parent_key: 'LiquidWebBackupPlan',
						parent_value: 'Quota',
						price: 23,
						price_delta: 13,
						price_total: 23,
						price_total_delta: 13,
						value: '250',
						value_comments: null,
						value_description: '250 GB',
						value_display_order: 10,
					},
					{
						is_extra: 0,
						key: 'BackupQuota',
						key_description: 'Quota Pricing',
						key_display_order: 1,
						num_units: null,
						parent_key: 'LiquidWebBackupPlan',
						parent_value: 'Quota',
						price: 45,
						price_delta: 35,
						price_total: 45,
						price_total_delta: 35,
						value: '500',
						value_comments: null,
						value_description: '500 GB',
						value_display_order: 15,
					},
					{
						is_extra: 0,
						key: 'BackupQuota',
						key_description: 'Quota Pricing',
						key_display_order: 1,
						num_units: null,
						parent_key: 'LiquidWebBackupPlan',
						parent_value: 'Quota',
						price: 70,
						price_delta: 60,
						price_total: 70,
						price_total_delta: 60,
						value: '1000',
						value_comments: null,
						value_description: '1000 GB',
						value_display_order: 20,
					},
					{
						is_extra: 0,
						key: 'BackupQuota',
						key_description: 'Quota Pricing',
						key_display_order: 1,
						num_units: null,
						parent_key: 'LiquidWebBackupPlan',
						parent_value: 'Quota',
						price: 130,
						price_delta: 120,
						price_total: 130,
						price_total_delta: 120,
						value: '2000',
						value_comments: null,
						value_description: '2000 GB',
						value_display_order: 25,
					},
					{
						is_extra: 0,
						key: 'BackupQuota',
						key_description: 'Quota Pricing',
						key_display_order: 1,
						num_units: null,
						parent_key: 'LiquidWebBackupPlan',
						parent_value: 'Quota',
						price: 0,
						price_delta: -10,
						price_total: 0,
						price_total_delta: -10,
						value: '50',
						value_comments: null,
						value_description: '50 GB',
						value_display_order: 1,
					},
				],
				parent_key: 'LiquidWebBackupPlan',
				parent_value: 'Quota',
				price: '10.00000',
				price_total: '10.00000',
				tax: null,
				totals: {
					subtotal_cost: '10.00000',
					total_cost: '10.00000',
				},
				valid: 1,
				value: '100',
				value_comments: null,
				value_description: '100 GB',
				value_display_order: 5,
				value_price_time_unit: 'month',
			},
			{
				discount: '0.00000',
				extra_data: {
					tags: [],
				},
				is_extra: 0,
				key: 'Bandwidth',
				key_description: 'Bandwidth',
				key_display_order: 3,
				key_group: 'Network Options',
				key_group_display_order: 4,
				num_units: null,
				options: [
					{
						is_extra: 0,
						key: 'Bandwidth',
						key_description: 'Bandwidth',
						key_display_order: 3,
						num_units: null,
						price: 50,
						price_delta: 50,
						price_total: 50,
						price_total_delta: 50,
						value: 'SS.15000',
						value_comments: null,
						value_description: '15 TB Monthly Transfer',
						value_display_order: 4,
					},
					{
						is_extra: 0,
						key: 'Bandwidth',
						key_description: 'Bandwidth',
						key_display_order: 3,
						num_units: null,
						price: 100,
						price_delta: 100,
						price_total: 100,
						price_total_delta: 100,
						value: 'SS.20000',
						value_comments: null,
						value_description: '20 TB Monthly Transfer',
						value_display_order: 5,
					},
				],
				parent_key: null,
				parent_value: null,
				price: '0.00000',
				price_total: '0.00000',
				tax: null,
				totals: {
					subtotal_cost: '0.00000',
					total_cost: '0.00000',
				},
				valid: 1,
				value: 'SS.10000',
				value_comments: null,
				value_description: '10 TB Monthly Transfer',
				value_display_order: 3,
				value_price_time_unit: 'static-month',
			},
			{
				discount: '0.00000',
				extra_data: {
					tags: [],
				},
				is_extra: 0,
				key: 'NetworkStorage',
				key_description: 'Network Storage',
				key_display_order: null,
				key_group: 'Misc. Options',
				key_group_display_order: 2147483647,
				num_units: null,
				options: [
					{
						is_extra: 0,
						key: 'NetworkStorage',
						key_description: 'Network Storage',
						key_display_order: null,
						num_units: null,
						price: 1,
						price_delta: 1,
						price_total: 1,
						price_total_delta: 1,
						value: '10gb',
						value_comments: null,
						value_description: '10GB',
						value_display_order: 5,
					},
					{
						is_extra: 0,
						key: 'NetworkStorage',
						key_description: 'Network Storage',
						key_display_order: null,
						num_units: null,
						price: 2,
						price_delta: 2,
						price_total: 2,
						price_total_delta: 2,
						value: '20gb',
						value_comments: null,
						value_description: '20GB',
						value_display_order: 10,
					},
				],
				parent_key: null,
				parent_value: null,
				price: '0.00000',
				price_total: '0.00000',
				tax: null,
				totals: {
					subtotal_cost: '0.00000',
					total_cost: '0.00000',
				},
				valid: 1,
				value: 'no_storage',
				value_comments: null,
				value_description: 'None',
				value_display_order: 0,
				value_price_time_unit: 'month',
			},
			{
				discount: '0.00000',
				extra_data: {
					tags: [],
				},
				is_extra: 0,
				key: 'cPanelLicenseTier',
				key_description: 'cPanel License Tier',
				key_display_order: 0,
				key_group: 'Misc. Options',
				key_group_display_order: 2147483647,
				num_units: null,
				options: [
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 10,
						price_delta: 10,
						price_total: 10,
						price_total_delta: 10,
						value: 'plus_cloud_50',
						value_comments: null,
						value_description: 'Plus',
						value_display_order: 25,
					},
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 20,
						price_delta: 20,
						price_total: 20,
						price_total_delta: 20,
						value: 'premier_cloud_100',
						value_comments: null,
						value_description: 'Premier Fixed 100',
						value_display_order: 35,
					},
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 125,
						price_delta: 125,
						price_total: 125,
						price_total_delta: 125,
						value: 'premier_cloud_1000',
						value_comments: null,
						value_description: 'Premier Fixed 1000',
						value_display_order: 140,
					},
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 25,
						price_delta: 25,
						price_total: 25,
						price_total_delta: 25,
						value: 'premier_cloud_150',
						value_comments: null,
						value_description: 'Premier Fixed 150',
						value_display_order: 40,
					},
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 185,
						price_delta: 185,
						price_total: 185,
						price_total_delta: 185,
						value: 'premier_cloud_1500',
						value_comments: null,
						value_description: 'Premier Fixed 1500',
						value_display_order: 200,
					},
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 30,
						price_delta: 30,
						price_total: 30,
						price_total_delta: 30,
						value: 'premier_cloud_200',
						value_comments: null,
						value_description: 'Premier Fixed 200',
						value_display_order: 45,
					},
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 35,
						price_delta: 35,
						price_total: 35,
						price_total_delta: 35,
						value: 'premier_cloud_250',
						value_comments: null,
						value_description: 'Premier Fixed 250',
						value_display_order: 50,
					},
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 40,
						price_delta: 40,
						price_total: 40,
						price_total_delta: 40,
						value: 'premier_cloud_300',
						value_comments: null,
						value_description: 'Premier Fixed 300',
						value_display_order: 55,
					},
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 45,
						price_delta: 45,
						price_total: 45,
						price_total_delta: 45,
						value: 'premier_cloud_350',
						value_comments: null,
						value_description: 'Premier Fixed 350',
						value_display_order: 60,
					},
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 55,
						price_delta: 55,
						price_total: 55,
						price_total_delta: 55,
						value: 'premier_cloud_400',
						value_comments: null,
						value_description: 'Premier Fixed 400',
						value_display_order: 70,
					},
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 65,
						price_delta: 65,
						price_total: 65,
						price_total_delta: 65,
						value: 'premier_cloud_450',
						value_comments: null,
						value_description: 'Premier Fixed 450',
						value_display_order: 80,
					},
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 75,
						price_delta: 75,
						price_total: 75,
						price_total_delta: 75,
						value: 'premier_cloud_500',
						value_comments: null,
						value_description: 'Premier Fixed 500',
						value_display_order: 90,
					},
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 85,
						price_delta: 85,
						price_total: 85,
						price_total_delta: 85,
						value: 'premier_cloud_600',
						value_comments: null,
						value_description: 'Premier Fixed 600',
						value_display_order: 100,
					},
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 95,
						price_delta: 95,
						price_total: 95,
						price_total_delta: 95,
						value: 'premier_cloud_700',
						value_comments: null,
						value_description: 'Premier Fixed 700',
						value_display_order: 110,
					},
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 105,
						price_delta: 105,
						price_total: 105,
						price_total_delta: 105,
						value: 'premier_cloud_800',
						value_comments: null,
						value_description: 'Premier Fixed 800',
						value_display_order: 120,
					},
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 115,
						price_delta: 115,
						price_total: 115,
						price_total_delta: 115,
						value: 'premier_cloud_900',
						value_comments: null,
						value_description: 'Premier Fixed 900',
						value_display_order: 130,
					},
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 5,
						price_delta: 5,
						price_total: 5,
						price_total_delta: 5,
						value: 'pro_cloud_30',
						value_comments: null,
						value_description: 'Pro',
						value_display_order: 20,
					},
				],
				parent_key: null,
				parent_value: null,
				price: '0.00000',
				price_total: '0.00000',
				tax: null,
				totals: {
					subtotal_cost: '0.00000',
					total_cost: '0.00000',
				},
				valid: 1,
				value: 'admin_cloud_5',
				value_comments: null,
				value_description: 'Admin',
				value_display_order: 15,
				value_price_time_unit: 'month',
			},
			{
				discount: '0.00000',
				extra_data: {
					tags: [],
				},
				is_extra: 0,
				key: 'Acronis',
				key_description: 'Acronis Cyber Backup',
				key_display_order: null,
				key_group: 'Storage and Backups',
				key_group_display_order: 2,
				num_units: null,
				options: [
					{
						is_extra: 0,
						key: 'Acronis',
						key_description: 'Acronis Cyber Backup',
						key_display_order: null,
						num_units: null,
						price: 0,
						price_delta: 0,
						price_total: 0,
						price_total_delta: 0,
						value: 'Acronis_AC100',
						value_comments: null,
						value_description: 'Liquid Web Cloud - 100GB Storage',
						value_display_order: 10,
					},
				],
				parent_key: null,
				parent_value: null,
				price: '0.00000',
				price_total: '0.00000',
				tax: null,
				totals: {
					subtotal_cost: '0.00000',
					total_cost: '0.00000',
				},
				valid: 1,
				value: 'No_Acronis',
				value_comments: null,
				value_description: 'No Acronis Cyber Backup Required',
				value_display_order: 0,
				value_price_time_unit: 'month',
			},
			{
				discount: '0.00000',
				extra_data: {
					active: 1,
					deprecated: 0,
					os: 'LinuxCentOS',
					os_license: null,
					support_level: 'Self-Managed',
					tags: ['CentOS', 'Self-Managed'],
					zone_availability: {
						'40460': 1,
						'45412': 1,
					},
				},
				is_extra: 0,
				key: 'Template',
				key_description: 'Server Type',
				key_display_order: 2,
				key_group: 'Server Options',
				key_group_display_order: 1,
				num_units: null,
				options: [
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'LinuxUbuntu',
							os_license: null,
							support_level: 'Core-Managed',
							zone_availability: {
								'40460': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 10,
						price_delta: 10,
						price_total: 10,
						price_total_delta: 10,
						value: 'UBUNTU_1604_COREMANAGED',
						value_comments: null,
						value_description: 'Ubuntu 16.04 64-bit Core-managed',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							extra_software: 'Plesk',
							os: 'LinuxCentOS',
							os_license: null,
							support_level: 'Fully-Managed',
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 1,
						price_delta: 1,
						price_total: 1,
						price_total_delta: 1,
						value: 'CENTOS_7_PLESK',
						value_comments: null,
						value_description: 'CentOS 7 64-bit Plesk',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							extra_software: 'Cpanel',
							os: 'LinuxCloudLinux',
							os_license: null,
							support_level: 'Fully-Managed',
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 15,
						price_delta: 15,
						price_total: 15,
						price_total_delta: 15,
						value: 'CENTOS_7_CPANEL_CLOUDLINUX',
						value_comments: null,
						value_description: 'CentOS 7 64-bit cPanel w/CloudLinux',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'LinuxAlpine',
							os_license: null,
							support_level: 'Self-Managed',
							zone_availability: {
								'40460': 1,
								'86172': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 0,
						price_delta: 0,
						price_total: 0,
						price_total_delta: 0,
						value: 'ALPINE_LINUX',
						value_comments: null,
						value_description: 'Alpine Linux 64-bit Self-managed',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'LinuxUbuntu',
							os_license: null,
							support_level: 'Self-Managed',
							zone_availability: {
								'1': 1,
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 0,
						price_delta: 0,
						price_total: 0,
						price_total_delta: 0,
						value: 'UBUNTU_1804_UNMANAGED',
						value_comments: null,
						value_description: 'Ubuntu 18.04 LTS 64-bit Self-Managed',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							extra_software: 'cwp',
							os: 'LinuxCentOS',
							os_license: null,
							support_level: null,
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 0,
						price_delta: 0,
						price_total: 0,
						price_total_delta: 0,
						value: 'CENTOS_7_CWP',
						value_comments: null,
						value_description: 'CentOS 7 w/ CentOS Web Panel',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'LinuxDebian',
							os_license: null,
							support_level: 'Self-Managed',
							zone_availability: {
								'1': 1,
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 1,
						price_delta: 1,
						price_total: 1,
						price_total_delta: 1,
						value: 'DEBIAN_8_UNMANAGED',
						value_comments: null,
						value_description: 'Debian 8 64-bit Self-managed',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'LinuxCentOS',
							os_license: null,
							support_level: 'Core-Managed',
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 0,
						price_delta: 0,
						price_total: 0,
						price_total_delta: 0,
						value: 'CENTOS_8_COREMANAGED',
						value_comments: null,
						value_description: 'CentOS 8 64-bit CoreManaged',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							extra_software: 'Cpanel',
							os: 'LinuxCentOS',
							os_license: null,
							support_level: 'Fully-Managed',
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 15,
						price_delta: 15,
						price_total: 15,
						price_total_delta: 15,
						value: 'CENTOS_7_CPANEL',
						value_comments: null,
						value_description: 'CentOS 7 64-bit with cPanel Fully-managed',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							extra_software: 'Interworx',
							os: 'LinuxCentOS',
							os_license: null,
							support_level: 'Fully-Managed',
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 5,
						price_delta: 5,
						price_total: 5,
						price_total_delta: 5,
						value: 'CENTOS_7_INTERWORX',
						value_comments: null,
						value_description: 'CentOS 7 w/ Interworx',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'LinuxFedora',
							os_license: null,
							support_level: 'Self-Managed',
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 0,
						price_delta: 0,
						price_total: 0,
						price_total_delta: 0,
						value: 'FEDORA_33_SERVER_UNMANAGED',
						value_comments: null,
						value_description: 'Fedora 33 Server 64-bit Self-managed',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'LinuxAlma',
							os_license: null,
							support_level: 'Self-Managed',
							zone_availability: {
								'1': 1,
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 0,
						price_delta: 0,
						price_total: 0,
						price_total_delta: 0,
						value: 'ALMALINUX_8_UNMANAGED',
						value_comments: null,
						value_description: 'AlmaLinux 8 64-bit Self-managed',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'LinuxUbuntu',
							os_license: null,
							support_level: 'Self-Managed',
							zone_availability: {
								'1': 1,
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 0,
						price_delta: 0,
						price_total: 0,
						price_total_delta: 0,
						value: 'UBUNTU_1604_UNMANAGED',
						value_comments: null,
						value_description: 'Ubuntu 16.04 LTS 64-bit Self-Managed',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'LinuxCentOS',
							os_license: null,
							support_level: 'Self-Managed',
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 1,
						price_delta: 1,
						price_total: 1,
						price_total_delta: 1,
						value: 'CENTOS_8_UNMANAGED',
						value_comments: null,
						value_description: 'CentOS 8 64-bit Self-managed',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							extra_software: 'Interworx',
							os: 'LinuxCloudLinux',
							os_license: null,
							support_level: 'Fully-Managed',
							zone_availability: {
								'40460': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 0,
						price_delta: 0,
						price_total: 0,
						price_total_delta: 0,
						value: 'CLOUDLINUX_7_INTERWORX',
						value_comments: null,
						value_description: 'CloudLinux 7 w/ Interworx',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'LinuxDebian',
							os_license: null,
							support_level: 'Self-Managed',
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 0,
						price_delta: 0,
						price_total: 0,
						price_total_delta: 0,
						value: 'DEBIAN_10_UNMANAGED',
						value_comments: null,
						value_description: 'Debian 10 64-bit Self-managed',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							extra_software: 'ControlPanel',
							os: 'LinuxUbuntu',
							os_license: null,
							support_level: 'Core-Managed',
							zone_availability: {
								'1': 1,
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 1,
						price_delta: 1,
						price_total: 1,
						price_total_delta: 1,
						value: 'UBUNTU_2004_COREMANAGED',
						value_comments: null,
						value_description: 'Ubuntu 20.04 LTS 64-bit Core-Managed',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'LinuxCentOS',
							os_license: null,
							support_level: 'Self-Managed',
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 0,
						price_delta: 0,
						price_total: 0,
						price_total_delta: 0,
						value: 'CENTOS_STREAM_UNMANAGED',
						value_comments: null,
						value_description: 'CentOS Stream 64-bit Self-managed',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'LinuxUbuntu',
							os_license: null,
							support_level: 'Self-Managed',
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 0,
						price_delta: 0,
						price_total: 0,
						price_total_delta: 0,
						value: 'UBUNTU_2004_DJANGO',
						value_comments: null,
						value_description: 'Ubuntu 20.04 LTS 64-bit Django',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'LinuxCentOS',
							os_license: null,
							support_level: 'Core-Managed',
							zone_availability: {
								'40460': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 0,
						price_delta: 0,
						price_total: 0,
						price_total_delta: 0,
						value: 'CENTOS_7_COREMANAGED',
						value_comments: 'THIS IS A TEST',
						value_description: 'CentOS 7.0 64-bit CoreManaged',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'LinuxDebian',
							os_license: null,
							support_level: 'Self-Managed',
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 0,
						price_delta: 0,
						price_total: 0,
						price_total_delta: 0,
						value: 'DEBIAN_9_UNMANAGED',
						value_comments: null,
						value_description: 'Debian 9 64-bit Self-managed',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'LinuxUbuntu',
							os_license: null,
							support_level: 'Self-Managed',
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 0,
						price_delta: 0,
						price_total: 0,
						price_total_delta: 0,
						value: 'UBUNTU_2004_UNMANAGED',
						value_comments: null,
						value_description: 'Ubuntu 20.04 LTS 64-bit Self-Managed',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							extra_software: 'Cpanel',
							os: 'LinuxCentOS',
							os_license: null,
							support_level: 'Fully-Managed',
							zone_availability: {
								'1': 1,
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 15,
						price_delta: 15,
						price_total: 15,
						price_total_delta: 15,
						value: 'CENTOS_8_CPANEL',
						value_comments: null,
						value_description: 'CentOS 8 64-bit with cPanel Fully-managed',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'LinuxUbuntu',
							os_license: null,
							support_level: 'Core-Managed',
							zone_availability: {
								'40460': 1,
								'45412': 1,
								'86172': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 1,
						price_delta: 1,
						price_total: 1,
						price_total_delta: 1,
						value: 'UBUNTU_1804_COREMANAGED',
						value_comments: null,
						value_description: 'Ubuntu 18.04 64-bit Core-managed',
						value_display_order: 0,
					},
				],
				parent_key: null,
				parent_value: null,
				price: '0.00000',
				price_total: '0.00000',
				tax: null,
				totals: {
					subtotal_cost: '0.00000',
					total_cost: '0.00000',
				},
				valid: 1,
				value: 'CENTOS_7_UNMANAGED',
				value_comments: null,
				value_description: 'CentOS 7 64-bit Self-managed',
				value_display_order: 0,
				value_price_time_unit: 'month',
			},
			{
				discount: '0.00000',
				extra_data: {
					category: 'ssd',
					config_id: '4',
					cores: 1,
					is_baremetal: 0,
					memory: 440,
					price: 1,
					product_code: 'SS.VPS',
					storage_size: 25,
					tags: ['TestLoad2'],
				},
				is_extra: 0,
				key: 'ConfigId',
				key_description: 'Server Type',
				key_display_order: 1,
				key_group: 'Server Options',
				key_group_display_order: 1,
				num_units: null,
				options: [
					{
						extra_data: {
							category: 'ssd',
							config_id: '164',
							cores: 2,
							is_baremetal: 0,
							memory: 1024,
							price: 12,
							product_code: 'SS.VPS',
							storage_size: 30,
						},
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 12,
						price_delta: 11,
						price_total: 12,
						price_total_delta: 11,
						value: '164',
						value_comments: null,
						value_description: '1GB',
						value_display_order: 19,
					},
					{
						extra_data: {
							category: 'ssd',
							config_id: '1',
							cores: 2,
							is_baremetal: 0,
							memory: 1700,
							price: 1,
							product_code: 'SS.VPS',
							storage_size: 100,
						},
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 1,
						price_delta: 0,
						price_total: 1,
						price_total_delta: 0,
						value: '1',
						value_comments: null,
						value_description: '2GB',
						value_display_order: 6,
					},
				],
				parent_key: null,
				parent_value: null,
				price: '1.00000',
				price_total: '1.00000',
				tax: null,
				totals: {
					subtotal_cost: '1.00000',
					total_cost: '1.00000',
				},
				valid: 1,
				value: '4',
				value_comments: null,
				value_description: 'VPS 2000 Plus',
				value_display_order: 7,
				value_price_time_unit: 'month',
			},
		],
		created: '2021-07-12 11:29:22.582377-04',
		discount: '0.00000',
		extra_data: {
			available_backups: [],
			available_images: [],
			available_parents: [],
			ip_pool: {},
		},
		productInfo: {
			alias: 'storm-vps',
			capabilities: {
				ATSLogin: 1,
				AutoActivate: 1,
				autoAddIp: 1,
				autoPrivateNetworking: 1,
				autoReboot: 1,
				autoRemoveIp: 1,
				autoRename: 1,
				autoShutdown: 1,
				bandwidthStats: 1,
				canAcronisBackup: 1,
				canHotplugRamVcpu: 1,
				canThreatStack: 1,
				canUseSpiceConsole: 1,
				changeBWOptions: 1,
				clone: 1,
				create: 1,
				credentials: 1,
				destroy: 1,
				emailable: 1,
				extraAuth: 1,
				guardian: 1,
				image: 1,
				manAddIp: 1,
				manReboot: 1,
				manRemoveIp: 1,
				managed: 1,
				migratableDestination: 1,
				migratableSource: 1,
				multiplePublicIPs: 1,
				networking: 1,
				presentable: 1,
				provBackup: 1,
				provFirewall: 1,
				reimage: 1,
				rescueBoot: 1,
				resize: 1,
				restoreFromTemplate: 1,
				reverseDNSEdit: 1,
				rootLogin: 1,
				serviceMonitoring: 1,
				serviceMonitoringBasic: 1,
				serviceMonitoringDns: 1,
				serviceMonitoringLinux: 1,
				serviceMonitoringMachineMysqld: 1,
				serviceMonitoringSystem: 1,
				sonar: 1,
				volumeAttach: 1,
				volumeDetach: 1,
				webConsole: 1,
			},
			credit_eligible: 1,
			cycle: 'month',
			discountable: 1,
			product_code: 'SS.VPS',
			product_description: 'LiquidWeb Storm VPS',
			product_display_group: 'Storm VPS',
			sub_title: 'A subtitle',
			tax_code: 'SW050400',
			title: 'Stormy VPS',
		},
		product_code: 'SS.VPS',
		properties: {
			account_default_region: 1,
			account_default_zone: '45412',
			zone: '40460',
		},
		region: 1,
		tax: null,
		totals: {
			configs_discount_total: '0.00000',
			configs_price_total: '11.00000',
			configs_subtotal: '11.00000',
			configs_tax_total: '0',
			configs_total_cost: '11.00000',
			item_discount_total: '0.00000',
			item_prepay_auto_renew: null,
			item_prepay_discount: null,
			item_prepay_once: null,
			item_prepay_ongoing: null,
			item_prepay_term_max: null,
			item_price_total: '11.00000',
			item_setup_fee: '0',
			item_subtotal: '11.00000',
			item_tax_total: '0',
			item_total_cost: '11.00000',
			subtotal_base_cost: '0.00000',
			total_base_cost: '0.00000',
		},
		uuid: '0b5fb0f9-0fea-4b66-a859-6b0cdb044cd7',
		valid: 1,
	},
	'c965fa1d-6c7b-44a7-8063-cda48e40ed25': {
		base_price: '0.00000',
		basket_uuid: 'e3461dab-a15c-464f-b40c-8f67d2d728f2',
		configs: [
			{
				discount: '0.00000',
				extra_data: {
					tags: [],
				},
				is_extra: 0,
				key: 'ExtraIp',
				key_description: 'Public IP Addresses',
				key_display_order: 6,
				key_group: 'Network Options',
				key_group_display_order: 19,
				nonce: 'f8d81b96-0a52-11ec-8eef-0cc47a525dd0',
				num_units: 0,
				options: [],
				parent_key: null,
				parent_value: null,
				price: '2.00000',
				price_total: '0.00000',
				tax: null,
				totals: {
					subtotal_cost: '0.00000',
					total_cost: '0.00000',
				},
				valid: 1,
				value: '1',
				value_comments: null,
				value_description: '%{units} Additional Public IPs',
				value_display_order: 1,
				value_price_time_unit: 'month',
			},
			{
				discount: '0.00000',
				extra_data: {
					tags: [],
				},
				is_extra: 0,
				key: 'LiquidWebBackupPlan',
				key_description: 'Backup Plan',
				key_display_order: 5,
				key_group: 'Misc. Options',
				key_group_display_order: 2147483647,
				nonce: 'f8d8f674-0a52-11ec-8eef-0cc47a525dd0',
				num_units: null,
				options: [
					{
						is_extra: 0,
						key: 'LiquidWebBackupPlan',
						key_description: 'Backup Plan',
						key_display_order: 5,
						num_units: null,
						price: 0,
						price_delta: 0,
						price_total: 0,
						price_total_delta: 0,
						value: 'Daily',
						value_comments: null,
						value_description: 'As-you-go Backups',
						value_display_order: 2,
					},
					{
						is_extra: 0,
						key: 'LiquidWebBackupPlan',
						key_description: 'Backup Plan',
						key_display_order: 5,
						num_units: null,
						price: 0,
						price_delta: 0,
						price_total: 0,
						price_total_delta: 0,
						value: 'Quota',
						value_comments: null,
						value_description: 'Quota-based Backups',
						value_display_order: 3,
					},
				],
				parent_key: null,
				parent_value: null,
				price: '0.00000',
				price_total: '0.00000',
				tax: null,
				totals: {
					subtotal_cost: '0.00000',
					total_cost: '0.00000',
				},
				valid: 1,
				value: 'None',
				value_comments: null,
				value_description: 'No Backups',
				value_display_order: 1,
				value_price_time_unit: 'month',
			},
			{
				discount: '0.00000',
				extra_data: {
					tags: [],
				},
				is_extra: 0,
				key: 'Bandwidth',
				key_description: 'Bandwidth',
				key_display_order: 3,
				key_group: 'Bandwidth Options',
				key_group_display_order: 20,
				nonce: 'f8da0528-0a52-11ec-8eef-0cc47a525dd0',
				num_units: null,
				options: [
					{
						is_extra: 0,
						key: 'Bandwidth',
						key_description: 'Bandwidth',
						key_display_order: 3,
						num_units: null,
						price: 50,
						price_delta: 50,
						price_total: 50,
						price_total_delta: 50,
						value: 'SS.15000',
						value_comments: null,
						value_description: '15 TB Monthly Transfer',
						value_display_order: 4,
					},
					{
						is_extra: 0,
						key: 'Bandwidth',
						key_description: 'Bandwidth',
						key_display_order: 3,
						num_units: null,
						price: 100,
						price_delta: 100,
						price_total: 100,
						price_total_delta: 100,
						value: 'SS.20000',
						value_comments: null,
						value_description: '20 TB Monthly Transfer',
						value_display_order: 5,
					},
				],
				parent_key: null,
				parent_value: null,
				price: '0.00000',
				price_total: '0.00000',
				tax: null,
				totals: {
					subtotal_cost: '0.00000',
					total_cost: '0.00000',
				},
				valid: 1,
				value: 'SS.10000',
				value_comments: null,
				value_description: '10 TB Monthly Transfer',
				value_display_order: 3,
				value_price_time_unit: 'static-month',
			},
			{
				discount: '0.00000',
				extra_data: {
					category: 'ssd',
					config_id: '1436',
					cores: 8,
					is_baremetal: 0,
					memory: 8000,
					price: 129,
					product_code: 'SS.VPS',
					storage_size: 150,
					tags: [],
				},
				is_extra: 0,
				key: 'ConfigId',
				key_description: 'Server Type',
				key_display_order: 1,
				key_group: 'Choose Server Type',
				key_group_display_order: 0,
				nonce: 'f8dae51a-0a52-11ec-8eef-0cc47a525dd0',
				num_units: null,
				options: [
					{
						extra_data: {
							category: 'ssd',
							config_id: '1090',
							cores: 2,
							is_baremetal: 0,
							memory: 2000,
							price: 49,
							product_code: 'SS.VPS',
							storage_size: 40,
						},
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 49,
						price_delta: -80,
						price_total: 49,
						price_total_delta: -80,
						value: '1090',
						value_comments: null,
						value_description: '2GB - SSD',
						value_display_order: 12,
					},
					{
						extra_data: {
							category: 'ssd',
							config_id: '1435',
							cores: 4,
							is_baremetal: 0,
							memory: 4000,
							price: 89,
							product_code: 'SS.VPS',
							storage_size: 100,
						},
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 89,
						price_delta: -40,
						price_total: 89,
						price_total_delta: -40,
						value: '1435',
						value_comments: null,
						value_description: '4GB - SSD',
						value_display_order: 25,
					},
					{
						extra_data: {
							category: 'ssd',
							config_id: '1437',
							cores: 8,
							is_baremetal: 0,
							memory: 16000,
							price: 179,
							product_code: 'SS.VPS',
							storage_size: 200,
						},
						is_extra: 0,
						key: 'ConfigId',
						key_description: 'Server Type',
						key_display_order: 1,
						num_units: null,
						price: 179,
						price_delta: 50,
						price_total: 179,
						price_total_delta: 50,
						value: '1437',
						value_comments: null,
						value_description: '16GB - SSD',
						value_display_order: 42,
					},
				],
				parent_key: null,
				parent_value: null,
				price: '129.00000',
				price_total: '129.00000',
				tax: null,
				totals: {
					subtotal_cost: '129.00000',
					total_cost: '129.00000',
				},
				valid: 1,
				value: '1436',
				value_comments: null,
				value_description: '8GB - SSD',
				value_display_order: 38,
				value_price_time_unit: 'month',
			},
			{
				discount: '0.00000',
				extra_data: {
					tags: [],
				},
				is_extra: 0,
				key: 'NetworkStorage',
				key_description: 'Cloud Block Storage Volume Size',
				key_display_order: null,
				key_group: 'Cloud Storage',
				key_group_display_order: 2147483647,
				nonce: 'f8dbc61a-0a52-11ec-8eef-0cc47a525dd0',
				num_units: null,
				options: [
					{
						is_extra: 0,
						key: 'NetworkStorage',
						key_description: 'Cloud Block Storage Volume Size',
						key_display_order: null,
						num_units: null,
						price: 2.5,
						price_delta: 2.5,
						price_total: 2.5,
						price_total_delta: 2.5,
						value: '25CBS',
						value_comments: null,
						value_description: '25GB Volume',
						value_display_order: 5,
					},
					{
						is_extra: 0,
						key: 'NetworkStorage',
						key_description: 'Cloud Block Storage Volume Size',
						key_display_order: null,
						num_units: null,
						price: 5,
						price_delta: 5,
						price_total: 5,
						price_total_delta: 5,
						value: '50CBS',
						value_comments: null,
						value_description: '50GB Volume',
						value_display_order: 10,
					},
					{
						is_extra: 0,
						key: 'NetworkStorage',
						key_description: 'Cloud Block Storage Volume Size',
						key_display_order: null,
						num_units: null,
						price: 10,
						price_delta: 10,
						price_total: 10,
						price_total_delta: 10,
						value: '100CBS',
						value_comments: null,
						value_description: '100GB Volume',
						value_display_order: 15,
					},
					{
						is_extra: 0,
						key: 'NetworkStorage',
						key_description: 'Cloud Block Storage Volume Size',
						key_display_order: null,
						num_units: null,
						price: 15,
						price_delta: 15,
						price_total: 15,
						price_total_delta: 15,
						value: '150CBS',
						value_comments: null,
						value_description: '150GB Volume',
						value_display_order: 20,
					},
				],
				parent_key: null,
				parent_value: null,
				price: '0.00000',
				price_total: '0.00000',
				tax: null,
				totals: {
					subtotal_cost: '0.00000',
					total_cost: '0.00000',
				},
				valid: 1,
				value: 'nocbs',
				value_comments: null,
				value_description: 'No Volume Required',
				value_display_order: 1,
				value_price_time_unit: 'month',
			},
			{
				discount: '0.00000',
				extra_data: {
					active: 1,
					deprecated: 0,
					extra_software: 'Interworx',
					os: 'LinuxCentOS',
					os_license: null,
					support_level: 'Fully-Managed',
					tags: [],
					zone_availability: {
						'12': 1,
						'15': 1,
						'26': 1,
						'27': 1,
						'28': 1,
					},
				},
				is_extra: 0,
				key: 'Template',
				key_description: 'Server Type',
				key_display_order: 2,
				key_group: 'Server Templates',
				key_group_display_order: 2,
				nonce: 'f8dc97de-0a52-11ec-8eef-0cc47a525dd0',
				num_units: null,
				options: [
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'LinuxCentOS',
							os_license: null,
							support_level: 'Self-Managed',
							zone_availability: {
								'12': 1,
								'15': 1,
								'26': 1,
								'27': 1,
								'28': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 0,
						price_delta: -10,
						price_total: 0,
						price_total_delta: -10,
						value: 'CENTOS_STREAM_UNMANAGED',
						value_comments: null,
						value_description: 'CentOS Stream 64-bit Self-managed',
						value_display_order: 34,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							extra_software: 'Cpanel',
							os: 'LinuxCentOS',
							os_license: null,
							support_level: 'Fully-Managed',
							zone_availability: {
								'12': 1,
								'15': 1,
								'26': 1,
								'27': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 17,
						price_delta: 7,
						price_total: 17,
						price_total_delta: 7,
						value: 'CENTOS_7_CPANEL',
						value_comments: null,
						value_description: 'CentOS 7 64-bit with cPanel Fully-managed',
						value_display_order: 2,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'LinuxDebian',
							os_license: null,
							support_level: 'Self-Managed',
							zone_availability: {
								'12': 1,
								'15': 1,
								'26': 1,
								'27': 1,
								'28': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 0,
						price_delta: -10,
						price_total: 0,
						price_total_delta: -10,
						value: 'DEBIAN_10_UNMANAGED',
						value_comments: null,
						value_description: 'Debian 10 64-bit Self-managed',
						value_display_order: 162,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							extra_software: 'ControlPanel',
							os: 'LinuxUbuntu',
							os_license: null,
							support_level: 'Core-Managed',
							zone_availability: {
								'12': 1,
								'15': 1,
								'26': 1,
								'27': 1,
								'28': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 0,
						price_delta: -10,
						price_total: 0,
						price_total_delta: -10,
						value: 'UBUNTU_2004_COREMANAGED',
						value_comments: null,
						value_description: 'Ubuntu 20.04 LTS 64-bit Core-Managed',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'LinuxUbuntu',
							os_license: null,
							support_level: 'Core-Managed',
							zone_availability: {
								'12': 1,
								'15': 1,
								'26': 1,
								'27': 1,
								'28': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 0,
						price_delta: -10,
						price_total: 0,
						price_total_delta: -10,
						value: 'UBUNTU_1804_COREMANAGED',
						value_comments: null,
						value_description: 'Ubuntu 18.04 LTS 64-bit Core-managed',
						value_display_order: 5,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							extra_software: 'Cpanel',
							os: 'LinuxCloudLinux',
							os_license: null,
							support_level: 'Fully-Managed',
							zone_availability: {
								'12': 1,
								'15': 1,
								'26': 1,
								'27': 1,
								'28': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 39,
						price_delta: 29,
						price_total: 39,
						price_total_delta: 29,
						value: 'CENTOS_7_CPANEL_CLOUDLINUX',
						value_comments: null,
						value_description: 'CentOS 7 64-bit cPanel w/CloudLinux',
						value_display_order: 3,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'LinuxCentOS',
							os_license: null,
							support_level: 'Self-Managed',
							zone_availability: {
								'12': 1,
								'15': 1,
								'26': 1,
								'27': 1,
								'28': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 0,
						price_delta: -10,
						price_total: 0,
						price_total_delta: -10,
						value: 'CENTOS_8_UNMANAGED',
						value_comments: null,
						value_description: 'CentOS 8 64-bit Self-managed',
						value_display_order: 35,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'LinuxCentOS',
							os_license: null,
							support_level: 'Self-Managed',
							zone_availability: {
								'12': 1,
								'15': 1,
								'26': 1,
								'27': 1,
								'28': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 0,
						price_delta: -10,
						price_total: 0,
						price_total_delta: -10,
						value: 'CENTOS_7_UNMANAGED',
						value_comments: null,
						value_description: 'CentOS 7 64-bit Self-managed',
						value_display_order: 40,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'LinuxCentOS',
							os_license: null,
							support_level: 'Core-Managed',
							zone_availability: {
								'12': 1,
								'15': 1,
								'26': 1,
								'27': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 0,
						price_delta: -10,
						price_total: 0,
						price_total_delta: -10,
						value: 'CENTOS_7_COREMANAGED',
						value_comments: null,
						value_description: 'CentOS 7 64-bit Core-managed',
						value_display_order: 4,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							extra_software: 'Plesk',
							os: 'LinuxCentOS',
							os_license: null,
							support_level: 'Fully-Managed',
							zone_availability: {
								'12': 1,
								'15': 1,
								'26': 1,
								'27': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 17,
						price_delta: 7,
						price_total: 17,
						price_total_delta: 7,
						value: 'CENTOS_7_PLESK',
						value_comments: null,
						value_description: 'CentOS 7 64-bit with Plesk Fully-managed',
						value_display_order: 1,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'LinuxFedora',
							os_license: null,
							support_level: 'Self-Managed',
							zone_availability: {
								'12': 1,
								'15': 1,
								'26': 1,
								'27': 1,
								'28': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 0,
						price_delta: -10,
						price_total: 0,
						price_total_delta: -10,
						value: 'FEDORA_33_SERVER_UNMANAGED',
						value_comments: null,
						value_description: 'Fedora 33 Server 64-bit Self-managed',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'LinuxDebian',
							os_license: null,
							support_level: 'Self-Managed',
							zone_availability: {
								'12': 1,
								'15': 1,
								'26': 1,
								'27': 1,
								'28': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 0,
						price_delta: -10,
						price_total: 0,
						price_total_delta: -10,
						value: 'DEBIAN_9_UNMANAGED',
						value_comments: null,
						value_description: 'Debian 9 64-bit Self-managed',
						value_display_order: 165,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'LinuxUbuntu',
							os_license: null,
							support_level: 'Self-Managed',
							zone_availability: {
								'12': 1,
								'15': 1,
								'26': 1,
								'27': 1,
								'28': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 0,
						price_delta: -10,
						price_total: 0,
						price_total_delta: -10,
						value: 'UBUNTU_1804_UNMANAGED',
						value_comments: null,
						value_description: 'Ubuntu 18.04 LTS 64-bit Self-Managed',
						value_display_order: 79,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'LinuxAlma',
							os_license: null,
							support_level: 'Self-Managed',
							zone_availability: {
								'12': 1,
								'15': 1,
								'26': 1,
								'27': 1,
								'28': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 0,
						price_delta: -10,
						price_total: 0,
						price_total_delta: -10,
						value: 'ALMALINUX_8_UNMANAGED',
						value_comments: null,
						value_description: 'AlmaLinux 8 64-bit Self-managed',
						value_display_order: 0,
					},
					{
						extra_data: {
							active: 1,
							deprecated: 0,
							os: 'LinuxUbuntu',
							os_license: null,
							support_level: 'Self-Managed',
							zone_availability: {
								'12': 1,
								'15': 1,
								'26': 1,
								'27': 1,
								'28': 1,
							},
						},
						is_extra: 0,
						key: 'Template',
						key_description: 'Server Type',
						key_display_order: 2,
						num_units: null,
						price: 0,
						price_delta: -10,
						price_total: 0,
						price_total_delta: -10,
						value: 'UBUNTU_2004_UNMANAGED',
						value_comments: null,
						value_description: 'Ubuntu 20.04 LTS 64-bit Self-Managed',
						value_display_order: 0,
					},
				],
				parent_key: null,
				parent_value: null,
				price: '10.00000',
				price_total: '10.00000',
				tax: null,
				totals: {
					subtotal_cost: '10.00000',
					total_cost: '10.00000',
				},
				valid: 1,
				value: 'CENTOS_7_INTERWORX',
				value_comments: null,
				value_description: 'CentOS 7 64-bit with Interworx Fully-managed',
				value_display_order: 0,
				value_price_time_unit: 'month',
			},
			{
				discount: '0.00000',
				extra_data: {
					tags: [],
				},
				is_extra: 1,
				key: 'cPanelLicenseTier',
				key_description: 'cPanel License Tier',
				key_display_order: 0,
				key_group: 'Control Panel',
				key_group_display_order: 18,
				nonce: 'f8dcad50-0a52-11ec-8eef-0cc47a525dd0',
				num_units: null,
				options: [
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 0,
						price_delta: 0,
						price_total: 0,
						price_total_delta: 0,
						value: 'admin_cloud_5',
						value_comments: null,
						value_description: 'Admin 5',
						value_display_order: 15,
					},
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 11,
						price_delta: 11,
						price_total: 11,
						price_total_delta: 11,
						value: 'plus_cloud_50',
						value_comments: null,
						value_description: 'Plus 50',
						value_display_order: 25,
					},
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 21,
						price_delta: 21,
						price_total: 21,
						price_total_delta: 21,
						value: 'premier_cloud_100',
						value_comments: null,
						value_description: 'Premier Fixed 100',
						value_display_order: 35,
					},
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 208,
						price_delta: 208,
						price_total: 208,
						price_total_delta: 208,
						value: 'premier_cloud_1000',
						value_comments: null,
						value_description: 'Premier Fixed 1000',
						value_display_order: 140,
					},
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 29,
						price_delta: 29,
						price_total: 29,
						price_total_delta: 29,
						value: 'premier_cloud_150',
						value_comments: null,
						value_description: 'Premier Fixed 150',
						value_display_order: 40,
					},
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 314,
						price_delta: 314,
						price_total: 314,
						price_total_delta: 314,
						value: 'premier_cloud_1500',
						value_comments: null,
						value_description: 'Premier Fixed 1500',
						value_display_order: 200,
					},
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 39,
						price_delta: 39,
						price_total: 39,
						price_total_delta: 39,
						value: 'premier_cloud_200',
						value_comments: null,
						value_description: 'Premier Fixed 200',
						value_display_order: 45,
					},
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 49,
						price_delta: 49,
						price_total: 49,
						price_total_delta: 49,
						value: 'premier_cloud_250',
						value_comments: null,
						value_description: 'Premier Fixed 250',
						value_display_order: 50,
					},
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 58,
						price_delta: 58,
						price_total: 58,
						price_total_delta: 58,
						value: 'premier_cloud_300',
						value_comments: null,
						value_description: 'Premier Fixed 300',
						value_display_order: 55,
					},
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 68,
						price_delta: 68,
						price_total: 68,
						price_total_delta: 68,
						value: 'premier_cloud_350',
						value_comments: null,
						value_description: 'Premier Fixed 350',
						value_display_order: 60,
					},
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 84,
						price_delta: 84,
						price_total: 84,
						price_total_delta: 84,
						value: 'premier_cloud_400',
						value_comments: null,
						value_description: 'Premier Fixed 400',
						value_display_order: 70,
					},
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 101,
						price_delta: 101,
						price_total: 101,
						price_total_delta: 101,
						value: 'premier_cloud_450',
						value_comments: null,
						value_description: 'Premier Fixed 450',
						value_display_order: 80,
					},
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 117,
						price_delta: 117,
						price_total: 117,
						price_total_delta: 117,
						value: 'premier_cloud_500',
						value_comments: null,
						value_description: 'Premier Fixed 500',
						value_display_order: 90,
					},
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 136,
						price_delta: 136,
						price_total: 136,
						price_total_delta: 136,
						value: 'premier_cloud_600',
						value_comments: null,
						value_description: 'Premier Fixed 600',
						value_display_order: 100,
					},
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 154,
						price_delta: 154,
						price_total: 154,
						price_total_delta: 154,
						value: 'premier_cloud_700',
						value_comments: null,
						value_description: 'Premier Fixed 700',
						value_display_order: 110,
					},
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 173,
						price_delta: 173,
						price_total: 173,
						price_total_delta: 173,
						value: 'premier_cloud_800',
						value_comments: null,
						value_description: 'Premier Fixed 800',
						value_display_order: 120,
					},
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 191,
						price_delta: 191,
						price_total: 191,
						price_total_delta: 191,
						value: 'premier_cloud_900',
						value_comments: null,
						value_description: 'Premier Fixed 900',
						value_display_order: 130,
					},
					{
						is_extra: 0,
						key: 'cPanelLicenseTier',
						key_description: 'cPanel License Tier',
						key_display_order: 0,
						num_units: null,
						price: 5,
						price_delta: 5,
						price_total: 5,
						price_total_delta: 5,
						value: 'pro_cloud_30',
						value_comments: null,
						value_description: 'Pro 30',
						value_display_order: 20,
					},
				],
				parent_key: null,
				parent_value: null,
				price: '0.00000',
				price_total: '0.00000',
				tax: null,
				totals: {
					subtotal_cost: '0.00000',
					total_cost: '0.00000',
				},
				valid: 1,
				value: 'none',
				value_comments: null,
				value_description: 'None',
				value_display_order: 0,
				value_price_time_unit: 'month',
			},
			{
				discount: '0.00000',
				extra_data: {
					tags: [],
				},
				is_extra: 0,
				key: 'Acronis',
				key_description: 'Acronis Cyber Backup',
				key_display_order: 0,
				key_group: 'Backups',
				key_group_display_order: 4,
				nonce: 'f8dcc59c-0a52-11ec-8eef-0cc47a525dd0',
				num_units: null,
				options: [
					{
						is_extra: 0,
						key: 'Acronis',
						key_description: 'Acronis Cyber Backup',
						key_display_order: 0,
						num_units: null,
						price: 0,
						price_delta: -12,
						price_total: 0,
						price_total_delta: -12,
						value: 'No_Acronis',
						value_comments: null,
						value_description: 'No Acronis Cyber Backup Required',
						value_display_order: 0,
					},
					{
						is_extra: 0,
						key: 'Acronis',
						key_description: 'Acronis Cyber Backup',
						key_display_order: 0,
						num_units: null,
						price: 20,
						price_delta: 8,
						price_total: 20,
						price_total_delta: 8,
						value: 'Acronis_LW250',
						value_comments: null,
						value_description: 'Liquid Web Cloud - 250GB Storage',
						value_display_order: 10,
					},
					{
						is_extra: 0,
						key: 'Acronis',
						key_description: 'Acronis Cyber Backup',
						key_display_order: 0,
						num_units: null,
						price: 35,
						price_delta: 23,
						price_total: 35,
						price_total_delta: 23,
						value: 'Acronis_LW500',
						value_comments: null,
						value_description: 'Liquid Web Cloud - 500GB Storage',
						value_display_order: 15,
					},
					{
						is_extra: 0,
						key: 'Acronis',
						key_description: 'Acronis Cyber Backup',
						key_display_order: 0,
						num_units: null,
						price: 47,
						price_delta: 35,
						price_total: 47,
						price_total_delta: 35,
						value: 'Acronis_LW750',
						value_comments: null,
						value_description: 'Liquid Web Cloud - 750GB Storage',
						value_display_order: 20,
					},
					{
						is_extra: 0,
						key: 'Acronis',
						key_description: 'Acronis Cyber Backup',
						key_display_order: 0,
						num_units: null,
						price: 60,
						price_delta: 48,
						price_total: 60,
						price_total_delta: 48,
						value: 'Acronis_LW1000',
						value_comments: null,
						value_description: 'Liquid Web Cloud - 1TB Storage',
						value_display_order: 25,
					},
					{
						is_extra: 0,
						key: 'Acronis',
						key_description: 'Acronis Cyber Backup',
						key_display_order: 0,
						num_units: null,
						price: 80,
						price_delta: 68,
						price_total: 80,
						price_total_delta: 68,
						value: 'Acronis_LW1500',
						value_comments: null,
						value_description: 'Liquid Web Cloud - 1.5TB Storage',
						value_display_order: 30,
					},
					{
						is_extra: 0,
						key: 'Acronis',
						key_description: 'Acronis Cyber Backup',
						key_display_order: 0,
						num_units: null,
						price: 105,
						price_delta: 93,
						price_total: 105,
						price_total_delta: 93,
						value: 'Acronis_LW2000',
						value_comments: null,
						value_description: 'Liquid Web Cloud - 2TB Storage',
						value_display_order: 35,
					},
					{
						is_extra: 0,
						key: 'Acronis',
						key_description: 'Acronis Cyber Backup',
						key_display_order: 0,
						num_units: null,
						price: 130,
						price_delta: 118,
						price_total: 130,
						price_total_delta: 118,
						value: 'Acronis_LW2500',
						value_comments: null,
						value_description: 'Liquid Web Cloud - 2.5TB Storage',
						value_display_order: 40,
					},
					{
						is_extra: 0,
						key: 'Acronis',
						key_description: 'Acronis Cyber Backup',
						key_display_order: 0,
						num_units: null,
						price: 17,
						price_delta: 5,
						price_total: 17,
						price_total_delta: 5,
						value: 'Acronis_AC100',
						value_comments: null,
						value_description: 'Acronis Cloud - 100GB Storage',
						value_display_order: 45,
					},
					{
						is_extra: 0,
						key: 'Acronis',
						key_description: 'Acronis Cyber Backup',
						key_display_order: 0,
						num_units: null,
						price: 35,
						price_delta: 23,
						price_total: 35,
						price_total_delta: 23,
						value: 'Acronis_AC250',
						value_comments: null,
						value_description: 'Acronis Cloud - 250GB Storage',
						value_display_order: 50,
					},
					{
						is_extra: 0,
						key: 'Acronis',
						key_description: 'Acronis Cyber Backup',
						key_display_order: 0,
						num_units: null,
						price: 65,
						price_delta: 53,
						price_total: 65,
						price_total_delta: 53,
						value: 'Acronis_AC500',
						value_comments: null,
						value_description: 'Acronis Cloud - 500GB Storage',
						value_display_order: 55,
					},
					{
						is_extra: 0,
						key: 'Acronis',
						key_description: 'Acronis Cyber Backup',
						key_display_order: 0,
						num_units: null,
						price: 95,
						price_delta: 83,
						price_total: 95,
						price_total_delta: 83,
						value: 'Acronis_AC750',
						value_comments: null,
						value_description: 'Acronis Cloud - 750GB Storage',
						value_display_order: 60,
					},
					{
						is_extra: 0,
						key: 'Acronis',
						key_description: 'Acronis Cyber Backup',
						key_display_order: 0,
						num_units: null,
						price: 125,
						price_delta: 113,
						price_total: 125,
						price_total_delta: 113,
						value: 'Acronis_AC1000',
						value_comments: null,
						value_description: 'Acronis Cloud - 1TB Storage',
						value_display_order: 65,
					},
					{
						is_extra: 0,
						key: 'Acronis',
						key_description: 'Acronis Cyber Backup',
						key_display_order: 0,
						num_units: null,
						price: 170,
						price_delta: 158,
						price_total: 170,
						price_total_delta: 158,
						value: 'Acronis_AC1500',
						value_comments: null,
						value_description: 'Acronis Cloud - 1.5TB Storage',
						value_display_order: 70,
					},
					{
						is_extra: 0,
						key: 'Acronis',
						key_description: 'Acronis Cyber Backup',
						key_display_order: 0,
						num_units: null,
						price: 225,
						price_delta: 213,
						price_total: 225,
						price_total_delta: 213,
						value: 'Acronis_AC2000',
						value_comments: null,
						value_description: 'Acronis Cloud - 2TB Storage',
						value_display_order: 75,
					},
					{
						is_extra: 0,
						key: 'Acronis',
						key_description: 'Acronis Cyber Backup',
						key_display_order: 0,
						num_units: null,
						price: 280,
						price_delta: 268,
						price_total: 280,
						price_total_delta: 268,
						value: 'Acronis_AC2500',
						value_comments: null,
						value_description: 'Acronis Cloud - 2.5TB Storage',
						value_display_order: 80,
					},
				],
				parent_key: null,
				parent_value: null,
				price: '12.00000',
				price_total: '12.00000',
				tax: null,
				totals: {
					subtotal_cost: '12.00000',
					total_cost: '12.00000',
				},
				valid: 1,
				value: 'Acronis_LW100',
				value_comments: null,
				value_description: 'Liquid Web Cloud - 100GB Storage',
				value_display_order: 5,
				value_price_time_unit: 'month',
			},
		],
		created: '2021-08-31 07:38:17.738891-04',
		discount: '0.00000',
		extra_data: {
			available_backups: [],
			available_images: [],
			available_parents: [],
			ip_pool: {},
		},
		nonce: 'f8de0e48-0a52-11ec-8eef-0cc47a525dd0',
		productInfo: {
			alias: null,
			capabilities: {
				ATSLogin: 1,
				AutoActivate: 1,
				autoAddIp: 1,
				autoPrivateNetworking: 1,
				autoReboot: 1,
				autoRemoveIp: 1,
				autoRename: 1,
				autoShutdown: 1,
				bandwidthStats: 1,
				canAcronisBackup: 1,
				canHotplugRamVcpu: 1,
				canThreatStack: 1,
				canUseSpiceConsole: 1,
				changeBWOptions: 1,
				clone: 1,
				create: 1,
				credentials: 1,
				destroy: 1,
				emailable: 1,
				extraAuth: 1,
				image: 1,
				manAddIp: 1,
				manReboot: 1,
				manRemoveIp: 1,
				managed: 1,
				migratableDestination: 1,
				migratableSource: 1,
				multiplePublicIPs: 1,
				networking: 1,
				presentable: 1,
				provBackup: 1,
				provFirewall: 1,
				reimage: 1,
				resize: 1,
				restoreFromTemplate: 1,
				reverseDNSEdit: 1,
				rootLogin: 1,
				serviceMonitoring: 1,
				serviceMonitoringBasic: 1,
				serviceMonitoringDns: 1,
				serviceMonitoringInternal: 1,
				serviceMonitoringInternalBasic: 1,
				serviceMonitoringInternalDns: 1,
				serviceMonitoringInternalLinux: 1,
				serviceMonitoringLinux: 1,
				serviceMonitoringMachineBlackbox: 1,
				serviceMonitoringMachineMysqld: 1,
				serviceMonitoringMachineNode: 1,
				sonar: 1,
				volumeAttach: 1,
				volumeDetach: 1,
				webConsole: 1,
			},
			credit_eligible: 1,
			cycle: 'month',
			discountable: 1,
			product_code: 'SS.VPS',
			product_description: 'Cloud VPS Hosting - Linux',
			product_display_group: 'Cloud VPS',
			sub_title: 'Cloud VPS Hosting - Linux',
			tax_code: 'SW050400',
			title: 'Cloud VPS Server Linux',
		},
		product_code: 'SS.VPS',
		properties: {
			zone: '1',
		},
		region: 1,
		tax: null,
		totals: {
			configs_discount_total: '0.00000',
			configs_price_total: '151.00000',
			configs_subtotal: '151.00000',
			configs_tax_total: '0',
			configs_total_cost: '151.00000',
			item_discount_total: '0.00000',
			item_prepay_auto_renew: null,
			item_prepay_discount: null,
			item_prepay_once: null,
			item_prepay_ongoing: null,
			item_prepay_term_max: null,
			item_price_total: '151.00000',
			item_setup_fee: '0',
			item_subtotal: '151.00000',
			item_tax_total: '0',
			item_total_cost: '151.00000',
			subtotal_base_cost: '0.00000',
			total_base_cost: '0.00000',
		},
		uuid: 'c965fa1d-6c7b-44a7-8063-cda48e40ed25',
		valid: 1,
	},
};

export { uuids };
export default items;
