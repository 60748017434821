import Immutable from 'immutable';
import actions from './actions';

const initialState = Immutable.fromJS({
	isOpen: false,
	dialogProps: {},
});

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actions.RENAME_PROJECT_DIALOG_TOGGLE:
			return state.set('isOpen', action.isOpen);

		case actions.RENAME_PROJECT_DIALOG_SET_PROPS:
			return state.set('dialogProps', action.dialogProps);

		default:
			return state;
	}
};

export default reducer;
