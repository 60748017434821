import { put, call, select, take } from 'redux-saga/effects';
import {
	selectors as detailsSelectors,
	actions as detailsActions,
} from 'modules/api/basket/detailsModule';
import { BASKET_UUID } from 'utility/constants/baskets';
import snackbarSaga from 'modules/snackbar/sagas/sagas';
import { merge } from 'lodash';
import basketActions from '../../actions';

function* handleError({ error }) {
	if (error) {
		yield put(basketActions.setError('load cart'));
	}
}

// TODO: pass in the actions object and move this function to 'utility/redux/saga'
// Desicions will need to be made about error handling when we do this.
function* conditionalTake({ init, hasData }) {
	if ((init && !hasData) || !init) {
		const detailsResult = yield take([
			detailsActions.setType,
			detailsActions.errorType,
		]);
		const error = detailsResult?.type === detailsActions.errorType;
		yield call(handleError, { error });
		return detailsResult;
	}
	return false;
}

// pass in {init: true} if you only want details called if there's no data yet.
function* detailsSaga({ moduleKey, init } = {}) {
	// TODO - if any current mutations going on, pause / cancel any ongoing details calls
	// this includes add / remove domain, edit
	// (From Caleb), I would suggest using the "take first" idea in index.js so that we can keep the load off of the backend too.
	// also, we need to call all calls to details in a single spot.

	const alsowith = [
		'cpqMetadata',
		['discountSources', 'discountStrategy', 'totals'],
		'totals',
		['items', 'totals', 'productInfo', 'maxDiscount', ['configs', 'totals']],
	];

	const uuid = yield call([localStorage, 'getItem'], BASKET_UUID);

	yield put(
		detailsActions[init ? 'init' : 'fetch'](
			{
				uuid,
				alsowith,
			},
			moduleKey,
		),
	);
	const hasData = yield select(detailsSelectors.hasData);

	// blocking until response or error if we're expecting something at all.
	const result = yield call(conditionalTake, { init, hasData });
	return result;
}

// Gets basketDetails but only updates part of the object. Useful for when small changes are made to the root of the object.
function* minDetailsRefresh({ alsowith, moduleKey } = {}) {
	const oldObject = yield select(detailsSelectors.getNativeData);
	const uuid = yield call([localStorage, 'getItem'], BASKET_UUID);
	yield put(detailsActions.fetch({ uuid, alsowith }, moduleKey));
	const newObject = yield take([
		detailsActions.setType,
		detailsActions.errorType,
	]);
	const error = newObject.type === detailsActions.errorType;
	yield call(snackbarSaga, {
		error,
		errorMessage:
			'Your cart may not be up-to-date. Refresh if you experience problems.',
	});
	if (!error)
		yield put(detailsActions.set(merge(oldObject, newObject.payload)));
}

export { conditionalTake, handleError, detailsSaga, minDetailsRefresh };
export default detailsSaga;
