// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/random

// Used to slightly randomize a number. Initial use case was to make skeleton loaders more fun.
const returnStaticValue = process.env.NODE_ENV === 'test';

// Returns an int between min and max, including min or max
const randomNumberBetween = (min, max) => {
	if (returnStaticValue) return min;
	return Math.floor(Math.random() * (max - min + 1)) + min;
};

export default randomNumberBetween;
