// TODO: Put all Status files in the same folder. See components/common/Status/

import React from 'react';
import PropTypes from 'prop-types';
import GeneralStatus from 'components/common/Status';

// These are used to display default status messages based on subaccount status if there are no other notifications
const subaccountStatusDefaults = {
	New: {
		description: 'Building',
		notificationLevel: 'pending',
	},
	Active: {
		description: 'Running',
		notificationLevel: 'normal',
	},
	'Pending-Activation': {
		description: 'Building',
		notificationLevel: 'pending',
	},
	'Pending-Config': {
		description: 'Configuring',
		notificationLevel: 'warning',
	},
	Disabled: {
		description: 'Disabled',
		notificationLevel: 'high',
	},
	'Pending-Termination': {
		description: 'Terminating',
		notificationLevel: 'warning',
	},
	'': {
		description: '',
		notificationLevel: 'pending',
	},
};

const Status = ({
	uniqId,
	subaccntStatus,
	newestNotifications, // from connected
	title,
	listView,
	iconOnly,
	BoxProps,
}) => {
	let displayMessage = '';
	let displayNotificationLevel = 'pending';
	if (uniqId) {
		const notification = newestNotifications.find(
			(x) => x.get('uniq_id') === uniqId,
		);

		if (notification) {
			displayMessage = notification.get('last_alert')
				? notification.get('last_alert')
				: notification.get('description');
		} else {
			displayMessage =
				subaccountStatusDefaults[subaccntStatus] &&
				subaccountStatusDefaults[subaccntStatus].description;
		}

		displayNotificationLevel =
			subaccountStatusDefaults[subaccntStatus] &&
			subaccountStatusDefaults[subaccntStatus].notificationLevel;
		if (notification) displayNotificationLevel = 'high';
		if (
			notification &&
			// using startsWith since in dev there are different Provisioning systems that have extra values appended
			(notification.get('system').startsWith('Provisioning') ||
				notification.get('system') === 'Nocworx Workflow') &&
			notification.get('severity') !== 'Critical'
		)
			displayNotificationLevel = 'pending';
	}

	return (
		<GeneralStatus
			message={displayMessage}
			level={displayNotificationLevel}
			title={title}
			listView={listView}
			iconOnly={iconOnly}
			BoxProps={BoxProps}
		/>
	);
};

Status.propTypes = {
	uniqId: PropTypes.string,
	subaccntStatus: PropTypes.string,
	newestNotifications: PropTypes.object.isRequired,
	title: PropTypes.string,
	listView: PropTypes.bool,
	iconOnly: PropTypes.bool,
	BoxProps: PropTypes.object,
};

Status.defaultProps = {
	uniqId: null,
	subaccntStatus: '',
};

export default Status;
