import { spawn } from 'redux-saga/effects';

import { sagas as availableSagas } from './availableModule';
import { sagas as createSagas } from './createModule';
import { sagas as detailsSagas } from './detailsModule';
import { sagas as listSagas } from './listModule';
import { sagas as updateSagas } from './updateModule';
import { sagas as rolesSagas } from './rolesModule';
import stateDataSagas from './stateData/stateDataSagas';

export default function* sagas() {
	yield spawn(availableSagas);
	yield spawn(createSagas);
	yield spawn(listSagas);
	yield spawn(detailsSagas);
	yield spawn(updateSagas);
	yield spawn(rolesSagas);
	yield spawn(stateDataSagas);
}
