import produce from 'immer';
import actions from './actions';

const initialState = {
	isStarting: false,
};

/* eslint-disable default-case, no-param-reassign */
const reducer = (state = initialState, action) =>
	produce(state, (draft) => {
		switch (action.type) {
			case actions.SET_IS_STARTING:
				draft.isStarting = action.payload;
				break;
		}
	});

export default reducer;
