import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';
import Immutable from 'immutable';

const getStateSlice = (state) => state.account.user.list;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	isPaginated: true,
	getStateSlice,
	actionType: 'ACCOUNT_USER_LIST',
	url: '/account/user/list.json',
});

const selectors = {
	...defaultSelectors,
	// must set page_size to 9999 to get all owners properly
	accountOwners: createSelector(
		defaultSelectors.getItems,
		(data) =>
			data &&
			data.filter((x) =>
				x.get('roles', Immutable.List()).includes('AccountOwner'),
			),
	),
	// must set page_size to 9999 to get all owners properly
	secondaryOwners: createSelector(
		defaultSelectors.getItems,
		(data) =>
			data &&
			data.filter((x) =>
				x.get('roles', Immutable.List()).includes('SecondaryOwner'),
			),
	),
	// must set page_size to 9999 to get all non owners properly
	nonOwners: createSelector(
		defaultSelectors.getItems,
		(data) =>
			data &&
			data.filter(
				(x) =>
					!(
						x.get('roles', Immutable.List()).includes('AccountOwner') ||
						x.get('roles', Immutable.List()).includes('SecondaryOwner')
					),
			),
	),
	totalActiveUsers: createSelector(
		defaultSelectors.getItems,
		(data) => data && data.filter((user) => !!user.get('active')).size,
	),
};

export { actions, reducer, sagas, selectors };
