import { put, select, take, call, takeLatest } from 'redux-saga/effects';
import {
	actions as swapActions,
	selectors as swapSelectors,
} from 'modules/api/basket/item/swapModule';
import { selectors as basketItemDetailsSelectors } from 'modules/api/basket/item/detailsModule';
import dialogActions from 'modules/dialogs/actions';
import snackbarSaga from 'modules/snackbar/sagas';
import { fetchBasketItemDetails } from '../fetchBasketItemDetails';
import basketActions from '../../actions';

function* swap({ region, productCode, uuid }) {
	yield put(swapActions.fetch({ region, uuid, product_code: productCode }));
	const action = yield take([swapActions.setType, swapActions.errorType]);
	const error = action.type === swapActions.errorType;
	yield call(snackbarSaga, {
		error,
		errorMessage: yield select(swapSelectors.getErrorString),
	});
	if (!error) yield call(fetchBasketItemDetails, action);
}

function* changeOS({ os, uuid }) {
	yield put(dialogActions.close());
	const productCode =
		(yield select(basketItemDetailsSelectors.productCode)) || '';
	const productCodeNoWin = productCode.replace(/\.WIN$/, '');
	const newProductCode =
		os === 'windows' ? `${productCodeNoWin}.WIN` : productCodeNoWin;
	if (newProductCode !== productCode)
		yield call(swap, { uuid, productCode: newProductCode });
}

// called from itemAssertProperties if new zone is outside region.
function* changeRegion({ region, uuid }) {
	yield call(swap, { region, uuid });
}

function* changeStormType({ uuid }) {
	const isCloudDedicated = yield select(
		basketItemDetailsSelectors.isCloudDedicated,
	);
	const os = yield select(basketItemDetailsSelectors.os);
	const newCode = isCloudDedicated ? 'SS.VPS' : 'SS.VM';
	const newCodeWithOs = os === 'windows' ? `${newCode}.WIN` : newCode;
	yield call(swap, { productCode: newCodeWithOs, uuid });
}

export { changeOS, changeRegion, changeStormType, swap };
export default function* itemSwapRoot() {
	yield takeLatest([basketActions.BASKET_ITEM_SWAP_OS], changeOS);
	yield takeLatest([basketActions.BASKET_ITEM_SWAP_REGION], changeRegion);
	yield takeLatest(
		[basketActions.BASKET_ITEM_SWAP_STORM_TYPE],
		changeStormType,
	);
}
