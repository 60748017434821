import React from 'react';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';
import Badge from '@material-ui/core/Badge';

export const SProgress = styled((props) => (
	<CircularProgress size={8} {...props} />
))`
	color: ${(p) => p.theme.palette.common.white1};
`;

export const SBadge = styled((props) => (
	<Badge variant="standard" color="error" {...props} />
))``;
