import { combineReducers } from 'redux';

import { reducer as details } from './detailsModule';
import { reducer as price } from './priceModule';
import marketplace from './marketplace/marketplaceReducer';

export default combineReducers({
	details,
	marketplace,
	price,
});
