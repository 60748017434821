import { createSelector } from 'reselect';
import { selectors as dynamicChildSelectors } from 'modules/api/account/limits/dynamicChildModule';
import { selectors as productConfigSelectors } from 'modules/basket/productConfig';
import { selectors as assertPropertiesSelectors } from 'modules/api/basket/item/assertPropertiesModule';
import { middleValue } from 'utility/middleValue';
import stormConfigSelectors from './stormConfigSelectors';
import { selectors } from '.';

const availableParents = createSelector(
	selectors.extraData,
	(extraData_) => extraData_?.available_parents || [],
);

const deployOntoOptions = createSelector(
	availableParents,
	(parents) => [
		{ value: 'vps', label: 'VPS' },
		{ value: 'cloudDedicated', label: 'Cloud Dedicated' },
		...parents.map(({ domain, uniq_id: uniqId }) => ({
			label: domain,
			value: uniqId,
		})),
	],
);

const selectedPrivateParent = createSelector(
	availableParents,
	productConfigSelectors.deployOnto,
	(slice, toFind) => {
		return slice?.find(({ uniq_id: uniqId }) => uniqId === toFind);
	},
);

const initialCpu = createSelector(
	selectedPrivateParent,
	dynamicChildSelectors.basketLimits,
	stormConfigSelectors.currentCores,
	(privateParent, limits, current) =>
		middleValue([privateParent?.vcpu, limits?.Cpu, current]),
);

const initialRam = createSelector(
	selectedPrivateParent,
	dynamicChildSelectors.basketLimits,
	stormConfigSelectors.currentRamRaw,
	(privateParent, limits, current) =>
		middleValue([
			privateParent?.resources?.memory?.free,
			limits?.Memory,
			current,
		]),
);

// TODO: stormConfigSelectors.currentStorage is returning a string. Why?
const initialDisk = createSelector(
	selectedPrivateParent,
	dynamicChildSelectors.basketLimits,
	stormConfigSelectors.currentStorageRaw,
	(privateParent, limits, current) =>
		middleValue([
			privateParent?.resources?.diskspace?.free,
			limits?.Disk,
			current,
		]),
);

const deployConfigProps = createSelector(
	dynamicChildSelectors.basketLimits,
	selectedPrivateParent,
	selectors.properties,
	(limits, selectedPP, properties) => {
		const cpuMin = limits?.Cpu;
		const ramMin = limits?.Memory;
		const diskMin = limits?.Disk;
		return {
			cpu: {
				min: cpuMin,
				max: selectedPP?.vcpu,
			},
			ram: {
				min: ramMin,
				max: selectedPP?.resources?.memory?.free,
			},
			disk: {
				min: diskMin,
				max: selectedPP?.resources?.diskspace?.free,
			},
			initialValues: {
				cpu: properties?.vcpu || cpuMin,
				cpuText: properties?.vcpu || cpuMin,
				ram: properties?.memory || ramMin,
				ramText: properties?.memory || ramMin,
				disk: properties?.diskspace || diskMin,
				diskText: properties?.diskspace || diskMin,
			},
		};
	},
);

// does not include stormConfigSelectors.isLoading because that is optimistically updated.
const isLoading = createSelector(
	dynamicChildSelectors.isLoading,
	assertPropertiesSelectors.isLoading,
	selectors.properties,
	// no selected private parent means we're either loading the details from setting the deploy onto, or the private parent sliders aren't displaying anyway.
	(dynamic, assert, properties) => dynamic || (assert && !properties.parent),
);

export default {
	availableParents,
	deployOntoOptions,
	selectedPrivateParent,
	deployConfigProps,
	initialCpu,
	initialRam,
	initialDisk,
	isLoading,
};
