import {
	osStringConvert,
	supportLevelStringConvert,
} from './templateCopyHelpers';

const createTemplateTagOptions = (tags, supportLevels, osArray) => {
	const tagsOptions = tags
		.filter((tag) => !supportLevels.includes(tag) && !osArray?.includes(tag)) // some of these are duplicates
		.map((elem) => ({
			label: elem,
			value: elem,
		}));

	const supportOptions = supportLevels.map((elem) => ({
		label: supportLevelStringConvert(elem),
		value: elem,
	}));

	const osOptions = osArray.map((elem) => ({
		label: osStringConvert(elem),
		value: elem,
	}));

	// merge the arrays and return the result.
	return [
		...tagsOptions,
		...supportOptions,
		...osOptions,
		{ label: 'All', value: 'All' },
	];
};

const createTagType = (osArray, supportLevels, show) => {
	if (show === 'All') return 'All';
	if (osArray?.includes(show)) return 'os';
	if (supportLevels?.includes(show)) return 'supportLevel';
	return 'tag';
};

export { createTagType, createTemplateTagOptions };
