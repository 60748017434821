import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import compose from 'utility/compose';
import { Field, reduxForm } from 'redux-form';
import { required } from 'redux-form-validators';
import { renderTextField } from 'utility/form/renderers';
import LWButton from 'components/common/LWButton';
import LWTypography from 'components/common/LWTypography';
import { selectors as supportRequestCreateSelectors } from 'modules/api/support/request/createModule';
import ConfirmCancel from 'components/common/Dialogs/ConfirmCancel';

const styles = () => ({
	root: {
		padding: '1rem',
	},
	formControl: {
		marginBottom: '2rem',
		display: 'block',
	},
});

const UnblockIPSupportForm = ({
	classes,
	handleSubmit,
	onClose,
	onSubmit,
	invalid,
	isLoading,
	showTextField,
	setShowTextField,
}) => {
	const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);

	useEffect(() => {
		if (isLoading === true) {
			setFeedbackSubmitted(true);
		}
	}, [isLoading]);

	if (feedbackSubmitted) {
		return (
			<LWTypography>
				Thank you for the feedback. The Support Team has received and recorded
				it, and will keep you up to date with changes in the ticket created.
			</LWTypography>
		);
	}

	return showTextField ? (
		<form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
			<Grid container spacing={1}>
				<Grid item xs={12}>
					<Field
						name="description"
						label="Comments"
						component={renderTextField}
						multiline
						fullWidth
						validate={required()}
					/>
				</Grid>
				<Grid item xs={8} md={6}>
					<LWButton
						color="secondary"
						variant="contained"
						type="submit"
						disabled={invalid}
					>
						{isLoading ? <CircularProgress size={22} /> : 'Submit Request'}
					</LWButton>
				</Grid>
				<Grid item xs={8} md={6}>
					<LWButton color="tertiary" variant="contained" onClick={onClose}>
						{'Cancel'}
					</LWButton>
				</Grid>
			</Grid>
		</form>
	) : (
		<>
			<Grid container spacing={1}>
				<LWTypography className={classes.summary}>
					{`We're sorry, but there was a problem unblocking that IP.  Would you like to open a support request?`}
				</LWTypography>
			</Grid>
			<ConfirmCancel
				confirm={() => setShowTextField(true)}
				confirmText="Yes"
				cancel={onClose}
				cancelText="No"
			/>
		</>
	);
};

UnblockIPSupportForm.propTypes = {
	classes: PropTypes.object.isRequired,
	handleSubmit: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
	invalid: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, ownProps) => ({
	initialValues: {
		description: `I received this error "${ownProps.errorMsg}" when trying to unblock IP "${ownProps.serverDetails.ip}" from server "${ownProps.serverDetails.server.domain} - ${ownProps.serverDetails.server.ip}". I would like the IP unblocked from the server as soon as possible.`,
	},

	isLoading: supportRequestCreateSelectors.isLoading(state),
});

export { UnblockIPSupportForm };

export default compose(
	withStyles(styles),
	connect(mapStateToProps),
	reduxForm({
		form: 'unBlockIpSupport',
		enableReinitialize: true,
	}),
)(UnblockIPSupportForm);
