// TODO: write unit tests for these selectors.

import { createAPIModule } from 'utility/redux/apiModuleHelpers';
import { createSelector } from 'reselect';
import { bytes, toGibibyte } from 'utility/format';
import { selectors as assetDetailSelectors } from 'modules/api/asset/detailsModule';
import { deployOnto as deployOntoSelector } from 'modules/server/resize/options/selectors';

const getStateSlice = (state) => state.server.availableConfigs;

const {
	actions,
	reducer,
	sagas,
	selectors: defaultSelectors,
} = createAPIModule({
	getStateSlice,
	method: 'POST',
	actionType: 'SERVER_AVAILABLECONFIGS',
	url: '/server/availableConfigs.json',
});

const { getNativeState } = defaultSelectors;

// This takes the array we get from the api and munges in the current deployment if it doesn't exist.
const configs = createSelector(
	getNativeState,
	assetDetailSelectors.configIdValue,
	assetDetailSelectors.configId,
	assetDetailSelectors.productType,
	assetDetailSelectors.privateParent,
	(slice, currentId, currentConfigImmuable, productCode, privateParent) => {
		const availableConfigs = slice?.data?.configs || [];
		const addCurrent =
			!availableConfigs.find(
				({ config_id: configId }) => configId === currentId,
			) && Boolean(!privateParent);
		const currentConfig = currentConfigImmuable.toJS();
		// Since we don't reliably get the selected config from the api, we munge as needed.
		if (addCurrent)
			availableConfigs.push({
				category: currentConfig.category,
				config_id: currentId,
				cores: currentConfig.cpu_cores,
				is_baremetal: Number(currentConfig.category === 'bare-metal'),
				memory: currentConfig.memory,
				price: currentConfig.price,
				product_code: productCode,
				storage_size: currentConfig.disk,
				storage_type: currentConfig.disk_type,
				cpu: currentConfig.cpu_description,
				storage_raid: currentConfig.raid_level,
				cpu_speed: currentConfig.cpu_speed,
			});
		return availableConfigs;
	},
);

// returns an array designed to be consumed by ProductOptionTileSet.
// TODO: destructure config in map.
const getProductOptions = ({ rawConfigs = [], current = NaN, deployOnto }) =>
	rawConfigs
		.filter((elem) => elem.config_id)
		.map((config) => ({
			value: Number(config.config_id), // Number to ensure consistancy with configIdValue selector in modules/api/asset/detailsModule
			price: config.price,
			isCurrent: current === Number(config.config_id),
			listItems: [
				config.cpu_speed && {
					value: `Speed: ${config.cpu_speed}MHz`,
					key: 'speed',
				},
				{
					value: `${deployOnto === 'vps' ? 'vCPUs' : 'Cores'}: ${config.cores}`,
					key: 'cores',
					toolTipText: config.cpu_description?.replace(/\s+/, ' '),
				},
				{ value: `Ram: ${toGibibyte(config.memory)}`, key: 'ram' },
				{
					value: `Disk: ${bytes(config.storage_size)}${
						config.storage_type ? `, ${config.storage_type}` : ''
					}${config.storage_raid ? `, Raid:${config.storage_raid}` : ''}`,
					key: 'disk',
				},
			].filter((elem) => elem), // removes undefined listItems.
			// The following are only for filtering and not used in ProductOptionTileSet even though they may get passed in.
			cores: Number(config.cores),
			ram: Number(config.memory),
			storage: Number(config.storage_size),
			disk: config.storage_type,
		}));

const rawConfigsSelector = createSelector(
	configs,
	deployOntoSelector,
	(slice, deployOnto) => {
		if (deployOnto === 'cloudDedicated') {
			return slice?.filter(({ is_baremetal: isBaremetal }) => isBaremetal);
		}
		if (deployOnto === 'vps') {
			return slice?.filter(({ is_baremetal: isBaremetal }) => !isBaremetal);
		}
		return [];
	},
);

const optionsSelector = createSelector(
	assetDetailSelectors.configIdValue,
	rawConfigsSelector,
	deployOntoSelector,
	(current, rawConfigs, deployOnto) => {
		return getProductOptions({
			rawConfigs,
			current,
			deployOnto,
		});
	},
);

// Selectors for LWSliders
/* all of the following can be selected dynamically
by the deployOnto property (values cloudDedicated or vps) in modules/server/resize */

const sortedSet = (options) =>
	Array.from(new Set(options.filter((elem) => elem).sort((a, b) => a - b)));

const cores = createSelector(
	optionsSelector,
	(slice) => sortedSet(slice.map((elem) => elem.cores)),
);

const ram = createSelector(
	optionsSelector,
	(slice) => sortedSet(slice.map((elem) => elem.ram)),
);

const storage = createSelector(
	optionsSelector,
	(slice) => sortedSet(slice.map((elem) => elem.storage)),
);

// returns an array designed to be consumed by LWSelect.
const getDiskOptions = (rawConfigs) => {
	const diskOptions = Array.from(
		new Set(
			rawConfigs?.filter(({ disk: option }) => option).map(({ disk }) => disk),
		),
	);

	return (
		diskOptions?.map((option) => ({
			value: option,
			label: option,
		})) || []
	);
};

const diskOptions = createSelector(
	optionsSelector,
	(slice) => {
		return getDiskOptions(slice);
	},
);

const selectors = {
	configs,
	optionsSelector,
	cores,
	ram,
	storage,
	diskOptions,
	...defaultSelectors,
};

export { actions, reducer, sagas, selectors };
