const actions = {
	SET_DIALOGS_OPEN: 'SET_DIALOGS_OPEN',
	open: ({
		title,
		content,
		dialogProps,
		color,
		contentKey,
		contentProps,
		dismissible,
	}) => ({
		type: actions.SET_DIALOGS_OPEN,
		content,
		title,
		dialogProps,
		color,
		contentKey,
		contentProps,
		dismissible,
	}),
	SET_DIALOGS_CLOSE: 'SET_DIALOGS_CLOSE',
	close: () => ({
		type: actions.SET_DIALOGS_CLOSE,
	}),
	DIALOGS_SET_TITLE: 'DIALOGS_SET_TITLE',
	setTitle: (title) => ({
		type: actions.DIALOGS_SET_TITLE,
		title,
	}),
	DIALOGS_SET_CONTENT: 'DIALOGS_SET_CONTENT',
	setContent: (content) => ({
		type: actions.DIALOGS_SET_CONTENT,
		content,
	}),
};

export default actions;
