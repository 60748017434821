import { combineReducers } from 'redux';

import { reducer as create } from './createModule';
import { reducer as deleteReducer } from './deleteModule';
import { reducer as list } from './listModule';
import { reducer as multiCreateAndUpdate } from './multiCreateAndUpdateModule';
import { reducer as update } from './updateModule';

export default combineReducers({
	create,
	list,
	delete: deleteReducer, // delete is a keyword
	multiCreateAndUpdate,
	update,
});
