import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import ConfirmationDialog from 'components/common/ConfirmationDialog';

const EditNameDialog = ({
	renameObj: { renamed, isLoading: isLoadingRename },
	availableObj: {
		error: availableError,
		data: availableData,
		isLoading: isLoadingAvailable,
	},
	dialogObj: { isOpen, dialogProps },
	clearRename,
	clearAvailable,
	displaySnackbar,
	toggleDialog,
	fetchAvailable,
	renameProject,
	history,
}) => {
	const { projectId, projectName, redirect, fetchAfter } = dialogProps;
	const [newProjectName, setNewProjectName] = useState(projectName);

	React.useEffect(() => {
		setNewProjectName(projectName);
	}, [projectName]);

	React.useEffect(() => {
		if (renamed) {
			if (fetchAfter) {
				fetchAfter();
			}
			if (redirect) {
				// redirect
				history.push('/projects');
			}
			displaySnackbar(`Successfully renamed project ${projectName}`);
			clearRename();
			clearAvailable();
		}
	}, [
		clearAvailable,
		clearRename,
		renamed,
		displaySnackbar,
		history,
		projectName,
		redirect,
		fetchAfter,
	]);

	const handleChangeName = (e) => {
		setNewProjectName(e.target.value);
		if (e.target.value) {
			fetchAvailable(e.target.value);
		}
	};
	const notAvailable =
		(availableData && availableData.available === 0) || !!availableError;
	return (
		<ConfirmationDialog
			open={isOpen}
			confirmProps={{
				disabled: notAvailable,
				isLoading: isLoadingRename || isLoadingAvailable,
			}}
			onConfirm={() => renameProject({ name: newProjectName, projectId })}
			onClose={() => toggleDialog(false)}
			title="Edit Project Name"
		>
			<TextField
				error={notAvailable}
				helperText={notAvailable && 'Project name not available'}
				value={newProjectName}
				label="Project Name"
				variant="outlined"
				onChange={handleChangeName}
			/>
		</ConfirmationDialog>
	);
};

EditNameDialog.propTypes = {
	redirect: PropTypes.bool,
};

EditNameDialog.defaultProps = {
	redirect: false,
};

export default EditNameDialog;
