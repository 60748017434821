import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Route } from 'react-router';
import { useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Loading from 'components/common/Loading';
import { getFullPathName, getQueryParams } from 'modules/routeSelectors';
import { selectors as appConfigSelectors } from 'modules/api/appConfigModule';
import { isKeroOnly as isKeroOnlySelector } from 'modules/auth/oidcSelectors';
import { REDIRECT_PATH, OPP_ID, CLONE_BASKET } from 'utility/constants/auth';

const AuthenticatedRoute = ({
	exact,
	path,
	component: Component,
	isKeroOnly,
	keroSignupLink,
	redirectPath,
	queryParams,
	userManager,
}) => {
	const [isLoading, setIsLoading] = useState(true);
	const history = useHistory();

	useEffect(() => {
		if (userManager) {
			userManager.clearStaleState();
			userManager.getUser().then((user) => {
				if (user) {
					// Handle leftover expired sessions
					if (new Date().getTime() / 1000 > user.expires_at) {
						userManager.signinRedirect();
					}
					setIsLoading(false);
					const storedRedirectPath = sessionStorage.getItem(REDIRECT_PATH);
					if (storedRedirectPath) {
						sessionStorage.removeItem(REDIRECT_PATH);
						history.push(storedRedirectPath);
					}
				} else {
					const { opp_id: oppId, clone } = queryParams;
					sessionStorage.setItem(REDIRECT_PATH, redirectPath);
					if (oppId) sessionStorage.setItem(OPP_ID, oppId);
					if (clone) sessionStorage.setItem(CLONE_BASKET, clone);
					userManager.signinRedirect();
				}
			});
		}
	}, [
		queryParams,
		userManager,
		history,
		isKeroOnly,
		redirectPath,
		keroSignupLink,
	]);

	if (isLoading) {
		return (
			<Box height={250} position="relative" p={2} m={2}>
				<Loading />
			</Box>
		);
	}

	return (
		<Route
			exact={exact}
			path={path}
			render={(otherProps) => <Component {...otherProps} />}
		/>
	);
};

AuthenticatedRoute.propTypes = {
	path: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
	redirectPath: getFullPathName(state),
	queryParams: getQueryParams(state),
	userManager: appConfigSelectors.userManager(state),
	keroSignupLink: appConfigSelectors.getKeroSignupLink(state),
	isKeroOnly: isKeroOnlySelector(state),
});

export { AuthenticatedRoute };

export default connect(mapStateToProps)(AuthenticatedRoute);
