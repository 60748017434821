import accountAddressActions from './addressActions';

const initialState = {
	isValidateDialogOpen: false,
	address: {},
	submit: null,
	effect: null,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case accountAddressActions.ACCOUNT_ADDRESS_TOGGLE_DIALOG:
			return Object.assign({}, state, {
				isValidateDialogOpen: action.isValidateDialogOpen,
			});
		case accountAddressActions.ACCOUNT_ADDRESS_SET_VALIDATE:
			return Object.assign({}, state, {
				address: action.address,
				submit: action.submit,
			});
		case accountAddressActions.ACCOUNT_ADDRESS_SUBMIT_ORIGINAL:
			return Object.assign({}, state, {
				isValidateDialogOpen: false,
			});
		case accountAddressActions.ACCOUNT_ADDRESS_SUBMIT_VALIDATED:
			return Object.assign({}, state, {
				isValidateDialogOpen: false,
			});
		default:
			return state;
	}
};

export default reducer;
