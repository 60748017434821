import sortAscending from 'utility/sortAscending';

// grab the middle value in an array with an odd number of items
// or the first middle value in an array with an even numer of items
const middleValue = (values) => {
	const sortedValues = sortAscending(values.filter((elem) => elem));
	const middleIndex = Math.floor((sortedValues.length - 1) / 2);
	return sortedValues[middleIndex];
};

// grab one above and one below the middle most value
const middleValues = (values) => {
	if (!values || !values.length) return [];
	const sortedValues = sortAscending(values);
	if (sortedValues.length === 2) return sortedValues;
	if (sortedValues.length === 1) return [sortedValues[0], sortedValues[0]];
	const middleIndex = Math.floor((sortedValues.length - 1) / 2);
	return [sortedValues[middleIndex - 1], sortedValues[middleIndex + 1]];
};

export { middleValue, middleValues };
