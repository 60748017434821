const actions = {
	SSH_KEYS_CLOSE_DIALOG: 'SSH_KEYS_CLOSE_DIALOG',
	closeDialog: () => ({
		type: actions.SSH_KEYS_CLOSE_DIALOG,
	}),
	SSH_KEYS_ADD_KEY: 'SSH_KEYS_ADD_KEY',
	add: () => ({
		type: actions.SSH_KEYS_ADD_KEY,
	}),
	SSH_KEYS_EDIT_KEY: 'SSH_KEYS_EDIT_KEY',
	edit: ({ publicKeyName, publicKeyValue, id }) => ({
		type: actions.SSH_KEYS_EDIT_KEY,
		payload: {
			publicKeyName,
			publicKeyValue,
			id,
		},
	}),
	SSH_KEYS_REMOVE_KEY: 'SSH_KEYS_REMOVE_KEY',
	remove: ({ publicKeyName, publicKeyValue, id }) => ({
		type: actions.SSH_KEYS_REMOVE_KEY,
		payload: {
			publicKeyName,
			publicKeyValue,
			id,
		},
	}),
	// Triggers saga
	SSH_KEYS_ADD_CONFIRM: 'SSH_KEYS_ADD_CONFIRM',
	addConfirm: () => ({
		type: actions.SSH_KEYS_ADD_CONFIRM,
	}),

	// Triggers saga
	SSH_KEYS_EDIT_CONFIRM: 'SSH_KEYS_EDIT_CONFIRM',
	editConfirm: () => ({
		type: actions.SSH_KEYS_EDIT_CONFIRM,
	}),

	// Triggers saga
	SSH_KEYS_REMOVE_CONFIRM: 'SSH_KEYS_REMOVE_CONFIRM',
	removeConfirm: () => ({
		type: actions.SSH_KEYS_REMOVE_CONFIRM,
	}),

	SSH_KEYS_CHANGE_VALUE: 'SSH_KEYS_CHANGE_VALUE',
	changeValue: ({ label, value }) => ({
		type: actions.SSH_KEYS_CHANGE_VALUE,
		payload: {
			label,
			value,
		},
	}),

	SSH_KEYS_ERROR: 'SSH_KEYS_ERROR',
	error: (errors) => ({
		type: actions.SSH_KEYS_ERROR,
		payload: errors,
	}),
	// inits all ssh keys into the module key, 'all' (moduleKey.ALL)
	SSH_KEY_INIT_ALL: 'SSH_KEY_INIT_ALL',
	initAll: () => ({
		type: actions.SSH_KEY_INIT_ALL,
	}),
};

export default actions;
