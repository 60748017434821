const images = {
	'1': {
		accnt: 3378300,
		features: {},
		hv_type: 'kvm',
		id: '1',
		name: 'test image 2',
		size: '1.46',
		source_hostname: 'host.backupsandimagestest.com',
		source_uniq_id: '78554A',
		template: 'CENTOS_6.0_x86_64_UNMANAGED',
		template_description: 'CentOS 7 64-bit Self-managed',
		time_taken: '2020-01-14 16:25:06',
	},
	'2': {
		accnt: 3378300,
		features: {},
		hv_type: 'kvm',
		id: '2',
		name: 'image2',
		size: '7.13',
		source_hostname: 'paypergigbackups.testing.com',
		source_uniq_id: '',
		template: 'ARCHLINUX1234',
		template_description: 'CentOS 7 64-bit cPanel w/CloudLinux',
		time_taken: '2020-01-16 16:53:47',
	},
	'4': {
		accnt: 3378300,
		features: {},
		hv_type: 'kvm',
		id: '4',
		name: 'testing a rename',
		size: '1.50',
		source_hostname: 'host.backupsandimagestest.com',
		source_uniq_id: '78554A',
		template: 'CENTOS_6.0_x86_64_UNMANAGED',
		template_description: 'CentOS 7 64-bit Self-managed',
		time_taken: '2020-01-20 11:45:59',
	},
	'10': {
		accnt: 3378300,
		features: {},
		hv_type: 'kvm',
		id: '10',
		name: 'givemeaname',
		size: '1.50',
		source_hostname: 'host.backupsandimagestest.com',
		source_uniq_id: '78554A',
		template: 'CENTOS_6.0_x86_64_UNMANAGED',
		template_description: 'CentOS 7 64-bit Self-managed',
		time_taken: '2020-01-21 17:43:39',
	},
	'11': {
		accnt: 3378300,
		features: {},
		hv_type: 'kvm',
		id: '11',
		name: 'create image name',
		size: '1.50',
		source_hostname: 'host.backupsandimagestest.com',
		source_uniq_id: '78554A',
		template: 'CENTOS_6.0_x86_64_UNMANAGED',
		template_description: 'CentOS 7 64-bit Self-managed',
		time_taken: '2020-01-21 18:01:32',
	},
	'13': {
		accnt: 3378300,
		features: {},
		hv_type: 'kvm',
		id: '13',
		name: 'new image',
		size: '1.51',
		source_hostname: 'host.backupsandimagestest.com',
		source_uniq_id: '78554A',
		template: 'CENTOS_6.0_x86_64_UNMANAGED',
		template_description: 'CentOS 7 64-bit Self-managed',
		time_taken: '2020-01-23 14:08:27',
	},
	'14': {
		accnt: 3378300,
		features: {},
		hv_type: 'kvm',
		id: '14',
		name: 'newimage test',
		size: '1.56',
		source_hostname: 'child.mctesterson.com',
		source_uniq_id: 'UQZ3HX',
		template: 'CENTOS_6.0_x86_64_UNMANAGED',
		template_description: 'CentOS 7 64-bit Self-managed',
		time_taken: '2020-02-05 10:23:57',
	},
	'18': {
		accnt: 3378300,
		features: {},
		hv_type: 'kvm',
		id: '18',
		name: 'sdafds',
		size: '1.51',
		source_hostname: 'host.backupsandimagestest.com',
		source_uniq_id: '78554A',
		template: 'CENTOS_6.0_x86_64_UNMANAGED',
		template_description: 'CentOS 7 64-bit Self-managed',
		time_taken: '2020-04-20 12:22:24',
	},
	'19': {
		accnt: 3378300,
		features: {},
		hv_type: 'kvm',
		id: '19',
		name: '---',
		size: '1.51',
		source_hostname: 'host.backupsandimagestest.com',
		source_uniq_id: '78554A',
		template: 'CENTOS_6.0_x86_64_UNMANAGED',
		template_description: 'CentOS 7 64-bit Self-managed',
		time_taken: '2020-04-20 12:24:58',
	},
	'20': {
		accnt: 3378300,
		features: {},
		hv_type: 'kvm',
		id: '20',
		name: 'test image 2',
		size: '1.51',
		source_hostname: 'host.backupsandimagestest.com',
		source_uniq_id: '78554A',
		template: 'CENTOS_6.0_x86_64_UNMANAGED',
		template_description: 'CentOS 7 64-bit Self-managed',
		time_taken: '2020-04-20 12:29:42',
	},
	'21': {
		accnt: 3378300,
		features: {},
		hv_type: 'kvm',
		id: '21',
		name: 'aaadsfsdf',
		size: '1.53',
		source_hostname: 'host.backupsandimagestest.com',
		source_uniq_id: '78554A',
		template: 'CENTOS_6.0_x86_64_UNMANAGED',
		template_description: 'CentOS 7 64-bit Self-managed',
		time_taken: '2020-04-24 22:58:01',
	},
	'22': {
		accnt: 3378300,
		features: {},
		hv_type: 'kvm',
		id: '22',
		name: 'asdfasdfds',
		size: '1.53',
		source_hostname: 'host.backupsandimagestest.com',
		source_uniq_id: '78554A',
		template: 'CENTOS_6.0_x86_64_UNMANAGED',
		template_description: 'CentOS 7 64-bit Self-managed',
		time_taken: '2020-04-25 11:12:49',
	},
	'23': {
		accnt: 3378300,
		features: {},
		hv_type: 'kvm',
		id: '23',
		name: 'sdfsdf',
		size: '1.53',
		source_hostname: 'host.backupsandimagestest.com',
		source_uniq_id: '78554A',
		template: 'CENTOS_6.0_x86_64_UNMANAGED',
		template_description: 'CentOS 7 64-bit Self-managed',
		time_taken: '2020-04-25 11:15:03',
	},
};

export default images;
