import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LWTypography from 'components/common/LWTypography';
import ProjectName from 'components/atoms/iconsWithText/ProjectName';
import HostnameNeeded from 'components/molecules/HostnameNeeded';
import LabeledServer from 'components/connectedMolecules/LabeledServer';
import HostnameProjectDialog from 'containers/pages/shop/dialogs/HostnameProjectDialog';
import randomNumberBetween from 'utility/randomNumberBetween';
import { acronisProductCodes } from 'utility/acronisProductMap';
import { auxToolTipText as cloneToolTipText } from 'containers/pages/servers/details/CloneServerModalContents';
import CloneLink from './CloneLink';
import SLWLink from './PaddedLink';

const skeletonWidths = {
	title: '100px',
	hostnameOrSubtitle: '120px',
	secondaryInfo: `${randomNumberBetween(70, 80)}%`,
};

const SecondaryInfo = ({ isLoading, children }) => (
	<LWTypography
		variant="body1"
		BoxProps={{
			marginY: 0.5,
		}}
		SkeletonProps={{ width: skeletonWidths.secondaryInfo }}
		isLoading={isLoading}
	>
		{children}
	</LWTypography>
);

const IndentedSecondaryInfo = ({ isLoading, children }) => (
	<LWTypography
		variant="body2"
		color="inherit"
		BoxProps={{
			color: 'text.secondary',
			ml: 2,
		}}
		SkeletonProps={{ width: skeletonWidths.secondaryInfo }}
		isLoading={isLoading}
	>
		{children}
	</LWTypography>
);

const Content = ({
	title = '',
	hostnameOrSubtitle = '',
	projectName = '',
	itemUuid,
	itemDetails,
	secondaryInfo = [],
	isLoading = false,
	readOnly,
	fetchItemRemove,
	hideDomain = false, // from drillToContent
	hideEditHostname, // from drillToContent
	hideConfigure, // from drillToContent
	hideClone, // from drillToContent
	setRemoved = () => {}, // from drillToContent
	removed, // from drillToContent
	cloneFrom, // from drillTocontent
	openDialog, // from connected
	itemRemoveError, // from connected
}) => {
	const [itemErred, setItemErred] = useState(false);

	useEffect(() => {
		if (itemRemoveError && removed) {
			setRemoved(false);
			setItemErred(true);
		} else if (removed) {
			setItemErred(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [itemRemoveError, removed]);

	let configurationUrl = `/shop/config/${itemUuid}`;

	if (acronisProductCodes.includes(itemDetails.product_code))
		configurationUrl = `/servers/dedicated/${itemDetails?.properties?.related_subaccnt}/acronis/add/`;

	if (itemDetails.product_code === 'DREG')
		configurationUrl = '/shop/domain/configure';

	const isPrivateParent = itemDetails.product_code === 'SS.PP';

	return (
		<Box display="flex" flexDirection="column" flexGrow={1}>
			<Box my={0.5}>
				<Grid container alignItems="center" spacing={2}>
					<Grid item>
						<LWTypography
							variant="subtitle1"
							SkeletonProps={{ width: skeletonWidths.title }}
							isLoading={isLoading}
						>
							{title}
						</LWTypography>
					</Grid>
					{!hideDomain && (
						<Grid item>
							{!hostnameOrSubtitle && !isLoading ? (
								<HostnameNeeded
									itemDetails={itemDetails}
									hostnameDisplay={isPrivateParent ? 'Name' : 'Hostname'}
								/>
							) : (
								<LWTypography
									color="inherit"
									variant="subtitle2"
									BoxProps={{
										color: 'text.secondary',
										alignItems: 'center',
									}}
									SkeletonProps={{ width: skeletonWidths.hostnameOrSubtitle }}
									isLoading={isLoading}
								>
									{hostnameOrSubtitle}
								</LWTypography>
							)}
						</Grid>
					)}
					{projectName && (
						<Grid item>
							<Box
								pt={
									0.5
								} /* TODO: fix spacing wierdness in LabeledIcon that's making us do this. */
							>
								<ProjectName isLoading={isLoading}>{projectName}</ProjectName>
							</Box>
						</Grid>
					)}
					{cloneFrom && !isLoading && (
						<Grid item>
							<LabeledServer
								label="Clone from:"
								uniqId={cloneFrom}
								toolTipText={cloneToolTipText}
							/>
						</Grid>
					)}
				</Grid>
			</Box>
			{!readOnly && (
				<>
					<Box display="flex" alignItems="center" marginY={0.5}>
						{!hideEditHostname && (
							<SLWLink
								onClick={() =>
									// Title is handled in HostnameProjectDialog
									openDialog({
										content: (
											<HostnameProjectDialog itemDetails={itemDetails} />
										),
									})
								}
							>
								{`Edit ${isPrivateParent ? 'N' : 'Hostn'}ame/Project`}
							</SLWLink>
						)}
						{!hideConfigure && (
							<SLWLink to={configurationUrl}>Edit Configuration</SLWLink>
						)}
						{!hideClone && <CloneLink title={title} uuid={itemUuid} />}
						<SLWLink
							onClick={() => {
								fetchItemRemove({
									uuid: itemUuid,
									productCode: itemDetails.product_code,
								});
								setRemoved(true);
							}}
						>
							Delete
						</SLWLink>
					</Box>
					{itemErred && (
						<Box display="block">
							<LWTypography color="error">
								Delete was not successful
							</LWTypography>
							<LWTypography color="error">{itemRemoveError}</LWTypography>
						</Box>
					)}
				</>
			)}
			{secondaryInfo.map((info) => {
				// Can't easily seperate this out, as there needs to be a key for the components, and it varies by the data type
				if (typeof info !== 'object')
					return (
						<SecondaryInfo isLoading={isLoading} key={info}>
							{info}
						</SecondaryInfo>
					);

				return (
					<React.Fragment key={info.value}>
						<SecondaryInfo isLoading={isLoading}>{info.value}</SecondaryInfo>
						{info.subitems &&
							info.subitems.map((item) => (
								<IndentedSecondaryInfo isLoading={isLoading} key={item}>
									{item}
								</IndentedSecondaryInfo>
							))}
					</React.Fragment>
				);
			})}
		</Box>
	);
};

Content.propTypes = {
	title: PropTypes.string,
	hostnameOrSubtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	projectName: PropTypes.string,
	itemUuid: PropTypes.string,
	secondaryInfo: PropTypes.arrayOf(
		PropTypes.oneOfType([
			PropTypes.string,
			PropTypes.shape({
				value: PropTypes.string,
				subitems: PropTypes.arrayOf(PropTypes.string),
			}),
		]),
	),
	isLoading: PropTypes.bool,
	hideDomain: PropTypes.bool,
	hideEditHostname: PropTypes.bool,
	hideConfigure: PropTypes.bool,
	hideClone: PropTypes.bool,
	fetchItemRemove: PropTypes.func.isRequired,
	/** instructions from parent for hiding item during removal. */
	setRemoved: PropTypes.func,
	/** Has item removal been attempted? */
	removed: PropTypes.bool,
	/** Only displays if removed has been toggled. Forces removed item to display so error can be seen. */
	itemRemoveError: PropTypes.string,
	openDialog: PropTypes.func.isRequired,
};

export default Content;
